import * as React from "react"

const ChecklistGreenIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Zm7.414-18.586a2 2 0 1 0-2.828-2.828L14 17.172l-2.586-2.586a2 2 0 1 0-2.828 2.828l4 4a2 2 0 0 0 2.828 0l8-8Z"
      fill="#3BDB63"
    />
  </svg>
)

export default ChecklistGreenIcon
