import * as React from "react"
import { SVGProps } from "react"

const ToReceiveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M9.35718 11H31.6429V29H9.35718V11Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.35718 13.1689L19.3943 21.6392C19.7036 21.9003 20.0953 22.0435 20.5 22.0435C20.9048 22.0435 21.2965 21.9003 21.6057 21.6392L31.6429 13.1689" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToReceiveIcon
