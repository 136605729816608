import React, { useEffect, useState } from 'react';
import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Spinner } from 'reactstrap';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { translate } from 'react-jhipster';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { toast } from 'react-toastify';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]

const categoryActive = { padding: "5px", marginRight: "10px", color: "#F37F26", borderBottom: "2px solid #F37F26" };
const categoryInactive = { padding: "5px", marginRight: "10px", color: "#9E9E9E" };

export interface Props {
    match?: any,
    location?: any,
}

export const ViewFileHistory = (props: Props) => {

    let history = useHistory();
    const [urlBlob, setUrlBlob] = useState<any>(null)
    //   const [category, setCategory] = useState<any>(0)
    //   const [page, setPage] = useState<any>(1)
    //   const [limit, setLimit] = useState<any>(5)
    //   const [selected, setSelected] = useState(list[0])
    //   const [listDocumentHistoriDetail, setListDocumentHistoriDetail] = useState<any>([])
    //   const [dataTracker, setDataTracker] = useState<any>([])
    //   const [showModal, setShowModal] = useState<any>(false)
    //   const [documentSelected, setDocumentSelected] = useState<any>(null)
    //   const [loadingButton, setLoadingButton] = useState<boolean>(false)
    //   const [tooltipOpen, setTooltipOpen] = React.useState(false);

    useEffect(() => {

        getDataFile(props.location.state)
        // console.log("ViewFileHistory", props.location.state)
        // getListDocumentHistoriDetail(props.match.params.id)
        // getDocumentTracker(props.match.params.id)
    }, []);

    const getDataFile = (item) => {
        axios.get(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${item?.fileId}&fileHistoryId=${item?.fileHistoryId}`, { responseType: 'blob' })
            .then(res => {
                setUrlBlob(URL.createObjectURL(res.data))
                // console.log("ViewFileHistory", res)
                // setDataTracker(res.data.data)
            }).catch(err => {
                console.log("ViewFileHistory err", err.response.data.message)
                toast.error(translate(`${err.response.data.message}`));
            })
    }

    return (
        <div className="container">
        <Row>
          <Col>
            <h2>Preview Document</h2>
            <p>File Preview Document</p>
          </Col>
        </Row>
        {urlBlob ?
          <iframe width="100%" src={`${urlBlob}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} />
          :
          <div className="shine photo"></div>}

          </div>
        // <div className="px-5 pt-4 font-family-spoqa scrollbar" style={{ height: '100%', width: '100%' }}>
        //     <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
        //         <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
        //     </button>
        //     <div>
        //         view file history
        //     </div>
        //     {/* <div className="row mb-4">
        //         <div className="col-12">


        //         </div>
        //     </div> */}
        // </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(ViewFileHistory);