import * as React from "react"
import { SVGProps } from "react"

const SearchSentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="14" fill="#FFF4EB"/>
    <g clip-path="url(#clip0_12819_15255)">
      <path d="M12.7104 18.3539L15.0907 20.731C15.2385 20.8788 15.4216 20.9863 15.6226 21.0434C15.8236 21.1006 16.0359 21.1054 16.2393 21.0574C16.4427 21.0094 16.6305 20.9102 16.7848 20.7693C16.9391 20.6283 17.0548 20.4503 17.1209 20.2521L21.0275 8.52153C21.0996 8.3056 21.1102 8.07384 21.0579 7.85225C21.0057 7.63066 20.8928 7.42801 20.7318 7.26702C20.5708 7.10604 20.3682 6.9931 20.1466 6.94088C19.925 6.88865 19.6932 6.8992 19.4773 6.97135L7.74674 10.8812C7.54913 10.9476 7.37172 11.0633 7.23126 11.2173C7.09079 11.3713 6.99193 11.5586 6.94399 11.7615C6.89605 11.9644 6.90064 12.1762 6.9573 12.3768C7.01396 12.5774 7.12084 12.7603 7.26783 12.9081L10.258 15.9015L10.1566 19.6815L12.7104 18.3539Z" stroke="#F58220" stroke-width="1.09091" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.6773 7.2168L10.2581 15.9026" stroke="#F58220" stroke-width="1.09091" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_12819_15255">
        <rect width="15.2727" height="15.2727" fill="white" transform="translate(6.36353 6.36328)"/>
      </clipPath>
    </defs>
  </svg>
)

export default SearchSentIcon
