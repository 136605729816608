import * as React from "react"
import { SVGProps } from "react"

const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8Zm2.966-7.434a.8.8 0 0 0-1.132-1.132L7.2 8.47 6.166 7.434a.8.8 0 1 0-1.132 1.132l1.6 1.6a.8.8 0 0 0 1.132 0l3.2-3.2Z"
      fill="#fff"
    />
  </svg>
)

export default CheckCircleIcon
