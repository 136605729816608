import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from "react-multi-select-component";
import sanitizeHtml from 'sanitize-html';
import CloseModal from 'app/component/close-modal';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
  match?: any
}


export const AnnouncementView = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([])
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([])
  const [fileContent, setFileContent] = useState<any>('')
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [title, setTile] = useState<any>('')
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [datePost, setDatePost] = useState<any>('')
  const [dateCreated, setDateCreated] = useState<any>('')
  const [id, setId] = useState<any>("0")
  const [pengirim, setPengirim] = useState<any>("")
  const [urlProfileImage, setUrlProfileImage] = useState<any>("")

  const [modalBannerPreview, setModalBannerPreview] = useState<any>(false)

  const onDropHandler = (ev) => {
    ev.preventDefault();
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file =
        [...ev.dataTransfer.items]
          .find((item: any) => item.kind === "file")
          .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    setFiles(file);
    setUrlImage(URL.createObjectURL(file))
  };

  const onDragOver = (ev) => ev.preventDefault();

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }

  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);

  const getDetailAnnouncement = (id) => {
    let data = []
    axios.get(`services/documentservice/api/detail-announcement/${id}`)
      .then((res) => {
        console.log("getDetailAnnouncement", res)
        setTile(res.data.data.title)
        setDateCreated(res.data.data.createdDateFormat)
        setPostDate(res.data.data.postedDateFormat)
        // res.data.data.to.map((dataarr, i) => {
        //   data.push({label: dataarr.branchName, value: dataarr.branchCode})
        // })
        setPengirim(`${res.data.data.senderEmployeeName} - ${res.data.data.senderBranchName}`)
        setFileContent(res.data.data.fileContent)
        if (res.data.data.image) {
          setUrlImage('data:image/png;base64,' + res.data.data.image)
        } else {
          setUrlImage("")
        }
        setUrlProfileImage(`data:image/png;base64 , ${res.data.data.senderProfilePicture}`)
        MarkAsREad(res.data.data)
        setTo(data)
      }).catch((err) => {
        console.log(err)
      })
  }

  const MarkAsREad = (data) => {

    axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`).then((res) => {

    }).catch((err) => {

    })
  }

  useEffect(() => {
    getSearch(props.location.state?.search)
    getUserList()
    // getDepartmentList()

    getDetailAnnouncement(props.match.params.id)
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListAnnouncement = (page, limit) => {
    axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`)
      .then(res => {
        setListAnnouncement(res.data.data?.content)
        setTotalPage(res.data.data?.totalPages)
      })
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }


  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`).then(async (result) => {
        setListSearch(result.data.content)
        setTotalPage(result.data?.totalPages)
        await delayTime(2000)
        setLoadingList(false)
      }).catch(err => {
        setListSearch([])
        setLoadingList(false)
      })
    }
  }

  const getUserList = () => {
    axios.get('services/uaadocservice/api/users/get-list-user-not-in-employee-no')
      .then(res => {
        // console.log('user', res)
      }).catch(err => {
        console.log(err)
      })
  }

  // const getDepartmentList = () => {
  //   axios.get('services/uaadocservice/api/department/list')
  //     .then(res => {
  //       if (res.data.length > 1) {
  //         res.data.map((data, i) => {
  //           setListTo(oldArray => [...oldArray, { label: data.deptName, value: data.deptCode }]);
  //         })
  //       }
  //     }).catch(err => {
  //       console.log(err)
  //     })
  // }


  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }

  const deleteFile = (id) => {
    setLoadingDelete(true)
    axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
      setLoadingDelete(false)
      toast.success('Success Delete Annoucement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowModal(false)
      setItemSelected(null)
      setPage(1)
      setLimit(5)
      getListAnnouncement(1, 5)
    }).catch(err => {
      setLoadingDelete(false)
      toast.error('Failed Delete Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const handlerChangeImage = (data) => {
    setFiles(data)
    setUrlImage(URL.createObjectURL(data))
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';
    event.currentTarget.onerror = null;
  };

  const openBannerPreview = () => {
    setModalBannerPreview(true)
  }

  const closeBannerPreview = () => {
    setModalBannerPreview(false)
  }

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">{title}</h1>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <img className="rounded-circle header-profile-user-announcement text-center" onError={(e) => imageOnErrorHandler(e)} src={urlProfileImage ? urlProfileImage : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                alt="Header Avatar" />
              <div className='mx-2'>
                <p className="text-center mb-0 font-size-14">
                  {pengirim}
                </p>
                <p className='mb-0 font-size-12'>
                  {postDate}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md my-4" style={{}}>
        <div className="bg-white" style={{ padding: "20px" }}>
          <div>
            {
              urlImage ?
                <div  style={{cursor: 'pointer'}} onClick={openBannerPreview}>
                  <img className="mx-auto d-block bg-white" style={{ objectFit: "contain", width: '100%', height: '300px', marginTop: "10px" }} src={urlImage}
                    alt="Sign" onError={(e) => imageOnErrorHandler(e)} />
                </div>
                :
                <></>
            }
          </div>
          <div className="px-3 pt-4 pb-5 mt-3 ml-2 mr-2">
            <p className="font-size-14" style={{ textAlign: 'justify', fontWeight: 500, color: "#616161" }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  deentitize(
                    fileContent
                  ),
                ),
              }}
            ></p>
          </div>
        </div>
      </div>

      <Modal isOpen={modalBannerPreview} size="lg" centered >
        <ModalBody id="documenttestApp.document.delete.question" style={{ padding: '0 !important' }}>
          <div style={{ position: 'absolute', right: '2%', top: '2%', cursor: 'pointer' }} onClick={() => closeBannerPreview()}>
            <CloseModal />
          </div>
          <div style={{ margin: "10px" }}>
            <img src={urlImage} style={{ objectFit: "contain", width: '100%', height: '700px' }} />
          </div>
        </ModalBody>
      </Modal>

    </div>
  );
};

const deentitize = function (input) {
  var ret = input
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/<br\s*[\/]?>/gi, '<br><br>')
  return ret
}

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementView);
