import * as React from "react"
import { SVGProps } from "react"

const IconSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 1a1 1 0 0 0-1.447-.894L6.763 4H3a3 3 0 0 0 0 6h.28l1.771 5.316A1 1 0 0 0 6 16h1a1 1 0 0 0 1-1v-4.382l6.553 3.276A1 1 0 0 0 16 13V1Z"
      fill="#F37F26"
    />
  </svg>
)

export default IconSpeaker