import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './management.scss'
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';
import Select from 'react-select'
import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Select as SelectMui } from '@mui/material';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export const MenuManagement = (props: IHeaderProps) => {
    const initialFormData = {
        id: "0",
        typeMenu: "",
        description: "",
        apiUrl: ""
    }

    const dataStatus = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Active",
            value: "true"
        },
        {
            label: "Inactive",
            value: "false"
        }
    ]

    let history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState<any>(10);
    const [totalPage, setTotalPage] = useState(1);
    const [modalMenuAdd, setModal] = useState(false);
    const [modalMenuDelete, setModalDelete] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [dataMenuManagement, setDataMenuManagement] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formEdit, setFormEdit] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [valSearch, setValSearch] = useState("");
    const [valActivated, setValActivated] = useState("all");

    useEffect(() => {
        getData()
    }, [page, size, totalPage, valSearch, valActivated]);

    // data menu management
    const getData = () => {
        var pageReal = page
        var pageCount = pageReal - 1
        var validateParamActive = valActivated == "all" ? `` : `&activated=${valActivated}`
        axios.get(`services/uaadocservice/api/menu/menus?search=${valSearch}${validateParamActive}&page=${pageCount}&limit=${size}`)
            .then(res => {
                setDataMenuManagement(res.data.data.data)
                setTotalPage(res.data.data.totalPage)
            }).catch(err => {
                toast.error(err.response.data.code + ' ' + err.response.data.message);
            })
    }

    const postData = () => {
        setLoading(true)
        let dataFormData = {
            menuName: formData.typeMenu,
            menuDescription: formData.description,
            menuApiUrl: "",
        }
        axios.post('services/uaadocservice/api/menu/add-menus', dataFormData).then(res => {
            setModal(false)
            setLoading(false)
            getData()
        }).catch(err => {
            setModal(false)
            toast.error(translate(`${err.response.data.message}`));
            setLoading(false)
        })
        setFormData({
            id: "0",
            typeMenu: "",
            description: "",
            apiUrl: ""
        })
    }

    const getDetail = (idMenu) => {
        axios.get(`services/uaadocservice/api/menu/menus/${idMenu}`).then(res => {
            var response = res.data.data;
            setFormData({
                id: idMenu,
                typeMenu: response.name,
                description: response.description,
                apiUrl: response.apiUrl
            })
            handleModal()
            setFormEdit(!formEdit)
        }).catch(err => {
            console.log(err)
        })
    }

    const postEditData = () => {
        setLoading(true)
        let editFormData = {
            menuName: formData.typeMenu,
            menuDescription: formData.description,
            menuApiUrl: "",
        }
        axios.put(`services/uaadocservice/api/menu/update-menus/${formData.id}`, editFormData).then(res => {
            setFormData({
                id: "0",
                typeMenu: "",
                description: "",
                apiUrl: ""
            })
            toast.success('Success Edit Data Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleModal()
            getData()
            setFormEdit(!formEdit)
            setLoading(false)
        }).catch(err => {
            toast.error('Failed Edit Data Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleModal()
            getData()
            setFormEdit(!formEdit)
            setLoading(false)
        })
    }

    const deleteData = () => {
        setLoading(true)
        axios.delete(`services/uaadocservice/api/menu/delete-menus/${idDelete}`).then(res => {
            toast.success('Success Delete Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIdDelete(null)
            setLoading(false)
            deleteHandleClose()
            getData()
        }).catch(err => {
            toast.error('Failed Delete Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIdDelete(null)
            setLoading(false)
            deleteHandleClose()
            getData()
        })
    }

    const handleSearch = (searchVal) => {
        setPage(1)
        setValSearch(searchVal)
    }

    const updateStatus = (valId, valStatus) => {
        let pathStatus = valStatus == "disable" ? `services/uaadocservice/api/menu/inactivate-menus/${valId}` : `services/uaadocservice/api/menu/activate-menus/${valId}`
        setLoading(true)
        axios.put(`${pathStatus}`)
            .then(res => {
                toast.success('Success update status menu');
                getData()
                setLoading(false)
            }).catch(err => {
                toast.error(translate(`${err.response.data.message}`));
                getData()
                setLoading(false)
            })
    }

    // modal 
    const handleModal = () => {
        setModal(!modalMenuAdd);
    }

    const handleClose = () => {
        setModal(false)
        setFormEdit(false)
        setFormData({
            ...formData,
            typeMenu: "",
            description: ""
        })
    }

    const deleteHandleModal = () => {
        setModalDelete(!modalMenuDelete);
    }

    const deleteHandleClose = () => {
        setModalDelete(false)
    };

    // pagination table
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Menu Management</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Mirae Asset DMS - Menu Management</h1>
                    </div>
                </div>
            </div>
            <div className='row my-3'>
                <div className="col-12 col-md-9 col-lg-9 px-0">
                    <div className="d-flex px-3" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text"
                                    className="form-control bg-white py-3"
                                    placeholder="Search Menu Management"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                    }} onKeyPress={(e) => {
                                        console.log('e.key', e.key)
                                    }}
                                />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 px-2">
                    <Select
                        placeholder={"Select Status"}
                        defaultValue={{ label: "All Status", value: "all" }}
                        options={dataStatus}
                        onChange={(e) => setValActivated(e.value)}
                    />
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive mt-4">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Menu Name</th>
                                    <th>Description</th>
                                    <th>Url</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataMenuManagement.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>{item?.name}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.description}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>{item?.apiUrl}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>
                                                    {
                                                        item?.activated ?
                                                            <span style={{ fontSize: '12px', background: '#43936C', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Active
                                                            </span>
                                                            :
                                                            <span style={{ fontSize: '12px', background: '#CB3A31', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Inactive
                                                            </span>
                                                    }
                                                </td>
                                                <td style={{ wordBreak: 'break-all', width: '120px' }} className='fix'>
                                                    {item?.activated ?
                                                        <Button
                                                            className="mr-2"
                                                            style={{ backgroundColor: '#FFF4F2', border: '1px solid #CB3A31' }}
                                                            disabled={loading}
                                                            onClick={() => updateStatus(item?.id, "disable")}>
                                                            <span style={{ color: '#CB3A31' }}>
                                                                Inactive
                                                            </span>
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="mr-2"
                                                            style={{ backgroundColor: '#EAF9F3', border: '1px solid #34c38f' }}
                                                            disabled={loading}
                                                            onClick={() => updateStatus(item?.id, "enable")}>
                                                            <span style={{ color: '#43936C' }}>
                                                                Active
                                                            </span>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit"
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <Modal className="font-family-spoqa" isOpen={modalMenuAdd} toggle={handleClose} size="md" centered={true}>
                <ModalHeader toggle={handleClose}>
                    <h2>Form Menu Management</h2>
                </ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <div className="mb-2">
                            Type Menu
                        </div>
                        <FormGroup>
                            <input
                                className="form-control"
                                value={formData.typeMenu}
                                placeholder="Type Menu Here"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        typeMenu: e.target.value
                                    })}
                                disabled={false}
                            />
                        </FormGroup>
                        <div className="mb-2">
                            Description
                        </div>
                        <FormGroup>
                            <textarea className='form-control'
                                style={{ resize: 'none' }}
                                value={formData.description}
                                rows={2}
                                onChange={(e) => {
                                    if (e.target.value.length <= 250) {
                                        setFormData({
                                            ...formData,
                                            description: e.target.value
                                        })
                                    } else {
                                        setFormData({
                                            ...formData,
                                            description: e.target.value.slice(0, 250)
                                        })
                                    }
                                }} />
                        </FormGroup>
                    </div>
                </ModalBody >
                <ModalFooter>
                    <Button
                        style={{ background: '#F37F26', borderColor: '#F37F26' }}
                        disabled={!formData.typeMenu || loading}
                        onClick={() => !formEdit ? postData() : postEditData()}>
                        <span className='mr-1'>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            {!formEdit ? 'Save' : 'Update'}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="font-family-spoqa" isOpen={modalMenuDelete} toggle={deleteHandleClose} size="md" style={{ marginTop: '15%' }}>
                <ModalHeader toggle={deleteHandleClose}>
                    <div className="w-100">
                        <h2 className="">Delete Confirmation</h2>
                        <h3 style={{ fontSize: '12px' }}>{`Are you sure to delete ?`}</h3>
                    </div>
                </ModalHeader>
                <div className="my-4">
                    <Row className='w-100'>
                        <Col lg={12} className="text-right">
                            <Button color="secondary" onClick={deleteHandleClose} className="mr-2">
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                            <Button color="success px-4" onClick={() => deleteData()} disabled={loading}>
                                {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(MenuManagement);
