import * as React from "react"
import { SVGProps } from "react"

const IconUseAsTemplate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M0.600098 1.1999C0.600098 0.758075 0.95827 0.399902 1.4001 0.399902H3.0001C3.44193 0.399902 3.8001 0.758075 3.8001 1.1999V10.7999C3.8001 11.2417 3.44193 11.5999 3.0001 11.5999H1.4001C0.95827 11.5999 0.600098 11.2417 0.600098 10.7999V1.1999Z" fill="white" />
        <path d="M5.4001 1.1999C5.4001 0.758075 5.75827 0.399902 6.2001 0.399902H7.8001C8.24192 0.399902 8.6001 0.758075 8.6001 1.1999V10.7999C8.6001 11.2417 8.24192 11.5999 7.8001 11.5999H6.2001C5.75827 11.5999 5.4001 11.2417 5.4001 10.7999V1.1999Z" fill="white" />
        <path d="M11.0001 0.399902C10.5583 0.399902 10.2001 0.758075 10.2001 1.1999V10.7999C10.2001 11.2417 10.5583 11.5999 11.0001 11.5999H12.6001C13.0419 11.5999 13.4001 11.2417 13.4001 10.7999V1.1999C13.4001 0.758075 13.0419 0.399902 12.6001 0.399902H11.0001Z" fill="white" />
    </svg>
)

export default IconUseAsTemplate
