import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument } from '../document/document.reducer';
import axios from 'axios';
import createBrowserHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';
import { getPreviewHTML } from 'app/utils/preview-pdf';
import { getApprovalPreviewHTML } from 'app/utils/preview-approval-pdf';
import './preview-style.scss'
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import {
  apiGetDataPreview,
  apiConverHTMLtoPDF,
  apiSendFirstStep,
  apiSendSecondStep
} from 'app/config/api-collection';

const history = createBrowserHistory({ forceRefresh: true });
export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }
export interface Props {
  getDocumentDetail: any,
  match: any,
  viewPDF: any,
  detaildocument: any,
  signDocument: any,
  approveDocument: any,
  blobPreview: any,
  filePDFSign: any,
  rejectDocument: any,
  PreviewPDFComponent: any,
  history: any,
  location: any
}
export const PreviewNewBackend: React.FC<Props> = (props) => {
  const [urlBlob, setUrlBlob] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [htmlTemp, setHtmlTemp] = useState<any>(null)
  const [fileNumberTemp, setFileNumberTemp] = useState<any>('')
  const [listFile, setListFile] = useState([])
  const [listLink, setListLink] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [fileType, setFileType] = useState("")
  const [fileId, setFileId] = useState("")

  useEffect(() => {
    let hasil = getDataPreview()
    setListFile(JSON.parse(props.location.state?.file))
    if (props.location.state.link) {
      setListLink(JSON.parse(props.location.state?.link))
    }
  }, []);

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl?.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const newSubmitDocument = async () => {
    await setLoading(true)
    const formData = new FormData();
    formData.append("data", props.location.state.data);
    listFile.map((item, index) => {
      if (!item.fileAttachmentId) {
        formData.append('fileAttachment', dataURLtoFile(item.file, item.fileName))
      }
    })
    listLink.map((data, index) => {
      formData.append("fileAttachmentLinks", data.value);
    })

    formData.append("type", fileType);
    formData.append("isMerge", props.location.state.isMerge);

    axios.put(`${apiSendFirstStep}`, formData)
      .then(res => {
        const formDataSecondStep = new FormData();
        formDataSecondStep.append("id", res.data.data.id);
        formDataSecondStep.append("fileNumberTemp", fileNumberTemp);
        formDataSecondStep.append('data', props.location.state.data)
        formDataSecondStep.append("html", htmlTemp);
        axios.put(`${apiSendSecondStep}`, formDataSecondStep)
          .then(res => {
            props.history.push('/?tab=5')
            setLoading(false)
            toast.success('Berhasil Membuat Dokumen', {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }).catch(async (err) => {
            setLoading(false)
            await toast.error(translate(`${err.response.data.message}`), {
              toastId: err.response.data.message,
              position: "top-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            await delayTime(3000)
            if (err.response.data.message == "error.errordocservice.message.error27" || err.response.data.message == "error.errordocservice.message.error28") {
              history.replace('/draft')
            }
          })
      }).catch(err => {
        setLoading(false)
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  const getDataPreview = async () => {
    axios.post(`${apiGetDataPreview}`, JSON.parse(props.location.state.data))
      .then(res => {
        setFileId(res.data.data.id)
        setFileType(res.data.data.type)
        setFileNumberTemp(res.data.data.fileNumber)
        getPreviewPDF(res.data.data, res.data.data.fileNumber)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const getPreviewPDF = (data, fileNumber) => {

      let html = data.type == "IMO" ? getPreviewHTML(data) : getApprovalPreviewHTML(data);
      setHtmlTemp(html);
      converHTMLtoPDF(html, fileNumber, data.type);

  }

  const converHTMLtoPDF = (html, fileNumber, type) => {
    const formData = new FormData();
    formData.append("html", html);
    formData.append('fileNumber', fileNumber)
    formData.append('data', props.location.state.data)

    if(type == "IMO") {

        let file = JSON.parse(props.location.state?.file)

        try {
          file.map(item => {
            if(item.file.includes(",")){
              formData.append('fileAttachmentList', dataURLtoFile(item.file, item.fileName))
            } else {
              formData.append('fileAttachmentList', dataURLtoFile("data:application/pdf;base64,"+item.file, item.fileName))
            }
          })
        } catch (e) {
          console.log(e)
        }
    }

    formData.append("isMerge", props.location.state.isMerge)

    axios.post(`${apiConverHTMLtoPDF}`, formData, { responseType: 'blob' })
      .then(res => {
        let createURL = URL.createObjectURL(res.data)
        setUrlBlob(createURL)
      })
      .catch(err => {
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl)
  }

  const goToBack = () => {
    if (fileType == "IMO") {
      props.history.push('/draft/create/edit', {
        file: listFile,
        link: listLink,
        data: props.location.state.data,
        toParse: props.location.state.toParse,
        ccParse: props.location.state.ccParse,
        effluentParse: props.location.state.effluentParse,
        screenerParse: props.location.state.screenerParse,
        isAdmin: props.location.state.isAdmin
      })
    } else {
      props.history.push('/draft/releaseapproval/create/edit', {
        file: listFile,
        link: listLink,
        data: props.location.state.data,
        qaParse: props.location.state.qaParse,
        itSecParse: props.location.state.itSecParse,
        uiUxParse: props.location.state.uiUxParse,
        hodParse: props.location.state.hodParse,
        prodOwnParse: props.location.state.prodOwnParse,
        viewerParse: props.location.state.viewerParse,
      })
    }
  }

  const downloadFilePDF = () => {
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = `DMS-${fileNumberTemp}.pdf`;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      document.body.removeChild(link);
    }, 100);
  }

  return (
    <div className="container font-family-spoqa">
      <Row>
        <Col>
          <h2>Draft</h2>
          <p>File Preview Draft</p>
        </Col>
      </Row>
      <div className="pdf" />
      {urlBlob ?
        <iframe src={`${urlBlob}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} />
        :
        <div className="shine photo"></div>
      }
      <div className="bg-white rounded shadow my-4 px-4 py-4">
        <h5>
          Attachment
        </h5>
        {
          listFile.length > 0 ? listFile.map((item, index) => {
            return (
              <div onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                <div >
                  <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{cursor:'pointer'}}>
                        {item.fileName} ( {bytesToSize(item.fileSize)} )
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }) : <>
          </>
        }
      </div>
      <div className="bg-white rounded shadow my-4 px-4 py-4">
        <h5>
          Link
        </h5>
        {
          listLink.length > 0 ? listLink.map((item, index) => {
            return (
              <div onClick={(e) => openInNewTab(item.value)}>
                <div >
                  <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {item.value}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }) : <>
          </>
        }
      </div>
      <Modal className="font-family-spoqa" isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => { setShowModal(false) }}>
        <ModalHeader toggle={handleClose} style={{ borderBottom: 'none' }}>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure to send document with number {fileNumberTemp} ?
          </h3>
          <div className="text-center mt-5">
            <button disabled={loading} className="btn btn-success mx-2 px-4" onClick={() => newSubmitDocument()}>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}   Yes
            </button >
            <button disabled={loading} className="btn btn-secondary mx-2"
              onClick={() => { setShowModal(false) }}>
              Cancel
            </button >
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
        </ModalFooter>
      </Modal>
      <Row className='mb-4 mt-4'>
        <Col className='text-left' xs="12" sm="12" md="6" lg="6">
        </Col>
        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          {fileNumberTemp != null
            ?
            <Button className="btn btn-secondary px-3 mr-2" onClick={downloadFilePDF} disabled={loading}>
              <FontAwesomeIcon icon={faFileDownload} />
              &nbsp;
              Download
            </Button>
            : <></>}

          <Button onClick={() => goToBack()} className='btn btn-secondary px-3 mr-2' disabled={loading}>
            Back
          </Button>
          <button className='btn btn-primary px-3' onClick={() => setShowModal(true)} disabled={loading}>
            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
            Send
          </button>
        </Col>
      </Row>
    </div>
  )

};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewNewBackend);
