import React, {useEffect, useRef, useState} from "react";
import { ResetPinOtp } from "./reset-pin-otp";
import { ResetPinSave } from "./reset-pin-save";
import { useTimer } from  "reactjs-countdown-hook";
import Page from "./reset-pin-util";
import Axios from "axios";
import { toast } from "react-toastify";
import { translate } from "react-jhipster";


export const ResetPin = () => {

    // 1. Page OTP
    const [etOTP, setOTP] = useState("")

    //State for otp timer and reset button
    const [getResendButtonColorOTP, setResendButtonColorOTP] = useState("#E0E0E0");
    const [getResendButtonTextColorOTP, setResendButtonTextColorOTP] = useState("#9E9E9E");
    const [isActiveResend, setActiveResend] = useState(false);
    const [loading, setLoading] = useState(false)

    //Three minutes OTP expired
    // @ts-ignore
    const {
        seconds,
        minutes,
        reset
    } = useTimer(180, handleTimerFinish);
    const handleSendOTP = () => {
        Axios.post('/services/uaadocservice/api/employee/reset-pin-send-otp')
            .then(res => {
                if(res.status == 200) {
                    toast.success('Success send otp', {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error('Failed to send pin otp', {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => {
            toast.error(`${err.response.data.message}`, {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };
    const handleVerifyOTP = (etOTP: string) => {
        setLoading(true)
        Axios.post('/services/uaadocservice/api/employee/verify-reset-pin-otp',{
            "otpCode" : etOTP
        })
            .then(res => {
                setLoading(false)
                if(res.status == 200) {
                    setPage(Page.PIN_SAVE)
                } else {
                    toast.error('Failed to verify otp', {
                        position: "top-left",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => {
            setLoading(false)
            toast.error(`${err.response.data.message}`, {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };

    useEffect(() => {
        if(etOTP.length == 6){
            handleVerifyOTP(etOTP)
        }
    },[etOTP])

    useEffect(() => {
        setPage(Page.PIN_OTP)
    }, []);

    function handleTimerFinish() {
        setResendButtonColorOTP("#F37F26");
        setResendButtonTextColorOTP("#FFFFFF");
        setActiveResend(true);
    }

    const disableButtonAfterSendOTP = () => {
        setResendButtonColorOTP("#E0E0E0");
        setResendButtonTextColorOTP("#9E9E9E");
        setActiveResend(false);
    }


    // 2. Page Save PIN
    const [getModalTitleMessage, setModalTitleMessage] = useState<any>("")
    const [getModalDescMessage, setModalDescMessage] = useState<any>("")
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState<any>("")
    const [showNewPasswordPIN, setShowNewPasswordPIN] = useState<boolean>(false)
    const [showConfirmPasswordPIN, setShowConfirmPasswordPIN] = useState<boolean>(false)
    const ref = useRef<HTMLInputElement[]>([]);
    const [getPin, setPin] = useState<any>("")
    const [getConfirmPin, setConfirmPin] = useState<any>("")
    const [isOpenModalPinSave, setOpenModalPinSave] = useState<any>("")
    const [isDisabledButton, setDisableButton] = useState<any>(false)

    const savePin = () => {
        Axios.put('/services/uaadocservice/api/personal-identity/save-pin-approval?pinApproval='+getConfirmPin)
            .then(res => {
                setModalTitleMessage("Success")
                setModalDescMessage("PIN has been reset.")
                setModalIllustrationStatus(true)
                setOpenModalPinSave(true)
            }).catch(err => {
            toast.error(translate(`${err.response.data.message}`), {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    //Page Transition
    const [whichPage, setPage] = useState(Page.PIN_OTP)

    const renderLayout = () => {
        switch(whichPage) {
            case Page.PIN_OTP:
                return <ResetPinOtp
                    handleTimerFinish={handleTimerFinish}
                    disableButtonAfterSendOTP={disableButtonAfterSendOTP}
                    handleSendOTP={handleSendOTP}
                    handleVerifyOTP={handleVerifyOTP}
                    etOTP={etOTP}
                    setOTP={setOTP}
                    seconds={seconds}
                    minutes={minutes}
                    reset={reset}
                    getResendButtonTextColorOTP={getResendButtonTextColorOTP}
                    getResendButtonColorOTP={getResendButtonColorOTP}
                    isActiveResend={isActiveResend}
                    setPage={setPage}
                    loading={loading}
                />;
            case Page.PIN_SAVE:
                return <ResetPinSave
                    isOpenModalPinSave={isOpenModalPinSave}
                    getModalIllustrationStatus={getModalIllustrationStatus}
                    getModalTitleMessage={getModalTitleMessage}
                    getModalDescMessage={getModalDescMessage}
                    setModalTitleMessage={setModalTitleMessage}
                    setModalDescMessage={setModalDescMessage}
                    setOpenModalPinSave={setOpenModalPinSave}
                    isDisabledButton={isDisabledButton}
                    ref={ref}
                    showNewPasswordPIN={showNewPasswordPIN}
                    setPin={setPin}
                    setShowNewPasswordPIN={setShowNewPasswordPIN}
                    setConfirmPin={setConfirmPin}
                    showConfirmPasswordPIN={showConfirmPasswordPIN}
                    setShowConfirmPasswordPIN={setShowConfirmPasswordPIN}
                    getPin={getPin}
                    getConfirmPin={getConfirmPin}
                    savePin={savePin}
                />
        }
    }

    return renderLayout();
}

export default ResetPin;
