import * as React from "react"
import { SVGProps } from "react"

const ReplaceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8.5 16H6.5C5.39543 16 4.5 15.1046 4.5 14V6C4.5 4.89543 5.39543 4 6.5 4H14.5C15.6046 4 16.5 4.89543 16.5 6V8M10.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V10C20.5 8.89543 19.6046 8 18.5 8H10.5C9.39543 8 8.5 8.89543 8.5 10V18C8.5 19.1046 9.39543 20 10.5 20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export default ReplaceIcon