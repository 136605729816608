import * as React from "react"
import { SVGProps } from "react"

const ToApproveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M29.6428 29.4289C29.6428 29.8835 29.4622 30.3195 29.1407 30.641C28.8192 30.9625 28.3832 31.1431 27.9285 31.1431H12.4999C12.0453 31.1431 11.6092 30.9625 11.2877 30.641C10.9663 30.3195 10.7856 29.8835 10.7856 29.4289V10.5717C10.7856 10.1171 10.9663 9.68102 11.2877 9.35952C11.6092 9.03803 12.0453 8.85742 12.4999 8.85742H21.0714L29.6428 17.4289V29.4289Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15.9287 22.5716L18.5001 24.2859L22.7859 17.4287" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToApproveIcon
