import * as React from "react"
import { SVGProps } from "react"

const RadioUnselectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 22.003C17.524 22.003 22.002 17.525 22.002 12.002C22.002 6.478 17.524 2 12 2C6.47605 2 1.99805 6.478 1.99805 12.002C1.99805 17.525 6.47605 22.003 12 22.003ZM12 20.503C9.74544 20.503 7.58318 19.6074 5.98893 18.0131C4.39469 16.4189 3.49905 14.2566 3.49905 12.002C3.49905 9.7474 4.39469 7.58513 5.98893 5.99089C7.58318 4.39664 9.74544 3.501 12 3.501C14.2548 3.501 16.4172 4.39669 18.0115 5.99103C19.6059 7.58537 20.5015 9.74776 20.5015 12.0025C20.5015 14.2572 19.6059 16.4196 18.0115 18.014C16.4172 19.6083 14.2548 20.504 12 20.504V20.503Z" fill="#9E9E9E"/>
  </svg>
)

export default RadioUnselectIcon
