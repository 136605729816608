import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import '../document/home.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import UnauthorizeImage from 'app/component/icon-unauthorize'

export const NewUnAuthorize = (props) => {

  return (
    <div className="font-family-spoqa" style={{ height: '90vh', width: '100%', verticalAlign: 'middle', alignContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column' }}>
      <div className="px-5 pt-4 text-center" >
        <UnauthorizeImage />
        <br></br>
      </div >
      <div className='my-3 text-center'>
        <h2 className='text-center' style={{ color: '#404040' }}>You don't have permission to access this page</h2>
        <button className='btn btn-primary' onClick={() => props.history.replace('/')}>Back</button>
      </div>
    </div>

  );
};

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NewUnAuthorize);
