import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}


export const AnnoucementDraft = (props: IHeaderProps) => {
    let history = useHistory();
    const [search, setSearch] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [loadingList, setLoadingList] = useState(false)
    const [showList, setShowList] = useState(false)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [sortType, setSortType] = useState('typeName.keyword,asc')
    const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
    const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
    const [sortDate, setSortDate] = useState('date,asc')
    const [limit, setLimit] = useState<any>(5)
    const [listAnnouncement, setListAnnouncement] = useState<any>([])
    const [showModal, setShowModal] = useState<any>(false)
    const [selectedItem, setItemSelected] = useState<any>(null)
    const [loadingDelete, setLoadingDelete] = useState<any>(false)
    const [isAdmin, setIsAdmin] = useState<Boolean>(false)
    const [isAnnounce, setIsAnnounce] = useState<Boolean>(false)

    useEffect(() => {
        // alert('data search', props.location.state.search)
        // alert(props.location.state.search)
        if (props.location.state?.search == '') {
            setListSearch([])
            setDefault()
        } else {
            getSearch(props.location.state?.search)
        }
    }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);

    useEffect(() => {
        // alert(props.location.state.search)
        getSearch(props.location.state?.search)
        getListAnnouncement(page, limit)
        getIsAdmin()
        getIsAnnounce()
    }, []);

    const setDefault = () => {
        setSortType('typeName.keyword,asc')
        setSortPriority('priority.keyword,asc')
        setSortLastModified('lastApprovedByName.keyword,asc')
        setSortDate('date,asc')
    }

    const getListAnnouncement = (page, limit) => {
        axios.get(`services/documentservice/api/announcement/draft?page=${page}&size=${limit}`)
            .then(res => {
                setListAnnouncement(res.data.data?.content)
                setTotalPage(res.data.data?.totalPages)
            })
    }

    const delayTime = (ms) => {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        )
    }

    const getIsAdmin = () => {
        axios.get('/services/uaadocservice/api/is-admin-dms')
            .then(res => {
                setIsAdmin(res.data)
            }).catch(err => {
                setIsAdmin(false)
            })
    }

    const getIsAnnounce = () => {
        axios.get('/services/uaadocservice/api/is-user-may-announce')
            .then(res => {
                setIsAnnounce(res.data)
            }).catch(err => {
                setIsAnnounce(false)
            })
    }

    const getSearch = async (e = '') => {
        if (e.length > 1) {
            setLoadingList(true)
            axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`)
                .then(async (result) => {
                    setListSearch(result.data.content)
                    setTotalPage(result.data?.totalPages)
                    await delayTime(2000)
                    setLoadingList(false)
                }).catch(err => {
                    setListSearch([])
                    setLoadingList(false)
                })
        }
    }

    const changeSortType = () => {
        if (sortType == 'typeName.keyword,asc') {
            setSortType('typeName.keyword,desc')
        } else {
            setSortType('typeName.keyword,asc')
        }
    }

    const changeSortPriority = () => {
        if (sortPriority == 'priority.keyword,asc') {
            setSortPriority('priority.keyword,desc')
        } else {
            setSortPriority('priority.keyword,asc')
        }
    }

    const changeSortLastModified = () => {
        if (sortLastModified == 'lastApprovedByName.keyword,asc') {
            setSortLastModified('lastApprovedByName.keyword,desc')
        } else {
            setSortLastModified('lastApprovedByName.keyword,asc')
        }
    }

    const changeSortDate = () => {
        if (sortDate == 'date,asc') {
            setSortDate('date,desc')
        } else {
            setSortDate('date,asc')
        }
    }

    const goToPage = (id) => {
        setSearch('')
        history.push(`/preview/only/pdf/${id}`)
    }

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        // getListDocumentHistori(value, limit)
        getListAnnouncement(value, limit)
    }

    const changeShow = (e) => {
        let show = e.target.value
        setPage(1)
        setLimit(show)
        getListAnnouncement(1, show)
        // getListDocumentHistori(1, show)
    }

    const handleClose = () => {
        setShowModal(false)
    };

    const openModalDelete = (item) => {
        setShowModal(true)
        setItemSelected(item)
    }

    const deleteFile = (id) => {
        setLoadingDelete(true)
        axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
            setLoadingDelete(false)
            toast.success('Success Delete Annoucement', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowModal(false)
            setItemSelected(null)
            setPage(1)
            setLimit(5)
            getListAnnouncement(1, 5)
        }).catch(err => {
            setLoadingDelete(false)
            toast.error('Failed Delete Announcement', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowModal(false)
        })
    }

    const goToCreate = () => {
        history.push('/announcement/create')
    }

    const goToDraft = () => {
        history.push('/announcement/draft')
    }

    const goToDetail = (data) => {
        axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`)
            .then((res) => {
                history.push(`/announcement/view/${data.id}`)
            }).catch((err) => {
                history.push(`/announcement/view/${data.id}`)
            })
    }

    const goToView = (id) => {
        history.push(`/announcement/edit/${id}`)
    }

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <button className="btn px-0" onClick={() => history.goBack()}>
                <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
            </button>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-28">Announcement Draft</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Mirae Asset Sekuritas Announcement Management</h1>
                    </div>
                </div>
            </div>
            {/* {
                isAdmin || isAnnounce ? <div className="font-family-spoqa btn btn-primary rounded-pill" onClick={() => goToCreate()}>
                    <AccountAnnounceIcon className="mr-1" /> Create Announcement
                </div> : <></>
            } */}
            <div className="my-3 bg-light" style={{ borderRadius: 30 }}>
                <table className="table table-centered table-nowrap mb-0 table-responsive" style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}>
                    <thead className="" style={{ background: '#fff', borderTopLeftRadius: 30, borderTopRightRadius: 30 }}>
                        <tr>
                            <th className='font-family-spoqa py-4 px-4' style={{ borderTopLeftRadius: 30 }}>Created Date</th>
                            <th className="font-family-spoqa px-4">Posted Date</th>
                            <th className="font-family-spoqa px-4">Judul</th>
                            <th className="font-family-spoqa px-4">Pengirim</th>
                            <th className="font-family-spoqa px-4 text-center fix" style={{ borderTopRightRadius: 30, width: '4%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody className="" style={{ background: '#fff' }}>
                        {
                            listAnnouncement.map((item, index) => {
                                return (
                                    <tr key={index} className="" style={item.isRead ? { background: '#FFF', cursor: 'pointer' } : { background: '#FFF4EB', cursor: 'pointer' }}>
                                        <td className="font-family-spoqa" onClick={() => goToDetail(item)}>
                                            {item?.createdDateFormat}
                                        </td>
                                        <td className="font-family-spoqa" style={{ wordBreak: 'break-all', minWidth: '300px', maxWidth: '400px', whiteSpace: 'pre-wrap' }} onClick={() => goToDetail(item)}>
                                            {item?.postedDateFormat}
                                        </td>
                                        <td className="font-family-spoqa" style={{ wordBreak: 'break-all', minWidth: '300px', maxWidth: '500px', whiteSpace: 'pre-wrap' }} onClick={() => goToDetail(item)}>
                                            {item?.title}
                                        </td>
                                        <td className="font-family-spoqa" onClick={() => goToDetail(item)}>
                                            {item?.senderBranchName} - {item?.senderEmployeeName}
                                        </td>
                                        {
                                            item.canDelete || item.canEdit ?
                                                <td className='fix'>
                                                    {item.canDelete ? <>
                                                        <div className="font-family-spoqa btn btn-danger mx-2" onClick={() => openModalDelete(item)}>
                                                            <FontAwesomeIcon icon="trash" />{' '}Delete
                                                        </div>
                                                    </> : <></>}
                                                    {item.canEdit ? <>
                                                        <div className="font-family-spoqa btn btn-warning mx-2" onClick={() => goToView(item.id)}>
                                                            <FontAwesomeIcon icon="pencil-alt" />{' '} Edit
                                                        </div>
                                                    </> : <></>}
                                                </td> : <td className='fix' onClick={() => goToDetail(item.id)}>

                                                </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="form-group row">
                        <label htmlFor="priority" className="font-family-spoqa col-sm-1 col-form-label">Show</label>
                        <div className="font-family-spoqa" style={{ width: '80px' }}>
                            <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)}>
                                <option value="2">2</option>
                                <option value="5">5</option>
                                <option value="10" selected>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <label htmlFor="priority" className="font-family-spoqa col-sm-2 col-form-label">Entries</label>
                    </div>
                </div>
                <div className="font-family-spoqa col-12 col-md-6 col-lg-6" style={{ justifyContent: 'end', display: 'flex' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                </div>
            </div>
            <Modal isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
                setShowModal(false)
                setItemSelected(null)
            }}>
                <ModalHeader toggle={handleClose} style={{ borderBottom: 'none' }}>
                </ModalHeader>
                <ModalBody id="documenttestApp.document.delete.question">
                    <h3 className="text-center">
                        Are you sure want to delete <span className="font-weight-bold">"{selectedItem?.title}"</span> announcement ?
                    </h3>
                    <div className="text-center mt-5">
                        <button className="btn btn-success mx-2 px-4" onClick={() => deleteFile(selectedItem?.id)} disabled={loadingDelete}>
                            {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}   Yes
                        </button >
                        <button disabled={loadingDelete} className="btn btn-secondary mx-2" onClick={() => {
                            setShowModal(false);
                            setItemSelected(null)
                        }}>
                            Cancel
                        </button >
                    </div>
                </ModalBody>
                <ModalFooter style={{ borderTop: 'none' }}>
                </ModalFooter>
            </Modal>

        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(AnnoucementDraft);
