import * as React from "react"
import { SVGProps } from "react"

const PencilIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.869.869a1.6 1.6 0 0 1 2.262 2.263l-.634.634-2.263-2.263L8.87.87ZM7.103 2.635.4 9.338V11.6h2.263l6.703-6.703-2.263-2.262Z"
      fill="#fff"
    />
  </svg>
)

export default PencilIcon
