import React, { useEffect, useState } from 'react';
import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import './styles.scss';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import LevelOrganizationAdd from 'app/component/level-organization-add';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]

export const LevelOrganization = (props: IHeaderProps) => {
    
    const initialFormData = {
        idOrganization: "0",
        levelOrganization: "",
        nameOrganization: ""
    }

    const dataStatus = [
        {
            label: "All Status",
            value: "all"
        },
        {
            label: "Active",
            value: "true"
        },
        {
            label: "Inactive",
            value: "false"
        }
    ]

    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [formData, setFormData] = useState(initialFormData);
    const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
    const [formEdit, setFormEdit] = useState(false)
    const [showModalDel, setShowModalDel] = useState<any>(false)
    const [modalLevelOrganizationAdd, setModalLevelOrganizationAdd] = useState<any>(false)
    const [fullname, setFullName] = useState<any>("")
    const [iddelete, setIdDelete] = useState<any>(null)
    const [loadingDelete, setLoadingDelete] = useState<any>(false)
    const [valActivated, setValActivated] = useState<any>("all");
    const [sort, setSort] = useState<any>("levelOrganizationCode")
    const [ascending, setAscending] = useState<any>(true)
    const [addStatus, setAddStatus] = useState<any>("")

    useEffect(() => {
        getLevelOrganization()
    }, [page, size, totalPage, keyword, valActivated, sort, ascending]);

    const getLevelOrganization = () => {
        var valAsc = ascending ? 'asc' : 'desc';
        axios.get(`services/uaadocservice/api/level-organization/get-list?keyword=${keyword}&page=${page}&size=${size}&statusFilter=${valActivated}&sort=${sort + ',' + valAsc}`)
            .then((res) => {
                setDataLevelOrganization(res.data.data.content)
                setTotalPage(res.data.data.totalPages)
            }).catch((err) => {
                toast.error(translate(`${err.response.data.message}`))
            })
    }

    const onChangeStatus = (event) => {
        setAddStatus(event.target.value)
    }

    const postData = () => {
        if (formData.levelOrganization == "0") {
            toast.warn('Level organization must more than 0', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (formData.levelOrganization == "" || formData.nameOrganization == "") {
            toast.warn('Level Organization or Name Organization not empty', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (formData.levelOrganization < '0') {
            toast.warn(`Level Organization can't less then 0`, {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (addStatus == "") {
            toast.warn(`Status cannot empty`);
        } else {
            setLoading(true)
            const formDataFinal = new FormData();
            let dataFormData = {
                id: formData.idOrganization,
                levelOrganizationCode: formData.levelOrganization,
                levelOrganizationName: formData.nameOrganization,
                isActive: addStatus == "Active" ? true : false
            }
            formDataFinal.append("data", JSON.stringify(dataFormData));
            axios.put('services/uaadocservice/api/level-organization/save', formDataFinal).then(res => {
                toast.success('Success Add Data Level Organization', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setModalLevelOrganizationAdd(false)
                setLoading(false)
                getLevelOrganization()
                setFormData({ ...formData, levelOrganization: "", nameOrganization: "" })
            }).catch(err => {
                setModalLevelOrganizationAdd(false)
                toast.error(translate(`${err.response.data.message}`));
                setLoading(false)
                setFormData({ ...formData, levelOrganization: "", nameOrganization: "" })
            })
        }
    }

    const editLevelOrganization = (valId, valCode, valName, valStatus) => {
        setFormData({
            ...formData,
            idOrganization: valId,
            levelOrganization: valCode,
            nameOrganization: valName
        })
        setAddStatus(valStatus)
        setFormEdit(!formEdit)
        setModalLevelOrganizationAdd(!modalLevelOrganizationAdd)
    }

    const postEditData = () => {
        if (formData.levelOrganization == "0") {
            toast.warn('Level organization must more than 0', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (formData.levelOrganization == "" || formData.nameOrganization == "") {
            toast.warn('Level organization or Name Organization not empty', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (formData.levelOrganization < '0') {
            toast.warn(`Level Organization can't less then 0`, {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            setLoading(true)
            const formDataFinal = new FormData();
            let editFormData = {
                id: formData.idOrganization,
                levelOrganizationCode: formData.levelOrganization,
                levelOrganizationName: formData.nameOrganization,
                isActive: addStatus == "Active" ? true : false
            }
            formDataFinal.append("data", JSON.stringify(editFormData));
            axios.put(`services/uaadocservice/api/level-organization/save`, formDataFinal).then(res => {
                toast.success('Success Edit Level Organization', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setModalLevelOrganizationAdd(false)
                getLevelOrganization()
                setFormEdit(!formEdit)
                setLoading(false)
                setFormData({ ...formData, levelOrganization: "", nameOrganization: "" })
            }).catch(err => {
                toast.error(translate(`${err.response.data.message}`));
                setModalLevelOrganizationAdd(false)
                getLevelOrganization()
                setFormEdit(!formEdit)
                setLoading(false)
                setFormData({ ...formData, levelOrganization: "", nameOrganization: "" })
            })
        }
    }

    const deleteOrganization = () => {
        setLoadingDelete(true)
        axios.put(`services/uaadocservice/api/level-organization/delete?id=${iddelete}`)
        .then(res => {
            setShowModalDel(false)
            setIdDelete(null)
            setLoadingDelete(false)
            getLevelOrganization()
            toast.success('Success Delete Level organization', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            setLoadingDelete(false)
            toast.error('Failed Delete Level organization', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const handleCloseLevelOrganizationAdd = () => {
        setModalLevelOrganizationAdd(false)
        setFormData({ ...formData, idOrganization: "0", levelOrganization: "", nameOrganization: "" })
    }

    const handleCloseDel = () => {
        setShowModalDel(false)
    }

    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Level Organization</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Mirae Asset DMS - Level Organization</h1>
                    </div>
                </div>
            </div>
            <div className='row my-3'>
                <div className="col-12 col-md-3 col-lg-3">
                    <div className='btn btn-primary w-90 py-2' onClick={(data) => setModalLevelOrganizationAdd(true)}>
                        <LevelOrganizationAdd /> Buat Level Organization
                    </div>
                </div>
            </div>
            <div className='row my-3'>
                <div className="col-12 col-md-9 col-lg-9 px-2">
                    <div className="app-search w-100 py-0 bg-white">
                        <div className="position-relative">
                            <input type="text"
                                className="form-control bg-white py-3"
                                placeholder="Search level organization"
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        getLevelOrganization()
                                    } else {
                                        setKeyword(e.target.value)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getLevelOrganization()
                                    }
                                }} />
                            <span className="bx bx-search-alt my-0"></span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 px-2">
                    <Select
                        placeholder={"Select Status"}
                        defaultValue={{ label: "All Status", value: "all" }}
                        options={dataStatus}
                        onChange={(e) => setValActivated(e.value)}
                    />
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('levelOrganizationCode')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Level Organization Code</span>
                                            <span className='mt-1'>
                                                {(sort == "levelOrganizationCode" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "levelOrganizationCode" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "levelOrganizationCode") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('levelOrganizationName')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Level Organization Name</span>
                                            <span className='mt-1'>
                                                {(sort == "levelOrganizationName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "levelOrganizationName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "levelOrganizationName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('isActive')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Status</span>
                                            <span className='mt-1'>
                                                {(sort == "isActive" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "isActive" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "isActive") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataLevelOrganization ? dataLevelOrganization.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>{item.levelOrganizationCode}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item.levelOrganizationName}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>
                                                    {
                                                        item?.status == "Active"?
                                                            <span style={{ fontSize: '12px', background: '#43936C', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Active
                                                            </span>
                                                            :
                                                            <span style={{ fontSize: '12px', background: '#CB3A31', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Inactive
                                                            </span>
                                                    }
                                                </td>
                                                <td style={{ wordBreak: 'break-all', width: '10%' }} className='fix'>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                                        <button
                                                            className='btn mr-2'
                                                            style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                                                            onClick={() => editLevelOrganization(item.levelOrganizationId, item.levelOrganizationCode, item.levelOrganizationName, item.status)}
                                                        >
                                                            <FontAwesomeIcon icon="pencil-alt" />
                                                            {' '}
                                                            Edit
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit"
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <Modal className="font-family-spoqa" isOpen={modalLevelOrganizationAdd} toggle={handleCloseLevelOrganizationAdd} size="md" centered={true}>
                <ModalHeader toggle={handleCloseLevelOrganizationAdd}>Form Level Organization</ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <div className="mb-2">
                            Level Organization
                        </div>
                        <FormGroup>
                            <input
                                className="form-control"
                                value={formData.levelOrganization}
                                placeholder="Level Organization"
                                type='number'
                                min="1"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        levelOrganization: e.target.value.slice(0, 5)
                                    })
                                }
                            />
                        </FormGroup>
                        <div className="mb-2">
                            Name
                        </div>
                        <FormGroup>
                            <input
                                className="form-control"
                                value={formData.nameOrganization}
                                placeholder="name"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        nameOrganization: e.target.value
                                    })}
                            />
                        </FormGroup>
                        <div className="mb-2">
                            Status
                        </div>
                        <FormGroup>
                            <div className="row">
                                <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                                    <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input type="radio"
                                        checked={addStatus === "Active"}
                                        value={"Active"}
                                        />
                                        <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                                            Active
                                        </span>
                                    </label>
                                </div>
                                <div className="col-12 col-md-2 col-lg-2 px-3"  onChange={event => onChangeStatus(event)}>
                                    <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input type="radio"
                                        checked={addStatus === "Inactive"}
                                        value={"Inactive"}
                                        />
                                        <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                                            Inactive
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                </ModalBody >
                <ModalFooter>
                    <Button
                        style={{ background: '#F37F26', borderColor: '#F37F26' }}
                        disabled={loading}
                        onClick={() => !formEdit ? postData() : postEditData()}
                    >
                        <span className='mr-1'>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            {!formEdit ? 'Save' : 'Update'}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="font-family-spoqa" isOpen={showModalDel} toggle={handleCloseDel} size="md" style={{ marginTop: '15%' }}>
                <ModalHeader toggle={handleCloseDel}>
                    <div className="w-100">
                        <h2 className="">Delete Confirmation</h2>
                        <h3 style={{ fontSize: '12px' }}>{`Apakah anda yakin menghapus ${fullname} ?`}</h3>
                    </div>
                </ModalHeader>
                <div className="my-4">
                    <Row className='w-100'>
                        <Col lg={12} className="text-right">
                            <Button color="secondary" onClick={handleCloseDel} className="mr-2">
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                            <Button color="success px-4" onClick={() => deleteOrganization()} disabled={loadingDelete}>
                                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(LevelOrganization);