import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { error } from 'console';
import moment from 'moment';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

export const KaryawanAddManual = (props: IHeaderProps) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [startDate, setStartDate] = useState(new Date());
  const [listDepartment, setListDepartment] = useState<any>([])
  const [addField, setAddField] = useState<any>([{ "levelOrganization": "", "groupId": "", "position": "", "jobLevel": "" }])
  const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
  const [dataOrganization, setDataOrganization] = useState<any>([])
  const [addStatus, setAddStatus] = useState<any>("Active")

  useEffect(() => {
    getLevelOrganization()
  }, [page, size, totalPage, dataOrganization]);

  const KaryawanSchema = Yup.object().shape({
    nik: Yup.string()
      .required("NIK is required")
      .matches(/^\d+$/, 'The field should have number only'),
    fullname: Yup.string()
      .required("Fullname is required")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
    email: Yup.string().email()
      .required("Email is required"),
    phonenumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d+$/, 'The field should have number only'),
    date: Yup.string()
      .required("Date is required"),
  });

  const getListNotification = () => {
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`).then((res) => {
      setTotalPage(res.data.data.totalPages)
    }).catch((err) => {
    })
  }

  const getDepartmentList = () => {
    let groupPayload = {
      page: 0,
      limit: 1000,
      keyword: ""
    }
    axios.post('services/uaadocservice/api/group/list', groupPayload)
      .then(res => {
        setListDepartment(res.data.data.groups)
      }).catch(err => {
        console.log(err)
      })
  }

  const onChangeStatus = (event) => {
    setAddStatus(event.target.value)
  }

  // member of groups
  const getLevelOrganization = () => {
    axios.get(`/services/uaadocservice/api/level-organization/get-all-active?levelOrganizationId=0`).then((res) => {
      const response = res.data
      const newLevel = response.map((obj, i) => ({ label: obj.levelOrganizationName, value: obj.id }));
      setDataLevelOrganization(newLevel)
    }).catch((err) => {
      throw new Error('getLevelOrganization')
    })
  }

  const getOrganization = (i, valLevelOrganization) => {
    axios.get(`/services/uaadocservice/api/group/get-by-level-organization?levelOrganizationId=${valLevelOrganization}`).then((res) => {
      const response = res.data
      const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
      const newArray = [...dataOrganization];
      newArray.splice(i, 1, newOrganization);
      setDataOrganization(newArray);
    }).catch((err) => {
      throw new Error('getOrganization')
    })
  }

  const addFormFields = () => {
    setAddField([...addField, { "levelOrganization": "", "groupId": "", "position": "", "jobLevel": "" }])
  }

  const removeFormFields = (i) => {
    let newFormValues = [...addField];
    newFormValues.splice(i, 1);
    setAddField(newFormValues)
    removeOrganization(i)
  }

  const removeOrganization = (i) => {
    let newDataOrganization = [...dataOrganization];
    newDataOrganization.splice(i, 1);
    setDataOrganization(newDataOrganization)
  }

  const handleSelect = async (type, i, e) => {
    let data = [...addField];
    if (type == 'levelOrganization') {
      data[i].levelOrganization = e.value;
      getOrganization(i, e.value)
    }
    if (type == 'groupId') {
      data[i].groupId = e.value;
    }
    if (type == 'position') {
      data[i].position = e;
    }
    if (type == 'jobLevel') {
      if (e == "0") {
        toast.warn('Level organization must more than 0', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        data[i].jobLevel = e.slice(0, 5);
      }
    }
    setAddField(data)
  }

  const submitDataKaryawan = (data) => {
    const validation = addField.every(item => item.levelOrganization && item.groupId && item.position && item.jobLevel)
    if (data.nik < 0 || data.phonenumber <= 0) {
      toast.warn('Fill in the data NIK/Phone number correctly');
    } else if (!validation) {
      toast.warn('Fill in the data Member of Groups correctly');
    } else {
      setLoading(true)
      const formData = new FormData();
      let payload = {
        id: "0",
        employeeNo: data.nik.toString(),
        fullName: data.fullname,
        email: data.email,
        phoneNumber: data.phonenumber.toString(),
        joinDate: moment(data.date).format('DD/MM/YYYY'),
        flagActive: addStatus == "Active" ? true : false,
        groups: addField
      }
      formData.append("data", JSON.stringify(payload));
      axios.put('/services/uaadocservice/api/personal-identity/karyawan/save', formData).then(res => {
        console.log('submit', res)
        toast.success('Success Add Employee', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
        history.push('/karyawan')
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false)
      })
    }
  }

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Tambah Data Karyawan</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
          </div>
        </div>
      </div>
      <div className="row" style={{ height: "730px" }}>
        <Formik
          initialValues={{ nik: "", fullname: "", date: "", email: "", phonenumber: "", position: "" }}
          validationSchema={KaryawanSchema}
          onSubmit={(values) => {
            submitDataKaryawan(values)
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) =>
          (
            <div>
              <Form>
                <div className="card bg-white px-5 py-5">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Nomor Identitas Karyawan (NIK)
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <Field
                        type="number"
                        name="nik"
                        placeholder="Nomor Identitas Karyawan"
                        autocomplete="off"
                        className={`mt-2 form-control py-2
                          ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="nik"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Full Name
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <Field
                        type="text"
                        name="fullname"
                        placeholder="Masukkan Nama"
                        autocomplete="off"
                        className={`mt-2 form-control py-2
                          ${touched.fullname && errors.fullname ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="fullname"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Email
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Masukkan Email"
                        autocomplete="off"
                        className={`mt-2 form-control py-2
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Phone Number
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <Field
                        type="number"
                        name="phonenumber"
                        placeholder="Masukkan Phone Number"
                        autocomplete="off"
                        className={`mt-2 form-control py-2
                          ${touched.phonenumber && errors.phonenumber ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="phonenumber"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Join Date
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">
                      <DatePicker
                        name="date"
                        selected={values.date}
                        onChange={(date: Date) => setFieldValue('date', date)}
                        className={`mt-2 form-control py-2 px-0 mx-0
                         ${touched.date && errors.date ? "is-invalid" : ""}`}
                      />
                      <div className="text-danger mt-3" style={{ fontSize: '10px' }}>
                        {touched.date && errors.date ? errors.date : ""}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Status
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9">

                      <div className="row">
                        <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                          <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                            <input type="radio"
                              checked={addStatus === "Active"}
                              value={"Active"}
                            />
                            <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                              Active
                            </span>
                          </label>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                          <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                            <input type="radio"
                              checked={addStatus === "Inactive"}
                              value={"Inactive"}
                            />
                            <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                              Inactive
                            </span>
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-16">
                          Member of Groups
                          <span className="required" style={{ color: '#ff0000' }}>*</span>
                        </h1>
                      </div>
                    </div>
                  </div>
                  {addField.map((element, index) => {
                    let valLength = addField.length - 1;
                    return (
                      <div key={index} className="row mb-2">
                        <div className="col-12 col-sm-12 col-md-3">
                          <Select
                            name="group"
                            value={dataLevelOrganization.filter((function (option: any) {
                              return option.value == element.levelOrganization
                            }))}
                            placeholder="Level Organization"
                            options={dataLevelOrganization}
                            onChange={(e) => handleSelect('levelOrganization', index, e)}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                          <Select
                            name="group"
                            value={dataOrganization[index]?.filter((function (option: any) {
                              return option.value == element.groupId
                            }))}
                            placeholder="Organization"
                            options={dataOrganization[index]}
                            onChange={(e) => handleSelect('groupId', index, e)}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                          <input
                            className="form-control"
                            placeholder="Position"
                            value={element.position}
                            onChange={(e) =>
                              handleSelect('position', index, e.target.value)
                            }
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-2">
                          <input
                            type='number'
                            className="form-control"
                            placeholder="Job Level"
                            value={element.jobLevel}
                            onChange={(e) =>
                              handleSelect('jobLevel', index, e.target.value)
                            }
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-1">
                          {
                            valLength == index ?
                              <Button style={{ display: 'flex', alignSelf: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }} onClick={() => addFormFields()} className="mr-2">
                                <span style={{ color: '#F5F5F5' }}>+</span>
                              </Button>
                              :
                              <Button color='#CB3A31' style={{ display: 'flex', alignSelf: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }} onClick={() => removeFormFields(index)} className="mr-2">
                                <span style={{ color: '#F5F5F5' }}>-</span>
                              </Button>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
                <Row className='mb-4 mt-4'>
                  <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                  </Col>
                  <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                    <button className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} disabled={loading ? true : false} onClick={() => history.goBack()}>
                      {/* {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} */}
                      Back </button>
                    <button className='btn btn-primary px-5' type="submit" disabled={loading}>
                      {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                      Simpan </button>
                  </Col>
                </Row>
              </Form>
            </div>
          )
          }
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(KaryawanAddManual);