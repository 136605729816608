import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId,  getDocumentDetail, postDocument } from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }


export interface Props {
  getDocumentDetail: any,
  match: any,
  viewPDF: any,
  detaildocument: any,
  signDocument: any,
  approveDocument: any,
  blobPreview: any,
  filePDFSign: any,
  rejectDocument: any,
  PreviewPDFComponent: any,
  postDocument: any
}

export const PreviewDraft: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);

 
  useEffect(() => {
    props.getDocumentDetail(props.match.params.id);
  }, []);

  const submit = () => {
  pdf(<PreviewPDFComponent 
      from={props.detaildocument?.senderBranchName}
      title={props.detaildocument?.fileNumber}
      regarding={props.detaildocument?.regarding}
      date={props.detaildocument?.date}
      to={props.detaildocument?.to}
      fileContent={props.detaildocument?.fileContent}
      cc={props.detaildocument?.cc}
      dataDocument={props.detaildocument}
    />).toBlob().then(res => {
      props.postDocument(res)
    })
    // props.postDocument(fileblob)
  }


  let datadetail = props.detaildocument
  // let datafinal = JSON.parse(datadetail)
  return (
    <div className="container">
        <Row>
          <Col>
          <h2>Draft</h2>
           <p>File Preview Draft</p>
          </Col>
          <Col className='text-right'>
            <button className='btn btn-primary mr-2'>Kembali</button>
            <button className='btn btn-primary' onClick={() => submit()}>Print Document</button>
          </Col>
        </Row>
       <PDFViewer style={{width: '100%', height: '1000px'}} >
        <PreviewPDFComponent 
          from={props.detaildocument?.senderBranchName}
          title={props.detaildocument?.fileNumber}
          regarding={props.detaildocument?.regarding}
          date={props.detaildocument?.date}
          to={props.detaildocument?.to}
          fileContent={props.detaildocument?.fileContent}
          cc={props.detaildocument?.cc}
          dataDocument={props.detaildocument}
        />
      </PDFViewer>
      <div>
          <BlobProvider document={
            <PreviewPDFComponent 
            from={props.detaildocument?.senderBranchName}
            title={props.detaildocument?.fileNumber}
            regarding={props.detaildocument?.regarding}
            date={props.detaildocument?.date}
            to={props.detaildocument?.to}
            fileContent={props.detaildocument?.fileContent}
            cc={props.detaildocument?.cc}
            dataDocument={props.detaildocument}
            />
          }>
            {({ blob, url, loading, error }) => {
              // Do whatever you need with blob here
              // setFileblob(blob)
              return <div>There's something going on on the fly</div>;
            }}
          </BlobProvider>
  </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDraft);
