import * as React from "react"
import { SVGProps } from "react"

const XCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8ZM6.966 5.834a.8.8 0 1 0-1.132 1.132L6.87 8 5.834 9.034a.8.8 0 0 0 1.132 1.132L8 9.13l1.034 1.035a.8.8 0 0 0 1.132-1.132L9.13 8l1.035-1.034a.8.8 0 0 0-1.132-1.132L8 6.87 6.966 5.834Z"
      fill="#fff"
    />
  </svg>
)

export default XCircleIcon
