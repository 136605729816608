import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import '../document/home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

// import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from './document.reducer';
import axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
// import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotFoundImage from 'app/component/notfound';

import UnauthorizeImage from 'app/component/icon-unauthorize'



export const NewNotFound = (props) => {

  useEffect(() => {
    console.log('NewNotFound', props)
  }, []);

  const { documentList, loading } = props;
  return (
    <div className="font-family-spoqa" style={{ height: '90vh', width: '100%', verticalAlign: 'middle', alignContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column' }}>
      <div className="px-5 pt-4 text-center" >
        <NotFoundImage />
        <br></br>
      </div >
      <div className='my-3 text-center'>
        <h2 className='text-center' style={{ color: '#404040' }}>Oops, we were unable to find that page</h2>
        <p className='text-center'>Please check your network or try again later</p>
        <button className='btn btn-primary' onClick={() => props.history.replace('/')}>Back</button>
      </div>
    </div>

  );
};

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NewNotFound);
