import * as React from "react"
import { SVGProps } from "react"

const CloseDeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2ZM10.448 8.752a1.2 1.2 0 1 0-1.697 1.697L10.303 12 8.75 13.552a1.2 1.2 0 1 0 1.697 1.697L12 13.697l1.551 1.552a1.2 1.2 0 0 0 1.697-1.697L13.697 12l1.551-1.551a1.2 1.2 0 1 0-1.697-1.697L12 10.303l-1.552-1.551Z"
      fill="#CB3A31"
    />
  </svg>
)

export default CloseDeleteIcon 
