import React, { useEffect } from 'react';

interface GoogleAnalyticsProps {
    trackingId: string;
}

const GoogleAnalytics = ({ trackingId }: GoogleAnalyticsProps) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', trackingId);
    }, [trackingId]);

    return null; // This component doesn't render any visible UI
};

export default GoogleAnalytics;