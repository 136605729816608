import * as React from "react"

const EyeSmallIconDarkHide = (props) => (
  <svg
    width={16}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.966.834a.8.8 0 0 0-1.132 1.132l11.2 11.2a.8.8 0 1 0 1.132-1.132l-1.179-1.178A8.012 8.012 0 0 0 15.634 7 8.004 8.004 0 0 0 4.39 2.26L2.966.834Zm3.409 3.41 1.21 1.21a1.602 1.602 0 0 1 1.961 1.96l1.211 1.212a3.2 3.2 0 0 0-4.383-4.383Z"
      fill="#9E9E9E"
    />
    <path
      d="M9.963 12.357 7.8 10.194A3.2 3.2 0 0 1 4.806 7.2L1.868 4.262A7.984 7.984 0 0 0 .366 7a8.004 8.004 0 0 0 9.597 5.357Z"
      fill="#9E9E9E"
    />
  </svg>
)

export default EyeSmallIconDarkHide
