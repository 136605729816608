import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import PinField from "react-pin-field";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {Modal, ModalBody, Button, Spinner} from 'reactstrap';
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";

export const ResetPinSave = (props) => {

    const history = useHistory()

    return (
        <div className="container text-center">
            <div className='container d-flex  h-100' style={{alignItems: 'center', justifyContent: 'center'}}>

                <Modal style={{top:"25%"}} isOpen={props.isOpenModalPinSave}>
                    <ModalBody>
                        <div style={{padding:"16px"}}>
                            <div className="text-center mb-4">{props.getModalIllustrationStatus? <SuccessIllustration/> : <FailedIllustration/>}</div>
                            <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{props.getModalTitleMessage}</p>
                            <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{props.getModalDescMessage}</p>
                            <div className="d-flex justify-content-center" onClick={ () => {
                                props.setModalTitleMessage("");
                                props.setModalDescMessage("");
                                props.setOpenModalPinSave(false);
                            }}>
                                <Button disabled={props.isDisabledButton} onClick={()=> {
                                    if (props.getModalIllustrationStatus) {
                                        props.setOpenModalPinSave(false)
                                        history.replace("/profile/me")
                                    } else {
                                        props.setOpenModalPinSave(false)
                                    }
                                }} type="submit" className="py-2 h2 text-white font-weight-bold rounded"
                                        style={
                                            {
                                                backgroundColor: '#0F2D3C',
                                                width: '120px',
                                            }
                                        }>
                                    {(props.isDisabledButton)? <Spinner color='#fff' size="sm" style={{marginRight: "8px"}}/> : <></>}
                                    OK
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <div className="" style={{padding: '10px', width: '70%'}}>
                    <div className='d-flex'>
                        <button className="btn px-0 mr-4" onClick={() => history.replace('/profile/pin/change')}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057"/>{' '}
                        </button>
                        <h2 className='mb-0'>
                            Create New PIN
                        </h2>
                    </div>


                    <div className='mt-10 text-left'>
                        <div>
                            <h5 className='px-3' style={{marginTop: '50px'}}>
                                Add New PIN
                            </h5>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="row" style={{marginTop: '20px'}}>
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <PinField
                                            ref={props.ref}
                                            className="ml-3 bg-transparent pin-field"
                                            type={props.showNewPasswordPIN ? 'text' : 'password'}
                                            validate={/^[0-9]$/}
                                            onChange={(e) => props.setPin(e)}
                                            length={6}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 eyepin">
                                        {
                                            !props.showNewPasswordPIN ?
                                                <button className="btn px-0"
                                                        onClick={() => props.setShowNewPasswordPIN(!props.showNewPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEyeSlash} style={{fontSize: "20px"}}
                                                                     color="#9E9E9E"/>
                                                </button>
                                                :
                                                <button className="btn px-0"
                                                        onClick={() => props.setShowNewPasswordPIN(!props.showNewPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEye} style={{fontSize: "20px"}}
                                                                     color="#9E9E9E"/>
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='mt-10 text-left'>
                        <div>
                            <h5 className='px-3' style={{marginTop: '50px'}}>
                                Confirm New Pin
                            </h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row" style={{marginTop: '20px'}}>
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <PinField
                                            ref={props.ref}
                                            className="ml-3 bg-transparent pin-field"
                                            type={props.showConfirmPasswordPIN ? 'text' : 'password'}
                                            validate={/^[0-9]$/}
                                            onChange={(e) => props.setConfirmPin(e)}
                                            length={6}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 eyepin">
                                        {
                                            !props.showConfirmPasswordPIN ?
                                                <button className="btn px-0"
                                                        onClick={() => props.setShowConfirmPasswordPIN(!props.showConfirmPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEyeSlash} style={{fontSize: "20px"}}
                                                                     color="#9E9E9E"/>
                                                </button>
                                                :
                                                <button className="btn px-0"
                                                        onClick={() => props.setShowConfirmPasswordPIN(!props.showConfirmPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEye} style={{fontSize: "20px"}}
                                                                     color="#9E9E9E"/>
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='text-left'>
                            <button
                                className='btn btn-primary py-3 mt-5 bSavePin'
                                disabled={!props.getPin || props.getPin.length < 6 || !props.getConfirmPin || props.getConfirmPin.length < 6 || (props.getPin != props.getConfirmPin)}
                                onClick={() => props.savePin()}>
                                Save New PIN
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ResetPinSave;