import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from './../../entities/document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const DocumentNew = (props: IDocumentProps) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [listDocumentDraft, setListDocumentDraf] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const handleClose = () => {
    setDocumentSelected(null)
    setShowModal(false)
  };

  const handleOpen = (item) => {
    setDocumentSelected(item)
    setShowModal(true)
  }

  useEffect(() => {
    getListDocumentDraft(page, limit)
  }, []);


  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getListDocumentDraft(value, limit)

  }

  // const changeCategory = (categoryParams) => {
  //   setCategory(categoryParams)
  //   if(category == 0) {
  //     setPage(1)
  //     props.getDocumentApproval("3103077", page, limit, true);
  //   } else if (category == 1) {
  //     setPage(1)
  //     props.getDocumentAcknowledge("3103077", page, limit, true);
  //   } else if (category == 2) {
  //     setPage(1)
  //     props.getDocumentSuratMasuk("3103077", page, limit, true);
  //   } else if(category == 3){
  //     setPage(1)
  //     props.getDocumentSuratKeluar("3103077", page, limit, true);
  //   } 
  // } 


  const renderStatusPriority = (data) => {
    if (data == "1") {
      return <span style={{
        backgroundColor: '#FFF4F2',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #CB3A31',
        color: '#CB3A31',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>High</span>
    } else if (data == "2") {
      return <span style={{
        backgroundColor: '#FFF4EB',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #F37F26',
        color: '#F37F26',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Medium</span>
    } else {
      return <span style={{
        backgroundColor: '#F0F3FF',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #3267E3',
        color: '#3267E3',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Low</span>
    }
  }


  const renderBatasWaktu = (data) => {
    if (!data) return '-'

    return moment(data).format('DD MMM YY | HH:mm') + " WIB"
  }

  //   const renderStatus = (status) => {

  // //     - DRAFT

  // // - WAITING FOR ACKNOWLEDGEMENT

  // // - WAITING FOR APPROVAL

  // // - APPROVED

  // // - REJECT
  //     if(status == 'DRAFT') {
  //       return 'DRAFT'
  //     } else if(status == 'WAITING_FOR_ACKNOWLEDGEMENT') {
  //       return 'WAITING FOR ACKNOWLEDGMENT'
  //     } else if (status == 'WAITING_FOR_APPROVAL') {
  //       return 'WAITING FOR APPROVAL'
  //     } else if(status == 'APPROVED') {
  //       return 'APPROVED'
  //     } else if (status == 'REJECT') {
  //       return 'REJECTED'
  //     } else {
  //       return ''
  //     }
  //   }

  const changeShow = (e) => {
    let show = e.target.value
    setPage(1)
    setLimit(show)
    getListDocumentDraft(1, show)
  }

  const deleteDocument = (id) => {
    setLoadingButton(true)
    axios.delete(`/services/documentservice/api/documents/${id}`).then(res => {
      setLoadingButton(false)

      setPage(1),
        setLimit(5)
      getListDocumentDraft(1, 5)
      setShowModal(false)
    }).catch(err => {
      setLoadingButton(false)
      setPage(1),
        setLimit(5)
      getListDocumentDraft(1, 5)
      setShowModal(false)
    })
  }

  const getListDocumentDraft = (page, size) => {
    axios.get(`/services/documentservice/api/documents/draft?page=${page}&size=${size}`).then(res => {
      setListDocumentDraf(res.data.data)
    }).catch(err => {

    })
  }

  const gotoPreview = (id) => {
    props.history.push(`/preview/only/pdf/${id}`)
  }

  const gotoEdit = (item) => {

    switch (item.typeCode){
      case "IMO":
        props.history.push(
            {
              pathname :`/draft/create/${item?.id}`,
              state: { from: "draft" }
            }
        )
        break
      case "ERRA":
        props.history.push(`/make-new-request/${item?.id}`)
        break
      default:
        props.history.push(`/draft/releaseapproval/create/${item?.id}`)
        break
    }
  }

  const { documentList, loading } = props;
  return (
    <div className="px-4 pt-4" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-28">Draft </h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-14">List Documents Draft</h1>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid"> */}
      <div className="row font-family-spoqa">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">

              <div className="table-responsive">
                <table className="table table-striped table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Document Type</th>
                      <th>File Number</th>
                      <th style={{ textAlign: 'center' }}>Priority</th>
                      <th>Due Date</th>
                      <th>Created Date</th>
                      <th>Last Modified</th>
                      <th>Regarding</th>
                      <th>Sender</th>
                      <th>Document Status</th>
                      <th className='fix'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDocumentDraft.content ? listDocumentDraft.content.map((item, index) => {
                      return <tr key={index}>
                        <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.fileNumber}</td>
                        <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '200px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.createdDateFormat}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.lastModifiedDateFormat}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item.regarding ? item.regarding : '-'}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.fileStatus}</td>
                        <td className='fix' style={{ minWidth: '200px' }}>
                          <div>
                            {item?.canView && <button className='btn btn-info mr-2' disabled={!item?.canView} onClick={() => gotoPreview(item?.id)}>  <FontAwesomeIcon icon="eye" />{' '}View</button>}
                            {item?.canDelete && <button className='btn btn-danger mr-2' disabled={!item?.canDelete} onClick={() => handleOpen(item)}>   <FontAwesomeIcon icon="trash" />{' '}{' '}Delete</button>}
                            <button className='btn btn-warning mr-2' onClick={() => gotoEdit(item)}>  <FontAwesomeIcon icon="pencil-alt" />{' '}Edit</button>
                          </div>
                        </td>
                      </tr>
                    }) : <></>}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group row">
                <label htmlFor="priority" className="col-sm-1 col-form-label text-center">Show :</label>
                <div className="" style={{ width: '80px' }}>
                  <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)}>
                    <option value="2">2</option>
                    <option value="5">5</option>
                    <option value="10" selected>10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
                <label htmlFor="priority" className="col-sm-2 col-form-label">Entries</label>
              </div>
            </div>
            <div className="col-6" style={{ display: 'flex', justifyContent: 'end' }}>
              <Pagination count={listDocumentDraft.totalPages} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Modal */}
      <Modal className="font-family-spoqa" isOpen={showModal} toggle={handleClose} size="md">
        <ModalHeader toggle={handleClose}>
          <h2>Delete Confirmation</h2>
          <span style={{ fontSize: '12px' }}></span>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          Are you sure you want to delete Document <span className='font-weight-bold'>"{documentSelected?.regarding}"</span> ?
        </ModalBody>
        <ModalFooter>
          <Row className='w-100'>
            <Col lg={4}>
            </Col>
            <Col lg={8} className="text-right">
              <Button color="secondary" onClick={handleClose} className="mr-2" disabled={loadingButton}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="danger" disabled={loadingButton} onClick={() => deleteDocument(documentSelected?.id)}>
                {loadingButton ? <Spinner color='#fff' size="sm" className="mr-1" /> : <></>}
                Delete
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>

    </div >


  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentNew);
