import * as React from "react"
import { SVGProps } from "react"

const UploadFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.567 8.235a.798.798 0 0 0-1.13 0l-1.6 1.6a.802.802 0 0 0-.175.871.8.8 0 0 0 .74.494H7.2v2.4a.8.8 0 1 0 1.6 0v-2.4h.802a.8.8 0 0 0 .565-1.365l-1.6-1.6ZM12 5.6c-.04 0-.078.01-.117.012A3.2 3.2 0 0 0 12 4.8a3.2 3.2 0 0 0-6.329-.665A3.167 3.167 0 0 0 4.801 4a3.2 3.2 0 0 0-3.2 3.2V8a3.198 3.198 0 0 0 2.518 3.124c-.05-.158-.09-.32-.107-.488a2.403 2.403 0 0 1 .692-1.933l1.6-1.6A2.386 2.386 0 0 1 8.002 6.4c.64 0 1.244.25 1.696.703l1.6 1.6c.508.513.758 1.212.692 1.922a2.304 2.304 0 0 1-.133.575H12a2.4 2.4 0 0 0 2.4-2.4V8A2.4 2.4 0 0 0 12 5.6Z"
      fill="#F5F5F5"
    />
  </svg>
)

export default UploadFileIcon
