import * as React from "react"

const UserAddKaryawanIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.4 7.2a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8ZM6.4 8.8a4.8 4.8 0 0 1 4.8 4.8H1.6a4.8 4.8 0 0 1 4.8-4.8ZM12.8 5.6a.8.8 0 0 0-1.6 0v.8h-.8a.8.8 0 1 0 0 1.6h.8v.8a.8.8 0 1 0 1.6 0V8h.8a.8.8 0 0 0 0-1.6h-.8v-.8Z"
      fill="#fff"
    />
  </svg>
)

export default UserAddKaryawanIcon
