import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import { translate } from 'react-jhipster';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import DragUploadIcon from 'app/component/upload-drag-icon';
import FilesDragAndDrop from 'app/component/files-drag-and-drop';
import ExcelFileIcon from 'app/component/excel-icon';
import { toast } from 'react-toastify';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

export const KaryawanAdd = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([])
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([])
  const [fileContent, setFileContent] = useState<any>('')
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [title, setTile] = useState<any>('')
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [datePost, setDatePost] = useState<any>('')
  const [dateCreated, setDateCreated] = useState<any>('')
  const [id, setId] = useState<any>("0")
  const [countSuccess, setCountSuccess] = useState<any>(0)
  const [countFailed, setCountFailed] = useState<any>(0)
  const [listFailed, setListFailed] = useState<any>([])
  const [showModalFailed, setShowModalFailed] = useState<any>(false)

  const onDropHandler = (ev) => {
    ev.preventDefault();
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file =
        [...ev.dataTransfer.items]
          .find((item: any) => item.kind === "file")
          .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    setFiles(file);
    setUrlImage(URL.createObjectURL(file))
  };

  const onDragOver = (ev) => ev.preventDefault();

  const renderStatusFileHistories = (status, statusName) => {
    if (status == "CREATED" || status == "REVISE") {
      return <div style={{ background: '#FFF4EB', border: '1px solid #F37F26', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
    } else if (status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION' || status == 'WAITING_FOR_APPROVAL') {
      return <div style={{ color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
    } else {
      return <div style={{ color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px' }} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
    }
  }

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)

    getListNotification()
  }, [page, size, totalPage]);

  const handleChange = (event: SelectChangeEvent) => {
    setSize(parseInt(event.target.value));
  };

  const resultUploadLogic = (data) => {
    if (data.data.failCount > 0) {
      setCountSuccess(data.data.successCount)
      setCountFailed(data.data.failCount)
      setListFailed(data.data.failList)
      setShowModalFailed(true)
    } else {
      history.push('/karyawan')
    }
  }

  const uploadFileKaryawan = () => {
    setLoading(true)
    const formData = new FormData();
    // formDataa.append("data", JSON.stringify(payloaddata));
    formData.append("excelFile", files)
    axios.post(`/services/uaadocservice/api/personal-identity/karyawan/upload-excel`, formData).then(res => {
      setLoading(false)
      toast.success('Success Upload', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      resultUploadLogic(res)
      // history.push('/karyawan')
    }).catch(err => {
      resultUploadLogic(err.response)
      setLoading(false)
      toast.error('Failed Upload', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const downloadFileKaryawan = () => {
    setLoading(true)
    axios.get(`/services/uaadocservice/feign/client/template-upload-karyawan`, { responseType: 'blob' }).then(res => {
      window.open(URL.createObjectURL(res.data));
      setLoading(false)
      toast.success(`Success Download File`, {
        toastId: 'download-file',
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false)
    })

  }

  useEffect(() => {
    // alert(props.location.state.search)
    getListNotification()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListNotification = () => {
    setLoadingList(true)
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`)
      .then((res) => {
        setTotalPage(res.data.data.totalPages)
        setLoadingList(false)
      }).catch((err) => {
        setLoadingList(false)

      })
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`)
        .then(async (result) => {
          setListSearch(result.data.content)
          // setTotalPage(result.data?.totalPages)
          await delayTime(2000)
          setLoadingList(false)
        }).catch(err => {
          setListSearch([])
          setLoadingList(false)
        })
    }
  }

  const goToPage = (data) => {
    setSearch('')
    axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.id}`).then((res) => {
      history.push(data.pageUrl)
    }).catch((err) => {
      history.push(data.pageUrl)
    })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderUnread = (data) => {
    if (!data.isRead) {
      return <span style={{ background: '#FFF4EB', border: '1px solid #F37F26', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold mt-3 text-mirae text-center float-right">New</span>
    } else {
      return <></>
    }
  }

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Update Data Karyawan</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            {/* <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1> */}
          </div>
        </div>
      </div>
      <button className='btn btn-success px-3' disabled={false} onClick={() => downloadFileKaryawan()}>
        {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
        Download Template Excel
      </button>
      <div className="bg-white rounded px-3 py-4 shadow my-4">
        <h5>
          Upload File
        </h5>
        <div>
          <div className="my-3">
            {
              urlImage ? <div style={{ height: 200, position: 'relative', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <ExcelFileIcon />
                  <div className="py-2 px-3 my-3 rounded shadow" style={{ background: '#F5F5F5' }}>{files.name}</div>
                </div>
                <CloseDeleteIcon style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer'
                }} onClick={() => {
                  setUrlImage(null)
                  setFiles(null)
                }} />
              </div> : <></>
            }
          </div>
          {
            !urlImage ? <>
              <div style={{ marginTop: 20 }}></div>
              <label htmlFor={"file_picker"} id="drop_zone" onDrop={onDropHandler} onDragOver={onDragOver}>
                <DragUploadIcon />
                <div>Drag and drop a file here</div>
                <input
                  id="file_picker"
                  type="file"
                  // accept="image/png, image/jpeg"
                  onChange={(ev) => {
                    setFiles(ev.target.files[0]);
                    setUrlImage(URL.createObjectURL(ev.target.files[0]))
                  }}
                  style={{ display: "none" }}
                ></input>
              </label>
            </> : <></>
          }
        </div>
        <span>must be .xlsx file using our excel template (you can download from this page)</span>
      </div>
      <Row className='mb-4 mt-4'>
        <Col className='text-left' xs="12" sm="12" md="6" lg="6">
        </Col>
        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          <button className='btn btn-primary px-3' disabled={loading || !files || !urlImage} onClick={() => uploadFileKaryawan()}>
            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
            Update Data
          </button>
        </Col>
      </Row>
      <Modal className="font-family-spoqa" isOpen={showModalFailed} size="lg" style={{ marginTop: '15%' }}>
        <div className="my-4">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className=" pb-0 text-center">
                  <h4 className="mb-0 font-size-28">{countSuccess} Data Succesfully Added</h4>
                </div>
                <div className="my-3 text-center">
                  <h5 className="mb-0 text-danger font-weight-normal">{countFailed} Data failed, please check again!</h5>
                </div>

              </div>
            </div>

            <div className="row">

              <div className="table-responsive" style={{ height: '300px', overflowY: 'scroll' }}>
                <table className="table table-striped table-centered  mb-0" >
                  <thead className="thead-light">
                    <tr>
                      {/* <th >
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">&nbsp;</label>
                          </div>
                        </th> */}
                      <th className='py-3'>Employee Number</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      listFailed && listFailed.length > 0 ? listFailed.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td className='py-3'>{item.employeeNo}</td>
                              <td>{item.fullName}</td>
                              <td>{item.email}</td>
                              <td className="text-danger">Failed</td>
                            </tr>
                          </>
                        )
                      }) : <></>
                    }
                  </tbody>
                </table>
              </div>

            </div>


          </div>
          <div className='w-100 px-5'>
            <Row className='mb-4 mt-4'>
              <Col className='text-right' xs="12" sm="12" md="6" lg="6">
              </Col>
              <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                <button className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={() => history.goBack()}>
                  {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                  OK </button>
                <button className='btn btn-primary px-5' type="submit" onClick={() => setShowModalFailed(false)}>
                  {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                  Reupload </button>
              </Col>
            </Row>
          </div>
        </div>

      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(KaryawanAdd);
