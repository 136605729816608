import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId } from './inbox.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

import axios from 'axios';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Document = (props: IDocumentProps) => {
  const [search, setSearch] = useState('');

  axios.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
  })

  useEffect(() => {
    // props.getEntitiesByEmpId("3103092", 10, 10, true);
  }, []);

  axios.interceptors.response.use(response => {
    console.log('Getting Response:', JSON.stringify(response, null, 2))
    return response
  })

  const startSearching = () => {
    if (search) {
      props.getSearchEntities(search);
    }
  };

  const clear = () => {
    setSearch('');
    // props.getEntitiesByEmpId("3103092", 10, 10, true);
  };

  const handleSearch = event => setSearch(event.target.value);

  const { documentList, match, loading } = props;
  var responsestring = JSON.stringify(documentList);
  var dataValue = JSON.parse(responsestring);
  var contentValue = dataValue['data'];
  // var x = JSON.stringify(dataValue.data);
  // var xvalue = JSON.parse(x);
  // var contentValuex = JSON.parse(x);
  // var contentValuexx = contentValue['content'];

  return (
    <div className="container">
      <h2 id="document-heading">
        {/* <Translate contentKey="documenttestApp.document.home.title">Documents</Translate> */}
        {/* <Translate contentKey="table.title">Documents</Translate> */}
        Inbox
        {/* <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="table.createNew">Create new Document</Translate>
        </Link> */}
      </h2>
      <div className="d-flex flex-row-reverse">
      <Col sm="3">
          <AvForm onSubmit={startSearching}>
            <AvGroup>
              <InputGroup>
                <AvInput
                  type="text"
                  name="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder={translate('table.search')}
                />
                <Button className="input-group-addon">
                  <FontAwesomeIcon icon="search" />
                </Button>
                {/* <Button type="reset" className="input-group-addon" onClick={clear}>
                  <FontAwesomeIcon icon="trash" />
                </Button> */}
              </InputGroup>
            </AvGroup>
          </AvForm>
        </Col>

      </div>

      <div className="row">



        <div className="col-lg-12">
          <div className="card">
            <div className="card-body shadow-lg">
              <div className="table-responsive">
                
                {contentValue && contentValue.content.length > 0 ? (
                  <Table responsive className="table-striped table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <Translate contentKey="global.field.id">ID</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.type">Type</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.no">File Number</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.dueDate">Date</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.sender">Sender</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.regarding">Regarding</Translate>
                        </th>
                        <th>
                          <Translate contentKey="table.document.status">File Status</Translate>
                        </th>

                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {contentValue.content.map((document, i) => (
                        <tr key={`entity-${i}`}>
                          <td>
                            <Button tag={Link} to={`${match.url}/${document.id}`} color="link" size="sm">
                              {document.id}
                            </Button>
                          </td>
                          <td>{document.type}</td>
                          <td>{document.fileNumber}</td>
                          {/* <td>{document.priority}</td>
                  <td>{document.documentSecurity}</td> */}
                          <td>{document.date ? <TextFormat type="date" value={document.date} format={APP_DATE_FORMAT} /> : null}</td>
                          {/* <td>{document.expDate ? <TextFormat type="date" value={document.expDate} format={APP_DATE_FORMAT} /> : null}</td> */}
                          <td>{document.senderEmployeeNo}</td>
                          {/* <td>{document.senderPosition}</td>
                  <td>{document.senderLevel}</td>
                  <td>{document.receiver}</td>
                  <td>{document.cc}</td> */}
                          <td>{document.regarding}</td>
                          {/* <td>{document.fileContent}</td> */}
                          <td>{document.fileStatus}</td>
                          {/* <td>{document.version}</td>
                  <td>{document.createdBy}</td>
                  <td>{document.createdDate ? <TextFormat type="date" value={document.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{document.lastModifiedBy}</td> */}
                          {/* <td>
                    {document.lastModifiedDate ? (
                      <TextFormat type="date" value={document.lastModifiedDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{document.description}</td>
                  <td>{document.fileId}</td>
                  <td>{document.filePath}</td> */}
                          <td className="text-right">
                            <div className="btn-group flex-btn-group-container">
                              <Button tag={Link} to={`${match.url}/${document.id}`} color="info" size="sm">
                                <FontAwesomeIcon icon="eye" />{' '}
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.view">View</Translate>
                                </span>
                              </Button>
                              <Button tag={Link} to={`${match.url}/${document.id}/edit`} color="primary" size="sm">
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                </span>
                              </Button>
                              <Button tag={Link} to={`${match.url}/${document.id}/delete`} color="danger" size="sm">
                                <FontAwesomeIcon icon="trash" />{' '}
                                <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  !loading && (
                    <div className="alert alert-warning">
                      <Translate contentKey="table.notFound">No Documents found</Translate>
                    </div>
                  )
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Document);
