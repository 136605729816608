import React from "react";
import OtpInput from "react-otp-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./pin.scss"
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";


export const ResetPinOtp = (props) => {

  const history = useHistory()
  
  return (
      <div className="container text-center">
        <div className='container d-flex  h-100' style={{ alignItems: 'center', justifyContent: 'center' }}>

          <div className="" style={{ padding: '10px', width: '70%' }}>
            <div className='d-flex'>
              <button className="btn px-0 mr-4" onClick={() => history.replace('/profile/pin/change')}>
                <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057" />{' '}
              </button>
              <h2 className='mb-0'>
                Input OTP
              </h2>
            </div>


            <div className={"col-12 col-sm-12 col-md-8 mt-4"}>

              <div className={"row text-left"}>
                Enter the OTP code that has been sent to the email {history.location.state?.email}
              </div>

              <div className={"row"}>
                <OtpInput
                    value={props.etOTP}
                    onChange={(e) => {
                      props.setOTP(e)
                    }}
                    inputType={"tel"}
                    numInputs={6}
                    containerStyle={{paddingLeft:0, marginTop:"16px"}}
                    renderSeparator={<span style={{width: "20px"}}></span>}
                    renderInput={(props) => <input id={"pinInput"} {...props} style={{
                      width: "40px",
                      height: "40px",
                      border: "1px solid #C2C2C2",
                      boxSizing: "border-box",
                      fontSize: "20px",
                      boxShadow: "inset 1px 2px 2px rgba(0, 0, 0, 0.12)",
                      borderRadius: "6px",
                      textAlign: "center",
                    }} placeholder={"_"}
                    />}
                    shouldAutoFocus
                />

                <div className={"row"} style={{paddingRight:0}}>
                  <div className={"col"} style={{paddingRight:0, paddingLeft:0}}>
                    <div className='d-flex align-items-center justify-content-between mt-4'>
                      <div>Not receiving OTP code? <span className="time" style={{marginLeft:"8px", color:"#D8382E"}}>{`${props.minutes} : ${props.seconds}`}</span></div>

                      <div className='d-flex align-items-center'><Button onClick={props.isActiveResend? () => {
                      props.disableButtonAfterSendOTP();
                      props.reset();
                      props.handleSendOTP();
                      } : ()=> {}} style={{backgroundColor:props.getResendButtonColorOTP,textAlign:"center"}}><span style={{color:props.getResendButtonTextColorOTP}}>Resend</span></Button></div>
                    </div>
                  </div>
                </div>

                <div style={{marginTop:20}} className="row pl-0 ml-0 mr-0 mt-4 col-12 col-sm-12">
                  <Button color="primary" onClick={() => {
                    props.handleVerifyOTP(props.etOTP);
                  }} className={"pl-0 ml-0 pr-0"}>Send {props.loading && <Spinner color='#FFFFFF' size="sm" style={{marginLeft: "8px", marginBottom: "4px"}}/>}</Button>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
  )
}

export default ResetPinOtp