import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocument, defaultValue } from 'app/shared/model/document.model';

export const ACTION_TYPES = {
  SEARCH_DOCUMENTS: 'document/SEARCH_DOCUMENTS',
  FETCH_DOCUMENT_LIST: 'document/FETCH_DOCUMENT_LIST',
  FETCH_DOCUMENT_LIST_APPROVAL: 'document/FETCH_DOCUMENT_LIST_APPROVAL',
  FETCH_DOCUMENT_LIST_ACKNOWLEDGE: 'document/FETCH_DOCUMENT_LIST_ACKNOWLEDGE',
  FETCH_DOCUMENT_LIST_SURAT_MASUK: 'document/FETCH_DOCUMENT_LIST_SURAT_MASUK',
  FETCH_DOCUMENT_LIST_SURAT_KELUAR: 'document/FETCH_DOCUMENT_LIST_SURAT_KELUAR',
  FETCH_DOCUMENT_DETAIL: 'document/FETCH_DOCUMENT_DETAIL',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  FETCH_DEPARTMENT: 'document/FETCH_DEPARTMENT',
  CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  RESET: 'document/RESET',
  OPENPREVIEW: 'document/previewsign',
  APPROVE_DOCUMENT: 'document/approve',
  REJECT_DOCUMENT: 'document/reject',
  VIEW_DOCUMENT_SIGN: 'document/viewsign',
  SEND_DOCUMENT: 'document/SEND_DOCUMENT',
  FETCH_SIGNATURE: 'signature/FETCH_SIGNATURE',
  SAVE_SIGNATURE: 'signature/SAVE_SIGNATURE'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDocument>,
  documentDashboardList: [] as ReadonlyArray<IDocument>,
  documentListAcknowledge: [] as ReadonlyArray<IDocument>,
  documentListSuratMasuk: [] as ReadonlyArray<IDocument>,
  documentListSuratKeluar: [] as ReadonlyArray<IDocument>,
  deptList: [],
  entity: defaultValue,
  updating: false,
  
};

export type SignatureState = Readonly<typeof initialState>;

// Reducer

export default (state: SignatureState = initialState, action): SignatureState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DOCUMENTS):
    case REQUEST(ACTION_TYPES.FETCH_SIGNATURE):
    case REQUEST(ACTION_TYPES.SAVE_SIGNATURE):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
    case SUCCESS(ACTION_TYPES.FETCH_SIGNATURE):
      return {
        ...state,
      }
    case SUCCESS(ACTION_TYPES.SAVE_SIGNATURE):
      return {
        ...state
      }
    case FAILURE(ACTION_TYPES.SEARCH_DOCUMENTS):
      return {
        ...state,
        loading: false,
        updating: false,
      
        errorMessage: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};


const apiUrl = 'services/documentservice/api/documents';
// const apiUrl = 'services/documentservice/api/documents/send_document_new?id='
const deptlisturl = 'services/uaadocservice/api/department/list'

const apiSearchUrl = 'api/_search/documents';

export const getEntitiesByEmpId = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
   payload: axios.get<IDocument>(`${apiUrl}/draft?employeeNo=${employeeId}`),
});


export const getDocumentApproval = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_approval?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentAcknowledge = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_acknowledge?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentSuratMasuk = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_masuk?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentSuratKeluar = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_keluar?employeeNo=${employeeId}&page=${page}&size=${size}`),
});

export const getDocumentDetail: ICrudGetAction<IDocument> = (id:string) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
  payload: axios.get<IDocument>(`/services/documentservice/api/documents/${id}`),
});

export const postDocument = (data, id) => {
  const formData = new FormData();
  formData.append("file", data);
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
    payload: axios.post<any>(`/services/documentservice/api/file-metadata/upload-to-gcp?fileId=${id}`, formData),
  }
}

export const viewPDF = (id) => {
  return {
    type: ACTION_TYPES.VIEW_DOCUMENT_SIGN,
    payload: axios.get<any>(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${id}`, {responseType:'arraybuffer'}),
  }
}

export const approveDocument = (id, notes, file) => {
  const formData = new FormData();
  formData.append("pdfFile", file);
  return {
    type: ACTION_TYPES.APPROVE_DOCUMENT,
    payload: axios.post<any>(`/services/documentservice/api/dms/approve?id=${id}&notes=${notes}`, formData),
  }
}

export const rejectDocument = (id, notes, file) => {
  const formData = new FormData();
  formData.append("pdfFile", file);
  return {
    type: ACTION_TYPES.REJECT_DOCUMENT,
    payload: axios.put<any>(`/services/documentservice/api/dms/reject?id=${id}&notes=${notes}`, formData),
  }
}

export const signDocument = (data, sign, id) => {
  const formData = new FormData();
  formData.append("pdfFile", data);
  formData.append("signatureImage", sign);
  return {
    type: ACTION_TYPES.OPENPREVIEW,
    payload: axios.post<any>(`/services/documentservice/api/dms/sign-file`, formData, {responseType: 'blob'}),
  }
}

export const getDeptList = (sort) => ({
  type: ACTION_TYPES.FETCH_DEPARTMENT,
  payload: axios.get<IDocument>(`${deptlisturl}`),
});

export const getSignature = () => ({
  type: ACTION_TYPES.FETCH_SIGNATURE,
  payload: axios.get<IDocument>(`/api/get-signature`),
});

export const saveSignature = (sign) => {
  const formData = new FormData();
  formData.append("signatureImage", sign);
  return {
    type: ACTION_TYPES.SAVE_SIGNATURE,
    payload: axios.put<any>(`/services/uaadocservice/api/personal-identity/save-signature`, formData),
  }
}

export const deleteSignature = (sort) => ({
  type: ACTION_TYPES.FETCH_SIGNATURE,
  payload: axios.get<IDocument>(`/api/get-signature`),
});


export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
