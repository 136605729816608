  import React, { useState } from 'react'
  import { Page, Text, View, Document, StyleSheet, Image, Font,} from '@react-pdf/renderer';
  import Html from 'react-pdf-html';
import EnvelopeIcon from 'app/component/envelope-icon';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import moment from 'moment';
import { Col, Row } from 'reactstrap';

const logo = require('./../../../../../../images/new_mr_logo1.png')
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald'
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'left',
      color: 'grey',
    },
    headerright: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'right',
      color: 'grey',
    },
     pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 20,
      // left: 0,
      right: 20,
      textAlign: 'center',
      color: 'grey',
    },
    qrPosition: {
      position: 'absolute',
      fontSize: 1,
      bottom: 20,
      // left: 0,
      left: 20,
      textAlign: 'center',
      color: 'rgba(240,240,240,0.1)',
    },
  });

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
  


const PreviewPDFComponent: React.FC<{
  from?: any,
  title?: any,
  regarding?: any,
  date?: any,
  to?: any,
  fileContent?: any,
  cc?: any,
  cbRender?: any,
  dataDocument?: any,
  notesApprove?: any,
  notesReject?: any,
  acknowledge?: any}> = ({from, title, regarding, date, to, fileContent, cc, dataDocument, notesApprove, notesReject, acknowledge, cbRender}) => {
    const [toDetail, setToDetail] = useState<any>([])
    

    const sign = () => {

    }

    const setDataTo = (dataTo) => {
        let datastring = ''
        dataTo.map((item, index)=> {
            if(datastring.search(item.branchName) == -1){
                if(dataTo.length == index + 1) {
                     datastring = datastring + item.branchName
                } else {
                     datastring = datastring + item.branchName + ', '
                }
            } else {
              // datastring = datastring + item.branchName + ', '
            }
        })
        return datastring
    }

    const chunks = (array, size) => {
      var results = [];
      while (array.length) {
        results.push(array.splice(0, size));
      }
      return results;
    };

  

    const setSignature = (data) => {
      let listSignature: any[] = [{}]
      listSignature[0].employeeName = dataDocument.senderEmployeeName
      listSignature[0].positionName = dataDocument.senderPositionName
      listSignature[0].employeeNo = dataDocument.senderEmployeeNo

      let acknowledgeList = acknowledge
      let approvalList = dataDocument.approval
      
      acknowledgeList.map((datadata, index) => {
        datadata['title'] = 'Acknowledge By'
      })

      approvalList.map((datadata1, index) => {
        datadata1['title'] = 'Approved By'
      })

    

      let arrayfinal : any[] = listSignature.concat(approvalList)
      arrayfinal = arrayfinal.concat(acknowledgeList)

      if(arrayfinal?.length == 2) {
        arrayfinal.push({})
      } else if (arrayfinal.length == 1) {
        arrayfinal.push({})
        arrayfinal.push({})
      }

      if(3 - (arrayfinal.length%3) == 2){
        arrayfinal.push({})
        arrayfinal.push({})
      } else if(3 - (arrayfinal.length%3) == 1) {
        arrayfinal.push({})
      }

      let arraychunk = chunks(arrayfinal, 3)
      
      let htmltd = ''
      let htmlfinal = ''
     
      arraychunk.map((item, index) => {
        if(item.length == 3 ) {
          htmltd = htmltd + `
          <tr>
           <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
           
           <div style="text-align: center">
           ${index == 0 ? 'Prepared By' : item[0].title}
           </div>
            <div style="height: 100px; padding-top: 40px">
           
        </div>
        <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
     
        <div style="text-align: center">
        [ ${item[0]?.employeeName} ] <br>
  
        <span style="font-size: 10px">${item[0]?.positionName}</span><br>
        </div>
        <div style="text-align: left; color: rgba(240,240,240,0.1);">
        <span style="font-size: 1px;">@detail${item[0]?.employeeNo}</span><br>
        <span style="font-size: 1px"></span><br>
        </div>
        <div style="height: 70px;">
        <span style="font-size:8px;">
       
        </span>
        </div>
        </div>
            <span style="font-size: 10px"></span>
          </td>
            ${ item[1].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
           
            <div style="text-align: center">
           ${item[1].title}
            </div>
             <div style="height: 100px; padding-top: 40px">
            
         </div>
         <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[1]?.employeeNo}</span><br><br>
      
         <div style="text-align: center">
         [ ${item[1]?.employeeName} ] <br>
   
         <span style="font-size: 10px">${item[1]?.positionName}</span><br>
         </div>
         <div style="text-align: left; color: rgba(240,240,240,0.1);">
         <span style="font-size: 1px;">@detail${item[1]?.employeeNo}</span><br>
         <span style="font-size: 1px"></span><br>
         </div>
         <div style="height: 70px;">
         <span style="font-size:8px;">
        
         </span>
         </div>
         </div>
             <span style="font-size: 10px"></span>
           </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
            
            <div style="height: 130px">
    
            </div>
             
            
             
            </td>` }
           ${ item[2].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
           
           <div style="text-align: center">
           ${item[2].title}
           </div>
            <div style="height: 100px; padding-top: 40px">
           
        </div>
        <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[2]?.employeeNo}</span><br><br>
     
        <div style="text-align: center">
        [ ${item[2]?.employeeName} ] <br>
  
        <span style="font-size: 10px">${item[2]?.positionName}</span><br>
        </div>
        <div style="text-align: left; color: rgba(240,240,240,0.1);">
        <span style="font-size: 1px;">@detail${item[2]?.employeeNo}</span><br>
        <span style="font-size: 1px"></span><br>
        </div>
        <div style="height: 70px;">
        <span style="font-size:8px;">
       
        </span>
        </div>
        </div>
            <span style="font-size: 10px"></span>
          </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
           
           <div style="height: 130px">
   
           </div>
            
           
            
           </td>` }
            </tr>
        `
        } else if (item.length == 2) {
          htmltd = htmltd + `
          <tr>
            <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
              <div style="text-align: center">
                 ${index == 0 ? 'Prepared By' : item[0].title}
              </div>

              <div style="height: 100px; padding-top: 40px; ">

              </div>

              <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
                  <div style="text-align: center">
                    [ ${item[0]?.employeeName} ] <br>
                  
                    <span style="font-size: 10px">${item[0]?.positionName}</span>
                  </div>
                  <div style="text-align: left; color: rgba(240,240,240,0.1);">
                    <span style="font-size: 1px;">@detail${item[0]?.employeeNo}</span><br>
                    <span style="font-size: 1px"></span><br>
                  </div>
                  <div style="height: 70px;">
                    <span style="font-size:1spx;">
                    </span>
                  </div>
            </td>
           <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
                <div style="text-align: center">
                ${item[1].title}
                  </div>
                  <div style="height: 100px; padding-top: 40px">
                
              </div>
              <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[1]?.employeeNo}</span><br><br>
              <div style="text-align: center">
              [ ${item[1]?.employeeName} ] <br>
        
              <span style="font-size: 10px">${item[1]?.positionName}</span><br>
              </div>
              <div style="text-align: left; color: rgba(240,240,240,0.1);">
              <span style="font-size: 1px;">@detail${item[1]?.employeeNo}</span><br>
              <span style="font-size: 1px"></span><br>
              </div>
              <div style="height: 70px;">
              <span style="font-size:8px;">
            
              </span>
              </div>
              </div>
                  <span style="font-size: 10px"></span>
            </td>
            <td style="border: 1px solid #fff; text-align: center; padding: 10px; font-size: 12px">
           
          <div style="height: 130px">
  
          </div>
           
          
           
          </td>
            </tr>
        `
        }  else if (item.length == 1) {
          htmltd = htmltd + `
          <tr>
          <td style="border: 1px solid #bdbdbd; padding: 10px; font-size: 12px">
          <div style="text-align: center">
             ${index == 0 ? 'Prepared By' : item[0].title}
          </div>

          <div style="height: 100px; padding-top: 40px; ">

          </div>

          <span style="color: rgba(240,240,240,0.1); font-size: 1px">@${item[0]?.employeeNo}</span><br><br>
              <div style="text-align: center">
                [ ${item[0]?.employeeName} ] <br>
              
                <span style="font-size: 10px">${item[0]?.positionName}</span>
              </div>
              <div style="text-align: left; color: rgba(240,240,240,0.1);">
                <span style="font-size: 1px;">@detail${item[0]?.employeeNo}</span><br>
                <span style="font-size: 1px"></span><br>
              </div>
              <div style="height: 70px;">
                <span style="font-size:1spx;">
                </span>
              </div>
        </td>
            <td style="border: 1px solid #fff; text-align: center; padding: 10px; font-size: 12px">
           
            <div style="height: 100px">
    
            </div>
             
            
             
            </td
            <td style="border: 1px solid #fff; text-align: center; padding: 10px; font-size: 12px">
           
            <div style="height: 100px">
    
            </div>
             
            
             
            </td
            </tr>
        `
        }
      })
      return htmltd

    }

    const setSignatureKnowledge = (data) => {
        if(dataDocument?.acknowledge) {
          return ''
        }
        let htmld = ''
        if(acknowledge){
            htmld = htmld + '<br><br><br><span style="font-size: 10px; font-weight: bold; margin-top: 10px">Confirmed By : </span><br>'
        }
        if(acknowledge){
        acknowledge.map((item, index) => {
          if((index + 1) % 2 != 0) {
            htmld = htmld + `
              <br>
              <tr>
                <td style="font-size: 10px">
                  ${item.employeeName} (${item.positionName})  :
                </td>
                <td style="border-bottom: '1px solid black'">
                  <span style="color: rgba(240, 240, 240, 0.1); font-size: 1px">@${item.employeeNo}</span>
                </td>
                <td>
                </td>
              </tr>
            `
          } else {
            htmld = htmld + `
            <br>
            <br>
            <tr style="text-align: 'center'">
            <td style="font-size: 10px; text-align: center, padding-left: 10px">
            <span>${item.employeeName} (${item.positionName})  : </span>
        </td>
              <td style="width: 5px">
              </td>
              
              <td style="border-bottom: '1px solid black'">
              <span style="color: rgba(240, 240, 240, 0.1); font-size: 1px">@${item.employeeNo}</span>
            </td>
            
            </tr>
          `
          }
        })
      }

      return htmld
    }

    const setSignatureAck = (data) => {
      if(!dataDocument?.acknowledge) {
        return ''
      }

      let arrayfinal = dataDocument.acknowledge
   

      if(arrayfinal?.length == 2) {
        arrayfinal.push({})
      } else if (arrayfinal == 1) {
        arrayfinal.push({})
        arrayfinal.push({})
      }
      let arraychunk = chunks(arrayfinal, 3)
      
      let htmltd = ''
      let htmlfinal = ''
      arraychunk.map((item, index) => {
        if(item.length == 3 ) {
          htmltd = htmltd + `
          <tr>
            <td style="border: 1px solid #bdbdbd; height: 160px; padding: 10px; font-size: 12px">
              ${index == 0 ? 'Prepared By' : 'Approved By'}
            <div style="height: 130px; padding-top: 40px">
            <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[0]?.employeeNo}</span>
            </div>
              [ ${item[0]?.employeeName} ] <br>
            
              <span style="font-size: 10px">${item[0]?.positionName}</span><br>
              <span style="font-size: 10px"></span>
            </td>
            ${ item[1].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; height: 160px; padding: 10px; font-size: 12px">
            Approved By
            <div style="height: 130px; padding-top: 40px">
            <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[1]?.employeeNo}</span>
        </div>
            [ ${item[2]?.employeeName} ] <br>
          
            <span style="font-size: 10px">${item[1]?.positionName}</span><br>
            <span style="font-size: 10px"></span>
          </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
           
           <div style="height: 130px">
   
           </div>
            
           
            
           </td>` }
           ${ item[2].employeeNo != null ? `<td style="border: 1px solid #bdbdbd; height: 160px; padding: 10px; font-size: 12px">
            Approved By
            <div style="height: 130px; padding-top: 40px">
            <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[2]?.employeeNo}</span>
        </div>
            [ ${item[2]?.employeeName} ] <br>
          
            <span style="font-size: 10px">${item[2]?.positionName}</span><br>
            <span style="font-size: 10px"></span>
          </td>` :  `<td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
           
           <div style="height: 130px">
   
           </div>
            
           
            
           </td>` }
            </tr>
        `
        } else if (item.length == 2) {
          htmltd = htmltd + `
          <tr>
            <td style="border: 1px solid #bdbdbd; height: 160px; padding: 10px; font-size: 12px">
              ${index == 0 ? 'Prepared By' : 'Approved By'}
              <div style="height: 130px; padding-top: 40px">
              <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[0]?.employeeNo}</span>
          </div>
              [ ${item[0]?.employeeName} ] <br>
            
              <span style="font-size: 10px">${item[0]?.positionName}</span><br>
              <span style="font-size: 10px"></span>
            </td>
            <td style="border: 1px solid #bdbdbd; height: 160px; padding: 10px; font-size: 12px">
              Approved By
              <div style="height: 130px; padding-top: 40px">
              <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[1]?.employeeNo}</span>
          </div>
              [ ${item[1]?.employeeName} ] <br>
            
              <span style="font-size: 10px">${item[1]?.positionName}</span><br>
              <span style="font-size: 10px"></span>
            </td>
            <td style="border: 1px solid #fff; height: 160px;  padding: 10px; font-size: 12px">
           
          <div style="height: 130px">
  
          </div>
           
          
           
          </td>
            </tr>
        `
        }  else if (item.length == 1) {
          htmltd = htmltd + `
          <tr>
            <td style="border: 1px solid #bdbdbd; height: 160px;  padding: 10px; font-size: 12px">
              ${index == 0 ? 'Prepared By' : 'Approved By'}
              <div style="height: 130px; padding-top: 40px">
              <span style="color: rgba(240,240,240,0.1); font-size: 1px">${item[0]?.employeeNo}</span>
          </div>
              [ ${item[0]?.employeeName} ] <br>
            
              <span style="font-size: 10px">${item[0]?.positionName}</span><br>
              <span style="font-size: 10px"></span>
            </td>
            <td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
           
            <div style="height: 130px">
    
            </div>
             
            
             
            </td
            <td style="border: 1px solid #fff; height: 160px; padding: 10px; font-size: 12px">
           
            <div style="height: 130px">
    
            </div>
             
            
             
            </td
            </tr>
        `
        }
      })
      return htmltd

    }

    const round3 = (x) => 
    {
        return Math.ceil(x/3)*3;
    }

   const setSignaturePrepared = () => {
     
   }

    if(dataDocument) { return (
        <Document onRender={cbRender}>
    <Page style={styles.body}>
     <Image style={{height: '150px', width: '600px', position: 'absolute', transform: 'rotate(-45deg)', top: 300, opacity: 0.1}} source={'./../../../content/images/logo-big.png'} fixed/>
    <Text style={styles.header} render={({ pageNumber, totalPages }) => pageNumber > 1 ? `${title}` : ''} fixed />
    <Image style={{ height: '150px', width: '100%', top: '50%', left: '10%',position: 'absolute', opacity: 0.1, transform: 'rotate(-50deg)'}} source={logo} fixed/>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
        <View style={{width: '78%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>{title}</Text>
        </View>
        <View style={{justifyContent: 'flex-end'}}>
        <Image style={{height: '40px', width: '100%'}} source={logo}/>
          </View>
      </View>
      <Text style={{fontWeight: 'bold', borderBottom: '1px solid #000', paddingVertical: '10px'}}>
        INTERNAL MEMO
      </Text>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', borderBottom: '1px solid #bdbdbd', paddingBottom: '10px', paddingTop: '10px'}}>
        <View style={{width: '15%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text> No. IMO </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text> : </Text>
        </View>
        <View style={{width: '35%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  {title} </Text>
        </View>
        <View style={{width: '10%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  Tanggal  </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  :  </Text>
        </View>
        <View style={{width: '30%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          {/* <Text>  {moment(date).format('MMMM Do, YYYY')} </Text> */}
          <Text>  {date} </Text>
        </View>
      </View>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', borderBottom: '1px solid #bdbdbd', paddingBottom: '10px', paddingTop: '10px'}}>
        <View style={{width: '15%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text> Dari  </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text> : </Text>
        </View>
        <View style={{width: '80%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  {from} </Text>
        </View>
      
      </View>

      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', borderBottom: '1px solid #bdbdbd', paddingBottom: '10px', paddingTop: '10px'}}>
        <View style={{width: '15%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  Kepada </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  : </Text>
        </View>
        <View style={{width: '80%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>    {to ? setDataTo(to) : '-'} </Text>
        </View>
      
      </View>

      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', borderBottom: '1px solid #bdbdbd', paddingBottom: '10px', paddingTop: '10px'}}>
        <View style={{width: '15%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  Tembusan  </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  : </Text>
        </View>
        <View style={{width: '80%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>    {to ? setDataTo(cc) : '-'} </Text>
        </View>
      
      </View>

      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', borderBottom: '1px solid #000', paddingBottom: '10px', paddingTop: '10px'}}>
        <View style={{width: '15%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  Perihal  </Text>
        </View>
        <View style={{width: '5%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>  : </Text>
        </View>
        <View style={{width: '80%', color: 'gray', fontSize: '12px', alignSelf: 'center'}}>
          <Text>     {regarding} </Text>
        </View>
      
      </View>
    
      <Html>{`
    
    
    ${fileContent}
          <table style="width: 100%;">
           
            ${setSignature(dataDocument)}
         
          </table>

         

        

        `}</Html>
  {/* <table>
          ${setSignatureKnowledge(acknowledge)}
          </table> */}
         {/* <table style="width: 100%;">
        <tr>
            <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
                Prepared By
                <div style="height: 130px">

                </div>
                [ ${dataDocument?.senderEmployeeName} ] <br>
               
                <span style="font-size: 10px"> ${dataDocument?.senderBranchName} </span>
            </td>
            <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
                Approved By
                <div style="height: 130px">

                </div>
                ${dataDocument.approval[0]?.employeeName} <br>
               
                <span style="font-size: 10px"> ${dataDocument.approval[0]?.positionCode} </span>
            </td>
            <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
                Approved By
                <div style="height: 130px">

                </div>
                ${dataDocument.approval[1]?.employeeName} <br>
               
                <span style="font-size: 10px"> ${dataDocument.approval[1]?.positionCode} </span>
            </td>
        </tr>
        <tr>
        <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
            CC
            <div style="height: 130px">

            </div>
            ${dataDocument.cc[0]?.employeeName} <br>
           
            <span style="font-size: 10px"> ${dataDocument.approval[0]?.positionCode} </span>
        </td>
        <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
            CC
            <div style="height: 130px">

            </div>
            ${dataDocument.cc[1]?.employeeName} <br>
           
            <span style="font-size: 10px"> ${dataDocument.approval[1]?.positionCode} </span>
        </td>
        <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
        acknowledge
        <div style="height: 130px">

        </div>
        ${dataDocument.acknowledge[0]?.employeeName} <br>
       
        <span style="font-size: 10px"> ${dataDocument.approval[0]?.positionCode} </span>
    </td>
    </tr>

       <tr>
       
        <td style="border: 1px solid #bdbdbd; height: 160px; text-align: center; padding: 10px; font-size: 12px">
        acknowledge
        <div style="height: 130px">

        </div>
        ${dataDocument.acknowledge[1]?.employeeName} <br>
       
        <span style="font-size: 10px"> ${dataDocument.approval[1]?.positionCode} </span>
       </td>
       <td>
       </td>
       <td>
       </td>
    </tr>
    </table> */}

     
     
      
        
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Halaman ${pageNumber} dari ${totalPages}`
      )} fixed />
        <Text style={styles.qrPosition} render={({ pageNumber, totalPages }) => pageNumber === totalPages ? `@QRcode` : ''}  />
    </Page>
  </Document>
    )
  } else {
    return <></>
  }
 }

 export default PreviewPDFComponent