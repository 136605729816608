import * as React from "react"

const EditBlue = (props) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18.268 1.732.707-.707-.707.707ZM4.5 19.035v1a1 1 0 0 0 .707-.292l-.707-.707Zm-3.5 0H0a1 1 0 0 0 1 1v-1Zm0-3.57-.707-.708a1 1 0 0 0-.293.707h1ZM15.44 2.438a1.5 1.5 0 0 1 2.12 0l1.415-1.414a3.5 3.5 0 0 0-4.95 0L15.44 2.44Zm2.12 0a1.5 1.5 0 0 1 0 2.122l1.415 1.414a3.5 3.5 0 0 0 0-4.95L17.56 2.44Zm0 2.122L3.794 18.328l1.414 1.415L18.975 5.975 17.56 4.56ZM4.5 18.035H1v2h3.5v-2Zm9.525-17.01L.293 14.757l1.414 1.415L15.44 2.439l-1.414-1.414ZM0 15.465v3.57h2v-3.57H0ZM12.525 3.938l3.536 3.536 1.414-1.414-3.536-3.536-1.414 1.414Z"
      fill="#3267E3"
    />
  </svg>
)

export default EditBlue
