import * as React from "react"

const DuplicateIllustration = (props) => (
    <svg
        width={150}
        height={150}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >

      <path
          d="M9.83579 89.7574L73.3345 53.5721C75.9408 52.0881 79.1479 52.1474 81.6949 53.7332L140.142 90.1475C145.405 93.4294 145.253 101.155 139.871 104.225L78.9532 139.019C74.0707 141.809 68.0542 141.64 63.3324 138.587L9.44654 103.767C4.28473 100.434 4.49628 92.8019 9.83579 89.7574Z"
          fill="url(#paint0_linear_9638_9100)"/>
      <path
          d="M75.4057 111.132C74.0719 111.132 72.7192 111.075 71.3857 110.963C60.8593 110.081 51.4579 105.83 46.8499 99.8709L46.6663 99.6336L50.9266 96.3396L51.1102 96.5769C55.351 102.061 65.1253 105.745 75.4321 105.745C75.4327 105.745 75.433 105.745 75.4339 105.745C76.3981 105.745 77.3671 105.711 78.3136 105.645C84.724 105.197 90.2677 103.269 93.5902 100.343L90.5851 97.0851L101.22 96.4782L100.283 107.597L97.2427 104.302C92.9947 108.095 86.422 110.477 78.6895 111.018C77.6065 111.094 76.5019 111.132 75.4057 111.132Z"
          fill="#E0E0E0"/>
      <path d="M62.8623 83.1982V78.8962L68.4507 75.6697V79.9717L62.8623 83.1982Z" fill="#F0F0F0"/>
      <path d="M62.8623 78.8962L55.8765 74.863V79.165L62.8623 83.1982V78.8962Z" fill="#E6E6E6"/>
      <path d="M62.8623 78.8964L68.6163 75.5727L61.4649 71.4429L55.7109 74.7666L62.8623 78.8964Z" fill="#FAFAFA"/>
      <path d="M62.8623 78.8962V79.9717L68.6163 76.6495V75.5725L62.8623 78.8962Z" fill="#F0F0F0"/>
      <path d="M62.8623 79.9717L68.4507 76.7449V76.9816L62.8623 80.2081V79.9717Z" fill="#E6E6E6"/>
      <path d="M55.7109 74.7664L62.8623 78.8962V79.9717L55.7109 75.8428V74.7664Z" fill="#E6E6E6"/>
      <path d="M62.8621 79.9717L55.876 75.9385L55.8763 76.1749L62.8621 80.2081V79.9717Z" fill="#E0E0E0"/>
      <path
          d="M64.7018 79.55L66.6107 78.4478C66.6929 78.4004 66.7598 78.4388 66.7598 78.5339V79.6628C66.7598 79.7576 66.6932 79.8734 66.6107 79.9208L64.7018 81.023C64.6196 81.0704 64.553 81.032 64.553 80.9369V79.8083C64.553 79.7129 64.6196 79.5977 64.7018 79.55Z"
          fill="#EBEBEB"/>
      <path
          d="M64.8242 79.678L66.4886 78.7171C66.5435 78.6856 66.5879 78.7111 66.5879 78.7744V79.6207C66.5879 79.684 66.5435 79.7611 66.4886 79.7926L64.8242 80.7535C64.7693 80.785 64.7249 80.7595 64.7249 80.6962V79.8499C64.7249 79.7866 64.7693 79.7095 64.8242 79.678Z"
          fill="#FAFAFA"/>
      <path d="M62.8623 78.3552V74.0532L68.4507 70.8267V75.1287L62.8623 78.3552Z" fill="#F0F0F0"/>
      <path d="M62.8623 74.053L55.8765 70.0198V74.3218L62.8623 78.355V74.053Z" fill="#E6E6E6"/>
      <path d="M62.8623 74.0531L68.6163 70.7294L61.4649 66.5996L55.7109 69.9233L62.8623 74.0531Z" fill="#F5F5F5"/>
      <path d="M62.8623 74.0532V75.1287L68.6163 71.8065V70.7295L62.8623 74.0532Z" fill="#F0F0F0"/>
      <path d="M62.8623 75.1286L68.4507 71.9021V72.1385L62.8623 75.365V75.1286Z" fill="#E6E6E6"/>
      <path d="M55.7109 69.9233L62.8623 74.0531V75.1286L55.7109 70.9997V69.9233Z" fill="#E6E6E6"/>
      <path d="M62.8623 75.1287L55.8762 71.0955L55.8765 71.3319L62.8623 75.3651V75.1287Z" fill="#E0E0E0"/>
      <path
          d="M64.702 74.7067L66.6109 73.6045C66.6931 73.5571 66.76 73.5955 66.76 73.6906V74.8192C66.76 74.9143 66.6934 75.0298 66.6109 75.0772L64.702 76.1794C64.6198 76.2268 64.5532 76.1884 64.5532 76.0933V74.9647C64.5532 74.8699 64.6198 74.7544 64.702 74.7067Z"
          fill="#EBEBEB"/>
      <path
          d="M64.8244 74.835L66.4888 73.8741C66.5437 73.8426 66.5881 73.8681 66.5881 73.9314V74.7777C66.5881 74.841 66.5437 74.9181 66.4888 74.9496L64.8244 75.9105C64.7695 75.942 64.7251 75.9165 64.7251 75.8532V75.0069C64.7251 74.9436 64.7695 74.8665 64.8244 74.835Z"
          fill="#FAFAFA"/>
      <path
          d="M68.1496 75.3029L62.8624 78.3554L56.1781 74.4941L56.0146 74.591L62.8624 78.5315L68.3137 75.3977L68.1496 75.3029Z"
          fill="#E0E0E0"/>
      <path opacity="0.5"
            d="M68.1496 75.3029L62.8624 78.3554L56.1781 74.4941L56.0146 74.591L62.8624 78.5315L68.3137 75.3977L68.1496 75.3029Z"
            fill="#E0E0E0"/>
      <path d="M87.1372 83.1982V78.8962L81.5488 75.6697V79.9717L87.1372 83.1982Z" fill="#F0F0F0"/>
      <path d="M87.1375 78.8962L94.1232 74.863V79.165L87.1375 83.1982V78.8962Z" fill="#E6E6E6"/>
      <path d="M87.1373 78.8964L81.3833 75.5727L88.5347 71.4429L94.2887 74.7666L87.1373 78.8964Z" fill="#FAFAFA"/>
      <path d="M87.1373 78.8962V79.9717L81.3833 76.6495V75.5725L87.1373 78.8962Z" fill="#F0F0F0"/>
      <path d="M87.1372 79.9717L81.5488 76.7449V76.9816L87.1372 80.2081V79.9717Z" fill="#E6E6E6"/>
      <path d="M94.2888 74.7664L87.1375 78.8962V79.9717L94.2888 75.8428V74.7664Z" fill="#E6E6E6"/>
      <path d="M87.1375 79.9717L94.1235 75.9385L94.1232 76.1749L87.1375 80.2081V79.9717Z" fill="#E0E0E0"/>
      <path
          d="M85.2977 79.55L83.3885 78.4478C83.3063 78.4004 83.2397 78.4388 83.2397 78.5339V79.6628C83.2397 79.7576 83.3063 79.8734 83.3885 79.9208L85.2977 81.023C85.3799 81.0704 85.4465 81.032 85.4465 80.9369V79.8083C85.4465 79.7129 85.3799 79.5977 85.2977 79.55Z"
          fill="#EBEBEB"/>
      <path
          d="M85.1753 79.678L83.5109 78.7171C83.456 78.6856 83.4116 78.7111 83.4116 78.7744V79.6207C83.4116 79.684 83.456 79.7611 83.5109 79.7926L85.1753 80.7535C85.2302 80.785 85.2746 80.7595 85.2746 80.6962V79.8499C85.2746 79.7866 85.2302 79.7095 85.1753 79.678Z"
          fill="#FAFAFA"/>
      <path d="M87.1372 78.3552V74.0532L81.5488 70.8267V75.1287L87.1372 78.3552Z" fill="#F0F0F0"/>
      <path d="M87.1375 74.053L94.1232 70.0198V74.3218L87.1375 78.355V74.053Z" fill="#E6E6E6"/>
      <path d="M87.1373 74.0531L81.3833 70.7294L88.5347 66.5996L94.2887 69.9233L87.1373 74.0531Z" fill="#F5F5F5"/>
      <path d="M87.1373 74.0532V75.1287L81.3833 71.8065V70.7295L87.1373 74.0532Z" fill="#F0F0F0"/>
      <path d="M87.1372 75.1286L81.5488 71.9021V72.1385L87.1372 75.365V75.1286Z" fill="#E6E6E6"/>
      <path d="M94.2888 69.9233L87.1375 74.0531V75.1286L94.2888 70.9997V69.9233Z" fill="#E6E6E6"/>
      <path d="M87.1375 75.1287L94.1235 71.0955L94.1232 71.3319L87.1375 75.3651V75.1287Z" fill="#E0E0E0"/>
      <path
          d="M85.2977 74.7067L83.3885 73.6045C83.3063 73.5571 83.2397 73.5955 83.2397 73.6906V74.8192C83.2397 74.9143 83.3063 75.0298 83.3885 75.0772L85.2977 76.1794C85.3799 76.2268 85.4465 76.1884 85.4465 76.0933V74.9647C85.4465 74.8699 85.3799 74.7544 85.2977 74.7067Z"
          fill="#EBEBEB"/>
      <path
          d="M85.1753 74.835L83.5109 73.8741C83.456 73.8426 83.4116 73.8681 83.4116 73.9314V74.7777C83.4116 74.841 83.456 74.9181 83.5109 74.9496L85.1753 75.9105C85.2302 75.942 85.2746 75.9165 85.2746 75.8532V75.0069C85.2746 74.9436 85.2302 74.8665 85.1753 74.835Z"
          fill="#FAFAFA"/>
      <path
          d="M81.8501 75.3029L87.1373 78.3554L93.8216 74.4941L93.9851 74.591L87.1373 78.5315L81.686 75.3977L81.8501 75.3029Z"
          fill="#E0E0E0"/>
      <path opacity="0.5"
            d="M81.8501 75.3029L87.1373 78.3554L93.8216 74.4941L93.9851 74.591L87.1373 78.5315L81.686 75.3977L81.8501 75.3029Z"
            fill="#E0E0E0"/>
      <path d="M19.2542 100.623L25.9339 96.7667V82.4426L19.2545 86.2991L19.2542 100.623Z" fill="#7A4721"/>
      <path d="M19.2546 86.2995L9.71216 80.7903V95.1144L19.2543 100.623L19.2546 86.2995Z" fill="#CC7633"/>
      <path d="M9.71191 80.7898L19.2543 86.299L25.9338 82.4425L16.3917 76.9333L9.71191 80.7898Z" fill="#F58220"/>
      <path
          d="M18.4757 93.5635L10.8419 89.1562L10.8422 94.4617L10.6667 94.5634L18.3005 98.9704L18.476 98.869L18.4757 93.5635Z"
          fill="#7A4721"/>
      <path d="M18.3001 93.6649L10.6663 89.2576L10.6666 94.5634L18.3004 98.9704L18.3001 93.6649Z" fill="#FBDEC9"/>
      <path
          d="M15.3078 92.8364L14.0097 92.087C13.8411 91.9895 13.7046 92.0342 13.7046 92.1863C13.7046 92.3384 13.8411 92.5406 14.0097 92.6381L15.3081 93.3875C15.4767 93.4847 15.6132 93.4403 15.6132 93.2882C15.6129 93.1358 15.4764 92.9336 15.3078 92.8364Z"
          fill="#7A4721"/>
      <path
          d="M18.4757 87.159L10.8419 82.7517L10.8422 88.0575L10.6667 88.1589L18.3005 92.5659L18.476 92.4645L18.4757 87.159Z"
          fill="#7A4721"/>
      <path d="M18.3001 87.2603L10.6663 82.853L10.6666 88.1588L18.3004 92.5658L18.3001 87.2603Z" fill="#FBDEC9"/>
      <path
          d="M15.3078 86.4319L14.0097 85.6825C13.8411 85.585 13.7046 85.6297 13.7046 85.7818C13.7046 85.9339 13.8411 86.1361 14.0097 86.2336L15.3081 86.983C15.4767 87.0802 15.6132 87.0358 15.6132 86.8837C15.6129 86.7313 15.4764 86.5291 15.3078 86.4319Z"
          fill="#7A4721"/>
      <path d="M130.746 100.623L124.066 96.7667V82.4426L130.746 86.2991V100.623Z"
            fill="url(#paint1_linear_9638_9100)"/>
      <g opacity="0.2">
        <path d="M130.746 100.623L124.066 96.7667V82.4426L130.746 86.2991V100.623Z" fill="#CC7633"/>
      </g>
      <path d="M130.746 86.2995L140.288 80.7903V95.1144L130.746 100.623V86.2995Z"
            fill="url(#paint2_linear_9638_9100)"/>
      <path d="M140.289 80.7898L130.746 86.299L124.067 82.4425L133.609 76.9333L140.289 80.7898Z"
            fill="url(#paint3_linear_9638_9100)"/>
      <g opacity="0.4">
        <path d="M140.289 80.7898L130.746 86.299L124.067 82.4425L133.609 76.9333L140.289 80.7898Z" fill="white"/>
      </g>
      <path
          d="M131.525 93.5633L139.159 89.156L139.158 94.4615L139.334 94.5632L131.7 98.9702L131.524 98.8688L131.525 93.5633Z"
          fill="url(#paint4_linear_9638_9100)"/>
      <g opacity="0.1">
        <path
            d="M131.525 93.5633L139.159 89.156L139.158 94.4615L139.334 94.5632L131.7 98.9702L131.524 98.8688L131.525 93.5633Z"
            fill="black"/>
      </g>
      <path d="M131.7 93.6649L139.334 89.2576L139.334 94.5634L131.7 98.9704L131.7 93.6649Z"
            fill="url(#paint5_linear_9638_9100)"/>
      <g opacity="0.4">
        <path d="M131.7 93.6649L139.334 89.2576L139.334 94.5634L131.7 98.9704L131.7 93.6649Z" fill="white"/>
      </g>
      <path
          d="M134.692 92.8364L135.99 92.087C136.159 91.9895 136.296 92.0342 136.296 92.1863C136.296 92.3384 136.159 92.5406 135.99 92.6381L134.692 93.3875C134.523 93.4847 134.387 93.4403 134.387 93.2882C134.387 93.1358 134.524 92.9336 134.692 92.8364Z"
          fill="url(#paint6_linear_9638_9100)"/>
      <g opacity="0.1">
        <path
            d="M134.692 92.8364L135.99 92.087C136.159 91.9895 136.296 92.0342 136.296 92.1863C136.296 92.3384 136.159 92.5406 135.99 92.6381L134.692 93.3875C134.523 93.4847 134.387 93.4403 134.387 93.2882C134.387 93.1358 134.524 92.9336 134.692 92.8364Z"
            fill="black"/>
      </g>
      <path
          d="M131.525 87.159L139.159 82.7517L139.158 88.0575L139.334 88.1589L131.7 92.5659L131.524 92.4645L131.525 87.159Z"
          fill="url(#paint7_linear_9638_9100)"/>
      <g opacity="0.1">
        <path
            d="M131.525 87.159L139.159 82.7517L139.158 88.0575L139.334 88.1589L131.7 92.5659L131.524 92.4645L131.525 87.159Z"
            fill="black"/>
      </g>
      <path d="M131.7 87.2603L139.334 82.853L139.334 88.1588L131.7 92.5658L131.7 87.2603Z"
            fill="url(#paint8_linear_9638_9100)"/>
      <g opacity="0.4">
        <path d="M131.7 87.2603L139.334 82.853L139.334 88.1588L131.7 92.5658L131.7 87.2603Z" fill="white"/>
      </g>
      <path
          d="M134.692 86.4319L135.99 85.6825C136.159 85.585 136.296 85.6297 136.296 85.7818C136.296 85.9339 136.159 86.1361 135.99 86.2336L134.692 86.983C134.523 87.0802 134.387 87.0358 134.387 86.8837C134.387 86.7313 134.524 86.5291 134.692 86.4319Z"
          fill="url(#paint9_linear_9638_9100)"/>
      <g opacity="0.1">
        <path
            d="M134.692 86.4319L135.99 85.6825C136.159 85.585 136.296 85.6297 136.296 85.7818C136.296 85.9339 136.159 86.1361 135.99 86.2336L134.692 86.983C134.523 87.0802 134.387 87.0358 134.387 86.8837C134.387 86.7313 134.524 86.5291 134.692 86.4319Z"
            fill="black"/>
      </g>
      <path
          d="M91.3743 82.1297L122.605 100.16L124.501 101.255C124.532 101.271 124.562 101.288 124.589 101.301C124.642 101.326 124.694 101.348 124.749 101.364C124.812 101.389 124.88 101.408 124.946 101.419C124.99 101.43 125.034 101.436 125.078 101.441C125.086 101.444 125.097 101.446 125.105 101.444C125.16 101.452 125.218 101.455 125.272 101.455C125.464 101.457 125.64 101.419 125.772 101.345C126.079 101.166 126.397 100.662 126.483 100.215L131.746 72.8741C131.757 72.8165 131.762 72.7535 131.762 72.6929C131.762 72.6434 131.759 72.5942 131.751 72.5447C131.751 72.542 131.751 72.542 131.751 72.5393C131.74 72.4652 131.724 72.3884 131.699 72.317C131.674 72.2429 131.641 72.1715 131.605 72.1031C131.584 72.0593 131.556 72.0152 131.529 71.9741C131.496 71.9273 131.46 71.8835 131.422 71.8424C131.389 71.804 131.35 71.7683 131.312 71.738C131.273 71.705 131.23 71.6777 131.188 71.6528L98.0607 52.5278C98.0334 52.5113 98.0031 52.4948 97.9728 52.481C97.9425 52.4672 97.9125 52.4507 97.8795 52.4399C97.8657 52.4345 97.8522 52.4288 97.8357 52.4261C97.7946 52.4153 97.7535 52.4096 97.7151 52.4123C97.5093 52.4288 97.3338 52.5686 97.128 52.6895C96.7356 52.9199 96.3927 53.1203 96.3927 53.1203V53.123C96.3378 53.1449 96.2883 53.1806 96.2445 53.2274C96.1869 53.2877 96.1401 53.3645 96.1101 53.4551C96.0963 53.4935 96.0882 53.5319 96.0798 53.5676L90.8169 80.9081C90.732 81.3557 90.9816 81.9017 91.3743 82.1297Z"
          fill="url(#paint10_linear_9638_9100)"/>
      <path
          d="M91.3743 82.1297L122.605 100.16L124.501 101.255C124.532 101.271 124.562 101.288 124.589 101.301C124.642 101.326 124.694 101.348 124.749 101.364C124.812 101.389 124.88 101.408 124.946 101.419C124.99 101.43 125.034 101.436 125.078 101.441C125.086 101.444 125.097 101.446 125.105 101.444C125.16 101.452 125.218 101.455 125.272 101.455C125.464 101.457 125.64 101.419 125.772 101.345C126.079 101.166 126.397 100.662 126.483 100.215L131.746 72.8741C131.757 72.8165 131.762 72.7535 131.762 72.6929C131.762 72.6434 131.759 72.5942 131.751 72.5447C131.751 72.542 131.751 72.542 131.751 72.5393C131.74 72.4652 131.724 72.3884 131.699 72.317C131.674 72.2429 131.641 72.1715 131.605 72.1031C131.584 72.0593 131.556 72.0152 131.529 71.9741C131.496 71.9273 131.46 71.8835 131.422 71.8424C131.389 71.804 131.35 71.7683 131.312 71.738C131.273 71.705 131.23 71.6777 131.188 71.6528L98.0607 52.5278C98.0334 52.5113 98.0031 52.4948 97.9728 52.481C97.9425 52.4672 97.9125 52.4507 97.8795 52.4399C97.8657 52.4345 97.8522 52.4288 97.8357 52.4261C97.7946 52.4153 97.7535 52.4096 97.7151 52.4123C97.5093 52.4288 97.3338 52.5686 97.128 52.6895C96.7356 52.9199 96.3927 53.1203 96.3927 53.1203V53.123C96.3378 53.1449 96.2883 53.1806 96.2445 53.2274C96.1869 53.2877 96.1401 53.3645 96.1101 53.4551C96.0963 53.4935 96.0882 53.5319 96.0798 53.5676L90.8169 80.9081C90.732 81.3557 90.9816 81.9017 91.3743 82.1297Z"
          fill="url(#paint11_linear_9638_9100)"/>
      <path
          d="M124.589 101.301C124.976 101.488 125.484 101.51 125.772 101.345C126.079 101.167 126.397 100.662 126.483 100.215L131.746 72.8743C131.792 72.6247 131.734 72.3421 131.606 72.1033L130.492 72.7453C130.621 72.9841 130.678 73.2667 130.631 73.5163L125.368 100.859C125.289 101.271 124.951 101.455 124.589 101.301Z"
          fill="#FBDEC9"/>
      <path
          d="M97.1279 52.6899C96.7355 52.9203 96.3926 53.1207 96.3926 53.1207V53.1234C96.5519 53.0466 96.7493 53.0574 96.947 53.1729L130.074 72.2982C130.247 72.3969 130.39 72.5589 130.491 72.7455L131.606 72.1035C131.584 72.0597 131.556 72.0156 131.529 71.9745C131.496 71.9277 131.46 71.8839 131.422 71.8428C131.353 71.7687 131.274 71.703 131.189 71.6535L98.0612 52.5285C98.0036 52.4955 97.9433 52.4655 97.88 52.4406C97.8662 52.4352 97.8527 52.4295 97.8362 52.4268C97.7951 52.416 97.754 52.4103 97.7156 52.413C97.5095 52.4292 97.334 52.569 97.1279 52.6899Z"
          fill="#37474F"/>
      <path
          d="M97.1279 52.6899C96.7355 52.9203 96.3926 53.1207 96.3926 53.1207V53.1234C96.5519 53.0466 96.7493 53.0574 96.947 53.1729L130.074 72.2982C130.247 72.3969 130.39 72.5589 130.491 72.7455L131.606 72.1035C131.584 72.0597 131.556 72.0156 131.529 71.9745C131.496 71.9277 131.46 71.8839 131.422 71.8428C131.353 71.7687 131.274 71.703 131.189 71.6535L98.0612 52.5285C98.0036 52.4955 97.9433 52.4655 97.88 52.4406C97.8662 52.4352 97.8527 52.4295 97.8362 52.4268C97.7951 52.416 97.754 52.4103 97.7156 52.413C97.5095 52.4292 97.334 52.569 97.1279 52.6899Z"
          fill="#FBDEC9"/>
      <path
          d="M125.369 100.859L130.631 73.5172C130.717 73.0708 130.468 72.5245 130.074 72.2974L96.9472 53.1718C96.5536 52.9444 96.1647 53.122 96.0786 53.5684L90.8169 80.9095C90.7311 81.3559 90.9804 81.9022 91.3743 82.1293L124.501 101.255C124.895 101.483 125.283 101.305 125.369 100.859Z"
          fill="url(#paint12_linear_9638_9100)"/>
      <path
          d="M125.369 100.859L130.631 73.5172C130.717 73.0708 130.468 72.5245 130.074 72.2974L96.9472 53.1718C96.5536 52.9444 96.1647 53.122 96.0786 53.5684L90.8169 80.9095C90.7311 81.3559 90.9804 81.9022 91.3743 82.1293L124.501 101.255C124.895 101.483 125.283 101.305 125.369 100.859Z"
          fill="#37474F"/>
      <path
          d="M125.369 100.859L130.631 73.5172C130.717 73.0708 130.468 72.5245 130.074 72.2974L96.9472 53.1718C96.5536 52.9444 96.1647 53.122 96.0786 53.5684L90.8169 80.9095C90.7311 81.3559 90.9804 81.9022 91.3743 82.1293L124.501 101.255C124.895 101.483 125.283 101.305 125.369 100.859Z"
          fill="#CC7633"/>
      <path
          d="M100.257 81.0251L122.725 93.9962L124.089 94.7837C124.111 94.7957 124.132 94.8074 124.152 94.8173C124.19 94.835 124.227 94.8509 124.267 94.8626C124.312 94.8803 124.362 94.8941 124.409 94.9022C124.441 94.91 124.472 94.9139 124.504 94.9181C124.51 94.9202 124.518 94.922 124.524 94.9202C124.563 94.9262 124.605 94.928 124.644 94.928C124.782 94.9301 124.909 94.9022 125.003 94.8491C125.224 94.7207 125.453 94.3577 125.515 94.0358L129.301 74.3672C129.308 74.3258 129.312 74.2802 129.312 74.237C129.312 74.2016 129.31 74.1659 129.305 74.1305C129.305 74.1284 129.305 74.1284 129.305 74.1266C129.297 74.0732 129.285 74.018 129.267 73.9667C129.249 73.9133 129.226 73.862 129.2 73.8128C129.184 73.7813 129.164 73.7495 129.145 73.7201C129.121 73.6865 129.095 73.655 129.067 73.6253C129.044 73.5977 129.016 73.5719 128.989 73.5503C128.961 73.5266 128.929 73.5068 128.9 73.4891L105.068 59.7305C105.048 59.7188 105.027 59.7068 105.005 59.6969C104.983 59.687 104.961 59.6753 104.938 59.6672C104.928 59.6633 104.918 59.6594 104.906 59.6573C104.876 59.6495 104.847 59.6456 104.819 59.6474C104.671 59.6594 104.545 59.7599 104.397 59.8469C104.114 60.0128 103.867 60.1568 103.867 60.1568V60.1589C103.828 60.1748 103.792 60.2003 103.761 60.2339C103.72 60.2774 103.686 60.3326 103.664 60.3977C103.654 60.4253 103.648 60.4529 103.643 60.4787L99.8568 80.1473C99.7953 80.4686 99.975 80.8613 100.257 81.0251Z"
          fill="url(#paint13_linear_9638_9100)"/>
      <path
          d="M100.257 81.0251L122.725 93.9962L124.089 94.7837C124.111 94.7957 124.132 94.8074 124.152 94.8173C124.19 94.835 124.227 94.8509 124.267 94.8626C124.312 94.8803 124.362 94.8941 124.409 94.9022C124.441 94.91 124.472 94.9139 124.504 94.9181C124.51 94.9202 124.518 94.922 124.524 94.9202C124.563 94.9262 124.605 94.928 124.644 94.928C124.782 94.9301 124.909 94.9022 125.003 94.8491C125.224 94.7207 125.453 94.3577 125.515 94.0358L129.301 74.3672C129.308 74.3258 129.312 74.2802 129.312 74.237C129.312 74.2016 129.31 74.1659 129.305 74.1305C129.305 74.1284 129.305 74.1284 129.305 74.1266C129.297 74.0732 129.285 74.018 129.267 73.9667C129.249 73.9133 129.226 73.862 129.2 73.8128C129.184 73.7813 129.164 73.7495 129.145 73.7201C129.121 73.6865 129.095 73.655 129.067 73.6253C129.044 73.5977 129.016 73.5719 128.989 73.5503C128.961 73.5266 128.929 73.5068 128.9 73.4891L105.068 59.7305C105.048 59.7188 105.027 59.7068 105.005 59.6969C104.983 59.687 104.961 59.6753 104.938 59.6672C104.928 59.6633 104.918 59.6594 104.906 59.6573C104.876 59.6495 104.847 59.6456 104.819 59.6474C104.671 59.6594 104.545 59.7599 104.397 59.8469C104.114 60.0128 103.867 60.1568 103.867 60.1568V60.1589C103.828 60.1748 103.792 60.2003 103.761 60.2339C103.72 60.2774 103.686 60.3326 103.664 60.3977C103.654 60.4253 103.648 60.4529 103.643 60.4787L99.8568 80.1473C99.7953 80.4686 99.975 80.8613 100.257 81.0251Z"
          fill="url(#paint14_linear_9638_9100)"/>
      <path
          d="M124.152 94.8174C124.431 94.9515 124.796 94.9674 125.003 94.8489C125.224 94.7205 125.453 94.3575 125.514 94.0356L129.3 74.367C129.334 74.1873 129.293 73.9839 129.2 73.8123L128.398 74.2743C128.491 74.4459 128.532 74.6493 128.499 74.829L124.713 94.4997C124.656 94.7958 124.413 94.9281 124.152 94.8174Z"
          fill="#E0E0E0"/>
      <path
          d="M104.397 59.8467C104.114 60.0126 103.867 60.1566 103.867 60.1566V60.1587C103.982 60.1035 104.124 60.1113 104.266 60.1941L128.098 73.9527C128.222 74.0238 128.325 74.1402 128.398 74.2743L129.199 73.8123C129.183 73.7808 129.164 73.749 129.144 73.7196C129.12 73.686 129.095 73.6545 129.067 73.6248C129.017 73.5714 128.96 73.524 128.899 73.4886L105.067 59.73C105.026 59.7063 104.983 59.6847 104.937 59.6667C104.927 59.6628 104.917 59.6589 104.906 59.6568C104.876 59.649 104.847 59.6451 104.819 59.6469C104.671 59.6592 104.545 59.7597 104.397 59.8467Z"
          fill="#FAFAFA"/>
      <path
          d="M124.713 94.4991L128.499 74.8299C128.56 74.5089 128.381 74.1159 128.098 73.9524L104.267 60.1935C103.984 60.03 103.704 60.1578 103.642 60.4788L99.8564 80.148C99.7946 80.4693 99.974 80.862 100.257 81.0255L124.088 94.7844C124.372 94.9479 124.651 94.8201 124.713 94.4991Z"
          fill="#F5F5F5"/>
      <path
          d="M107.087 75.2414C107.255 75.1445 107.391 74.8982 107.391 74.6915V38.9213C107.391 38.7146 107.246 38.4632 107.067 38.3597L84.6041 25.3907C84.425 25.2872 84.1439 25.2821 83.9759 25.379C83.8079 25.4759 83.6717 25.7222 83.6717 25.9289L83.6714 61.6991C83.6714 61.9058 83.8166 62.1572 83.9957 62.2607L106.459 75.2297C106.638 75.3332 106.919 75.3383 107.087 75.2414Z"
          fill="#EBEBEB"/>
      <path
          d="M83.6746 25.8721C83.7019 25.7074 83.8339 25.6498 83.9956 25.7431L106.46 38.7109C106.551 38.7631 106.633 38.8537 106.688 38.9551L107.297 38.6038C107.24 38.5024 107.157 38.4118 107.067 38.3596L84.6052 25.3918C84.427 25.2874 84.1444 25.282 83.9767 25.378C83.8255 25.4686 83.6965 25.6771 83.6746 25.8721Z"
          fill="#FAFAFA"/>
      <path
          d="M106.504 75.2517C106.679 75.3342 106.932 75.3312 107.088 75.2409C107.256 75.1449 107.39 74.898 107.39 74.6922V38.9223C107.39 38.8179 107.354 38.7054 107.297 38.604L106.688 38.9553C106.748 39.057 106.784 39.1692 106.784 39.2736V75.0435C106.784 75.2328 106.66 75.3204 106.504 75.2517Z"
          fill="#E0E0E0"/>
      <path
          d="M106.459 75.2295L83.9957 62.2605C83.8166 62.157 83.6714 61.9056 83.6714 61.6989L83.6717 25.9287C83.6717 25.722 83.8169 25.6383 83.996 25.7415L106.459 38.7105C106.638 38.8137 106.783 39.0651 106.783 39.2721L106.783 75.0423C106.783 75.2493 106.638 75.333 106.459 75.2295Z"
          fill="#F5F5F5"/>
      <g opacity="0.3">
        <path
            d="M105.143 55.0059C105.143 55.281 104.896 55.3611 104.591 55.1853L85.8637 44.3721C85.5592 44.1963 85.3123 43.8309 85.3123 43.5558C85.3123 43.2807 85.5592 43.2006 85.8637 43.3764L104.591 54.1896C104.896 54.3654 105.143 54.7308 105.143 55.0059Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 57.9925C105.143 58.2676 104.896 58.3477 104.591 58.1719L85.8637 47.3587C85.5592 47.1829 85.3123 46.8175 85.3123 46.5424C85.3123 46.2673 85.5592 46.1872 85.8637 46.363L104.591 57.1762C104.896 57.3523 105.143 57.7177 105.143 57.9925Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 60.9793C105.143 61.2541 104.896 61.3345 104.591 61.1587L85.8637 50.3455C85.5592 50.1697 85.3123 49.8043 85.3123 49.5292C85.3123 49.2541 85.5592 49.174 85.8637 49.3498L104.591 60.163C104.896 60.3391 105.143 60.7045 105.143 60.9793Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 63.9661C105.143 64.2412 104.896 64.3213 104.591 64.1455L85.8637 53.3323C85.5592 53.1565 85.3123 52.7911 85.3123 52.516C85.3123 52.2409 85.5592 52.1608 85.8637 52.3366L104.591 63.1498C104.896 63.3259 105.143 63.6913 105.143 63.9661Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 46.0459C105.143 46.3207 104.896 46.4011 104.591 46.2253L85.8637 35.4121C85.5592 35.2363 85.3123 34.8709 85.3123 34.5958C85.3123 34.321 85.5592 34.2406 85.8637 34.4164L104.591 45.2296C104.896 45.4057 105.143 45.7711 105.143 46.0459Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 49.0323C105.143 49.3071 104.896 49.3875 104.591 49.2117L85.8637 38.3985C85.5592 38.2227 85.3123 37.8573 85.3123 37.5822C85.3123 37.3071 85.5592 37.227 85.8637 37.4028L104.591 48.216C104.896 48.3918 105.143 48.7572 105.143 49.0323Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 43.0632C105.143 43.3383 104.896 43.4184 104.591 43.2426L85.8637 32.4294C85.5592 32.2536 85.3123 31.8882 85.3123 31.6131C85.3123 31.3383 85.5592 31.2579 85.8637 31.4337L104.591 42.2469C104.896 42.4227 105.143 42.7884 105.143 43.0632Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 52.0191C105.143 52.2939 104.896 52.3743 104.591 52.1985L85.8637 41.3853C85.5592 41.2095 85.3123 40.8441 85.3123 40.569C85.3123 40.2939 85.5592 40.2138 85.8637 40.3896L104.591 51.2028C104.896 51.3786 105.143 51.744 105.143 52.0191Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 66.9529C105.143 67.228 104.896 67.3081 104.591 67.1323L85.8637 56.3191C85.5592 56.1433 85.3123 55.7779 85.3123 55.5028C85.3123 55.2277 85.5592 55.1476 85.8637 55.3234L104.591 66.1366C104.896 66.3124 105.143 66.6781 105.143 66.9529Z"
            fill="#C2C2C2"/>
        <path
            d="M105.143 69.9397C105.143 70.2148 104.896 70.2949 104.591 70.1191L85.8637 59.3059C85.5592 59.1301 85.3123 58.7647 85.3123 58.4896C85.3123 58.2148 85.5592 58.1344 85.8637 58.3102L104.591 69.1234C104.896 69.2992 105.143 69.6646 105.143 69.9397Z"
            fill="#C2C2C2"/>
      </g>
      <path
          d="M90.0351 56.1158C90.0351 55.8878 90.1134 55.7273 90.2388 55.6463C90.2388 55.6463 90.2568 55.6313 90.2916 55.6157L91.3653 54.989C91.3653 54.989 91.3659 54.9887 91.3665 54.9884L91.3689 54.9869V54.9875C91.503 54.9116 91.6872 54.923 91.8909 55.0406L113.362 67.4216L113.987 64.9004C114.044 64.7102 114.157 64.5722 114.297 64.4897L114.293 64.4879L115.41 63.839L115.409 63.8435C115.603 63.7289 115.851 63.7235 116.089 63.8612L125.224 69.1337C125.634 69.3704 125.967 69.9461 125.967 70.4198L125.966 100.88C125.966 101.107 125.891 101.269 125.766 101.35L124.616 102.017C124.484 102.084 124.307 102.069 124.111 101.955L90.765 82.7033C90.3549 82.4666 90.0222 81.8906 90.0222 81.4172L90.0351 56.1158Z"
          fill="url(#paint15_linear_9638_9100)"/>
      <path
          d="M114.312 64.4781C114.504 64.3719 114.744 64.3752 114.977 64.509L124.111 69.7809C124.314 69.9009 124.499 70.1034 124.636 70.3365L125.749 69.6882C125.615 69.4551 125.429 69.249 125.224 69.1326L116.09 63.8607C115.85 63.7236 115.603 63.7305 115.411 63.8436V63.8403L114.312 64.4781Z"
          fill="url(#paint16_linear_9638_9100)"/>
      <path
          d="M90.2915 55.6154C90.4217 55.5605 90.5933 55.5812 90.7784 55.6874L112.27 68.0864C112.682 68.3231 113.124 68.1482 113.261 67.6919L113.361 67.421L91.8908 55.0391C91.7741 54.9704 91.661 54.9395 91.5614 54.9395C91.4894 54.9395 91.4243 54.9566 91.3694 54.9875H91.3661L90.2915 55.6154Z"
          fill="#F58220"/>
      <path
          d="M90.0357 56.117L90.0225 81.4175C90.0225 81.8921 90.3546 82.4684 90.7662 82.7045L124.197 102.005C124.489 102.174 124.854 101.963 124.854 101.626V71.0675C124.854 70.8317 124.772 70.568 124.637 70.3376C124.5 70.1045 124.313 69.9014 124.113 69.7832L114.979 64.5095C114.745 64.3778 114.504 64.3724 114.312 64.4792L114.292 64.49H114.298C114.158 64.5722 114.046 64.7096 113.988 64.9016L113.362 67.4216L113.263 67.6934C113.126 68.1488 112.684 68.3246 112.272 68.0885L90.7791 55.6886C90.5952 55.5815 90.4224 55.5623 90.2934 55.6172L90.2631 55.6337C90.2577 55.6364 90.2523 55.6391 90.2466 55.6418L90.2385 55.6472C90.1152 55.7276 90.0357 55.8893 90.0357 56.117Z"
          fill="#FFF4EB"/>
      <path
          d="M105.619 80.4327C105.668 80.4099 105.719 80.3991 105.769 80.3991C105.901 80.3991 106.027 80.4735 106.087 80.6007L107.086 82.7148V78.9657C107.086 78.7713 107.244 78.6138 107.438 78.6138C107.632 78.6138 107.79 78.7713 107.79 78.9657V83.457L108.863 82.4244C109.003 82.2891 109.226 82.2942 109.361 82.434C109.495 82.5741 109.491 82.797 109.351 82.9317L107.682 84.5373C107.6 84.6159 107.488 84.6507 107.374 84.63C107.263 84.6093 107.168 84.5364 107.12 84.4341L105.451 80.9016C105.368 80.7252 105.443 80.5155 105.619 80.4327Z"
          fill="#F58220"/>
      <path
          d="M109.835 81.2352C109.64 81.2352 109.483 81.0777 109.483 80.8833V78.7602L105.393 76.3989V78.1158C105.393 78.3102 105.236 78.4678 105.042 78.4678C104.847 78.4678 104.69 78.3102 104.69 78.1158V75.7896C104.69 75.6639 104.757 75.5478 104.865 75.4848C104.92 75.4533 104.981 75.4377 105.042 75.4377C105.102 75.4377 105.163 75.4536 105.217 75.4848L110.01 78.252C110.119 78.3147 110.186 78.4308 110.186 78.5568V80.883C110.187 81.0777 110.029 81.2352 109.835 81.2352Z"
          fill="#F58220"/>
      <path
          d="M124.584 102.028C124.595 102.026 124.606 102.023 124.617 102.017L125.767 101.35C125.89 101.268 125.967 101.106 125.967 100.879V70.4186C125.967 70.1798 125.882 69.9218 125.747 69.6887L124.636 70.3364V70.3391C124.771 70.5695 124.853 70.8329 124.853 71.069V101.625C124.853 101.815 124.738 101.965 124.584 102.028Z"
          fill="url(#paint17_linear_9638_9100)"/>
      <path
          d="M58.6257 82.1297L27.3945 100.16L25.4985 101.255C25.4682 101.271 25.4382 101.288 25.4106 101.301C25.3584 101.326 25.3062 101.348 25.2513 101.364C25.1883 101.389 25.1196 101.408 25.0536 101.419C25.0098 101.43 24.9657 101.436 24.9219 101.441C24.9135 101.444 24.9027 101.446 24.8943 101.444C24.8394 101.452 24.7818 101.455 24.7269 101.455C24.5349 101.457 24.3591 101.419 24.2274 101.345C23.9202 101.166 23.6019 100.662 23.5167 100.215L18.2538 72.8741C18.2427 72.8165 18.2373 72.7535 18.2373 72.6929C18.2373 72.6434 18.24 72.5942 18.2484 72.5447C18.2484 72.542 18.2484 72.542 18.2484 72.5393C18.2595 72.4652 18.2757 72.3884 18.3006 72.317C18.3252 72.2429 18.3582 72.1715 18.3939 72.1031C18.4158 72.0593 18.4434 72.0152 18.4707 71.9741C18.5037 71.9273 18.5394 71.8835 18.5778 71.8424C18.6108 71.804 18.6492 71.7683 18.6876 71.738C18.726 71.705 18.7698 71.6777 18.8112 71.6528L51.9387 52.5278C51.9663 52.5113 51.9963 52.4948 52.0266 52.481C52.0569 52.4672 52.0869 52.4507 52.1199 52.4399C52.1337 52.4345 52.1472 52.4288 52.1637 52.4261C52.2048 52.4153 52.2459 52.4096 52.2843 52.4123C52.4901 52.4288 52.6656 52.5686 52.8714 52.6895C53.2638 52.9199 53.6067 53.1203 53.6067 53.1203V53.123C53.6616 53.1449 53.7111 53.1806 53.7549 53.2274C53.8125 53.2877 53.8593 53.3645 53.8893 53.4551C53.9031 53.4935 53.9112 53.5319 53.9196 53.5676L59.1825 80.9081C59.268 81.3557 59.0181 81.9017 58.6257 82.1297Z"
          fill="url(#paint18_linear_9638_9100)"/>
      <path
          d="M58.6257 82.1297L27.3945 100.16L25.4985 101.255C25.4682 101.271 25.4382 101.288 25.4106 101.301C25.3584 101.326 25.3062 101.348 25.2513 101.364C25.1883 101.389 25.1196 101.408 25.0536 101.419C25.0098 101.43 24.9657 101.436 24.9219 101.441C24.9135 101.444 24.9027 101.446 24.8943 101.444C24.8394 101.452 24.7818 101.455 24.7269 101.455C24.5349 101.457 24.3591 101.419 24.2274 101.345C23.9202 101.166 23.6019 100.662 23.5167 100.215L18.2538 72.8741C18.2427 72.8165 18.2373 72.7535 18.2373 72.6929C18.2373 72.6434 18.24 72.5942 18.2484 72.5447C18.2484 72.542 18.2484 72.542 18.2484 72.5393C18.2595 72.4652 18.2757 72.3884 18.3006 72.317C18.3252 72.2429 18.3582 72.1715 18.3939 72.1031C18.4158 72.0593 18.4434 72.0152 18.4707 71.9741C18.5037 71.9273 18.5394 71.8835 18.5778 71.8424C18.6108 71.804 18.6492 71.7683 18.6876 71.738C18.726 71.705 18.7698 71.6777 18.8112 71.6528L51.9387 52.5278C51.9663 52.5113 51.9963 52.4948 52.0266 52.481C52.0569 52.4672 52.0869 52.4507 52.1199 52.4399C52.1337 52.4345 52.1472 52.4288 52.1637 52.4261C52.2048 52.4153 52.2459 52.4096 52.2843 52.4123C52.4901 52.4288 52.6656 52.5686 52.8714 52.6895C53.2638 52.9199 53.6067 53.1203 53.6067 53.1203V53.123C53.6616 53.1449 53.7111 53.1806 53.7549 53.2274C53.8125 53.2877 53.8593 53.3645 53.8893 53.4551C53.9031 53.4935 53.9112 53.5319 53.9196 53.5676L59.1825 80.9081C59.268 81.3557 59.0181 81.9017 58.6257 82.1297Z"
          fill="url(#paint19_linear_9638_9100)"/>
      <path opacity="0.2"
            d="M25.4107 101.301C25.0237 101.488 24.5161 101.51 24.2281 101.345C23.9209 101.167 23.6026 100.662 23.5174 100.215L18.2545 72.8743C18.208 72.6247 18.2656 72.3421 18.3943 72.1033L19.5082 72.7453C19.3792 72.9841 19.3216 73.2667 19.3684 73.5163L24.6313 100.859C24.7111 101.271 25.0486 101.455 25.4107 101.301Z"
            fill="black"/>
      <path opacity="0.1"
            d="M52.872 52.69C53.2644 52.9204 53.6073 53.1208 53.6073 53.1208V53.1235C53.448 53.0467 53.2506 53.0575 53.0529 53.173L19.9257 72.298C19.7529 72.3967 19.6101 72.5587 19.5087 72.7453L18.3948 72.1033C18.4167 72.0595 18.4443 72.0154 18.4716 71.9743C18.5046 71.9275 18.5403 71.8837 18.5787 71.8426C18.6474 71.7685 18.7269 71.7028 18.8118 71.6533L51.9393 52.5283C51.9969 52.4953 52.0572 52.4653 52.1205 52.4404C52.1343 52.435 52.1478 52.4293 52.1643 52.4266C52.2054 52.4158 52.2465 52.4101 52.2849 52.4128C52.4904 52.4293 52.6659 52.5691 52.872 52.69Z"
            fill="white"/>
      <path
          d="M24.6309 100.859L19.369 73.5172C19.2832 73.0708 19.5324 72.5245 19.9263 72.2974L53.053 53.1718C53.4466 52.9444 53.8354 53.122 53.9215 53.5684L59.1832 80.9095C59.269 81.3559 59.0197 81.9022 58.6258 82.1293L25.4991 101.255C25.1055 101.483 24.7167 101.305 24.6309 100.859Z"
          fill="url(#paint20_linear_9638_9100)"/>
      <path opacity="0.15"
            d="M24.6309 100.859L19.369 73.5172C19.2832 73.0708 19.5324 72.5245 19.9263 72.2974L53.053 53.1718C53.4466 52.9444 53.8354 53.122 53.9215 53.5684L59.1832 80.9095C59.269 81.3559 59.0197 81.9022 58.6258 82.1293L25.4991 101.255C25.1055 101.483 24.7167 101.305 24.6309 100.859Z"
            fill="black"/>
      <path
          d="M49.7425 81.0251L27.2749 93.9962L25.9108 94.7837C25.8892 94.7957 25.8673 94.8074 25.8475 94.8173C25.81 94.835 25.7725 94.8509 25.7329 94.8626C25.6876 94.8803 25.6381 94.8941 25.5907 94.9022C25.5592 94.91 25.5274 94.9139 25.4959 94.9181C25.4899 94.9202 25.4821 94.922 25.4761 94.9202C25.4365 94.9262 25.3951 94.928 25.3558 94.928C25.2175 94.9301 25.0912 94.9022 24.9967 94.8491C24.7756 94.7207 24.5467 94.3577 24.4855 94.0358L20.6995 74.3672C20.6917 74.3258 20.6875 74.2802 20.6875 74.237C20.6875 74.2016 20.6896 74.1659 20.6953 74.1305C20.6953 74.1284 20.6953 74.1284 20.6953 74.1266C20.7031 74.0732 20.7151 74.018 20.7328 73.9667C20.7505 73.9133 20.7742 73.862 20.8 73.8128C20.8159 73.7813 20.8354 73.7495 20.8552 73.7201C20.8789 73.6865 20.9044 73.655 20.9323 73.6253C20.956 73.5977 20.9836 73.5719 21.0112 73.5503C21.0388 73.5266 21.0703 73.5068 21.1 73.4891L44.9317 59.7305C44.9515 59.7188 44.9731 59.7068 44.9947 59.6969C45.0163 59.687 45.0382 59.6753 45.0619 59.6672C45.0718 59.6633 45.0817 59.6594 45.0934 59.6573C45.1231 59.6495 45.1525 59.6456 45.1804 59.6474C45.3283 59.6594 45.4549 59.7599 45.6028 59.8469C45.8851 60.0128 46.1317 60.1568 46.1317 60.1568V60.1589C46.1713 60.1748 46.2067 60.2003 46.2382 60.2339C46.2796 60.2774 46.3132 60.3326 46.3348 60.3977C46.3447 60.4253 46.3507 60.4529 46.3564 60.4787L50.1424 80.1473C50.2045 80.4686 50.0248 80.8613 49.7425 81.0251Z"
          fill="url(#paint21_linear_9638_9100)"/>
      <path
          d="M49.7425 81.0251L27.2749 93.9962L25.9108 94.7837C25.8892 94.7957 25.8673 94.8074 25.8475 94.8173C25.81 94.835 25.7725 94.8509 25.7329 94.8626C25.6876 94.8803 25.6381 94.8941 25.5907 94.9022C25.5592 94.91 25.5274 94.9139 25.4959 94.9181C25.4899 94.9202 25.4821 94.922 25.4761 94.9202C25.4365 94.9262 25.3951 94.928 25.3558 94.928C25.2175 94.9301 25.0912 94.9022 24.9967 94.8491C24.7756 94.7207 24.5467 94.3577 24.4855 94.0358L20.6995 74.3672C20.6917 74.3258 20.6875 74.2802 20.6875 74.237C20.6875 74.2016 20.6896 74.1659 20.6953 74.1305C20.6953 74.1284 20.6953 74.1284 20.6953 74.1266C20.7031 74.0732 20.7151 74.018 20.7328 73.9667C20.7505 73.9133 20.7742 73.862 20.8 73.8128C20.8159 73.7813 20.8354 73.7495 20.8552 73.7201C20.8789 73.6865 20.9044 73.655 20.9323 73.6253C20.956 73.5977 20.9836 73.5719 21.0112 73.5503C21.0388 73.5266 21.0703 73.5068 21.1 73.4891L44.9317 59.7305C44.9515 59.7188 44.9731 59.7068 44.9947 59.6969C45.0163 59.687 45.0382 59.6753 45.0619 59.6672C45.0718 59.6633 45.0817 59.6594 45.0934 59.6573C45.1231 59.6495 45.1525 59.6456 45.1804 59.6474C45.3283 59.6594 45.4549 59.7599 45.6028 59.8469C45.8851 60.0128 46.1317 60.1568 46.1317 60.1568V60.1589C46.1713 60.1748 46.2067 60.2003 46.2382 60.2339C46.2796 60.2774 46.3132 60.3326 46.3348 60.3977C46.3447 60.4253 46.3507 60.4529 46.3564 60.4787L50.1424 80.1473C50.2045 80.4686 50.0248 80.8613 49.7425 81.0251Z"
          fill="url(#paint22_linear_9638_9100)"/>
      <path
          d="M25.8476 94.8174C25.5692 94.9515 25.2041 94.9674 24.9968 94.8489C24.7757 94.7205 24.5468 94.3575 24.4856 94.0356L20.6996 74.367C20.666 74.1873 20.7074 73.9839 20.8004 73.8123L21.6017 74.2743C21.509 74.4459 21.4676 74.6493 21.5012 74.829L25.2872 94.4997C25.3442 94.7958 25.5872 94.9281 25.8476 94.8174Z"
          fill="#E0E0E0"/>
      <path
          d="M45.6031 59.8467C45.8854 60.0126 46.132 60.1566 46.132 60.1566V60.1587C46.0174 60.1035 45.8755 60.1113 45.7333 60.1941L21.9016 73.9527C21.7771 74.0238 21.6745 74.1402 21.6016 74.2743L20.8003 73.8123C20.8162 73.7808 20.8357 73.749 20.8555 73.7196C20.8792 73.686 20.9047 73.6545 20.9326 73.6248C20.9821 73.5714 21.0391 73.524 21.1003 73.4886L44.932 59.73C44.9734 59.7063 45.0169 59.6847 45.0622 59.6667C45.0721 59.6628 45.082 59.6589 45.0937 59.6568C45.1234 59.649 45.1528 59.6451 45.1807 59.6469C45.3286 59.6592 45.4552 59.7597 45.6031 59.8467Z"
          fill="#FAFAFA"/>
      <path
          d="M25.2866 94.4991L21.5012 74.8299C21.4394 74.5089 21.6188 74.1159 21.9023 73.9524L45.7334 60.1935C46.0166 60.03 46.2965 60.1578 46.3583 60.4788L50.1437 80.148C50.2055 80.4693 50.0261 80.862 49.7429 81.0255L25.9118 94.7844C25.6283 94.9479 25.3484 94.8201 25.2866 94.4991Z"
          fill="#F5F5F5"/>
      <path
          d="M42.9126 75.2414C42.7446 75.1445 42.6084 74.8982 42.6084 74.6915V38.9213C42.6084 38.7146 42.7536 38.4632 42.9327 38.3597L65.3955 25.3907C65.5746 25.2872 65.8557 25.2821 66.0237 25.379C66.1917 25.4759 66.3279 25.7222 66.3279 25.9289L66.3282 61.6991C66.3282 61.9058 66.183 62.1572 66.0039 62.2607L43.5408 75.2297C43.362 75.3332 43.0806 75.3383 42.9126 75.2414Z"
          fill="#EBEBEB"/>
      <path
          d="M66.3255 25.8721C66.2979 25.7074 66.1662 25.6498 66.0045 25.7431L43.5399 38.7109C43.4493 38.7631 43.3671 38.8537 43.3122 38.9551L42.7029 38.6038C42.7605 38.5024 42.8427 38.4118 42.9333 38.3596L65.3949 25.3918C65.5731 25.2874 65.856 25.282 66.0231 25.378C66.1746 25.4686 66.3036 25.6771 66.3255 25.8721Z"
          fill="#FAFAFA"/>
      <path
          d="M43.4961 75.2517C43.3206 75.3342 43.068 75.3312 42.9117 75.2409C42.7443 75.1449 42.6099 74.898 42.6099 74.6922V38.9223C42.6099 38.8179 42.6456 38.7054 42.7032 38.604L43.3125 38.9553C43.2522 39.057 43.2165 39.1692 43.2165 39.2736V75.0435C43.2162 75.2328 43.3398 75.3204 43.4961 75.2517Z"
          fill="#E0E0E0"/>
      <path
          d="M43.5411 75.2295L66.0042 62.2605C66.1833 62.157 66.3285 61.9056 66.3285 61.6989L66.3282 25.9287C66.3282 25.722 66.183 25.6383 66.0039 25.7415L43.5408 38.7105C43.3617 38.8137 43.2168 39.0651 43.2168 39.2721L43.2171 75.0423C43.2168 75.2493 43.362 75.333 43.5411 75.2295Z"
          fill="#F5F5F5"/>
      <g opacity="0.3">
        <path
            d="M44.8572 55.0059C44.8572 55.281 45.1041 55.3611 45.4086 55.1853L64.1361 44.3721C64.4406 44.1963 64.6875 43.8309 64.6875 43.5558C64.6875 43.2807 64.4406 43.2006 64.1361 43.3764L45.4083 54.1896C45.1041 54.3654 44.8572 54.7308 44.8572 55.0059Z"
            fill="#455A64"/>
        <path
            d="M44.8572 57.9925C44.8572 58.2676 45.1041 58.3477 45.4086 58.1719L64.1361 47.3587C64.4406 47.1829 64.6875 46.8175 64.6875 46.5424C64.6875 46.2673 64.4406 46.1872 64.1361 46.363L45.4083 57.1762C45.1041 57.3523 44.8572 57.7177 44.8572 57.9925Z"
            fill="#455A64"/>
        <path
            d="M44.8572 60.9793C44.8572 61.2541 45.1041 61.3345 45.4086 61.1587L64.1361 50.3455C64.4406 50.1697 64.6875 49.8043 64.6875 49.5292C64.6875 49.2541 64.4406 49.174 64.1361 49.3498L45.4083 60.163C45.1041 60.3391 44.8572 60.7045 44.8572 60.9793Z"
            fill="#455A64"/>
        <path
            d="M44.8572 63.9661C44.8572 64.2412 45.1041 64.3213 45.4086 64.1455L64.1361 53.3323C64.4406 53.1565 64.6875 52.7911 64.6875 52.516C64.6875 52.2409 64.4406 52.1608 64.1361 52.3366L45.4083 63.1498C45.1041 63.3259 44.8572 63.6913 44.8572 63.9661Z"
            fill="#455A64"/>
        <path
            d="M44.8572 46.0459C44.8572 46.3207 45.1041 46.4011 45.4086 46.2253L64.1361 35.4121C64.4406 35.2363 64.6875 34.8709 64.6875 34.5958C64.6875 34.321 64.4406 34.2406 64.1361 34.4164L45.4083 45.2296C45.1041 45.4057 44.8572 45.7711 44.8572 46.0459Z"
            fill="#455A64"/>
        <path
            d="M44.8572 49.0323C44.8572 49.3071 45.1041 49.3875 45.4086 49.2117L64.1361 38.3985C64.4406 38.2227 64.6875 37.8573 64.6875 37.5822C64.6875 37.3071 64.4406 37.227 64.1361 37.4028L45.4083 48.216C45.1041 48.3918 44.8572 48.7572 44.8572 49.0323Z"
            fill="#455A64"/>
        <path
            d="M44.8572 43.0632C44.8572 43.3383 45.1041 43.4184 45.4086 43.2426L64.1361 32.4294C64.4406 32.2536 64.6875 31.8882 64.6875 31.6131C64.6875 31.3383 64.4406 31.2579 64.1361 31.4337L45.4083 42.2469C45.1041 42.4227 44.8572 42.7884 44.8572 43.0632Z"
            fill="#455A64"/>
        <path
            d="M44.8572 52.0191C44.8572 52.2939 45.1041 52.3743 45.4086 52.1985L64.1361 41.3853C64.4406 41.2095 64.6875 40.8441 64.6875 40.569C64.6875 40.2939 64.4406 40.2138 64.1361 40.3896L45.4083 51.2028C45.1041 51.3786 44.8572 51.744 44.8572 52.0191Z"
            fill="#455A64"/>
        <path
            d="M44.8572 66.9529C44.8572 67.228 45.1041 67.3081 45.4086 67.1323L64.1361 56.3191C64.4406 56.1433 64.6875 55.7779 64.6875 55.5028C64.6875 55.2277 64.4406 55.1476 64.1361 55.3234L45.4083 66.1366C45.1041 66.3124 44.8572 66.6781 44.8572 66.9529Z"
            fill="#455A64"/>
        <path
            d="M44.8572 69.9397C44.8572 70.2148 45.1041 70.2949 45.4086 70.1191L64.1361 59.3059C64.4406 59.1301 64.6875 58.7647 64.6875 58.4896C64.6875 58.2148 64.4406 58.1344 64.1361 58.3102L45.4083 69.1234C45.1041 69.2992 44.8572 69.6646 44.8572 69.9397Z"
            fill="#455A64"/>
      </g>
      <path
          d="M59.9647 56.1158C59.9647 55.8878 59.8864 55.7273 59.761 55.6463C59.761 55.6463 59.743 55.6313 59.7082 55.6157L58.6345 54.989L58.6333 54.9884L58.6309 54.9869V54.9875C58.4968 54.9116 58.3126 54.923 58.1089 55.0406L36.6382 67.4216L36.0133 64.9004C35.9563 64.7102 35.8432 64.5722 35.7031 64.4897L35.7073 64.4879L34.5898 63.839L34.5904 63.8435C34.3966 63.7289 34.1488 63.7235 33.9106 63.8612L24.7759 69.1337C24.3658 69.3704 24.0334 69.9461 24.0334 70.4198L24.0343 100.88C24.0343 101.107 24.109 101.269 24.2338 101.35L25.3837 102.017C25.5163 102.084 25.6933 102.069 25.8892 101.955L59.2351 82.7033C59.6452 82.4666 59.9779 81.8906 59.9779 81.4172L59.9647 56.1158Z"
          fill="url(#paint23_linear_9638_9100)"/>
      <path opacity="0.4"
            d="M35.6878 64.4781C35.4958 64.3719 35.2555 64.3752 35.0224 64.509L25.8886 69.7809C25.6861 69.9009 25.501 70.1034 25.3639 70.3365L24.2512 69.6882C24.385 69.4551 24.5701 69.249 24.7759 69.1326L33.9097 63.8607C34.1497 63.7236 34.3966 63.7305 34.5889 63.8436V63.8403L35.6878 64.4781Z"
            fill="white"/>
      <path opacity="0.4"
            d="M59.7082 55.6154C59.578 55.5605 59.4064 55.5812 59.221 55.6874L37.7293 68.0864C37.3177 68.3231 36.8752 68.1482 36.7381 67.6919L36.6382 67.421L58.1086 55.0391C58.2253 54.9704 58.3384 54.9395 58.438 54.9395C58.51 54.9395 58.5754 54.9566 58.63 54.9875H58.6333L59.7082 55.6154Z"
            fill="white"/>
      <path
          d="M59.9642 56.117L59.9774 81.4175C59.9774 81.8921 59.6453 82.4684 59.2337 82.7045L25.8029 102.005C25.511 102.174 25.1462 101.963 25.1462 101.626V71.0675C25.1462 70.8317 25.2284 70.568 25.3628 70.3376C25.4999 70.1045 25.6865 69.9014 25.8869 69.7832L35.0216 64.5095C35.2547 64.3778 35.4962 64.3724 35.6885 64.4792L35.7077 64.49H35.7023C35.8421 64.5722 35.9546 64.7096 36.0125 64.9016L36.6386 67.4216L36.7373 67.6934C36.8744 68.1488 37.3163 68.3246 37.7279 68.0885L59.2211 55.6886C59.405 55.5815 59.5778 55.5623 59.7068 55.6172L59.7371 55.6337C59.7425 55.6364 59.7482 55.6391 59.7536 55.6418L59.7617 55.6472C59.8847 55.7276 59.9642 55.8893 59.9642 56.117Z"
          fill="url(#paint24_linear_9638_9100)"/>
      <path
          d="M40.7427 79.6407C40.7913 79.6635 40.8423 79.6743 40.8927 79.6743C41.0247 79.6743 41.151 79.5999 41.211 79.4727L42.2097 77.3586V81.1077C42.2097 81.3021 42.3672 81.4596 42.5616 81.4596C42.756 81.4596 42.9135 81.3021 42.9135 81.1077V76.6164L43.9866 77.649C44.127 77.7843 44.3496 77.7792 44.4843 77.6394C44.619 77.4993 44.6145 77.2764 44.4747 77.1417L42.8058 75.5361C42.7239 75.4575 42.6114 75.4227 42.4983 75.4434C42.387 75.4641 42.2922 75.537 42.2439 75.6393L40.5753 79.1718C40.4919 79.3479 40.5672 79.5579 40.7427 79.6407Z"
          fill="#FFF4EB"/>
      <path
          d="M44.9582 78.5378C44.7638 78.5378 44.6063 78.6953 44.6063 78.8897V81.0128L40.517 83.3741V81.6572C40.517 81.4628 40.3595 81.3053 40.1651 81.3053C39.9707 81.3053 39.8132 81.4628 39.8132 81.6572V83.9834C39.8132 84.1091 39.8804 84.2252 39.989 84.2882C40.0436 84.3197 40.1042 84.3353 40.1651 84.3353C40.2257 84.3353 40.2866 84.3194 40.3412 84.2882L45.1343 81.521C45.2432 81.4583 45.3101 81.3422 45.3101 81.2162V78.89C45.3101 78.6953 45.1526 78.5378 44.9582 78.5378Z"
          fill="#FFF4EB"/>
      <path opacity="0.2"
            d="M25.416 102.028C25.4052 102.026 25.3941 102.023 25.383 102.017L24.2334 101.35C24.1098 101.268 24.033 101.106 24.033 100.879V70.4186C24.033 70.1798 24.1182 69.9218 24.2526 69.6887L25.3638 70.3364V70.3391C25.2294 70.5695 25.1472 70.8329 25.1472 71.069V101.625C25.1472 101.815 25.2624 101.965 25.416 102.028Z"
            fill="black"/>
      <path d="M91.2361 94.5762V96.8622L100.041 106.407V104.121L91.2361 94.5762Z"
            fill="url(#paint25_linear_9638_9100)"/>
      <path opacity="0.15" d="M91.2361 94.5762V96.8622L100.041 106.407V104.121L91.2361 94.5762Z" fill="#CC7633"/>
      <path d="M100.892 96.3107V94.0247L100.041 104.121V106.407L100.892 96.3107Z"
            fill="url(#paint26_linear_9638_9100)"/>
      <path opacity="0.2" d="M100.892 96.3107V94.0247L100.041 104.121V106.407L100.892 96.3107Z" fill="#F58220"/>
      <path
          d="M71.2348 109.989C60.97 109.128 51.55 105.011 46.9114 99.0119L47.0872 96.9149L50.6971 96.0845C55.4176 102.189 66.967 106.053 78.1585 105.268C84.7813 104.805 90.4972 102.77 93.8404 99.6863L97.2613 101.107L97.0855 103.204C92.8969 107.068 86.2939 109.497 78.4927 110.043C76.0612 110.213 73.6246 110.189 71.2348 109.989Z"
          fill="url(#paint27_linear_9638_9100)"/>
      <g opacity="0.15">
        <path
            d="M71.2348 109.989C60.97 109.128 51.55 105.011 46.9114 99.0119L47.0872 96.9149L50.6971 96.0845C55.4176 102.189 66.967 106.053 78.1585 105.268C84.7813 104.805 90.4972 102.77 93.8404 99.6863L97.2613 101.107L97.0855 103.204C92.8969 107.068 86.2939 109.497 78.4927 110.043C76.0612 110.213 73.6246 110.189 71.2348 109.989Z"
            fill="#F58220"/>
      </g>
      <path
          d="M100.892 94.0247L91.2361 94.5758L94.0162 97.5896C90.6733 100.674 84.9571 102.708 78.3343 103.172C67.1428 103.956 55.5934 100.092 50.8729 93.9878L47.0872 96.9152C51.7258 102.914 61.1458 107.031 71.4106 107.892C73.8004 108.092 76.237 108.116 78.6682 107.946C86.4694 107.4 93.0724 104.971 97.261 101.107L100.041 104.121L100.892 94.0247Z"
          fill="url(#paint28_linear_9638_9100)"/>
      <defs>
        <linearGradient id="paint0_linear_9638_9100" x1="74.85" y1="52.5" x2="74.85" y2="141"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FBDEC9"/>
          <stop offset="1" stop-color="white"/>
        </linearGradient>
        <linearGradient id="paint1_linear_9638_9100" x1="125.903" y1="86.0787" x2="133.454" y2="89.0227"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint2_linear_9638_9100" x1="133.37" y1="84.7569" x2="143.227" y2="89.7899"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint3_linear_9638_9100" x1="128.528" y1="78.8065" x2="133.352" y2="87.6749"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint4_linear_9638_9100" x1="133.672" y1="91.1188" x2="139.609" y2="96.132"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint5_linear_9638_9100" x1="133.799" y1="91.2001" x2="139.662" y2="96.0904"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint6_linear_9638_9100" x1="134.912" y1="92.3161" x2="135.718" y2="93.4798"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint7_linear_9638_9100" x1="133.672" y1="84.7146" x2="139.609" y2="89.7277"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint8_linear_9638_9100" x1="133.799" y1="84.7956" x2="139.662" y2="89.6858"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint9_linear_9638_9100" x1="134.912" y1="85.9116" x2="135.718" y2="87.0752"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint10_linear_9638_9100" x1="102.065" y1="62.2203" x2="131.939" y2="88.6995"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint11_linear_9638_9100" x1="102.065" y1="62.2203" x2="131.939" y2="88.6995"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint12_linear_9638_9100" x1="101.758" y1="62.729" x2="131.124" y2="88.4497"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint13_linear_9638_9100" x1="107.948" y1="66.7032" x2="129.44" y2="85.7522"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint14_linear_9638_9100" x1="107.948" y1="66.7032" x2="129.44" y2="85.7522"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint15_linear_9638_9100" x1="99.907" y1="64.3629" x2="128.184" y2="87.2554"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint16_linear_9638_9100" x1="117.457" y1="65.0739" x2="120.839" y2="71.3135"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FF8B00" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint17_linear_9638_9100" x1="124.964" y1="76.1567" x2="126.762" y2="76.2382"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FF8B00" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint18_linear_9638_9100" x1="29.5018" y1="62.2203" x2="59.3763" y2="88.6997"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint19_linear_9638_9100" x1="29.5018" y1="62.2203" x2="59.3763" y2="88.6997"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint20_linear_9638_9100" x1="30.3102" y1="62.729" x2="59.6765" y2="88.4497"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint21_linear_9638_9100" x1="28.791" y1="66.7032" x2="50.2822" y2="85.7519"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint22_linear_9638_9100" x1="28.791" y1="66.7032" x2="50.2822" y2="85.7519"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint23_linear_9638_9100" x1="33.9182" y1="64.3629" x2="62.1952" y2="87.2554"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint24_linear_9638_9100" x1="34.7248" y1="64.8829" x2="62.5105" y2="86.981"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint25_linear_9638_9100" x1="93.6575" y1="96.9423" x2="100.719" y2="102.52"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint26_linear_9638_9100" x1="100.275" y1="96.501" x2="101.377" y2="96.5814"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint27_linear_9638_9100" x1="60.7576" y1="98.8988" x2="65.0102" y2="115.047"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
        <linearGradient id="paint28_linear_9638_9100" x1="61.8834" y1="96.8021" x2="65.8954" y2="113.082"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF8B00"/>
          <stop offset="1" stop-color="#FFF4EB"/>
        </linearGradient>
      </defs>

    </svg>
)

export default DuplicateIllustration