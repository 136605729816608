import * as React from "react"
import { SVGProps } from "react"

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.667 10.667H8V8h-.667M8 5.333h.007M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0Z"
      stroke="#9E9E9E"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InfoIcon
