// ConfigCareer.tsx
export const baseUrlAPI = () => {
  return 'https://api.prd.miraeasset.co.id/v1/career';
};

export const urlWebCareer = () => {
  return 'https://career.miraeasset.io/detail-career/';
};

export const apiKeyTinyMCE: string = 'ofyv4jj2oacut7go4bv4c112ro9uuupgfqcuj3docuzk1ib1';
