import './header.scss';

import '../../../../../../../scss/app.min.scss'



import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import {
  Navbar,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  Container,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink as Link } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, BrandIcon } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';



const HeaderPublic = (props: any) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

    

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
      <header id="page-topbar">


        <div className="navbar-header">


          <div className="d-flex">

            <div className="navbar-brand px-4 py-5">
              <Brand />

            </div>

         


           


          </div>



          {/* </Navbar> */}
        </div>  
      </header>
  );
};

export default HeaderPublic;
