export const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]

export const selectOption = [
    { label: "Upload", value: 0 },
    { label: "Manual", value: 1 },
];

export const filterOption = [
    { label: "30% - 100%", value: 0, minVal: 30, maxVal: 100 },
    { label: "30% - 50%", value: 1, minVal: 30, maxVal: 50 },
    { label: "51% - 70%", value: 2, minVal: 51, maxVal: 70 },
    { label: "70% - 100%", value: 3, minVal: 70, maxVal: 100 },
    { label: "> 70%", value: 4, minVal: 70, maxVal: 300 },
]