import * as React from "react"
import { SVGProps } from "react"

const EyeSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 9.6a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2Z" fill="#F5F5F5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.366 8a8.004 8.004 0 0 1 15.268 0A8.003 8.003 0 0 1 .366 8ZM11.2 8a3.2 3.2 0 1 1-6.4 0 3.2 3.2 0 0 1 6.4 0Z"
      fill="#F5F5F5"
    />
  </svg>
)

export default EyeSmallIcon
