import * as React from "react"
import { SVGProps } from "react"

const ToSentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M18.3309 26.8435L22.0714 30.5789C22.3037 30.8111 22.5914 30.9802 22.9072 31.0699C23.2231 31.1597 23.5567 31.1672 23.8763 31.0918C24.196 31.0164 24.491 30.8606 24.7335 30.6391C24.9759 30.4176 25.1578 30.1379 25.2617 29.8264L31.4006 11.3926C31.514 11.0533 31.5305 10.6891 31.4485 10.3409C31.3664 9.99271 31.1889 9.67425 30.936 9.42128C30.683 9.16831 30.3645 8.99084 30.0163 8.90876C29.6681 8.82669 29.3039 8.84327 28.9646 8.95665L10.5309 15.1006C10.2203 15.205 9.94155 15.3868 9.72082 15.6289C9.5001 15.8709 9.34473 16.1653 9.26941 16.4841C9.19408 16.8029 9.20128 17.1356 9.29032 17.4509C9.37937 17.7661 9.54732 18.0535 9.7783 18.2858L14.4772 22.9898L14.3177 28.9298L18.3309 26.8435Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M30.8504 9.34082L14.4773 22.99" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToSentIcon
