import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
import Toggle from 'react-toggle'
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import { Item } from 'react-bootstrap/lib/Pagination';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any,
  match?: any
}

export const GroupDetail = (props: IHeaderProps) => {

  const initialFormDataEdit = {
    email: "",
    employeeNo: "",
    fullName: "",
    groupAuthorityId: "",
    isHead: "",
    jobLevel: "",
    position: ""
  }

  let history = useHistory();
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSaveKaryawan, setLoadingSaveKaryawan] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [showModal, setShowModal] = useState<any>(false)
  const [showModalPermission, setShowModalPermission] = useState<any>(false)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [listDepartment, setListDepartment] = useState<any>([])
  const [modalEdit, setModalEdit] = useState<any>(false)
  const [modalAdd, setModalAdd] = useState<any>(false)
  const [dataDetail, setDataDetail] = useState<any>(null)
  const [dataHC, setDataHC] = useState<any>({ value: "", label: "" })
  const [listHC, setListHC] = useState<any>([])
  const [groupName, setGroupName] = useState<any>("")
  const [isEdit, setIsEdit] = useState<any>("")
  const [listMember, setListMember] = useState<any>([])
  const [keywordKaryawan, setKeywordKaryawan] = useState<any>("")
  const [listPermissions, setListPermissionUser] = useState<any>([])
  const [loadingSetPermission, setLoadingSetPermission] = useState<any>(false)
  const [idUpdate, setIdUpdate] = useState<any>(null)
  const [appData, setData] = useState([]);

  const [idUrl, setIdUrl] = useState(props.match.params.id);

  /* state child group */
  const [formDataEdit, setFormDataEdit] = useState(initialFormDataEdit)
  const [modalEditPersonel, setModalEditPersonel] = useState(false)
  const [pageChildGroup, setPageChildGroup] = useState(1)
  const [totalPageChildGroup, setTotalPageChildGroup] = useState(1)
  const [sizeChildGroup, setSizeChildGroup] = useState<any>(10)
  const [dataChildGroup, setDataChildGroup] = useState<any>([])
  const [modalDelChildGroup, setModalDelChildGroup] = useState<any>(false)
  const [fullNameChildGroup, setFullNameChildGroup] = useState<any>("")
  const [idDeleteChildGroup, setIdDeleteChildGroup] = useState<any>(null)
  const [loadingChildGroup, setLoadingChildGroup] = useState(false)
  /* end state child group */

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  useEffect(() => {
    getMemberKaryawan()
    getDetailGroup()
    getListKaryawan()
    getListKaryawanHC()
    getDataChild()
  }, [page, size, totalPage, pageChildGroup, sizeChildGroup, totalPageChildGroup, idUrl]);

  const pressBackButton = () => {
    history.goBack()
    setTimeout(() => {
      var res = window.location.pathname.replace(/\D/g, "");
      setIdUrl(res)
      getMemberKaryawan()
      getDetailGroup()
      getListKaryawan()
      getListKaryawanHC()
      getDataChild()
    }, 500);


  }

  // get data
  const getMemberKaryawan = async () => {
    axios.get(`/services/uaadocservice/api/group-authority/get-list-member-of-group?keyword=${keyword}&groupId=${idUrl}&page=${page}&size=${size}`).then((res) => {
      setListMember(res.data.data.content)
      setTotalPage(res.data.data.totalPages)
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const getDetailGroup = async () => {
    axios.get(`/services/uaadocservice/api/group/get-detail/${idUrl}`).then((res) => {
      setDataDetail(res.data.data)
      setGroupName(res.data.data?.groupName)
      setDataHC({ label: res.data.data?.adminHCName, value: res.data.data?.adminHC })
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const getPermissionUser = (id) => {
    axios.get(`/services/uaadocservice/api/group/${idUrl}/get-authorization?employeeNo=${id}`)
      .then(async (result) => {
        setListPermissionUser(result.data.data.permissions)
        setShowModalPermission(true)
        setIdUpdate(id)
      }).catch(err => {
        setListPermissionUser([])
        setShowModalPermission(false)
      })
  }

  const getListKaryawan = async () => {
    axios.get(`/services/uaadocservice/api/personal-identity/karyawan/list?keyword=${keywordKaryawan}&page=${page}&limit=${size}&isAscending=${"true"}&sort=${"employeeNo"}&statusFilter=${"true"}`).then(async (result) => {
      setData(result.data.data.data)
    }).catch(err => {
      console.log("result", err.response.data)
    })
  }

  const getListKaryawanHC = () => {
    axios.get(`services/uaadocservice/api/group-authority/get-list-admin-hc`).then((res) => {
      setListHC(res.data)
    }).catch((err) => {
      setListHC([])
      throw new Error('getListAdminHC error')
    })
  }

  const getDataChild = () => {
    axios.get(`/services/uaadocservice/api/group/get-list-group-child?page=${pageChildGroup}&size=${sizeChildGroup}&parentGroupId=${idUrl}`).then((res) => {
      setDataChildGroup(res.data.data.content)
      setTotalPageChildGroup(res.data.data.totalPages)
    }).catch((err) => {
      throw new Error('getDataChild')
    })
  }

  const selectAll = () => {
    if (appData.every(el => el.checked)) {
      const tempData = appData.map(el => ({ ...el, checked: false }));
      setData(tempData);
    } else {
      const tempData = appData.map(el => ({ ...el, checked: true }));
      setData(tempData);
    }
  }

  const checkOne = id => {
    const tempData = appData.map(el => {
      if (el.employeeNo === id) {
        return { ...el, checked: !el.checked };
      }
      return el;
    });
    setData(tempData);
  }

  const changeValue = (e) => {
    let updatedList = listPermissions.map(item => {
      if (item.name == e.name) {
        return { ...item, value: !e.value }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item 
    });
    setListPermissionUser(updatedList)
  }

  const setPermissionUser = () => {
    setLoadingSetPermission(true)
    let permission = listPermissions.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
    axios.put(`/services/uaadocservice/api/group/${idUrl}/set-authorization?employeeNo=${idUpdate}`, permission).then(async (result) => {
      toast.success('Success Update Permission', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowModalPermission(false)
      setLoadingSetPermission(false)
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoadingSetPermission(false)
    })
  }

  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
      .required("NIK is required"),
    group: Yup.string()
      .required("Group is required"),
    date: Yup.string()
      .required("Date is required"),
    fullname: Yup.string()
      .min(5, "Fullname must be 5 characters at minimum")
      .required("Fullname is required"),
  });

  const editPersonel = (valGroupAuthorityId) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-data-member?groupAuthorityId=${valGroupAuthorityId}`).then((res) => {
      const valData = res.data
      setFormDataEdit({
        ...formDataEdit,
        email: valData.email,
        employeeNo: valData.employeeNo,
        fullName: valData.fullName,
        groupAuthorityId: valData.groupAuthorityId,
        isHead: valData.isHead,
        jobLevel: valData.jobLevel,
        position: valData.position
      })
      setModalEditPersonel(true)
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
      handleCloseEditPersonel()
    })
  }

  // action
  const submitUpdateGroup = () => {
    let payload = {
      groupName: groupName,
      adminHC: dataHC.value
    }
    axios.put(`/services/uaadocservice/api/group/${idUrl}/update`, payload).then(res => {
      toast.success('Success Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getDetailGroup()
      setIsEdit(false)
    }).catch(err => {
      toast.error('Failed Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const submitUpdateHC = (id) => {
    // setLoading(true)
    let payload = {
      adminHC: id
    }
    axios.put(`/services/uaadocservice/api/group/${idUrl}/update`, payload).then(res => {
      toast.success('Success Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getDetailGroup()
      setIsEdit(false)
    }).catch(err => {
      toast.error('Failed Update Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const submitKaryawanToGroup = () => {
    let employeeNoList = []
    appData.map((item, index) => {
      if (item.checked == true) {
        employeeNoList.push(item.employeeNo)
      }
    })
    let payload = {
      employeeNoList: employeeNoList
    }
    axios.post(`/services/uaadocservice/api/group/${idUrl}/add-member`, payload).then(res => {
      toast.success('Success add karyawan to Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setModalAdd(false)
      getMemberKaryawan()
      getListKaryawan()
    }).catch(err => {
      toast.error('Failed Add Karyawan To Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const deleteKaryawan = () => {
    let payload = {
      employeeNoList: [iddelete]
    }
    setLoadingDelete(true)
    axios.delete(`/services/uaadocservice/api/group-authority/delete-member/${iddelete}`).then(res => {
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getMemberKaryawan()
      toast.success('Success Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      setLoadingDelete(false)
      toast.error('Failed Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const postEditPersonel = () => {
    if (formDataEdit.jobLevel == "0") {
      toast.warn('Job level must more than 0', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true)
      const formDataFinal = new FormData();
      let dataEdit = {
        groupId: formDataEdit.groupAuthorityId,
        jobLevel: formDataEdit.jobLevel,
        position: formDataEdit.position,
        isHead: formDataEdit.isHead
      }
      formDataFinal.append("data", JSON.stringify(dataEdit));
      axios.put(`/services/uaadocservice/api/group-authority/save`, formDataFinal).then(res => {
        setLoading(false)
        handleCloseEditPersonel()
        getMemberKaryawan()
        toast.success('Success Edit Personel', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }).catch(err => {
        setLoading(false)
        handleCloseEditPersonel()
        toast.error('Failed Update Personel', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    }
  }

  // modal 
  const handleInputEditPersonsel = (name, value) => {
    setFormDataEdit({
      ...formDataEdit,
      [name]: value.value
    })
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleClosePermission = () => {
    setShowModalPermission(false)
  }

  const handleCloseEditPersonel = () => {
    setFormDataEdit({ ...formDataEdit, email: "", employeeNo: "", fullName: "", groupAuthorityId: "", isHead: "", jobLevel: "", position: "" })
    setModalEditPersonel(false)
  }

  // pagination
  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /* Get Child */

  // action
  const pressView = async (valId) => {
    history.push(`/group/detail/${valId}`)
    window.location.reload()
  }

  const deleteChildGroup = () => {
    setLoadingChildGroup(true)
    axios.delete(`/services/uaadocservice/api/group/delete/${idDeleteChildGroup}`).then(res => {
      setModalDelChildGroup(false)
      setLoadingChildGroup(false)
      setFullNameChildGroup("")
      setIdDeleteChildGroup(null)
      getDataChild()
      toast.success('Success Delete Child Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      setLoadingChildGroup(false)
      toast.error('Failed Delete Child Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  // modal
  const handleCloseDelChildGroup = () => {
    setModalDelChildGroup(false)
  }

  // pagination
  const handleChangeChildGroup = (event) => {
    setPageChildGroup(1)
    setSizeChildGroup(parseInt(event.target.value));
  };

  const handleChangePageChildGroup = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageChildGroup(value);
  };
  /* End Get Child */


  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <button className="btn px-0 mb-4" onClick={() => pressBackButton()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="card bg-white py-3 px-3">
        <div className="row">
          <div className="col-11">
            {
              isEdit ? <input type="text" value={groupName} className="form-control bg-white py-4" onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  submitUpdateGroup()
                }
              }} placeholder="" onChange={(e) => {
                setGroupName(e.target.value)
              }} /> : <h3 className="mb-0">
                {groupName}
              </h3>
            }
          </div>
          <div className="col-1 text-center" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
            setIsEdit(!isEdit)
            getDetailGroup()
          }}>
            <EditBlue />
          </div>
        </div>
      </div>
      <div className="form-group my-5">
        <h5 className="text-xl">Admin HC</h5>
        <Select
          name="group"
          styles={customStyles}
          placeholder="Find Name Or Division"
          value={dataHC}
          defaultValue={dataHC}
          options={listHC.map(e => ({ label: e.fullName, value: e.employeeNo }))}
          onChange={newValue => {
            setDataHC(newValue)
            submitUpdateHC(newValue.value)
          }}
        />
      </div>
      <div className='row my-3'>
        <div className="col-12 col-md-2 col-lg-2 px-2">
          <div className='btn btn-primary w-100 py-2' onClick={(data) => setModalAdd(true)}>
            <UserAddKaryawanIcon /> Add Personel
          </div>
        </div>
        <div className="col-12 col-md-10 col-lg-10 px-0">
          <div className="d-flex px-3" style={{ flexGrow: 10 }}>
            <div className="app-search w-100 py-0 bg-white">
              <div className="position-relative">
                <input type="text" className="form-control bg-white py-3" placeholder="Cari member" onChange={(e) => {
                  if (e.target.value === '') {
                    setKeyword(e.target.value)
                    getMemberKaryawan()
                  } else {
                    setKeyword(e.target.value)
                  }
                }} onKeyPress={(e) => {
                  if (e.key == 'Enter') {

                    getMemberKaryawan()
                  }
                }} />
                <span className="bx bx-search-alt my-0"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table 1 */}
      <div className="table-responsive mt-4">
        <table className="table table-striped table-centered  mb-0">
          <thead className="thead-light">
            <tr>
              <th className='py-3'>Employee Number</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Job Level</th>
              <th>Position</th>
              <th>Is Head</th>
              <th>Permission</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              listMember ? listMember.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className='py-2' style={{ wordBreak: 'break-all' }}>{item.employeeNo ?? "-"}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.fullName ?? "-"}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.email}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.jobLevel}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.position}</td>
                    <td style={{ wordBreak: 'break-all' }}>{item.isHead == true ? "Yes" : "No"}</td>
                    <td style={{ wordBreak: 'break-all' }}>
                      <button
                        className='btn'
                        style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                        onClick={() => getPermissionUser(item.employeeNo)}>
                        Edit Permission
                      </button>
                    </td>
                    <td style={{ wordBreak: 'break-all', width: '10%' }} className='fix'>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div style={{ cursor: 'pointer' }} className="mx-2" onClick={() => editPersonel(item.groupAuthorityId)}>
                          <EditBlue />
                        </div>
                        <div style={{ cursor: 'pointer' }} className="mx-2">
                          <DeleteRed onClick={() => {
                            setShowModal(true)
                            setFullName(item.fullName)
                            setIdDelete(item.groupAuthorityId)
                          }} />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              }) : <></>
            }
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="row mt-4">
        <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
          <div style={{ display: 'flex' }}>
            <span className="mt-3 mx-2">
              Show
            </span>
            <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                <SelectMui
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={size}
                  onChange={handleChange}
                  label="Limit"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </SelectMui>
              </FormControl>
            </span>
            <span className="mt-3 mx-2">
              Entries
            </span>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
          <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
        </div>
      </div>
      {dataChildGroup.length > 0 ?
        <div className="row mt-5">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h1 className="mb-0 font-size-24">Child Group</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h1 className="mb-0 font-size-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
            </div>
          </div>
        </div>
        : <></>}
      {dataChildGroup.length > 0 ?
        <div className="table-responsive mt-2">
          <table className="table table-striped table-centered  mb-0">
            <thead className="thead-light">
              <tr>
                <th className='py-3'>Nama Group</th>
                <th>Parent Group</th>
                <th>Level</th>
                <th>Total Member</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataChildGroup ? dataChildGroup.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='py-2' style={{ wordBreak: 'break-all' }}>{item.groupName ?? "-"}</td>
                      <td style={{ wordBreak: 'break-all' }}>{item.parentGroupName ?? "-"}</td>
                      <td style={{ wordBreak: 'break-all' }}>{item.levelOrganizationName ?? "-"}</td>
                      <td style={{ wordBreak: 'break-all' }}>{item.totalMember ?? "-"}</td>
                      <td style={{ wordBreak: 'break-all', width: '15%' }} className='fix'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                          <div style={{ cursor: 'pointer' }} className="mx-2">
                            <DeleteRed onClick={() => {
                              setModalDelChildGroup(true)
                              setFullNameChildGroup(item.groupName)
                              setIdDeleteChildGroup(item.id)
                            }} />
                          </div>
                          <button
                            className='btn mr-2'
                            style={{ backgroundColor: '#F37F26', borderColor: '#F37F26', color: '#FFFFFF' }}
                            onClick={() => pressView(item.id)}
                          >
                            <FontAwesomeIcon icon="eye" />{' '}View
                          </button>

                        </div>
                      </td>
                    </tr>
                  )
                }) : <></>
              }
            </tbody>
          </table>
        </div>
        : <></>}
      {dataChildGroup.length > 0 ?
        <div className="row mt-4">
          <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
            <div style={{ display: 'flex' }}>
              <span className="mt-3 mx-2">
                Show
              </span>
              <span>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                  <SelectMui
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={sizeChildGroup}
                    onChange={handleChangeChildGroup}
                    label="Limit"
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </SelectMui>
                </FormControl>
              </span>
              <span className="mt-3 mx-2">
                Entries
              </span>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <Pagination count={totalPageChildGroup} color="primary" page={pageChildGroup} showFirstButton showLastButton onChange={handleChangePageChildGroup} />
          </div>
        </div>
        : <></>}
      {/* modal 1 */}
      <Modal className="font-family-spoqa" isOpen={modalAdd} toggle={handleClose} size="xl" style={{ marginTop: '15%' }}>
        <div className="my-4 font-family-spoqa">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                  <h1 className="mb-0 font-size-28">Tambah Personel</h1>
                </div>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                </div>
              </div>
            </div>
            <div className="">
              <div className="col-12 col-md-12 col-lg-12 px-0 mb-4">
                <div className="d-flex" style={{ flexGrow: 10 }}>
                  <div className="app-search w-100 py-0 bg-white" >
                    <div className="position-relative">
                      <input type="text" className="form-control  py-3" style={{ background: '#EDEDED' }} placeholder="Cari Karyawan" onChange={(e) => {
                        if (e.target.value === '') {
                          setKeywordKaryawan(e.target.value)
                          getListKaryawan()
                        } else {
                          setKeywordKaryawan(e.target.value)
                        }
                      }} onKeyPress={(e) => {
                        if (e.key == 'Enter') {

                          getListKaryawan()
                        }
                      }} />
                      <span className="bx bx-search-alt my-0"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-wrapper-scroll-y" style={{ height: '400px', overflowY: 'scroll' }}>
                <table className="table" >
                  <thead>
                    <tr>
                      <th className='' style={{ background: '#E0E0E0', position: 'sticky', top: 0, }}>Employee No</th>
                      <th style={{ background: '#E0E0E0', position: 'sticky', top: 0 }}>Fullname</th>
                      <th style={{ background: '#E0E0E0', position: 'sticky', top: 0 }}>Email</th>
                      <th className='text-center' style={{ background: '#E0E0E0', position: 'sticky', top: 0 }}>
                        <input
                          type="checkbox"
                          checked={appData.every(el => el.checked)}
                          onChange={selectAll}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}>
                    {appData.map(el => (
                      <tr key={el.employeeNo}>
                        <td>{el.employeeNo}</td>
                        <td>{el.fullName}</td>
                        <td>{el.email}</td>
                        <td className='text-center'>
                          <input
                            type="checkbox"
                            checked={el.checked}
                            onChange={() => checkOne(el.employeeNo)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Row className='mb-4 mt-4'>
                <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                </Col>
                <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                  <div className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={() => setModalAdd(false)}>
                    {loadingSaveKaryawan ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                    Cancel </div>
                  <button className='btn btn-primary px-5' type="submit" disabled={!appData.some(item => item.checked == true)} onClick={() => submitKaryawanToGroup()}>
                    {loadingSaveKaryawan ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                    Save</button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={showModalPermission} toggle={handleClosePermission} size="lg" style={{ marginTop: '15%' }}>
        <div className="my-4 font-family-spoqa">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                  <h1 className="mb-0 font-size-28">Permission Personel</h1>
                </div>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                </div>
              </div>
            </div>
            <div className="">
              <div className="table-wrapper-scroll-y" style={{ height: '400px', overflowY: 'scroll' }}>
                <table className="table" >
                  <thead>
                    <tr>
                      <th className='' style={{ background: '#E0E0E0', position: 'sticky', top: 0, zIndex: 99 }}>Permission Name</th>
                      <th style={{ background: '#E0E0E0', position: 'sticky', top: 0, zIndex: 99 }}>Status</th>
                    </tr>
                  </thead>
                  <tbody style={{ height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}>
                    {listPermissions.map(el => (
                      <tr key={el}>
                        <td style={{ display: el.name == "mayAcknowledge" ? "none" : "" }}>{el.name}</td>
                        <td style={{ display: el.name == "mayAcknowledge" ? "none" : "" }}>
                          <Toggle
                            defaultChecked={el.value}
                            aria-label='No label tag'
                            onChange={(e) => changeValue(el)} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Row className='mb-4 mt-4'>
                <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                </Col>
                <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                  <div className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={() => setShowModalPermission(false)}>
                    {loadingSetPermission ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                    Cancel </div>
                  <button className='btn btn-primary px-5' type="submit" onClick={() => setPermissionUser()}>
                    {loadingSetPermission ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                    Save</button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={modalEdit} toggle={handleClose} size="lg" style={{ marginTop: '15%' }}>
        <div className="my-4 font-family-spoqa">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                  <h1 className="mb-0 font-size-28">Edit Data Group</h1>
                </div>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                </div>
              </div>
            </div>
            <div className="row">
              <Formik
                initialValues={{ group_name: "", adminHC: "" }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({ touched, errors, isSubmitting, values, setFieldValue }) =>
                (
                  <div>
                    <Form>
                      <div className="">
                        <div className="form-group">
                          <h5 className="text-xl">Nama Group</h5>
                          <Field
                            type="text"
                            name="group_name"
                            placeholder=""
                            autocomplete="off"
                            className={`mt-2 form-control py-4
                    ${touched.group_name && errors.group_name ? "is-invalid" : ""}`}
                          />
                          <ErrorMessage
                            component="div"
                            name="group_name"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <h5 className="text-xl">Admin HC</h5>
                          <Select
                            name="group"
                            className={`
                    ${touched.adminHC && errors.adminHC ? "is-invalid" : ""}`}
                            styles={customStyles}
                            placeholder="Find Name Or Division"
                            options={listDepartment.map(e => ({ label: e.deptName, value: e.deptCode }))}
                            onChange={newValue => setFieldValue('group', newValue)}
                          />
                          <div className="text-danger " style={{ fontSize: '10px' }}>
                            {touched.adminHC && errors.adminHC ? errors.adminHC : ""}
                          </div>
                        </div>
                      </div>
                      <Row className='mb-4 mt-4'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                          <div className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={() => setModalEdit(false)}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Cancel </div>
                          <button className='btn btn-primary px-5' type="submit">
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Save</button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )
                }
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={showModal} toggle={handleClose} size="md" style={{ marginTop: '15%' }}>
        <ModalHeader toggle={handleClose}>
          <div className="w-100">
            <h2 className="">Delete Confirmation</h2>
            <h3 style={{ fontSize: '12px' }}>{`Apakah anda yakin menghapus User Karyawan ${fullname} dari group`}</h3>
          </div>
        </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
            <Col lg={12} className="text-right">
              <Button color="secondary" onClick={handleClose} className="mr-2">
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success px-4" onClick={() => deleteKaryawan()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* child group modal delete*/}
      <Modal className="font-family-spoqa" isOpen={modalDelChildGroup} toggle={handleCloseDelChildGroup} size="md" style={{ marginTop: '15%' }}>
        <ModalHeader toggle={handleCloseDelChildGroup}>
          <div className="w-100">
            <h2 className="">Delete Confirmation</h2>
            <h3 style={{ fontSize: '12px' }}>{`Apakah anda yakin menghapus Child Group ${fullNameChildGroup} ?`}</h3>
          </div>
        </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
            <Col lg={12} className="text-right">
              <Button color="secondary" onClick={handleCloseDelChildGroup} className="mr-2">
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success px-4" onClick={() => deleteChildGroup()} disabled={loadingChildGroup}>
                {loadingChildGroup ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      {/* edit personel */}
      <Modal className="font-family-spoqa" isOpen={modalEditPersonel} toggle={handleCloseEditPersonel} size="lg" centered={true}>
        <ModalHeader toggle={handleCloseEditPersonel}>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                <h1 className="mb-2 font-size-24">Edit Personel</h1>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="mb-0 font-size-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row ml-2 mr-2">
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Employee Number
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formDataEdit.employeeNo}
                  placeholder="Employee Number"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Full Name
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formDataEdit.fullName}
                  placeholder="Full Name"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Email
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formDataEdit.email}
                  placeholder="Email"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Job Level
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  type='number'
                  value={formDataEdit.jobLevel}
                  placeholder="Job Level"
                  onChange={(e) =>
                    setFormDataEdit({
                      ...formDataEdit,
                      jobLevel: e.target.value.slice(0, 5)
                    })}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Position
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formDataEdit.position}
                  placeholder="Position"
                  onChange={(e) =>
                    setFormDataEdit({
                      ...formDataEdit,
                      position: e.target.value
                    })}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Is Head
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <Select
                  name="group"
                  value={optEditPersonsel.filter((function (option: any) {
                    return option.value == formDataEdit.isHead
                  }))}
                  placeholder="Select Is Head"
                  options={optEditPersonsel}
                  onChange={(e) => handleInputEditPersonsel("isHead", e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ background: '#F37F26', borderColor: '#F37F26' }}
            disabled={loading}
            onClick={() => postEditPersonel()}>
            <span className='mr-1'>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              Update
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const optEditPersonsel = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);