import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './management.scss';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Select as SelectMui } from '@mui/material';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export const RoleMenu = (props: IHeaderProps) => {

    let history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState<any>(10);
    const [totalPage, setTotalPage] = useState(1);
    const [modalRoleAdd, setRoleModal] = useState(false);
    const [selectAuthority, setSelectAuthority] = useState("");
    const [dataRoleManagement, setDataRoleManagement] = useState([]);
    const [dataAuthorityManagement, setDataAuthorityManagement] = useState([]);
    const [dataMenuManagement, setDataMenuManagement] = useState([]);
    const [addField, setAddField] = useState([{ "menuId": "", "allAccess": false, "readAccess": false, "createAccess": false, "updateAccess": false, "deleteAccess": false }]);
    const [loading, setLoading] = useState(false)
    const [formEdit, setFormEdit] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [modalRoleDelete, setModalRoleDelete] = useState(false);

    const [valSearchRole, setValSearchRole] = useState("");
    const [valSearchMenu, setValSearchMenu] = useState("");

    useEffect(() => {
        getData()
        getDataAuthority()
        getDataMenu()
    }, [page, size, totalPage, addField, valSearchRole, valSearchMenu]);

    // data menu management
    const getData = () => {
        let pageReal = page
        var pageCount = pageReal

        var paramSearchRole = valSearchRole ? `&authId=${valSearchRole}` : ``;
        var paramSearchMenu = valSearchMenu ? `&menu=${valSearchMenu}` : ``;

        axios.get(`/services/uaadocservice/api/auth-menu/list?page=${pageCount}&limit=${size}${paramSearchRole}${paramSearchMenu}`)
            .then(res => {
                setDataRoleManagement(res.data.data.data)
                setTotalPage(res.data.data.totalPage)
            }).catch(err => {
                toast.error(err.response.data.code + ' ' + err.response.data.message);
            })
    }

    const getDataAuthority = () => {
        axios.get(`services/uaadocservice/api/authority/list/all`).then(res => {
            const response = res.data.data
            const newAuthority = response.map((obj, i) => ({ value: obj.name, label: obj.name }));
            setDataAuthorityManagement(newAuthority)
        }).catch(err => {
            toast.error(err.response.data.code + ' ' + err.response.data.message);
        })
    }

    const getDataMenu = () => {
        axios.get(`services/uaadocservice/api/menu/menus/all`).then(res => {
            const response = res.data.data
            const newMenu = response.map((obj, i) => ({ value: obj.id, label: obj.name }));
            setDataMenuManagement(newMenu)
        }).catch(err => {
            toast.error(err.response.data.code + ' ' + err.response.data.message);
        })
    }


    const handleSearchRole = (searchVal) => {
        setPage(1)
        setValSearchRole(searchVal)
    }

    const handleSearchMenu = (searchVal) => {
        setPage(1)
        setValSearchMenu(searchVal)
    }

    // modal 
    const handleModal = () => {
        setRoleModal(!modalRoleAdd);
    }

    const handleClose = () => {
        setSelectAuthority("")
        setAddField([{ "menuId": "", "allAccess": false, "readAccess": false, "createAccess": false, "updateAccess": false, "deleteAccess": false }])
        setRoleModal(false)
        setFormEdit(false)
    }

    const deleteHandleModal = () => {
        setModalRoleDelete(!modalRoleDelete);
    }

    const deleteHandleClose = () => {
        setModalRoleDelete(false)
    };

    // pagination table
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    // add more fields
    const handleCheck = (i, e) => {
        let data = [...addField];
        // select menu
        if (e.target == undefined) {
            data[i].menuId = e.value;
        } else {
            // select checkbox
            if (e.target.name === "allAccess") {
                data[i].allAccess = e.target.checked;
                data[i].readAccess = e.target.checked;
                data[i].createAccess = e.target.checked;
                data[i].updateAccess = e.target.checked;
                data[i].deleteAccess = e.target.checked;
            } else if (e.target.name === "readAccess") {
                if (e.target.checked == true) {
                    data[i][e.target.name] = e.target.checked;
                } else {
                    data[i].readAccess = e.target.checked;
                    data[i].createAccess = false;
                    data[i].updateAccess = false;
                    data[i].deleteAccess = false;
                    data[i].allAccess = false;
                }
            } else if (e.target.name == "createAccess" || e.target.name == "updateAccess" || e.target.name == "deleteAccess") {
                data[i][e.target.name] = e.target.checked;
                if (data[i].createAccess == true && data[i].updateAccess == true && data[i].deleteAccess == true) {
                    data[i].allAccess = true;
                } else {
                    data[i].allAccess = false;
                }
            } else {
                data[i][e.target.name] = e.target.checked;
            }
        }
        setAddField(data);
    }

    const removeFormFields = (i) => {
        let newFormValues = [...addField];
        newFormValues.splice(i, 1);
        setAddField(newFormValues)
    }

    const addFormFields = () => {
        setAddField([...addField, { "menuId": "", "allAccess": false, "readAccess": false, "createAccess": false, "updateAccess": false, "deleteAccess": false }])
    }

    const authoritySelect = (e) => {
        setSelectAuthority(e.value)
    }

    // action
    const postData = () => {
        setLoading(true)
        axios.post(`services/uaadocservice/api/auth-menu/add?name=${selectAuthority}`, addField).then(res => {
            toast.success('Success Add Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
        }).catch(err => {
            toast.error(translate(`${err.response.data.message}`));
            setLoading(false)
            handleClose()
            getData()
        })
    }

    const getDetail = (role) => {
        axios.get(`services/uaadocservice/api/auth-menu/detail-auth?search=${role}`).then(res => {
            var response = res.data.data.data[0].menu
            const dataDetail = response.map((obj, i) => (
                obj.readAccess && obj.createAccess && obj.deleteAccess && obj.updateAccess
                    ?
                    { menuId: obj.menuId, "allAccess": true, readAccess: obj.readAccess, createAccess: obj.createAccess, updateAccess: obj.updateAccess, deleteAccess: obj.deleteAccess }
                    :
                    { menuId: obj.menuId, "allAccess": false, readAccess: obj.readAccess, createAccess: obj.createAccess, updateAccess: obj.updateAccess, deleteAccess: obj.deleteAccess }
            ));
            setFormEdit(!formEdit)
            handleModal()
            setSelectAuthority(res.data.data.data[0].role)
            setAddField(dataDetail)
        }).catch(err => {
            toast.error(err.response.data.code + ' ' + err.response.data.message);
        })
    }

    const postEditData = () => {
        const modEditData = addField.map((obj, i) => ({ menuId: obj.menuId, readAccess: obj.readAccess, createAccess: obj.createAccess, updateAccess: obj.updateAccess, deleteAccess: obj.deleteAccess }));
        setLoading(true)
        axios.put(`services/uaadocservice/api/auth-menu/update?name=${selectAuthority}`, modEditData)
        .then(res => {
            toast.success(res.data.message, {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
            setFormEdit(!formEdit)
        }).catch(err => {
            toast.error('Failed Edit Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
            setFormEdit(!formEdit)
        })
    }

    const deleteData = () => {
        setLoading(true)
        axios.delete(`services/uaadocservice/api/auth-menu/delete-by-role/${idDelete}`).then(res => {
            toast.success('Success Delete Role Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIdDelete(null)
            setLoading(false)
            deleteHandleClose()
            getData()
        }).catch(err => {
            toast.error('Failed Delete Role Menu', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIdDelete(null)
            setLoading(false)
            deleteHandleClose()
            getData()
        })
    }

    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Role Menu</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Mirae Asset Sekuritas Role Menu</h1>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12 col-md-5 col-lg-3 px-2">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text"
                                    className="form-control bg-white py-3"
                                    placeholder="Search by Role Name"
                                    onChange={(e) => {
                                        handleSearchRole(e.target.value)
                                    }} onKeyPress={(e) => {
                                        console.log('e.key', e.key)
                                    }}
                                />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-3 px-2">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text"
                                    className="form-control bg-white py-3"
                                    placeholder="Search by Menu Name"
                                    onChange={(e) => {
                                        handleSearchMenu(e.target.value)
                                    }} onKeyPress={(e) => {
                                        console.log('e.key', e.key)
                                    }}
                                />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-4 px-2"></div>
                <div className="col-12 col-md-5 col-lg-2 px-2">
                    <div className='btn btn-primary w-100 py-2'
                        style={{ background: '#F37F26' }}
                        onClick={(data) => handleModal()} >
                        <i className="bx bx-plus"></i>
                        <span>Add Role Menu</span>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive mt-4">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Role Name</th>
                                    <th>Menu Name</th>
                                    <th className='fix'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataRoleManagement.map((itemRoleManagement, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-all' }}>{itemRoleManagement?.role}</td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    {itemRoleManagement.menu.map((itemMenuManagement, i) => (
                                                        <span style={{ background: '#FFF4EB', color: '#F37F26', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                            {itemMenuManagement.menuName}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td style={{ wordBreak: 'break-all', width: '15%' }} className='fix'>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                                                        <button
                                                            className='btn mr-2'
                                                            style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                                                            onClick={() => getDetail(itemRoleManagement?.role)}
                                                        >
                                                            <FontAwesomeIcon icon="pencil-alt" />{' '}Edit
                                                        </button>
                                                        <button
                                                            className='btn mr-2'
                                                            style={{ backgroundColor: '#EEB4B0', borderColor: '#EEB4B0', color: '#CB3A31' }}
                                                            onClick={() => {
                                                                deleteHandleModal(),
                                                                    setIdDelete(itemRoleManagement?.role)
                                                            }}>
                                                            <FontAwesomeIcon icon="trash" />{' '}Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>

                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit"
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <Modal className="font-family-spoqa" isOpen={modalRoleAdd} toggle={handleClose} size="lg" centered={true}>
                <ModalHeader toggle={handleClose}>Form Role Management</ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <FormGroup>
                            <Select
                                name="group"
                                value={dataAuthorityManagement.filter((function (option: any) {
                                    return option.value == selectAuthority
                                }))}
                                placeholder="Select role user"
                                options={dataAuthorityManagement}
                                isDisabled={formEdit}
                                onChange={(e) => authoritySelect(e)}
                            />
                        </FormGroup>
                        <FormGroup>
                            {addField.map((element, index) => {
                                return (
                                    <div className="row mt-3 mb-4">
                                        <div className="col-4" style={{ alignSelf: "center" }}>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12" >
                                                    <Select
                                                        name="group"
                                                        value={dataMenuManagement.filter((function (option: any) {
                                                            return option.value == element.menuId
                                                        }))}
                                                        placeholder="Select menu"
                                                        options={dataMenuManagement}
                                                        onChange={(e) => handleCheck(index, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8" style={{ alignSelf: "center" }}>
                                            <div className="row mr-1">
                                                <div className="col-12 col-sm-12 col-md-2" style={{ display: "flex", alignSelf: "center" }}>
                                                    <div className="row ">
                                                        <div className="col-12 col-sm-12 col-md-2" >
                                                            <input type="checkbox" name="allAccess" checked={element.allAccess} onChange={(e) => handleCheck(index, e)} />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <div className='font-weight' >
                                                                <span className={"all"}>all</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-2" style={{ display: "flex", alignSelf: "center" }}>
                                                    <div className="row ">
                                                        <div className="col-12 col-sm-12 col-md-2" >
                                                            <input type="checkbox" name="readAccess" checked={element.readAccess} onChange={(e) => handleCheck(index, e)} />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <div className='font-weight' >
                                                                <span>read</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-2" style={{ display: "flex", alignSelf: "center" }}>
                                                    <div className="row ">
                                                        <div className="col-12 col-sm-12 col-md-2" >
                                                            <input type="checkbox"
                                                                name="createAccess"
                                                                checked={element.createAccess}
                                                                onChange={(e) => handleCheck(index, e)}
                                                                disabled={!element.readAccess}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <div className='font-weight' >
                                                                <span>create</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-2" style={{ display: "flex", alignSelf: "center" }}>
                                                    <div className="row ">
                                                        <div className="col-12 col-sm-12 col-md-2" >
                                                            <input type="checkbox"
                                                                name="updateAccess"
                                                                checked={element.updateAccess}
                                                                onChange={(e) => handleCheck(index, e)}
                                                                disabled={!element.readAccess}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <div className='font-weight' >
                                                                <span>update</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-2" style={{ display: "flex", alignSelf: "center" }}>
                                                    <div className="row ">
                                                        <div className="col-12 col-sm-12 col-md-2" >
                                                            <input type="checkbox"
                                                                name="deleteAccess"
                                                                checked={element.deleteAccess}
                                                                onChange={(e) => handleCheck(index, e)}
                                                                disabled={!element.readAccess}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <div className='font-weight' >
                                                                <span className={"delete"}>delete</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    index ?
                                                        <div className="col-12 col-sm-12 col-md-2">
                                                            <Button color='#FFF4F2' style={{ border: '1px solid #EEB4B0' }} onClick={() => removeFormFields(index)} className="mr-2">
                                                                <span style={{ color: '#CB3A31' }}>Delete</span>
                                                            </Button>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </FormGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button style={{ background: '#002F48' }} disabled={loading} onClick={() => addFormFields()}>
                        Add New Role
                    </Button>
                    {' '}
                    <Button
                        style={{ background: '#F37F26', borderColor: '#F37F26' }}
                        disabled={loading}
                        onClick={() => !formEdit ? postData() : postEditData()}>
                        <span className='mr-1'>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            {!formEdit ? 'Save' : 'Update'}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="font-family-spoqa" isOpen={modalRoleDelete} toggle={deleteHandleClose} size="md" style={{ marginTop: '15%' }}>
                <ModalHeader toggle={deleteHandleClose}>
                    <div className="w-100">
                        <h2 className="">Delete Confirmation</h2>
                        <h3 style={{ fontSize: '12px' }}>{`Are you sure to delete ?`}</h3>
                    </div>
                </ModalHeader>
                <div className="my-4">
                    <Row className='w-100'>
                        <Col lg={12} className="text-right">
                            <Button color="secondary" onClick={deleteHandleClose} className="mr-2">
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                            <Button color="success px-4" onClick={() => deleteData()} disabled={loading}>
                                {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(RoleMenu);
