import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const formatDate = (value: string): string => {
    if (!value) return '';

    const date = new Date(value.split('/').reverse().join('-'));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};


export const validateDate = (date: string, type: string, startDate?: string) => {
    const selectedDate = moment(date).startOf('day'); // Remove time part
    const today = moment().startOf('day'); // Remove time part

    let result = false;

    if (type === 'from') {
        // Start date must be today or later
        result = selectedDate.isSameOrAfter(today);
        console.log("Selected Date:", selectedDate.format("YYYY-MM-DD"));
        console.log("Today:", today.format("YYYY-MM-DD"));
    } else if (type === 'to' && startDate) {
        const startMoment = moment(startDate).startOf('day'); // Normalize startDate
        // End date must be the same as or after the start date
        result = selectedDate.isSameOrAfter(startMoment);
        console.log("Start Date:", startMoment.format("YYYY-MM-DD"));
    }

    return result;
};


export const formatDateShort = (value: string): string => {
    if (!value) return '';

    return moment(new Date(value)).format('D MMM YYYY');
};
