import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
// @ts-ignore
import Accordion from 'react-bootstrap/Accordion'
// @ts-ignore
import Card from 'react-bootstrap/Card'

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    subAccountNoList?: any,
    date?: any
}

export const CreateNewTC = (props: IHeaderProps) => {
    const initialFormData = {
        selectPricDigit: 2,
    }
    let history = useHistory();
    const [dataListTransactionDetails, setDataListTransactionDetails] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)

    const parameterSubAcc = props.location.state.subAccountNoList as IParams
    const parameterDate = props.location.state.date as IParams

    const priceDigit = [
        { label: "2 digit  (x,00)", value: 2 },
        { label: "4 digit  (x,0000)", value: 4 },
        { label: "6 digit  (x,000000)", value: 6 },
        { label: "8 digit  (x,00000000)", value: 8 },
    ]

    useEffect(() => {
        getListTransactionDetails();
    }, []);

    // get data
    const getListTransactionDetails = () => {
        let listAccountNo = parameterSubAcc
        let date = parameterDate
        axios.get(`services/consolidatedportfolioservice/api/asset-management/sub-account/data-transaksi/${date}?subAccountNumbers=${listAccountNo}`).then((res) => {
            setDataListTransactionDetails(res.data.data.content)
        }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    /* handle select */
    const handleInput = (label, val) => {
        if (label == '2 digit  (x,00)') {
            setFormData({
                ...formData,
                [label]: val.value,
            })
        } else {
            setFormData({
                ...formData,
                [label]: val.value
            })
        }
    }

    const createNewTCPreview = () => {
        let subAccountNoList = parameterSubAcc
        let date = parameterDate

        let prcDigit = formData.selectPricDigit
        let payload = {
            subAccountNoList: subAccountNoList,
            date: date,
            priceDigit: prcDigit
        }
        history.push(`/investmentmanager/createnewtc/preview`, payload)
    }

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start">
                        <button className="btn px-0" onClick={() => history.goBack()}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
                        </button>
                        <h1 className="font-family-spoqa mb-0 font-size-28 px-2">Create New TC</h1>
                    </div>
                </div>
            </div>
            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="col-12 col-md-3 col-lg-6">
                    <div className="row">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Price Decimal Digit</h1>
                        <div className="col-12 col-md-3 col-lg-4">
                            <Select
                                name="price"
                                defaultValue={priceDigit[0]}
                                value={priceDigit.filter((function (option: any) {
                                    return option.value == formData.selectPricDigit
                                }))}
                                placeholder="Select Price Digit"
                                options={priceDigit}
                                onChange={(e) => handleInput('selectPricDigit', e)}
                            />
                        </div>
                        <div className="col-12 col-md-3 col-lg-6">
                            <button className='btn btn-primary' onClick={() => createNewTCPreview()
                            }> <span className="bx bx-repost fa-lg my-0"></span> Start Generate TC
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-start">
                <h1 className="font-family-spoqa mb-n4 font-size-28 py-2">List Transaction</h1>
            </div>

            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {dataListTransactionDetails?.map((item, index) => {
                    return <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={index} >
                        <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                            <div className='row' style={{ wordWrap: 'break-word' }} >
                                <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                    <span className='font-weight-bold ml-1' style={{ wordBreak: 'break-word' }}>{item?.SUB_ACCOUNT}</span>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body style={{ backgroundColor: 'white' }}>
                            <div className="py-2">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h1 className="font-family-spoqa mb-0 font-size-14 px-2 fw-bold">BUY INVOICE</h1>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-centered mb-2">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Gross Amount</th>
                                                <th>Broker Fee</th>
                                                <th>Levy</th>
                                                <th>WHT</th>
                                                <th>VAT</th>
                                                <th>PPH23</th>
                                                <th style={{ textAlign: 'right' }}>Net Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item?.BUY ? item?.BUY.map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td> */}
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.ITEM_CD}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PRICE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.QUANTITY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.GROSS_AMOUNT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.BROKER_FEE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.LEVY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.WHT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.VAT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PPH23}</td>
                                                            <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{el?.NET_AMOUNT}</td>
                                                        </tr>
                                                    )
                                                }) : <></>
                                            }
                                            {
                                                item?.TOTAL_BUY ?
                                                    <tr>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY ? 'Total' : '' }</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.GROSS_AMOUNT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.BROKER_FEE}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.LEVY}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.WHT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.VAT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.PPH23}</td>
                                                        <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{item?.TOTAL_BUY.NET_AMOUNT}</td>
                                                    </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="py-2">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h1 className="font-family-spoqa mb-0 font-size-14 px-2 fw-bold">SELL INVOICE</h1>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-centered mb-2">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Gross Amount</th>
                                                <th>Broker Fee</th>
                                                <th>Levy</th>
                                                <th>WHT</th>
                                                <th>VAT</th>
                                                <th>PPH23</th>
                                                <th style={{ textAlign: 'right' }}>Net Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                item?.SELL ? item?.SELL.map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td> */}
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.ITEM_CD}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PRICE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.QUANTITY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.GROSS_AMOUNT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.BROKER_FEE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.LEVY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.WHT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.VAT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PPH23}</td>
                                                            <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{el?.NET_AMOUNT}</td>
                                                        </tr>
                                                    )
                                                }) : <></>
                                            }
                                            {
                                                item?.TOTAL_SELL ?
                                                    <tr>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL ? 'Total' : '' }</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.GROSS_AMOUNT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.BROKER_FEE}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.LEVY}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.WHT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.VAT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.PPH23}</td>
                                                        <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{item?.TOTAL_SELL.NET_AMOUNT}</td>
                                                    </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                })
                } : <></>
            </Accordion>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTC);