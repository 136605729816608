import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import {toast} from "react-toastify";
import {
    GET_ANALYST_LIST, GET_ATTACHMENT_DRAFT,
    GET_COMPANY_SECTOR, GET_DETAIL_DRAFT, GET_GRAPH,
    GET_LIST_RESEARCHER,
    GET_LIST_SECTOR, GET_LIST_STOCK_BY_SECTOR,
    GET_OVERALL_RECOMMENDATION,
    GET_PROGRESS_ANALYST,
    GET_SECTOR_PIE_CHART,
    GET_SUMMARY_TOTAL,
    GET_USER_DETAIL,
    POST_TABLE_ANALYST, PUT_SEND_DRAFT, PUT_UPLOAD_DRAFT
} from "app/entities/researchreport/api-constant-erra";

export const ACTION_TYPES = {
    REFRESH_TOKEN: 'conf/REFRESH_TOKEN',
    FETCH_SUMMARY_TOTAL: 'page1/FETCH_SUMMARY_TOTAL',
    FETCH_SECTOR_PIE_CHART: 'page1/FETCH_SECTOR_PIE_CHART',
    FETCH_COMPANY_SECTOR: 'page1/FETCH_COMPANY_SECTOR',

    FETCH_TABLE_ANALYST_RECOMMENDATION: 'page2/FETCH_TABLE_ANALYST_RECOMMENDATION',
    FILTER_TABLE_ANALYST_RECOMMENDATION: 'page2/FILTER_TABLE_ANALYST_RECOMMENDATION',
    FETCH_LIST_SECTOR: 'page2/FETCH_LIST_SECTOR',
    FETCH_LIST_STOCK_BY_SECTOR: 'page2/FETCH_LIST_STOCK_BY_SECTOR',
    FETCH_LIST_ANALYST: 'page2/FETCH_LIST_ANALYST',

    FETCH_PROFILE: 'page3/FETCH_PROFILE',
    FETCH_PROGRESS_ANALYST: 'page3/FETCH_PROGRESS_ANALYST',
    FETCH_LIST_RESEARCHER: 'page3/FETCH_LIST_RESEARCHER',
    FETCH_OVERALL_RECOMMENDATION: 'page3/FETCH_OVERALL_RECOMMENDATION',
    FETCH_GRAPH_MONTHLY: 'page3/GRAPH_MONTHLY',
    FETCH_GRAPH_ANNUALLY: 'page3/GRAPH_ANNUALLY',

    UPLOAD_DRAFT: 'page4/UPLOAD_DRAFT',
    SEND_DRAFT: 'page4/SEND_DRAFT',
    FETCH_DETAIL_DRAFT: 'page4/DETAIL_DRAFT',
    FETCH_ATTACHMENT: 'page4/FETCH_ATTACHMENT'

};

const initialState = {
    loading: false,
    errorMessage: null,

    //Page 1
    dataSummaryTotal: [] as any,
    dataLabelPieChart: [] as any,
    dataValuePieChart: [] as any,
    dataColorPieChart: [] as any,
    dataLabelCompanySector: [] as any,
    dataValueCompanySector: [] as any,
    dataColorCompanySector: [] as any,

    //Page 2
    dataAnalystRecommendation: [] as any,
    dataListSector: [] as any,
    dataListStockBySector: [] as any,
    dataListAnalyst: [] as any,
    dataColorAnalyst: [] as any,
    totalPages: 0,

    //Page 3
    dataProfile: {} as any,
    dataProgressAnalyst: [] as any,
    isShowProfile: false,
    dataListResearcher: [] as any,
    dataOverallRecommendation: [] as any,
    dataAreaChart: [] as any,
    labelAreaChart: [] as any,
    dataBarChart: [] as any,
    labelBarChart: [] as any,

    //Page 4
    generatedDocumentNumber: null,
    uploadRes: null,
    sendDocumentRes: null,
    draftDetail: null,
    fileAttachment: null
};

export type ResearchReportState = Readonly<typeof initialState>;

export default (state: ResearchReportState = initialState, action): ResearchReportState => {
    switch (action.type) {

        //Page 1
        case REQUEST(ACTION_TYPES.FETCH_SUMMARY_TOTAL):
        case REQUEST(ACTION_TYPES.FETCH_SECTOR_PIE_CHART):
        case REQUEST(ACTION_TYPES.FETCH_COMPANY_SECTOR):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_SUMMARY_TOTAL):
        case FAILURE(ACTION_TYPES.FETCH_SECTOR_PIE_CHART):
        case FAILURE(ACTION_TYPES.FETCH_COMPANY_SECTOR):
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_SUMMARY_TOTAL):
            return {
                ...state,
                loading: false,
                dataSummaryTotal: action.payload.data
            };
        case SUCCESS(ACTION_TYPES.FETCH_SECTOR_PIE_CHART):
            const dataSectorPieChart = action.payload.data;
            const valueLabels = [];
            const valueDatas = [];
            const valueColors = [];

            dataSectorPieChart.map((element, index) => {
                const color = hexToRGB(element.color)
                valueLabels.push(element.sector_name)
                valueDatas.push(element.total)
                valueColors.push(color)
            })
            return {
                ...state,
                loading: false,
                dataLabelPieChart: valueLabels,
                dataValuePieChart: valueDatas,
                dataColorPieChart: valueColors
            };

        case SUCCESS(ACTION_TYPES.FETCH_COMPANY_SECTOR):
            const dataCompanySector = action.payload.data;
            const valueLabelCompanySector = [];
            const valueDatasCompanySector = [];
            const valueColorsCompanySector = [];
            dataCompanySector.map((element, index) => {
                valueLabelCompanySector.push(element.sector_name)
                valueDatasCompanySector.push(element.total)
                valueColorsCompanySector.push(element.color)
            })
            return {
                ...state,
                loading: false,
                dataLabelCompanySector: valueLabelCompanySector,
                dataValueCompanySector: valueDatasCompanySector,
                dataColorCompanySector: valueColorsCompanySector,
            };

        //Page 2
        case SUCCESS(ACTION_TYPES.FETCH_TABLE_ANALYST_RECOMMENDATION):
            return {
                ...state,
                loading: false,
                dataAnalystRecommendation: action.payload.data.data,
                totalPages: action.payload.data.totalPages,
            }

        case SUCCESS(ACTION_TYPES.FILTER_TABLE_ANALYST_RECOMMENDATION):
            return {
                ...state,
                loading: false,
                dataAnalystRecommendation: action.payload.data.data,
                totalPages: action.payload.data.totalPages,
            }

        case SUCCESS(ACTION_TYPES.FETCH_LIST_SECTOR):
            const dataListSector = action.payload.data;
            const formattedListSector = [];
            dataListSector.map((item) => {
                formattedListSector.push({label: item.name, value: item.code})
            })
            return {
                ...state,
                loading: false,
                dataListSector: formattedListSector,
            };

        case SUCCESS(ACTION_TYPES.FETCH_LIST_STOCK_BY_SECTOR):
            const dataListStockBySector = action.payload.data
            const formattedListStockBySector = [];
            dataListStockBySector.map((item) => {
                formattedListStockBySector.push({label: item.name, value: item.code})
            })
            return {
                ...state,
                loading: false,
                dataListStockBySector: formattedListStockBySector
            }

        //Page 3
        case SUCCESS(ACTION_TYPES.FETCH_LIST_ANALYST):
            const dataListAnalyst = action.payload.data
            const formattedListAnalyst = [];
            dataListAnalyst.map((item) => {
                formattedListAnalyst.push({label: item.name, value: item.user_id})
            })
            return {
                ...state,
                loading: false,
                dataListAnalyst: formattedListAnalyst
            }

        case REQUEST(ACTION_TYPES.FETCH_PROFILE):
            return {
                ...state,
                loading: false,
                isShowProfile: false
            }

        case SUCCESS(ACTION_TYPES.FETCH_PROFILE):
            return {
                ...state,
                loading: false,
                dataProfile: action.payload.data.data,
                isShowProfile: true
            }

        case SUCCESS(ACTION_TYPES.FETCH_PROGRESS_ANALYST):
            return {
                ...state,
                loading: false,
                dataProgressAnalyst: action.payload.data.data
            }

        case SUCCESS(ACTION_TYPES.FETCH_LIST_RESEARCHER):
            const dataListResearcher = action.payload.data
            const formattedListResearcher = [];
            dataListResearcher.map((item) => {
                formattedListResearcher.push({label: item.name, value: item.user_id})
            })
            return {
                ...state,
                loading: false,
                dataListResearcher: formattedListResearcher
            }

        case SUCCESS(ACTION_TYPES.FETCH_OVERALL_RECOMMENDATION):
            return {
                ...state,
                loading: false,
                dataOverallRecommendation: action.payload.data.data
            }

        case SUCCESS(ACTION_TYPES.FETCH_GRAPH_MONTHLY):
            const dataListMonthly = action.payload.data.data
            const formattedListMonthlyLabel = [];
            const formattedListMonthlyData = [];
            dataListMonthly.map((item) => {
                formattedListMonthlyLabel.push(item.time)
                formattedListMonthlyData.push(item.value)
            })

            return {
                ...state,
                loading: false,
                dataAreaChart: formattedListMonthlyData,
                labelAreaChart: formattedListMonthlyLabel
            }

        case SUCCESS(ACTION_TYPES.FETCH_GRAPH_ANNUALLY):
            const dataListAnnually = action.payload.data.data
            const formattedListAnnuallyLabel = [];
            const formattedListAnnuallyData = [];
            dataListAnnually.map((item) => {
                formattedListAnnuallyLabel.push(item.time)
                formattedListAnnuallyData.push(item.value)
            })
            return {
                ...state,
                loading: false,
                dataBarChart: formattedListAnnuallyData,
                labelBarChart: formattedListAnnuallyLabel
            }

        //Page 4
        case REQUEST(ACTION_TYPES.UPLOAD_DRAFT):
            return {
                ...state,
                loading: true,
                uploadRes: null
            }

        case SUCCESS(ACTION_TYPES.UPLOAD_DRAFT):
            return {
                ...state,
                loading: false,
                uploadRes: action.payload.data.data
            }

        case FAILURE(ACTION_TYPES.UPLOAD_DRAFT):
            return {
                ...state,
                loading: false,
                uploadRes: null
            }

        case REQUEST(ACTION_TYPES.SEND_DRAFT):
            return {
                ...state,
                loading: true,
                sendDocumentRes: null
            }

        case SUCCESS(ACTION_TYPES.SEND_DRAFT):
            toast.success("Success Upload");
            return {
                ...state,
                loading: false,
                sendDocumentRes: action.payload.data
            }

        case FAILURE(ACTION_TYPES.SEND_DRAFT):
            return {
                ...state,
                loading: false,
            }

        case SUCCESS(ACTION_TYPES.REFRESH_TOKEN):
            return {
                ...state,
                loading: false
            }

        case REQUEST(ACTION_TYPES.FETCH_DETAIL_DRAFT):
            // toast.success("Success Upload");
            return {
                ...state,
                loading: true,
            }

        case SUCCESS(ACTION_TYPES.FETCH_DETAIL_DRAFT):
            return {
                ...state,
                loading: false,
                draftDetail: action.payload.data.data
            }

        case REQUEST(ACTION_TYPES.FETCH_ATTACHMENT):
            // toast.success("Success Upload");
            return {
                ...state,
                loading: true,
            }

        case SUCCESS(ACTION_TYPES.FETCH_ATTACHMENT):
            return {
                ...state,
                loading: false,
                fileAttachment: action.payload.data.data
            }
        default:
            return state;
    }
}

const hexToRGB = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255]+')';
    }
    throw new Error('Bad Hex');
}

//Page 1
export const getSummaryTotal = () => ({
    type: ACTION_TYPES.FETCH_SUMMARY_TOTAL,
    payload: axios.get(GET_SUMMARY_TOTAL),
});
export const getSectorPieChart = () => ({
    type: ACTION_TYPES.FETCH_SECTOR_PIE_CHART,
    payload: axios.get(GET_SECTOR_PIE_CHART),
});
export const getCompanySector = () => ({
    type: ACTION_TYPES.FETCH_COMPANY_SECTOR,
    payload: axios.get(GET_COMPANY_SECTOR),
});


//Page 2
export const getAnalystRecommendation = () => ({
    type: ACTION_TYPES.FETCH_TABLE_ANALYST_RECOMMENDATION,
    payload: axios.post(POST_TABLE_ANALYST)
});
export const getAnalystRecommendationFilter = (sort) => ({
    type: ACTION_TYPES.FETCH_TABLE_ANALYST_RECOMMENDATION,
    payload: axios.post(POST_TABLE_ANALYST, sort)
});

export const getSectorList = (id) => async dispatch => {
    await dispatch({
        type: ACTION_TYPES.FETCH_LIST_SECTOR,
        payload: axios.get(
            GET_LIST_SECTOR
        )
    });

    if(id != null) {
        await dispatch(getDetailApproval(id))
    }
};

export const getStockBySectorList = (sector) => ({
    type: ACTION_TYPES.FETCH_LIST_STOCK_BY_SECTOR,
    payload: axios.get(
        `${GET_LIST_STOCK_BY_SECTOR}/${encodeURIComponent(sector)}`
    )
})

//Page 2 & Page 3
export const getAnalystList = () => ({
    type: ACTION_TYPES.FETCH_LIST_ANALYST,
    payload: axios.get(
        GET_ANALYST_LIST
    )
})

//Page 3
export const getProfile = (userid) => ({
    type: ACTION_TYPES.FETCH_PROFILE,
    payload: axios.get(
        `${GET_USER_DETAIL}/${userid}`
    )
})
export const getProgressAnalyst = (userid) => ({
    type: ACTION_TYPES.FETCH_PROGRESS_ANALYST,
    payload: axios.get(
        `${GET_PROGRESS_ANALYST}/${userid}`
    )
})
export const getResearcherList = () => ({
    type: ACTION_TYPES.FETCH_LIST_RESEARCHER,
    payload: axios.get(
        GET_LIST_RESEARCHER
    )
})
export const getOverallRecommendation = (userid) => ({
    type: ACTION_TYPES.FETCH_OVERALL_RECOMMENDATION,
    payload: axios.get(
        `${GET_OVERALL_RECOMMENDATION}/${userid}`
    )
})
export const getGraphMonthly = (userid) => ({
    type: ACTION_TYPES.FETCH_GRAPH_MONTHLY,
    payload: axios.get(
            `${GET_GRAPH}/${userid}/month`
    )
})
export const getGraphAnnually = (userid) => ({
    type: ACTION_TYPES.FETCH_GRAPH_ANNUALLY,
    payload: axios.get(
            `${GET_GRAPH}/${userid}/year`
    )
})

//Page 4
export const sendDraft = (file, body) => {

    const formData = new FormData()
    formData.append("fileAttachment", file)
    formData.append("data", body)
    return ({
        type: ACTION_TYPES.UPLOAD_DRAFT,
        payload: axios.put(
            PUT_UPLOAD_DRAFT,
            formData
        )
    })
}
export const submitRequest = (draftResponse, body) => {

    const formData = new FormData()
    formData.append("id", draftResponse?.id)
    formData.append("fileNumberTemp", draftResponse?.fileNumber)
    formData.append("data", body)
    formData.append("html", "")

    return ({
        type: ACTION_TYPES.SEND_DRAFT,
        payload: axios.put(
            PUT_SEND_DRAFT,
            formData
        )
    })
}

export const getDetailApproval =  (id)  => async dispatch => {
    await dispatch({
        type: ACTION_TYPES.FETCH_DETAIL_DRAFT,
        payload: axios.get(`${GET_DETAIL_DRAFT}/${id}`)
    })

    if (id != null) {
        await dispatch(getAttachment(id))
    }
}

export const getAttachment = (id) => ({
    type: ACTION_TYPES.FETCH_ATTACHMENT,
    payload: axios.get(`${GET_ATTACHMENT_DRAFT}?fileId=${id}`)
})
