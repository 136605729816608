import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Spinner } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument } from './../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import createBrowserHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';
import { Box, CircularProgress } from '@mui/material';
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import IconChecklist from "app/component/icon-checklist";
import './previewapproval-style.scss'
import moment from "moment-timezone";
import ChevronDownIcon from 'app/component/chevron-down-icon';

const history = createBrowserHistory({ forceRefresh: true });

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export interface Props {
  getDocumentDetail?: any,
  match?: any,
  viewPDF?: any,
  detaildocument?: any,
  signDocument?: any,
  approveDocument?: any,
  blobPreview?: any,
  filePDFSign?: any,
  rejectDocument?: any,
  PreviewPDFComponent?: any,
  history?: any
}

export const PreviewAcknowledge: React.FC<Props> = (props) => {
  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [showModalReject, setShowModalReject] = useState(false)
  const [showModalSign, setShowModalSign] = useState(false)
  // const [notesApprove, setNotesApprove] = useState('I approved this document')
  const [notesApprove, setNotesApprove] = useState('')
  const [notesReject, setNotesReject] = useState('I approved this document')
  const [previewApprove, setPreviewApprove] = useState(false)
  const [typeTransaction, setTypeTransaction] = useState(1) // 1 -> approval / 2 -> reject
  const [urlBlob, setUrlBlob] = useState<any>(null)
  const [listAttachment, setListAttachment] = useState<any>([])
  const [loadingDraftFile, setLoadingDraftFile] = useState(false)
  const [numberFile, setNumberFile] = useState<any>("")
  const [modalNotifDownload, setModalNotifDownload] = useState(false)
  const [loadingDownload, setLoadindDownload] = useState(false)
  const [typeValue, setTypeValue] = useState('');
  const [documentDetail, setDocumentDetail] = useState<any>(null)

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const signCanvas = useRef<any>();

  const clear = () => signCanvas.current.clear();
  const save = () => {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    //console dataURLtoBlob(basse64)
  }

  useEffect(() => {
    // const windowUrl = window.location.search;
    // const params = new URLSearchParams(windowUrl);
    // setNumberFile(params.get("fileNumber"))
    getDetailDocument(props.match.params.id)
    getFilePDF()
  }, []);

  const getFilePDF = () => {
    axios.get(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${props.match.params.id}`,
      { responseType: 'blob' })
      .then(res => {
        setUrlBlob(URL.createObjectURL(res.data))
        getListAttachment()
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (err.response.status == 401) {
          history.push('/new/401')
        }
        if (err.response.status == 404) {
          history.push('/new/404')
        }
      })
  }

  const getDetailDocument = (id) => {
    axios.get('/services/documentservice/api/documents/get-detail/new/' + id)
      .then(res => {
        setDocumentDetail(res.data.data)
        setTypeValue(res.data.data.type)
        setNumberFile(res.data.data.fileNumber)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (err.response.status == 401) {
          // history.push(`/preview/only/pdf/${id}`)
          history.push('/new/401')
        }
        if (err.response.status == 404) {
          history.push('/new/404')
        }
      })
  }

  const getListAttachment = () => {
    setLoadingDraftFile(true)
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`)
      .then(result => {
        setLoadingDraftFile(false)
        setListAttachment(result.data.data.content)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`))
        setLoadingDraftFile(false)
      })
  }

  const handleClose = () => {
    setNotesApprove('')
    setShowModal(false)
  };

  const handleOpen = () => {
    setShowModal(true)
  }

  const handleCloseReject = () => {
    setNotesReject('')
    setShowModalReject(false)
  };

  const handleOpenReject = () => {
    setShowModalReject(true)
  }

  const handleCloseSign = () => {
    setShowModalSign(false)
  };

  const handleOpenSign = () => {
    setShowModalSign(true)
  }

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }


  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl)
  }

  const renderViewPDF = (id) => {
    props.viewPDF(id)
  }

  const submit = (datadokumen) => {
    if (datadokumen.isSigned == false) {
      pdf(<PreviewPDFComponent
        from={props.detaildocument?.senderBranchName}
        title={props.detaildocument?.fileNumber}
        regarding={props.detaildocument?.regarding}
        date={props.detaildocument?.date}
        to={props.detaildocument?.to}
        fileContent={props.detaildocument?.fileContent}
        cc={props.detaildocument?.cc}
        dataDocument={props.detaildocument}
        acknowledge={props.detaildocument?.acknowledgeList}
      />).toBlob().then(res => {
        let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        let signFile = dataURLtoBlob(basse64)
        props.signDocument(res, signFile, props.match.params.id)
        setShowModalSign(false)

      })
    } else {
      let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      let signFile = dataURLtoBlob(basse64)
      props.signDocument(props.blobPreview, signFile, props.match.params.id)
      setShowModalSign(false)
    }
  }

  const submitApprove = (datadokumen) => {
    setPreviewApprove(true)
    setShowModal(false)
  }

  const sendApprove = () => {
    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesApprove
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 7')

    axios.post(`/services/documentservice/api/dms/send-approve?requestParam=${encodedValue}`)
      .then(res => {
        props.history.push('/')
      }).catch(err => {

      })
  }

  const submitReject = (datadokumen) => {
    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesReject
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 10')

    axios.post(`services/documentservice/api/dms/confirm-reject?requestParam=${encodedValue}`).then(res => {
      setShowModalReject(false)
      props.history.push('/')
    }).catch(err => {
      setShowModalReject(false)
    })
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  let datadetail = props.detaildocument

  const pressCloseModal = () => {
    setModalNotifDownload(false)
    window.location.reload()
  }

  const downloadFilePDF = () => {
    setLoadindDownload(true)
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = `DMS-${numberFile}.pdf`;

    const toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))

    toDataURL(link.href)
      .then(dataUrl => {
        const formDataFinal = new FormData();
        formDataFinal.append("id", props.match.params.id);
        formDataFinal.append("filePdf", dataURLtoFile(dataUrl, link.download));
        axios.post(`/services/documentservice/api/dms/download-document`, formDataFinal, { responseType: 'blob' })
          .then(result => {
            axios.get(`/services/documentservice/api/documents/get-detail/new/${props.match.params.id}`)
              .then(res => {
                const linknewfile = document.createElement('a');
                linknewfile.href = URL.createObjectURL(result.data);
                linknewfile.download = `DMS-${numberFile}.pdf`;
                document.body.appendChild(linknewfile);
                linknewfile.click();
                setTimeout(function () {
                  document.body.removeChild(linknewfile);
                }, 100);
                setLoadindDownload(false)

                var responseData = res.data.data;
                console.log("res.data.data", res.data.data)
                if (responseData.fileStatus == "DONE") {
                  if (responseData.documentSecurity == "1" || responseData.documentSecurity == "2") {
                    setModalNotifDownload(true)
                  }
                }
              }).catch(err => {
                toast.error(translate(`${err.response.data.message}`))
              })
          }).catch(err => {
            toast.error(translate(`${err.response.data.message}`));
          })
      })
  }

  const viewModalNotifDownload = () => {
    return (
      <Modal
        isOpen={modalNotifDownload}
        size="md"
        centered={true}
        backdrop={"static"}>
        <ModalHeader>
          <div className="font-size-18 font-family-spoqa">
            Notification
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
            <div className="" style={{ display: "flex", justifyContent: "center" }}>
              <IconChecklist />
            </div>
            <div className="font-size-18 mt-3" style={{ textAlign: "center" }}>
              You've just downloaded a Restricted document.
            </div>
            <div className="font-size-18" style={{ textAlign: "center" }}>
              Please check your notification for your document password. Thank you !
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            id="use-a-template" type="submit"
            style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
            onClick={pressCloseModal}>
            <span>Ok</span>
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  if (props.detaildocument.isSigned == false) {
    return (
      <div className="container">
        <Row>
          <Col>
            <h2>Acknowledge</h2>
            <p>File Preview Acknowledge</p>
          </Col>
        </Row>
        <PDFViewer style={{ width: '100%', height: '1000px' }} >
          <PreviewPDFComponent
            from={props.detaildocument?.senderBranchName}
            title={props.detaildocument?.fileNumber}
            regarding={props.detaildocument?.regarding}
            date={props.detaildocument?.date}
            to={props.detaildocument?.to}
            fileContent={props.detaildocument?.fileContent}
            cc={props.detaildocument?.cc}
            dataDocument={props.detaildocument}
            acknowledge={props.detaildocument?.acknowledgeList}
            notesApprove={notesApprove}
            notesReject={notesReject}
          />
        </PDFViewer>
        <Row className='mb-4 mt-4'>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
          </Col>
          <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            {/* <button className='btn btn-secondary mr-2 px-4'>Cancel</button> */}
            <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1' />Reject</button>
            <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button>
            <button className='btn btn-success px-3' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1' /> Approve</button>
          </Col>
        </Row>
        <Modal isOpen={showModal} toggle={handleClose} size="lg">
          <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Disetujui Dengan Catatan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <span>Notes</span>
            <FormGroup>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                maxLength={255}
                value={notesApprove}
                onChange={(e) => setNotesApprove(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesApprove.length}/255</div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}>
              </Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="success" onClick={() => submitApprove(props.detaildocument)}>
                  Confirm Approve
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg">
          <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Ditolak Dengan Alasan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <FormGroup>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                maxLength={255}
                value={notesReject}
                onChange={(e) => setNotesReject(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesReject.length}/255</div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}>
              </Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleCloseReject} className="mr-2">
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject}>
                  Confirm Reject
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
          <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question" style={{ backgroundColor: '#fff', justifyContent: 'center', display: 'flex' }}>
            <div style={{ backgroundColor: '#bdbdbd', width: 150, height: 100 }}>
              <SignaturePad
                ref={signCanvas}
                canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={12}>
              </Col>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
                  Clear
                </Button>
                <Button color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
                  Confirm Sign
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    )
  } else {
    return (
      <div className="container">
        <Row>
          <Col>
            <h2>Preview Document</h2>
            <p>File Preview Document</p>
          </Col>
        </Row>
        <div className="pdf" />
        {urlBlob ?
          <iframe width="100%" src={`${urlBlob}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} />
          :
          <div className="shine photo"></div>}

        {typeValue == "ERRA" &&
          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Document Detail
            </h5>
            <>
              <div className="card p-2">
                <div className="card-body">

                  {/*ROW*/}
                  <div className="row font-family-spoqa mb-3">

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Report Type:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.reportType}</span>
                        </div>
                      </div>
                    </div>

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Recommendation:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.recommendation}</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/*ROW*/}
                  <div className="row font-family-spoqa mb-3">

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Sector:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.sectorName} ({documentDetail.sectorCode})</span>
                        </div>
                      </div>
                    </div>

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Stock:
                        <div className="position-relative pass-wrapper mt-2">
                          {documentDetail.reportType == "company" ?
                            <span>{documentDetail.stockName} ({documentDetail.stockCode})</span> : <span> - </span>
                          }
                        </div>
                      </div>
                    </div>

                  </div>

                  {/*ROW*/}
                  <div className="row font-family-spoqa mb-3">

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Target Price:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.targetPrice}</span>
                        </div>
                      </div>
                    </div>

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Period:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.period}</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/*ROW*/}
                  <div className="row font-family-spoqa mb-3">

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Publish Date:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{moment(documentDetail.releaseDate).format("DD-MM-YYYY")}</span>
                        </div>
                      </div>
                    </div>

                    {/*COL*/}
                    <div className="font-family-spoqa mb-2 col-6">
                      <div className="mb-2 font-weight-bold">
                        Note:
                        <div className="position-relative pass-wrapper mt-2">
                          <span>{documentDetail.notes}</span>
                        </div>
                      </div>
                    </div>

                  </div>




                </div>
              </div>
            </>
          </div>}
        {typeValue != "ERRA" &&
          <div className="bg-white rounded shadow my-4 px-4 py-4">
          <h5>
            Attachment
          </h5>
          {
            listAttachment.length > 0
              ?
              listAttachment.map((item, index) => {
                return (
                  <div key={index} onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                    <div>
                      <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{cursor:'pointer'}}>
                            {item.fileName} ( {bytesToSize(item.fileSize)} )
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              (loadingDraftFile == true
                ?
                <div className="text-center my-4">
                  <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color='warning' />
                  </Box>
                </div>
                :
                <></>
              )
          }
        </div>
        }

        <Row className='mb-4 mt-4'>
          <Col></Col>
          {
            previewApprove ?
              <Col className='text-right'></Col>
              :
              <Col className='text-right'>
                {numberFile != null
                  ?
                  <Button className="btn btn-secondary px-3 mr-2" onClick={downloadFilePDF} disabled={loadingDownload}>
                    {loadingDownload ? <Spinner color='#fff' size="sm" className='mr-1' /> : <FontAwesomeIcon icon={faFileDownload} />}
                    &nbsp;
                    Download
                  </Button>
                  :
                  <></>
                }

                <button className='btn btn-light mr-2 px-3' style={{ border: '1px solid #000' }} onClick={() => window.history.back()}>Back</button>
              </Col>
          }
        </Row>
        <Modal isOpen={showModal} toggle={handleClose} size="lg">
          <ModalHeader toggle={handleClose}>
            <h2>Approval Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Disetujui Dengan Catatan : </span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <span>Notes</span>
            <FormGroup>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                value={notesApprove}
                maxLength={255}
                onChange={(e) => setNotesApprove(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesApprove.length}/255</div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}></Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="success" onClick={() => submitApprove(props.detaildocument)}>
                  Confirm Approve
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalReject} toggle={handleCloseReject} size="lg">
          <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Ditolak Dengan Alasan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <FormGroup>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                maxLength={255}
                value={notesReject}
                onChange={(e) => setNotesReject(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesReject.length}/255</div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}>
              </Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleClose} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject}>
                  Confirm Reject
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalSign} toggle={handleCloseSign} size="sm">
          <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question" style={{ backgroundColor: '#fff', justifyContent: 'center', display: 'flex' }}>
            <div style={{ backgroundColor: '#bdbdbd', width: 150, height: 100 }}>
              <SignaturePad
                ref={signCanvas}
                canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={12}>
              </Col>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
                  Clear
                </Button>
                <Button color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
                  Confirm Sign
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {viewModalNotifDownload()}
      </div>
    );
  }
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAcknowledge);