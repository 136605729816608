import * as React from "react"
import { SVGProps } from "react"

const AccountAnnounceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9 1.4a.8.8 0 0 0-1.158-.716L5.512 3.8H2.5a2.4 2.4 0 0 0 0 4.8h.223l1.418 4.253a.8.8 0 0 0 .759.547h.8a.8.8 0 0 0 .8-.8V9.094l5.242 2.621A.8.8 0 0 0 12.9 11V1.4Z"
      fill="#fff"
    />
  </svg>
)

export default AccountAnnounceIcon
