import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconCalendar from "app/component/icon-calendar";
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useParams, useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { baseUrlAPI } from './configCareer';
// import id from 'date-fns/locale/id';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    key?: any,
}

export const JobsDetail = (props: IHeaderProps) => {
    const initialFormData = {
        listStatus: null,
        selectStatus: null,
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("full_name")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListOfJobs, setDataListOfJobs] = useState<any>([])
    const [dataDetailofJob, setDataDetailofJob] = useState<any>([])
    const [dataListofCandidates, setDataListofCandidates] = useState<any>([])
    const [dataListofStatus, setDataListofStatus] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [selectedStartDate, setSelectedStartDate] = useState(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState(new Date())

    // const parameter = props.location.state.key as IParams
    const { id } = useParams();

    useEffect(() => {
        getDetailJob()
        getCandidates()
        getStatus()
    }, [page, size, totalPage, keyword, formData, sort, ascending, formData.selectStatus]);

    const token = localStorage.getItem('access_token');

    // get data
    const getDetailJob = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/job/get-detail/${id}`, config)
            .then((res) => {
                setDataDetailofJob(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getCandidates = () => {

        let processStatus = !formData.listStatus ? "" : formData.listStatus

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/job/get-candidates?job_id=${id}&keyword=${keyword}&process_status=${processStatus}&page=${page}&limit=${size}&sort_by=${sort} ${!ascending ? 'DESC' : 'ASC'}`, config)

            .then((res) => {
                setDataListofCandidates(res.data.data);
                setTotalPage(res.data.metadata.total_page);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }


    const getStatus = () => {
        axios.get(`${baseUrlAPI()}/process-status/get-list`).then((res) => {
            const response = res.data.data
            const newStatus = response.map((obj, i) => ({ label: obj.process_status_name, value: obj.process_status_code }));
            let addStatus = [{ label: "All", value: 0 }, ...newStatus];
            setDataListofStatus(addStatus)
        }).catch((err) => {
            throw new Error('getStatus')
        })
    }

    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    const handleClickBack = () => {
        history.push(`/career/jobs`)
    };

    const handleInput = (name, value) => {
        if (name == 'listStatus') {
            setFormData({ ...formData, selectStatus: null })
            setFormData({
                ...formData,
                [name]: value.value,
            })
            setPage(1)
            // getStatus()
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
            setPage(1)
        }
    }

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const iframeRef = useRef(null);

    useEffect(() => {
        const setIframeHeight = () => {
            if (iframeRef.current) {
                const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                const height = iframeDocument.body.scrollHeight + 'px';
                iframeRef.current.style.height = height;
            }
        };

        window.addEventListener('resize', setIframeHeight);
        setIframeHeight();

        return () => {
            window.removeEventListener('resize', setIframeHeight);
        };
    }, [dataDetailofJob.description]);

    return (
        <div className=" px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start my-2">
                        <button className="btn px-0" onClick={handleClickBack}>
                            <div className="font-family-spoqa mb-0 font-size-20" style={{ color: '#f58220' }}>
                                <FontAwesomeIcon icon="arrow-left" color="#f58220" /> Back to Job List
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {dataDetailofJob && (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="row w-100">
                                    <div className="col-10 d-flex align-items-center">
                                        <div className="font-family-spoqa font-weight-bold mb-0 font-size-30 pe-2 ps-0">
                                            {dataDetailofJob.position}
                                        </div>
                                        <button
                                            className="font-family-spoqa mb-0 py-1 px-4"
                                            disabled={true}
                                            style={{
                                                backgroundColor: dataDetailofJob.status_code === 'Active' ? 'green' : 'red',
                                                color: '#fff',
                                                border: 'none',
                                                padding: '8px 20px', 
                                                borderRadius: '20px'
                                            }}
                                        >
                                            {dataDetailofJob.status_code}
                                        </button>
                                    </div>
                                    <div className="col-2 d-flex justify-content-end">
                                        <button
                                            className="btn btn-primary font-family-spoqa mb-0 py-1 px-4"
                                            onClick={() => {
                                                history.push(`/career/jobs/edit/${dataDetailofJob?.id}`, { key: dataDetailofJob?.id });
                                            }}
                                            style={{
                                                borderRadius: '20px'
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-start">
                                <div className="font-family-spoqa mb-0 font-size-24 ">{dataDetailofJob.location_name} - Valid until {dataDetailofJob.due_date}</div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-start">
                                <div className="font-family-spoqa mb-n4 font-size-30">Detail</div>
                            </div>
                            <hr className="mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="row w-100">
                                <div className="col-md-6 col-sm-12">
                                    <div className="row my-3">
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Division/Department
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none' }}
                                                value={dataDetailofJob.division_name}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Number of Position Available
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none' }}
                                                value={dataDetailofJob.number_of_position}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row my-3">
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Type of Employment
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none' }}
                                                value={dataDetailofJob.employment_type}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Job Level
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none' }}
                                                value={dataDetailofJob.job_level_name}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start ms-n2">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Job Description</div>
                                </div>
                                <hr className="ms-2 mt-n2 " style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                        <div style={{ width: '100%', height: 'auto', border: 'none', overflow: 'visible' }}>
                                            <div dangerouslySetInnerHTML={{ __html: dataDetailofJob.description }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Job Requirement</div>
                                </div>
                                <hr className="ms-2 mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                        <div style={{ width: '100%', height: 'auto', border: 'none', overflow: 'visible' }}>
                                            <div dangerouslySetInnerHTML={{ __html: dataDetailofJob.requirement }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-start">
                                <div className="font-family-spoqa mb-n4 font-size-30 px-2">Candidate Applied</div>
                            </div>
                            <hr className="ms-2 mt-n2 " style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                        </div>
                    </div>
                </div>
            )}

            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="col-10 col-md-10 col-lg-10">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-white py-3" value={keyword} placeholder="Search Candidate" onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        getCandidates()
                                        setPage(1)
                                    } else {
                                        setKeyword(e.target.value)
                                        setPage(1)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getCandidates()
                                    }
                                }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <Select
                                name="status"
                                defaultValue={{ label: "All", value: 0 }}
                                value={dataListofStatus.filter((function (option: any) {
                                    return option.value == formData.listStatus
                                }))}
                                placeholder="Select Status"
                                options={dataListofStatus}
                                onChange={(e) => handleInput('listStatus', e)}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="card font-family-spoqa">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th >No</th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('full_name')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Candidate Name</span>
                                            <span className='mt-1'>
                                                {(sort == "full_name" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "full_name" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "full_name") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('email')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Email</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th>Mobile Number</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataListofCandidates ? dataListofCandidates.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='py-2' style={{ wordBreak: 'break-all' }}>{item?.no}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.full_name}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.email}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.cellphone_number}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.process_status}</td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    <button className='btn btn-primary my-n2' onClick={() => {
                                                        history.push(`/career/candidate/detail/${item?.candidate_id}/${item?.job_candidate_id}`, { key: `/career/jobs/detail/${id}` })
                                                    }}> Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4 font-family-spoqa">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">Show</span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(JobsDetail);