import * as React from "react"
import { SVGProps } from "react"

const DragUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={41}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m22.887 24.4.155.13 11.546 11.333a5.391 5.391 0 0 1-2.838.803H12.583a5.39 5.39 0 0 1-2.838-.802L21.292 24.53l.138-.118a1.25 1.25 0 0 1 1.457-.012ZM31.75 6.665a5.416 5.416 0 0 1 5.417 5.417v19.166a5.407 5.407 0 0 1-.809 2.85L24.793 22.744l-.213-.193a3.75 3.75 0 0 0-4.815-.01l-.225.205L7.975 34.098a5.392 5.392 0 0 1-.808-2.849V20.836a10.834 10.834 0 0 0 14.17-14.17H31.75Zm-20.417-5a9.166 9.166 0 1 1 0 18.332 9.166 9.166 0 0 1 0-18.332Zm17.087 10a3.754 3.754 0 1 0 0 7.507 3.754 3.754 0 0 0 0-7.507Zm0 2.5a1.253 1.253 0 1 1 0 2.507 1.253 1.253 0 0 1 0-2.507ZM11.333 4.999l-.15.012a.833.833 0 0 0-.67.67l-.013.152v4.166H6.33l-.15.014a.833.833 0 0 0-.67.67l-.013.15.013.15a.833.833 0 0 0 .67.67l.15.013h4.17v4.172l.013.15a.833.833 0 0 0 .67.67l.15.015.15-.015a.833.833 0 0 0 .67-.67l.014-.15v-4.172h4.175l.15-.013a.832.832 0 0 0 .67-.67l.013-.15-.013-.15a.833.833 0 0 0-.672-.67l-.15-.014h-4.173V5.833l-.014-.15a.833.833 0 0 0-.67-.672l-.15-.012Z"
      fill="#9E9E9E"
    />
  </svg>
)

export default DragUploadIcon
