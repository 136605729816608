import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const Notification = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [listNotification, setListNotification] = useState<any>([])


  const renderStatusFileHistories = (status, statusName) => {
    if (status == "CREATED" || status == "REVISE") {
      return <div style={{ background: '#FFF4EB', border: '1px solid #F37F26', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
    } else if (status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION' || status == 'WAITING_FOR_APPROVAL') {
      return <div style={{ color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
    } else {
      return <div style={{ color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px' }} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
    }
  }


  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)

    getListNotification()
  }, [page, size, totalPage]);



  const handleChange = (event: SelectChangeEvent) => {
    setSize(parseInt(event.target.value));
  };


  useEffect(() => {
    getListNotification()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListNotification = () => {
    setLoadingList(true)
    axios.get(`services/documentservice/api/notification/get-list?page=${page}&size=${size}&sort=createdDate,desc`)
      .then((res) => {
        setListNotification(res.data.data.content)
        setTotalPage(res.data.data.totalPages)
        setLoadingList(false)
      }).catch((err) => {
        setLoadingList(false)

      })
  }



  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }


  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`)
        .then(async (result) => {
          setListSearch(result.data.content)
          // setTotalPage(result.data?.totalPages)
          await delayTime(2000)
          setLoadingList(false)
        }).catch(err => {
          setListSearch([])
          setLoadingList(false)
        })
    }
  }





  const goToPage = (data) => {
    setSearch('')
    axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.id}`).then((res) => {
      history.push(data.pageUrl)
    }).catch((err) => {
      history.push(data.pageUrl)
    })
  }


  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderUnread = (data) => {
    if (!data.isRead) {
      return <span style={{ background: '#FFF4EB', border: '1px solid #F37F26', borderRadius: '6px' }} className="px-2 py-1 font-weight-bold mt-3 text-mirae text-center float-right">New</span>
    } else {
      return <></>
    }
  }

  const renderEmptyState = () => {
    return listNotification.length == 0 && !loadingList && props.location.state?.search && <div className="my-4" style={{ height: '400px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <Illustration1 className='my-5' />
        <h3>Empty State</h3>
      </div>
    </div>
  }



  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Notification</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
          </div>
        </div>
      </div>
      {/* after */}
      <div className="mt-4">
        {
          !loadingList
            ?
            listNotification.map((item, index) => {
              return (
                <div key={index} onClick={() => goToPage(item)} style={{ cursor: 'pointer' }}>
                  <div className="card mt-2" style={{ borderRadius: '10px' }}>
                    <div style={{ background: '#FFF4EB', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', fontSize: '12px' }} className="px-3 py-3">
                      <span className='mr-2'>{item.type ? item.type : "-"}</span> {renderUnread(item)}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 my-2">
                          <h4 className='font-weight-bold'>{item.description ? item.description : "-"}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            :
            <>
              <div style={{ height: '170px' }} className="my-3">
                <div className="shimmerBG"></div>
              </div>
              <div style={{ height: '170px' }} className="my-3">
                <div className="shimmerBG"></div>
              </div>
              <div style={{ height: '170px' }} className="my-3">
                <div className="shimmerBG"></div>
              </div>
            </>
        }
        {renderEmptyState()}
      </div>
      {!loadingList ?
        <div className="row">
          {listNotification.length > 0 ? <> <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
            <div style={{ display: 'flex' }}>
              <span className="mt-3 mx-2">
                Show
              </span>
              <span>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={size}
                    onChange={handleChange}
                    label="Limit">
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </span>
              <span className="mt-3 mx-2">
                Entries
              </span>
            </div>
          </div>
            <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
            </div> </> : <> </>}
        </div> : <>
          <div className="row">
            <div className="col-4">
              <div style={{ height: '30px' }} className="my-3">
                <div className="shimmerBG"></div>
              </div>
            </div>
            <div className="col-4">
            </div>
            <div className="col-4">
              <div style={{ height: '30px' }} className="my-3">
                <div className="shimmerBG"></div>
              </div>
            </div>
          </div>
        </>}
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
});

const mapDispatchToProps = {
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
