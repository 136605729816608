import * as React from "react"

const CloseModal = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.152 1.152a1.2 1.2 0 0 1 1.697 0L8 6.304l5.152-5.152a1.2 1.2 0 0 1 1.697 1.697L9.697 8.001l5.152 5.151a1.2 1.2 0 1 1-1.697 1.697L8 9.698l-5.151 5.151a1.2 1.2 0 0 1-1.697-1.697l5.151-5.151-5.151-5.152a1.2 1.2 0 0 1 0-1.697Z"
      fill="#0A0A0A"
    />
  </svg>
)

export default CloseModal
