import * as React from "react"

const LevelOrganizationAdd = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.80039 2.80039H6.40039C6.40039 4.78862 8.01217 6.40039 10.0004 6.40039C11.9886 6.40039 13.6004 4.78862 13.6004 2.80039H17.2004C18.5259 2.80039 19.6004 3.87491 19.6004 5.20039V16.0004C19.6004 17.3259 18.5259 18.4004 17.2004 18.4004H2.80039C1.47491 18.4004 0.400391 17.3259 0.400391 16.0004V5.20039C0.400391 3.87491 1.47491 2.80039 2.80039 2.80039ZM5.80039 11.2004C6.7945 11.2004 7.60039 10.3945 7.60039 9.40039C7.60039 8.40628 6.7945 7.60039 5.80039 7.60039C4.80628 7.60039 4.00039 8.40628 4.00039 9.40039C4.00039 10.3945 4.80628 11.2004 5.80039 11.2004ZM8.74097 16.0004C8.78032 15.8065 8.80098 15.6059 8.80098 15.4004C8.80098 13.7435 7.45783 12.4004 5.80098 12.4004C4.14412 12.4004 2.80098 13.7435 2.80098 15.4004C2.80098 15.6059 2.82163 15.8065 2.86099 16.0004H8.74097ZM12.4004 8.80039C11.7376 8.80039 11.2004 9.33765 11.2004 10.0004C11.2004 10.6631 11.7376 11.2004 12.4004 11.2004H16.0004C16.6631 11.2004 17.2004 10.6631 17.2004 10.0004C17.2004 9.33765 16.6631 8.80039 16.0004 8.80039H12.4004ZM11.2004 13.6004C11.2004 12.9376 11.7376 12.4004 12.4004 12.4004H14.8004C15.4631 12.4004 16.0004 12.9376 16.0004 13.6004C16.0004 14.2631 15.4631 14.8004 14.8004 14.8004H12.4004C11.7376 14.8004 11.2004 14.2631 11.2004 13.6004Z"
      fill="#fff"
    />
  </svg>
)

export default LevelOrganizationAdd
