import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './management.scss'
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Badge, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { Select as SelectMui, Checkbox } from '@mui/material';
import Select from 'react-select'

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export const AuthorityManagement = (props: IHeaderProps) => {

    let history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState<any>(10);
    const [totalPage, setTotalPage] = useState(1);
    const [modalAuthorityAdd, setAuthorityModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [dataAuthorityManagement, setDataAuthorityManagement] = useState([]);
    const [dataRole, setDataRole] = useState([]);
    const [selectAuthority, setSelectAuthority] = useState("");
    const [idRole, setIdRole] = useState("");
    const [dataUpdateAuthority, setDataUpdateAuthority] = useState([]);
    const [valSearch, setValSearch] = useState("");
    const [filterChecked, setFilterChecked] = useState("");

    useEffect(() => {
        getData()
        getDataRole()
    }, [page, size, totalPage, valSearch, filterChecked]);

    // data menu management
    const getData = () => {
        let pageReal = page
        var pageCount = pageReal - 1
        var paramFilter = filterChecked ? `&activated=${filterChecked}` : ``;
        var paramSearch = valSearch ? `&search=${valSearch}` : ``;
        axios.get(`services/uaadocservice/api/users/get-list-user-employee?page=${pageCount}&limit=${size}${paramFilter}${paramSearch}`).then(res => {
            setDataAuthorityManagement(res.data.data.data)
            setTotalPage(res.data.data.totalPage)
        }).catch(err => {
            toast.error(err.response.data.code + ' ' + err.response.data.message);
        })
    }

    const getDataRole = () => {
        axios.get(`services/uaadocservice/api/authority/list/all`).then(res => {
            const response = res.data.data
            const newAuthority = response.map((obj, i) => ({ value: obj.name, label: obj.name }));
            setDataRole(newAuthority)
        }).catch(err => {
            toast.error(err.response.data.code + ' ' + err.response.data.message);
        })
    }

    const getDetail = (value) => {
        setIdRole(value.login)
        handleModal()
    }

    const getUpdate = (value) => {
        setIdRole(value.login)
        setDataUpdateAuthority(value.authorities)
        handleModal()
    }

    const handleCheck = (i, e) => {
        let dataModify = [...dataUpdateAuthority];
        dataModify[i] = e.value;
        setDataUpdateAuthority(dataModify)
    }

    const removeFormFields = (i) => {
        let newFormValues = [...dataUpdateAuthority];
        newFormValues.splice(i, 1);
        setDataUpdateAuthority(newFormValues)
    }

    const addFormFields = () => {
        setDataUpdateAuthority([...dataUpdateAuthority, ""])
    }

    const handleFilterCheck = (val) => {
        setFilterChecked(val)
        setValSearch("")
    }

    const handleSearch = (searchVal) => {
        setPage(1)
        setValSearch(searchVal)
        setFilterChecked("")
    }

    // action 
    const saveSetRole = () => {
        var dataArray = [selectAuthority]
        setLoading(true)
        axios.put(`services/uaadocservice/api/users/update-role?username=${idRole}`, dataArray).then(res => {
            toast.success('Success Set Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
        }).catch(err => {
            toast.error('Failed Set Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
        })
    }

    const updateSetRole = () => {
        setLoading(true)
        axios.put(`services/uaadocservice/api/users/update-role?username=${idRole}`, dataUpdateAuthority).then(res => {
            toast.success('Success Update Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
        }).catch(err => {
            toast.error('Failed Update Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            handleClose()
            getData()
        })
    }

    const updateStatus = (changeStatus, curStatus) => {
        var loginId = changeStatus.login
        var validationStatus = curStatus == "enable" ? "activate" : "inactivate";
        setLoading(true)
        axios.put(`services/uaadocservice/api/users/${validationStatus}/${loginId}`).then(res => {
            toast.success('Success Update Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            getData()
        }).catch(err => {
            toast.error('Failed Update Data Role', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
            getData()
        })
    }

    // modal 
    const handleModal = () => {
        setAuthorityModal(!modalAuthorityAdd);
    }

    const handleClose = () => {
        setIdRole("")
        setSelectAuthority("")
        setDataUpdateAuthority([])
        setAuthorityModal(false)
    }

    // pagination table
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-28">Authority Management</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="mb-0 font-size-14">Mirae Asset Sekuritas Authority Management</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-4 mb-2">
                    <h1 className="mb-0 font-size-24">Filter</h1>
                </div>
                <div className="col-sm">
                    <div className="row my-3">
                        <div className="col-sm-2">
                            <input type="checkbox" name="all" checked={filterChecked == "" ? true : false} onChange={(e) => handleFilterCheck("")} />
                            <span style={{ marginLeft: '5px', lineHeight: '10px' }}>All</span>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" name="active" checked={filterChecked == "true" ? true : false} onChange={(e) => handleFilterCheck("true")} />
                            <span style={{ marginLeft: '5px', lineHeight: '10px' }}>Enable</span>
                        </div>
                        <div className="col-sm-3">
                            <input type="checkbox" name="inactive" checked={filterChecked == "false" ? true : false} onChange={(e) => handleFilterCheck("false")} />
                            <span style={{ marginLeft: '5px', lineHeight: '10px' }}>Disable</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 px-0">
                    <div className="d-flex px-3" style={{ width: '100%' }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control bg-white py-3"
                                    placeholder="Search Authority Management"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                    }} onKeyPress={(e) => {
                                        console.log('e.key', e.key)
                                    }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => { }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Employee No</span>
                                            <span className='mt-1'>
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => { }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Username</span>
                                            <span className='mt-1'>
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => { }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Role</span>
                                            <span className='mt-1'>
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => { }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Status</span>
                                            <span className='mt-1'>
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => { }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Action</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataAuthorityManagement.map((itemAuthorityManagement, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{itemAuthorityManagement?.employeeNo}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{itemAuthorityManagement?.firstName}</td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>
                                                    {itemAuthorityManagement.authorities.map((authorities, i) => (
                                                        <span key={i} style={{ fontSize: '12px', background: '#FFF4EB', color: '#F37F26', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                            {authorities}
                                                        </span>
                                                    ))}
                                                </td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>
                                                    {
                                                        itemAuthorityManagement?.activated == true ?
                                                            <span style={{ fontSize: '12px', background: '#002F48', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Enable
                                                            </span>
                                                            :
                                                            <span style={{ fontSize: '12px', background: '#CB3A31', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                                                Disable
                                                            </span>
                                                    }
                                                </td>
                                                <td style={{ wordBreak: 'break-all', minWidth: '300px' }}>
                                                    <Button style={{ backgroundColor: '#F5FCFF', border: '1px solid #ABC1CC' }} onClick={() => getUpdate(itemAuthorityManagement)} className="mr-2">
                                                        <span style={{ color: '#002F48' }}>Update</span>
                                                    </Button>
                                                    {itemAuthorityManagement?.activated ?
                                                        <Button
                                                            className="mr-2"
                                                            style={{ backgroundColor: '#FFF4F2', border: '1px solid #CB3A31' }}
                                                            disabled={loading}
                                                            onClick={() => updateStatus(itemAuthorityManagement, "disable")}>
                                                            <span style={{ color: '#CB3A31' }}>
                                                                Disable
                                                            </span>
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="mr-2"
                                                            style={{ backgroundColor: '#EAF9F3', border: '1px solid #34c38f' }}
                                                            disabled={loading}
                                                            onClick={() => updateStatus(itemAuthorityManagement, "enable")}>
                                                            <span style={{ color: '#34c38f' }}>
                                                                Enable
                                                            </span>
                                                        </Button>
                                                    }
                                                    <Button style={{ backgroundColor: '#F37F26', border: '1px solid #F37F26' }} onClick={() => getDetail(itemAuthorityManagement)} className="mr-2">
                                                        <span style={{ color: '#F5F5F5' }}>Set Role</span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>

                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit"
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <Modal className="font-family-spoqa" isOpen={modalAuthorityAdd} toggle={handleClose} size="md" centered={true}>
                <ModalHeader toggle={handleClose}>Form Role Management</ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <FormGroup>
                            {dataUpdateAuthority.length == 0 ?
                                <Select
                                    name="group"
                                    value={dataRole.filter((function (option: any) {
                                        return option.value == selectAuthority
                                    }))}
                                    placeholder="Select Role Here"
                                    options={dataRole}
                                    onChange={(e) => setSelectAuthority(e.value)}
                                />
                                :
                                dataUpdateAuthority.map((element, index) => {
                                    return (
                                        <FormGroup key={index}>
                                            <div className="row mr-1">
                                                <div className="col-12 col-sm-12 col-md-10">
                                                    <Select
                                                        name="group"
                                                        value={dataRole.filter((function (option: any) {
                                                            return option.value == element
                                                        }))}
                                                        placeholder="Select Role Here"
                                                        options={dataRole}
                                                        onChange={(e) => handleCheck(index, e)}
                                                    />
                                                </div>

                                                {index ?
                                                    <div className="col-12 col-sm-12 col-md-2">
                                                        <Button color='#FFF4F2' style={{ border: '1px solid #EEB4B0' }} onClick={() => removeFormFields(index)} className="mr-2">
                                                            <span style={{ color: '#CB3A31' }}>Delete</span>
                                                        </Button>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </FormGroup>
                                    )
                                })
                            }
                        </FormGroup>
                    </div>
                </ModalBody >
                <ModalFooter>
                    {dataUpdateAuthority.length > 0 ?
                        <Button style={{ background: '#002F48' }} disabled={loading} onClick={() => addFormFields()}>
                            Add Role
                        </Button>
                        : <></>}
                    {''}
                    <Button
                        style={{ background: '#F37F26', borderColor: '#F37F26' }}
                        disabled={loading}
                        onClick={() => dataUpdateAuthority.length == 0 ? saveSetRole() : updateSetRole()}>
                        <span className='mr-1'>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            {dataUpdateAuthority.length == 0 ? 'Save' : 'Update'}
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorityManagement);
