import React, { useEffect, useState } from 'react';
import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { translate } from 'react-jhipster';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { toast } from 'react-toastify';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

const categoryActive = { padding: "5px", marginRight: "10px", color: "#F37F26", borderBottom: "2px solid #F37F26" };
const categoryInactive = { padding: "5px", marginRight: "10px", color: "#9E9E9E" };

export interface Props {
  match?: any
}

export const HistoriDetail = (props: Props) => {

  let history = useHistory();
  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [listDocumentHistoriDetail, setListDocumentHistoriDetail] = useState<any>([])
  const [dataTracker, setDataTracker] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  useEffect(() => {
    getListDocumentHistoriDetail(props.match.params.id)
    getDocumentTracker(props.match.params.id)
  }, []);

  const getListDocumentHistoriDetail = (id) => {
    axios.get(`/services/documentservice/api/file-histories/${id}`)
      .then(res => {
        setListDocumentHistoriDetail(res.data.data.content)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const getDocumentTracker = (id) => {
    axios.get(`/services/documentservice/api/file-histories/get-document-tracker/${id}`)
      .then(res => {
        setDataTracker(res.data.data)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const renderStatusFileHistories = (status, statusName) => {
    console.log("renderStatusFileHistories", status, statusName)
    if (status == "CREATED" || status == "REVISE") {
      return <div className="font-weight-bold text-center" style={{ color: '#F37F26', background: '#FFF4EB', border: '1px solid #F37F26', borderRadius: '6px', fontSize: '12px', padding: '10px' }}>{statusName}</div>
    } else if (status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION') {
      return <div className="font-weight-bold text-center" style={{ color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA', borderRadius: '6px', fontSize: '12px', padding: '10px' }} >{statusName}</div>
    } else if (status == 'DOWNLOADED') {
      return <div className="font-weight-bold text-center" style={{ color: '#043B72', background: '#F7F7F7', border: '1px solid #043B72', borderRadius: '6px', fontSize: '12px', padding: '10px' }} >{statusName}</div>
    } else {
      return <div className="font-weight-bold text-center" style={{ color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px', fontSize: '12px', padding: '10px' }}>{statusName}</div>
    }
  }

  const renderBatasWaktu = (data) => {
    if (!data) return '-'
    return moment(data).format('DD-MMM-YY | HH:mm') + " WIB"
  }

  const onChangeCategory = (categoryParams) => {
    setCategory(categoryParams)
  }

  const handleTooltipOpen = (email) => {
    navigator.clipboard.writeText(email)
    toast.success(`Copied !!`);
  }

  const viewsGetDocumentFromGCP = (item) => {
    console.log("item", item)
    // axios.get(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${item?.fileId}&fileHistoryId=${item?.fileHistoryId}`, { responseType: 'blob' })
    //   .then(res => {
        history.push({ 
          pathname: '/history/viewfile',
          state: item
         })
    //     // console.log("viewsGetDocumentFromGCP", res)
    //     // setDataTracker(res.data.data)
    //   }).catch(err => {
    //     toast.error(translate(`${err.response.data.message}`));
    //   })
  }

  console.log("listDocumentHistoriDetail", listDocumentHistoriDetail)

  return (
    <div className="px-5 pt-4 font-family-spoqa scrollbar" style={{ height: '100%', width: '100%' }}>
      <button className="btn px-0 mb-4" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mb-4">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between p-1">
            <h1 className="mb-0 font-size-28">Detail History Document</h1>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-1">
            <div className="ml-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
              <h1 className="mb-0 font-size-20" style={{ color: "#9E9E9E" }}>{listDocumentHistoriDetail[0]?.fileNumber}</h1>
              <div className='mx-2'>
                <button
                  className="btn p-2"
                  style={{ backgroundColor: "#F37F26", textAlign: "center" }}
                  onClick={() => history.push(`/preview/only/pdf/${props.match.params.id}`)}>
                  <i className="bx bxs-file" style={{ color: "#ffffff" }}> &nbsp;</i>
                  <span style={{ color: "#ffffff" }}>View Doc &nbsp;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center justify-content-between mt-1">
          <div className="ml-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <div className="mb-0 font-size-14" style={category == 0 ? categoryActive : categoryInactive} onClick={() => onChangeCategory(0)}>
              Tracing Document
            </div>
            <div className="mb-0" style={category == 1 ? categoryActive : categoryInactive} onClick={() => onChangeCategory(1)}>
              Detail
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #E0E0E0", marginLeft: "15px", width: "100%" }} />
      </div>
      {
        category == 0 ?
          <div className="container-fluid mt-5" style={{ width: '100%' }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", left: -100 }}>
              <Timeline>
                {
                  dataTracker.map((item, index) => {
                    var lastIndex = dataTracker.length - 1;
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent style={{ flex: 1 / 2, padding: "5px", marginRight: "10px", marginLeft: "-50px" }}>
                          <span style={{ fontSize: "14px", color: "#404040" }}>{item.status}</span>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot style={item.flagDone == true ? { backgroundColor: "#43936C" } : { backgroundColor: "#9E9E9E" }} />
                          {
                            index == lastIndex ?
                              <></>
                              :
                              (
                                item.flagDone == true
                                  ?
                                  <div style={{ borderLeft: "2px solid #43936C", height: "80px" }} />
                                  :
                                  <TimelineConnector />
                              )
                          }
                        </TimelineSeparator>
                        <TimelineContent style={{ width: "400px" }}>
                          <div style={{ fontSize: "14px", color: "#000" }}>{item.name}</div>
                          <div style={{ fontSize: "12px" }} onClick={() => handleTooltipOpen(item.email)}>
                            <span style={{ color: "#0000EE" }}>{item.email}</span> &nbsp;
                            {item.email == "" ? <></> : <i className="bx bxs-copy" />}
                          </div>
                          <div style={{ fontSize: "12px", color: "#404040" }}>{item.position}</div>
                          <div style={{ fontSize: "12px", color: "#404040" }}>{item.timestampFormat}</div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })
                }
              </Timeline>
            </div>
          </div>
          : <></>
      }
      {
        category == 1 ?
          <div className="container-fluid mt-5">
            <div className="row">
              {listDocumentHistoriDetail ? listDocumentHistoriDetail?.map((item, index) => {
                return (
                  <div className="col-lg-12" key={index}>
                    <div className="card" style={{ borderRadius: '10px' }}>
                      <div style={{ background: '#FFF4EB', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }} className="px-3 py-3">
                        <span className='mr-2'>{item?.fileNumber}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.senderBranchName}</span>{'  '} • {'  '}<span className='mr-2 ml-2'>{item?.dateFormat}</span>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-md-2 col-lg-2 my-2">
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}>Regarding</h5>
                            <h4 className='font-weight-light mt-3' style={{ fontSize: '14px', textAlign: 'justify' }}>{item.regarding ? item.regarding : "-"}</h4>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2 my-2">
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}>Status</h5>
                            {renderStatusFileHistories(item?.status, item?.statusName)}
                          </div>
                          <div className="col-12 col-md-2 col-lg-2 my-2">
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}>Last Approval</h5>
                            <h5 className='font-italic font-weight-light mt-3 text-center' style={{ fontSize: '14px' }}>{item?.name}</h5>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2 my-2">
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}>Note</h5>
                            <h5 className='font-italic font-weight-light mt-3 text-center' style={{ fontSize: '12px' }}>{item?.notes}</h5>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2 my-2">
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}>Date</h5>
                            <h5 className='font-weight-light mt-3 text-center' style={{ fontSize: '14px' }}>{item?.timestampFormat}</h5>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2 my-2" style={{ display: "flex", justifyContent: "center" }}>
                            <h5 className='font-weight-bold' style={{ textAlign: 'center' }}></h5>
                            <button 
                              className='btn btn-secondary px-4 mt-3' 
                              style={{ height: "35px", width: "100px", backgroundColor: "#F37F26", border: "#F37F26" }}
                              onClick={() => viewsGetDocumentFromGCP(item)}>
                            Views
                          </button>
                          </div>
                            

                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : <></>}
            </div>
          </div>
          : <></>
      }
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(HistoriDetail);