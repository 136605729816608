import * as React from "react"
import { SVGProps } from "react"

const ToConfirmIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M16.643 16.5717C18.7733 16.5717 20.5002 14.8448 20.5002 12.7146C20.5002 10.5843 18.7733 8.85742 16.643 8.85742C14.5128 8.85742 12.7859 10.5843 12.7859 12.7146C12.7859 14.8448 14.5128 16.5717 16.643 16.5717Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.0716 29.4291H8.92871V26.8577C8.94236 25.5511 9.28696 24.2692 9.93032 23.1319C10.5737 21.9945 11.4948 21.0388 12.6077 20.3539C13.7206 19.6691 14.9889 19.2775 16.2941 19.2157C17.5994 19.1539 18.899 19.4239 20.0716 20.0006" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M31.2145 22.5713L23.1402 30.6456L19.4888 31.1427L20.0031 27.4913L28.0602 19.417L31.2145 22.5713Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToConfirmIcon
