import * as React from "react"
import { SVGProps } from "react"

const SuccessReplaceIllustration = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={133}
        height={133}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M7.03011 86.5893L64.2669 54.039C66.613 52.7066 69.5067 52.7638 71.8037 54.1861L124.487 86.9408C129.229 89.8917 129.098 96.8399 124.242 99.6029L69.335 130.902C64.9372 133.412 59.5095 133.265 55.2506 130.518L6.6786 99.186C2.02739 96.186 2.21541 89.3277 7.03011 86.5893Z" fill="#FBDEC9"/>
        <path d="M31.071 45.1057L33.3354 51.0403C35.4198 48.163 37.5697 44.8278 39.0329 40.5281C38.5016 37.1766 37.2754 34.4791 35.992 31.4219L33.4334 31.9859C33.8013 35.1167 34.1446 37.5199 34.864 39.7107L31.071 45.1057Z" fill="#EDEDED"/>
        <path d="M32.2479 30.0725C32.2724 30.4404 32.4277 30.7919 32.4931 31.1597C32.5258 31.3559 32.5421 31.5684 32.6648 31.7319C32.7138 31.7973 32.7874 31.8545 32.8446 31.9036C33.0326 32.0507 33.2288 32.1978 33.4332 32.3286C33.6784 32.4921 33.9318 32.6229 34.2097 32.7047C34.4631 32.7782 34.741 32.8109 35.0026 32.7701C35.2642 32.7374 35.5094 32.6393 35.7056 32.4594C35.9917 32.206 36.1307 31.83 36.2042 31.454C36.2696 31.078 36.2778 30.6938 36.3269 30.3177C36.3432 30.1788 36.3759 30.0398 36.335 29.909C36.2942 29.7537 36.1797 29.6311 36.0653 29.5167C35.8855 29.3205 35.6975 29.1325 35.5094 28.9363C35.4277 28.8464 35.3378 28.7646 35.2233 28.7237C35.1416 28.6992 35.0517 28.6992 34.9618 28.6992C34.3814 28.7156 33.801 28.7319 33.2206 28.7401C33.1716 28.7401 33.1225 28.7483 33.0898 28.7646C32.9999 28.8218 32.9917 28.9526 32.9182 29.0344C32.8609 29.0916 32.7792 29.1161 32.7056 29.1406C32.3623 29.2796 32.2233 29.7047 32.2479 30.0725Z" fill="#FFBB7D"/>
        <path opacity="0.15" d="M33.0164 30.2858C33.0491 30.6291 33.0737 30.9725 33.1064 31.3158C33.1145 31.3894 33.1227 31.4629 33.139 31.5283C33.1717 31.6264 33.229 31.7082 33.2698 31.7981C33.3516 31.9616 33.3761 32.1496 33.4251 32.3212C33.6704 32.4847 33.9238 32.6155 34.2017 32.6973C34.4551 32.7708 34.7331 32.8035 34.9946 32.7627C34.8802 32.4929 34.7985 32.1986 34.8148 31.9125C34.8557 31.2667 35.3298 30.7436 35.7875 30.2776C35.4197 30.2858 35.0437 30.294 34.6758 30.3022C34.635 30.5065 34.5859 30.7027 34.545 30.8989C34.5123 31.0787 34.4633 31.2667 34.357 31.4139C34.2508 31.561 34.0464 31.6591 33.8748 31.6019C33.7767 31.5692 33.7113 31.4956 33.654 31.4057C33.6459 31.3894 33.6377 31.373 33.6295 31.3567C33.5559 31.2422 33.4987 31.1033 33.4006 31.0133C33.4006 31.0052 33.3924 30.997 33.3924 31.0052C33.3843 30.997 33.3843 30.9888 33.3761 30.9806C33.3352 30.9398 33.278 30.9152 33.2453 30.8662C33.2044 30.8172 33.1963 30.7518 33.1881 30.6945C33.1636 30.5392 33.1472 30.3757 33.1472 30.2204C33.1472 30.1469 33.1472 30.0733 33.1799 30.0079C33.2944 29.7708 33.7358 29.7381 33.7276 29.4766C33.605 29.7381 33.1799 29.7136 33.0491 29.967C33.0001 30.0651 33.0083 30.1796 33.0164 30.2858Z" fill="#171715"/>
        <path opacity="0.15" d="M35.3868 29.6474C35.068 29.6638 34.7492 29.6965 34.4385 29.7537C34.4058 29.7619 34.365 29.77 34.3323 29.7864C34.2914 29.8109 34.2751 29.8518 34.2505 29.8927C34.2015 29.9908 34.1524 30.0807 34.1116 30.1869C34.0298 30.3995 33.9889 30.6284 33.9317 30.8491C33.8745 31.0698 33.7846 31.2905 33.6211 31.454C33.6293 31.266 33.7437 31.0943 33.8091 30.9226C33.9317 30.6038 33.9072 30.236 34.0543 29.9335C34.0952 29.8436 34.1524 29.7619 34.2424 29.7128C34.3323 29.6556 34.4467 29.6474 34.5612 29.6311C34.8391 29.6066 35.117 29.582 35.3868 29.6474Z" fill="#171715"/>
        <path opacity="0.15" d="M33.8174 29.6646C33.6376 30.1305 33.613 30.6455 33.376 31.0869C33.4822 30.5883 33.613 30.0896 33.752 29.5992C33.7683 29.5501 33.7765 29.5011 33.8092 29.4684C33.8501 29.4193 33.9155 29.403 33.9809 29.3948C34.267 29.3376 34.5694 29.3131 34.8555 29.3376C34.5694 29.3948 34.2752 29.4602 33.9891 29.5174C33.9482 29.5256 33.9073 29.5338 33.8746 29.5583C33.8501 29.5828 33.8337 29.6237 33.8174 29.6646Z" fill="#171715"/>
        <path opacity="0.15" d="M33.7356 29.1475C33.7029 29.1475 33.662 29.1557 33.6293 29.1557C33.6129 29.1557 33.6048 29.1557 33.5884 29.1557C33.5394 29.1475 33.5149 29.0822 33.4658 29.0822C33.8173 28.9514 34.2015 29.0168 34.5693 29.0495C34.2996 29.1067 34.0135 29.1312 33.7356 29.1475Z" fill="#171715"/>
        <path d="M39.3268 87.9075C38.632 87.9156 36.5639 87.0573 35.3786 86.3135C35.3214 86.2808 35.2723 86.2481 35.2233 86.2072C35.1252 86.1418 35.0271 86.0682 34.9453 86.0028C34.8636 85.9374 34.7818 85.8802 34.7246 85.8148C34.6347 85.7249 34.5693 85.6432 34.5366 85.5696C34.5448 85.2017 34.5693 84.8502 34.6183 84.5314C34.6756 84.1227 34.7737 83.7631 34.929 83.4933L35.0761 82.1445L35.1252 81.7031H38.2069L38.1415 82.5941L38.0598 83.6568C38.0598 83.6568 38.0925 83.7058 38.166 83.8121C38.4031 84.1309 39.0734 84.9402 40.5202 85.9456C41.0597 86.3462 41.1088 87.9075 39.3268 87.9075Z" fill="black"/>
        <path d="M35.1747 63.3164L40.9539 72.5861C41.2318 72.9948 41.3054 73.518 41.1419 73.9921C39.9076 77.6624 39.8095 79.142 38.191 83.4989C37.7578 84.6678 34.3082 85.2646 34.3981 84.3082C34.1365 80.1883 35.052 77.3027 36.1719 74.6624C34.5289 72.4799 28.9131 68.875 28.8967 66.1366L35.1747 63.3164Z" fill="#45362E"/>
        <path d="M29.6568 98.2548C28.9619 98.2629 26.8938 97.4046 25.7085 96.6689C25.6513 96.6362 25.6023 96.6035 25.545 96.5627C25.4469 96.4973 25.3489 96.4237 25.2671 96.3583C25.2181 96.3174 25.169 96.2847 25.12 96.2439C25.0954 96.2193 25.0628 96.1948 25.0464 96.1703C24.9565 96.0804 24.8911 95.9986 24.8584 95.9251C24.8666 95.5572 24.8911 95.2057 24.9401 94.8869C24.9401 94.8706 24.9401 94.8624 24.9483 94.8461C25.0055 94.4537 25.1036 94.1104 25.2508 93.8488L25.3979 92.5L25.447 92.0586H28.5287L28.4633 92.9414L28.3815 94.0041C28.3815 94.0041 28.4142 94.0613 28.4878 94.1594C28.5042 94.1839 28.5287 94.2084 28.545 94.2411C28.823 94.6008 29.5014 95.361 30.8339 96.3011C31.3815 96.7016 31.4306 98.2548 29.6568 98.2548Z" fill="black"/>
        <path d="M32.1336 69.9885L31.169 79.8549C30.1064 85.25 29.3298 87.0647 28.6513 93.874C28.6432 93.9148 28.6432 93.9557 28.635 93.9966C28.6268 94.0865 28.5859 94.1764 28.5287 94.2582C28.1445 94.7895 26.8285 95.2227 25.8394 95.1573C25.4715 95.1328 25.1445 95.0429 24.9402 94.8549C24.8748 94.7977 24.8257 94.7323 24.7767 94.6587C24.5151 94.1846 24.7767 84.2364 26.3135 79.9203C25.8067 76.9449 25.8802 73.855 25.6595 71.0266C25.1364 69.4326 25.1691 67.479 25.7086 65.6397C25.7086 65.6397 34.3979 63.0158 34.9619 62.8768L35.1091 62.8359C35.3053 66.7678 35.1827 68.2964 32.1336 69.9885Z" fill="#634E42"/>
        <path d="M25.6678 65.0267C25.6678 65.043 25.6105 65.7542 25.6678 66.0894C28.8312 67.855 34.7658 64.8714 35.1091 62.8523C35.0519 62.4436 35.0764 62.0431 34.962 61.5117C33.4089 64.1602 27.9975 66.0403 25.6678 65.0267Z" fill="black"/>
        <path d="M33.4333 64.5848C33.1636 64.8709 32.7548 65.0753 32.2807 65.3123L32.199 64.5439C32.6404 64.315 33.0328 64.1107 33.3516 63.8164L33.4333 64.5848Z" fill="#575756"/>
        <path d="M35.0111 61.8708C33.6868 64.176 28.4879 66.7673 25.6678 65.1242C25.0792 64.7809 25.1937 64.2659 24.9076 63.0888C24.6378 61.8381 24.3926 60.6774 24.1882 59.6229C23.3708 55.503 23.0111 52.8545 22.9212 51.473V51.4649C22.8967 51.2033 22.0874 48.7019 22.0874 48.5303C23.4689 48.0807 25.6106 47.5984 26.9675 46.7973C28.9539 45.6366 29.1255 45.6774 30.1228 45.187C34.1282 43.2415 35.4443 61.1188 35.0111 61.8708Z" fill="#EDEDED"/>
        <path d="M30.3025 49.8039C31.0136 51.7985 32.109 54.2589 33.8011 55.2072L34.1281 56.8666L32.1008 56.2944C30.5968 55.1827 30.1717 52.3625 29.7303 50.0001L28.8965 48.774L29.3788 48.1445L30.188 48.3162L30.3025 49.8039Z" fill="#E95454"/>
        <path d="M25.6106 47.3612C25.5207 49.0451 27.9076 50.0097 29.0112 49.8952C29.2564 49.4293 29.191 48.7753 29.4035 48.244C29.7959 48.4565 30.1883 48.5464 30.6787 48.8162C30.7523 45.5792 27.0984 45.3748 26.1501 46.6255C26.003 46.8298 25.7986 47.0669 25.6106 47.3612Z" fill="#DADADA"/>
        <path d="M29.9181 41.8102C30.7764 41.1562 31.0298 40.3551 30.662 39.3987C30.0081 38.238 28.5857 37.7802 26.5994 38.3606C24.3759 39.0145 23.7874 41.0418 24.8582 43.2162C25.2342 43.9764 25.4549 44.7856 25.5857 45.1126C25.7574 45.5458 25.9536 45.5949 26.395 45.5704L29.9181 41.8102Z" fill="#634E42"/>
        <path d="M30.1143 47.3036C30.1143 47.2872 30.1143 47.2627 30.1143 47.2464L29.9263 46.0693L29.8691 45.7178L25.8555 44.3281L26.1579 46.6333C26.2887 48.9793 29.9999 48.5379 30.1143 47.3036Z" fill="#FFBB7D"/>
        <path opacity="0.1" d="M30.1142 47.3013C30.1142 47.285 30.1142 47.2605 30.1142 47.2441L29.9262 46.067L27.0652 45.3477C27.2287 46.8109 28.7573 48.0207 30.1142 47.3013Z" fill="#1D1D1B"/>
        <path d="M30.3431 39.8785C29.7627 38.5542 28.226 38.3989 26.7382 38.7995C24.6946 39.3553 24.523 40.982 24.8663 42.6005C25.087 43.3444 25.5039 44.1537 25.9126 44.8403L27.0652 45.339C27.0652 45.339 27.5475 46.0992 28.1769 46.4098C28.79 46.7122 29.5175 46.8839 30.1224 46.606C30.7845 46.3362 31.1524 45.7967 31.0543 44.6441C30.9644 43.5488 31.087 43.4425 31.0216 42.952C30.9807 42.6741 30.8581 42.3798 30.8417 42.2245C30.8254 42.0365 30.8826 41.7668 30.8581 41.5624C30.8172 41.1455 30.5148 40.2627 30.3431 39.8785Z" fill="#FFBB7D"/>
        <path d="M31.4796 41.7605C31.1118 41.2864 28.8802 38.7033 26.5505 41.2291C26.5668 41.2618 27.09 43.6079 27.09 43.6161C26.9756 43.706 25.9865 43.1992 25.9047 43.1501C25.9047 43.1501 26.0846 43.7632 26.3707 44.9158C26.5832 45.7904 26.2072 45.6269 26.0192 45.5615C25.7658 45.4716 24.8094 42.6678 24.793 42.6188C24.5396 42.0221 23.6976 39.3245 26.6077 38.3599C30.2208 37.1665 32.2725 38.1801 31.4796 41.7605Z" fill="#1D1D1B"/>
        <path d="M25.8553 42.4458C25.324 42.3804 25.4957 43.067 25.6019 43.3531C25.6755 43.5411 25.7818 43.7046 25.8553 43.9008C25.9207 44.0806 25.9371 44.3341 26.1087 44.4485C26.5175 44.7264 26.5256 44.0398 26.5256 43.7782C26.5175 43.4022 26.3131 42.503 25.8553 42.4458Z" fill="#FFBB7D"/>
        <path d="M16.161 41.0828C15.3517 44.0256 15.2945 47.3117 15.6623 50.8103L23.3544 54.6114C24.3108 53.7367 24.368 50.467 22.3163 48.6196L19.6596 47.6795L18.646 40.9766L16.161 41.0828Z" fill="#EDEDED"/>
        <path d="M15.2618 40.128C15.2945 40.2342 15.3599 40.3323 15.4334 40.4222C15.6541 40.7002 15.883 40.9863 16.1037 41.2724C16.2182 41.4195 16.3408 41.5748 16.5043 41.6484C16.6105 41.6974 16.7413 41.7219 16.8558 41.7465C17.0683 41.7792 17.2808 41.8119 17.4934 41.82C17.5751 41.82 17.6569 41.82 17.7386 41.8119C18.0329 41.7873 18.6296 41.4522 18.6705 41.395C18.6541 41.1497 18.6705 40.9045 18.7195 40.6593C18.7522 40.4876 18.8013 40.316 18.8667 40.1525C18.883 40.1116 18.8994 40.0626 18.9157 40.0135C18.9648 39.8991 19.0138 39.7765 19.0465 39.6538C19.1037 39.4495 19.1119 39.2369 19.1119 39.0244C19.1119 38.8609 19.1201 38.6893 19.0465 38.5339C18.9893 38.4113 18.8748 38.3132 18.7686 38.2233C18.7195 38.1825 18.6705 38.1416 18.6214 38.1007C18.5315 38.019 18.4334 37.9454 18.3353 37.8637C18.319 37.8555 18.3108 37.8391 18.2945 37.831C18.1473 37.7165 17.992 37.5939 17.8449 37.4795C17.8122 37.4549 17.7713 37.4222 17.7304 37.3977C17.6242 37.3241 17.5097 37.2587 17.3871 37.2261C17.1419 37.1688 16.8966 37.2506 16.6596 37.3405C16.4225 37.4386 16.1855 37.5367 15.9484 37.6511C15.8258 37.7165 15.695 37.7819 15.6133 37.8963C15.5234 38.019 15.507 38.1661 15.4825 38.3132C15.4334 38.6566 15.3844 38.9917 15.3353 39.335C15.319 39.4822 15.2945 39.6211 15.27 39.7683C15.2536 39.8909 15.2372 40.0135 15.2618 40.128Z" fill="#FFBB7D"/>
        <path opacity="0.15" d="M17.6567 39.6146C17.9592 39.7536 18.2208 39.9906 18.3924 40.2767C18.466 40.3993 18.515 40.5301 18.6295 40.6119C18.6622 40.6364 18.6867 40.6527 18.7194 40.6609C18.7521 40.4892 18.8011 40.3176 18.8665 40.1541C18.8829 40.1132 18.8992 40.0642 18.9156 40.0151C18.8257 40.0724 18.7112 40.0887 18.6213 40.0233C18.4823 39.9252 18.4987 39.7127 18.4987 39.5492C18.4987 39.3121 18.4251 39.0833 18.3025 38.8789C18.3516 39.0587 18.2862 39.2631 18.1635 39.3939C18.0328 39.541 17.8447 39.6146 17.6567 39.6146Z" fill="#171715"/>
        <path d="M101.141 65.418C101.141 65.418 99.7925 67.7885 99.2775 70.0038C99.0241 71.0746 98.6726 72.9139 98.6726 74.5487C98.6726 76.4125 99.0813 78.358 99.5963 79.3634C100.103 80.3689 105.997 85.8702 106.667 87.3007C106.692 87.7013 105.931 89.7612 105.621 90.6195C105.277 91.5596 105.016 92.8266 105.817 92.614C107.141 92.2544 107.877 91.0446 107.934 90.1209C108 88.9356 109.144 88.2816 108.94 87.6522C108.686 86.8593 108.572 86.9492 108.392 86.6386C107.485 85.0936 107.517 83.8838 106.414 82.298C105.155 80.4833 102.449 78.2272 102.449 78.2272C102.449 78.2272 103.692 74.8512 104.248 72.9139C104.803 70.9765 104.509 69.7586 104.509 69.7586L101.141 65.418Z" fill="#FCAF9D"/>
        <path d="M107.174 65.9086C107.133 65.8678 103.626 66.3419 103.144 68.3364C102.384 71.459 100.929 77.2628 100.7 80.4835C100.627 81.6034 100.7 83.0911 100.97 84.7505C101.199 86.1728 101.215 89.5325 100.97 90.4153C100.659 91.5271 100.242 94.0039 99.4249 94.9439C98.9262 95.5161 98.869 95.8676 99.4903 95.9902C100.193 96.121 100.774 96.0802 101.329 95.6061C101.926 95.0911 101.934 94.1674 102.122 93.4725C102.343 92.6633 103.21 92.6469 103.283 91.8132C103.34 91.1429 103.013 90.8731 102.752 90.2518C103.373 88.5189 103.921 86.6797 104.15 84.8895C104.329 83.4753 104.289 81.7914 103.52 80.5244C104.354 79.5598 105.294 78.677 106.079 77.6633C106.962 76.5189 107.681 75.2274 108.122 73.8377C108.932 71.2792 109.087 67.9522 107.174 65.9086Z" fill="#FFC9B6"/>
        <path d="M103.741 53.2695C103.741 53.2695 102.237 53.6047 101.82 54.4221C101.599 54.8472 101.232 55.354 100.39 56.6701C99.425 58.1741 99.5312 59.0079 100.095 59.6946C100.267 59.9071 100.806 60.4303 100.806 60.4303C100.806 60.4303 100.823 61.9589 100.929 62.8989C101.125 64.5583 101.06 66.8308 101.06 66.8308C101.06 66.8308 101.452 69.1687 102.605 69.3321C104.166 69.561 106.79 66.373 106.79 66.373C106.79 66.373 106.226 64.2722 107.346 62.1796C108.466 60.0788 109.921 58.5256 108.654 56.9725C108.425 56.6864 108.098 56.0815 107.746 55.7791C106.169 54.4058 103.741 53.2695 103.741 53.2695Z" fill="#FFC9B6"/>
        <path d="M107.755 55.7785C107.755 55.7785 106.586 56.6941 106.349 58.5251C106.103 60.3562 107.575 61.7867 107.575 61.7867C107.575 61.7867 107.411 62.8984 107.477 64.0101C107.542 65.1218 107.722 66.2335 108.425 67.7458C109.128 69.2581 109.43 71.8003 109.161 73.7866C108.891 75.773 109.921 78.0782 109.921 78.0782C109.921 78.0782 109.618 78.6831 108.695 79.0182C107.771 79.3534 107.027 79.0346 106.725 79.5087C106.422 79.9828 105.834 80.4324 104.869 80.5387C103.904 80.6449 102.997 79.664 102.441 79.7458C101.885 79.8275 100.823 79.9174 100.013 79.5087C98.918 78.9528 99.1551 78.0945 98.1824 78.0373C96.8581 77.9637 96.2614 77.4406 95.8608 76.9256C95.8608 76.9256 97.2832 73.2717 97.978 71.1463C98.2968 70.1736 99.1387 68.5878 99.6537 67.4597C100.169 66.3316 100.618 65.4161 100.586 64.5414C100.52 62.7921 100.349 60.7649 100.349 60.7649C100.349 60.7649 99.8172 59.7431 99.6537 58.9338C99.3921 57.6259 100.373 56.6614 100.373 56.6614C100.373 56.6614 101.967 54.16 102.605 53.2608C103.013 52.6805 105.032 53.8903 106.414 54.8221C107.191 55.3453 107.755 55.7785 107.755 55.7785Z" fill="#FF6029"/>
        <path d="M105.343 49.1172C105.343 49.1172 105.809 47.8992 107.19 47.7603C108.572 47.6295 108.425 48.7902 108.425 48.7902C108.425 48.7902 108.997 48.2099 109.471 49.109C109.945 50.0082 108.964 50.9238 108.964 50.9238C108.964 50.9238 109.299 51.1526 108.964 51.9374C108.629 52.7139 106.626 51.9374 106.626 51.9374L105.343 49.1172Z" fill="#352322"/>
        <path d="M104.837 55.5512C105.646 55.7883 106.243 55.5757 106.798 55.0934C106.758 55.0362 106.717 54.979 106.676 54.9136C106.349 54.415 106.414 54.2024 106.447 53.5567C106.471 53.0008 106.357 52.9436 106.357 52.4531C105.572 52.8291 104.501 52.9926 103.7 52.9599C103.676 53.2052 103.602 53.4668 103.48 53.7038C103.414 54.6112 103.962 55.2978 104.837 55.5512Z" fill="#FCAF9D"/>
        <path d="M106.561 54.7177L106.994 55.2245C106.994 55.2245 106.782 55.7803 105.556 55.8457C104.33 55.903 103.749 55.4534 103.471 55.0937C103.193 54.7259 103.275 54.0556 103.275 54.0556L103.815 53.8594C103.815 53.8594 103.856 54.5378 104.493 54.8566C105.556 55.388 106.561 54.7177 106.561 54.7177Z" fill="white"/>
        <path d="M106.488 52.453C106.717 51.8072 106.766 50.7445 106.455 50.0906C106.153 49.4448 105.335 49.0688 104.689 48.8644C103.684 48.5456 102.351 49.4612 102.024 50.7854C101.722 52.0034 102 53.9734 103.193 54.7255C104.518 55.5592 106.144 53.4339 106.488 52.453Z" fill="#FFC9B6"/>
        <path d="M106.937 49.5006C106.349 48.8793 105.098 47.7022 103.472 48.6668C102.442 49.2799 102.09 49.9011 102.09 50.5714C102.72 51.0374 103.782 51.4134 105.343 51.3153C105.491 51.6668 105.973 53.1545 106.447 53.5387C106.831 52.9665 107.371 52.1818 107.452 51.4297C107.518 50.8085 107.387 49.9747 106.937 49.5006Z" fill="#261822"/>
        <path d="M95.305 38.9324C95.8445 38.3275 96.0816 39.1531 96.4739 39.6027C96.6129 39.758 96.8826 39.9134 97.1769 40.0441C97.5121 40.1913 97.8554 40.3057 98.0679 40.3303C98.0843 40.3303 98.1006 40.3384 98.117 40.3384C98.1088 40.1995 97.8881 39.8888 97.8717 39.6845C97.839 39.2185 98.0925 39.0714 98.4113 39.5128C98.6728 39.8807 98.8118 40.3711 98.8772 40.9188C98.9181 41.2049 98.9426 41.4992 98.9508 41.8098C98.9671 42.4965 100.3 47.1558 100.864 48.4637C101.027 48.8479 101.182 49.1912 101.33 49.5346C101.534 49.9923 101.746 50.4338 102 50.9406C101.918 51.4555 101.975 52.6817 102.449 53.916C101.975 54.6027 101.542 54.9378 101.28 55.273C100.324 53.9487 99.8418 52.4773 99.2859 50.6299C99.2614 50.5482 99.2369 50.4664 99.2123 50.3847C98.8608 49.1913 98.3377 47.4092 98.1497 46.2403C97.9698 45.1368 97.741 43.4529 97.8799 42.3575C97.5366 42.055 97.087 41.8507 96.6047 41.4992C96.1715 41.1886 95.7219 40.7635 95.305 40.0523C95.1252 39.758 94.9944 39.2839 95.305 38.9324Z" fill="#FCAF9D"/>
        <path d="M105.343 52.3299C105.343 52.3299 106.095 51.5779 106.365 52.1664C106.635 52.7468 105.956 53.4253 105.956 53.4253L105.343 52.3299Z" fill="#FFC9B6"/>
        <path d="M106.676 57.6927C106.978 57.2676 108 56.2132 108.768 55.0933C109.038 54.7091 109.267 54.3085 109.43 53.9325C109.52 53.72 109.651 53.4175 109.79 53.0906C110.092 52.363 110.444 51.5374 110.501 51.423C111.147 50.0742 112.259 48.8481 113.092 47.6056C113.321 47.2704 113.493 46.9598 113.689 46.6737C113.869 46.4121 114.065 46.1587 114.351 45.938C114.76 45.611 114.711 45.423 114.989 45.2677C115.381 45.047 115.283 45.7745 115.087 46.0279C115.013 46.1178 114.809 46.3631 114.703 46.4611C114.776 46.5102 114.858 46.5429 114.932 46.5674C115.054 46.6165 115.177 46.641 115.283 46.6492C115.373 46.6573 115.455 46.6655 115.537 46.6655C116.166 46.6737 116.583 46.453 117.27 46.4121C117.801 46.3794 117.695 47.0497 117.22 47.4911C116.853 47.8344 116.19 47.9816 115.594 48.0633C115.022 48.1451 114.498 48.1614 114.335 48.2595C113.46 48.7581 113.002 50.6546 112.659 51.66C112.259 52.8371 111.915 53.0578 111.466 54.0878C111.466 54.096 111.458 54.1042 111.458 54.1123C111.032 55.1096 110.894 56.1478 110.714 56.6709C110.706 56.6873 110.706 56.7036 110.697 56.72C110.24 57.9788 109.602 59.5483 108.531 60.4148C106.864 61.7717 105.752 59.0006 106.676 57.6927Z" fill="#FFC9B6"/>
        <path d="M108.417 58.0508C108.417 58.0508 107.468 58.3042 106.798 59.3342C106.471 59.8328 107.223 60.7401 107.754 60.0208C108.286 59.3015 108.417 58.0508 108.417 58.0508Z" fill="#FCAF9D"/>
        <path d="M103.308 91.5819C103.316 90.9852 102.842 90.4375 102.842 90.4375C102.817 90.6092 102.605 90.928 102.458 91.3203C101.902 91.1078 100.823 90.8789 100.823 90.8789L100.733 91.3612L102.343 91.729C102.335 91.7699 102.327 91.8026 102.319 91.8435C102.286 92.1051 102.261 92.383 102.245 92.6282C102.082 92.9797 101.894 93.4538 101.82 93.8135C101.738 94.2304 101.599 94.6963 101.518 94.9743C101.174 94.5737 100.398 94.0587 99.7765 94.2059C99.7765 94.2059 99.0081 95.195 98.7302 95.71C98.4522 96.2249 99.049 96.6582 100.038 96.3802C100.888 96.1432 101.444 95.759 101.632 95.4647C101.665 95.4157 101.681 95.3748 101.697 95.3339C101.697 95.3258 101.697 95.3094 101.697 95.3012C101.804 95.0233 102.008 94.4348 102.114 93.9852C102.196 93.6173 102.36 93.2413 102.474 92.9961C102.482 92.9879 102.482 92.9879 102.49 92.9797C102.49 93.5192 102.49 94.2876 102.49 94.5165C102.703 94.6718 102.924 94.5165 102.924 94.5165C102.924 94.5165 102.964 93.9198 103.103 93.2331C103.218 92.7018 103.365 92.0969 103.308 91.5819Z" fill="#F24A1D"/>
        <path d="M109.144 88.0038C109.152 87.4071 108.678 86.8594 108.678 86.8594C108.654 87.031 108.441 87.3498 108.294 87.7422C107.738 87.5297 106.659 87.3008 106.659 87.3008L106.569 87.7831L108.179 88.1509C108.171 88.1918 108.163 88.2245 108.155 88.2654C108.122 88.5269 108.098 88.8049 108.081 89.0501C107.918 89.4016 107.73 89.8757 107.656 90.2354C107.575 90.6523 107.436 91.1182 107.354 91.3961C107.01 90.9956 106.234 90.4806 105.613 90.6278C105.613 90.6278 104.844 91.6168 104.566 92.1318C104.288 92.6468 104.885 93.0801 105.874 92.8021C106.724 92.5651 107.28 92.1809 107.468 91.8866C107.501 91.8376 107.517 91.7967 107.534 91.7558C107.534 91.7476 107.534 91.7313 107.534 91.7231C107.64 91.4452 107.844 90.8566 107.951 90.407C108.032 90.0392 108.196 89.6632 108.31 89.418C108.318 89.4098 108.318 89.4098 108.327 89.4016C108.327 89.9411 108.327 90.7095 108.327 90.9384C108.539 91.0937 108.76 90.9384 108.76 90.9384C108.76 90.9384 108.801 90.3416 108.94 89.655C109.046 89.1237 109.193 88.5269 109.144 88.0038Z" fill="#F24A1D"/>
        <path d="M103.864 61.5268C104.714 61.5268 105.556 60.9219 105.556 60.9219C105.556 60.9219 105.294 62.1235 103.888 62.1317C102.54 62.1399 102.082 61.1181 102.082 61.1181C102.082 61.1181 102.891 61.5268 103.864 61.5268Z" fill="#F24A1D"/>
        <path d="M106.994 68.6211C106.994 68.6211 107.845 70.3704 107.804 71.9644C107.763 73.5584 107.885 75.1442 107.885 76.493C107.885 77.8418 108.172 79.1497 108.172 79.1497C108.172 79.1497 107.804 79.1742 107.469 79.2069C107.133 79.2478 106.717 79.5093 106.717 79.5093C106.717 79.5093 106.896 76.4113 106.994 74.0652C107.093 71.7273 106.994 68.6211 106.994 68.6211Z" fill="#F24A1D"/>
        <path d="M103.716 67.1914C103.716 67.1914 103.692 69.8317 103.422 72.2186C103.152 74.6055 103.062 74.9407 103.046 76.6737C103.03 77.957 102.899 79.8535 102.899 79.8535C102.899 79.8535 102.645 79.739 102.131 79.7963C101.844 79.829 101.46 79.8535 101.46 79.8535C101.46 79.8535 101.959 75.6437 102.474 72.6192C102.981 69.5947 103.716 67.1914 103.716 67.1914Z" fill="#F24A1D"/>
        <path d="M101.231 68.1484C101.231 68.1484 100.594 70.1185 100.136 71.8432C99.6783 73.568 99.196 75.2438 99.0407 76.1593C98.8853 77.0748 98.6728 78.1865 98.6728 78.1865C98.6728 78.1865 98.4276 78.0721 98.1905 78.0476C97.9535 78.0312 97.7083 77.9985 97.7083 77.9985C97.7083 77.9985 98.305 75.3092 98.82 73.756C99.3349 72.2029 99.8336 70.4945 100.349 69.5381C100.872 68.5817 101.231 68.1484 101.231 68.1484Z" fill="#F24A1D"/>
        <path d="M90.8494 80.4162L90.8412 82.1328C90.8412 83.3672 90.0319 84.5933 88.4134 85.5334C85.16 87.4216 79.8712 87.4216 76.6015 85.5334C74.9502 84.5851 74.1328 83.3345 74.1328 82.0838L74.141 80.3672C74.141 81.6097 74.9584 82.8604 76.6096 83.8168C79.8794 85.705 85.1764 85.705 88.4216 83.8168C90.0319 82.8767 90.8412 81.6506 90.8494 80.4162Z" fill="#B88325"/>
        <path d="M88.3806 76.9748C91.6503 78.8631 91.6748 81.9285 88.4214 83.8167C85.168 85.705 79.8792 85.705 76.6095 83.8167C73.3398 81.9285 73.3152 78.8631 76.5686 76.9748C79.8138 75.0865 85.1027 75.0865 88.3806 76.9748Z" fill="#F9B233"/>
        <path d="M82.5194 84.3545C80.435 84.3545 78.4895 83.8968 77.0426 83.0548C75.7347 82.3028 75.0154 81.3464 75.0154 80.3818C75.0154 79.4172 75.7184 78.4772 77.0099 77.7333C78.4486 76.8995 80.3941 76.4336 82.4704 76.4336C84.5549 76.4336 86.5004 76.8914 87.9472 77.7333C89.2551 78.4854 89.9744 79.4418 89.9744 80.4063C89.9744 81.3709 89.2714 82.311 87.9799 83.0548C86.533 83.8968 84.5957 84.3545 82.5194 84.3545Z" fill="#B88325"/>
        <path d="M82.5194 84.7647C80.435 84.7647 78.4895 84.3069 77.0426 83.465C75.7347 82.7129 75.0154 81.7565 75.0154 80.792C75.0154 79.8274 75.7184 78.8873 77.0099 78.1435C78.4486 77.3097 80.3941 76.8438 82.4704 76.8438C84.5549 76.8438 86.5004 77.3015 87.9472 78.1435C89.2551 78.8955 89.9744 79.8519 89.9744 80.8165C89.9744 81.7811 89.2714 82.7211 87.9799 83.465C86.533 84.3069 84.5957 84.7647 82.5194 84.7647Z" fill="#FFCA38"/>
        <path d="M86.7461 82.936L85.896 82.4456L85.4055 82.7317L86.2557 82.7807V83.2221L85.43 83.7044L84.5799 83.2139L83.9096 83.6063L77.6235 79.9769V79.5355L78.2939 79.5927L77.4192 79.0859V78.6445L78.2448 78.6118L79.1194 79.1186L79.6099 78.8325L78.7352 78.3257V77.8843L79.5609 77.8516L80.4846 78.3829C81.4328 77.9905 82.2584 78.0478 83.0595 78.5137L83.2884 78.6445C83.9341 79.0205 84.114 79.3883 83.7952 79.8134C84.629 79.5682 85.3483 79.699 86.0186 80.0832L86.5336 80.3774C86.975 80.6308 87.1957 80.9088 87.1957 81.1949C87.1957 81.4401 87.0322 81.7017 86.6971 81.9633L87.5799 82.0287V82.4701L86.7461 82.936ZM84.1794 82.4047L85.3401 81.7344C85.7325 81.5055 85.7979 81.293 85.3238 81.0232L84.7761 80.7044C84.1957 80.3693 83.787 80.3938 83.2557 80.7044L82.2421 81.293L84.1794 82.4047ZM81.3429 80.7698L82.2911 80.2221C82.7243 79.9687 82.8306 79.7235 82.332 79.4292L81.9805 79.2249C81.5309 78.9633 81.1549 78.9551 80.738 79.2003L79.6835 79.8134L81.3429 80.7698Z" fill="#B88325"/>
        <path d="M87.1791 80.7305H86.844V81.1719H87.1791V80.7305Z" fill="#B88325"/>
        <path d="M83.9343 78.9805H83.5991V79.4219H83.9343V78.9805Z" fill="#B88325"/>
        <path d="M86.7461 82.4879L85.896 81.9974L85.4055 82.2835L86.2557 82.774L85.43 83.2563L84.5799 82.7658L83.9096 83.1582L77.6235 79.5288L78.2939 79.1364L77.4192 78.6296L78.2448 78.1473L79.1194 78.6541L79.6099 78.368L78.7352 77.8612L79.5609 77.3789L80.4846 77.9102C81.4328 77.5179 82.2584 77.5751 83.0595 78.041L83.2884 78.1718C83.9341 78.5478 84.114 78.9157 83.7952 79.3408C84.629 79.0955 85.3483 79.2263 86.0186 79.6105L86.5336 79.9048C86.975 80.1582 87.1957 80.4361 87.1957 80.7222C87.1957 80.9674 87.0322 81.229 86.6971 81.4906L87.5799 82.0056L86.7461 82.4879ZM84.1794 81.9565L85.3401 81.2862C85.7325 81.0574 85.7979 80.8448 85.3238 80.5751L84.7761 80.2563C84.1957 79.9211 83.787 79.9457 83.2557 80.2563L82.2421 80.8448L84.1794 81.9565ZM81.3429 80.3217L82.2911 79.774C82.7243 79.5206 82.8306 79.2754 82.332 78.9811L81.9805 78.7767C81.5309 78.5151 81.1549 78.507 80.738 78.7522L79.6835 79.3653L81.3429 80.3217Z" fill="#F9B233"/>
        <path d="M90.8494 78.3655L90.8412 80.0821C90.8412 81.3164 90.0319 82.5425 88.4134 83.4826C85.16 85.3709 79.8712 85.3709 76.6015 83.4826C74.9502 82.5344 74.1328 81.2837 74.1328 80.033L74.141 78.3164C74.141 79.5589 74.9584 80.8096 76.6096 81.766C79.8794 83.6543 85.1764 83.6543 88.4216 81.766C90.0319 80.8259 90.8412 79.5998 90.8494 78.3655Z" fill="#B88325"/>
        <path d="M88.3806 74.9162C91.6503 76.8045 91.6748 79.8699 88.4214 81.7581C85.168 83.6464 79.8792 83.6464 76.6095 81.7581C73.3398 79.8699 73.3152 76.8045 76.5686 74.9162C79.822 73.0279 85.1027 73.0279 88.3806 74.9162Z" fill="#F9B233"/>
        <path d="M82.5194 82.3038C80.435 82.3038 78.4895 81.846 77.0426 81.004C75.7347 80.252 75.0154 79.2956 75.0154 78.331C75.0154 77.3665 75.7184 76.4264 77.0099 75.6825C78.4486 74.8488 80.3941 74.3828 82.4704 74.3828C84.5549 74.3828 86.5004 74.8406 87.9472 75.6825C89.2551 76.4346 89.9744 77.391 89.9744 78.3556C89.9744 79.3201 89.2714 80.2602 87.9799 81.004C86.533 81.846 84.5957 82.3038 82.5194 82.3038Z" fill="#B88325"/>
        <path d="M82.5194 82.7139C80.435 82.7139 78.4895 82.2562 77.0426 81.4142C75.7347 80.6622 75.0154 79.7058 75.0154 78.7412C75.0154 77.7766 75.7184 76.8366 77.0099 76.0927C78.4486 75.2589 80.3941 74.793 82.4704 74.793C84.5549 74.793 86.5004 75.2507 87.9472 76.0927C89.2551 76.8447 89.9744 77.8011 89.9744 78.7657C89.9744 79.7303 89.2714 80.6703 87.9799 81.4142C86.533 82.2562 84.5957 82.7139 82.5194 82.7139Z" fill="#FFCA38"/>
        <path d="M86.7461 80.8852L85.896 80.3948L85.4055 80.6809L86.2557 80.7299V81.1713L85.43 81.6536L84.5799 81.1632L83.9096 81.5555L77.6235 77.9261V77.4847L78.2939 77.5419L77.4192 77.0351V76.5937L78.2448 76.561L79.1194 77.0678L79.6099 76.7817L78.7352 76.2749V75.8335L79.5609 75.8008L80.4846 76.3321C81.4328 75.9397 82.2584 75.997 83.0595 76.4629L83.2884 76.5937C83.9341 76.9697 84.114 77.3376 83.7952 77.7626C84.629 77.5174 85.3483 77.6482 86.0186 78.0324L86.5336 78.3267C86.975 78.5801 87.1957 78.858 87.1957 79.1441C87.1957 79.3893 87.0322 79.6509 86.6971 79.9125L87.5799 79.9779V80.4193L86.7461 80.8852ZM84.1794 80.3539L85.3401 79.6836C85.7325 79.4547 85.7979 79.2422 85.3238 78.9724L84.7761 78.6536C84.1957 78.3185 83.787 78.343 83.2557 78.6536L82.2421 79.2422L84.1794 80.3539ZM81.3429 78.719L82.2911 78.1713C82.7243 77.9179 82.8306 77.6727 82.332 77.3784L81.9805 77.1741C81.5309 76.9125 81.1549 76.9043 80.738 77.1496L79.6835 77.7626L81.3429 78.719Z" fill="#B88325"/>
        <path d="M87.1791 78.6797H86.844V79.1211H87.1791V78.6797Z" fill="#B88325"/>
        <path d="M83.9343 76.9297H83.5991V77.3711H83.9343V76.9297Z" fill="#B88325"/>
        <path d="M86.7461 80.4449L85.896 79.9545L85.4055 80.2406L86.2557 80.731L85.43 81.2133L84.5799 80.7228L83.9096 81.1152L77.6235 77.4858L78.2939 77.0934L77.4192 76.5866L78.2448 76.1043L79.1194 76.6111L79.6099 76.325L78.7352 75.8182L79.5609 75.3359L80.4846 75.8673C81.4328 75.4749 82.2584 75.5321 83.0595 75.9981L83.2884 76.1289C83.9341 76.5049 84.114 76.8727 83.7952 77.2978C84.629 77.0526 85.3483 77.1833 86.0186 77.5675L86.5336 77.8618C86.975 78.1152 87.1957 78.3931 87.1957 78.6792C87.1957 78.9245 87.0322 79.1861 86.6971 79.4476L87.5799 79.9626L86.7461 80.4449ZM84.1794 79.9136L85.3401 79.2433C85.7325 79.0144 85.7979 78.8019 85.3238 78.5321L84.7761 78.2133C84.1957 77.8782 83.787 77.9027 83.2557 78.2133L82.2421 78.8019L84.1794 79.9136ZM81.3429 78.2787L82.2911 77.731C82.7243 77.4776 82.8306 77.2324 82.332 76.9381L81.9805 76.7338C81.5309 76.4722 81.1549 76.464 80.738 76.7092L79.6835 77.3223L81.3429 78.2787Z" fill="#F9B233"/>
        <path d="M90.8494 76.3108L90.8412 78.0274C90.8412 79.2617 90.0319 80.4879 88.4134 81.4279C85.16 83.3162 79.8712 83.3162 76.6015 81.4279C74.9502 80.4797 74.1328 79.229 74.1328 77.9783L74.141 76.2617C74.141 77.5042 74.9584 78.7549 76.6096 79.7113C79.8794 81.5996 85.1764 81.5996 88.4216 79.7113C90.0319 78.7712 90.8412 77.5451 90.8494 76.3108Z" fill="#B88325"/>
        <path d="M88.3806 72.8693C91.6503 74.7576 91.6748 77.823 88.4214 79.7113C85.168 81.5995 79.8792 81.5995 76.6095 79.7113C73.3398 77.823 73.3152 74.7576 76.5686 72.8693C79.8138 70.9811 85.1027 70.9811 88.3806 72.8693Z" fill="#F9B233"/>
        <path d="M82.5194 80.2569C80.435 80.2569 78.4895 79.7991 77.0426 78.9572C75.7347 78.2051 75.0154 77.2487 75.0154 76.2842C75.0154 75.3196 75.7184 74.3795 77.0099 73.6357C78.4486 72.8019 80.3941 72.3359 82.4704 72.3359C84.5549 72.3359 86.5004 72.7937 87.9472 73.6357C89.2551 74.3877 89.9744 75.3441 89.9744 76.3087C89.9744 77.2732 89.2714 78.2133 87.9799 78.9572C86.533 79.7909 84.5957 80.2569 82.5194 80.2569Z" fill="#B88325"/>
        <path d="M82.5194 80.6592C80.435 80.6592 78.4895 80.2015 77.0426 79.3595C75.7347 78.6075 75.0154 77.6511 75.0154 76.6865C75.0154 75.7219 75.7184 74.7819 77.0099 74.038C78.4486 73.2042 80.3941 72.7383 82.4704 72.7383C84.5549 72.7383 86.5004 73.196 87.9472 74.038C89.2551 74.79 89.9744 75.7464 89.9744 76.711C89.9744 77.6756 89.2714 78.6156 87.9799 79.3595C86.533 80.2015 84.5957 80.6592 82.5194 80.6592Z" fill="#FFCA38"/>
        <path d="M86.7459 78.8384L85.8957 78.3479L85.4053 78.634L86.2554 78.683V79.1245L85.4298 79.6067L84.5797 79.1163L83.9094 79.5087L77.6233 75.8792V75.4378L78.2936 75.495L77.4189 74.9882V74.5468L78.2446 74.5141L79.1192 75.0209L79.6097 74.7348L78.735 74.228V73.7866L79.5606 73.7539L80.4843 74.2852C81.4326 73.8929 82.2582 73.9501 83.0592 74.416L83.2881 74.5468C83.9339 74.9228 84.1137 75.2907 83.7949 75.7158C84.6287 75.4705 85.3481 75.6013 86.0184 75.9855L86.5333 76.2798C86.9748 76.5332 87.1955 76.8111 87.1955 77.0972C87.1955 77.3424 87.032 77.604 86.6968 77.8656L87.5797 77.931V78.3724L86.7459 78.8384ZM84.1791 78.307L85.3399 77.6367C85.7323 77.4078 85.7976 77.1953 85.3235 76.9256L84.7759 76.6068C84.1955 76.2716 83.7868 76.2961 83.2554 76.6068L82.2418 77.1953L84.1791 78.307ZM81.3426 76.6722L82.2909 76.1245C82.7241 75.8711 82.8304 75.6258 82.3317 75.3316L81.9802 75.1272C81.5306 74.8656 81.1546 74.8574 80.7377 75.1027L79.6832 75.7158L81.3426 76.6722Z" fill="#B88325"/>
        <path d="M87.1789 76.6328H86.8438V77.0742H87.1789V76.6328Z" fill="#B88325"/>
        <path d="M83.934 74.8828H83.5989V75.3242H83.934V74.8828Z" fill="#B88325"/>
        <path d="M86.7459 78.3902L85.8957 77.8998L85.4053 78.1859L86.2554 78.6763L85.4298 79.1586L84.5797 78.6681L83.9094 79.0605L77.6233 75.4311L78.2936 75.0387L77.4189 74.5319L78.2446 74.0496L79.1192 74.5564L79.6097 74.2703L78.735 73.7635L79.5606 73.2812L80.4843 73.8126C81.4326 73.4202 82.2582 73.4774 83.0592 73.9434L83.2881 74.0742C83.9339 74.4502 84.1137 74.818 83.7949 75.2431C84.6287 74.9979 85.3481 75.1287 86.0184 75.5129L86.5333 75.8071C86.9748 76.0605 87.1955 76.3385 87.1955 76.6246C87.1955 76.8698 87.032 77.1314 86.6968 77.393L87.5797 77.9079L86.7459 78.3902ZM84.1791 77.8589L85.3399 77.1886C85.7323 76.9597 85.7976 76.7472 85.3235 76.4774L84.7759 76.1586C84.1955 75.8235 83.7868 75.848 83.2554 76.1586L82.2418 76.7472L84.1791 77.8589ZM81.3426 76.224L82.2909 75.6763C82.7241 75.4229 82.8304 75.1777 82.3317 74.8834L81.9802 74.6791C81.5306 74.4175 81.1546 74.4093 80.7377 74.6545L79.6832 75.2676L81.3426 76.224Z" fill="#F9B233"/>
        <path d="M90.8494 74.2717L90.8412 75.9883C90.8412 77.2226 90.0319 78.4488 88.4134 79.3888C85.16 81.2771 79.8712 81.2771 76.6015 79.3888C74.9502 78.4406 74.1328 77.1899 74.1328 75.9393L74.141 74.2227C74.141 75.4733 74.9584 76.7158 76.6096 77.6722C79.8794 79.5605 85.1764 79.5605 88.4216 77.6722C90.0319 76.7322 90.8412 75.506 90.8494 74.2717Z" fill="#B88325"/>
        <path d="M88.3806 70.8225C91.6503 72.7107 91.6748 75.7761 88.4214 77.6644C85.168 79.5527 79.8792 79.5527 76.6095 77.6644C73.3398 75.7761 73.3152 72.7107 76.5686 70.8225C79.8138 68.9342 85.1027 68.9342 88.3806 70.8225Z" fill="#F9B233"/>
        <path d="M82.5194 78.21C80.435 78.21 78.4895 77.7523 77.0426 76.9103C75.7347 76.1583 75.0154 75.2019 75.0154 74.2373C75.0154 73.2727 75.7184 72.3327 77.0099 71.5888C78.4486 70.755 80.3941 70.2891 82.4704 70.2891C84.5549 70.2891 86.5004 70.7468 87.9472 71.5888C89.2551 72.3408 89.9744 73.2972 89.9744 74.2618C89.9744 75.2264 89.2714 76.1664 87.9799 76.9103C86.533 77.7523 84.5957 78.21 82.5194 78.21Z" fill="#B88325"/>
        <path d="M82.5194 78.6202C80.435 78.6202 78.4895 78.1624 77.0426 77.3205C75.7347 76.5684 75.0154 75.612 75.0154 74.6474C75.0154 73.6829 75.7184 72.7428 77.0099 71.9989C78.4486 71.1652 80.3941 70.6992 82.4704 70.6992C84.5549 70.6992 86.5004 71.157 87.9472 71.9989C89.2551 72.751 89.9744 73.7074 89.9744 74.672C89.9744 75.6365 89.2714 76.5766 87.9799 77.3205C86.533 78.1624 84.5957 78.6202 82.5194 78.6202Z" fill="#FFCA38"/>
        <path d="M86.7459 76.7876L85.8957 76.2971L85.4053 76.5832L86.2554 76.6323V77.0737L85.4298 77.556L84.5797 77.0655L83.9094 77.4579L77.6233 73.8285V73.387L78.2936 73.4443L77.4189 72.9375V72.496L78.2446 72.4633L79.1192 72.9702L79.6097 72.684L78.735 72.1772V71.7358L79.5606 71.7031L80.4843 72.2345C81.4326 71.8421 82.2582 71.8993 83.0592 72.3652L83.2881 72.496C83.9339 72.8721 84.1137 73.2399 83.7949 73.665C84.6287 73.4197 85.3481 73.5505 86.0184 73.9347L86.5333 74.229C86.9748 74.4824 87.1955 74.7603 87.1955 75.0464C87.1955 75.2917 87.032 75.5532 86.6968 75.8148L87.5797 75.8802V76.3216L86.7459 76.7876ZM84.1791 76.2562L85.3399 75.5859C85.7323 75.3571 85.7976 75.1445 85.3235 74.8748L84.7759 74.556C84.1955 74.2208 83.7868 74.2453 83.2554 74.556L82.2418 75.1445L84.1791 76.2562ZM81.3426 74.6214L82.2909 74.0737C82.7241 73.8203 82.8304 73.5751 82.3317 73.2808L81.9802 73.0764C81.5306 72.8148 81.1546 72.8067 80.7377 73.0519L79.6832 73.665L81.3426 74.6214Z" fill="#B88325"/>
        <path d="M87.1789 74.582H86.8438V75.0234H87.1789V74.582Z" fill="#B88325"/>
        <path d="M83.934 72.832H83.5989V73.2734H83.934V72.832Z" fill="#B88325"/>
        <path d="M86.7459 76.3473L85.8957 75.8568L85.4053 76.1429L86.2554 76.6334L85.4298 77.1156L84.5797 76.6252L83.9094 77.0176L77.6233 73.3881L78.2936 72.9958L77.4189 72.489L78.2446 72.0067L79.1192 72.5135L79.6097 72.2274L78.735 71.7206L79.5606 71.2383L80.4843 71.7696C81.4326 71.3772 82.2582 71.4345 83.0592 71.9004L83.2881 72.0312C83.9339 72.4072 84.1137 72.7751 83.7949 73.2001C84.6287 72.9549 85.3481 73.0857 86.0184 73.4699L86.5333 73.7642C86.9748 74.0176 87.1955 74.2955 87.1955 74.5816C87.1955 74.8268 87.032 75.0884 86.6968 75.35L87.5797 75.865L86.7459 76.3473ZM84.1791 75.8159L85.3399 75.1456C85.7323 74.9167 85.7976 74.7042 85.3235 74.4345L84.7759 74.1157C84.1955 73.7805 83.7868 73.805 83.2554 74.1157L82.2418 74.7042L84.1791 75.8159ZM81.3426 74.181L82.2909 73.6334C82.7241 73.38 82.8304 73.1347 82.3317 72.8405L81.9802 72.6361C81.5306 72.3745 81.1546 72.3663 80.7377 72.6116L79.6832 73.2247L81.3426 74.181Z" fill="#F9B233"/>
        <path d="M90.8494 72.2248L90.8412 73.9414C90.8412 75.1758 90.0319 76.4019 88.4134 77.342C85.16 79.2303 79.8712 79.2303 76.6015 77.342C74.9502 76.3937 74.1328 75.1431 74.1328 73.8924L74.141 72.1758C74.141 73.4265 74.9584 74.669 76.6096 75.6254C79.8794 77.5136 85.1764 77.5136 88.4216 75.6254C90.0319 74.6771 90.8412 73.451 90.8494 72.2248Z" fill="#B88325"/>
        <path d="M88.3806 68.7756C91.6503 70.6639 91.6748 73.7292 88.4214 75.6175C85.168 77.5058 79.8792 77.5058 76.6095 75.6175C73.3398 73.7292 73.3152 70.6639 76.5686 68.7756C79.8138 66.8873 85.1027 66.8873 88.3806 68.7756Z" fill="#F9B233"/>
        <path d="M82.5194 76.1631C80.435 76.1631 78.4895 75.7054 77.0426 74.8634C75.7347 74.1114 75.0154 73.155 75.0154 72.1904C75.0154 71.2258 75.7184 70.2858 77.0099 69.5419C78.4486 68.7081 80.3941 68.2422 82.4704 68.2422C84.5549 68.2422 86.5004 68.7 87.9472 69.5419C89.2551 70.294 89.9744 71.2504 89.9744 72.2149C89.9744 73.1795 89.2714 74.1196 87.9799 74.8634C86.533 75.6972 84.5957 76.1631 82.5194 76.1631Z" fill="#B88325"/>
        <path d="M82.5194 76.5733C80.435 76.5733 78.4895 76.1155 77.0426 75.2736C75.7347 74.5215 75.0154 73.5651 75.0154 72.6006C75.0154 71.636 75.7184 70.6959 77.0099 69.9521C78.4486 69.1183 80.3941 68.6523 82.4704 68.6523C84.5549 68.6523 86.5004 69.1101 87.9472 69.9521C89.2551 70.7041 89.9744 71.6605 89.9744 72.6251C89.9744 73.5897 89.2714 74.5297 87.9799 75.2736C86.533 76.1074 84.5957 76.5733 82.5194 76.5733Z" fill="#FFCA38"/>
        <path d="M86.7461 74.7485L85.896 74.2581L85.4055 74.5442L86.2557 74.5932V75.0346L85.43 75.5169L84.5799 75.0264L83.9096 75.4188L77.6235 71.7894V71.348L78.2939 71.4052L77.4192 70.8984V70.457L78.2448 70.4243L79.1194 70.9311L79.6099 70.645L78.7352 70.1382V69.6968L79.5609 69.6641L80.4846 70.1954C81.4328 69.803 82.2584 69.8602 83.0595 70.3262L83.2884 70.457C83.9341 70.833 84.114 71.2008 83.7952 71.6259C84.629 71.3807 85.3483 71.5115 86.0186 71.8957L86.5336 72.1899C86.975 72.4433 87.1957 72.7213 87.1957 73.0074C87.1957 73.2526 87.0322 73.5142 86.6971 73.7758L87.5799 73.8412V74.2826L86.7461 74.7485ZM84.1794 74.2172L85.3401 73.5469C85.7325 73.318 85.7979 73.1055 85.3238 72.8357L84.7761 72.5169C84.1957 72.1818 83.787 72.2063 83.2557 72.5169L82.2421 73.1055L84.1794 74.2172ZM81.3429 72.5823L82.2911 72.0346C82.7243 71.7812 82.8306 71.536 82.332 71.2417L81.9805 71.0374C81.5309 70.7758 81.1549 70.7676 80.738 71.0128L79.6835 71.6259L81.3429 72.5823Z" fill="#B88325"/>
        <path d="M87.1791 72.5391H86.844V72.9805H87.1791V72.5391Z" fill="#B88325"/>
        <path d="M83.9343 70.7891H83.5991V71.2305H83.9343V70.7891Z" fill="#B88325"/>
        <path d="M86.7461 74.2965L85.896 73.806L85.4055 74.0921L86.2557 74.5826L85.43 75.0649L84.5799 74.5744L83.9096 74.9668L77.6235 71.3374L78.2939 70.945L77.4192 70.4382L78.2448 69.9559L79.1194 70.4627L79.6099 70.1766L78.7352 69.6698L79.5609 69.1875L80.4846 69.7188C81.4328 69.3265 82.2584 69.3837 83.0595 69.8496L83.2884 69.9804C83.9341 70.3564 84.114 70.7243 83.7952 71.1493C84.629 70.9041 85.3483 71.0349 86.0186 71.4191L86.5336 71.7134C86.975 71.9668 87.1957 72.2447 87.1957 72.5308C87.1957 72.776 87.0322 73.0376 86.6971 73.2992L87.5799 73.8142L86.7461 74.2965ZM84.1794 73.7651L85.3401 73.0948C85.7325 72.866 85.7979 72.6534 85.3238 72.3837L84.7761 72.0649C84.1957 71.7297 83.787 71.7543 83.2557 72.0649L82.2421 72.6534L84.1794 73.7651ZM81.3429 72.1303L82.2911 71.5826C82.7243 71.3292 82.8306 71.0839 82.332 70.7897L81.9805 70.5853C81.5309 70.3237 81.1549 70.3156 80.738 70.5608L79.6835 71.1739L81.3429 72.1303Z" fill="#F9B233"/>
        <path d="M90.8494 70.178L90.8412 71.8946C90.8412 73.1289 90.0319 74.355 88.4134 75.2951C85.16 77.1834 79.8712 77.1834 76.6015 75.2951C74.9502 74.3469 74.1328 73.0962 74.1328 71.8455L74.141 70.1289C74.141 71.3714 74.9584 72.6221 76.6096 73.5785C79.8794 75.4668 85.1764 75.4668 88.4216 73.5785C90.0319 72.6384 90.8412 71.4123 90.8494 70.178Z" fill="#B88325"/>
        <path d="M88.3806 66.7287C91.6503 68.617 91.6748 71.6824 88.4214 73.5706C85.168 75.4589 79.8792 75.4589 76.6095 73.5706C73.3398 71.6824 73.3152 68.617 76.5686 66.7287C79.8138 64.8404 85.1027 64.8404 88.3806 66.7287Z" fill="#F9B233"/>
        <path d="M82.5194 74.1163C80.435 74.1163 78.4895 73.6585 77.0426 72.8165C75.7347 72.0645 75.0154 71.1081 75.0154 70.1435C75.0154 69.179 75.7184 68.2389 77.0099 67.495C78.4486 66.6612 80.3941 66.1953 82.4704 66.1953C84.5549 66.1953 86.5004 66.6531 87.9472 67.495C89.2551 68.2471 89.9744 69.2035 89.9744 70.1681C89.9744 71.1326 89.2714 72.0727 87.9799 72.8165C86.533 73.6585 84.5957 74.1163 82.5194 74.1163Z" fill="#B88325"/>
        <path d="M82.5194 74.5264C80.435 74.5264 78.4895 74.0687 77.0426 73.2267C75.7347 72.4747 75.0154 71.5183 75.0154 70.5537C75.0154 69.5891 75.7184 68.6491 77.0099 67.9052C78.4486 67.0714 80.3941 66.6055 82.4704 66.6055C84.5549 66.6055 86.5004 67.0632 87.9472 67.9052C89.2551 68.6572 89.9744 69.6136 89.9744 70.5782C89.9744 71.5428 89.2714 72.4828 87.9799 73.2267C86.533 74.0687 84.5957 74.5264 82.5194 74.5264Z" fill="#FFCA38"/>
        <path d="M86.7459 72.6938L85.8957 72.2034L85.4053 72.4895L86.2554 72.5385V72.9799L85.4298 73.4622L84.5797 72.9718L83.9094 73.3641L77.6233 69.7347V69.2933L78.2936 69.3505L77.4189 68.8437V68.4023L78.2446 68.3696L79.1192 68.8764L79.6097 68.5903L78.735 68.0835V67.6421L79.5606 67.6094L80.4843 68.1407C81.4326 67.7483 82.2582 67.8056 83.0592 68.2715L83.2881 68.4023C83.9339 68.7783 84.1137 69.1462 83.7949 69.5712C84.6287 69.326 85.3481 69.4568 86.0184 69.841L86.5333 70.1353C86.9748 70.3887 87.1955 70.6666 87.1955 70.9527C87.1955 71.1979 87.032 71.4595 86.6968 71.7211L87.5797 71.7865V72.2279L86.7459 72.6938ZM84.1791 72.1625L85.3399 71.4922C85.7323 71.2633 85.7976 71.0508 85.3235 70.781L84.7759 70.4622C84.1955 70.1271 83.7868 70.1516 83.2554 70.4622L82.2418 71.0508L84.1791 72.1625ZM81.3426 70.5276L82.2909 69.9799C82.7241 69.7265 82.8304 69.4813 82.3317 69.187L81.9802 68.9827C81.5306 68.7211 81.1546 68.7129 80.7377 68.9581L79.6832 69.5712L81.3426 70.5276Z" fill="#B88325"/>
        <path d="M87.1789 70.4883H86.8438V70.9297H87.1789V70.4883Z" fill="#B88325"/>
        <path d="M83.934 68.7383H83.5989V69.1797H83.934V68.7383Z" fill="#B88325"/>
        <path d="M86.7459 72.2535L85.8957 71.763L85.4053 72.0491L86.2554 72.5396L85.4298 73.0219L84.5797 72.5314L83.9094 72.9238L77.6233 69.2944L78.2936 68.902L77.4189 68.3952L78.2446 67.9129L79.1192 68.4197L79.6097 68.1336L78.735 67.6268L79.5606 67.1445L80.4843 67.6759C81.4326 67.2835 82.2582 67.3407 83.0592 67.8067L83.2881 67.9374C83.9339 68.3135 84.1137 68.6813 83.7949 69.1064C84.6287 68.8611 85.3481 68.9919 86.0184 69.3761L86.5333 69.6704C86.9748 69.9238 87.1955 70.2017 87.1955 70.4878C87.1955 70.7331 87.032 70.9947 86.6968 71.2562L87.5797 71.7712L86.7459 72.2535ZM84.1791 71.7222L85.3399 71.0519C85.7323 70.823 85.7976 70.6105 85.3235 70.3407L84.7759 70.0219C84.1955 69.6868 83.7868 69.7113 83.2554 70.0219L82.2418 70.6105L84.1791 71.7222ZM81.3426 70.0873L82.2909 69.5396C82.7241 69.2862 82.8304 69.041 82.3317 68.7467L81.9802 68.5423C81.5306 68.2808 81.1546 68.2726 80.7377 68.5178L79.6832 69.1309L81.3426 70.0873Z" fill="#F9B233"/>
        <path d="M90.8494 68.135L90.8412 69.8516C90.8412 71.0859 90.0319 72.3121 88.4134 73.2521C85.16 75.1404 79.8712 75.1404 76.6015 73.2521C74.9502 72.3039 74.1328 71.0532 74.1328 69.8026L74.141 68.0859C74.141 69.3366 74.9584 70.5791 76.6096 71.5355C79.8794 73.4238 85.1764 73.4238 88.4216 71.5355C90.0319 70.5873 90.8412 69.3611 90.8494 68.135Z" fill="#B88325"/>
        <path d="M90.849 68.1261C90.8566 65.4534 87.1224 63.2762 82.5085 63.2632C77.8946 63.2501 74.1482 65.4061 74.1406 68.0788C74.1331 70.7514 77.8673 72.9286 82.4811 72.9416C87.095 72.9547 90.8415 70.7987 90.849 68.1261Z" fill="#F9B233"/>
        <path d="M82.5199 72.0733C80.4355 72.0733 78.49 71.6155 77.0431 70.7736C75.7352 70.0215 75.0159 69.0651 75.0159 68.1006C75.0159 67.136 75.7189 66.1959 77.0104 65.4521C78.4491 64.6183 80.3946 64.1523 82.4709 64.1523C84.5553 64.1523 86.5008 64.6101 87.9477 65.4521C89.2556 66.2041 89.9749 67.1605 89.9749 68.1251C89.9749 69.0897 89.2719 70.0297 87.9804 70.7736C86.5335 71.6074 84.5962 72.0733 82.5199 72.0733Z" fill="#B88325"/>
        <path d="M82.5199 72.4834C80.4355 72.4834 78.49 72.0257 77.0431 71.1837C75.7352 70.4317 75.0159 69.4753 75.0159 68.5107C75.0159 67.5461 75.7189 66.6061 77.0104 65.8622C78.4491 65.0284 80.3946 64.5625 82.4709 64.5625C84.5553 64.5625 86.5008 65.0203 87.9477 65.8622C89.2556 66.6143 89.9749 67.5707 89.9749 68.5352C89.9749 69.4998 89.2719 70.4399 87.9804 71.1837C86.5335 72.0175 84.5962 72.4834 82.5199 72.4834Z" fill="#FFCA38"/>
        <path d="M86.7456 70.6509L85.8955 70.1604L85.405 70.4465L86.2552 70.4955V70.937L85.4296 71.4192L84.5794 70.9288L83.9091 71.3212L77.6231 67.6917V67.2503L78.2934 67.3075L77.4187 66.8007V66.3593L78.2443 66.3266L79.119 66.8334L79.6094 66.5473L78.7348 66.0405V65.5991L79.5604 65.5664L80.4841 66.0977C81.4323 65.7054 82.2579 65.7626 83.059 66.2285L83.2879 66.3593C83.9337 66.7353 84.1135 67.1032 83.7947 67.5283C84.6285 67.283 85.3478 67.4138 86.0181 67.798L86.5331 68.0923C86.9745 68.3457 87.1952 68.6236 87.1952 68.9097C87.1952 69.155 87.0317 69.4165 86.6966 69.6781L87.5794 69.7435V70.1849L86.7456 70.6509ZM84.1789 70.1195L85.3397 69.4492C85.732 69.2203 85.7974 69.0078 85.3233 68.7381L84.7756 68.4193C84.1952 68.0841 83.7865 68.1086 83.2552 68.4193L82.2416 69.0078L84.1789 70.1195ZM81.3424 68.4847L82.2906 67.937C82.7239 67.6836 82.8301 67.4383 82.3315 67.1441L81.98 66.9397C81.5304 66.6781 81.1544 66.6699 80.7375 66.9152L79.683 67.5283L81.3424 68.4847Z" fill="#B88325"/>
        <path d="M87.1787 68.4453H86.8435V68.8867H87.1787V68.4453Z" fill="#B88325"/>
        <path d="M83.9338 66.6953H83.5986V67.1367H83.9338V66.6953Z" fill="#B88325"/>
        <path d="M86.7456 70.2027L85.8955 69.7123L85.405 69.9984L86.2552 70.4888L85.4296 70.9711L84.5794 70.4806L83.9091 70.873L77.6231 67.2436L78.2934 66.8512L77.4187 66.3444L78.2443 65.8621L79.119 66.3689L79.6094 66.0828L78.7348 65.576L79.5604 65.0938L80.4841 65.6251C81.4323 65.2327 82.2579 65.2899 83.059 65.7559L83.2879 65.8867C83.9337 66.2627 84.1135 66.6305 83.7947 67.0556C84.6285 66.8104 85.3478 66.9412 86.0181 67.3253L86.5331 67.6196C86.9745 67.873 87.1952 68.151 87.1952 68.4371C87.1952 68.6823 87.0317 68.9439 86.6966 69.2055L87.5794 69.7204L86.7456 70.2027ZM84.1789 69.6714L85.3397 69.0011C85.732 68.7722 85.7974 68.5597 85.3233 68.2899L84.7756 67.9711C84.1952 67.636 83.7865 67.6605 83.2552 67.9711L82.2416 68.5597L84.1789 69.6714ZM81.3424 68.0365L82.2906 67.4888C82.7239 67.2354 82.8301 66.9902 82.3315 66.6959L81.98 66.4916C81.5304 66.23 81.1544 66.2218 80.7375 66.467L79.683 67.0801L81.3424 68.0365Z" fill="#F9B233"/>
        <path d="M90.8489 66.0803L90.8407 67.7969C90.8407 69.0312 90.0314 70.2574 88.4129 71.1974C85.1595 73.0857 79.8707 73.0857 76.601 71.1974C74.9498 70.2492 74.1323 68.9985 74.1323 67.7479L74.1405 66.0312C74.1405 67.2819 74.9579 68.5244 76.6092 69.4808C79.8789 71.3691 85.1759 71.3691 88.4211 69.4808C90.0314 68.5408 90.8407 67.3146 90.8489 66.0803Z" fill="#B88325"/>
        <path d="M90.8444 66.0753C90.852 63.4026 87.1178 61.2255 82.5039 61.2124C77.89 61.1993 74.1436 63.3554 74.136 66.028C74.1284 68.7006 77.8626 70.8778 82.4765 70.8908C87.0904 70.9039 90.8368 68.7479 90.8444 66.0753Z" fill="#F9B233"/>
        <path d="M82.5192 70.0186C80.4347 70.0186 78.4892 69.5608 77.0424 68.7189C75.7345 67.9668 75.0151 67.0104 75.0151 66.0459C75.0151 65.0813 75.7181 64.1412 77.0097 63.3974C78.4484 62.5636 80.3939 62.0977 82.4701 62.0977C84.5546 62.0977 86.5001 62.5554 87.947 63.3974C89.2549 64.1494 89.9742 65.1058 89.9742 66.0704C89.9742 67.035 89.2712 67.975 87.9797 68.7189C86.5328 69.5608 84.5955 70.0186 82.5192 70.0186Z" fill="#B88325"/>
        <path d="M82.5192 70.4288C80.4347 70.4288 78.4892 69.971 77.0424 69.129C75.7345 68.377 75.0151 67.4206 75.0151 66.456C75.0151 65.4915 75.7181 64.5514 77.0097 63.8075C78.4484 62.9738 80.3939 62.5078 82.4701 62.5078C84.5546 62.5078 86.5001 62.9656 87.947 63.8075C89.2549 64.5596 89.9742 65.516 89.9742 66.4806C89.9742 67.4451 89.2712 68.3852 87.9797 69.129C86.5328 69.971 84.5955 70.4288 82.5192 70.4288Z" fill="#FFCA38"/>
        <path d="M86.7454 68.5969L85.8953 68.1064L85.4048 68.3925L86.2549 68.4416V68.883L85.4293 69.3653L84.5792 68.8748L83.9089 69.2672L77.6228 65.6378V65.1964L78.2931 65.2536L77.4185 64.7468V64.289L78.2441 64.2563L79.1187 64.7631L79.6092 64.477L78.7345 63.9702V63.5288L79.5601 63.4961L80.4838 64.0274C81.4321 63.6351 82.2577 63.6923 83.0588 64.1582L83.2876 64.289C83.9334 64.665 84.1132 65.0329 83.7944 65.4579C84.6282 65.2127 85.3476 65.3435 86.0179 65.7277L86.5329 66.022C86.9743 66.2754 87.195 66.5533 87.195 66.8394C87.195 67.0846 87.0315 67.3462 86.6964 67.6078L87.5792 67.6732V68.1146L86.7454 68.5969ZM84.1786 68.0656L85.3394 67.3953C85.7318 67.1664 85.7972 66.9538 85.323 66.6841L84.7754 66.3653C84.195 66.0301 83.7863 66.0547 83.2549 66.3653L82.2413 66.9538L84.1786 68.0656ZM81.3421 66.4307L82.2904 65.883C82.7236 65.6296 82.8299 65.3844 82.3313 65.0901L81.9797 64.8857C81.5301 64.6242 81.1541 64.616 80.7372 64.8612L79.6827 65.4743L81.3421 66.4307Z" fill="#B88325"/>
        <path d="M87.1789 66.3906H86.8438V66.832H87.1789V66.3906Z" fill="#B88325"/>
        <path d="M83.9335 64.6406H83.5984V65.082H83.9335V64.6406Z" fill="#B88325"/>
        <path d="M86.7458 68.1562L85.8956 67.6657L85.4052 67.9519L86.2553 68.4423L85.4297 68.9246L84.5796 68.4341L83.9093 68.8265L77.6232 65.1971L78.2935 64.8047L77.4106 64.2897L78.2363 63.8075L79.1109 64.3143L79.6014 64.0282L78.7267 63.5213L79.5523 63.0391L80.476 63.5704C81.4243 63.178 82.2499 63.2352 83.0509 63.7012L83.2798 63.832C83.9256 64.208 84.1055 64.5758 83.7867 65.0009C84.6204 64.7557 85.3398 64.8865 86.0101 65.2707L86.525 65.5649C86.9665 65.8183 87.1872 66.0963 87.1872 66.3824C87.1872 66.6276 87.0237 66.8892 86.6885 67.1508L87.5714 67.6657L86.7458 68.1562ZM84.179 67.6249L85.3398 66.9546C85.7321 66.7257 85.7975 66.5132 85.3234 66.2434L84.7757 65.9246C84.1954 65.5895 83.7866 65.614 83.2553 65.9246L82.2417 66.5132L84.179 67.6249ZM81.3425 65.99L82.2907 65.4423C82.724 65.1889 82.8303 64.9437 82.3316 64.6494L81.9801 64.4451C81.5305 64.1835 81.1545 64.1753 80.7376 64.4205L79.6831 65.0336L81.3425 65.99Z" fill="#F9B233"/>
        <path d="M90.8491 64.0334L90.8409 65.75C90.8409 66.9844 90.0317 68.2105 88.4131 69.1506C85.1598 71.0388 79.871 71.0388 76.6012 69.1506C74.95 68.2023 74.1326 66.9517 74.1326 65.701L74.1407 63.9844C74.1407 65.2351 74.9582 66.4776 76.6094 67.434C79.8791 69.3222 85.1761 69.3222 88.4213 67.434C90.0317 66.4857 90.8409 65.2596 90.8491 64.0334Z" fill="#B88325"/>
        <path d="M90.8488 64.0245C90.8563 61.3519 87.1222 59.1747 82.5083 59.1616C77.8944 59.1486 74.148 61.3046 74.1404 63.9772C74.1328 66.6498 77.867 68.827 82.4809 68.8401C87.0948 68.8531 90.8412 66.6971 90.8488 64.0245Z" fill="#F9B233"/>
        <path d="M82.5197 67.9717C80.4352 67.9717 78.4897 67.514 77.0429 66.672C75.735 65.92 75.0156 64.9636 75.0156 63.999C75.0156 63.0344 75.7186 62.0944 77.0102 61.3505C78.4489 60.5167 80.3943 60.0508 82.4706 60.0508C84.5551 60.0508 86.5006 60.5085 87.9475 61.3505C89.2554 62.1025 89.9747 63.0589 89.9747 64.0235C89.9747 64.9881 89.2717 65.9281 87.9801 66.672C86.5333 67.5058 84.596 67.9717 82.5197 67.9717Z" fill="#B88325"/>
        <path d="M82.5197 68.3819C80.4352 68.3819 78.4897 67.9241 77.0429 67.0822C75.735 66.3301 75.0156 65.3737 75.0156 64.4092C75.0156 63.4446 75.7186 62.5045 77.0102 61.7607C78.4489 60.9269 80.3943 60.4609 82.4706 60.4609C84.5551 60.4609 86.5006 60.9187 87.9475 61.7607C89.2554 62.5127 89.9747 63.4691 89.9747 64.4337C89.9747 65.3982 89.2717 66.3383 87.9801 67.0822C86.5333 67.9159 84.596 68.3819 82.5197 68.3819Z" fill="#FFCA38"/>
        <path d="M86.7456 66.5532L85.8955 66.0627L85.405 66.3488L86.2552 66.3979V66.8393L85.4296 67.3216L84.5794 66.8311L83.9091 67.2235L77.6231 63.5941V63.1527L78.2934 63.2099L77.4187 62.7031V62.2617L78.2443 62.229L79.119 62.7358L79.6094 62.4497L78.7348 61.9429V61.5014L79.5604 61.4688L80.4841 62.0001C81.4323 61.6077 82.2579 61.6649 83.059 62.1309L83.2879 62.2617C83.9337 62.6377 84.1135 63.0055 83.7947 63.4306C84.6285 63.1854 85.3478 63.3162 86.0181 63.7003L86.5331 63.9946C86.9745 64.248 87.1952 64.526 87.1952 64.8121C87.1952 65.0573 87.0317 65.3189 86.6966 65.5805L87.5794 65.6458V66.0873L86.7456 66.5532ZM84.1789 66.0219L85.3397 65.3516C85.732 65.1227 85.7974 64.9102 85.3233 64.6404L84.7756 64.3216C84.1952 63.9864 83.7865 64.011 83.2552 64.3216L82.2416 64.9102L84.1789 66.0219ZM81.3424 64.387L82.2906 63.8393C82.7239 63.5859 82.8301 63.3407 82.3315 63.0464L81.98 62.842C81.5304 62.5805 81.1544 62.5723 80.7375 62.8175L79.683 63.4306L81.3424 64.387Z" fill="#B88325"/>
        <path d="M87.1787 64.3477H86.8435V64.7891H87.1787V64.3477Z" fill="#B88325"/>
        <path d="M83.9338 62.5977H83.5986V63.0391H83.9338V62.5977Z" fill="#B88325"/>
        <path d="M86.7456 66.1051L85.8955 65.6146L85.405 65.9007L86.2552 66.3912L85.4296 66.8735L84.5794 66.383L83.9091 66.7754L77.6231 63.1459L78.2934 62.7536L77.4187 62.2468L78.2443 61.7645L79.119 62.2713L79.6094 61.9852L78.7348 61.4784L79.5604 60.9961L80.4841 61.5274C81.4323 61.1351 82.2579 61.1923 83.059 61.6582L83.2879 61.789C83.9337 62.165 84.1135 62.5329 83.7947 62.9579C84.6285 62.7127 85.3478 62.8435 86.0181 63.2277L86.5331 63.522C86.9745 63.7754 87.1952 64.0533 87.1952 64.3394C87.1952 64.5846 87.0317 64.8462 86.6966 65.1078L87.5794 65.6228L86.7456 66.1051ZM84.1789 65.5737L85.3397 64.9034C85.732 64.6746 85.7974 64.462 85.3233 64.1923L84.7756 63.8735C84.1952 63.5383 83.7865 63.5628 83.2552 63.8735L82.2416 64.462L84.1789 65.5737ZM81.3424 63.9389L82.2906 63.3912C82.7239 63.1378 82.8301 62.8925 82.3315 62.5983L81.98 62.3939C81.5304 62.1323 81.1544 62.1242 80.7375 62.3694L79.683 62.9825L81.3424 63.9389Z" fill="#F9B233"/>
        <path d="M90.8494 61.9905L90.8412 63.7071C90.8412 64.9414 90.0319 66.1675 88.4134 67.1076C85.16 68.9959 79.8712 68.9959 76.6015 67.1076C74.9502 66.1594 74.1328 64.9087 74.1328 63.658L74.141 61.9414C74.141 63.1839 74.9584 64.4346 76.6096 65.391C79.8794 67.2793 85.1764 67.2793 88.4216 65.391C90.0319 64.4509 90.8412 63.2248 90.8494 61.9905Z" fill="#B88325"/>
        <path d="M90.8444 61.9856C90.852 59.313 87.1179 57.1357 82.504 57.1226C77.8901 57.1095 74.1437 59.2655 74.1361 61.9381C74.1285 64.6108 77.8626 66.788 82.4765 66.8011C87.0904 66.8142 90.8369 64.6582 90.8444 61.9856Z" fill="#F9B233"/>
        <path d="M82.5197 65.9288C80.4352 65.9288 78.4897 65.471 77.0429 64.629C75.735 63.877 75.0156 62.9206 75.0156 61.956C75.0156 60.9915 75.7186 60.0514 77.0102 59.3075C78.4489 58.4738 80.3943 58.0078 82.4706 58.0078C84.5551 58.0078 86.5006 58.4656 87.9475 59.3075C89.2554 60.0596 89.9747 61.016 89.9747 61.9806C89.9747 62.9451 89.2717 63.8852 87.9801 64.629C86.5333 65.471 84.596 65.9288 82.5197 65.9288Z" fill="#B88325"/>
        <path d="M82.5197 66.3389C80.4352 66.3389 78.4897 65.8812 77.0429 65.0392C75.735 64.2872 75.0156 63.3308 75.0156 62.3662C75.0156 61.4016 75.7186 60.4616 77.0102 59.7177C78.4489 58.8839 80.3943 58.418 82.4706 58.418C84.5551 58.418 86.5006 58.8757 87.9475 59.7177C89.2554 60.4697 89.9747 61.4261 89.9747 62.3907C89.9747 63.3553 89.2717 64.2953 87.9801 65.0392C86.5333 65.8812 84.596 66.3389 82.5197 66.3389Z" fill="#FFCA38"/>
        <path d="M86.7459 64.5024L85.8957 64.012L85.4053 64.2981L86.2554 64.3471V64.7885L85.4298 65.2708L84.5797 64.7803L83.9094 65.1727L77.6233 61.5433V61.1019L78.2936 61.1591L77.4189 60.6523V60.2109L78.2446 60.1782L79.1192 60.685L79.6097 60.3989L78.735 59.8921V59.4507L79.5606 59.418L80.4843 59.9493C81.4326 59.5569 82.2582 59.6142 83.0592 60.0801L83.2881 60.2109C83.9339 60.5869 84.1137 60.9547 83.7949 61.3798C84.6287 61.1346 85.3481 61.2654 86.0184 61.6496L86.5333 61.9438C86.9748 62.1972 87.1955 62.4752 87.1955 62.7613C87.1955 63.0065 87.032 63.2681 86.6968 63.5297L87.5797 63.5951V64.0365L86.7459 64.5024ZM84.1791 63.9711L85.3399 63.3008C85.7323 63.0719 85.7976 62.8594 85.3235 62.5896L84.7759 62.2708C84.1955 61.9357 83.7868 61.9602 83.2554 62.2708L82.2418 62.8594L84.1791 63.9711ZM81.3426 62.3362L82.2909 61.7885C82.7241 61.5351 82.8304 61.2899 82.3317 60.9956L81.9802 60.7913C81.5306 60.5297 81.1546 60.5215 80.7377 60.7667L79.6832 61.3798L81.3426 62.3362Z" fill="#B88325"/>
        <path d="M87.1789 62.2969H86.8438V62.7383H87.1789V62.2969Z" fill="#B88325"/>
        <path d="M83.934 60.5469H83.5989V60.9883H83.934V60.5469Z" fill="#B88325"/>
        <path d="M86.7459 64.0621L85.8957 63.5716L85.4053 63.8577L86.2554 64.3482L85.4298 64.8305L84.5797 64.34L83.9094 64.7324L77.6233 61.103L78.2936 60.7106L77.4189 60.2038L78.2446 59.7215L79.1192 60.2283L79.6097 59.9422L78.735 59.4354L79.5606 58.9531L80.4843 59.4845C81.4326 59.0921 82.2582 59.1493 83.0592 59.6152L83.2881 59.746C83.9339 60.1221 84.1137 60.4899 83.7949 60.915C84.6287 60.6697 85.3481 60.8005 86.0184 61.1847L86.5333 61.479C86.9748 61.7324 87.1955 62.0103 87.1955 62.2964C87.1955 62.5417 87.032 62.8032 86.6968 63.0648L87.5797 63.5798L86.7459 64.0621ZM84.1791 63.5308L85.3399 62.8605C85.7323 62.6316 85.7976 62.419 85.3235 62.1493L84.7759 61.8305C84.1955 61.4953 83.7868 61.5199 83.2554 61.8305L82.2418 62.4191L84.1791 63.5308ZM81.3426 61.8959L82.2909 61.3482C82.7241 61.0948 82.8304 60.8496 82.3317 60.5553L81.9802 60.3509C81.5306 60.0894 81.1546 60.0812 80.7377 60.3264L79.6832 60.9395L81.3426 61.8959Z" fill="#F9B233"/>
        <path d="M90.8498 59.9436L90.8417 61.6602C90.8417 62.8945 90.0324 64.1207 88.4139 65.0607C85.1605 66.949 79.8717 66.949 76.6019 65.0607C74.9507 64.1125 74.1333 62.8618 74.1333 61.6111L74.1415 59.8945C74.1415 61.137 74.9589 62.3877 76.6101 63.3441C79.8799 65.2324 85.1768 65.2324 88.4221 63.3441C90.0324 62.3959 90.8417 61.1697 90.8498 59.9436Z" fill="#B88325"/>
        <path d="M90.8491 59.9348C90.8567 57.2622 87.1225 55.085 82.5086 55.0719C77.8947 55.0588 74.1483 57.2147 74.1407 59.8874C74.1331 62.56 77.8673 64.7372 82.4811 64.7503C87.095 64.7634 90.8415 62.6074 90.8491 59.9348Z" fill="#F9B233"/>
        <path d="M82.5204 63.8819C80.4359 63.8819 78.4905 63.4241 77.0436 62.5822C75.7357 61.8301 75.0164 60.8737 75.0164 59.9092C75.0164 58.9446 75.7194 58.0045 77.0109 57.2607C78.4496 56.4269 80.3951 55.9609 82.4714 55.9609C84.5558 55.9609 86.5013 56.4187 87.9482 57.2607C89.2561 58.0127 89.9754 58.9691 89.9754 59.9337C89.9754 60.8982 89.2724 61.8383 87.9809 62.5822C86.534 63.416 84.5967 63.8819 82.5204 63.8819Z" fill="#B88325"/>
        <path d="M82.5204 64.292C80.4359 64.292 78.4905 63.8343 77.0436 62.9923C75.7357 62.2403 75.0164 61.2839 75.0164 60.3193C75.0164 59.3547 75.7194 58.4147 77.0109 57.6708C78.4496 56.837 80.3951 56.3711 82.4714 56.3711C84.5558 56.3711 86.5013 56.8289 87.9482 57.6708C89.2561 58.4229 89.9754 59.3793 89.9754 60.3438C89.9754 61.3084 89.2724 62.2485 87.9809 62.9923C86.534 63.8261 84.5967 64.292 82.5204 64.292Z" fill="#FFCA38"/>
        <path d="M86.7461 62.4595L85.896 61.969L85.4055 62.2551L86.2557 62.3041V62.7456L85.43 63.2278L84.5799 62.7374L83.9096 63.1297L77.6235 59.5003V59.0589L78.2939 59.1161L77.4192 58.6093V58.1679L78.2448 58.1352L79.1194 58.642L79.6099 58.3559L78.7352 57.8491V57.4077L79.5609 57.375L80.4846 57.9063C81.4328 57.514 82.2584 57.5712 83.0595 58.0371L83.2884 58.1679C83.9341 58.5439 84.114 58.9118 83.7952 59.3368C84.629 59.0916 85.3483 59.2224 86.0186 59.6066L86.5336 59.9009C86.975 60.1543 87.1957 60.4322 87.1957 60.7183C87.1957 60.9635 87.0322 61.2251 86.6971 61.4867L87.5799 61.5521V61.9935L86.7461 62.4595ZM84.1794 61.9281L85.3401 61.2578C85.7325 61.0289 85.7979 60.8164 85.3238 60.5466L84.7761 60.2278C84.1957 59.8927 83.787 59.9172 83.2557 60.2278L82.2421 60.8164L84.1794 61.9281ZM81.3429 60.2932L82.2911 59.7456C82.7243 59.4922 82.8306 59.2469 82.332 58.9527L81.9805 58.7483C81.5309 58.4867 81.1549 58.4785 80.738 58.7238L79.6835 59.3368L81.3429 60.2932Z" fill="#B88325"/>
        <path d="M87.1791 60.2539H86.844V60.6953H87.1791V60.2539Z" fill="#B88325"/>
        <path d="M83.9343 58.5039H83.5991V58.9453H83.9343V58.5039Z" fill="#B88325"/>
        <path d="M86.7461 62.0113L85.896 61.5209L85.4055 61.807L86.2557 62.2974L85.43 62.7797L84.5799 62.2892L83.9096 62.6816L77.6235 59.0522L78.2939 58.6598L77.4192 58.153L78.2448 57.6707L79.1194 58.1775L79.6099 57.8914L78.7352 57.3846L79.5609 56.9023L80.4846 57.4337C81.4328 57.0413 82.2584 57.0985 83.0595 57.5645L83.2884 57.6953C83.9341 58.0713 84.114 58.4391 83.7952 58.8642C84.629 58.619 85.3483 58.7498 86.0186 59.1339L86.5336 59.4282C86.975 59.6816 87.1957 59.9596 87.1957 60.2457C87.1957 60.4909 87.0322 60.7525 86.6971 61.014L87.5799 61.529L86.7461 62.0113ZM84.1794 61.48L85.3401 60.8097C85.7325 60.5808 85.7979 60.3683 85.3238 60.0985L84.7761 59.7797C84.1957 59.4446 83.787 59.4691 83.2557 59.7797L82.2421 60.3683L84.1794 61.48ZM81.3429 59.8451L82.2911 59.2974C82.7243 59.044 82.8306 58.7988 82.332 58.5045L81.9805 58.3002C81.5309 58.0386 81.1549 58.0304 80.738 58.2756L79.6835 58.8887L81.3429 59.8451Z" fill="#F9B233"/>
        <path d="M90.8494 57.8889L90.8412 59.6055C90.8412 60.8398 90.0319 62.066 88.4134 63.006C85.16 64.8943 79.8712 64.8943 76.6015 63.006C74.9502 62.0578 74.1328 60.8071 74.1328 59.5565L74.141 57.8398C74.141 59.0823 74.9584 60.333 76.6096 61.2894C79.8794 63.1777 85.1764 63.1777 88.4216 61.2894C90.0319 60.3494 90.8412 59.1232 90.8494 57.8889Z" fill="#B88325"/>
        <path d="M88.3806 54.4458C91.6503 56.3341 91.6748 59.3994 88.4214 61.2877C85.168 63.176 79.8792 63.176 76.6095 61.2877C73.3398 59.3994 73.3152 56.3341 76.5686 54.4458C79.8138 52.5493 85.1027 52.5493 88.3806 54.4458Z" fill="#F9B233"/>
        <path d="M82.5194 61.8272C80.435 61.8272 78.4895 61.3694 77.0426 60.5275C75.7347 59.7754 75.0154 58.819 75.0154 57.8545C75.0154 56.8899 75.7184 55.9498 77.0099 55.206C78.4486 54.3722 80.3941 53.9062 82.4704 53.9062C84.5549 53.9062 86.5004 54.364 87.9472 55.206C89.2551 55.958 89.9744 56.9144 89.9744 57.879C89.9744 58.8436 89.2714 59.7836 87.9799 60.5275C86.533 61.3694 84.5957 61.8272 82.5194 61.8272Z" fill="#B88325"/>
        <path d="M82.5194 62.2374C80.435 62.2374 78.4895 61.7796 77.0426 60.9376C75.7347 60.1856 75.0154 59.2292 75.0154 58.2646C75.0154 57.3 75.7184 56.36 77.0099 55.6161C78.4486 54.7823 80.3941 54.3164 82.4704 54.3164C84.5549 54.3164 86.5004 54.7742 87.9472 55.6161C89.2551 56.3682 89.9744 57.3246 89.9744 58.2891C89.9744 59.2537 89.2714 60.1938 87.9799 60.9376C86.533 61.7796 84.5957 62.2374 82.5194 62.2374Z" fill="#FFCA38"/>
        <path d="M79.0293 87.467L79.0211 89.1836C79.0211 90.418 78.2119 91.6441 76.5933 92.5842C73.34 94.4724 68.0512 94.4724 64.7814 92.5842C63.1384 91.6359 62.3127 90.3853 62.3127 89.1346L62.3209 87.418C62.3209 88.6605 63.1384 89.9111 64.7896 90.8675C68.0593 92.7558 73.3563 92.7558 76.6015 90.8675C78.2201 89.9275 79.0293 88.7014 79.0293 87.467Z" fill="#B88325"/>
        <path d="M76.569 84.0256C79.8388 85.9139 79.8633 88.9792 76.6099 90.8675C73.3565 92.7558 68.0677 92.7558 64.798 90.8675C61.5282 88.9792 61.5037 85.9139 64.7571 84.0256C68.0023 82.1373 73.2911 82.1373 76.569 84.0256Z" fill="#F9B233"/>
        <path d="M70.6999 91.4053C68.6154 91.4053 66.6699 90.9476 65.223 90.1056C63.9151 89.3536 63.1958 88.3972 63.1958 87.4326C63.1958 86.468 63.8988 85.528 65.1903 84.7841C66.629 83.9503 68.5745 83.4844 70.6508 83.4844C72.7353 83.4844 74.6808 83.9421 76.1276 84.7841C77.4355 85.5361 78.1549 86.4925 78.1549 87.4571C78.1549 88.4217 77.4519 89.3617 76.1603 90.1056C74.7216 90.9476 72.7843 91.4053 70.6999 91.4053Z" fill="#B88325"/>
        <path d="M70.6999 91.8155C68.6154 91.8155 66.6699 91.3577 65.223 90.5158C63.9151 89.7637 63.1958 88.8073 63.1958 87.8427C63.1958 86.8782 63.8988 85.9381 65.1903 85.1943C66.629 84.3605 68.5745 83.8945 70.6508 83.8945C72.7353 83.8945 74.6808 84.3523 76.1276 85.1943C77.4355 85.9463 78.1549 86.9027 78.1549 87.8673C78.1549 88.8318 77.4519 89.7719 76.1603 90.5158C74.7216 91.3577 72.7843 91.8155 70.6999 91.8155Z" fill="#FFCA38"/>
        <path d="M74.926 89.9907L74.0759 89.5002L73.5855 89.7863L74.4356 89.8354V90.2768L73.61 90.7591L72.7598 90.2686L72.0896 90.661L65.8035 87.0316V86.5902L66.4738 86.6474L65.5991 86.1406V85.6992L66.4247 85.6665L67.2994 86.1733L67.7898 85.8872L66.9152 85.3804V84.9389L67.7408 84.9062L68.6645 85.4376C69.6127 85.0452 70.4383 85.1024 71.2394 85.5684L71.4683 85.6992C72.1141 86.0752 72.2939 86.443 71.9751 86.8681C72.8089 86.6229 73.5282 86.7537 74.1985 87.1378L74.7135 87.4321C75.1549 87.6855 75.3756 87.9635 75.3756 88.2496C75.3756 88.4948 75.2121 88.7564 74.877 89.018L75.7598 89.0833V89.5248L74.926 89.9907ZM72.3593 89.4594L73.5201 88.7891C73.9124 88.5602 73.9778 88.3477 73.5037 88.0779L72.956 87.7591C72.3757 87.424 71.9669 87.4485 71.4356 87.7591L70.422 88.3476L72.3593 89.4594ZM69.531 87.8245L70.4792 87.2768C70.9125 87.0234 71.0187 86.7782 70.5119 86.4839L70.1604 86.2795C69.7108 86.018 69.3348 86.0098 68.9179 86.255L67.8634 86.8681L69.531 87.8245Z" fill="#B88325"/>
        <path d="M75.3676 87.7852H75.0325V88.2266H75.3676V87.7852Z" fill="#B88325"/>
        <path d="M72.1223 86.0352H71.7871V86.4766H72.1223V86.0352Z" fill="#B88325"/>
        <path d="M74.926 89.5426L74.0759 89.0521L73.5855 89.3382L74.4356 89.8287L73.61 90.311L72.7598 89.8205L72.0896 90.2129L65.8035 86.5835L66.4738 86.1911L65.5991 85.6843L66.4247 85.202L67.2994 85.7088L67.7898 85.4227L66.9152 84.9159L67.7408 84.4336L68.6645 84.9649C69.6127 84.5726 70.4383 84.6298 71.2394 85.0957L71.4683 85.2265C72.1141 85.6025 72.2939 85.9704 71.9751 86.3954C72.8089 86.1502 73.5282 86.281 74.1985 86.6652L74.7135 86.9595C75.1549 87.2129 75.3756 87.4908 75.3756 87.7769C75.3756 88.0221 75.2121 88.2837 74.877 88.5453L75.7598 89.0603L74.926 89.5426ZM72.3593 89.0112L73.5201 88.3409C73.9124 88.1121 73.9778 87.8995 73.5037 87.6298L72.956 87.311C72.3757 86.9758 71.9669 87.0003 71.4356 87.311L70.422 87.8995L72.3593 89.0112ZM69.531 87.3764L70.4792 86.8287C70.9125 86.5753 71.0187 86.33 70.5119 86.0358L70.1604 85.8314C69.7108 85.5698 69.3348 85.5617 68.9179 85.8069L67.8634 86.42L69.531 87.3764Z" fill="#F9B233"/>
        <path d="M79.0298 85.4202L79.0216 87.1368C79.0216 88.3711 78.2124 89.5972 76.5938 90.5373C73.3404 92.4256 68.0516 92.4256 64.7819 90.5373C63.1389 89.5891 62.3132 88.3384 62.3132 87.0877L62.3214 85.3711C62.3214 86.6136 63.1388 87.8643 64.7901 88.8207C68.0598 90.7089 73.3568 90.7089 76.602 88.8207C78.2205 87.8806 79.0298 86.6545 79.0298 85.4202Z" fill="#B88325"/>
        <path d="M76.5693 81.9709C79.839 83.8592 79.8636 86.9246 76.6102 88.8128C73.3568 90.7011 68.068 90.7011 64.7982 88.8128C61.5285 86.9246 61.504 83.8592 64.7573 81.9709C68.0026 80.0826 73.2914 80.0826 76.5693 81.9709Z" fill="#F9B233"/>
        <path d="M70.7001 89.3584C68.6156 89.3584 66.6701 88.9007 65.2233 88.0587C63.9154 87.3067 63.196 86.3503 63.196 85.3857C63.196 84.4211 63.899 83.4811 65.1906 82.7372C66.6293 81.9034 68.5748 81.4375 70.6511 81.4375C72.7355 81.4375 74.681 81.8953 76.1279 82.7372C77.4358 83.4893 78.1551 84.4457 78.1551 85.4102C78.1551 86.3748 77.4521 87.3149 76.1606 88.0587C74.7219 88.9007 72.7846 89.3584 70.7001 89.3584Z" fill="#B88325"/>
        <path d="M70.7001 89.7686C68.6156 89.7686 66.6701 89.3108 65.2233 88.4689C63.9154 87.7168 63.196 86.7604 63.196 85.7959C63.196 84.8313 63.899 83.8912 65.1906 83.1474C66.6293 82.3136 68.5748 81.8477 70.6511 81.8477C72.7355 81.8477 74.681 82.3054 76.1279 83.1474C77.4358 83.8994 78.1551 84.8558 78.1551 85.8204C78.1551 86.785 77.4521 87.725 76.1606 88.4689C74.7219 89.3108 72.7846 89.7686 70.7001 89.7686Z" fill="#FFCA38"/>
        <path d="M74.926 87.9399L74.0759 87.4495L73.5855 87.7356L74.4356 87.7846V88.226L73.61 88.7083L72.7598 88.2179L72.0896 88.6102L65.8035 84.9808V84.5394L66.4738 84.5966L65.5991 84.0898V83.6484L66.4247 83.6157L67.2994 84.1225L67.7898 83.8364L66.9152 83.3296V82.8882L67.7408 82.8555L68.6645 83.3868C69.6127 82.9944 70.4383 83.0517 71.2394 83.5176L71.4683 83.6484C72.1141 84.0244 72.2939 84.3922 71.9751 84.8173C72.8089 84.5721 73.5282 84.7029 74.1985 85.0871L74.7135 85.3813C75.1549 85.6348 75.3756 85.9127 75.3756 86.1988C75.3756 86.444 75.2121 86.7056 74.877 86.9672L75.7598 87.0326V87.474L74.926 87.9399ZM72.3593 87.4086L73.5201 86.7383C73.9124 86.5094 73.9778 86.2969 73.5037 86.0271L72.956 85.7083C72.3757 85.3732 71.9669 85.3977 71.4356 85.7083L70.422 86.2969L72.3593 87.4086ZM69.531 85.7737L70.4792 85.226C70.9125 84.9726 71.0187 84.7274 70.5119 84.4331L70.1604 84.2288C69.7108 83.9672 69.3348 83.959 68.9179 84.2042L67.8634 84.8173L69.531 85.7737Z" fill="#B88325"/>
        <path d="M75.3674 85.7344H75.0322V86.1758H75.3674V85.7344Z" fill="#B88325"/>
        <path d="M72.122 83.9844H71.7869V84.4258H72.122V83.9844Z" fill="#B88325"/>
        <path d="M74.926 87.4996L74.0759 87.0091L73.5855 87.2952L74.4356 87.7857L73.61 88.268L72.7598 87.7775L72.0896 88.1699L65.8035 84.5405L66.4738 84.1481L65.5991 83.6413L66.4247 83.159L67.2994 83.6658L67.7898 83.3797L66.9152 82.8729L67.7408 82.3906L68.6645 82.922C69.6127 82.5296 70.4383 82.5868 71.2394 83.0527L71.4683 83.1835C72.1141 83.5596 72.2939 83.9274 71.9751 84.3525C72.8089 84.1072 73.5282 84.238 74.1985 84.6222L74.7135 84.9165C75.1549 85.1699 75.3756 85.4478 75.3756 85.7339C75.3756 85.9792 75.2121 86.2407 74.877 86.5023L75.7598 87.0173L74.926 87.4996ZM72.3593 86.9683L73.5201 86.298C73.9124 86.0691 73.9778 85.8566 73.5037 85.5868L72.956 85.268C72.3757 84.9329 71.9669 84.9574 71.4356 85.268L70.422 85.8566L72.3593 86.9683ZM69.531 85.3334L70.4792 84.7857C70.9125 84.5323 71.0187 84.2871 70.5119 83.9928L70.1604 83.7884C69.7108 83.5269 69.3348 83.5187 68.9179 83.7639L67.8634 84.377L69.531 85.3334Z" fill="#F9B233"/>
        <path d="M79.0298 83.3733L79.0216 85.0899C79.0216 86.3242 78.2124 87.5504 76.5938 88.4904C73.3404 90.3787 68.0516 90.3787 64.7819 88.4904C63.1389 87.5422 62.3132 86.2915 62.3132 85.0408L62.3214 83.3242C62.3214 84.5667 63.1388 85.8174 64.7901 86.7738C68.0598 88.6621 73.3568 88.6621 76.602 86.7738C78.2205 85.8337 79.0298 84.6076 79.0298 83.3733Z" fill="#B88325"/>
        <path d="M76.5693 79.9318C79.839 81.8201 79.8636 84.8855 76.6102 86.7738C73.3568 88.662 68.068 88.662 64.7982 86.7738C61.5285 84.8855 61.504 81.8201 64.7573 79.9318C68.0026 78.0436 73.2914 78.0436 76.5693 79.9318Z" fill="#F9B233"/>
        <path d="M70.7001 87.3194C68.6156 87.3194 66.6701 86.8616 65.2233 86.0197C63.9154 85.2676 63.196 84.3112 63.196 83.3466C63.196 82.3821 63.899 81.442 65.1906 80.6982C66.6293 79.8644 68.5748 79.3984 70.6511 79.3984C72.7355 79.3984 74.681 79.8562 76.1279 80.6982C77.4358 81.4502 78.1551 82.4066 78.1551 83.3712C78.1551 84.3357 77.4521 85.2758 76.1606 86.0197C74.7219 86.8534 72.7846 87.3194 70.7001 87.3194Z" fill="#B88325"/>
        <path d="M70.7001 87.7217C68.6156 87.7217 66.6701 87.264 65.2233 86.422C63.9154 85.67 63.196 84.7136 63.196 83.749C63.196 82.7844 63.899 81.8444 65.1906 81.1005C66.6293 80.2667 68.5748 79.8008 70.6511 79.8008C72.7355 79.8008 74.681 80.2585 76.1279 81.1005C77.4358 81.8525 78.1551 82.8089 78.1551 83.7735C78.1551 84.7381 77.4521 85.6781 76.1606 86.422C74.7219 87.264 72.7846 87.7217 70.7001 87.7217Z" fill="#FFCA38"/>
        <path d="M74.926 85.893L74.0759 85.4026L73.5855 85.6887L74.4356 85.7377V86.1791L73.61 86.6614L72.7598 86.171L72.0896 86.5633L65.8035 82.9339V82.4925L66.4738 82.5497L65.5991 82.0429V81.6015L66.4247 81.5688L67.2994 82.0756L67.7898 81.7895L66.9152 81.2827V80.8413L67.7408 80.8086L68.6645 81.3399C69.6127 80.9476 70.4383 81.0048 71.2394 81.4707L71.4683 81.6015C72.1141 81.9775 72.2939 82.3454 71.9751 82.7704C72.8089 82.5252 73.5282 82.656 74.1985 83.0402L74.7135 83.3345C75.1549 83.5879 75.3756 83.8658 75.3756 84.1519C75.3756 84.3971 75.2121 84.6587 74.877 84.9203L75.7598 84.9857V85.4271L74.926 85.893ZM72.3593 85.3617L73.5201 84.6914C73.9124 84.4625 73.9778 84.25 73.5037 83.9802L72.956 83.6614C72.3757 83.3263 71.9669 83.3508 71.4356 83.6614L70.422 84.25L72.3593 85.3617ZM69.531 83.7268L70.4792 83.1792C70.9125 82.9258 71.0187 82.6805 70.5119 82.3862L70.1604 82.1819C69.7108 81.9203 69.3348 81.9121 68.9179 82.1574L67.8634 82.7704L69.531 83.7268Z" fill="#B88325"/>
        <path d="M75.3674 83.6875H75.0322V84.1289H75.3674V83.6875Z" fill="#B88325"/>
        <path d="M72.122 81.9375H71.7869V82.3789H72.122V81.9375Z" fill="#B88325"/>
        <path d="M74.926 85.4449L74.0759 84.9545L73.5855 85.2406L74.4356 85.731L73.61 86.2133L72.7598 85.7228L72.0896 86.1152L65.8035 82.4858L66.4738 82.0934L65.5991 81.5866L66.4247 81.1043L67.2994 81.6111L67.7898 81.325L66.9152 80.8182L67.7408 80.3359L68.6645 80.8673C69.6127 80.4749 70.4383 80.5321 71.2394 80.9981L71.4683 81.1289C72.1141 81.5049 72.2939 81.8727 71.9751 82.2978C72.8089 82.0526 73.5282 82.1833 74.1985 82.5675L74.7135 82.8618C75.1549 83.1152 75.3756 83.3932 75.3756 83.6793C75.3756 83.9245 75.2121 84.1861 74.877 84.4476L75.7598 84.9626L74.926 85.4449ZM72.3593 84.9136L73.5201 84.2433C73.9124 84.0144 73.9778 83.8019 73.5037 83.5321L72.956 83.2133C72.3757 82.8782 71.9669 82.9027 71.4356 83.2133L70.422 83.8019L72.3593 84.9136ZM69.531 83.2787L70.4792 82.731C70.9125 82.4776 71.0187 82.2324 70.5119 81.9381L70.1604 81.7338C69.7108 81.4722 69.3348 81.464 68.9179 81.7092L67.8634 82.3223L69.531 83.2787Z" fill="#F9B233"/>
        <path d="M79.0298 81.3225L79.0216 83.0391C79.0216 84.2734 78.2124 85.4996 76.5938 86.4396C73.3404 88.3279 68.0516 88.3279 64.7819 86.4396C63.1389 85.4914 62.3132 84.2407 62.3132 82.9901L62.3214 81.2734C62.3214 82.5241 63.1388 83.7666 64.7901 84.723C68.0598 86.6113 73.3568 86.6113 76.602 84.723C78.2205 83.783 79.0298 82.5568 79.0298 81.3225Z" fill="#B88325"/>
        <path d="M76.5693 77.8732C79.839 79.7615 79.8636 82.8269 76.6102 84.7152C73.3568 86.6035 68.068 86.6035 64.7982 84.7152C61.5285 82.8269 61.504 79.7615 64.7573 77.8732C68.0026 75.985 73.2914 75.985 76.5693 77.8732Z" fill="#F9B233"/>
        <path d="M70.7001 85.2608C68.6156 85.2608 66.6701 84.803 65.2233 83.9611C63.9154 83.209 63.196 82.2526 63.196 81.2881C63.196 80.3235 63.899 79.3834 65.1906 78.6396C66.6293 77.8058 68.5748 77.3398 70.6511 77.3398C72.7355 77.3398 74.681 77.7976 76.1279 78.6396C77.4358 79.3916 78.1551 80.348 78.1551 81.3126C78.1551 82.2772 77.4521 83.2172 76.1606 83.9611C74.7219 84.803 72.7846 85.2608 70.7001 85.2608Z" fill="#B88325"/>
        <path d="M70.7001 85.671C68.6156 85.671 66.6701 85.2132 65.2233 84.3712C63.9154 83.6192 63.196 82.6628 63.196 81.6982C63.196 80.7336 63.899 79.7936 65.1906 79.0497C66.6293 78.2159 68.5748 77.75 70.6511 77.75C72.7355 77.75 74.681 78.2078 76.1279 79.0497C77.4358 79.8018 78.1551 80.7582 78.1551 81.7227C78.1551 82.6873 77.4521 83.6274 76.1606 84.3712C74.7219 85.2132 72.7846 85.671 70.7001 85.671Z" fill="#FFCA38"/>
        <path d="M74.9258 83.8423L74.0757 83.3518L73.5852 83.6379L74.4354 83.6869V84.1284L73.6097 84.6107L72.7596 84.1202L72.0893 84.5126L65.8032 80.8832V80.4417L66.4735 80.499L65.5989 79.9921V79.5507L66.4245 79.518L67.2991 80.0248L67.7896 79.7387L66.9149 79.2319V78.7905L67.7406 78.7578L68.6643 79.2891C69.6125 78.8968 70.4381 78.954 71.2392 79.4199L71.4681 79.5507C72.1138 79.9267 72.2937 80.2946 71.9749 80.7197C72.8086 80.4744 73.528 80.6052 74.1983 80.9894L74.7133 81.2837C75.1547 81.5371 75.3754 81.815 75.3754 82.1011C75.3754 82.3464 75.2119 82.6079 74.8768 82.8695L75.7596 82.9349V83.3763L74.9258 83.8423ZM72.3591 83.3109L73.5198 82.6406C73.9122 82.4118 73.9776 82.1992 73.5035 81.9295L72.9558 81.6107C72.3754 81.2755 71.9667 81.3 71.4354 81.6107L70.4217 82.1992L72.3591 83.3109ZM69.5307 81.6761L70.479 81.1284C70.9122 80.875 71.0185 80.6297 70.5116 80.3355L70.1602 80.1311C69.7106 79.8695 69.3346 79.8614 68.9177 80.1066L67.8632 80.7197L69.5307 81.6761Z" fill="#B88325"/>
        <path d="M75.3674 81.6367H75.0322V82.0781H75.3674V81.6367Z" fill="#B88325"/>
        <path d="M72.122 79.8867H71.7869V80.3281H72.122V79.8867Z" fill="#B88325"/>
        <path d="M74.9258 83.4019L74.0757 82.9115L73.5852 83.1976L74.4354 83.688L73.6097 84.1703L72.7596 83.6799L72.0893 84.0722L65.8032 80.4428L66.4735 80.0505L65.5989 79.5437L66.4245 79.0614L67.2991 79.5682L67.7896 79.2821L66.9149 78.7753L67.7406 78.293L68.6643 78.8243C69.6125 78.4319 70.4381 78.4892 71.2392 78.9551L71.4681 79.0859C72.1138 79.4619 72.2937 79.8297 71.9749 80.2548C72.8086 80.0096 73.528 80.1404 74.1983 80.5246L74.7133 80.8188C75.1547 81.0723 75.3754 81.3502 75.3754 81.6363C75.3754 81.8815 75.2119 82.1431 74.8768 82.4047L75.7596 82.9197L74.9258 83.4019ZM72.3591 82.8706L73.5198 82.2003C73.9122 81.9714 73.9776 81.7589 73.5035 81.4891L72.9558 81.1703C72.3754 80.8352 71.9667 80.8597 71.4354 81.1703L70.4217 81.7589L72.3591 82.8706ZM69.5307 81.2357L70.479 80.6881C70.9122 80.4347 71.0185 80.1894 70.5116 79.8951L70.1602 79.6908C69.7106 79.4292 69.3346 79.421 68.9177 79.6663L67.8632 80.2793L69.5307 81.2357Z" fill="#F9B233"/>
        <path d="M79.0298 79.2795L79.0216 80.9961C79.0216 82.2305 78.2124 83.4566 76.5938 84.3967C73.3404 86.2849 68.0516 86.2849 64.7819 84.3967C63.1389 83.4484 62.3132 82.1978 62.3132 80.9471L62.3214 79.2305C62.3214 80.4811 63.1388 81.7236 64.7901 82.68C68.0598 84.5683 73.3568 84.5683 76.602 82.68C78.2205 81.7318 79.0298 80.5057 79.0298 79.2795Z" fill="#B88325"/>
        <path d="M76.5693 75.8303C79.839 77.7185 79.8636 80.7839 76.6102 82.6722C73.3568 84.5605 68.068 84.5605 64.7982 82.6722C61.5285 80.7839 61.504 77.7185 64.7573 75.8303C68.0026 73.942 73.2914 73.942 76.5693 75.8303Z" fill="#F9B233"/>
        <path d="M70.7001 83.2178C68.6156 83.2178 66.6701 82.7601 65.2233 81.9181C63.9154 81.1661 63.196 80.2097 63.196 79.2451C63.196 78.2805 63.899 77.3405 65.1906 76.5966C66.6293 75.7628 68.5748 75.2969 70.6511 75.2969C72.7355 75.2969 74.681 75.7546 76.1279 76.5966C77.4358 77.3486 78.1551 78.305 78.1551 79.2696C78.1551 80.2342 77.4521 81.1742 76.1606 81.9181C74.7219 82.7519 72.7846 83.2178 70.7001 83.2178Z" fill="#B88325"/>
        <path d="M70.7001 83.628C68.6156 83.628 66.6701 83.1702 65.2233 82.3283C63.9154 81.5762 63.196 80.6198 63.196 79.6552C63.196 78.6907 63.899 77.7506 65.1906 77.0068C66.6293 76.173 68.5748 75.707 70.6511 75.707C72.7355 75.707 74.681 76.1648 76.1279 77.0068C77.4358 77.7588 78.1551 78.7152 78.1551 79.6798C78.1551 80.6443 77.4521 81.5844 76.1606 82.3283C74.7219 83.162 72.7846 83.628 70.7001 83.628Z" fill="#FFCA38"/>
        <path d="M74.9258 81.7993L74.0757 81.3088L73.5852 81.5949L74.4354 81.644V82.0854L73.6097 82.5677L72.7596 82.0772L72.0893 82.4696L65.8032 78.8402V78.3988L66.4735 78.456L65.5989 77.9492V77.5078L66.4245 77.4751L67.2991 77.9819L67.7896 77.6958L66.9149 77.189V76.7475L67.7406 76.7148L68.6643 77.2462C69.6125 76.8538 70.4381 76.911 71.2392 77.377L71.4681 77.5078C72.1138 77.8838 72.2937 78.2516 71.9749 78.6767C72.8086 78.4315 73.528 78.5623 74.1983 78.9464L74.7133 79.2407C75.1547 79.4941 75.3754 79.7721 75.3754 80.0582C75.3754 80.3034 75.2119 80.565 74.8768 80.8266L75.7596 80.8919V81.3334L74.9258 81.7993ZM72.3591 81.268L73.5198 80.5977C73.9122 80.3688 73.9776 80.1563 73.5035 79.8865L72.9558 79.5677C72.3754 79.2326 71.9667 79.2571 71.4354 79.5677L70.4217 80.1563L72.3591 81.268ZM69.5307 79.6331L70.479 79.0854C70.9122 78.832 71.0185 78.5868 70.5116 78.2925L70.1602 78.0881C69.7106 77.8266 69.3346 77.8184 68.9177 78.0636L67.8632 78.6767L69.5307 79.6331Z" fill="#B88325"/>
        <path d="M75.3671 79.5938H75.032V80.0352H75.3671V79.5938Z" fill="#B88325"/>
        <path d="M72.1218 77.8438H71.7866V78.2852H72.1218V77.8438Z" fill="#B88325"/>
        <path d="M74.9258 81.3512L74.0757 80.8607L73.5852 81.1468L74.4354 81.6373L73.6097 82.1195L72.7596 81.6291L72.0893 82.0215L65.8032 78.392L66.4735 77.9997L65.5989 77.4929L66.4245 77.0106L67.2991 77.5174L67.7896 77.2313L66.9149 76.7245L67.7406 76.2422L68.6643 76.7735C69.6125 76.3811 70.4381 76.4384 71.2392 76.9043L71.4681 77.0351C72.1138 77.4111 72.2937 77.779 71.9749 78.204C72.8086 77.9588 73.528 78.0896 74.1983 78.4738L74.7133 78.7681C75.1547 79.0215 75.3754 79.2994 75.3754 79.5855C75.3754 79.8307 75.2119 80.0923 74.8768 80.3539L75.7596 80.8689L74.9258 81.3512ZM72.3591 80.8198L73.5198 80.1495C73.9122 79.9206 73.9776 79.7081 73.5035 79.4384L72.9558 79.1196C72.3754 78.7844 71.9667 78.8089 71.4354 79.1196L70.4217 79.7081L72.3591 80.8198ZM69.5307 79.185L70.479 78.6373C70.9122 78.3839 71.0185 78.1386 70.5116 77.8444L70.1602 77.64C69.7106 77.3784 69.3346 77.3702 68.9177 77.6155L67.8632 78.2286L69.5307 79.185Z" fill="#F9B233"/>
        <path d="M79.0298 77.2366L79.0216 78.9532C79.0216 80.1875 78.2124 81.4136 76.5938 82.3537C73.3404 84.242 68.0516 84.242 64.7819 82.3537C63.1389 81.4055 62.3132 80.1548 62.3132 78.9041L62.3214 77.1875C62.3214 78.4382 63.1388 79.6807 64.7901 80.6371C68.0598 82.5254 73.3568 82.5254 76.602 80.6371C78.2205 79.697 79.0298 78.4709 79.0298 77.2366Z" fill="#B88325"/>
        <path d="M79.0302 77.2354C79.0377 74.5628 75.3036 72.3856 70.6897 72.3725C66.0758 72.3595 62.3294 74.5155 62.3218 77.1881C62.3142 79.8607 66.0484 82.0379 70.6623 82.051C75.2762 82.064 79.0226 79.908 79.0302 77.2354Z" fill="#F9B233"/>
        <path d="M70.7003 81.1749C68.6159 81.1749 66.6704 80.7171 65.2235 79.8751C63.9156 79.1231 63.1963 78.1667 63.1963 77.2021C63.1963 76.2375 63.8993 75.2975 65.1908 74.5536C66.6295 73.7198 68.575 73.2539 70.6513 73.2539C72.7358 73.2539 74.6812 73.7117 76.1281 74.5536C77.436 75.3057 78.1554 76.2621 78.1554 77.2266C78.1554 78.1912 77.4524 79.1313 76.1608 79.8751C74.7221 80.7171 72.7848 81.1749 70.7003 81.1749Z" fill="#B88325"/>
        <path d="M70.7003 81.585C68.6159 81.585 66.6704 81.1272 65.2235 80.2853C63.9156 79.5332 63.1963 78.5769 63.1963 77.6123C63.1963 76.6477 63.8993 75.7077 65.1908 74.9638C66.6295 74.13 68.575 73.6641 70.6513 73.6641C72.7358 73.6641 74.6812 74.1218 76.1281 74.9638C77.436 75.7158 78.1554 76.6722 78.1554 77.6368C78.1554 78.6014 77.4524 79.5414 76.1608 80.2853C74.7221 81.1272 72.7848 81.585 70.7003 81.585Z" fill="#FFCA38"/>
        <path d="M74.9263 79.7485L74.0762 79.258L73.5857 79.5442L74.4358 79.5932V80.0346L73.6102 80.5169L72.7601 80.0264L72.0898 80.4188L65.8037 76.7894V76.348L66.474 76.4052L65.5994 75.8984V75.457L66.425 75.4243L67.2996 75.9311L67.7901 75.645L66.9154 75.1382V74.6968L67.741 74.6641L68.6647 75.1954C69.613 74.803 70.4386 74.8602 71.2397 75.3262L71.4685 75.457C72.1143 75.833 72.2942 76.2008 71.9754 76.6259C72.8091 76.3807 73.5285 76.5115 74.1988 76.8957L74.7138 77.1899C75.1552 77.4433 75.3759 77.7213 75.3759 78.0074C75.3759 78.2526 75.2124 78.5142 74.8772 78.7758L75.7601 78.8412V79.2826L74.9263 79.7485ZM72.3595 79.2172L73.5203 78.5469C73.9127 78.318 73.9781 78.1055 73.504 77.8357L72.9563 77.5169C72.3759 77.1818 71.9672 77.2063 71.4358 77.5169L70.4222 78.1055L72.3595 79.2172ZM69.5312 77.5823L70.4795 77.0346C70.9127 76.7812 71.0189 76.536 70.5121 76.2417L70.1606 76.0374C69.7111 75.7758 69.335 75.7676 68.9182 76.0128L67.8637 76.6259L69.5312 77.5823Z" fill="#B88325"/>
        <path d="M75.3679 77.543H75.0327V77.9844H75.3679V77.543Z" fill="#B88325"/>
        <path d="M72.1225 75.793H71.7874V76.2344H72.1225V75.793Z" fill="#B88325"/>
        <path d="M74.9263 79.3082L74.0762 78.8177L73.5857 79.1038L74.4358 79.5943L73.6102 80.0766L72.7601 79.5861L72.0898 79.9785L65.8037 76.3491L66.474 75.9567L65.5994 75.4499L66.425 74.9676L67.2996 75.4744L67.7901 75.1883L66.9154 74.6815L67.741 74.1992L68.6647 74.7305C69.613 74.3382 70.4386 74.3954 71.2397 74.8613L71.4685 74.9921C72.1143 75.3681 72.2942 75.736 71.9754 76.1611C72.8091 75.9158 73.5285 76.0466 74.1988 76.4308L74.7138 76.7251C75.1552 76.9785 75.3759 77.2564 75.3759 77.5425C75.3759 77.7878 75.2124 78.0493 74.8772 78.3109L75.7601 78.8259L74.9263 79.3082ZM72.3595 78.7769L73.5203 78.1066C73.9127 77.8777 73.9781 77.6651 73.504 77.3954L72.9563 77.0766C72.3759 76.7414 71.9672 76.766 71.4358 77.0766L70.4222 77.6651L72.3595 78.7769ZM69.5312 77.142L70.4795 76.5943C70.9127 76.3409 71.0189 76.0957 70.5121 75.8014L70.1606 75.597C69.7111 75.3355 69.335 75.3273 68.9182 75.5725L67.8637 76.1856L69.5312 77.142Z" fill="#F9B233"/>
        <path d="M79.03 75.1897L79.0219 76.9063C79.0219 78.1406 78.2126 79.3668 76.5941 80.3068C73.3407 82.1951 68.0519 82.1951 64.7821 80.3068C63.1391 79.3586 62.3135 78.1079 62.3135 76.8572L62.3217 75.1406C62.3217 76.3831 63.1391 77.6338 64.7903 78.5902C68.06 80.4785 73.357 80.4785 76.6023 78.5902C78.2208 77.642 79.03 76.4158 79.03 75.1897Z" fill="#B88325"/>
        <path d="M79.0343 75.1846C79.0419 72.512 75.3077 70.3348 70.6938 70.3218C66.0799 70.3087 62.3335 72.4647 62.3259 75.1373C62.3184 77.81 66.0526 79.9871 70.6664 80.0002C75.2803 80.0133 79.0268 77.8572 79.0343 75.1846Z" fill="#F9B233"/>
        <path d="M70.7003 79.128C68.6159 79.128 66.6704 78.6702 65.2235 77.8283C63.9156 77.0762 63.1963 76.1198 63.1963 75.1552C63.1963 74.1907 63.8993 73.2506 65.1908 72.5068C66.6295 71.673 68.575 71.207 70.6513 71.207C72.7358 71.207 74.6812 71.6648 76.1281 72.5068C77.436 73.2588 78.1554 74.2152 78.1554 75.1798C78.1554 76.1443 77.4524 77.0844 76.1608 77.8283C74.7221 78.662 72.7848 79.128 70.7003 79.128Z" fill="#B88325"/>
        <path d="M70.7003 79.5381C68.6159 79.5381 66.6704 79.0804 65.2235 78.2384C63.9156 77.4864 63.1963 76.53 63.1963 75.5654C63.1963 74.6008 63.8993 73.6608 65.1908 72.9169C66.6295 72.0831 68.575 71.6172 70.6513 71.6172C72.7358 71.6172 74.6812 72.075 76.1281 72.9169C77.436 73.669 78.1554 74.6254 78.1554 75.5899C78.1554 76.5545 77.4524 77.4945 76.1608 78.2384C74.7221 79.0722 72.7848 79.5381 70.7003 79.5381Z" fill="#FFCA38"/>
        <path d="M74.9263 77.7055L74.0762 77.2151L73.5857 77.5012L74.4358 77.5502V77.9916L73.6102 78.4739L72.7601 77.9835L72.0898 78.3758L65.8037 74.7464V74.305L66.474 74.3622L65.5994 73.8554V73.414L66.425 73.3813L67.2996 73.8881L67.7901 73.602L66.9154 73.0952V72.6538L67.741 72.6211L68.6647 73.1524C69.613 72.7601 70.4386 72.8173 71.2397 73.2832L71.4685 73.414C72.1143 73.79 72.2942 74.1579 71.9754 74.5829C72.8091 74.3377 73.5285 74.4685 74.1988 74.8527L74.7138 75.147C75.1552 75.4004 75.3759 75.6783 75.3759 75.9644C75.3759 76.2096 75.2124 76.4712 74.8772 76.7328L75.7601 76.7982V77.2396L74.9263 77.7055ZM72.3595 77.1742L73.5203 76.5039C73.9127 76.275 73.9781 76.0625 73.504 75.7927L72.9563 75.4739C72.3759 75.1388 71.9672 75.1633 71.4358 75.4739L70.4222 76.0625L72.3595 77.1742ZM69.5312 75.5393L70.4795 74.9917C70.9127 74.7382 71.0189 74.493 70.5121 74.1987L70.1606 73.9944C69.7111 73.7328 69.335 73.7246 68.9182 73.9699L67.8637 74.5829L69.5312 75.5393Z" fill="#B88325"/>
        <path d="M75.3679 75.5H75.0327V75.9414H75.3679V75.5Z" fill="#B88325"/>
        <path d="M72.1225 73.75H71.7874V74.1914H72.1225V73.75Z" fill="#B88325"/>
        <path d="M74.9263 77.2574L74.0762 76.767L73.5857 77.0531L74.4358 77.5435L73.6102 78.0258L72.7601 77.5353L72.0898 77.9277L65.8037 74.2983L66.474 73.9059L65.5994 73.3991L66.425 72.9168L67.2996 73.4236L67.7901 73.1375L66.9154 72.6307L67.741 72.1484L68.6647 72.6798C69.613 72.2874 70.4386 72.3446 71.2397 72.8106L71.4685 72.9414C72.1143 73.3174 72.2942 73.6852 71.9754 74.1103C72.8091 73.8651 73.5285 73.9958 74.1988 74.38L74.7138 74.6743C75.1552 74.9277 75.3759 75.2056 75.3759 75.4917C75.3759 75.737 75.2124 75.9986 74.8772 76.2601L75.7601 76.7751L74.9263 77.2574ZM72.3595 76.7261L73.5203 76.0558C73.9127 75.8269 73.9781 75.6144 73.504 75.3446L72.9563 75.0258C72.3759 74.6907 71.9672 74.7152 71.4358 75.0258L70.4222 75.6144L72.3595 76.7261ZM69.5312 75.0912L70.4795 74.5435C70.9127 74.2901 71.0189 74.0449 70.5121 73.7506L70.1606 73.5463C69.7111 73.2847 69.335 73.2765 68.9182 73.5217L67.8637 74.1348L69.5312 75.0912Z" fill="#F9B233"/>
        <path d="M79.0293 73.135L79.0211 74.8516C79.0211 76.0859 78.2119 77.3121 76.5933 78.2521C73.34 80.1404 68.0512 80.1404 64.7814 78.2521C63.1384 77.3039 62.3127 76.0532 62.3127 74.8026L62.3209 73.0859C62.3209 74.3366 63.1384 75.5791 64.7896 76.5355C68.0593 78.4238 73.3563 78.4238 76.6015 76.5355C78.2201 75.5955 79.0293 74.3693 79.0293 73.135Z" fill="#B88325"/>
        <path d="M79.0297 73.1338C79.0373 70.4612 75.3031 68.284 70.6892 68.271C66.0753 68.2579 62.3289 70.4139 62.3213 73.0866C62.3137 75.7592 66.0479 77.9364 70.6618 77.9494C75.2757 77.9625 79.0221 75.8065 79.0297 73.1338Z" fill="#F9B233"/>
        <path d="M70.6999 77.0733C68.6154 77.0733 66.6699 76.6155 65.223 75.7736C63.9151 75.0215 63.1958 74.0651 63.1958 73.1006C63.1958 72.136 63.8988 71.1959 65.1903 70.4521C66.629 69.6183 68.5745 69.1523 70.6508 69.1523C72.7353 69.1523 74.6808 69.6101 76.1276 70.4521C77.4355 71.2041 78.1549 72.1605 78.1549 73.1251C78.1549 74.0897 77.4519 75.0297 76.1603 75.7736C74.7216 76.6155 72.7843 77.0733 70.6999 77.0733Z" fill="#B88325"/>
        <path d="M70.6999 77.4835C68.6154 77.4835 66.6699 77.0257 65.223 76.1837C63.9151 75.4317 63.1958 74.4753 63.1958 73.5107C63.1958 72.5461 63.8988 71.6061 65.1903 70.8622C66.629 70.0284 68.5745 69.5625 70.6508 69.5625C72.7353 69.5625 74.6808 70.0203 76.1276 70.8622C77.4355 71.6143 78.1549 72.5707 78.1549 73.5352C78.1549 74.4998 77.4519 75.4399 76.1603 76.1837C74.7216 77.0257 72.7843 77.4835 70.6999 77.4835Z" fill="#FFCA38"/>
        <path d="M74.9258 75.6509L74.0757 75.1604L73.5852 75.4465L74.4354 75.4955V75.937L73.6097 76.4192L72.7596 75.9288L72.0893 76.3212L65.8032 72.6917V72.2503L66.4735 72.3075L65.5989 71.8007V71.3593L66.4245 71.3266L67.2991 71.8334L67.7896 71.5473L66.9149 71.0405V70.5991L67.7406 70.5664L68.6643 71.0977C69.6125 70.7054 70.4381 70.7626 71.2392 71.2285L71.4681 71.3593C72.1138 71.7353 72.2937 72.1032 71.9749 72.5283C72.8086 72.283 73.528 72.4138 74.1983 72.798L74.7133 73.0923C75.1547 73.3457 75.3754 73.6236 75.3754 73.9097C75.3754 74.1549 75.2119 74.4165 74.8768 74.6781L75.7596 74.7435V75.1849L74.9258 75.6509ZM72.3591 75.1195L73.5198 74.4492C73.9122 74.2203 73.9776 74.0078 73.5035 73.7381L72.9558 73.4193C72.3754 73.0841 71.9667 73.1086 71.4354 73.4193L70.4217 74.0078L72.3591 75.1195ZM69.5307 73.4847L70.479 72.937C70.9122 72.6836 71.0185 72.4383 70.5116 72.1441L70.1602 71.9397C69.7106 71.6781 69.3346 71.6699 68.9177 71.9152L67.8632 72.5283L69.5307 73.4847Z" fill="#B88325"/>
        <path d="M75.3671 73.4453H75.032V73.8867H75.3671V73.4453Z" fill="#B88325"/>
        <path d="M72.1218 71.6953H71.7866V72.1367H72.1218V71.6953Z" fill="#B88325"/>
        <path d="M74.9258 75.2105L74.0757 74.7201L73.5852 75.0062L74.4354 75.4966L73.6097 75.9789L72.7596 75.4885L72.0893 75.8808L65.8032 72.2514L66.4735 71.8591L65.5989 71.3522L66.4245 70.87L67.2991 71.3768L67.7896 71.0907L66.9149 70.5838L67.7406 70.1016L68.6643 70.6329C69.6125 70.2405 70.4381 70.2977 71.2392 70.7637L71.4681 70.8945C72.1138 71.2705 72.2937 71.6383 71.9749 72.0634C72.8086 71.8182 73.528 71.949 74.1983 72.3332L74.7133 72.6274C75.1547 72.8808 75.3754 73.1588 75.3754 73.4449C75.3754 73.6901 75.2119 73.9517 74.8768 74.2133L75.7596 74.7282L74.9258 75.2105ZM72.3591 74.6792L73.5198 74.0089C73.9122 73.78 73.9776 73.5675 73.5035 73.2977L72.9558 72.9789C72.3754 72.6438 71.9667 72.6683 71.4354 72.9789L70.4217 73.5675L72.3591 74.6792ZM69.5307 73.0443L70.479 72.4966C70.9122 72.2432 71.0185 71.998 70.5116 71.7037L70.1602 71.4994C69.7106 71.2378 69.3346 71.2296 68.9177 71.4749L67.8632 72.0879L69.5307 73.0443Z" fill="#F9B233"/>
        <path d="M79.0293 71.092L79.0211 72.8086C79.0211 74.043 78.2119 75.2691 76.5933 76.2092C73.34 78.0974 68.0512 78.0974 64.7814 76.2092C63.1384 75.2609 62.3127 74.0103 62.3127 72.7596L62.3209 71.043C62.3209 72.2936 63.1384 73.5361 64.7896 74.4925C68.0593 76.3808 73.3563 76.3808 76.6015 74.4925C78.2201 73.5443 79.0293 72.3182 79.0293 71.092Z" fill="#B88325"/>
        <path d="M76.569 67.6428C79.8388 69.531 79.8633 72.5964 76.6099 74.4847C73.3565 76.373 68.0677 76.373 64.798 74.4847C61.5282 72.5964 61.5037 69.531 64.7571 67.6428C68.0023 65.7545 73.2911 65.7545 76.569 67.6428Z" fill="#F9B233"/>
        <path d="M70.6999 75.0303C68.6154 75.0303 66.6699 74.5726 65.223 73.7306C63.9151 72.9786 63.1958 72.0222 63.1958 71.0576C63.1958 70.093 63.8988 69.153 65.1903 68.4091C66.629 67.5753 68.5745 67.1094 70.6508 67.1094C72.7353 67.1094 74.6808 67.5671 76.1276 68.4091C77.4355 69.1611 78.1549 70.1175 78.1549 71.0821C78.1549 72.0467 77.4519 72.9867 76.1603 73.7306C74.7216 74.5644 72.7843 75.0303 70.6999 75.0303Z" fill="#B88325"/>
        <path d="M70.6999 75.4405C68.6154 75.4405 66.6699 74.9827 65.223 74.1408C63.9151 73.3887 63.1958 72.4323 63.1958 71.4677C63.1958 70.5032 63.8988 69.5631 65.1903 68.8193C66.629 67.9855 68.5745 67.5195 70.6508 67.5195C72.7353 67.5195 74.6808 67.9773 76.1276 68.8193C77.4355 69.5713 78.1549 70.5277 78.1549 71.4923C78.1549 72.4568 77.4519 73.3969 76.1603 74.1408C74.7216 74.9745 72.7843 75.4405 70.6999 75.4405Z" fill="#FFCA38"/>
        <path d="M74.9258 73.6079L74.0757 73.1174L73.5852 73.4035L74.4354 73.4526V73.894L73.6097 74.3763L72.7596 73.8858L72.0893 74.2782L65.8032 70.6488V70.2074L66.4735 70.2646L65.5989 69.7578V69.3164L66.4245 69.2837L67.2991 69.7905L67.7896 69.5044L66.9149 68.9976V68.5561L67.7406 68.5234L68.6643 69.0548C69.6125 68.6624 70.4381 68.7196 71.2392 69.1856L71.4681 69.3164C72.1138 69.6924 72.2937 70.0602 71.9749 70.4853C72.8086 70.2401 73.528 70.3708 74.1983 70.755L74.7133 71.0493C75.1547 71.3027 75.3754 71.5806 75.3754 71.8667C75.3754 72.112 75.2119 72.3736 74.8768 72.6351L75.7596 72.7005V73.142L74.9258 73.6079ZM72.3591 73.0766L73.5198 72.4063C73.9122 72.1774 73.9776 71.9648 73.5035 71.6951L72.9558 71.3763C72.3754 71.0411 71.9667 71.0657 71.4354 71.3763L70.4217 71.9648L72.3591 73.0766ZM69.5307 71.4417L70.479 70.894C70.9122 70.6406 71.0185 70.3954 70.5116 70.1011L70.1602 69.8967C69.7106 69.6352 69.3346 69.627 68.9177 69.8722L67.8632 70.4853L69.5307 71.4417Z" fill="#B88325"/>
        <path d="M75.3674 71.4023H75.0322V71.8438H75.3674V71.4023Z" fill="#B88325"/>
        <path d="M72.122 69.6523H71.7869V70.0938H72.122V69.6523Z" fill="#B88325"/>
        <path d="M74.9258 73.1598L74.0757 72.6693L73.5852 72.9554L74.4354 73.4459L73.6097 73.9281L72.7596 73.4377L72.0893 73.8301L65.8032 70.2006L66.4735 69.8083L65.5989 69.3015L66.4245 68.8192L67.2991 69.326L67.7896 69.0399L66.9149 68.5331L67.7406 68.0508L68.6643 68.5821C69.6125 68.1897 70.4381 68.247 71.2392 68.7129L71.4681 68.8437C72.1138 69.2197 72.2937 69.5876 71.9749 70.0126C72.8086 69.7674 73.528 69.8982 74.1983 70.2824L74.7133 70.5767C75.1547 70.8301 75.3754 71.108 75.3754 71.3941C75.3754 71.6393 75.2119 71.9009 74.8768 72.1625L75.7596 72.6775L74.9258 73.1598ZM72.3591 72.6284L73.5198 71.9581C73.9122 71.7292 73.9776 71.5167 73.5035 71.247L72.9558 70.9282C72.3754 70.593 71.9667 70.6175 71.4354 70.9282L70.4217 71.5167L72.3591 72.6284ZM69.5307 70.9935L70.479 70.4459C70.9122 70.1925 71.0185 69.9472 70.5116 69.653L70.1602 69.4486C69.7106 69.187 69.3346 69.1788 68.9177 69.4241L67.8632 70.0372L69.5307 70.9935Z" fill="#F9B233"/>
        <path d="M79.0298 69.0373L79.0216 70.7539C79.0216 71.9883 78.2124 73.2144 76.5938 74.1545C73.3404 76.0428 68.0516 76.0428 64.7819 74.1545C63.1389 73.2062 62.3132 71.9556 62.3132 70.7049L62.3214 68.9883C62.3214 70.2308 63.1388 71.4815 64.7901 72.4379C68.0598 74.3261 73.3568 74.3261 76.602 72.4379C78.2205 71.4978 79.0298 70.2717 79.0298 69.0373Z" fill="#B88325"/>
        <path d="M76.5693 65.5942C79.839 67.4825 79.8636 70.5479 76.6102 72.4362C73.3568 74.3244 68.068 74.3244 64.7982 72.4362C61.5285 70.5479 61.504 67.4825 64.7573 65.5942C68.0026 63.6978 73.2914 63.6978 76.5693 65.5942Z" fill="#F9B233"/>
        <path d="M70.7001 72.9756C68.6156 72.9756 66.6701 72.5179 65.2233 71.6759C63.9154 70.9239 63.196 69.9675 63.196 69.0029C63.196 68.0383 63.899 67.0983 65.1906 66.3544C66.6293 65.5206 68.5748 65.0547 70.6511 65.0547C72.7355 65.0547 74.681 65.5124 76.1279 66.3544C77.4358 67.1064 78.1551 68.0629 78.1551 69.0274C78.1551 69.992 77.4521 70.932 76.1606 71.6759C74.7219 72.5179 72.7846 72.9756 70.7001 72.9756Z" fill="#B88325"/>
        <path d="M70.7001 73.3858C68.6156 73.3858 66.6701 72.928 65.2233 72.0861C63.9154 71.334 63.196 70.3776 63.196 69.4131C63.196 68.4485 63.899 67.5084 65.1906 66.7646C66.6293 65.9308 68.5748 65.4648 70.6511 65.4648C72.7355 65.4648 74.681 65.9226 76.1279 66.7646C77.4358 67.5166 78.1551 68.473 78.1551 69.4376C78.1551 70.4022 77.4521 71.3422 76.1606 72.0861C74.7219 72.928 72.7846 73.3858 70.7001 73.3858Z" fill="#FFCA38"/>
        <path d="M72.122 67.5977H71.7869V68.0391H72.122V67.5977Z" fill="#B88325"/>
        <path d="M67.2175 94.5178L67.2094 96.2344C67.2094 97.4687 66.4001 98.6949 64.7816 99.6349C61.5282 101.523 56.2394 101.523 52.9696 99.6349C51.3266 98.6867 50.501 97.436 50.501 96.1854L50.5091 94.4688C50.5091 95.7113 51.3266 96.9619 52.9778 97.9183C56.2475 99.8066 61.5445 99.8066 64.7897 97.9183C66.4001 96.9783 67.2093 95.7521 67.2175 94.5178Z" fill="#B88325"/>
        <path d="M64.7487 91.0764C68.0185 92.9646 68.043 96.03 64.7896 97.9183C61.5362 99.8066 56.2474 99.8066 52.9777 97.9183C49.7079 96.03 49.6834 92.9646 52.9368 91.0764C56.182 89.1881 61.479 89.1881 64.7487 91.0764Z" fill="#F9B233"/>
        <path d="M58.8876 98.4561C56.8031 98.4561 54.8577 97.9983 53.4108 97.1564C52.1029 96.4043 51.3835 95.4479 51.3835 94.4834C51.3835 93.5188 52.0865 92.5787 53.3781 91.8349C54.8168 91.0011 56.7623 90.5352 58.8386 90.5352C60.923 90.5352 62.8685 90.9929 64.3154 91.8349C65.6233 92.5869 66.3426 93.5433 66.3426 94.5079C66.3426 95.4725 65.6396 96.4125 64.3481 97.1564C62.9094 97.9983 60.9639 98.4561 58.8876 98.4561Z" fill="#B88325"/>
        <path d="M58.8876 98.8663C56.8031 98.8663 54.8577 98.4085 53.4108 97.5665C52.1029 96.8145 51.3835 95.8581 51.3835 94.8935C51.3835 93.929 52.0865 92.9889 53.3781 92.245C54.8168 91.4112 56.7623 90.9453 58.8386 90.9453C60.923 90.9453 62.8685 91.4031 64.3154 92.245C65.6233 92.9971 66.3426 93.9535 66.3426 94.9181C66.3426 95.8826 65.6396 96.8227 64.3481 97.5665C62.9094 98.4085 60.9639 98.8663 58.8876 98.8663Z" fill="#FFCA38"/>
        <path d="M63.1138 97.0419L62.2637 96.5514L61.7732 96.8375L62.6233 96.8865V97.3279L61.7977 97.8021L60.9476 97.3116L60.2773 97.704L53.9912 94.0746V93.6331L54.6615 93.6904L53.7869 93.1836V92.7421L54.6125 92.7094L55.4871 93.2162L55.9776 92.9301L55.1029 92.4233V91.9819L55.9285 91.9492L56.8522 92.4806C57.8005 92.0882 58.6261 92.1454 59.4272 92.6113L59.656 92.7421C60.3018 93.1182 60.4817 93.486 60.1629 93.9111C60.9966 93.6658 61.716 93.7966 62.3863 94.1808L62.9013 94.4751C63.3427 94.7285 63.5634 95.0064 63.5634 95.2925C63.5634 95.5378 63.3999 95.7993 63.0648 96.0609L63.9476 96.1263V96.5677L63.1138 97.0419ZM60.547 96.5105L61.7078 95.8402C62.1002 95.6113 62.1656 95.3988 61.6915 95.129L61.1438 94.8102C60.5634 94.4751 60.1547 94.4996 59.6234 94.8102L58.6097 95.3988L60.547 96.5105ZM57.7187 94.8756L58.667 94.328C59.1002 94.0746 59.2065 93.8293 58.6997 93.535L58.3481 93.3307C57.8986 93.0691 57.5225 93.0609 57.1057 93.3062L56.0512 93.9192L57.7187 94.8756Z" fill="#B88325"/>
        <path d="M63.5471 94.8359H63.2119V95.2774H63.5471V94.8359Z" fill="#B88325"/>
        <path d="M60.3019 93.0859H59.9668V93.5274H60.3019V93.0859Z" fill="#B88325"/>
        <path d="M63.1141 96.5933L62.2639 96.1029L61.7734 96.389L62.6236 96.8794L61.798 97.3617L60.9479 96.8713L60.2776 97.2636L53.9915 93.6342L54.6618 93.2419L53.7871 92.7351L54.6127 92.2528L55.4874 92.7596L55.9778 92.4735L55.1032 91.9667L55.9288 91.4844L56.8525 92.0157C57.8007 91.6233 58.6263 91.6806 59.4274 92.1465L59.6563 92.2773C60.3021 92.6533 60.4819 93.0211 60.1631 93.4462C60.9969 93.201 61.7162 93.3318 62.3865 93.716L62.9015 94.0102C63.3429 94.2637 63.5636 94.5416 63.5636 94.8277C63.5636 95.0729 63.4002 95.3345 63.065 95.5961L63.9478 96.1111L63.1141 96.5933ZM60.5473 96.062L61.7081 95.3917C62.1004 95.1628 62.1658 94.9503 61.6917 94.6805L61.144 94.3617C60.5637 94.0266 60.1549 94.0511 59.6236 94.3617L58.61 94.9503L60.5473 96.062ZM57.719 94.4271L58.6672 93.8795C59.1004 93.6261 59.2067 93.3808 58.6999 93.0865L58.3484 92.8822C57.8988 92.6206 57.5228 92.6124 57.1059 92.8577L56.0514 93.4707L57.719 94.4271Z" fill="#F9B233"/>
        <path d="M67.2178 92.4748L67.2096 94.1914C67.2096 95.4258 66.4003 96.6519 64.7818 97.592C61.5284 99.4803 56.2396 99.4803 52.9699 97.592C51.3268 96.6438 50.5012 95.3931 50.5012 94.1424L50.5094 92.4258C50.5094 93.6683 51.3268 94.919 52.978 95.8754C56.2478 97.7636 61.5448 97.7636 64.79 95.8754C66.4003 94.9353 67.2096 93.7092 67.2178 92.4748Z" fill="#B88325"/>
        <path d="M64.749 89.0256C68.0187 90.9139 68.0432 93.9793 64.7898 95.8675C61.5364 97.7558 56.2477 97.7558 52.9779 95.8675C49.7082 93.9793 49.6836 90.9139 52.937 89.0256C56.1822 87.1373 61.4792 87.1373 64.749 89.0256Z" fill="#F9B233"/>
        <path d="M58.8878 96.4131C56.8034 96.4131 54.8579 95.9554 53.411 95.1134C52.1031 94.3614 51.3838 93.405 51.3838 92.4404C51.3838 91.4758 52.0868 90.5358 53.3783 89.7919C54.817 88.9581 56.7625 88.4922 58.8388 88.4922C60.9233 88.4922 62.8688 88.9499 64.3156 89.7919C65.6235 90.5439 66.3429 91.5004 66.3429 92.4649C66.3429 93.4295 65.6399 94.3696 64.3483 95.1134C62.9096 95.9554 60.9641 96.4131 58.8878 96.4131Z" fill="#B88325"/>
        <path d="M58.8878 96.8233C56.8034 96.8233 54.8579 96.3655 53.411 95.5236C52.1031 94.7715 51.3838 93.8151 51.3838 92.8506C51.3838 91.886 52.0868 90.9459 53.3783 90.2021C54.817 89.3683 56.7625 88.9023 58.8388 88.9023C60.9233 88.9023 62.8688 89.3601 64.3156 90.2021C65.6235 90.9541 66.3429 91.9105 66.3429 92.8751C66.3429 93.8397 65.6399 94.7797 64.3483 95.5236C62.9096 96.3655 60.9641 96.8233 58.8878 96.8233Z" fill="#FFCA38"/>
        <path d="M63.1138 94.9946L62.2637 94.5042L61.7732 94.7902L62.6233 94.8393V95.2807L61.7977 95.763L60.9476 95.2725L60.2773 95.6649L53.9912 92.0355V91.5941L54.6615 91.6513L53.7869 91.1445V90.7031L54.6125 90.6704L55.4871 91.1772L55.9776 90.8911L55.1029 90.3843V89.9429L55.9285 89.9102L56.8522 90.4415C57.8005 90.0491 58.6261 90.1063 59.4272 90.5723L59.656 90.7031C60.3018 91.0791 60.4817 91.4469 60.1629 91.872C60.9966 91.6268 61.716 91.7576 62.3863 92.1418L62.9013 92.436C63.3427 92.6894 63.5634 92.9674 63.5634 93.2535C63.5634 93.4987 63.3999 93.7603 63.0648 94.0219L63.9476 94.0873V94.5287L63.1138 94.9946ZM60.547 94.4633L61.7078 93.793C62.1002 93.5641 62.1656 93.3516 61.6915 93.0818L61.1438 92.763C60.5634 92.4279 60.1547 92.4524 59.6234 92.763L58.6097 93.3516L60.547 94.4633ZM57.7187 92.8284L58.667 92.2807C59.1002 92.0273 59.2065 91.7821 58.6997 91.4878L58.3481 91.2835C57.8986 91.0219 57.5225 91.0137 57.1057 91.2589L56.0512 91.872L57.7187 92.8284Z" fill="#B88325"/>
        <path d="M63.5471 92.7891H63.2119V93.2305H63.5471V92.7891Z" fill="#B88325"/>
        <path d="M60.3022 91.0391H59.967V91.4805H60.3022V91.0391Z" fill="#B88325"/>
        <path d="M63.1141 94.5543L62.2639 94.0638L61.7734 94.3499L62.6236 94.8404L61.798 95.3227L60.9479 94.8322L60.2776 95.2246L53.9915 91.5952L54.6618 91.2028L53.7871 90.696L54.6127 90.2137L55.4874 90.7205L55.9778 90.4344L55.1032 89.9276L55.9288 89.4453L56.8525 89.9766C57.8007 89.5843 58.6263 89.6415 59.4274 90.1074L59.6563 90.2382C60.3021 90.6142 60.4819 90.9821 60.1631 91.4072C60.9969 91.1619 61.7162 91.2927 62.3865 91.6769L62.9015 91.9712C63.3429 92.2246 63.5636 92.5025 63.5636 92.7886C63.5636 93.0339 63.4002 93.2954 63.065 93.557L63.9478 94.072L63.1141 94.5543ZM60.5473 94.0229L61.7081 93.3527C62.1004 93.1238 62.1658 92.9112 61.6917 92.6415L61.144 92.3227C60.5637 91.9875 60.1549 92.0121 59.6236 92.3227L58.61 92.9031L60.5473 94.0229ZM57.719 92.3881L58.6672 91.8404C59.1004 91.587 59.2067 91.3418 58.6999 91.0475L58.3484 90.8431C57.8988 90.5815 57.5228 90.5734 57.1059 90.8186L56.0514 91.4317L57.719 92.3881Z" fill="#F9B233"/>
        <path d="M67.2178 90.428L67.2096 92.1446C67.2096 93.3789 66.4003 94.605 64.7818 95.5451C61.5284 97.4334 56.2396 97.4334 52.9699 95.5451C51.3268 94.5969 50.5012 93.3462 50.5012 92.0955L50.5094 90.3789C50.5094 91.6214 51.3268 92.8721 52.978 93.8285C56.2478 95.7168 61.5448 95.7168 64.79 93.8285C66.4003 92.8884 67.2096 91.6623 67.2178 90.428Z" fill="#B88325"/>
        <path d="M64.749 86.9865C68.0187 88.8748 68.0432 91.9402 64.7898 93.8285C61.5364 95.7167 56.2477 95.7167 52.9779 93.8285C49.7082 91.9402 49.6836 88.8748 52.937 86.9865C56.1822 85.0982 61.4792 85.0982 64.749 86.9865Z" fill="#F9B233"/>
        <path d="M58.8878 94.3741C56.8034 94.3741 54.8579 93.9163 53.411 93.0744C52.1031 92.3223 51.3838 91.3659 51.3838 90.4013C51.3838 89.4368 52.0868 88.4967 53.3783 87.7528C54.817 86.9191 56.7625 86.4531 58.8388 86.4531C60.9233 86.4531 62.8688 86.9109 64.3156 87.7528C65.6235 88.5049 66.3429 89.4613 66.3429 90.4259C66.3429 91.3904 65.6399 92.3305 64.3483 93.0744C62.9096 93.9081 60.9641 94.3741 58.8878 94.3741Z" fill="#B88325"/>
        <path d="M58.8878 94.7764C56.8034 94.7764 54.8579 94.3186 53.411 93.4767C52.1031 92.7246 51.3838 91.7682 51.3838 90.8037C51.3838 89.8391 52.0868 88.8991 53.3783 88.1552C54.817 87.3214 56.7625 86.8555 58.8388 86.8555C60.9233 86.8555 62.8688 87.3132 64.3156 88.1552C65.6235 88.9072 66.3429 89.8636 66.3429 90.8282C66.3429 91.7928 65.6399 92.7328 64.3483 93.4767C62.9096 94.3186 60.9641 94.7764 58.8878 94.7764Z" fill="#FFCA38"/>
        <path d="M63.1141 92.9477L62.2639 92.4573L61.7734 92.7434L62.6236 92.7924V93.2338L61.798 93.7161L60.9479 93.2257L60.2776 93.618L53.9915 89.9886V89.5472L54.6618 89.6044L53.7871 89.0976V88.6562L54.6127 88.6235L55.4874 89.1303L55.9778 88.8442L55.1032 88.3374V87.896L55.9288 87.8633L56.8525 88.3946C57.8007 88.0023 58.6263 88.0595 59.4274 88.5254L59.6563 88.6562C60.3021 89.0322 60.4819 89.4001 60.1631 89.8251C60.9969 89.5799 61.7162 89.7107 62.3865 90.0949L62.9015 90.3892C63.3429 90.6426 63.5636 90.9205 63.5636 91.2066C63.5636 91.4518 63.4002 91.7134 63.065 91.975L63.9478 92.0404V92.4818L63.1141 92.9477ZM60.5473 92.4164L61.7081 91.7461C62.1004 91.5172 62.1658 91.3047 61.6917 91.0349L61.144 90.7161C60.5637 90.381 60.1549 90.4055 59.6236 90.7161L58.61 91.3047L60.5473 92.4164ZM57.719 90.7815L58.6672 90.2339C59.1004 89.9804 59.2067 89.7352 58.6999 89.4409L58.3484 89.2366C57.8988 88.975 57.5228 88.9668 57.1059 89.2121L56.0514 89.8251L57.719 90.7815Z" fill="#B88325"/>
        <path d="M63.5473 90.7422H63.2122V91.1836H63.5473V90.7422Z" fill="#B88325"/>
        <path d="M60.3022 88.9922H59.967V89.4336H60.3022V88.9922Z" fill="#B88325"/>
        <path d="M63.1143 92.4996L62.2642 92.0091L61.7737 92.2952L62.6238 92.7857L61.7982 93.268L60.9481 92.7775L60.2778 93.1699L53.9917 89.5405L54.662 89.1481L53.7874 88.6413L54.613 88.159L55.4876 88.6658L55.9781 88.3797L55.1034 87.8729L55.929 87.3906L56.8527 87.922C57.801 87.5296 58.6266 87.5868 59.4277 88.0528L59.6565 88.1835C60.3023 88.5596 60.4821 88.9274 60.1633 89.3525C60.9971 89.1072 61.7165 89.238 62.3868 89.6222L62.9018 89.9165C63.3432 90.1699 63.5639 90.4478 63.5639 90.7339C63.5639 90.9792 63.4004 91.2407 63.0653 91.5023L63.9481 92.0173L63.1143 92.4996ZM60.5475 91.9683L61.7083 91.298C62.1007 91.0691 62.1661 90.8566 61.692 90.5868L61.1443 90.268C60.5639 89.9329 60.1552 89.9574 59.6239 90.268L58.6102 90.8566L60.5475 91.9683ZM57.7192 90.3334L58.6674 89.7857C59.1007 89.5323 59.207 89.2871 58.7001 88.9928L58.3486 88.7884C57.899 88.5269 57.523 88.5187 57.1061 88.7639L56.0516 89.377L57.7192 90.3334Z" fill="#F9B233"/>
        <path d="M67.2178 88.3772L67.2096 90.0938C67.2096 91.3281 66.4003 92.5543 64.7818 93.4943C61.5284 95.3826 56.2396 95.3826 52.9699 93.4943C51.3268 92.5461 50.5012 91.2954 50.5012 90.0447L50.5094 88.3281C50.5094 89.5788 51.3268 90.8213 52.978 91.7777C56.2478 93.666 61.5448 93.666 64.79 91.7777C66.4003 90.8377 67.2096 89.6115 67.2178 88.3772Z" fill="#B88325"/>
        <path d="M64.749 84.9279C68.0187 86.8162 68.0432 89.8816 64.7898 91.7699C61.5364 93.6581 56.2477 93.6581 52.9779 91.7699C49.7082 89.8816 49.6836 86.8162 52.937 84.9279C56.1822 83.0396 61.4792 83.0396 64.749 84.9279Z" fill="#F9B233"/>
        <path d="M58.8878 92.3155C56.8034 92.3155 54.8579 91.8577 53.411 91.0158C52.1031 90.2637 51.3838 89.3073 51.3838 88.3427C51.3838 87.3782 52.0868 86.4381 53.3783 85.6943C54.817 84.8605 56.7625 84.3945 58.8388 84.3945C60.9233 84.3945 62.8688 84.8523 64.3156 85.6943C65.6235 86.4463 66.3429 87.4027 66.3429 88.3673C66.3429 89.3318 65.6399 90.2719 64.3483 91.0158C62.9096 91.8577 60.9641 92.3155 58.8878 92.3155Z" fill="#B88325"/>
        <path d="M58.8878 92.7256C56.8034 92.7256 54.8579 92.2679 53.411 91.4259C52.1031 90.6739 51.3838 89.7175 51.3838 88.7529C51.3838 87.7883 52.0868 86.8483 53.3783 86.1044C54.817 85.2706 56.7625 84.8047 58.8388 84.8047C60.9233 84.8047 62.8688 85.2624 64.3156 86.1044C65.6235 86.8564 66.3429 87.8129 66.3429 88.7774C66.3429 89.742 65.6399 90.682 64.3483 91.4259C62.9096 92.2679 60.9641 92.7256 58.8878 92.7256Z" fill="#FFCA38"/>
        <path d="M63.1141 90.897L62.2639 90.4065L61.7734 90.6926L62.6236 90.7416V91.183L61.798 91.6653L60.9479 91.1749L60.2776 91.5672L53.9915 87.9378V87.4964L54.6618 87.5536L53.7871 87.0468V86.6054L54.6127 86.5727L55.4874 87.0795L55.9778 86.7934L55.1032 86.2866V85.8452L55.9288 85.8125L56.8525 86.3438C57.8007 85.9515 58.6263 86.0087 59.4274 86.4746L59.6563 86.6054C60.3021 86.9814 60.4819 87.3493 60.1631 87.7743C60.9969 87.5291 61.7162 87.6599 62.3865 88.0441L62.9015 88.3384C63.3429 88.5918 63.5636 88.8697 63.5636 89.1558C63.5636 89.401 63.4002 89.6626 63.065 89.9242L63.9478 89.9896V90.431L63.1141 90.897ZM60.5473 90.3656L61.7081 89.6953C62.1004 89.4664 62.1658 89.2539 61.6917 88.9842L61.144 88.6654C60.5637 88.3302 60.1549 88.3547 59.6236 88.6654L58.61 89.2539L60.5473 90.3656ZM57.719 88.7307L58.6672 88.1831C59.1004 87.9297 59.2067 87.6844 58.6999 87.3901L58.3484 87.1858C57.8988 86.9242 57.5228 86.916 57.1059 87.1613L56.0514 87.7743L57.719 88.7307Z" fill="#B88325"/>
        <path d="M63.5471 88.6914H63.2119V89.1328H63.5471V88.6914Z" fill="#B88325"/>
        <path d="M60.3022 86.9414H59.967V87.3828H60.3022V86.9414Z" fill="#B88325"/>
        <path d="M63.1141 90.4566L62.2639 89.9662L61.7734 90.2523L62.6236 90.7427L61.798 91.225L60.9479 90.7346L60.2776 91.1269L53.9915 87.4975L54.6618 87.1052L53.7871 86.5983L54.6127 86.116L55.4874 86.6229L55.9778 86.3368L55.1032 85.83L55.9288 85.3477L56.8525 85.879C57.8007 85.4866 58.6263 85.5439 59.4274 86.0098L59.6563 86.1406C60.3021 86.5166 60.4819 86.8844 60.1631 87.3095C60.9969 87.0643 61.7162 87.1951 62.3865 87.5793L62.9015 87.8735C63.3429 88.1269 63.5636 88.4049 63.5636 88.691C63.5636 88.9362 63.4002 89.1978 63.065 89.4594L63.9478 89.9743L63.1141 90.4566ZM60.5473 89.9253L61.7081 89.255C62.1004 89.0261 62.1658 88.8136 61.6917 88.5438L61.144 88.225C60.5637 87.8899 60.1549 87.9144 59.6236 88.225L58.61 88.8136L60.5473 89.9253ZM57.719 88.2904L58.6672 87.7427C59.1004 87.4893 59.2067 87.2441 58.6999 86.9498L58.3484 86.7455C57.8988 86.4839 57.5228 86.4757 57.1059 86.721L56.0514 87.334L57.719 88.2904Z" fill="#F9B233"/>
        <path d="M67.2175 86.3342L67.2094 88.0508C67.2094 89.2851 66.4001 90.5113 64.7816 91.4513C61.5282 93.3396 56.2394 93.3396 52.9696 91.4513C51.3266 90.5031 50.501 89.2524 50.501 88.0018L50.5091 86.2852C50.5091 87.5358 51.3266 88.7783 52.9778 89.7347C56.2475 91.623 61.5445 91.623 64.7897 89.7347C66.4001 88.7865 67.2093 87.5604 67.2175 86.3342Z" fill="#B88325"/>
        <path d="M67.2189 86.3293C67.2265 83.6566 63.4924 81.4794 58.8785 81.4664C54.2646 81.4533 50.5181 83.6093 50.5106 86.2819C50.503 88.9545 54.2371 91.1317 58.851 91.1448C63.4649 91.1579 67.2114 89.0019 67.2189 86.3293Z" fill="#F9B233"/>
        <path d="M58.8876 90.2764C56.8031 90.2764 54.8577 89.8187 53.4108 88.9767C52.1029 88.2247 51.3835 87.2683 51.3835 86.3037C51.3835 85.3391 52.0865 84.3991 53.3781 83.6552C54.8168 82.8214 56.7623 82.3555 58.8386 82.3555C60.923 82.3555 62.8685 82.8132 64.3154 83.6552C65.6233 84.4072 66.3426 85.3636 66.3426 86.3282C66.3426 87.2928 65.6396 88.2328 64.3481 88.9767C62.9094 89.8105 60.9639 90.2764 58.8876 90.2764Z" fill="#B88325"/>
        <path d="M58.8876 90.6866C56.8031 90.6866 54.8577 90.2288 53.4108 89.3869C52.1029 88.6348 51.3835 87.6784 51.3835 86.7138C51.3835 85.7493 52.0865 84.8092 53.3781 84.0654C54.8168 83.2316 56.7623 82.7656 58.8386 82.7656C60.923 82.7656 62.8685 83.2234 64.3154 84.0654C65.6233 84.8174 66.3426 85.7738 66.3426 86.7384C66.3426 87.7029 65.6396 88.643 64.3481 89.3869C62.9094 90.2206 60.9639 90.6866 58.8876 90.6866Z" fill="#FFCA38"/>
        <path d="M63.1138 88.854L62.2637 88.3635L61.7732 88.6496L62.6233 88.6987V89.1401L61.7977 89.6224L60.9476 89.1319L60.2773 89.5243L53.9912 85.8949V85.4534L54.6615 85.5107L53.7869 85.0039V84.5624L54.6125 84.5297L55.4871 85.0366L55.9776 84.7505L55.1029 84.2436V83.8022L55.9285 83.7695L56.8522 84.3009C57.8005 83.9085 58.6261 83.9657 59.4272 84.4317L59.656 84.5624C60.3018 84.9385 60.4817 85.3063 60.1629 85.7314C60.9966 85.4861 61.716 85.6169 62.3863 86.0011L62.9013 86.2954C63.3427 86.5488 63.5634 86.8267 63.5634 87.1128C63.5634 87.3581 63.3999 87.6197 63.0648 87.8812L63.9476 87.9466V88.388L63.1138 88.854ZM60.547 88.3226L61.7078 87.6524C62.1002 87.4235 62.1656 87.2109 61.6915 86.9412L61.1438 86.6224C60.5634 86.2872 60.1547 86.3118 59.6234 86.6224L58.6097 87.2109L60.547 88.3226ZM57.7187 86.6878L58.667 86.1401C59.1002 85.8867 59.2065 85.6415 58.6997 85.3472L58.3481 85.1428C57.8986 84.8812 57.5225 84.8731 57.1057 85.1183L56.0512 85.7314L57.7187 86.6878Z" fill="#B88325"/>
        <path d="M63.5468 86.6484H63.2117V87.0898H63.5468V86.6484Z" fill="#B88325"/>
        <path d="M60.3019 84.8984H59.9668V85.3398H60.3019V84.8984Z" fill="#B88325"/>
        <path d="M63.1138 88.4058L62.2637 87.9154L61.7732 88.2015L62.6233 88.692L61.7977 89.1742L60.9476 88.6838L60.2773 89.0762L53.9912 85.4467L54.6615 85.0544L53.7869 84.5476L54.6125 84.0653L55.4871 84.5721L55.9776 84.286L55.1029 83.7792L55.9285 83.2969L56.8522 83.8282C57.8005 83.4358 58.6261 83.4931 59.4272 83.959L59.656 84.0898C60.3018 84.4658 60.4817 84.8336 60.1629 85.2587C60.9966 85.0135 61.716 85.1443 62.3863 85.5285L62.9013 85.8227C63.3427 86.0762 63.5634 86.3541 63.5634 86.6402C63.5634 86.8854 63.3999 87.147 63.0648 87.4086L63.9476 87.9236L63.1138 88.4058ZM60.547 87.8745L61.7078 87.2042C62.1002 86.9753 62.1656 86.7628 61.6915 86.4931L61.1438 86.1743C60.5634 85.8391 60.1547 85.8636 59.6234 86.1743L58.6097 86.7628L60.547 87.8745ZM57.7187 86.2396L58.667 85.692C59.1002 85.4386 59.2065 85.1933 58.6997 84.8991L58.3481 84.6947C57.8986 84.4331 57.5225 84.4249 57.1057 84.6702L56.0512 85.2832L57.7187 86.2396Z" fill="#F9B233"/>
        <path d="M67.2175 84.2834L67.2094 86C67.2094 87.2344 66.4001 88.4605 64.7816 89.4006C61.5282 91.2888 56.2394 91.2888 52.9696 89.4006C51.3266 88.4523 50.501 87.2017 50.501 85.951L50.5091 84.2344C50.5091 85.4851 51.3266 86.7276 52.9778 87.684C56.2475 89.5722 61.5445 89.5722 64.7897 87.684C66.4001 86.7439 67.2093 85.5178 67.2175 84.2834Z" fill="#B88325"/>
        <path d="M67.2145 84.2785C67.2221 81.6059 63.488 79.4287 58.8741 79.4156C54.2602 79.4025 50.5137 81.5585 50.5062 84.2311C50.4986 86.9037 54.2327 89.0809 58.8466 89.094C63.4605 89.1071 67.207 86.9511 67.2145 84.2785Z" fill="#F9B233"/>
        <path d="M58.8878 88.2217C56.8034 88.2217 54.8579 87.764 53.411 86.922C52.1031 86.17 51.3838 85.2136 51.3838 84.249C51.3838 83.2844 52.0868 82.3444 53.3783 81.6005C54.817 80.7667 56.7625 80.3008 58.8388 80.3008C60.9233 80.3008 62.8688 80.7585 64.3156 81.6005C65.6235 82.3525 66.3429 83.3089 66.3429 84.2735C66.3429 85.2381 65.6399 86.1781 64.3483 86.922C62.9096 87.764 60.9641 88.2217 58.8878 88.2217Z" fill="#B88325"/>
        <path d="M58.8878 88.628C56.8034 88.628 54.8579 88.1702 53.411 87.3283C52.1031 86.5762 51.3838 85.6198 51.3838 84.6552C51.3838 83.6907 52.0868 82.7506 53.3783 82.0068C54.817 81.173 56.7625 80.707 58.8388 80.707C60.9233 80.707 62.8688 81.1648 64.3156 82.0068C65.6235 82.7588 66.3429 83.7152 66.3429 84.6798C66.3429 85.6443 65.6399 86.5844 64.3483 87.3283C62.9096 88.1702 60.9641 88.628 58.8878 88.628Z" fill="#FFCA38"/>
        <path d="M63.1138 86.7993L62.2637 86.3088L61.7732 86.5949L62.6233 86.644V87.0854L61.7977 87.5677L60.9476 87.0772L60.2773 87.4696L53.9912 83.8402V83.3988L54.6615 83.456L53.7869 82.9492V82.5078L54.6125 82.4751L55.4871 82.9819L55.9776 82.6958L55.1029 82.189V81.7475L55.9285 81.7148L56.8522 82.2462C57.8005 81.8538 58.6261 81.911 59.4272 82.377L59.656 82.5078C60.3018 82.8838 60.4817 83.2516 60.1629 83.6767C60.9966 83.4315 61.716 83.5623 62.3863 83.9464L62.9013 84.2407C63.3427 84.4941 63.5634 84.7721 63.5634 85.0582C63.5634 85.3034 63.3999 85.565 63.0648 85.8266L63.9476 85.8919V86.3334L63.1138 86.7993ZM60.547 86.268L61.7078 85.5977C62.1002 85.3688 62.1656 85.1562 61.6915 84.8865L61.1438 84.5677C60.5634 84.2325 60.1547 84.2571 59.6234 84.5677L58.6097 85.1562L60.547 86.268ZM57.7187 84.6331L58.667 84.0854C59.1002 83.832 59.2065 83.5868 58.6997 83.2925L58.3481 83.0881C57.8986 82.8266 57.5225 82.8184 57.1057 83.0636L56.0512 83.6767L57.7187 84.6331Z" fill="#B88325"/>
        <path d="M63.5468 84.5938H63.2117V85.0352H63.5468V84.5938Z" fill="#B88325"/>
        <path d="M60.3019 82.8438H59.9668V83.2852H60.3019V82.8438Z" fill="#B88325"/>
        <path d="M63.1138 86.359L62.2637 85.8685L61.7732 86.1546L62.6233 86.6451L61.7977 87.1274L60.9476 86.6369L60.2773 87.0293L53.9912 83.3999L54.6615 83.0075L53.7869 82.5007L54.6125 82.0184L55.4871 82.5252L55.9776 82.2391L55.1029 81.7323L55.9285 81.25L56.8522 81.7813C57.8005 81.389 58.6261 81.4462 59.4272 81.9121L59.656 82.0429C60.3018 82.4189 60.4817 82.7868 60.1629 83.2118C60.9966 82.9666 61.716 83.0974 62.3863 83.4816L62.9013 83.7759C63.3427 84.0293 63.5634 84.3072 63.5634 84.5933C63.5634 84.8385 63.3999 85.1001 63.0648 85.3617L63.9476 85.8767L63.1138 86.359ZM60.547 85.8276L61.7078 85.1573C62.1002 84.9285 62.1656 84.7159 61.6915 84.4462L61.1438 84.1274C60.5634 83.7922 60.1547 83.8168 59.6234 84.1274L58.6097 84.7159L60.547 85.8276ZM57.7187 84.1928L58.667 83.6451C59.1002 83.3917 59.2065 83.1464 58.6997 82.8522L58.3481 82.6478C57.8986 82.3862 57.5225 82.3781 57.1057 82.6233L56.0512 83.2364L57.7187 84.1928Z" fill="#F9B233"/>
        <path d="M67.2178 82.2444L67.2096 83.961C67.2096 85.1953 66.4003 86.4215 64.7818 87.3615C61.5284 89.2498 56.2396 89.2498 52.9699 87.3615C51.3268 86.4133 50.5012 85.1626 50.5012 83.9119L50.5094 82.1953C50.5094 83.4378 51.3268 84.6885 52.978 85.6449C56.2478 87.5332 61.5448 87.5332 64.79 85.6449C66.4003 84.6967 67.2096 83.4705 67.2178 82.2444Z" fill="#B88325"/>
        <path d="M67.2189 82.2394C67.2265 79.5668 63.4924 77.3896 58.8785 77.3765C54.2646 77.3634 50.5181 79.5194 50.5106 82.192C50.503 84.8647 54.2371 87.0419 58.851 87.0549C63.4649 87.068 67.2114 84.9121 67.2189 82.2394Z" fill="#F9B233"/>
        <path d="M58.8881 86.1866C56.8036 86.1866 54.8581 85.7288 53.4113 84.8869C52.1034 84.1348 51.384 83.1784 51.384 82.2138C51.384 81.2493 52.087 80.3092 53.3786 79.5654C54.8173 78.7316 56.7628 78.2656 58.839 78.2656C60.9235 78.2656 62.869 78.7234 64.3159 79.5654C65.6238 80.3174 66.3431 81.2738 66.3431 82.2384C66.3431 83.2029 65.6401 84.143 64.3486 84.8869C62.9099 85.7206 60.9644 86.1866 58.8881 86.1866Z" fill="#B88325"/>
        <path d="M58.8881 86.5928C56.8036 86.5928 54.8581 86.1351 53.4113 85.2931C52.1034 84.5411 51.384 83.5847 51.384 82.6201C51.384 81.6555 52.087 80.7155 53.3786 79.9716C54.8173 79.1378 56.7628 78.6719 58.839 78.6719C60.9235 78.6719 62.869 79.1296 64.3159 79.9716C65.6238 80.7236 66.3431 81.68 66.3431 82.6446C66.3431 83.6092 65.6401 84.5492 64.3486 85.2931C62.9099 86.1269 60.9644 86.5928 58.8881 86.5928Z" fill="#FFCA38"/>
        <path d="M63.1138 84.7641L62.2637 84.2737L61.7732 84.5598L62.6233 84.6088V85.0502L61.7977 85.5325L60.9476 85.0421L60.2773 85.4344L53.9912 81.805V81.3636L54.6615 81.4208L53.7869 80.914V80.4726L54.6125 80.4399L55.4871 80.9467L55.9776 80.6606L55.1029 80.1538V79.7124L55.9285 79.6797L56.8522 80.211C57.8005 79.8187 58.6261 79.8759 59.4272 80.3418L59.656 80.4726C60.3018 80.8486 60.4817 81.2165 60.1629 81.6415C60.9966 81.3963 61.716 81.5271 62.3863 81.9113L62.9013 82.2056C63.3427 82.459 63.5634 82.7369 63.5634 83.023C63.5634 83.2682 63.3999 83.5298 63.0648 83.7914L63.9476 83.8568V84.2982L63.1138 84.7641ZM60.547 84.2328L61.7078 83.5625C62.1002 83.3336 62.1656 83.1211 61.6915 82.8513L61.1438 82.5244C60.5634 82.1892 60.1547 82.2137 59.6234 82.5244L58.6097 83.1129L60.547 84.2328ZM57.7187 82.5979L58.667 82.0503C59.1002 81.7968 59.2065 81.5516 58.6997 81.2573L58.3481 81.053C57.8986 80.7914 57.5225 80.7832 57.1057 81.0285L56.0512 81.6415L57.7187 82.5979Z" fill="#B88325"/>
        <path d="M63.5471 82.5547H63.2119V82.9961H63.5471V82.5547Z" fill="#B88325"/>
        <path d="M60.3019 80.8047H59.9668V81.2461H60.3019V80.8047Z" fill="#B88325"/>
        <path d="M63.1141 84.3121L62.2639 83.8216L61.7734 84.1077L62.6236 84.5982L61.798 85.0805L60.9479 84.59L60.2776 84.9824L53.9915 81.353L54.6618 80.9606L53.7871 80.4538L54.6127 79.9715L55.4874 80.4783L55.9778 80.1922L55.1032 79.6854L55.9288 79.2031L56.8525 79.7345C57.8007 79.3421 58.6263 79.3993 59.4274 79.8653L59.6563 79.996C60.3021 80.3721 60.4819 80.7399 60.1631 81.165C60.9969 80.9197 61.7162 81.0505 62.3865 81.4347L62.9015 81.729C63.3429 81.9824 63.5636 82.2603 63.5636 82.5464C63.5636 82.7917 63.4002 83.0533 63.065 83.3148L63.9478 83.8298L63.1141 84.3121ZM60.5473 83.7808L61.7081 83.1105C62.1004 82.8816 62.1658 82.6691 61.6917 82.3993L61.144 82.0805C60.5637 81.7454 60.1549 81.7699 59.6236 82.0805L58.61 82.6691L60.5473 83.7808ZM57.719 82.1459L58.6672 81.5982C59.1004 81.3448 59.2067 81.0996 58.6999 80.8053L58.3484 80.6009C57.8988 80.3394 57.5228 80.3312 57.1059 80.5764L56.0514 81.1895L57.719 82.1459Z" fill="#F9B233"/>
        <path d="M67.2175 80.1897L67.2094 81.9063C67.2094 83.1406 66.4001 84.3668 64.7816 85.3068C61.5282 87.1951 56.2394 87.1951 52.9696 85.3068C51.3266 84.3586 50.501 83.1079 50.501 81.8572L50.5091 80.1406C50.5091 81.3913 51.3266 82.6338 52.9778 83.5902C56.2475 85.4785 61.5445 85.4785 64.7897 83.5902C66.4001 82.6502 67.2093 81.424 67.2175 80.1897Z" fill="#B88325"/>
        <path d="M67.2146 80.1849C67.2222 77.5123 63.4881 75.335 58.8742 75.3219C54.2603 75.3088 50.5138 77.4647 50.5062 80.1374C50.4986 82.81 54.2327 84.9872 58.8466 85.0003C63.4605 85.0135 67.207 82.8575 67.2146 80.1849Z" fill="#F9B233"/>
        <path d="M58.8878 84.128C56.8034 84.128 54.8579 83.6702 53.411 82.8283C52.1031 82.0762 51.3838 81.1198 51.3838 80.1552C51.3838 79.1907 52.0868 78.2506 53.3783 77.5068C54.817 76.673 56.7625 76.207 58.8388 76.207C60.9233 76.207 62.8688 76.6648 64.3156 77.5068C65.6235 78.2588 66.3429 79.2152 66.3429 80.1798C66.3429 81.1443 65.6399 82.0844 64.3483 82.8283C62.9096 83.6702 60.9641 84.128 58.8878 84.128Z" fill="#B88325"/>
        <path d="M58.8878 84.5342C56.8034 84.5342 54.8579 84.0765 53.411 83.2345C52.1031 82.4825 51.3838 81.5261 51.3838 80.5615C51.3838 79.5969 52.0868 78.6569 53.3783 77.913C54.817 77.0792 56.7625 76.6133 58.8388 76.6133C60.9233 76.6133 62.8688 77.071 64.3156 77.913C65.6235 78.665 66.3429 79.6214 66.3429 80.586C66.3429 81.5506 65.6399 82.4906 64.3483 83.2345C62.9096 84.0765 60.9641 84.5342 58.8878 84.5342Z" fill="#FFCA38"/>
        <path d="M55.398 101.572L55.3898 103.289C55.3898 104.523 54.5805 105.75 52.962 106.69C49.7086 108.578 44.4198 108.578 41.1501 106.69C39.4988 105.741 38.6814 104.491 38.6814 103.24L38.6896 101.523C38.6896 102.774 39.507 104.017 41.1582 104.973C44.428 106.861 49.725 106.861 52.9702 104.973C54.5887 104.033 55.398 102.807 55.398 101.572Z" fill="#B88325"/>
        <path d="M52.9374 98.1311C56.2072 100.019 56.2317 103.085 52.9783 104.973C49.7249 106.861 44.4361 106.861 41.1664 104.973C37.8966 103.085 37.8721 100.019 41.1255 98.1311C44.3707 96.2428 49.6595 96.2428 52.9374 98.1311Z" fill="#F9B233"/>
        <path d="M47.0761 105.511C44.9916 105.511 43.0461 105.053 41.5993 104.211C40.2914 103.459 39.572 102.503 39.572 101.538C39.572 100.573 40.275 99.6334 41.5666 98.8896C43.0053 98.0558 44.9508 97.5898 47.0271 97.5898C49.1115 97.5898 51.057 98.0476 52.5039 98.8896C53.8118 99.6416 54.5311 100.598 54.5311 101.563C54.5311 102.527 53.8281 103.467 52.5366 104.211C51.0897 105.053 49.1524 105.511 47.0761 105.511Z" fill="#B88325"/>
        <path d="M47.0761 105.921C44.9916 105.921 43.0461 105.463 41.5993 104.621C40.2914 103.869 39.572 102.913 39.572 101.948C39.572 100.984 40.275 100.044 41.5666 99.2997C43.0053 98.4659 44.9508 98 47.0271 98C49.1115 98 51.057 98.4578 52.5039 99.2997C53.8118 100.052 54.5311 101.008 54.5311 101.973C54.5311 102.937 53.8281 103.877 52.5366 104.621C51.0897 105.463 49.1524 105.921 47.0761 105.921Z" fill="#FFCA38"/>
        <path d="M51.3023 104.096L50.4522 103.606L49.9617 103.892L50.8118 103.941V104.382L49.9862 104.865L49.1361 104.374L48.4658 104.766L42.1797 101.137V100.696L42.85 100.753L41.9753 100.246V99.8046L42.801 99.7719L43.6756 100.279L44.1661 99.9926L43.2914 99.4858V99.0444L44.117 99.0117L45.0407 99.5431C45.989 99.1507 46.8146 99.2079 47.6157 99.6738L47.8445 99.8046C48.4903 100.181 48.6702 100.549 48.3514 100.974C49.1851 100.728 49.9045 100.859 50.5748 101.243L51.0898 101.538C51.5312 101.791 51.7519 102.069 51.7519 102.355C51.7519 102.6 51.5884 102.862 51.2532 103.123L52.1361 103.189V103.63L51.3023 104.096ZM48.7274 103.565L49.8881 102.895C50.2805 102.666 50.3459 102.453 49.8718 102.183L49.3241 101.865C48.7437 101.529 48.335 101.554 47.8037 101.865L46.79 102.453L48.7274 103.565ZM45.899 101.93L46.8473 101.382C47.2805 101.129 47.3868 100.884 46.8881 100.589L46.5366 100.385C46.0871 100.123 45.711 100.115 45.2941 100.36L44.2397 100.974L45.899 101.93Z" fill="#B88325"/>
        <path d="M51.7353 101.891H51.4001V102.332H51.7353V101.891Z" fill="#B88325"/>
        <path d="M48.4904 100.141H48.1553V100.582H48.4904V100.141Z" fill="#B88325"/>
        <path d="M51.3023 103.648L50.4522 103.158L49.9617 103.444L50.8118 103.934L49.9862 104.416L49.1361 103.926L48.4658 104.318L42.1797 100.689L42.85 100.297L41.9753 99.7897L42.801 99.3075L43.6756 99.8143L44.1661 99.5282L43.2914 99.0214L44.117 98.5391L45.0407 99.0704C45.989 98.678 46.8146 98.7353 47.6157 99.2012L47.8445 99.332C48.4903 99.708 48.6702 100.076 48.3514 100.501C49.1851 100.256 49.9045 100.386 50.5748 100.771L51.0898 101.065C51.5312 101.318 51.7519 101.596 51.7519 101.882C51.7519 102.128 51.5884 102.389 51.2532 102.651L52.1361 103.166L51.3023 103.648ZM48.7274 103.117L49.8881 102.446C50.2805 102.218 50.3459 102.005 49.8718 101.735L49.3241 101.416C48.7437 101.081 48.335 101.106 47.8037 101.416L46.79 102.005L48.7274 103.117ZM45.899 101.482L46.8473 100.934C47.2805 100.681 47.3868 100.436 46.8881 100.141L46.5366 99.9369C46.0871 99.6753 45.711 99.6671 45.2941 99.9124L44.2397 100.525L45.899 101.482Z" fill="#F9B233"/>
        <path d="M55.398 99.5256L55.3898 101.242C55.3898 102.477 54.5805 103.703 52.962 104.643C49.7086 106.531 44.4198 106.531 41.1501 104.643C39.4988 103.695 38.6814 102.444 38.6814 101.193L38.6896 99.4766C38.6896 100.727 39.507 101.97 41.1582 102.926C44.428 104.814 49.725 104.814 52.9702 102.926C54.5887 101.986 55.398 100.76 55.398 99.5256Z" fill="#B88325"/>
        <path d="M52.9374 96.0764C56.2072 97.9646 56.2317 101.03 52.9783 102.918C49.7249 104.807 44.4361 104.807 41.1664 102.918C37.8966 101.03 37.8721 97.9646 41.1255 96.0764C44.3707 94.1881 49.6595 94.1881 52.9374 96.0764Z" fill="#F9B233"/>
        <path d="M47.0763 103.464C44.9919 103.464 43.0464 103.006 41.5995 102.164C40.2916 101.412 39.5723 100.456 39.5723 99.4912C39.5723 98.5266 40.2753 97.5866 41.5668 96.8427C43.0055 96.0089 44.951 95.543 47.0273 95.543C49.1118 95.543 51.0572 96.0007 52.5041 96.8427C53.812 97.5947 54.5314 98.5511 54.5314 99.5157C54.5314 100.48 53.8284 101.42 52.5368 102.164C51.0899 103.006 49.1526 103.464 47.0763 103.464Z" fill="#B88325"/>
        <path d="M47.0763 103.874C44.9919 103.874 43.0464 103.416 41.5995 102.574C40.2916 101.822 39.5723 100.866 39.5723 99.9013C39.5723 98.9368 40.2753 97.9967 41.5668 97.2528C43.0055 96.4191 44.951 95.9531 47.0273 95.9531C49.1118 95.9531 51.0572 96.4109 52.5041 97.2528C53.812 98.0049 54.5314 98.9613 54.5314 99.9259C54.5314 100.89 53.8284 101.83 52.5368 102.574C51.0899 103.416 49.1526 103.874 47.0763 103.874Z" fill="#FFCA38"/>
        <path d="M51.3023 102.045L50.4522 101.555L49.9617 101.841L50.8118 101.89V102.331L49.9862 102.814L49.1361 102.323L48.4658 102.716L42.1797 99.0863V98.6449L42.85 98.7021L41.9753 98.1953V97.7539L42.801 97.7211L43.6756 98.228L44.1661 97.9419L43.2914 97.4351V96.9936L44.117 96.9609L45.0407 97.4923C45.989 97.0999 46.8146 97.1571 47.6157 97.6231L47.8445 97.7539C48.4903 98.1299 48.6702 98.4977 48.3514 98.9228C49.1851 98.6775 49.9045 98.8083 50.5748 99.1925L51.0898 99.4868C51.5312 99.7402 51.7519 100.018 51.7519 100.304C51.7519 100.549 51.5884 100.811 51.2532 101.073L52.1361 101.138V101.579L51.3023 102.045ZM48.7274 101.514L49.8881 100.844C50.2805 100.615 50.3459 100.402 49.8718 100.133L49.3241 99.8138C48.7437 99.4786 48.335 99.5032 47.8037 99.8138L46.79 100.402L48.7274 101.514ZM45.899 99.8792L46.8473 99.3315C47.2805 99.0781 47.3868 98.8329 46.8881 98.5386L46.5366 98.3342C46.0871 98.0726 45.711 98.0645 45.2941 98.3097L44.2397 98.9228L45.899 99.8792Z" fill="#B88325"/>
        <path d="M51.7353 99.8398H51.4001V100.281H51.7353V99.8398Z" fill="#B88325"/>
        <path d="M48.4902 98.0898H48.155V98.5313H48.4902V98.0898Z" fill="#B88325"/>
        <path d="M51.302 101.605L50.4519 101.115L49.9615 101.401L50.8116 101.891L49.986 102.373L49.1358 101.883L48.4655 102.275L42.1795 98.646L42.8498 98.2536L41.9751 97.7468L42.8007 97.2645L43.6754 97.7713L44.1658 97.4852L43.2912 96.9784L44.1168 96.4961L45.0405 97.0274C45.9887 96.6351 46.8143 96.6923 47.6154 97.1582L47.8443 97.289C48.4901 97.665 48.6699 98.0329 48.3511 98.4579C49.1849 98.2127 49.9042 98.3435 50.5745 98.7277L51.0895 99.022C51.5309 99.2754 51.7516 99.5533 51.7516 99.8394C51.7516 100.085 51.5881 100.346 51.253 100.608L52.1358 101.123L51.302 101.605ZM48.7271 101.074L49.8879 100.403C50.2803 100.175 50.3456 99.962 49.8715 99.6923L49.3239 99.3735C48.7435 99.0383 48.3348 99.0628 47.8034 99.3735L46.7898 99.962L48.7271 101.074ZM45.8988 99.4389L46.847 98.8912C47.2803 98.6378 47.3865 98.3925 46.8879 98.0983L46.5364 97.8939C46.0868 97.6323 45.7108 97.6242 45.2939 97.8694L44.2394 98.4825L45.8988 99.4389Z" fill="#F9B233"/>
        <path d="M55.3977 97.4865L55.3895 99.2032C55.3895 100.437 54.5803 101.664 52.9618 102.604C49.7084 104.492 44.4196 104.492 41.1498 102.604C39.4986 101.655 38.6812 100.405 38.6812 99.1541L38.6893 97.4375C38.6893 98.6882 39.5068 99.9307 41.158 100.887C44.4277 102.775 49.7247 102.775 52.9699 100.887C54.5885 99.9389 55.3977 98.7127 55.3977 97.4865Z" fill="#B88325"/>
        <path d="M52.9374 94.0373C56.2072 95.9256 56.2317 98.991 52.9783 100.879C49.7249 102.768 44.4361 102.768 41.1664 100.879C37.8966 98.991 37.8721 95.9256 41.1255 94.0373C44.3707 92.149 49.6595 92.149 52.9374 94.0373Z" fill="#F9B233"/>
        <path d="M47.0761 101.425C44.9916 101.425 43.0461 100.967 41.5993 100.125C40.2914 99.3731 39.572 98.4167 39.572 97.4521C39.572 96.4875 40.275 95.5475 41.5666 94.8036C43.0053 93.9698 44.9508 93.5039 47.0271 93.5039C49.1115 93.5039 51.057 93.9617 52.5039 94.8036C53.8118 95.5557 54.5311 96.5121 54.5311 97.4766C54.5311 98.4412 53.8281 99.3813 52.5366 100.125C51.0897 100.959 49.1524 101.425 47.0761 101.425Z" fill="#B88325"/>
        <path d="M47.0761 101.827C44.9916 101.827 43.0461 101.369 41.5993 100.527C40.2914 99.7754 39.572 98.819 39.572 97.8545C39.572 96.8899 40.275 95.9498 41.5666 95.206C43.0053 94.3722 44.9508 93.9062 47.0271 93.9062C49.1115 93.9062 51.057 94.364 52.5039 95.206C53.8118 95.958 54.5311 96.9144 54.5311 97.879C54.5311 98.8436 53.8281 99.7836 52.5366 100.527C51.0897 101.369 49.1524 101.827 47.0761 101.827Z" fill="#FFCA38"/>
        <path d="M51.3023 100.002L50.4522 99.512L49.9617 99.7981L50.8118 99.8471V100.289L49.9862 100.771L49.1361 100.28L48.4658 100.673L42.1797 97.0433V96.6019L42.85 96.6591L41.9753 96.1523V95.7109L42.801 95.6782L43.6756 96.185L44.1661 95.8989L43.2914 95.3921V94.9507L44.117 94.918L45.0407 95.4493C45.989 95.0569 46.8146 95.1142 47.6157 95.5801L47.8445 95.7109C48.4903 96.0869 48.6702 96.4547 48.3514 96.8798C49.1851 96.6346 49.9045 96.7654 50.5748 97.1496L51.0898 97.4438C51.5312 97.6972 51.7519 97.9752 51.7519 98.2613C51.7519 98.5065 51.5884 98.7681 51.2532 99.0297L52.1361 99.0951V99.5365L51.3023 100.002ZM48.7274 99.4711L49.8881 98.8008C50.2805 98.5719 50.3459 98.3594 49.8718 98.0896L49.3241 97.7708C48.7437 97.4357 48.335 97.4602 47.8037 97.7708L46.79 98.3594L48.7274 99.4711ZM45.899 97.8362L46.8473 97.2885C47.2805 97.0351 47.3868 96.7899 46.8881 96.4956L46.5366 96.2913C46.0871 96.0297 45.711 96.0215 45.2941 96.2667L44.2397 96.8798L45.899 97.8362Z" fill="#B88325"/>
        <path d="M51.7353 97.7969H51.4001V98.2383H51.7353V97.7969Z" fill="#B88325"/>
        <path d="M48.4904 96.0469H48.1553V96.4883H48.4904V96.0469Z" fill="#B88325"/>
        <path d="M51.3023 99.5543L50.4522 99.0638L49.9617 99.3499L50.8118 99.8404L49.9862 100.323L49.1361 99.8322L48.4658 100.225L42.1797 96.5952L42.85 96.2028L41.9753 95.696L42.801 95.2137L43.6756 95.7205L44.1661 95.4344L43.2914 94.9276L44.117 94.4453L45.0407 94.9766C45.989 94.5843 46.8146 94.6415 47.6157 95.1074L47.8445 95.2382C48.4903 95.6142 48.6702 95.9821 48.3514 96.4072C49.1851 96.1619 49.9045 96.2927 50.5748 96.6769L51.0898 96.9712C51.5312 97.2246 51.7519 97.5025 51.7519 97.7886C51.7519 98.0339 51.5884 98.2954 51.2532 98.557L52.1361 99.072L51.3023 99.5543ZM48.7274 99.023L49.8881 98.3527C50.2805 98.1238 50.3459 97.9112 49.8718 97.6415L49.3241 97.3227C48.7437 96.9875 48.335 97.0121 47.8037 97.3227L46.79 97.9112L48.7274 99.023ZM45.899 97.3881L46.8473 96.8404C47.2805 96.587 47.3868 96.3418 46.8881 96.0475L46.5366 95.8431C46.0871 95.5816 45.711 95.5734 45.2941 95.8186L44.2397 96.4317L45.899 97.3881Z" fill="#F9B233"/>
        <path d="M55.3967 95.4319L55.3886 97.1485C55.3886 98.3828 54.5793 99.609 52.9608 100.549C49.7074 102.437 44.4186 102.437 41.1488 100.549C39.4976 99.6008 38.6802 98.3501 38.6802 97.0994L38.6884 95.3828C38.6884 96.6253 39.5058 97.876 41.157 98.8324C44.4267 100.721 49.7237 100.721 52.969 98.8324C54.5875 97.8923 55.3967 96.6662 55.3967 95.4319Z" fill="#B88325"/>
        <path d="M55.3988 95.4346C55.4064 92.762 51.6722 90.5848 47.0583 90.5717C42.4444 90.5587 38.698 92.7147 38.6905 95.3873C38.6829 98.06 42.4171 100.237 47.031 100.25C51.6448 100.263 55.3913 98.1072 55.3988 95.4346Z" fill="#F9B233"/>
        <path d="M47.0754 99.3741C44.9909 99.3741 43.0454 98.9163 41.5985 98.0744C40.2906 97.3223 39.5713 96.3659 39.5713 95.4013C39.5713 94.4368 40.2743 93.4967 41.5658 92.7529C43.0045 91.9191 44.95 91.4531 47.0263 91.4531C49.1108 91.4531 51.0563 91.9109 52.5031 92.7529C53.811 93.5049 54.5304 94.4613 54.5304 95.4259C54.5304 96.3904 53.8274 97.3305 52.5358 98.0744C51.089 98.9163 49.1517 99.3741 47.0754 99.3741Z" fill="#B88325"/>
        <path d="M47.0754 99.7803C44.9909 99.7803 43.0454 99.3226 41.5985 98.4806C40.2906 97.7286 39.5713 96.7722 39.5713 95.8076C39.5713 94.843 40.2743 93.903 41.5658 93.1591C43.0045 92.3253 44.95 91.8594 47.0263 91.8594C49.1108 91.8594 51.0563 92.3171 52.5031 93.1591C53.811 93.9111 54.5304 94.8675 54.5304 95.8321C54.5304 96.7967 53.8274 97.7367 52.5358 98.4806C51.089 99.3226 49.1517 99.7803 47.0754 99.7803Z" fill="#FFCA38"/>
        <path d="M51.3018 97.9516L50.4517 97.4612L49.9612 97.7473L50.8113 97.7963V98.2377L49.9857 98.72L49.1356 98.2296L48.4653 98.6219L42.1792 94.9925V94.5511L42.8495 94.6083L41.9749 94.1015V93.6601L42.8005 93.6274L43.6751 94.1342L44.1656 93.8481L43.2909 93.3413V92.8999L44.1166 92.8672L45.0402 93.3985C45.9885 93.0061 46.8141 93.0634 47.6152 93.5293L47.8441 93.6601C48.4898 94.0361 48.6697 94.404 48.3509 94.829C49.1847 94.5838 49.904 94.7146 50.5743 95.0988L51.0893 95.3931C51.5307 95.6465 51.7514 95.9244 51.7514 96.2105C51.7514 96.4557 51.5879 96.7173 51.2528 96.9789L52.1356 97.0443V97.4857L51.3018 97.9516ZM48.7269 97.4203L49.8876 96.75C50.28 96.5211 50.3454 96.3086 49.8713 96.0388L49.3236 95.72C48.7432 95.3849 48.3345 95.4094 47.8032 95.72L46.7896 96.3086L48.7269 97.4203ZM45.8986 95.7854L46.8468 95.2377C47.28 94.9843 47.3863 94.7391 46.8876 94.4448L46.5362 94.2405C46.0866 93.9789 45.7105 93.9707 45.2936 94.216L44.2392 94.829L45.8986 95.7854Z" fill="#B88325"/>
        <path d="M51.7348 95.7461H51.3997V96.1875H51.7348V95.7461Z" fill="#B88325"/>
        <path d="M48.4897 93.9961H48.1545V94.4375H48.4897V93.9961Z" fill="#B88325"/>
        <path d="M51.3018 97.5113L50.4517 97.0209L49.9612 97.307L50.8113 97.7974L49.9857 98.2797L49.1356 97.7892L48.4653 98.1816L42.1792 94.5522L42.8495 94.1598L41.9749 93.653L42.8005 93.1707L43.6751 93.6775L44.1656 93.3914L43.2909 92.8846L44.1166 92.4023L45.0402 92.9337C45.9885 92.5413 46.8141 92.5985 47.6152 93.0645L47.8441 93.1953C48.4898 93.5713 48.6697 93.9391 48.3509 94.3642C49.1847 94.119 49.904 94.2497 50.5743 94.6339L51.0893 94.9282C51.5307 95.1816 51.7514 95.4595 51.7514 95.7457C51.7514 95.9909 51.5879 96.2525 51.2528 96.514L52.1356 97.029L51.3018 97.5113ZM48.7269 96.98L49.8876 96.3097C50.28 96.0808 50.3454 95.8683 49.8713 95.5985L49.3236 95.2797C48.7432 94.9446 48.3345 94.9691 47.8032 95.2797L46.7896 95.8683L48.7269 96.98ZM45.8986 95.3451L46.8468 94.7974C47.28 94.544 47.3863 94.2988 46.8876 94.0045L46.5362 93.8002C46.0866 93.5386 45.7105 93.5304 45.2936 93.7756L44.2392 94.3887L45.8986 95.3451Z" fill="#F9B233"/>
        <path d="M55.398 93.3928L55.3898 95.1094C55.3898 96.3437 54.5805 97.5699 52.962 98.5099C49.7086 100.398 44.4198 100.398 41.1501 98.5099C39.4988 97.5617 38.6814 96.311 38.6814 95.0604L38.6896 93.3438C38.6896 94.5863 39.507 95.8369 41.1582 96.7933C44.428 98.6816 49.725 98.6816 52.9702 96.7933C54.5887 95.8451 55.398 94.619 55.398 93.3928Z" fill="#B88325"/>
        <path d="M55.4042 93.3917C55.4118 90.719 51.6776 88.5418 47.0637 88.5288C42.4498 88.5157 38.7034 90.6717 38.6958 93.3444C38.6883 96.017 42.4224 98.1942 47.0363 98.2072C51.6502 98.2203 55.3966 96.0643 55.4042 93.3917Z" fill="#F9B233"/>
        <path d="M47.0766 97.335C44.9921 97.335 43.0466 96.8773 41.5998 96.0353C40.2919 95.2833 39.5725 94.3269 39.5725 93.3623C39.5725 92.3977 40.2755 91.4577 41.5671 90.7138C43.0057 89.88 44.9513 89.4141 47.0275 89.4141C49.112 89.4141 51.0575 89.8718 52.5043 90.7138C53.8122 91.4658 54.5316 92.4222 54.5316 93.3868C54.5316 94.3514 53.8286 95.2914 52.5371 96.0353C51.0902 96.8691 49.1529 97.335 47.0766 97.335Z" fill="#B88325"/>
        <path d="M47.0766 97.7413C44.9921 97.7413 43.0466 97.2835 41.5998 96.4415C40.2919 95.6895 39.5725 94.7331 39.5725 93.7685C39.5725 92.804 40.2755 91.8639 41.5671 91.12C43.0057 90.2863 44.9513 89.8203 47.0275 89.8203C49.112 89.8203 51.0575 90.2781 52.5043 91.12C53.8122 91.8721 54.5316 92.8285 54.5316 93.7931C54.5316 94.7576 53.8286 95.6977 52.5371 96.4415C51.0902 97.2753 49.1529 97.7413 47.0766 97.7413Z" fill="#FFCA38"/>
        <path d="M51.3025 95.9087L50.4524 95.4182L49.9619 95.7043L50.8121 95.7534V96.1948L49.9865 96.677L49.1363 96.1866L48.466 96.579L42.1799 92.9495V92.5081L42.8502 92.5653L41.9756 92.0585V91.6171L42.8012 91.5844L43.6759 92.0912L44.1663 91.8051L43.2917 91.2983V90.8569L44.1173 90.8242L45.041 91.3555C45.9892 90.9632 46.8148 91.0204 47.6159 91.4863L47.8448 91.6171C48.4906 91.9931 48.6704 92.361 48.3516 92.7861C49.1854 92.5408 49.9047 92.6716 50.575 93.0558L51.09 93.3501C51.5314 93.6035 51.7521 93.8814 51.7521 94.1675C51.7521 94.4128 51.5886 94.6743 51.2535 94.9359L52.1363 95.0013V95.4427L51.3025 95.9087ZM48.7276 95.3773L49.8884 94.707C50.2808 94.4782 50.3461 94.2656 49.872 93.9959L49.3243 93.6771C48.744 93.3419 48.3352 93.3664 47.8039 93.6771L46.7903 94.2656L48.7276 95.3773ZM45.8993 93.7425L46.8475 93.1948C47.2808 92.9414 47.387 92.6961 46.8884 92.4019L46.5369 92.1975C46.0873 91.9359 45.7113 91.9278 45.2944 92.173L44.2399 92.7861L45.8993 93.7425Z" fill="#B88325"/>
        <path d="M51.7353 93.7031H51.4001V94.1445H51.7353V93.7031Z" fill="#B88325"/>
        <path d="M48.4904 91.9531H48.1553V92.3945H48.4904V91.9531Z" fill="#B88325"/>
        <path d="M51.3023 95.4605L50.4522 94.9701L49.9617 95.2562L50.8118 95.7466L49.9862 96.2289L49.1361 95.7385L48.4658 96.1308L42.1797 92.5014L42.85 92.109L41.9753 91.6022L42.801 91.12L43.6756 91.6268L44.1661 91.3407L43.2914 90.8338L44.117 90.3516L45.0407 90.8829C45.989 90.4905 46.8146 90.5477 47.6157 91.0137L47.8445 91.1445C48.4903 91.5205 48.6702 91.8883 48.3514 92.3134C49.1851 92.0682 49.9045 92.199 50.5748 92.5832L51.0898 92.8774C51.5312 93.1308 51.7519 93.4088 51.7519 93.6949C51.7519 93.9401 51.5884 94.2017 51.2532 94.4633L52.1361 94.9782L51.3023 95.4605ZM48.7274 94.9292L49.8881 94.2589C50.2805 94.03 50.3459 93.8175 49.8718 93.5477L49.3241 93.2289C48.7437 92.8938 48.335 92.9183 47.8037 93.2289L46.79 93.8175L48.7274 94.9292ZM45.899 93.2943L46.8473 92.7466C47.2805 92.4932 47.3868 92.248 46.8881 91.9537L46.5366 91.7494C46.0871 91.4878 45.711 91.4796 45.2941 91.7248L44.2397 92.3379L45.899 93.2943Z" fill="#F9B233"/>
        <path d="M55.3972 91.3381L55.389 93.0547C55.389 94.2891 54.5798 95.5152 52.9613 96.4553C49.7079 98.3435 44.4191 98.3435 41.1493 96.4553C39.4981 95.507 38.6807 94.2564 38.6807 93.0057L38.6888 91.2891C38.6888 92.5316 39.5063 93.7822 41.1575 94.7386C44.4272 96.6269 49.7242 96.6269 52.9694 94.7386C54.588 93.7986 55.3972 92.5724 55.3972 91.3381Z" fill="#B88325"/>
        <path d="M55.3991 91.3409C55.4066 88.6682 51.6725 86.4911 47.0586 86.478C42.4447 86.4649 38.6983 88.621 38.6907 91.2936C38.6831 93.9662 42.4173 96.1434 47.0312 96.1565C51.6451 96.1695 55.3915 94.0135 55.3991 91.3409Z" fill="#F9B233"/>
        <path d="M47.0756 95.2803C44.9912 95.2803 43.0456 94.8226 41.5988 93.9806C40.2909 93.2286 39.5715 92.2722 39.5715 91.3076C39.5715 90.343 40.2745 89.403 41.5661 88.6591C43.0048 87.8253 44.9503 87.3594 47.0266 87.3594C49.111 87.3594 51.0565 87.8171 52.5034 88.6591C53.8113 89.4111 54.5306 90.3675 54.5306 91.3321C54.5306 92.2967 53.8276 93.2367 52.5361 93.9806C51.0892 94.8226 49.1519 95.2803 47.0756 95.2803Z" fill="#B88325"/>
        <path d="M47.0756 95.6866C44.9912 95.6866 43.0456 95.2288 41.5988 94.3869C40.2909 93.6348 39.5715 92.6784 39.5715 91.7138C39.5715 90.7493 40.2745 89.8092 41.5661 89.0653C43.0048 88.2316 44.9503 87.7656 47.0266 87.7656C49.111 87.7656 51.0565 88.2234 52.5034 89.0653C53.8113 89.8174 54.5306 90.7738 54.5306 91.7384C54.5306 92.7029 53.8276 93.643 52.5361 94.3869C51.0892 95.2288 49.1519 95.6866 47.0756 95.6866Z" fill="#FFCA38"/>
        <path d="M70.4141 63.7063C70.267 63.8698 69.7929 64.3112 69.752 64.7608C69.7111 65.2104 69.5803 65.5946 69.8664 66.2159C70.1525 66.8371 70.5204 66.7063 70.5204 66.7063C70.5204 66.7063 70.8228 67.8752 71.4277 68.5455C72.0326 69.2158 73.4959 69.363 74.207 69.3058C75.4168 69.2077 75.2124 68.5047 74.4441 67.4829C73.6266 66.3957 73.0462 65.5619 73.0135 64.6954C72.9808 63.8289 72.8582 62.8398 72.8582 62.8398L70.4141 63.7063Z" fill="#261822"/>
        <path d="M81.3758 57.4571C81.3758 57.4571 81.5229 57.1873 81.2287 57.735C80.9344 58.2827 80.681 59.2718 80.7055 59.9748C80.73 60.6777 82.1687 60.7922 82.1687 60.7922L82.5366 60.547C82.5366 60.547 82.7409 60.8985 83.9507 61.4053C85.1687 61.9039 86.5747 61.8385 87.1224 61.5115C87.6782 61.1846 87.9807 61.0292 86.7627 60.1873C85.5447 59.3453 83.542 57.5061 83.542 57.5061L81.3758 57.4571Z" fill="#261822"/>
        <path d="M73.5938 37.8807C74.1578 37.5128 80.1415 35.7227 79.79 35.9924C79.79 35.9924 81.76 40.3739 82.3976 42.6627C82.7573 43.9624 82.8145 45.0006 83.0188 46.3493C83.0679 46.6436 83.2804 46.6926 83.1169 47.6C82.9943 48.3112 84.0325 56.7144 84.0815 58.0795C83.1823 58.9051 82.275 59.3057 80.6074 58.6599C79.7981 55.5618 78.3431 52.3738 78.8744 48.5155C77.8036 47.9515 76.1851 45.7771 75.76 44.7308C74.9834 42.7853 72.572 38.5428 73.5938 37.8807Z" fill="#3A3A93"/>
        <path d="M72.9973 63.9418C72.6785 64.0889 72.3924 64.2933 72.0409 64.3914C71.297 64.6039 70.5205 64.3505 69.8502 64.048C69.1962 61.8655 69.3434 58.6693 69.5804 56.4704C69.7276 55.1053 70.1281 53.6012 71.3297 51.8764C70.8229 51.1734 70.3652 49.4159 70.2752 48.8928C70.0382 47.5358 69.8992 45.2879 70.3324 43.8655C70.0627 43.465 69.842 43.0399 69.654 42.7211C68.8529 41.3478 69.2534 39.4105 70.2589 37.9473C70.4878 37.6122 72.7112 38.4214 73.9619 38.6667C75.0572 38.8874 76.5041 39.2307 77.6403 38.871C77.8529 39.5985 77.861 40.3587 77.6322 41.119C77.485 41.6094 77.3951 42.9255 77.1008 43.3587C76.8719 45.2715 76.6267 49.6693 76.1117 52.3178C76.0218 52.7838 75.9401 53.4214 75.4087 53.7238C74.5423 58.0726 73.0463 63.7047 72.9973 63.9418Z" fill="#4646A5"/>
        <path d="M75.8174 12.5244C75.9727 12.6715 76.1444 12.8187 76.3242 12.9658C75.8011 13.1865 75.3106 13.4481 74.8773 13.7587C74.7302 13.5544 74.5667 13.3745 74.3705 13.2192C73.9782 12.9249 72.9891 13.0639 72.6621 12.7942C72.3596 12.5407 72.8746 12.4345 73.7738 12.2383C73.3814 11.7478 73.1689 11.0857 73.0545 10.489C72.891 9.64702 73.2098 9.05847 73.8555 9.16474C74.3869 9.24648 74.8201 10.0966 74.9836 10.3827C75.425 11.1675 75.7602 11.8214 75.8174 12.5244Z" fill="#FFB9A4"/>
        <path d="M75.1306 22.7249C74.9017 22.1282 74.82 21.5069 74.9099 20.853C75.0407 19.9293 75.5067 19.1282 75.9644 18.5805C75.8908 18.0001 74.7628 15.5233 75.0407 14.1909C75.2369 13.2345 76.5121 12.8012 77.0189 13.5288C77.4685 14.1827 78.4576 15.4089 78.6538 16.1118C78.8336 16.774 78.9807 17.6568 78.6129 18.368C79.3077 18.9892 79.4712 21.556 78.5966 23.0927C78.9399 24.605 78.3432 27.9074 77.5012 28.2344C76.7819 28.5123 76.0789 28.5205 75.6783 28.12C74.9344 27.3598 73.5775 26.1418 73.414 25.1118C73.3895 24.981 73.5039 23.5015 75.1306 22.7249Z" fill="#EFE8EB"/>
        <path d="M79.0544 37.4336C79.0712 37.4588 79.0796 37.4588 79.0544 37.4336V37.4336Z" fill="#261822"/>
        <path d="M72.482 24.8272C73.4711 24.443 74.1087 24.1814 76.1522 25.8245C77.5828 26.977 78.6699 30.5329 78.6045 31.1296C78.5555 31.5956 78.8907 35.2331 80.9506 37.2849C80.0433 38.7154 77.6481 40.4566 75.6945 40.8162C72.858 41.3394 70.9615 40.9143 69.3512 39.9825C69.4084 39.4266 70.2013 37.4647 69.5964 36.3857C68.5583 34.5302 67.7 33.3612 67.0624 31.3013C66.0487 28.007 70.2831 25.6773 72.482 24.8272Z" fill="white"/>
        <path d="M72.9807 24.3947C72.9807 24.3947 73.1687 25.4819 74.0924 27.035C74.3213 28.1958 73.8635 29.0459 72.3431 28.2611C70.8309 27.4846 69.2859 26.332 69.2859 26.332L69.4249 24.2148L72.9807 24.3947Z" fill="#FFB9A4"/>
        <path d="M69.3351 25.6289C69.6293 26.242 69.9808 26.5853 70.7329 26.9695C71.7629 27.4927 72.2124 27.3046 73.1116 27.3537C74.0435 27.4027 74.4686 27.7052 74.7465 28.4572C74.3787 28.4245 74.3133 28.2692 74.0517 28.588C73.88 28.8006 73.9536 29.0294 73.9454 29.242C73.9291 29.5444 73.5694 29.8142 73.0707 29.757C73.2097 28.8823 71.0435 28.5226 70.4958 28.3918C69.9972 28.2774 69.237 27.8033 69.0653 27.2965C68.8201 26.5935 68.8691 25.9314 69.3351 25.6289Z" fill="#EFE8EB"/>
        <path d="M73.4384 24.9258C73.4384 24.9258 74.354 25.6288 74.7954 26.0538C75.2368 26.4789 75.2204 27.0021 75.5801 27.2064C75.9398 27.4026 76.2831 27.4598 76.2831 27.4598C76.2831 27.4598 75.621 27.656 75.3839 27.5661C75.1387 27.4844 74.8444 27.4517 74.7872 27.5171C74.73 27.5824 74.3049 28.0566 74.264 28.0075C74.2232 27.9585 73.4956 27.7214 73.4956 27.7214C73.4956 27.7214 73.8308 27.1574 73.5938 26.528C73.3649 25.8985 72.8989 25.3427 72.8989 25.3427L73.4384 24.9258Z" fill="#EFE8EB"/>
        <path d="M67.6182 22.0785C67.5528 21.163 70.1359 19.757 71.2067 19.8142C72.8252 19.8878 73.5854 21.539 73.9451 22.7897C74.3211 24.1057 73.8388 26.0022 71.4274 25.5608C69.4737 25.2011 67.749 23.8605 67.6182 22.0785Z" fill="#FFC9B6"/>
        <path d="M67.2507 21.7289C66.9646 21.2548 67.1199 20.6581 67.5532 20.2167C67.9537 19.8079 68.5341 19.6118 69.1063 19.4564C69.6131 19.3257 69.8338 19.3502 70.2262 19.015C70.6431 18.6554 70.5041 18.5327 70.6431 18.0832C71.0681 16.7099 72.8583 17.7071 72.9401 18.8761C73.0954 18.8025 73.2752 18.7861 73.4714 18.8679C73.9292 19.0722 73.8638 19.5791 73.6512 19.9142C73.2997 20.4701 72.6376 20.9033 71.9183 21.0668C71.7466 22.1703 70.3488 22.3583 69.8256 22.2276C70.0954 22.9878 69.4578 24.0586 69.3842 25.015C68.9265 24.639 67.169 24.2384 66.8257 23.1022C66.6867 22.6935 66.8257 22.0395 67.2507 21.7289Z" fill="#261822"/>
        <path d="M69.9972 23.2743C69.9972 23.2743 69.237 22.8329 69.0408 23.4297C68.8692 23.9528 69.188 24.4842 69.9236 24.3779C70.5776 24.288 69.9972 23.2743 69.9972 23.2743Z" fill="#FFC9B6"/>
        <path d="M54.2615 18.795C54.6539 18.3782 55.7656 18.558 56.722 19.0566C57.6784 19.5634 58.128 19.5307 58.1688 19.0975C58.2015 18.6643 57.8991 18.5008 58.0299 18.2392C58.1607 17.9776 58.365 17.9531 58.5939 18.4844C58.8228 19.0158 58.9372 19.7514 59.1743 20.1847C59.444 20.389 59.959 20.7242 60.5149 21.0757C60.1552 21.6234 59.7301 22.1138 59.2233 22.5553C58.9372 22.1874 58.6675 21.8277 58.4141 21.5008C57.3024 20.9694 56.5748 21.231 55.3242 20.6506C54.0817 20.0621 53.8773 19.2119 54.2615 18.795Z" fill="#FFC9B6"/>
        <path d="M60.0815 20.7148C62.166 21.8184 62.1741 22.6603 63.2286 23.8129C65.1169 25.0718 66.6373 27.0173 66.8253 27.2707C68.0188 27.1808 69.0406 28.1208 70.2667 30.4995C71.0678 32.0609 70.7817 34.9954 68.1414 33.4914C66.3349 32.4614 65.6237 31.2761 64.9779 30.2543C64.1033 30.2952 62.9589 29.1344 61.2831 26.5759C60.5638 25.4723 59.8281 24.2543 58.5774 21.9083C59.403 21.2789 59.6973 21.0255 60.0815 20.7148Z" fill="#EFE8EB"/>
        <path d="M60.507 20.9512C60.507 20.9512 60.368 21.4416 59.8776 21.8095C59.3871 22.1773 58.8558 22.4062 58.8558 22.4062L58.2917 21.4498L59.3299 20.2891C59.3217 20.2891 60.131 20.7141 60.507 20.9512Z" fill="#E0D1DB"/>
        <path d="M76.6104 13.0152C76.7085 13.146 77.0191 13.5221 77.0191 13.5221C77.0191 13.5221 76.2426 13.7591 75.842 13.9226C75.4415 14.0943 75.0001 14.5684 75.0001 14.5684C75.0001 14.5684 74.3461 13.5302 74.951 13.0643C75.5559 12.5984 76.1608 12.4185 76.6104 13.0152Z" fill="#E0D1DB"/>
        <path d="M75.3597 28.4227C75.5804 28.0712 75.2698 27.5398 75.2698 27.5398C75.2698 27.5398 75.1145 27.5235 74.9101 27.4908C74.7548 27.4663 74.6894 27.6298 74.6894 27.6298C74.6894 27.6298 74.9019 28.1611 74.9183 28.4145C74.9183 28.4145 74.5096 28.3654 74.3297 28.3491C73.8393 28.3082 73.9619 28.7823 73.9619 28.7823C73.9619 28.7823 74.3869 28.8232 74.7303 28.8232C74.812 28.9949 74.9264 29.1747 75.0654 29.3627C75.188 29.5262 75.4169 29.5753 75.5804 29.469C75.8338 29.3137 75.8501 29.1011 75.7439 28.8723C75.6785 28.7578 75.4987 28.578 75.3597 28.4227Z" fill="#FF6029"/>
        <path d="M76.8065 30.7709C76.2343 29.6592 75.9646 29.4058 75.6294 29.1361C75.5231 29.0543 75.2289 29.2096 75.2616 29.3322C75.5967 30.5094 75.7847 31.3104 76.1035 33.2396C76.4305 35.2178 76.8065 36.5992 76.8065 36.5992C76.8065 36.5992 77.8283 37.2287 78.3024 37.3022C78.752 36.3131 78.5885 35.3567 78.5885 35.3567C78.5885 35.3567 77.4114 31.9399 76.8065 30.7709Z" fill="#FF6029"/>
        <path d="M53.5747 46.8412C53.3212 46.7431 53.0106 46.7676 52.6673 46.9638C52.2177 47.2172 51.8254 47.7077 51.5474 48.2799C51.2695 48.0347 50.869 48.002 50.4275 48.2635C49.5938 48.7458 48.9153 50.0374 48.9153 51.1491C48.9153 51.7295 49.0951 52.1382 49.3894 52.3425L51.5392 53.8548L52.6918 54.4842L54.6945 47.4706L53.5747 46.8412Z" fill="#F24A1D"/>
        <path d="M55.3322 48.7356C55.3322 47.6239 54.6537 47.1089 53.8199 47.5912C53.3785 47.8446 52.9779 48.3351 52.7 48.9073C52.4221 48.662 52.0215 48.6294 51.5801 48.8909C50.7463 49.3732 50.0679 50.6648 50.0679 51.7765C50.0679 52.3569 50.2477 52.7656 50.542 52.9699L52.6918 54.4822L54.8008 50.5748C55.1278 50.019 55.3322 49.3487 55.3322 48.7356Z" fill="#FF6029"/>
        <path d="M92.1661 1.08699V1.09516C91.7819 0.931673 91.3159 0.972544 90.8009 1.26682C90.1306 1.65919 89.5257 2.39488 89.1088 3.25319C88.6919 2.87717 88.0871 2.8363 87.4168 3.22049C86.1579 3.94801 85.1279 5.90168 85.1279 7.5856C85.1279 8.46026 85.4059 9.08151 85.8473 9.38396L89.1007 11.6728L90.8418 12.6292L93.8745 2.01886L92.1661 1.08699Z" fill="#F24A1D"/>
        <path d="M94.8229 3.95618C94.8229 2.27227 93.8011 1.50388 92.5341 2.23139C91.8638 2.62376 91.2589 3.35946 90.842 4.21776C90.4251 3.84174 89.8202 3.80087 89.1499 4.18507C87.8911 4.91258 86.8611 6.86626 86.8611 8.55017C86.8611 9.42483 87.139 10.0461 87.5804 10.3485L90.8338 12.6374L94.0218 6.72729C94.5123 5.88533 94.8229 4.87989 94.8229 3.95618Z" fill="#FF6029"/>
        <path d="M106.594 29.9418C106.373 29.8519 106.111 29.8764 105.817 30.048C105.433 30.2688 105.089 30.6938 104.852 31.1761C104.615 30.9636 104.272 30.9391 103.888 31.1598C103.169 31.5767 102.588 32.6884 102.588 33.6448C102.588 34.1434 102.743 34.4949 102.997 34.6666L104.852 35.9663L105.842 36.514L107.566 30.4731L106.594 29.9418Z" fill="#F24A1D"/>
        <path d="M108.106 31.5756C108.106 30.6192 107.525 30.1778 106.806 30.5947C106.422 30.8154 106.078 31.2405 105.841 31.7227C105.604 31.5102 105.261 31.4857 104.877 31.7064C104.158 32.1233 103.577 33.235 103.577 34.1914C103.577 34.69 103.732 35.0415 103.986 35.2132L105.841 36.5129L107.656 33.1451C107.926 32.671 108.106 32.0988 108.106 31.5756Z" fill="#FF6029"/>
        <path d="M4.79017 51.7923C4.52859 51.686 4.20979 51.7105 3.8583 51.9149C3.40053 52.1765 2.99181 52.6833 2.70571 53.2636C2.41961 53.0102 2.0109 52.9775 1.55314 53.2391C0.694828 53.7378 0 55.062 0 56.2064C0 56.8031 0.188015 57.2282 0.490466 57.4326L2.70571 58.9857L3.891 59.6396L5.95093 52.4299L4.79017 51.7923Z" fill="#F24A1D"/>
        <path d="M6.59698 53.7361C6.59698 52.5917 5.90215 52.0685 5.04384 52.559C4.58608 52.8206 4.17737 53.3274 3.89127 53.9077C3.60517 53.6543 3.19645 53.6216 2.73868 53.8832C1.88037 54.3819 1.18555 55.7061 1.18555 56.8505C1.18555 57.4472 1.37356 57.8723 1.67601 58.0767L3.89127 59.6298L6.05747 55.6162C6.38444 55.0522 6.59698 54.3655 6.59698 53.7361Z" fill="#FF6029"/>
        <path d="M54.3347 65.8263L52.8388 66.6928V63.4803L53.4928 63.0552L53.6481 62.8182L52.6181 62.2051C52.6099 62.1969 52.6099 62.1969 52.6017 62.1969L52.5772 62.1806C52.4464 62.107 52.2748 62.107 52.0868 62.2133C51.6699 62.4503 51.3347 63.1043 51.3347 63.6601V66.2187L48.5473 67.829C48.2039 68.0252 47.9179 68.5647 47.9179 69.0225C47.9179 69.2432 47.9832 69.3903 48.0895 69.4803L48.0813 69.4884L48.2203 69.5702C48.0405 69.8236 47.9097 70.1506 47.9097 70.4448C47.9097 70.6901 47.9914 70.8454 48.1222 70.9189L48.114 70.9271L48.3102 71.0416C48.0813 71.295 47.9097 71.671 47.9097 72.0225C47.9097 72.2432 47.9751 72.3903 48.0813 72.4802L48.0732 72.4884L48.3266 72.6356C48.0895 72.889 47.9097 73.2732 47.9097 73.6328C47.9097 73.8535 47.9751 74.0089 48.0813 74.0988L48.0895 74.1069C48.0977 74.1151 48.1059 74.1151 48.114 74.1233L49.2993 74.8263L49.8797 74.1642L49.2911 73.6819L54.3347 70.7718V65.8263Z" fill="#E0D1DB"/>
        <path d="M55.2341 70.2406L53.8118 71.0663V65.1889L55.2341 64.3633V70.2406Z" fill="#3A3A93"/>
        <path d="M56.3376 65.0172L55.2341 64.3633L53.8118 65.1889L54.9153 65.8428L56.3376 65.0172Z" fill="#5252BA"/>
        <path d="M54.915 66.7829L53.8115 66.1289L52.3892 66.9545L53.4927 67.6085L54.915 66.7829Z" fill="#E0D1DB"/>
        <path d="M53.9422 67.3457V63.4383C53.9422 62.8825 53.5989 62.6291 53.1901 62.8661C52.7732 63.1032 52.4381 63.7571 52.4381 64.313V66.8716L49.6507 68.4819C49.3073 68.6781 49.0212 69.2176 49.0212 69.6754C49.0212 69.986 49.152 70.1658 49.34 70.2067C49.152 70.4601 49.0212 70.7953 49.0212 71.1059C49.0212 71.4901 49.2174 71.6863 49.4872 71.629C49.2256 71.8825 49.0212 72.3075 49.0212 72.6835C49.0212 73.0922 49.2419 73.2803 49.5362 73.1903C49.2501 73.4274 49.0212 73.8852 49.0212 74.2857C49.0212 74.7435 49.2992 74.956 49.6507 74.7598L55.4463 71.4165V66.4874L53.9422 67.3457Z" fill="white"/>
        <path d="M56.3371 70.893L54.9148 71.7186V65.8412L56.3371 65.0156V70.893Z" fill="#4646A5"/>
        <path d="M50.0756 69.8628C50.0102 69.8628 49.953 69.8301 49.9203 69.7728C49.8712 69.6829 49.9039 69.5767 49.9857 69.5276L52.0865 68.3178C52.1682 68.2688 52.2827 68.2933 52.3317 68.3832C52.3807 68.4731 52.3481 68.5794 52.2663 68.6284L50.1655 69.8382C50.141 69.8546 50.1083 69.8628 50.0756 69.8628Z" fill="#E0D1DB"/>
        <path d="M50.0756 71.4721C50.0102 71.4721 49.953 71.4394 49.9203 71.3822C49.8712 71.2923 49.9039 71.186 49.9857 71.137L52.0865 69.9272C52.1682 69.8781 52.2827 69.9027 52.3317 69.9926C52.3807 70.0825 52.3481 70.1888 52.2663 70.2378L50.1655 71.4476C50.141 71.464 50.1083 71.4721 50.0756 71.4721Z" fill="#E0D1DB"/>
        <path d="M50.0756 73.0932C50.0102 73.0932 49.953 73.0605 49.9203 73.0033C49.8712 72.9134 49.9039 72.8071 49.9857 72.7581L52.0865 71.5483C52.1682 71.4992 52.2827 71.5238 52.3317 71.6137C52.3807 71.7036 52.3481 71.8099 52.2663 71.8589L50.1655 73.0687C50.141 73.0851 50.1083 73.0932 50.0756 73.0932Z" fill="#E0D1DB"/>
        <path d="M129.506 58.3408L127.373 59.5751V54.9893L128.313 54.3844L128.542 54.0411L127.062 53.1583C127.054 53.1501 127.046 53.1501 127.038 53.1419L126.997 53.1174C126.809 53.0111 126.563 53.0111 126.294 53.1665C125.697 53.5098 125.215 54.4335 125.215 55.2264V58.8803L121.234 61.1773C120.743 61.4634 120.343 62.2318 120.343 62.8858C120.343 63.1964 120.441 63.4171 120.596 63.5397L120.588 63.5479L120.784 63.6705C120.523 64.0302 120.343 64.4961 120.343 64.9293C120.343 65.2727 120.465 65.5016 120.645 65.6078L120.637 65.616L120.915 65.7795C120.588 66.1473 120.343 66.6868 120.343 67.1773C120.343 67.4879 120.441 67.7086 120.588 67.8231L120.58 67.8312L120.939 68.0438C120.596 68.4034 120.343 68.9593 120.343 69.4743C120.343 69.7849 120.441 70.0138 120.588 70.1446L120.58 70.1528L120.596 70.1609C120.604 70.1691 120.621 70.1691 120.629 70.1773L122.321 71.1746L123.147 70.2263L122.305 69.5397L129.514 65.3789V58.3408H129.506Z" fill="#FF9201"/>
        <path d="M130.79 64.6451L128.754 65.8222V57.4189L130.79 56.25V64.6451Z" fill="#3A3A93"/>
        <path d="M132.359 57.1819L130.79 56.25L128.754 57.4189L130.324 58.359L132.359 57.1819Z" fill="#5252BA"/>
        <path d="M130.323 59.7053L128.754 58.7734L126.719 59.9424L128.296 60.8824L130.323 59.7053Z" fill="#FFBE4A"/>
        <path d="M128.942 60.5057V54.9227C128.942 54.1297 128.46 53.7619 127.863 54.1052C127.266 54.4485 126.784 55.3722 126.784 56.1652V59.8191L122.803 62.1161C122.313 62.4022 121.912 63.1706 121.912 63.8245C121.912 64.2659 122.1 64.5275 122.37 64.5766C122.1 64.9362 121.912 65.4185 121.912 65.8599C121.912 66.4076 122.198 66.6856 122.574 66.6038C122.198 66.9635 121.912 67.5684 121.912 68.1161C121.912 68.6964 122.231 68.9744 122.64 68.8436C122.231 69.1869 121.912 69.8327 121.912 70.4131C121.912 71.067 122.313 71.3695 122.803 71.0915L131.076 66.3095V59.2632L128.942 60.5057Z" fill="#FFAF04"/>
        <path d="M132.359 65.5829L130.323 66.7519V58.3568L132.359 57.1797V65.5829Z" fill="#4646A5"/>
        <path d="M123.425 64.1035C123.335 64.1035 123.253 64.0544 123.204 63.9727C123.13 63.85 123.171 63.6947 123.294 63.6212L126.294 61.8882C126.416 61.8146 126.572 61.8555 126.645 61.9781C126.719 62.1007 126.678 62.256 126.555 62.3296L123.555 64.0626C123.506 64.0953 123.465 64.1035 123.425 64.1035Z" fill="#FF9201"/>
        <path d="M123.425 66.4081C123.335 66.4081 123.253 66.3591 123.204 66.2774C123.13 66.1547 123.171 65.9994 123.294 65.9259L126.294 64.1929C126.416 64.1193 126.572 64.1602 126.645 64.2828C126.719 64.4054 126.678 64.5607 126.555 64.6343L123.555 66.3673C123.506 66.4 123.465 66.4081 123.425 66.4081Z" fill="#FF9201"/>
        <path d="M123.425 68.7128C123.335 68.7128 123.253 68.6638 123.204 68.582C123.13 68.4594 123.171 68.3041 123.294 68.2305L126.294 66.4976C126.416 66.424 126.572 66.4649 126.645 66.5875C126.719 66.7101 126.678 66.8654 126.555 66.939L123.555 68.672C123.506 68.6965 123.465 68.7128 123.425 68.7128Z" fill="#FF9201"/>
        <path d="M16.3079 67.8057L14.8283 68.664V65.4842L15.4741 65.0591L15.6294 64.8221L14.6076 64.209C14.5995 64.2008 14.5995 64.2008 14.5913 64.2008L14.5668 64.1845C14.436 64.1109 14.2643 64.1109 14.0845 64.2172C13.6757 64.4542 13.3324 65.1 13.3324 65.6477V68.1817L10.5695 69.7757C10.2262 69.9719 9.94824 70.5032 9.94824 70.961C9.94824 71.1817 10.0136 71.3288 10.1199 71.4106L10.1117 71.4188L10.2507 71.5005C10.0709 71.7457 9.94824 72.0727 9.94824 72.367C9.94824 72.604 10.03 72.7594 10.1608 72.8411L10.1526 72.8493L10.3488 72.9637C10.1199 73.2171 9.95642 73.5931 9.95642 73.9365C9.95642 74.149 10.0218 74.3043 10.1281 74.3861L10.1199 74.3942L10.3651 74.5414C10.1281 74.7948 9.94824 75.179 9.94824 75.5305C9.94824 75.7512 10.0136 75.9065 10.1199 75.9964L10.1281 76.0046C10.1363 76.0128 10.1444 76.0128 10.1526 76.0209L11.3297 76.7157L11.9019 76.0536L11.3134 75.5795L16.3161 72.694V67.8057H16.3079Z" fill="#FF9201"/>
        <path d="M17.1907 72.1799L15.7847 72.9891V67.169L17.1907 66.3516V72.1799Z" fill="#3A3A93"/>
        <path d="M18.286 66.9973L17.1907 66.3516L15.7847 67.169L16.8719 67.8148L18.286 66.9973Z" fill="#5252BA"/>
        <path d="M16.872 68.7555L15.7848 68.1016L14.3706 68.919L15.466 69.5648L16.872 68.7555Z" fill="#FFBE4A"/>
        <path d="M15.9151 69.3007V65.4342C15.9151 64.8865 15.58 64.6331 15.1631 64.8702C14.7544 65.1072 14.411 65.753 14.411 66.3007V68.8347L11.6481 70.4287C11.3048 70.6249 11.0269 71.1563 11.0269 71.614C11.0269 71.9165 11.1576 72.1045 11.3457 72.1372C11.1576 72.3906 11.0269 72.7176 11.0269 73.0282C11.0269 73.4042 11.223 73.6004 11.4846 73.5432C11.223 73.7884 11.0269 74.2135 11.0269 74.5895C11.0269 74.99 11.2476 75.1862 11.5337 75.0963C11.2476 75.3334 11.0269 75.7829 11.0269 76.1835C11.0269 76.6413 11.3048 76.8456 11.6481 76.6494L17.3865 73.3388V68.4505L15.9151 69.3007Z" fill="#FFAF04"/>
        <path d="M18.2855 72.8244L16.8713 73.6418V67.8135L18.2855 66.9961V72.8244Z" fill="#4646A5"/>
        <path d="M12.0814 71.7999C12.0242 71.7999 11.9588 71.7672 11.9261 71.71C11.8771 71.6282 11.9098 71.5138 11.9915 71.4647L14.0678 70.2631C14.1496 70.2141 14.264 70.2386 14.313 70.3285C14.3621 70.4103 14.3294 70.5247 14.2476 70.5737L12.1714 71.7754C12.1468 71.7917 12.1141 71.7999 12.0814 71.7999Z" fill="#FF9201"/>
        <path d="M12.0814 73.3936C12.0242 73.3936 11.9588 73.3609 11.9261 73.3037C11.8771 73.222 11.9098 73.1075 11.9915 73.0585L14.0678 71.8569C14.1496 71.8078 14.264 71.8323 14.313 71.9223C14.3621 72.004 14.3294 72.1184 14.2476 72.1675L12.1714 73.3691C12.1468 73.3855 12.1141 73.3936 12.0814 73.3936Z" fill="#FF9201"/>
        <path d="M12.0814 74.9991C12.0242 74.9991 11.9588 74.9664 11.9261 74.9092C11.8771 74.8274 11.9098 74.713 11.9915 74.664L14.0678 73.4623C14.1496 73.4133 14.264 73.4378 14.313 73.5277C14.3621 73.6095 14.3294 73.7239 14.2476 73.773L12.1714 74.9746C12.1468 74.9909 12.1141 74.9991 12.0814 74.9991Z" fill="#FF9201"/>
    </svg>
)

export default SuccessReplaceIllustration