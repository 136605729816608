import * as React from "react"
import { SVGProps } from "react"

const ArrowFilterIdle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={10}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683417 6.09763 0.292894 5.70711C-0.0976312 5.31658 -0.0976312 4.68342 0.292894 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 9.29289C0.683417 8.90237 1.31658 8.90237 1.70711 9.29289L5 12.5858L8.29289 9.29289C8.68342 8.90237 9.31658 8.90237 9.70711 9.29289C10.0976 9.68342 10.0976 10.3166 9.70711 10.7071L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071L0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.29289Z" fill="white"/>
    </svg>
)

export default ArrowFilterIdle