import React, { useEffect, useState, Fragment, useRef } from 'react';
import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
// import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import {Spinner, Row, Col, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, FormGroup} from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import Axios from 'axios';
import moment from 'moment-timezone';
import { Pagination, SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconSpeaker from 'app/component/icon-speaker';
import CloseModal from 'app/component/close-modal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, CardBody, Card } from 'reactstrap';
import { Select as SelectMui, Tooltip } from '@mui/material';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ToApproveIcon from 'app/component/to-approve';
import ToAcknowledgeIcon from 'app/component/to-acknowledge';
import ToConfirmIcon from 'app/component/to-confirm';
import ToReceiveIcon from 'app/component/to-receive';
import ToActivityIcon from 'app/component/to-activity';
import ToSentIcon from 'app/component/to-sent';
import ToRejectedIcon from 'app/component/to-rejected';
import InfoIcon from 'app/component/InfoIcon';
import ChevronDownIcon from 'app/component/chevron-down-icon';
import ChevronUpIcon from 'app/component/chevron-up-icon';
import IconChecklist from "app/component/icon-checklist"
import { toast } from 'react-toastify';
import { Translate, translate, Storage } from 'react-jhipster';

import {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentConfirmation,
  getDocumentSuratMasuk,
  getDocumentActivity,
  getDocumentSuratKeluar,
  getDocumentSuratRejected,
  getMasterDocumentType,
  getMasterDocumentPriority,
  getMasterDocumentStatus,
  getMasterDocumentSender,
  getDocumentApprovalFilter,
  getDocumentAcknowledgeFilter,
  getDocumentConfirmationFilter,
  getDocumentSuratMasukFilter,
  getDocumentActivityFilter,
  getDocumentSuratKeluarFilter,
  getDocumentSuratRejectedFilter,
} from './../../entities/document/document.reducer';
import NoDataIllustration from "app/component/no-data-illustration";
import useWindowDimensions from "app/utils/ScreenDimesions";
import { useParams } from "react-router-dom";
import InfoIconTop from 'app/component/InfoIcon-top';


const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const TAB = {
  TO_APPROVE : "0",
  TO_ACKNOWLEDGE : "1",
  TO_CONFIRM : "2",
  RECEIVED : "3",
  ACTIVITY : "4",
  SENT : "5",
  REJECT_RECALL : "6"
}

export const Home = (props: IDocumentProps) => {

  let typedata = [{
    title: 'Approval',
    description: 'List Document(s) need to be approved',
    message_tooltip: 'Contains a list of documents that require your approval as an Approver / Berisi list document yang membutuhkan persetujuan anda sebagai pemberi persetujuan.',

  },
    {
      title: 'Acknowledge',
      description: 'Document(s) need to be acknowledge',
      message_tooltip: 'Contains a list of documents that require your approval as an Acknowledge / Berisi list document yang membutuhkan persetujuan anda sebagai yang mengetahui.'
    },
    {
      title: 'To Confirm',
      description: 'List Document(s) need to be confirm',
      message_tooltip: 'Contains a list of documents that require your approval as a Confirming Person / Berisi list document yang membutuhkan persetujuan anda sebagai yang mengonfirmasi.'
    },
    {
      title: 'Received',
      description: 'List document(s) that have been received',
      message_tooltip: 'Contains a list of documents sent/delegated to you / Berisi list document yang dikirimkan/didelegasikan kepada anda.'
    },
    {
      title: 'Activity',
      description: 'List of Documents',
      message_tooltip: 'Contains a list of documents that you have sent/signed / Berisi list document yang pernah anda kirim/tandatangani.'
    },
    {
      title: 'Sent',
      description: 'List document(s) that have been sent',
      message_tooltip: 'Contains a list of documents that you have sent as creator / Berisi list document yang pernah anda kirim/tandatangani sebagai pembuat dokumen.'
    },
    {
      title: 'Rejected',
      description: 'List document(s) that have been rejected',
      message_tooltip: 'Contains a list of documents that you have created and rejected by approver/acknowledge/confirming person / Berisi list document yang pernah anda buat dan ditolak oleh pihak acknowledge/approver.'
    },
  ]

  const formFilter = {
    filterDocType: "",
    filterPriority: "",
    filterDocStatus: "",
    filterSender: "",
    filterStartDate: "",
    filterEndDate: ""
  }

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [modalBanner, setModalBanner] = useState<any>(false)
  const [banner, setBanner] = useState<any>(null)
  const [listNotification, setListNotification] = useState<any>([])
  const [isOpen, setIsOpen] = useState(true);
  const [filterData, setFilterData] = useState(formFilter)
  const [dataDocType, setDataDocType] = useState<any>([])
  const [dataPriority, setDataPriority] = useState<any>([])
  const [dataDocStatus, setDataDocStatus] = useState<any>([])
  const [dataSender, setDataSender] = useState<any>([])
  const [modalRecall, setModalRecall] = useState<any>(false)
  const [isOpenModalResend, setOpenModalResend] = useState(false)
  const [idRecall, setIdRecall] = useState<any>("")
  const [idResend, setIdResend] = useState<any>("")
  const [notesRecall, setNotesRecall] = useState<any>("")
  const [loadingRecall, setLoadingRecall] = useState<any>(false)
  const [loadingResend, setLoadingResend] = useState<any>(false)
  const [modalNotifRecall, setModalNotifRecall] = useState<any>(false)
  const [isOpenModalNotifResend, setOpenModalNotifResend] = useState<any>(false)
  const [tnc, setTnc] = useState<any>(false)
  const listInnerRef = useRef();
  const [bottom, setBottom] = useState(false);
  const { height, width } = useWindowDimensions();
  const [isOpenTopTooltip1, setOpenTopTooltip1] = useState(false)
  const [isOpenTopTooltip2, setOpenTopTooltip2] = useState(false)
  const [isOpenTopTooltip3, setOpenTopTooltip3] = useState(false)
  const [isOpenTopTooltip4, setOpenTopTooltip4] = useState(false)
  const [isOpenTopTooltip5, setOpenTopTooltip5] = useState(false)
  const [isOpenTopTooltip6, setOpenTopTooltip6] = useState(false)
  const [isOpenTopTooltip7, setOpenTopTooltip7] = useState(false)
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);



  useEffect(() => {
    if(urlSearchParams.has("tab")) {
      switch (urlSearchParams.get("tab")) {
        case TAB.TO_APPROVE :
          setCategory(0)
          break;
        case TAB.TO_ACKNOWLEDGE:
          setCategory(1)
          break;
        case TAB.TO_CONFIRM:
          setCategory(2)
          break;
        case TAB.RECEIVED:
          setCategory(3)
          break;
        case TAB.ACTIVITY:
          setCategory(4)
          break;
        case TAB.SENT:
          setCategory(5)
          break;
        case TAB.REJECT_RECALL:
          setCategory(6)
          break;
        default:
          setCategory(0)
          break;
      }
    }
  }, [urlSearchParams]);

  useEffect(() => {
    props.getDocumentApproval(page, limit, true);
    props.getDocumentAcknowledge(page, limit, true);
    props.getDocumentConfirmation(page, limit, true);
    props.getDocumentSuratMasuk(page, limit, true);
    props.getDocumentActivity(page, limit, true);
    props.getDocumentSuratKeluar(page, limit, true);
    props.getDocumentSuratRejected(page, limit, true);
    getBanner()
    getListNotification()
    getDocType()
    getPriority()
    getDocStatus()
    getSender()
  }, []);

  useEffect(() => {
    setPage(1)
    setLimit(5)

    changePage(null, 1)

  }, [category]);

  const checkTnc = () => {
    Axios.get(`/services/documentservice/api/term_and_condition/is_show_popup_confirmation`)
        .then((res) => {
          if(res.data.data.result){
            Storage.session.set('termandconditions', true);
            setTnc(true)
          } else {
            Storage.session.set('termandconditions', false);
            setTnc(false)
          }
        }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const pressTncAgree = () => {
    Axios.post(`/services/documentservice/api/term_and_condition/save_confirmation`)
        .then((res) => {
          Storage.session.set('termandconditions', false);
          setTnc(false)
        }).catch((err) => {
        toast.error(translate(`${err.response.data.message}`));
    })
  }

  const handleScroll = (e) => {
    // console.log("handleScroll", e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight)
    const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
    setBottom(bottom)
  }

  const toggle = () => setIsOpen(!isOpen);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    /* data filter */
    var typeFilter = filterData.filterDocType;
    var priorityFilter = filterData.filterPriority;
    var fileStatusFilter = filterData.filterDocStatus;
    var groupIdFilter = filterData.filterSender;
    var fromDateFilter = moment(filterData.filterStartDate, 'YYYY-MM-DD', true).isValid() ? moment(filterData.filterStartDate).format('YYYY-MM-DD') : "";
    var toDateFilter = moment(filterData.filterEndDate, 'YYYY-MM-DD', true).isValid() ? moment(filterData.filterEndDate).format('YYYY-MM-DD') : "";
    /* params validate*/
    const searchDocType = typeFilter ? `&typeFilter=${typeFilter}` : ``;
    const searchPriorityFilter = priorityFilter ? `&priorityFilter=${priorityFilter}` : ``;
    const searchDocStatus = fileStatusFilter ? `&fileStatusFilter=${fileStatusFilter}` : ``;
    const searchSender = groupIdFilter ? `&groupIdFilter=${groupIdFilter}` : ``;
    const searchFromDateFilter = fromDateFilter ? `&fromDateFilter=${fromDateFilter}` : ``;
    const searchtoDateFilter = toDateFilter ? `&toDateFilter=${toDateFilter}` : ``;
    if (value != 0) {
      if (category == 0) {
        setPage(value)
        props.getDocumentApprovalFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 1) {
        setPage(value)
        props.getDocumentAcknowledgeFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 2) {
        setPage(value)
        props.getDocumentConfirmationFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 3) {
        setPage(value)
        props.getDocumentSuratMasukFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 4) {
        setPage(value)
        props.getDocumentActivityFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 5) {
        setPage(value)
        props.getDocumentSuratKeluarFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      } else if (category == 6) {
        setPage(value)
        props.getDocumentSuratRejectedFilter(value, limit, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
      }
    }
  }

  const changeCategory = (categoryParams) => {
    clearFilter()
    setCategory(categoryParams)
    if (categoryParams == 0) {
      props.getDocumentApproval(page, limit, true);
    } else if (categoryParams == 1) {
      props.getDocumentAcknowledge(page, limit, true);
    } else if (categoryParams == 2) {
      props.getDocumentConfirmation(page, limit, true);
    } else if (categoryParams == 3) {
      props.getDocumentSuratMasuk(page, limit, true);
    } else if (categoryParams == 4) {
      props.getDocumentActivity(page, limit, true);
    } else if (categoryParams == 5) {
      props.getDocumentSuratKeluar(page, limit, true);
    } else if (categoryParams == 6) {
      props.getDocumentSuratRejected(page, limit, true);
    }
  }

  const renderStatusPriority = (data) => {
    if (data == "1") {
      return <span style={{
        backgroundColor: '#FFF4F2',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #CB3A31',
        color: '#CB3A31',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>High</span>
    } else if (data == "2") {
      return <span style={{
        backgroundColor: '#FFF4EB',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #F37F26',
        color: '#F37F26',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Medium</span>
    } else {
      return <span style={{
        backgroundColor: '#F0F3FF',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #3267E3',
        color: '#3267E3',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Low</span>
    }
  }

  const renderBatasWaktu = (data) => {
    if (!data) return '-'
    return moment(data).format('DD MMM YY | HH:mm') + " WIB"
  }

  const renderStatus = (status) => {
    if (status == 'DRAFT') {
      return 'DRAFT'
    } else if (status == 'WAITING_FOR_ACKNOWLEDGEMENT') {
      return 'WAITING FOR ACKNOWLEDGMENT'
    } else if (status == 'WAITING_FOR_APPROVAL') {
      return 'WAITING FOR APPROVAL'
    } else if (status == 'APPROVED') {
      return 'APPROVED'
    } else if (status == 'REJECT') {
      return 'REJECTED'
    } else {
      return ''
    }
  }

  const gotoPreview = (id) => {
    props.history.push(`/preview/only/pdf/${id}`)
  }

  const changeShow = (e: SelectChangeEvent) => {
    let show = e.target.value;
    if (category == 0) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentApproval(1, e.target.value, true);
    } else if (category == 1) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentAcknowledge(1, e.target.value, true);
    } else if (category == 2) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentConfirmation(1, e.target.value, true);
    } else if (category == 3) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentSuratMasuk(1, e.target.value, true);
    } else if (category == 4) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentActivity(1, e.target.value, true);
    } else if (category == 5) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentSuratKeluar(1, e.target.value, true);
    } else if (category == 6) {
      setPage(1)
      setLimit(e.target.value)
      props.getDocumentSuratRejected(1, e.target.value, true);
    }
  }

  Axios.interceptors.response.use(response => {
    return response
  })

  const getListNotification = () => {
    Axios.get(`/services/documentservice/api/announcement/get-new-announcement?page=1&size=2`)
        .then((res) => {
          setListNotification(res.data.data.content)
        }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const goToDetail = (data) => {
    Axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`)
        .then((res) => {
          props.history.push(`/announcement/view/${data.id}`)
        }).catch((err) => {
      props.history.push(`/announcement/view/${data.id}`)
    })
  }

  const goToDetailBanner = (data) => {
    Axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`)
        .then((res) => {
          props.history.push(`/announcement/view/${data.announcementId}`)
        }).catch((err) => {
      props.history.push(`/announcement/view/${data.announcementId}`)
    })
  }

  const closeBanner = () => {
    setModalBanner(false)
  }

  /* get data */
  const getBanner = () => {
    Axios.get(`services/documentservice/api/announcement/get-latest`).then((res) => {
      if (res.data.data) {
        setModalBanner(true)
        setBanner(res.data.data)
      } else {
        setModalBanner(false)
      }
      checkTnc()
    }).catch((err) => {
      setModalBanner(false)
    })
  }

  const getDocType = () => {
    Axios.get(`services/documentservice/api/documents/dropdown-document-type`).then((res) => {
      const resDocType = res.data.map(e => ({ label: e.value, value: e.key }))
      setDataDocType([{ "label": "All", "value": "all" }, ...resDocType])
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const getPriority = () => {
    Axios.get(`services/documentservice/api/documents/dropdown-document-priority`).then((res) => {
      const resPriority = res.data.map(e => ({ label: e.value, value: e.key }))
      setDataPriority([{ "label": "All", "value": "all" }, ...resPriority])
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const getDocStatus = () => {
    Axios.get(`services/documentservice/api/documents/dropdown-document-status`).then((res) => {
      const resDocStatus = res.data.map(e => ({ label: e.value, value: e.key }))
      setDataDocStatus([{ "label": "All", "value": "all" }, ...resDocStatus])
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const getSender = () => {
    Axios.get(`services/documentservice/api/documents/dropdown-document-sender`).then((res) => {
      const resSender = res.data.map(e => ({ label: e.value, value: e.key }))
      setDataSender([{ "label": "All", "value": "all" }, ...resSender])
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }
  /* end get data */

  /* Filter data in home page */
  const clearFilter = () => {
    setFilterData({
      ...formFilter,
      filterDocType: "",
      filterPriority: "",
      filterDocStatus: "",
      filterSender: "",
      filterStartDate: "",
      filterEndDate: ""
    })
  }

  const filterSearch = () => {
    setPage(1)
    setLimit(5)
    /* data filter */
    var typeFilter = filterData.filterDocType;
    var priorityFilter = filterData.filterPriority;
    var fileStatusFilter = filterData.filterDocStatus;
    var groupIdFilter = filterData.filterSender;
    var fromDateFilter = moment(filterData.filterStartDate, 'YYYY-MM-DD', true).isValid() ? moment(filterData.filterStartDate).format('YYYY-MM-DD') : "";
    var toDateFilter = moment(filterData.filterEndDate, 'YYYY-MM-DD', true).isValid() ? moment(filterData.filterEndDate).format('YYYY-MM-DD') : "";
    /* params validate*/
    const searchDocType = typeFilter ? `&typeFilter=${typeFilter}` : ``;
    const searchPriorityFilter = priorityFilter ? `&priorityFilter=${priorityFilter}` : ``;
    const searchDocStatus = fileStatusFilter ? `&fileStatusFilter=${fileStatusFilter}` : ``;
    const searchSender = groupIdFilter ? `&groupIdFilter=${groupIdFilter}` : ``;
    const searchFromDateFilter = fromDateFilter ? `&fromDateFilter=${fromDateFilter}` : ``;
    const searchtoDateFilter = toDateFilter ? `&toDateFilter=${toDateFilter}` : ``;
    /* call props with filter */
    if (category == 0) {
      props.getDocumentApprovalFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 1) {
      props.getDocumentAcknowledgeFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 2) {
      props.getDocumentConfirmationFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 3) {
      props.getDocumentSuratMasukFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 4) {
      props.getDocumentActivityFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 5) {
      props.getDocumentSuratKeluarFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    } else if (category == 6) {
      props.getDocumentSuratRejectedFilter(1, 5, true, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter);
    }
  }

  const viewNotif = (val) => {
    if (val > 0) {
      if (val > 99) {
        return (
            <div style={{ width: '50px', height: '18px', borderRadius: '4px', position: 'absolute', top: 10, right: 10, backgroundColor: '#CB3A31' }}>
              <div style={{ fontSize: '9px', lineHeight: '17px', color: '#F5F5F5' }}>99+ New</div>
            </div>
        )
      } else {
        return (
            <div style={{ width: '50px', height: '18px', borderRadius: '4px', position: 'absolute', top: 10, right: 10, backgroundColor: '#CB3A31' }}>
              <div style={{ fontSize: '9px', lineHeight: '17px', color: '#F5F5F5' }}>{val} New</div>
            </div>
        )
      }
    }
  }

  const closeModalNotifRecall = () => {
    setModalNotifRecall(false)
    props.getDocumentSuratKeluar(1, 5, true);
    window.location.href = "?tab=6"
  }

  const openModalRecall = (item) => {
    setIdRecall(item.id)
    setModalRecall(true)
  }

  const closeModalRecall = () => {
    setIdRecall("")
    setModalRecall(false)
  }

  const pressRecall = () => {
    if (notesRecall == "") {
      toast.error(`Reason doesn't empty !`);
    } else {
      setLoadingRecall(true)
      Axios.get(`/services/documentservice/api/dms/recall?id=${idRecall}&notes=${notesRecall}`)
          .then((res) => {
            setLoadingRecall(false)
            setModalNotifRecall(true)
            closeModalRecall()
          }).catch((err) => {
        setLoadingRecall(false)
        closeModalRecall()
        toast.error(translate(`${err.response.data.message}`));
      })
    }
  }

  const { documentList, loading } = props;

  const closeModalNotifResend = () => {
    setOpenModalNotifResend(false)
    window.location.href = "?tab5"
  }

  const pressResend = () => {
    const formData = new FormData();
    formData.append("documentId", idResend);
    setLoadingResend(true)
    Axios.post(`services/documentservice/api/dms/document-resend`, formData)
        .then((res) => {
          setLoadingResend(false)
          setOpenModalNotifResend(true)
          closeModalResend()
        }).catch((err) => {
      setLoadingResend(false)
      closeModalResend()
      toast.error(translate(`${err.response.data.message}`));
    })
  }


  const openModalResend = (id) => {
    setIdResend(id)
    setOpenModalResend(true)
  }

  const closeModalResend = () => {
    setIdResend("")
    setOpenModalResend(false)
  }

  return (
      <div className="px-4 font-family-spoqa" style={{ height: '100%' }}>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h1 className="font-family-spoqa mb-0 font-size-28 font-weight-700">Dashboard</h1>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h1 className="font-family-spoqa mb-0 font-size-14">Welcome to Mirae Asset DMS</h1>
            </div>
          </div>
        </div>
        <div className="shadow">
          {
            listNotification.length > 0
                ?
                <div>
                  <div className="row mb-2 mt-2">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <h2 className="font-family-spoqa mb-0 font-size-18">New Announcement</h2>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 text-right">
                      <a className="font-family-spoqa font-size-12" href="/announcement/list">View All</a>
                    </div>
                    <div className="px-3 py-2">
                      {
                        listNotification.map((item, index) => {
                          return <div key={index} className='font-family-spoqa px-2 py-2 my-2 rounded shadow-md' style={{ background: '#FBDEC9', cursor: 'pointer' }} onClick={() => goToDetail(item)}>
                            <div className='d-flex'>
                              <div className='mx-2'>
                                <IconSpeaker />
                              </div>
                              <div className='mx-2' style={{ color: '#616161' }}>
                                {item?.postedDateFormat}
                              </div>
                              <div className='mx-2' style={{ color: '#616161' }}>
                                {item?.senderEmployeeName} - {item?.senderBranchName}
                              </div>
                              <div className='mx-2 font-weight-bold font-size-14'>
                                {item?.title}
                              </div>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div> : <></>
          }
        </div>
        <div className="card shadow container-fluid">
          <div className="font-family-spoqa" style={{ width: '100%' }}>
            <div className="row row-cols-lg-auto">
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip1} title={typedata[0].message_tooltip} placement="top" arrow={true}>
                  <div className="card shadow rounded-lg" style={category == 0 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    props.history.push("?tab=0")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToApproveIcon/>
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          To Approve
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip1(true)} onMouseLeave={() => setOpenTopTooltip1(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[0].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalApproval}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip2} title={typedata[1].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 1 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    setPage(1)
                    setLimit(5)
                    props.history.push("?tab=1")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToAcknowledgeIcon />
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          To Acknowledge
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip2(true)} onMouseLeave={() => setOpenTopTooltip2(false)}>
                        {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[1].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalAcknowledge}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip3} title={typedata[2].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 2 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    props.history.push("?tab=2")
                    setPage(1)
                    setLimit(5)
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToConfirmIcon />
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          To Confirm
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip3(true)} onMouseLeave={() => setOpenTopTooltip3(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[2].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalConfirmation}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip4} title={typedata[3].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 3 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    setPage(1)
                    setLimit(5)
                    props.history.push("?tab=3")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToReceiveIcon />
                          {props?.totalNotifSuratMasuk > 99 ? viewNotif(props?.totalNotifSuratMasuk) : (props?.totalNotifSuratMasuk < 100 ? viewNotif(props?.totalNotifSuratMasuk) : "")}
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          Received
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip4(true)} onMouseLeave={() => setOpenTopTooltip4(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[3].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalSuratMasuk}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip5} title={typedata[4].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 4 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    setPage(1)
                    setLimit(5)
                    props.history.push("?tab=4")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToActivityIcon />
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          Activity
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip5(true)} onMouseLeave={() => setOpenTopTooltip5(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[4].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalActivity}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip6} title={typedata[5].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 5 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    setPage(1)
                    setLimit(5)
                    props.history.push("?tab=5")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToSentIcon />
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          Sent
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip6(true)} onMouseLeave={() => setOpenTopTooltip6(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[5].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalSuratKeluar}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="col-12 col-md-5 mt-2 mb-2 col-lg">
                <Tooltip open={isOpenTopTooltip7} title={typedata[6].message_tooltip} placement="top" arrow={true}>
                  <div className=" card shadow rounded-lg" style={category == 6 ? { border: '1px solid #F37F26', cursor: 'pointer', height: '100%' } : { cursor: 'pointer', height: '100%' }} onClick={() => {
                    props.history.push("?tab=6")
                  }}>
                    <div className="card-body rounded-lg px-2">
                      <div className="text-center">
                        <div className="mb-4">
                          <ToRejectedIcon />
                          {props?.totalNotifSuratRejected > 99 ? viewNotif(props?.totalNotifSuratRejected) : (props?.totalNotifSuratRejected < 100 ? viewNotif(props?.totalNotifSuratRejected) : "")}
                        </div>
                        <p style={{ fontSize: '12px' }}>
                          Rejected / Recalled
                          <span className='mx-1' onMouseOver={() => setOpenTopTooltip7(true)} onMouseLeave={() => setOpenTopTooltip7(false)}>
                          {width > 425 && <InfoIconTop/>}
                      </span>
                          {width < 426 &&
                              <div style={{fontSize:"10px", color:"#bcbcbc"}}>
                                {typedata[0].message_tooltip}
                              </div>}
                        </p>
                      </div>
                      <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                          {props?.totalSuratRejected}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-3 mr-1 ml-1 border font-family-spoqa">
            <div className="col-lg-6 mb-3 font-size-16 border-bottom" style={{ color: '#0A0A0A', padding: '5px', backgroundColor: '#EDEDED' }} onClick={toggle}>
              <div style={{ marginLeft: '8px' }}>
                {typedata[category].title}
              </div>
            </div>
            <div className="col-lg-6 mb-3 border-bottom" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '5px', backgroundColor: '#EDEDED' }} onClick={toggle}>
              <div style={{ marginRight: '8px' }}>
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
            </div>
            <Collapse isOpen={isOpen}>
              <div className="row mb-2">
                <div className="col-4 col-md-4">
                  <Select
                      name="group"
                      placeholder="Document Type"
                      value={dataDocType.filter((function (option: any) {
                        return option.value == filterData.filterDocType
                      }))}
                      options={dataDocType}
                      onChange={(e) => e.value == "all" ? setFilterData({ ...filterData, filterDocType: "" }) : setFilterData({ ...filterData, filterDocType: e.value })}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <Select
                      name="group"
                      placeholder="Priority"
                      value={dataPriority.filter((function (option: any) {
                        return option.value == filterData.filterPriority
                      }))}
                      options={dataPriority}
                      onChange={(e) => e.value == "all" ? setFilterData({ ...filterData, filterPriority: "" }) : setFilterData({ ...filterData, filterPriority: e.value })}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <Select
                      name="group"
                      placeholder="Document Status"
                      value={dataDocStatus.filter((function (option: any) {
                        return option.value == filterData.filterDocStatus
                      }))}
                      options={dataDocStatus}
                      onChange={(e) => e.value == "all" ? setFilterData({ ...filterData, filterDocStatus: "" }) : setFilterData({ ...filterData, filterDocStatus: e.value })}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4 col-md-4">
                  <Select
                      name="group"
                      placeholder="Sender"
                      value={dataSender.filter((function (option: any) {
                        return option.value == filterData.filterSender
                      }))}
                      options={dataSender}
                      onChange={(e) => e.value == "all" ? setFilterData({ ...filterData, filterSender: "" }) : setFilterData({ ...filterData, filterSender: e.value })}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <DatePicker
                      placeholderText="Please select start date"
                      value={filterData.filterStartDate}
                      selected={filterData.filterStartDate}
                      dateFormat="d MMMM yyyy "
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          filterStartDate: e
                        })
                      }}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <DatePicker
                      placeholderText="Please select end date"
                      dateFormat="d MMMM yyyy"
                      minDate={filterData.filterStartDate}
                      selected={filterData.filterEndDate}
                      onChange={(e) => {
                        setFilterData({
                          ...filterData,
                          filterEndDate: e
                        })
                      }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4 col-md-8" />
                <div className="col-4 col-md-4">
                  <button
                      className='btn col-12'
                      style={{ backgroundColor: '#F37F26', color: '#fff' }}
                      onClick={() => filterSearch()}>
                    Search
                  </button>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="mt-5 mb-4 mr-1 ml-3 font-family-spoqa">
            <div className="font-size-24 font-weight-900">
              {typedata[category].title}
              <Tooltip title={typedata[category].message_tooltip} placement="right">
              <span className='mx-1'>
                <InfoIcon />
              </span>
              </Tooltip>
            </div>
            <div className="font-size-12">{typedata[category].description}</div>
          </div>
          <div className="row mr-1 ml-1 font-family-spoqa">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-striped table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                  <tr>
                    <th>Document No</th>
                    <th>Document Type</th>
                    <th style={{ textAlign: 'center' }}>Priority</th>
                    <th>Regarding</th>
                    <th>Date</th>
                    <th>Due Date</th>
                    <th>Sender</th>
                    {category != 5 && <th className='fix'>Sender Name</th>}
                    {category == 4 && <th>User Activity</th>}
                    <th>Document Status</th>
                    {category == 4 && <th className='fix'>Action</th>}
                    {category == 6 && <th className='fix'>Action</th>}
                    {category == 5 && <th className='fix'>Action</th>}
                  </tr>
                  </thead>
                  <tbody>
                  {category == 0 && props.documentList.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '190px' }}>
                            <a href={`preview/approval/pdf/${item?.id}`}>{item?.fileNumber}</a>
                            {/* // ?fileNumber=${item?.fileNumber} */}
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}
                          </td>
                        </tr>)
                  })}

                  {(category == 0 && props.documentList.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="9">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 1 && props.documentListAcknowledge.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '190px' }}>
                            <a href={`preview/approval/ack/pdf/${item?.id}`}>{item?.fileNumber}</a>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                        </tr>)
                  })}

                  {(category == 1 && props.documentListAcknowledge.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="9">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 2 && props.documentListConfirmation.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '190px' }}>
                            <a href={`preview/approval/ack/pdf/${item?.id}`}>{item?.fileNumber}</a>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                        </tr>)
                  })}

                  {(category == 2 && props.documentListConfirmation.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="9">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 3 && props.documentListSuratMasuk.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '220px' }}>
                            <div className='row'>
                              <div className="col-9 p-1" style={{ textAlign: "center" }}>
                                <a href={`preview/only/pdf/${item?.id}`}>
                                  {item?.fileNumber}
                                </a>
                              </div>
                              {
                                !item?.isRead ?
                                    <div className="col-3 p-1">
                                      <div style={{ width: "40px", backgroundColor: '#CB3A31', borderRadius: "6px", textAlign: "center" }}>
                                        <div style={{ fontSize: '10px', lineHeight: '18px', color: '#F5F5F5' }}>New</div>
                                      </div>
                                    </div>
                                    : <></>
                              }
                            </div>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                        </tr>)
                  })}

                  {(category == 3 && props.documentListSuratMasuk.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="9">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 4 && props.documentListActivity.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '190px' }}>
                            <a href={`preview/only/pdf/${item?.id}`}>{item?.fileNumber}</a>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.userActionStatus}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                          <td className='fixHome' style={{ wordBreak: 'break-all', minWidth: '170px' }}>
                            <Link className='btn btn-info' to={`/history/detail/${item?.id}`}>View</Link>{` `}
                          </td>
                        </tr>)
                  })}

                  {(category == 4 && props.documentListActivity.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="10">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 5 && props.documentListSuratKeluar.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '190px' }}>
                            <a href={`preview/only/pdf/${item?.id}`}>{item?.fileNumber}</a>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          {/* <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td> */}
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                          <td className='fixHome' style={{ wordBreak: 'break-all', minWidth: '170px' }}>
                            <Link className='btn btn-info' to={`/history/detail/${item?.id}`}>View</Link>{` `}
                            {item?.canRecall ?
                                <div className='btn' style={{ backgroundColor: "#002F48", color: "#fff" }} onClick={() => openModalRecall(item)}>
                                  <i className="bx bx-reset"></i>{` `}
                                  Recall
                                </div>
                                : <></>}
                          </td>
                        </tr>
                    )
                  })}

                  {(category == 5 && props.documentListSuratKeluar.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="9">
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  {category == 6 && props.documentListSuratRejected.map((item, index) => {
                    return (
                        <tr key={index}>
                          <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '220px' }}>
                            <div className='row'>
                              <div className="col-9 p-1" style={{ textAlign: "center" }}>
                                <a href={`preview/only/pdf/${item?.id}`}>
                                  {item?.fileNumber}
                                </a>
                              </div>
                              {
                                !item?.isRead ?
                                    <div className="col-3 p-1">
                                      <div style={{ width: "40px", backgroundColor: '#CB3A31', borderRadius: "6px", textAlign: "center" }}>
                                        <div style={{ fontSize: '10px', lineHeight: '18px', color: '#F5F5F5' }}>New</div>
                                      </div>
                                    </div>
                                    : <></>
                              }
                            </div>
                          </td>
                          <td style={{ wordBreak: 'break-all', minWidth: '150px' }}>{item?.type}</td>
                          <td style={{ textAlign: 'center', wordBreak: 'break-all', minWidth: '120px' }}>{renderStatusPriority(item?.priorityCode)}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '300px', textAlign: 'justify' }}>{item.regarding ? item.regarding : '-'}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.dateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderEmployeeName}</td>
                          <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatusName}</td>
                          <td className='fixHome' >
                            {
                              (item?.fileStatus == "APPROVED_BY_REJECTOR")?
                                  <button className='btn btn-primary' style={{ width: '80px' }} onClick={() => {
                                    openModalResend(item?.id)
                                  }}>Resend</button> :
                                  <button className='btn btn-warning' style={{ width: '80px' }} onClick={() => {

                                    switch (item?.typeCode){
                                      case "IMO":
                                        props.history.push(
                                            {
                                              pathname :`/draft/create/${item?.id}`,
                                            }
                                        )
                                        break
                                      case "RELEASE_APPROVAL":
                                        props.history.push(
                                            {
                                              pathname :`/draft/releaseapproval/create/${item?.id}`,
                                            }
                                        )
                                        break
                                      default:
                                        break
                                    }
                                  }}><FontAwesomeIcon icon="pencil-alt" />{' '}Edit</button>
                            }
                          </td>
                        </tr>)
                  })}

                  {(category == 6 && props.documentListSuratRejected.length == 0) && <tr style={{ textAlign: 'center', backgroundColor:"#FFFFFF"}}>
                    <td colspan="10" >
                      <NoDataIllustration/><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"16pt"}}>No Data Available</span><br/>
                      <span className={"font-family-spoqa"} style={{fontSize:"14pt", color:"#616161"}}>Please check again later.</span>
                    </td>
                  </tr>}

                  </tbody>
                </table>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-12 col-md-6 mb-2">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                    <span className="mt-3 mx-2">
                      Show
                    </span>
                    <span>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <SelectMui
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={limit}
                      onChange={changeShow}
                      label="Limit"
                      >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      </SelectMui>
                      </FormControl>
                    </span>
                    <span className="mt-3 mx-2">
                      Entries
                    </span>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center justify-content-md-end col-12 col-md-6 mb-2">
                  {
                  category == 0 && <Pagination count={props.totalPageApproval} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 1 && <Pagination count={props.totalPageAcknowledge} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 2 && <Pagination count={props.totalPageConfirmation} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 3 && <Pagination count={props.totalPageKotakMasuk} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 4 && <Pagination count={props.totalPageActivity} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 5 && <Pagination count={props.totalPageKotakKeluar} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }

                  {
                  category == 6 && <Pagination count={props.totalPageKotakRejected} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modalBanner} size="lg" centered>
          <ModalBody id="documenttestApp.document.delete.question" style={{ padding: '0 !important' }}>
            <div style={{ position: 'absolute', right: '2%', top: '2%', cursor: 'pointer' }} onClick={() => closeBanner()}>
              <CloseModal />
            </div>
            <div style={{ margin: "10px" }}>
              <img src={`data:image/png;base64, ${banner?.image}`} style={{ objectFit: "contain", width: '100%', height: '700px', cursor: 'pointer' }} onClick={() => goToDetailBanner(banner)} />
            </div>
          </ModalBody>
        </Modal>
        <Modal className="font-family-spoqa" backdrop={"static"} isOpen={modalRecall} size="md" centered toggle={closeModalRecall}>
          <ModalHeader toggle={closeModalRecall}>Recall Document</ModalHeader>
          <ModalBody>
            <div className="row ml-2 mr-2 mt-3 mb-4">
              <div className='font-size-14' style={{ display: 'flex' }}>
                Why do you want to recall this document ?
              </div>
              <div className="col-12">
              <textarea
                  className='form-control'
                  rows={8}
                  style={{ resize: 'none' }}
                  value={notesRecall}
                  onChange={(e) => {
                    setNotesRecall(e.target.value)
                  }}
              />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button disabled={loadingRecall} style={{ backgroundColor: "#002F48" }} onClick={closeModalRecall}>
              Cancel
            </Button>{' '}
            <Button disabled={loadingRecall} color="primary" onClick={pressRecall}>
              {loadingRecall ? <Spinner color='#fff' size="sm" className='mr-1' /> : ""}
              Recall
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
            isOpen={modalNotifRecall}
            size="md"
            centered={true}
            backdrop={"static"}>
          <ModalHeader>
            <div className="font-size-18 font-family-spoqa">
              Notification
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
              <div className="" style={{ display: "flex", justifyContent: "center" }}>
                <IconChecklist />
              </div>
              <div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
                Your document has been recalled succesfully.
              </div>
              <div className="font-size-14" style={{ textAlign: "center" }}>
                You can access your document in the Rejected/Recall Dashboard.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
                id="use-a-template" type="submit"
                style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
                onClick={closeModalNotifRecall}>
              <span>Ok</span>
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
            isOpen={tnc}
            size="lg"
            centered={true}
            scrollable={true}
            onScroll={handleScroll}
            ref={listInnerRef}
            backdrop={"static"}>
          <ModalHeader>
            <div className="font-size-18 font-weight-bold font-family-spoqa" style={{ textAlign: "center" }}>
              Terms & Conditions
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="ml-5 mr-5 mt-3 mb-3 font-family-spoqa" >
              <div className="font-size-16 font-weight-bold" style={{ textAlign: "center" }}>Document Management System (DMS)</div>
              <div className="font-size-14" style={{ textAlign: "center" }}>Terms & Conditions</div>
              <div className="font-size-10" style={{ textAlign: "center" }}>(last update January 16th 2023)</div>
              <div className="mt-2 mb-1 font-size-12 font-family-spoqa" style={{ textAlign: "center" }}>
                Harap baca syarat & ketentuan ini dengan seksama sebelum menggunakan Aplikasi
                <em className="font-size-11"> / Please read these terms & conditions carefully before using the Application.</em>
              </div>
              <div className="mt-3 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Syarat dan ketentuan ini mengatur penggunaan Anda atas situs web ini (<a href="http://document.miraeasset.co.id/">http://document.miraeasset.co.id</a>) (untuk selanjutnya disebut dengan “Situs DMS”). Dengan menggunakan Situs DMS, anda menerima seluruh syarat dan ketentuan ini secara penuh dan sadar dalam kapasitas anda sebagai karyawan PT. Mirae Asset Sekuritas Indonesia (“MASID”) untuk mematuhi peraturan internal Perusahaan.
                <em className="font-size-11"> / These terms and conditions govern your use of this website (<a href="http://document.miraeasset.co.id/">http://document.miraeasset.co.id</a>) (hereinafter shall referred to as “DMS Website”). By using this DMS Website, you accept all these terms and conditions in full and consciously in your capacity as an employee of PT. Mirae Asset Sekuritas Indonesia (“MASID”) to comply with the Company's internal rules.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Lisensi untuk Menggunakan Situs Web <em className="font-size-11"> / License to Use Website</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Kecuali dinyatakan lain, tidak ada hal yang dianggap untuk memberikan Hak Kekayaan Intelektual kepada Anda, MASID memiliki seluruh Hak Kekayaan Intelektual dalam Situs DMS secara pribadi dan eksklusif (termasuk namun tidak terbatas kepada materi dalam Situs DMS) dan seluruh Hak Kekayaan Intelektual ini dilindungi undang-undang
                <em className="font-size-11"> / Unless otherwise stated, nothing shall be deemed to grant you Intellectual Property Rights. MASID solely and exclusively own all the Intellectual Property Rights in the DMS Website (including but not limited to the material on the DMS Website) and all these Intellectual Property Rights are reserved and protected by law.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Dengan menggunakan Situs DMS, Anda dapat melihat/membuat/mengunduh/mencetak halaman dari Situs DMS untuk kebutuhan internal perusahaan atau dengan sungguh-sungguh berdasarkan kebutuhan operasional perusahaan. Tunduk pada batasan yang ditetapkan di bawah ini dan di tempat lain dalam syarat dan ketentuan ini
                <em className="font-size-11"> / By using the DMS Website, you may view / create / download / print pages from the DMS Website for internal company needs or solemnly based on the operational needs of the company. Subject to the restrictions set out below and elsewhere in these terms and conditions.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Dengan tidak <em className="font-size-11"> / You must not:</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                <ul>
                  <li>
                    Menggunakan Situs DMS ini di area publik yang membutuhkan akses koneksi sharing seperti WIFI/Tethering atau jaringan LAN termasuk namun tidak terbatas kepada warnet atau lokasi lain yang memenuhi kriteria fasilitas publik
                    <em className="font-size-11"> / Using this DMS Website in public areas that require access to sharing connections such as WIFI / Tethering or LAN networks including but not limited to Internet cafes or other locations that meet the criteria for public facilities;</em>
                  </li>
                  <li>
                    Menerbitkan kembali/mendistribusikan ulang materi dari Situs DMS (termasuk publikasi ulang di situs web lain), kecuali untuk konten yang secara khusus dan tegas disediakan untuk didistribusikan kembali dan/atau mendapat persetujuan Perusahaan dan/atau perwakilan Perusahaan yang berwenang memberikan persetujuan
                    <em className="font-size-11"> / Republish / redistribute materials from this DMS Website (including republication on another website), except for content specifically and expressly made available for redistribution and/or get approval from Company and/or Company’s representatives who have the authority to give approval;</em>
                  </li>
                  <li>
                    Menjual, menyewakan, atau mensublisensikan materi dari Situs DMS
                    <em className="font-size-11"> / Sell, rent or sub-license material from DMS Website;</em>
                  </li>
                  <li>
                    Menampilkan materi apa pun dari Situs DMS kepada publik
                    <em className="font-size-11"> / Show any material from the DMS Website to public;</em>
                  </li>
                  <li>
                    Mereproduksi, menggandakan, menyalin, atau mengeksploitasi materi dari Situs DMS untuk tujuan komersial ataupun keuntungan pribadi
                    <em className="font-size-11"> / Reproduce, duplicate, copy or otherwise exploit material from DMS Website for a commercial purpose / personal gain;</em>
                  </li>
                  <li>
                    Mengedit atau memodifikasi materi apa pun pada Situs DMS
                    <em className="font-size-11"> / Edit or otherwise modify any material on the DMS Website;</em>
                  </li>
                  <li>
                    Penggunaan lainnya yang bertentangan dengan aturan internal Perusahaan dan/atau yang merugikan Perusahaan secara umum
                    <em className="font-size-11"> / Other uses which is violate Company’s internal regulations and/or that are detrimental to the Company in general.</em>
                  </li>
                </ul>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Jika konten secara khusus disediakan untuk didistribusikan ulang, konten tersebut hanya dapat didistribusikan ulang di dalam Perusahaan
                <em className="font-size-11"> / In the event any content is specifically made available for redistribution, it may only be redistributed within the Company.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Penggunaan yang tidak dapat diterima <em className="font-size-11"> / Unacceptable use</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Anda tidak boleh menggunakan Situs DMS ini dengan cara apa pun yang menyebabkan, atau dapat menyebabkan, kerusakan pada Situs DMS atau penurunan ketersediaan atau aksesibilitas Situs DMS; atau dengan cara apa pun yang melanggar hukum, curang, atau berbahaya, atau sehubungan dengan tujuan atau aktivitas yang melanggar hukum
                <em className="font-size-11"> / You must not use this DMS Website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of DMS Website; or in any way which is unlawful, fraudulent, or harmful, or in connection with any unlawful, fraudulent, or harmful purpose or activity.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Anda tidak boleh menggunakan Situs DMS ini untuk menyalin, menyimpan, menghosting, mentransmisikan, mengirim, menggunakan, menerbitkan, atau mendistribusikan materi apa pun yang terdiri dari (atau terkait dengan) spyware, virus komputer, Trojan horse, worm, keystroke logger, rootkit, atau perangkat lunak komputer berbahaya lainnya
                <em className="font-size-11"> / You must not use this DMS Website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or other malicious computer software.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Anda tidak boleh melakukan aktivitas pengumpulan data yang sistematis atau otomatis (termasuk namun tidak terbatas pada scraping, penambangan data, ekstraksi data, dan pengambilan data) pada atau sehubungan dengan Situs DMS ini tanpa persetujuan tertulis dari MASID. Anda tidak boleh menggunakan Situs DMS ini untuk mengirimkan atau mengirim komunikasi komersial yang tidak diminta. Anda tidak boleh menggunakan Situs DMS ini untuk tujuan apapun yang berhubungan dengan pemasaran tanpa persetujuan tertulis dari MASID
                <em className="font-size-11"> / You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this DMS Website without MASID’s express written consent. You must not use this DMS Website to transmit or send unsolicited commercial communications. You must not use this DMS Website for any purposes related to marketing without MASID’s express written consent.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Akses terbatas <em className="font-size-11"> / Restricted Access</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                MASID berhak untuk membatasi akses ke area Situs DMS ini, baik ke area tertentu atau seluruh Situs DMS ini dan hal tersebut merupakan kebijakan internal MASID
                <em className="font-size-11"> / MASID reserves the right to restrict access to areas of this DMS Website, both specific areas or indeed the entire DMS Website, and such restriction is MASID’s internal discretion</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Jika MASID memberi Anda ID pengguna dan kata sandi untuk memungkinkan Anda mengakses area terlarang Situs DMS ini atau konten atau layanan lainnya, Anda harus memastikan bahwa ID pengguna dan kata sandi dirahasiakan. MASID dapat menonaktifkan ID pengguna dan kata sandi Anda berdasarkan Kebijakan tunggal MASID tanpa pemberitahuan atau penjelasan
                <em className="font-size-11"> / In the event MASID provides you with a user ID and password to enable you to access restricted areas of this DMS Website or other content or services, you must ensure that the user ID and password are kept confidential. MASID may disable your user ID and password based on MASID’ssole discretion without notices or explanation.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Konten Pengguna <em className="font-size-11"> / User Content</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Dalam syarat dan ketentuan ini, “<b>Konten Pengguna Anda</b>” berarti materi (termasuk namun tidak terbatas pada teks, gambar, materi audio, materi video, dan materi audio-visual) yang Anda kirimkan ke Situs DMS ini untuk tujuan apa pun merupakan hak milik MASID
                <em className="font-size-11"> / In these terms and conditions, “<b>Your User Content</b>” means material (including but not limited to text, images, audio material, video material and audio-visual material) that you submit to this DMS Website, for any purpose is the property of MASID.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Konten Pengguna Anda tidak boleh melanggar hukum, tidak boleh melanggar hak pihak ketiga mana pun, dan tidak boleh menimbulkan akibat hukum baik terhadap MASID atau pihak ketiga (dalam setiap kasus berdasarkan hukum yang berlaku)
                <em className="font-size-11"> / Your User Content must not be violate the laws, must not infringe any third party's rights, and must not be capable of giving rise to legal consequences whether against MASIDor any third party (in each case under any applicable law).</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Anda tidak boleh mengirimkan konten pengguna apa pun ke situs web yang sedang atau pernah menjadi subjek dari ancaman atau proses hukum aktual atau keluhan serupa lainnya
                <em className="font-size-11"> / You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                MASID berhak untuk mengedit atau menghapus materi yang dikirimkan ke Situs DMS ini atau dihosting atau dipublikasikan di Situs DMS ini
                <em className="font-size-11"> / MASID reserves the right to edit or remove any material submitted to this DMS Website or hosted or published upon this DMS Website.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Kepatuhan <em className="font-size-11"> / Reasonableness</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Dengan menggunakan Situs DMS ini, Anda setuju bahwa pengecualian dan batasan tanggung jawab yang ditetapkan dalam penafian Situs DMS ini adalah wajar. Jika menurut Anda tidak masuk akal, Anda tidak boleh menggunakan Situs DMS ini
                <em className="font-size-11"> / By using this DMS Website, you agree that the exclusions and limitations of liability set out in this DMS Website disclaimer are reasonable. If you do not think they are reasonable, you must not use this DMS Website.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12 font-weight-bold">
                Pelanggaran Syarat dan Ketentuan in <em className="font-size-11"> / Breaches of these Terms and Conditions</em>
              </div>
              <div className="mt-1 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Tanpa mengesampingkan hak-hak lain berdasarkan syarat dan ketentuan ini, jika Anda melanggar syarat dan ketentuan ini dengan cara apa pun, MASID dapat mengambil Tindakan yang dianggap tepat untuk menangani pelanggaran tersebut, termasuk menangguhkan akses Anda ke Situs DMS, melarang Anda mengakses Situs DMS, memblokir komputer menggunakan alamat IP Anda untuk mengakses Situs Web, atau mengambil segala upaya hukum yang MASID anggap diperlukan
                <em className="font-size-11"> / Without prejudice to other rights under these terms and conditions, if you breach these terms and conditions in any way, MASID may take such action it deems appropriate to deal with the breach, including suspending your access to the DMS Website, prohibiting you from accessing the DMS Website, blocking computers using your IP address from accessing the DMS Website, or conduct any Legal actions that MASID deems necessary.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                Anda dengan ini membebaskan MASID, termasuk namun tidak terbatas pada Afiliasinya, Pemegang Saham, Direksi dan Dewan Komisaris, sepenuhnya dari segala tuntutan hukum apapun baik pidana maupun perdata dan/atau kerugian yang mungkin timbul baik pada saat ini maupun di kemudian hari sehubungan dengan penggunaan Situs DMS ini
                <em className="font-size-11"> / You hereby indemnify MASID, including but not limited to its Affiliates, Shareholders, Board of Directors and Board of Commissioners, completely from all legal actions whatsoever, both criminal and civil and/or losses that may arise both to date or in the future in accordance with the use of DMS Website.</em>
              </div>
              <div className="mt-2 mb-1 font-size-12" style={{ textAlign: "justify" }}>
                MASID dapat merevisi syarat dan ketentuan ini dari waktu ke waktu. Syarat dan ketentuan yang direvisi akan berlaku untuk penggunaan Situs DMS sejak tanggal publikasi syarat dan ketentuan yang direvisi tersebut. Silahkan periksa halaman ini secara teratur untuk memastikan Anda sudah familiar dengan versi saat ini.
                <em className="font-size-11"> / MASID may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this DMS Website from the date of the publication of such revised terms and conditions.. Please check this page regularly to ensure you are familiar with the current version.</em>
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <Button
                id="use-a-template" type="submit"
                style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
                disabled={!bottom}
                onClick={() => pressTncAgree()}>
              <span>Accept</span>
            </Button>
          </ModalFooter>
        </Modal>
        <Modal className="font-family-spoqa" backdrop={"static"} isOpen={isOpenModalResend} size="md" centered toggle={closeModalResend}>
          <ModalHeader toggle={closeModalResend}>Resend Document</ModalHeader>
          <ModalBody>
            <div className="row ml-2 mr-2 mt-3 mb-4">
              <div className='font-size-14' style={{ display: 'flex' }}>
                Do you want to resend this document ?
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button disabled={loadingResend} style={{ backgroundColor: "#002F48" }} onClick={closeModalResend}>
              Cancel
            </Button>{' '}
            <button  disabled={loadingResend} className='btn btn-primary' onClick={pressResend}>
              {loadingResend ? <Spinner color='#fff' size="sm" className='mr-1' /> : ""} Resend
            </button>

          </ModalFooter>
        </Modal>
        <Modal
            isOpen={isOpenModalNotifResend}
            size="md"
            centered={true}
            backdrop={"static"}>
          <ModalHeader>
            <div className="font-size-18 font-family-spoqa">
              Notification
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
              <div className="" style={{ display: "flex", justifyContent: "center" }}>
                <IconChecklist />
              </div>
              <div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
                Your document has been resend succesfully.
              </div>
              <div className="font-size-14" style={{ textAlign: "center" }}>
                You can access your document in the Sent Dashboard.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
                id="use-a-template" type="submit"
                style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
                onClick={closeModalNotifResend}>
              <span>Ok</span>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListConfirmation: document.documentListConfirmation,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListActivity: document.documentListActivity,
  documentListSuratKeluar: document.documentListSuratKeluar,
  documentListSuratRejected: document.documentListSuratRejected,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalAcknowledge: document.totalAcknowledge,
  totalConfirmation: document.totalConfirmation,
  totalSuratMasuk: document.totalSuratMasuk,
  totalNotifSuratMasuk: document.totalNotifSuratMasuk,
  totalActivity: document.totalActivity,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageConfirmation: document.totalPageConfirmation,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageActivity: document.totalPageActivity,
  totalPageKotakKeluar: document.totalPageKotakKeluar,
  totalPageKotakRejected: document.totalPageKotakRejected,
  totalSuratRejected: document.totalSuratRejected,
  totalNotifSuratRejected: document.totalNotifSuratRejected,
  dataDocumentType: document.dataDocumentType,
  dataDocumentPriority: document.dataDocumentPriority,
  dataDocumentStatus: document.dataDocumentStatus,
  dataDocumentSender: document.dataDocumentSender,
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentConfirmation,
  getDocumentSuratMasuk,
  getDocumentActivity,
  getDocumentSuratKeluar,
  getDocumentSuratRejected,
  getMasterDocumentType,
  getMasterDocumentPriority,
  getMasterDocumentStatus,
  getMasterDocumentSender,
  getDocumentApprovalFilter,
  getDocumentAcknowledgeFilter,
  getDocumentConfirmationFilter,
  getDocumentSuratMasukFilter,
  getDocumentActivityFilter,
  getDocumentSuratKeluarFilter,
  getDocumentSuratRejectedFilter,
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Home);