import * as React from "react"
import { SVGProps } from "react"

const ToAcknowledgeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M10.2027 29.5313L9.49985 17.5313C9.48523 17.4104 9.49654 17.2878 9.53302 17.1716C9.56951 17.0555 9.63032 16.9484 9.71142 16.8576C9.79252 16.7667 9.89204 16.6942 10.0034 16.6449C10.1147 16.5955 10.2352 16.5704 10.357 16.5713H18.2598C18.452 16.5725 18.6384 16.637 18.7902 16.7547C18.9421 16.8725 19.0509 17.0369 19.0998 17.2227L19.7856 19.9999H30.9284C31.047 19.9995 31.1644 20.0237 31.2731 20.071C31.3818 20.1183 31.4795 20.1877 31.5601 20.2747C31.6407 20.3617 31.7023 20.4644 31.7411 20.5765C31.7799 20.6885 31.795 20.8074 31.7856 20.9256L31.117 29.497C31.0824 29.9275 30.8867 30.3291 30.5689 30.6215C30.251 30.9139 29.8346 31.0756 29.4027 31.0742H11.917C11.4905 31.0763 11.0786 30.9194 10.7616 30.6341C10.4446 30.3488 10.2453 29.9556 10.2027 29.5313Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.6428 13.1431V9.71456C14.6428 9.48724 14.7331 9.26922 14.8939 9.10847C15.0546 8.94773 15.2726 8.85742 15.5 8.85742H30.0714C30.2987 8.85742 30.5167 8.94773 30.6775 9.10847C30.8382 9.26922 30.9285 9.48724 30.9285 9.71456V16.5717" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.4998 13.1436H26.6426" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToAcknowledgeIcon
