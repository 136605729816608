import * as React from "react"
import { SVGProps } from "react"

const Illustration1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={318}
    height={210}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.3}
      d="M65.69 16.431C31.678 27.495 1.745 56.635.09 96.79c-.923 22.33 3.939 79.616 74.705 94.496 113.588 23.893 215.748 15.814 237.195-41.696 19.102-51.217-11.165-119.344-67.893-133.401C204.116 6.29 132.132-5.188 65.69 16.43Z"
      fill="#E6E6E6"
    />
    <path d="M253.831.79H54.698v177.341H253.83V.79Z" fill="#E6E6E6" />
    <path
      d="M70.46 21.192a4.76 4.76 0 1 0 0-9.521 4.76 4.76 0 0 0 0 9.52Z"
      fill="#FFD200"
    />
    <path
      d="M83.869 21.192a4.76 4.76 0 1 0 0-9.521 4.76 4.76 0 0 0 0 9.52Z"
      fill="#EA6D26"
    />
    <path
      d="M96.719 21.192a4.76 4.76 0 1 0 0-9.52 4.76 4.76 0 0 0 0 9.52Z"
      fill="#24285B"
    />
    <path d="M191.571 10.93h-73.76v11.003h73.76V10.93Z" fill="#fff" />
    <path
      opacity={0.09}
      d="M152.869 49.348s21 20.3 10.261 39.31c-.345.61-.7 1.188-1.076 1.767-5.075 7.795-13.002 13.256-21.315 17.052a77.858 77.858 0 0 1-10.089 3.776c-2.74.822-6.222 1.116-8.12 3.491a13.34 13.34 0 0 0-1.685 3.045 28.794 28.794 0 0 0-2.588 8.729c-.244 2.852.589 5.654.731 8.516.426 8.739-2.812 17.377-7.734 24.624a74.55 74.55 0 0 1-10.475 12.058c-1.929 1.827-3.908 3.674-5.989 5.339-1.512 1.198-2.304 1.086-4.151 1.015H74.774l18.575-48.842 16.027-31.465s28.663-1.106 37.616-12.748c8.952-11.642 5.877-35.667 5.877-35.667Z"
      fill="#000"
    />
    <path d="M280.352 35.087h-91.299V160.48h91.299V35.087Z" fill="#24285B" />
    <path
      d="M64.32 143.479s14.403-37.971 35.393-34.734c20.99 3.238 22.645 66.686-29.435 65.519l-5.958-30.785Z"
      fill="#F4A28C"
    />
    <path
      opacity={0.1}
      d="M113.101 138.972c-1.33 11.043-6.78 22.462-17.742 29.222a41.467 41.467 0 0 1-16.92 5.694 57.48 57.48 0 0 1-8.191.376l-5.938-30.785s14.413-37.971 35.403-34.734c6.09.944 10.587 7.004 12.576 15.083a43.034 43.034 0 0 1 .812 15.144Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      d="M113.101 138.972c-5.217 6.09-14.505 9.622-16.098 19.031a834.95 834.95 0 0 0-1.644 10.15 41.482 41.482 0 0 1-16.92 5.695l3.045-19.793 29.668-31.303c.39.337.759.696 1.106 1.076a43.064 43.064 0 0 1 .843 15.144Z"
      fill="#000"
    />
    <path
      d="m116.718 98.396-12.041-3.903-8.059 24.863 12.04 3.902 8.06-24.862Z"
      fill="#EA6D26"
    />
    <path
      d="M132.203 30.905a37.696 37.696 0 1 0-23.246 71.719 37.696 37.696 0 0 0 23.246-71.719Zm-20.371 62.829a28.36 28.36 0 1 1 35.718-18.27 28.36 28.36 0 0 1-35.718 18.24v.03ZM110.409 115.922l-10.93-3.543a4.79 4.79 0 0 0-6.034 3.08l-10.77 33.224a4.791 4.791 0 0 0 3.08 6.035l10.93 3.543a4.792 4.792 0 0 0 6.035-3.081l10.769-33.224a4.79 4.79 0 0 0-3.08-6.034Z"
      fill="#24285B"
    />
    <path
      d="M83.016 131.441s18.767-14.078 25.538-11.531c5.582 2.112 3.816 13.612-10.242 21.234a22.6 22.6 0 0 0-11.368 14.921c-1.177 5.298-3.928 11.388-10.15 15.357-12.982 8.353-20.097-1.766-16.88-15.225 3.218-13.459 4.619-17.428 23.102-24.756Z"
      fill="#F4A28C"
    />
    <path
      d="m74.551 209.21 8.932-38.996-29.496-14.149-25.243 53.145h45.807Z"
      fill="#EA6D26"
    />
    <path
      opacity={0.1}
      d="m74.551 209.21 8.932-38.996-14.078-6.76-13.144 45.756h18.29Z"
      fill="#000"
    />
    <path
      opacity={0.43}
      d="M240.737 63.02h-19.752v5.663h19.752V63.02Z"
      fill="#C1C1C1"
    />
    <path
      opacity={0.36}
      d="M207.821 74.6a7.531 7.531 0 1 0 0-15.062 7.531 7.531 0 0 0 0 15.063Z"
      fill="#C1C1C1"
    />
    <path d="M246.949 132.232H205.73v12.038h41.219v-12.038Z" fill="#EA6D26" />
    <path d="M246.289 83.695H205.73V88.7h40.559v-5.004Z" fill="#C1C1C1" />
    <path
      opacity={0.44}
      d="M269.117 96.789H205.73v5.004h63.387v-5.004ZM269.117 107.751H205.73v5.004h63.387v-5.004ZM255.485 119.139H205.73v5.004h49.755v-5.004Z"
      fill="#C1C1C1"
    />
    <path
      d="M197.904 48.495a3.42 3.42 0 1 0 0-6.84 3.42 3.42 0 0 0 0 6.84Z"
      fill="#FFD200"
    />
    <path
      d="M207.547 48.495a3.42 3.42 0 1 0 0-6.84 3.42 3.42 0 0 0 0 6.84Z"
      fill="#EA6D26"
    />
    <path
      d="M216.783 48.495a3.421 3.421 0 1 0 0-6.842 3.421 3.421 0 0 0 0 6.842Z"
      fill="#fff"
    />
    <path
      d="M120.886 90.526a23.802 23.802 0 1 1 23.761-23.791 23.82 23.82 0 0 1-23.761 23.791Zm0-41.178a17.387 17.387 0 1 0 17.387 17.387 17.4 17.4 0 0 0-17.387-17.387Z"
      fill="#C9C9C9"
    />
    <path
      d="M120.886 90.526a23.759 23.759 0 0 1-15.783-5.988l4.252-4.79a17.339 17.339 0 0 0 11.531 4.374v6.404Z"
      fill="#24285B"
    />
    <path
      d="M144.647 66.735h-6.405a17.408 17.408 0 0 0-17.387-17.387v-6.415a23.82 23.82 0 0 1 23.792 23.802Z"
      fill="#EA6D26"
    />
    <path
      d="M120.886 90.526v-6.404a17.416 17.416 0 0 0 17.387-17.387h6.374a23.82 23.82 0 0 1-23.761 23.791Z"
      fill="#FFD200"
    />
    <path
      opacity={0.08}
      d="M137.227 141.652h-9.805v15.905h9.805v-15.905ZM153.762 124.569h-9.805v32.998h9.805v-32.998ZM171.199 113.08h-9.804v44.487h9.804V113.08Z"
      fill="#000"
    />
    <path
      d="M229.907 6.86a14.82 14.82 0 0 1 5.613 1.015 13.71 13.71 0 0 1 4.151 2.497c.488.416.934.862 1.381 1.309a1.51 1.51 0 0 1-1.031 2.518 1.513 1.513 0 0 1-1.05-.336c-.457-.406-.863-.852-1.319-1.248a11.4 11.4 0 0 0-14.109-.498 16.58 16.58 0 0 0-2.03 1.787c-.27.334-.652.558-1.076.63a1.505 1.505 0 0 1-1.492-.701 1.408 1.408 0 0 1 .112-1.726 16.983 16.983 0 0 1 2.903-2.629 14.203 14.203 0 0 1 4.222-2.03 13.47 13.47 0 0 1 3.725-.588Z"
      fill="#EA6D26"
    />
    <path
      d="M230.648 12.34c1.2.058 2.377.355 3.461.874a9.577 9.577 0 0 1 3.147 2.476 1.617 1.617 0 0 1 0 2.112 1.555 1.555 0 0 1-1.949.385 5.902 5.902 0 0 1-1.076-.893 5.31 5.31 0 0 0-5.126-1.431 5.715 5.715 0 0 0-2.791 1.705c-.301.334-.642.63-1.015.883a1.594 1.594 0 0 1-1.969-.325 1.634 1.634 0 0 1-.142-2.03c.339-.41.705-.796 1.096-1.157a9.48 9.48 0 0 1 1.644-1.289 8.434 8.434 0 0 1 3.208-1.137c.497-.121 1.005-.132 1.512-.172ZM232.81 21.242a2.636 2.636 0 0 1-1.582 2.469 2.641 2.641 0 0 1-3.635-1.854 2.639 2.639 0 0 1 2.527-3.203 2.62 2.62 0 0 1 2.69 2.588Z"
      fill="#EA6D26"
    />
    <g opacity={0.08} fill="#000">
      <path
        opacity={0.08}
        d="m121.566 12.483 1.015 3.918c.223.863.437 1.664.579 2.456.182-.781.436-1.604.69-2.436l1.258-3.938h1.269l1.198 3.857c.284.924.507 1.736.68 2.517.147-.843.344-1.677.589-2.497l1.096-3.877h1.39l-2.486 7.714h-1.279l-1.188-3.684a27.941 27.941 0 0 1-.68-2.528c-.188.861-.422 1.711-.7 2.548l-1.249 3.664h-1.268l-2.325-7.714h1.411ZM133.482 12.483l1.015 3.918c.224.863.427 1.664.569 2.456h.05c.173-.781.427-1.604.68-2.436l1.259-3.938h1.177l1.198 3.857c.284.924.508 1.736.69 2.517.152-.842.349-1.675.589-2.497l1.106-3.877h1.381l-2.487 7.714h-1.269l-1.177-3.684a24.824 24.824 0 0 1-.69-2.528 22.688 22.688 0 0 1-.701 2.548l-1.238 3.664h-1.279l-2.324-7.714h1.451ZM145.408 12.483l1.015 3.918c.224.863.437 1.664.579 2.456.183-.781.436-1.604.69-2.436l1.259-3.938h1.177l1.198 3.857c.284.924.507 1.736.68 2.517h.051c.147-.843.343-1.677.588-2.497l1.137-3.877h1.391l-2.487 7.714h-1.279l-1.177-3.684a27.615 27.615 0 0 1-.68-2.528 25.23 25.23 0 0 1-.701 2.548l-1.248 3.664h-1.269l-2.334-7.714h1.41ZM155.619 19.365a.956.956 0 0 1 1.856-.394.94.94 0 0 1 .042.394.953.953 0 1 1-1.898 0Z"
      />
    </g>
  </svg>
)

export default Illustration1
