import * as React from "react"

const UploadDocumentListIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 1.6a1.6 1.6 0 0 0-1.6 1.6v9.6a1.6 1.6 0 0 0 1.6 1.6h6.4a1.6 1.6 0 0 0 1.6-1.6V5.93a1.6 1.6 0 0 0-.469-1.131L9.6 2.068A1.6 1.6 0 0 0 8.469 1.6H4.8Zm4 4.8a.8.8 0 1 0-1.6 0V8H5.6a.8.8 0 1 0 0 1.6h1.6v1.6a.8.8 0 0 0 1.6 0V9.6h1.6a.8.8 0 0 0 0-1.6H8.8V6.4Z"
      fill="#fff"
    />
  </svg>
)

export default UploadDocumentListIcon
