import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import HeaderPublic from 'app/shared/layout/header-public/header';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconChecklist from "app/component/icon-checklist";
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiForgotPassword } from 'app/config/api-collection';
import { translate } from 'react-jhipster';

export const ForgotPassword = (props) => {

  const { height, width } = useWindowDimensions();
  const [modalForgotPassword, setModalForgotPassword] = useState(false);
  const [numberEmployeeValue, setNumberEmployeeValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("ForgotPassword", props)
  }, []);

  const pressGoBack = () => {
    props.history.push('/')
    window.location.reload();
  }

  const clearDataState = () => {
    setNumberEmployeeValue("")
    setEmailValue("")
  }

  const pressForgotPassword = () => {
    if (!numberEmployeeValue || !emailValue) {
      toast.error(`The Employee Number and Email Cannot be Emptied`);
    } else {
      setLoading(true)
      let dataFormData = {
        employeeNo: numberEmployeeValue,
        email: emailValue
      }
      localStorage.removeItem('access_token');

      axios.post(`${apiForgotPassword}`, dataFormData)
        .then(res => {
          setModalForgotPassword(true)
          clearDataState()
          setLoading(false)
        }).catch(err => {
          setLoading(false)
          toast.error(`Employee number and email doesn't match`);
          // toast.error(`${err.response.data.message}`);
        })
    }
  }

  const pressButtonLogin = () => {
    setModalForgotPassword(false)
    pressGoBack()
  }

  return (
    <>
      <HeaderPublic />
      <div style={width < 425 ? { height: '90vh', background: '#fff', overflowY: 'scroll' } : { height: '90vh', background: '#fff' }} className="mt-5 py-5 container-lg font-family-spoqa">
        <div className="account-pages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card overflow-hidden">
                  <div className="card-body pt-0">
                    <div className="p-2">
                      <div className="div-container">
                        <div className="col-1 mr-2" style={{ display: "flex", alignItems: "center" }}>
                          <button className="btn px-0" onClick={() => pressGoBack()}>
                            <FontAwesomeIcon size='2x' icon="arrow-left" color="#0A0A0A" />{' '}
                          </button>
                        </div>
                        <div className="col-md-10" style={{ display: "flex", alignItems: "center" }}>
                          <div className="font-size-18">Forgot Password</div>
                        </div>
                      </div>
                      <div className="row mt-4 ml-1">
                        <div className="col-12">
                          <div className="">
                            <h5 className="">Welcome, to Mirae Asset DMS</h5>
                          </div>
                        </div>
                      </div>
                      <div className="div-container-column">
                        <div className="col-6 mt-4">
                          Employee Number
                        </div>
                        <div className="pass-wrapper mt-2">
                          <input
                            type="text"
                            className="form-control bg-white col-12 ml-2"
                            placeholder="Input Employee Number"
                            onChange={(e) => { setNumberEmployeeValue(e.target.value) }}
                          />
                        </div>
                      </div>
                      <div className="div-container-column">
                        <div className="col-6 mt-4">
                          Email
                        </div>
                        <div className="pass-wrapper mt-2">
                          <input
                            type="text"
                            className="form-control bg-white col-12 ml-2"
                            placeholder="Input Email"
                            onChange={(e) => { setEmailValue(e.target.value) }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 d-grid ml-2 " style={{ width: "100%" }}>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading} onClick={() => pressForgotPassword()}>
                          {loading ? <Spinner size="sm" color="#fff" className='mr-1' /> : <></>}
                          <span style={{ color: '#F5F5F5', textAlign: 'center' }}>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal centered backdrop={"static"} isOpen={modalForgotPassword}>
          <ModalHeader>
          </ModalHeader>
          <ModalBody>
            <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
              <div className="" style={{ display: "flex", justifyContent: "center" }}>
                <IconChecklist />
              </div>
              <div className="font-size-20 mt-3 font-weight-bold" style={{ textAlign: "center" }}>
                Create Password Success.
              </div>
              <div className="font-size-12" style={{ textAlign: "center" }}>
                Silahkan cek email dan login menggunakan password baru.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              id="use-a-template" type="submit"
              style={{ width: "100px", borderColor: "#002F48", backgroundColor: "#002F48", color: "#fff" }}
              onClick={pressButtonLogin}>
              <span>Login</span>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  //   isAuthenticated: authentication.isAuthenticated,
  //   loginError: authentication.loginError,
  //   showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
