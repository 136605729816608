import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Label, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import EyeSmallIcon from 'app/component/eye-small-icon';
import { toast } from 'react-toastify';
import UploadFileIcon from 'app/component/upload-icon';
import DragUploadIcon from 'app/component/word-document-icon';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Histori = (props: IDocumentProps) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(10)
  const [keyword, setKeyword] = useState('')
  const [selected, setSelected] = useState([])
  const [listDocumentDraft, setListDocumentDraf] = useState<any>([])
  const [listDocumentHistori, setListDocumentHistori] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [showModalSuccess, setShowModalSuccess] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [showList, setShowList] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [listAcces, setListAccess] = useState<any>([])
  const [openSelect, setopenSelect] = useState<any>(false)
  const [showAdd, setShowAdd] = useState<any>(false)
  const [listAvailable, setListAvailable] = useState<any>([])
  const [listSelected, setListSelected] = useState<any>([])


  const handleClose = () => {
    setDocumentSelected(null)
    setShowModal(false)
  };

  const handleCloseSuccess = () => {
    setDocumentSelected(null)
    setShowModalSuccess(false)
  };

  const handleOpen = (item) => {
    setDocumentSelected(item)
    setShowModal(true)
  }

  const handlefocus = (item) => {
    setopenSelect(true)
  }

  const handleblur = (item) => {
    setopenSelect(false)
  }

  useEffect(() => {
    getListDocumentDraft(page, limit)
    getListDocumentHistori(page, limit)
  }, []);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getListDocumentHistori(value, limit)

  }

  const renderStatusPriority = (data) => {
    if (data == "1") {
      return <span style={{
        backgroundColor: '#FFF4F2',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #CB3A31',
        color: '#CB3A31',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>High</span>
    } else if (data == "2") {
      return <span style={{
        backgroundColor: '#FFF4EB',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #F37F26',
        color: '#F37F26',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Medium</span>
    } else {
      return <span style={{
        backgroundColor: '#F0F3FF',
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 6,
        paddingBottom: 6,
        border: '1px solid #3267E3',
        color: '#3267E3',
        borderRadius: 8,
        fontSize: 13,
        fontWeight: 600
      }}>Low</span>
    }
  }

  const renderBatasWaktu = (data) => {
    if (!data) return '-'
    return moment(data).format('DD MMM YY | HH:mm') + " WIB"
  }

  const viewAccess = (id) => {
    axios.get(`services/documentservice/api/document-users/view-access?fileId=${id}`).then(res => {
      setListAccess(res.data.data.content)
    }).catch(err => {
      setListAccess([])
    })
  }

  const getListAccess = () => {
    axios.get(`/services/documentservice/api/get-list-user-and-department?fileId=${data.id}`).then(res => {
      setListAvailable(res.data)
    }).catch(err => {
      setListAvailable([])
    })
  }

  const deleteUser = (id) => {
    axios.delete(`services/documentservice/api/document-users/${id}`).then(res => {
      viewAccess(data.id)
    }).catch(err => {
      viewAccess(data.id)
    })
  }

  const changeShow = (e) => {
    let show = e.target.value
    setPage(1)
    setLimit(show)
    getListDocumentHistori(1, show)
  }

  const gotoDetail = (id) => {
    props.history.push(`/history/detail/${id}`)
  }

  const gotoDetailView = (id) => {
    props.history.push(`/preview/only/pdf/${id}`)
  }

  const setDataModal = (data) => {
    setData(data)
    setShowModal(true)
    viewAccess(data.id)
    setShowAdd(false)
    setopenSelect(false)
  }

  const SubmitSelected = () => {
    setLoadingButton(true)
    const accessors = listAvailable.filter((el) => {
      return listSelected.some((f) => {
        return f.value === el.accessorCode
      });
    });
    let final = {
      accessors: accessors
    }
    const formData = new FormData();
    formData.append("data", JSON.stringify(final));
    formData.append("fileId", data.id)

    axios.post(`/services/documentservice/api/document-users/add-accessor`, formData).then(result => {
      // toast.success('Success Add Accessor(s)', {
      //   position: "top-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      viewAccess(data.id)
      setLoadingButton(false)
      setShowAdd(false)

      setSelected([])
      setShowModal(false)
      setShowModalSuccess(true)
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`));
      setLoadingButton(false)
    })
  }

  const Option = (props) => {
    return (
      <>
        {
          props.options.length > 0 && <div className="mx-2 my-3">
            {/* {JSON.stringify(data)} */}
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 4 }}>
                {props.data.accessorName}
              </div>
              {
                props.data.accessorAsCode === 'ACCESSOR' && <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => deleteUser(props.data.documentUserId)}>
                  <FontAwesomeIcon icon="trash" color="#CB3A31" />{' '}
                </div>
              }
            </div>
          </div>
        }
        {
          props.data.documentUserId === props.options[props.options.length - 1].documentUserId && <>
            <div className="mx-2 mt-3" style={{ cursor: 'pointer' }} onClick={() => showListAdd()}>
              <hr className="mb-3" />
              <div className="my-2">
                <FontAwesomeIcon icon="plus" className="mr-2" /> Add New Access
              </div>
            </div>
          </>
        }
        {
          props.options.length == 0 && <>
            <div className="mx-2 mt-3" style={{ cursor: 'pointer' }} onClick={() => showListAdd()}>
              <hr className="mb-3" />
              <div className="my-2">
                <FontAwesomeIcon icon="plus" className="mr-2" /> Add New Access
              </div>
            </div>
          </>
        }
      </>
    )
  }

  const showListAdd = () => {
    if (openSelect === true) {
      setopenSelect(false)
    }
    if (showAdd === false) {
      setShowAdd(true)
    }
    getListAccess()
  }

  const getListDocumentDraft = (page, size) => {
    axios.get(`/services/documentservice/api/documents/draft?page=${page}&size=${size}`)
      .then(res => {
        setListDocumentDraf(res.data.data)

      }).catch(err => {

      })
  }

  const getListDocumentHistori = (page, size) => {
    axios.get(`/services/documentservice/api/documents/dashboard_history?page=${page}&size=${size}&keyword=${keyword}`)
      .then(res => {
        setListDocumentHistori(res.data.data)
      }).catch(err => {

      })
  }

  const gotoPreview = (id) => {
    props.history.push(`/preview/only/pdf/${id}`)
  }

  const gotoEdit = (id) => {
    props.history.push(`/draft/${id}/edit`)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  const { documentList, loading } = props;
  return (
    <div className="px-5 pt-4" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-28">History File</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-14">List Documents History</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className='row my-3 font-family-spoqa'>
              <div className="col-12 col-md-4 col-lg-4 px-0">
                <div className="d-flex px-3" style={{ flexGrow: 10 }}>
                  <div className="app-search w-100 py-0 bg-white">
                    <div className="position-relative">
                      <input type="text" className="form-control bg-white py-3" placeholder="Search History" onChange={(e) => {
                        if (e.target.value === '') {
                          setPage(1)
                          setKeyword(e.target.value)
                          getListDocumentHistori(1, 10)
                        } else {
                          setKeyword(e.target.value)
                        }
                      }} onKeyPress={(e) => {
                        console.log(e.key)
                        if (e.key == 'Enter') {
                          setPage(1)
                          getListDocumentHistori(1, 10)
                        }
                      }} />
                      <span className="bx bx-search-alt my-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card font-family-spoqa">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-centered  mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th className='py-3'>No Surat</th>
                        <th>Pengirim</th>
                        <th>Judul</th>
                        <th>Status Disposisi</th>
                        <th>Exp Date</th>
                        <th className='fix'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listDocumentHistori.content ? listDocumentHistori.content.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '200px', cursor: 'pointer', color: '#556ee6' }} onClick={() => gotoDetail(item?.id)}><a>{item?.fileNumber}</a></td>
                            <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.senderBranchName}</td>
                            <td style={{ wordBreak: 'break-all', minWidth: '300px', maxWidth: '400px', whiteSpace: 'pre-wrap' }}>{item.regarding ? item.regarding : '-'}</td>
                            <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.fileStatus}</td>
                            <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item?.expDateFormat}</td>
                            <td style={{ wordBreak: 'break-all', minWidth: '180px' }} className='fix'>
                              {item.canViewAccess ? <>
                                <div className="btn btn-primary mr-2" onClick={() => setDataModal(item)}>
                                  <EyeSmallIcon /> View Access
                                </div>

                              </> : <></>}
                              <div className="btn btn-info" onClick={() => gotoDetailView(item?.id)}>
                                <EyeSmallIcon /> View Document
                              </div>
                            </td>
                          </tr>
                        )
                      }) : <></>}
                    </tbody>
                  </table>
                </div>
                <div className="text-center mt-3">
                  {listDocumentHistori.content ? <></> : <p style={{ color: "lightgray" }}>Loading...</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 cold-md-6 col-lg-6">
                <div className="form-group row">
                  <label htmlFor="priority" className="col-sm-1 col-form-label text-left">Show</label>
                  <div className="text-center" style={{ width: '80px' }}>
                    <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)}>
                      <option value="2">2</option>
                      <option value="5">5</option>
                      <option value="10" selected>10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <label htmlFor="priority" className="col-sm-2 col-form-label text-left">Entries</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" style={{ justifyContent: 'end', display: 'flex' }}>
                <Pagination count={listDocumentHistori.totalPages} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="font-family-spoqa" isOpen={showModal} toggle={handleClose} size="lg" centered onClosed={() => {
        setListSelected([])
        setShowAdd(false)
        setLoadingButton(false)
      }}>
        <ModalHeader toggle={handleClose} style={{ borderBottom: 'none' }}>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <div className="text-center">
            <h2>Edit View Access</h2>
            <span style={{ fontSize: '12px' }}>  {data?.fileNumber}  </span>
          </div>
          <div className="px-4 my-4">
            <div className="row mx-5">
              <div className="col-12 col-md-3 text-center" >
                View Access
              </div>
              <div className="col-12 col-md-9">
                <div className="mb-3">
                  <Select

                    isLoading={listAcces.length == 0}
                    isClearable={true}
                    styles={customStyles}
                    isSearchable={true}
                    name="color"
                    components={{ Option, }}
                    options={listAcces}
                    onFocus={handlefocus}
                    onBlur={handleblur}
                    menuIsOpen={openSelect}
                    placeholder={listAcces.length > 0 ? "Click To Show List" : "Click To Show List"}
                  />
                </div>
                <div>
                  {
                    showAdd && <Select
                      name="color1"
                      closeMenuOnSelect={false}
                      isMulti
                      // options={listAvailable}
                      placeholder="Find Name Or Division"
                      options={listAvailable.map(e => ({ label: e.accessorName, value: e.accessorCode }))}
                      // getOptionLabel={(option) => option.accessorName}
                      // getOptionValue={(option) => option.accessorCode}
                      onChange={newValue => setListSelected(newValue)}
                    />
                  }
                </div>
                {
                  showAdd && <div className="my-3">
                    <button className="btn btn-primary" disabled={listSelected.length == 0 || loadingButton} onClick={() => SubmitSelected()}>
                      {loadingButton ? <Spinner color='#fff' size="sm" className="mr-1" /> : <></>} Simpan Perubahan
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
        </ModalFooter>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={showModalSuccess} toggle={handleCloseSuccess} size="lg" centered>
        <ModalHeader toggle={handleClose} style={{ borderBottom: 'none' }}>
        </ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <div className="text-center">
            <h1>Edit Access Success</h1>
            <button className="btn text-white px-4 py-2" style={{ background: '#002F48' }} onClick={() => setShowModalSuccess(false)}>
              OK
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Histori);
