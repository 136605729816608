import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Label, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';

import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@mui/material';
import EyeSmallIcon from 'app/component/eye-small-icon';
import { toast } from 'react-toastify';
import UploadFileIcon from 'app/component/upload-icon';
import DragUploadIcon from 'app/component/word-document-icon';

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Linktree = (props: IDocumentProps) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  
  const [count, setCount] = useState<any>(0)
  const [pageNumSize, setPageNumSize] = useState({pageNum: 1, pageSize: 10})
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(10)
  const [keyword, setKeyword] = useState('')
  const [isLoadingTable, setLoadingTable] = useState(false)
  const [isLoadingDrafting, setLoadingDrafting] = useState(false)
  const [isOpenModalDelete, setOpenModalDelete] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null)

  const [selected, setSelected] = useState([])
  const [listDocumentDraft, setListDocumentDraf] = useState<any>([])
  const [listDocumentHistori, setListDocumentHistori] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [showModalSuccess, setShowModalSuccess] = useState<any>(false)
  const [documentSelected, setDocumentSelected] = useState<any>(null)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [showList, setShowList] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [listAcces, setListAccess] = useState<any>([])
  const [openSelect, setopenSelect] = useState<any>(false)
  const [showAdd, setShowAdd] = useState<any>(false)
  const [listAvailable, setListAvailable] = useState<any>([])
  const [listSelected, setListSelected] = useState<any>([])


  useEffect(() => {
    getListDocumentHistori(page, limit)
  }, []);

  const toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getListDocumentHistori(value, limit)

  }


  const changeShow = (e) => {
    let show = e.target.value
    setPage(1)
    setLimit(show)
    getListDocumentHistori(1, show)
  }

  const gotoDetailView = (id) => {
    props.history.push(`/linktree/detail/${id}`)
  }

  const deleteById = async (id) => {
    try {
      await axios.delete(`/services/linkservice/api/v1/linktree/page-data?pageId=${id}`)
      getListDocumentHistori(page, limit)
      // props.history.push(`/linktree/`)
    }
  }


  const getListDocumentHistori = (page, size) => {
    setLoadingTable(true);
    axios.get(`/services/linkservice/api/v1/linktree/all?pageNum=${page}&pageSize=${size}&keyword=${keyword}`)
      .then(res => {
        setPage(res.data?.page);
        setLimit(res.data?.size);
        setCount(res.data?.count);
        setListDocumentHistori(JSON.parse(res.data?.data));
        setLoadingTable(false);
      }).catch(err => {
        console.log(err)
        setLoadingTable(false);
      })
  }

  const draftingLinktreePage = (pid, stat) => {
    setLoadingDrafting(true);

    axios.get(`/services/linkservice/api/v1/linktree/drafting?pageId=` + pid + "&isDraft=" + ((stat=="t") ? "f" : "t"))
      .then(res => {
        toast.success("Saved!");
        setLoadingDrafting(false);
        getListDocumentHistori(page, limit);
      }).catch(err => {
        toast.error(err?.response?.data);
        setLoadingDrafting(false);
      })

  }

  const gotoPreview = (id) => {
    props.history.push(`/linktree/detail/${id}`)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  return (
    <div className="px-5 pt-4" style={{ height: '100%' }}>

      <Modal style={{top:"25%"}} isOpen={isOpenModalDelete}>
          <ModalBody>
              <p style={{marginTop: '20px', fontSize:"20px"}} className="text-center mb-2 font-m-500">{"Delete"}</p>
              <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{"Apakah anda yakin untuk menghapus page "}<b>{toBeDeleted?.id}</b></p>
              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}} ><div style={{display: 'inline-block'}} >
              <div></div>
              </div></div>

              <div className="d-flex justify-content-center mb-3">
                  <div onClick={ () => {
                    ;
                  }}>
                      <button 
                        className="btn btn-primary" style={{color: "white", width: "70px"}}
                        onClick={() => { setOpenModalDelete(false); }} disabled={false}>
                        {'Cancel'}
                      </button>
                  </div>
                  <div style={{width: '10px'}} />
                  <div>
                      <button 
                        className="btn" style={{backgroundColor: "red", color: "white", width: "70px"}} 
                        onClick={() => { deleteById(toBeDeleted?.id); setOpenModalDelete(false); }} disabled={false}>
                        {'OK'}
                      </button>
                  </div>
              </div>
          </ModalBody>
      </Modal>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-28">Linktree</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-14">Linktree Page List</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">


            <div className='my-3 font-family-spoqa d-flex flex-row justify-content-between'>

              <div className='d-flex' style={{width: '350px'}}>
                <div className="app-search py-0 bg-white">
                  <div className="position-relative">
                    <input type="text" className="form-control bg-white py-3" placeholder="Search" onChange={(e) => {
                      if (e.target.value === '') {
                        setPage(1)
                        setKeyword(e.target.value)
                        getListDocumentHistori(1, 10)
                      } else {
                        setKeyword(e.target.value)
                      }
                    }} onKeyPress={(e) => {
                      if (e.key == 'Enter') {
                        setPage(1)
                        getListDocumentHistori(1, 10)
                      }
                    }} />
                    <span className="bx bx-search-alt my-0"></span>
                  </div>
                </div>

                <button className="btn btn-info ml-4" onClick={(e) => {
                  setPage(1)
                  getListDocumentHistori(1, 10)
                }} disabled={isLoadingTable}>
                  {isLoadingTable && <Spinner color='#fff' size="sm" className='mr-1' />}Search
                </button>
              </div>

              <button className="btn btn-primary ml-5" onClick={() => {gotoDetailView("new")}} disabled={false}>
                {'Create New'}
              </button>

            </div>

            <div className="card font-family-spoqa">
              <div className="card-body">
                {isLoadingTable ? 
                  <div className="text-center mt-3">
                    <p style={{ color: "lightgray" }}>Loading...</p>
                  </div>
                  : <div className="table-responsive">
                    <table className="table table-striped table-centered  mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>Page ID</th>
                          <th>Title</th>
                          <th>Creation Date</th>
                          <th className='fix'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {((listDocumentHistori != null) && (listDocumentHistori.length > 0)) && listDocumentHistori.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>{item.id}</td>
                              <td style={{ wordBreak: 'break-all', minWidth: '200px', maxWidth: '200px', whiteSpace: 'pre-wrap' }}>{item.title ? item.title : '-'}</td>
                              <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{moment(toDateTime(item.time["seconds"])).format("DD-MM-YYYY HH:MM:ss")}</td>
                              <td style={{ wordBreak: 'break-all', minWidth: '180px' }} className='fix'>
                                <div className="btn btn-primary" onClick={() => gotoDetailView(item?.id)}>
                                  {'Edit'}
                                </div>
                                <div className="btn btn-danger ml-2" onClick={() => {
                                  setToBeDeleted(item);
                                  setOpenModalDelete(true);
                                }}>
                                  {'Delete'}
                                </div>
                                <div className={"ml-2 btn btn-" + ((item?.isDraft == "t") ? "success" : "")} style={(item?.isDraft == "t") ? {} : {backgroundColor: "#043B72BF", color: 'white'}} onClick={() => {
                                  draftingLinktreePage(item?.id, item?.isDraft);
                                }}>
                                  {(item?.isDraft == "t") ? 
                                    <><i className="uil uil-play" style={{marginRight: '5px'}} ></i>{'Publish'}</> : 
                                    <><i className="uil uil-backward" style={{marginRight: '5px'}} ></i>{'Revert to Draft'}</>}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                }
                
              </div>
            </div>
            <div className="row">
              <div className="col-12 cold-md-6 col-lg-6">
                <div className="form-group row">
                  <label htmlFor="priority" className="col-sm-1 col-form-label text-left">Show</label>
                  <div className="text-center" style={{ width: '80px' }}>
                    <select name="show" value={limit} className="custom-select" onChange={(e) => changeShow(e)} defaultValue={10}>
                      <option value="1">1</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="20">50</option>
                      <option value="20">100</option>
                      <option value="20">500</option>
                    </select>
                  </div>
                  <label htmlFor="priority" className="col-sm-2 col-form-label text-left">Entries</label>
                  <label htmlFor="priority" className="col-sm-5 col-form-label text-left ml-4">{'Total Entries: ' + count}</label>
                </div>
              </div>
              {(count > limit) && <div className="col-12 col-md-6 col-lg-6" style={{ justifyContent: 'end', display: 'flex' }}>
                <Pagination count={Math.ceil(count/limit)} color="primary" page={page} showFirstButton showLastButton onChange={changePage} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  // documentList: document.documentDashboardList,
  // documentListAcknowledge: document.documentListAcknowledge,
  // loading: document.loading,
  account: authentication.account,
});


const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentApproval,
  getDocumentAcknowledge,
  getDocumentSuratKeluar,
  getDocumentSuratMasuk
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Linktree);
