import * as React from "react"
import { SVGProps } from "react"

const SearchReceivedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="14" fill="#FFF4EB"/>
    <path d="M6.58838 10.705L13.0865 15.0371C13.6398 15.4059 14.3605 15.4059 14.9138 15.0371L21.4119 10.705M8.23544 19.7638H19.7648C20.6745 19.7638 21.4119 19.0264 21.4119 18.1167V9.88143C21.4119 8.97179 20.6745 8.23438 19.7648 8.23438H8.23544C7.32579 8.23438 6.58838 8.97179 6.58838 9.88143V18.1167C6.58838 19.0264 7.32579 19.7638 8.23544 19.7638Z" stroke="#F58220" stroke-width="1.40824" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export default SearchReceivedIcon
