import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Document from './document';
import DocumentDetail from './document-detail';
import DocumentUpdate from './document-update';
import DocumentUpload from './document-update-upload';
// import DocumentPreview from '../preview/document-preview';
import DocumentDeleteDialog from './document-delete-dialog';

import DocumentUpdateBack from './document-update-back';
import { DocumentNew } from './documentnew';
import { CreateDocument } from './create-document';
import { DocumentCreate } from './document-create';

import { ReleaseApprovalCreate } from './releaseapproval-create';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/create/new`} component={DocumentCreate} />

      <ErrorBoundaryRoute exact path={`${match.url}/create/edit`} component={DocumentCreate} />
      <ErrorBoundaryRoute exact path={`${match.url}/create/:id`} component={DocumentCreate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DocumentDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/upload/new`} component={DocumentUpload} />

      <ErrorBoundaryRoute exact path={`${match.url}/releaseapproval/create/new`} component={ReleaseApprovalCreate} />
      <ErrorBoundaryRoute exact path={`${match.url}/releaseapproval/create/edit`} component={ReleaseApprovalCreate} />
      <ErrorBoundaryRoute exact path={`${match.url}/releaseapproval/create/:id`} component={ReleaseApprovalCreate} />

      <ErrorBoundaryRoute path={match.url} component={DocumentNew} />

    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DocumentDeleteDialog} />
  </>
);

export default Routes;
