import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';


import PinField from "react-pin-field"

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';


import { IRootState } from 'app/shared/reducers';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import SignatureIcon from 'app/component/signature-icon';

import LogoutIcon from 'app/component/logout-icon';
import { toast } from 'react-toastify';
import { logout } from 'app/shared/reducers/authentication';
import { useHistory } from "react-router-dom";
import Axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';


const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]


export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const PinSet = (props: IDocumentProps) => {
    // const { account } = props;
    let history = useHistory();

    const [category, setCategory] = useState<any>(0)
    const [page, setPage] = useState<any>(1)
    const [limit, setLimit] = useState<any>(5)
    const [selected, setSelected] = useState(list[0])
    const ref = useRef<HTMLInputElement[]>([]);
    const [oldPin, setOldPin] = useState<any>("")
    const [pin, setPin] = useState<any>("")
    const [confirmpin, setConfirmPin] = useState<any>("")
    const [showPasswordPIN, setShowPasswordPIN] = useState<boolean>(false)
    const [showConfirmPasswordPIN, setShowConfirmPasswordPIN] = useState<boolean>(false)

    useEffect(() => {
        // props.getSignature()
    }, []);

    const delayTime = (ms) => {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        )
    }

    const resetForm = () => {
        setPin("")
        setConfirmPin("")
    }

    const savePin = () => {
        if (pin != confirmpin) {
            toast.error('Pin dan konfirmasi Pin berbeda', {
                position: "top-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const formData = new FormData();
            formData.append("pinApproval", pin)
            Axios.put('services/uaadocservice/api/personal-identity/save-pin-approval', formData)
            .then(res => {
                toast.success('Berhasil Set PIN', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.push('/profile/me')
            }).catch(err => {
                toast.error('Gagal Set PIN', {
                    position: "top-left",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }
    }

    return (
        <div className="container text-center">
            <div className='container d-flex  h-100' style={{ alignItems: 'center', justifyContent: 'center' }}>
                <div className="" style={{ padding: '10px', width: '70%' }}>
                    <div className='d-flex mb-10'>
                        <button className="btn px-0 mr-4" onClick={() => history.replace('/profile/me')}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057" />{' '}
                        </button>
                        <h3 className='mb-0'>
                            Set PIN
                        </h3>
                    </div>
                    <div className='mt-10 text-left'>
                        <div>
                            <h5 className='px-3' style={{ marginTop: '50px' }}>
                                PIN
                            </h5>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <PinField
                                            ref={ref}
                                            className="ml-3 bg-transparent pin-field"
                                            type={showPasswordPIN ? 'text' : 'password'}
                                            style={{}}
                                            validate={/^[0-9]$/}
                                            onChange={(e) => setPin(e)}
                                            length={6}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 eyepin">
                                        {
                                            !showPasswordPIN ?
                                                <button className="btn px-0" onClick={() => setShowPasswordPIN(!showPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEye} style={{ fontSize: "20px" }} color="#9E9E9E" />
                                                </button>
                                                :
                                                <button className="btn px-0" onClick={() => setShowPasswordPIN(!showPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize: "20px" }} color="#9E9E9E" />
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10 text-left'>
                        <div>
                            <h5 className='px-3' style={{ marginTop: '50px' }}>
                                PIN Confirmation
                            </h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <PinField
                                            ref={ref}
                                            className="ml-3  bg-transparent pin-field"
                                            type={showConfirmPasswordPIN ? 'text' : 'password'}
                                            style={{}}
                                            validate={/^[0-9]$/}
                                            onChange={(e) => setConfirmPin(e)}
                                            length={6}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 eyepin">
                                        {
                                            !showConfirmPasswordPIN ?
                                                <button className="btn px-0" onClick={() => setShowConfirmPasswordPIN(!showConfirmPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEye} style={{ fontSize: "20px" }} color="#9E9E9E" />
                                                </button>
                                                :
                                                <button className="btn px-0" onClick={() => setShowConfirmPasswordPIN(!showConfirmPasswordPIN)}>
                                                    <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize: "20px" }} color="#9E9E9E" />
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-left'>
                        <button 
                            className='btn btn-primary py-3 mt-5 bSavePin' 
                            // style={{ width: '65%' }} 
                            disabled={!pin || pin.length < 6 || !confirmpin || confirmpin.length < 6} 
                            onClick={() => savePin()}>
                            Save New PIN
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    account: storeState.authentication.account
});


const mapDispatchToProps = {
    // getSignature,
    logout
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(PinSet);
