import * as React from "react"
import { SVGProps } from "react"

const IconTeams = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_1668_7912)">
      <path d="M10.0433 4.79704C10.4925 4.7138 10.909 4.5051 11.2445 4.19508C11.5801 3.88507 11.821 3.4864 11.9395 3.04518C12.058 2.60396 12.0492 2.13822 11.9141 1.7018C11.7789 1.26539 11.523 0.876145 11.176 0.57907C10.8289 0.281996 10.4048 0.0892325 9.95279 0.0230715C9.50075 -0.0430896 9.03922 0.0200545 8.62157 0.205202C8.20392 0.390349 7.84721 0.689936 7.59268 1.06932C7.33816 1.4487 7.19623 1.89238 7.18329 2.34904H8.36129C9.29029 2.34904 10.0433 3.10204 10.0433 4.03104V4.79704ZM5.74829 12.535H8.36129C9.29029 12.535 10.0433 11.782 10.0433 10.853V5.58004H12.8263C13.0116 5.58476 13.1875 5.66279 13.3153 5.79702C13.4432 5.93125 13.5126 6.11072 13.5083 6.29604V10.59C13.5341 11.7019 13.1175 12.7785 12.3501 13.5834C11.5826 14.3884 10.5271 14.8558 9.41529 14.883C7.79729 14.843 6.41529 13.893 5.74829 12.533V12.535ZM16.4853 3.16304C16.4853 3.38294 16.442 3.60069 16.3578 3.80385C16.2737 4.00701 16.1503 4.1916 15.9948 4.34709C15.8393 4.50259 15.6548 4.62593 15.4516 4.71008C15.2484 4.79423 15.0307 4.83754 14.8108 4.83754C14.5909 4.83754 14.3731 4.79423 14.17 4.71008C13.9668 4.62593 13.7822 4.50259 13.6267 4.34709C13.4712 4.1916 13.3479 4.00701 13.2638 3.80385C13.1796 3.60069 13.1363 3.38294 13.1363 3.16304C13.1363 2.71894 13.3127 2.29302 13.6267 1.97899C13.9408 1.66496 14.3667 1.48854 14.8108 1.48854C15.2549 1.48854 15.6808 1.66496 15.9948 1.97899C16.3089 2.29302 16.4853 2.71894 16.4853 3.16304ZM14.2473 12.651C14.2073 12.651 14.1673 12.651 14.1273 12.649C14.3943 11.9923 14.524 11.2878 14.5083 10.579V6.30604C14.5124 6.05627 14.4612 5.80867 14.3583 5.58104H16.1503C16.5403 5.58104 16.8573 5.89804 16.8573 6.28804V10.053C16.8573 10.7421 16.5836 11.4029 16.0964 11.8901C15.6091 12.3773 14.9483 12.651 14.2593 12.651H14.2463H14.2473Z" fill="white" />
      <path d="M1.53918 3.34914H8.36118C8.73818 3.34914 9.04318 3.65414 9.04318 4.03114V10.8531C9.04318 11.034 8.97132 11.2075 8.84342 11.3354C8.71553 11.4633 8.54206 11.5351 8.36118 11.5351H1.53918C1.44962 11.5351 1.36093 11.5175 1.27819 11.4832C1.19544 11.449 1.12026 11.3987 1.05693 11.3354C0.993601 11.2721 0.943366 11.1969 0.909092 11.1141C0.874818 11.0314 0.857178 10.9427 0.857178 10.8531V4.03014C0.857178 3.65314 1.16218 3.34914 1.53918 3.34914ZM6.74518 5.94514V5.22514H3.15518V5.94514H4.51218V9.66014H5.38218V5.94514H6.74518Z" fill="white" />
    </g>
    {/* <defs>
      <clipPath id="clip0_1668_7912">
        <rect width="16" height="16" fill="white" transform="translate(0.857178)" />
      </clipPath>
    </defs> */}
  </svg>

)

export default IconTeams
