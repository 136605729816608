import * as React from "react"
import { SVGProps } from "react"

const RadioSelectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 2C17.524 2 22.002 6.478 22.002 12.002C22.002 17.525 17.524 22.003 12 22.003C6.47605 22.003 1.99805 17.525 1.99805 12.002C1.99805 6.478 6.47605 2 12 2ZM12 3.5C10.8759 3.48781 9.76041 3.6987 8.71827 4.12047C7.67612 4.54224 6.72798 5.16652 5.92871 5.95717C5.12944 6.74781 4.49492 7.68913 4.06187 8.72664C3.62882 9.76415 3.40584 10.8772 3.40584 12.0015C3.40584 13.1258 3.62882 14.2389 4.06187 15.2764C4.49492 16.3139 5.12944 17.2552 5.92871 18.0458C6.72798 18.8365 7.67612 19.4608 8.71827 19.8825C9.76041 20.3043 10.8759 20.5152 12 20.503C14.2321 20.4688 16.3611 19.5581 17.9274 17.9675C19.4937 16.377 20.3717 14.2343 20.3717 12.002C20.3717 9.76972 19.4937 7.62698 17.9274 6.03646C16.3611 4.44594 14.2321 3.53524 12 3.501V3.5ZM11.997 6C13.5878 6 15.1134 6.63193 16.2383 7.75677C17.3631 8.88162 17.995 10.4072 17.995 11.998C17.995 13.5888 17.3631 15.1144 16.2383 16.2392C15.1134 17.3641 13.5878 17.996 11.997 17.996C10.4063 17.996 8.88066 17.3641 7.75582 16.2392C6.63098 15.1144 5.99905 13.5888 5.99905 11.998C5.99905 10.4072 6.63098 8.88162 7.75582 7.75677C8.88066 6.63193 10.4063 6 11.997 6Z" fill="#F58220"/>
  </svg>
)

export default RadioSelectIcon
