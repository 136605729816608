import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './search.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Collapse } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import NoDataSearchReceived from 'app/component/no-data-search-received';
import NoDataSearchSent from 'app/component/no-data-search-sent';
import ChevronUpIcon from 'app/component/chevron-up-icon';
import ChevronDownIcon from 'app/component/chevron-down-icon';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import SearchReceivedIcon from 'app/component/search-received-icon';
import SearchSentIcon from 'app/component/search-sent-icon';
import SearchAllIcon from 'app/component/search-all-icon';
import ArrowFilterIdle from 'app/component/arrow-filter-idle';
import ArrowFilterTop from 'app/component/arrow-filter-top';
import ArrowFilterBottom from 'app/component/arrow-filter-bottom';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}


export const Search = (props: IHeaderProps) => {
    let history = useHistory();
    const [search, setSearch] = useState('')

    const [getSearchReceived, setSearchReceived] = useState([])
    const [loadingListReceived, setloadingListReceived] = useState(false)
    const [pageReceived, setPageReceived] = useState(1)
    const [totalPageReceived, setTotalPageReceived] = useState(1)
    const [sizeReceived, setSizeReceived] = useState<any>(10)
    const [sortNameReceived, setSortNameReceived] = useState('')
    const [sortNoReceived, setSortNoReceived] = useState('')
    const [sortLastApprovalReceived, setSortLastApprovalReceived] = useState('')
    const [sortDateReceived, setSortDateReceived] = useState('')

    const [getSearchSent, setSearchSent] = useState([])
    const [loadingListSent, setloadingListSent] = useState(false)
    const [pageSent, setPageSent] = useState(1)
    const [totalPageSent, setTotalPageSent] = useState(1)
    const [sizeSent, setSizeSent] = useState<any>(10)
    const [sortNameSent, setSortNameSent] = useState('')
    const [sortNoSent, setSortNoSent] = useState('')
    const [sortLastApprovalSent, setSortLastApprovalSent] = useState('')
    const [sortDateSent, setSortDateSent] = useState('')
    const { height, width } = useWindowDimensions()

    const [isSelectReceived, setSelectReceived] = useState(true)
    const [isSelectSent, setSelectSent] = useState(false)

    const [hoveredIndexReceived, setHoveredIndexReceived] = useState(-1)
    const [hoveredIndexSent, setHoveredIndexSent] = useState(-1)

    const [filterListName, setFilterListName] = useState(
        {
            nameAsc: "regarding.keyword,asc",
            nameDesc: "regarding.keyword,desc",
            idleFilter: true,
            topFilter: false,
            downFilter: false
        }
    )

    const [filterListNo, setFilterListNo] = useState(
        {
            nameAsc: "fileNumber.keyword,asc",
            nameDesc: "fileNumber.keyword,desc",
            idleFilter: true,
            topFilter: false,
            downFilter: false
        }
    )

    const [filterListApproval, setFilterListApproval] = useState(
        {
            nameAsc: "date,asc",
            nameDesc: "date,desc",
            idleFilter: true,
            topFilter: false,
            downFilter: false
        }
    )

    const [filterListDate, setFilterListDate] = useState(
        {
            nameAsc: "date,asc",
            nameDesc: "date,desc",
            idleFilter: true,
            topFilter: false,
            downFilter: false
        }
    )

    const SELECTED_RECEIVED = 1
    const SELECTED_SENT = 2

    const renderStatusFileHistories = (status, statusName) => {
        if (status == "CREATED" || status == "REVISE") {
            return <div style={{background: '#FFF4EB', border: '1px solid #F37F26',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 text-mirae w-50 text-center">{statusName}</div>
        } else if(status == 'APPROVE' || status == 'APPROVE_REVISED_VERSION' || status == 'WAITING_FOR_APPROVAL') {
            return <div style={{color: '#20573D', background: '#F7F7F7', border: '1px solid #B8DBCA',  borderRadius: '6px'}} className="px-2 py-1 font-weight-bold  mt-3 w-50 text-center">{statusName}</div>
        } else {
            return <div style={{color: '#CB3A31', background: '#FFF4F2', border: '1px solid #EEB4B0', borderRadius: '6px'}} className="px-2 py-1  font-weight-bold mt-3 w-50 text-center">{statusName}</div>
        }
    }

    useEffect(() => {
        /*const params = new URLSearchParams(window.location.search)
        const searchValue = params.get('q')
        setSearch(searchValue)*/
        getSearch(search)
    }, [
        props.location.state?.search,
        filterListName,
        sortNameReceived, pageReceived, sizeReceived, totalPageReceived,
        sortNameSent, pageSent, sizeSent, totalPageSent, isSelectSent, isSelectReceived
    ]);

    useEffect(() => {
        setDefault()
        const params = new URLSearchParams(window.location.search)
        const searchValue = params.get('q')
        setSearch(searchValue)
        delayTime(5000)
        getSearch(searchValue)
    }, []);

    const setDefault = () => {

        if(isSelectReceived) {
            setPageReceived(1)
            setSortNameReceived("")
            setSortNoReceived("")
            setSortLastApprovalReceived("")
            setSortDateReceived("")
        } else {
            setPageSent(1)
            setSortNameSent("")
            setSortNoSent("")
            setSortLastApprovalSent("")
            setSortDateSent("")
        }

        setFilterListName({
            ...filterListName,
            topFilter :  false,
            downFilter : false,
            idleFilter : true
        })

        setFilterListNo({
            ...filterListNo,
            topFilter :  false,
            downFilter : false,
            idleFilter : true
        })

        setFilterListApproval({
            ...filterListApproval,
            topFilter :  false,
            downFilter : false,
            idleFilter : true
        })

        setFilterListDate({
            ...filterListDate,
            topFilter :  false,
            downFilter : false,
            idleFilter : true
        })
    }


    const delayTime = (ms) => {
        return new Promise(
            resolve => setTimeout(resolve, ms)
        )
    }


    const getSearch = (e = '') => {
        // if(e.length > 1 ) {
        var url = ''
        if (isSelectReceived){
            var sortName = sortNameReceived == "" ? `` : `&sort=${sortNameReceived}`
            /*var sortNo = sortNoReceived == "" ? `` :`&sort=${sortNoReceived}`
            var sortLastApproval = sortLastApprovalReceived == "" ? `` :`&sort=${sortLastApprovalReceived}`
            var sortDate = sortDateReceived == "" ? `` :`&sort=${sortDateReceived}`*/
            setloadingListReceived(true)
            url = `services/documentservice/api/custom-search/documents?keyword=${e}&page=${pageReceived}&size=${sizeReceived}${sortName}`
        } else {
            var sortName = sortNameSent == "" ? `` : `&sort=${sortNameSent}`
            /*var sortNo = sortNoSent == "" ? `` :`&sort=${sortNoSent}`
            var sortLastApproval = sortLastApprovalSent == "" ? `` :`&sort=${sortLastApprovalSent}`
            var sortDate = sortDateSent == "" ? `` :`&sort=${sortDateSent}`*/
            setloadingListSent(true)
            url = `services/documentservice/api/custom-search/documents?keyword=${e}&page=${pageSent}&size=${sizeSent}${sortName}`
        }

        axios.get(url).then((result) => {
            if(isSelectReceived) {
                setloadingListReceived(false)
                setSearchReceived(result.data.received.content)
                setTotalPageReceived(result.data?.received.totalPages)
            } else {
                setloadingListSent(false)
                setSearchSent(result.data.sent.content)
                setTotalPageSent(result.data?.sent.totalPages)
            }
        }).catch(err => {
            if(isSelectReceived) {
                setSearchReceived([])
                setloadingListReceived(false)
            } else {
                setSearchSent([])
                setloadingListSent(false)
            }
        })
        // }
    }


    const changeSortNameReceived = () => {
        if(sortNameReceived == filterListName.nameAsc) {
            setSortNameReceived(filterListName.nameDesc)
        } else {
            setSortNameReceived(filterListName.nameAsc)
        }
        getSearch(search)
    }

    const changeSortNameSent = () => {
        if (sortNameSent == filterListName.nameAsc) {
            setSortNameSent(filterListName.nameDesc)
        } else {
            setSortNameSent(filterListName.nameAsc)
        }
        getSearch(search)
    }
    const changeSortNoReceived = () => {
        if(sortNoReceived == filterListNo.nameAsc) {
            setSortNoReceived(filterListNo.nameDesc)
        } else {
            setSortNoReceived(filterListNo.nameAsc)
        }
        getSearch(search)
    }

    const changeSortNoSent = () => {
        if (sortNoSent == filterListNo.nameAsc) {
            setSortNoSent(filterListNo.nameDesc)
        } else {
            setSortNoSent(filterListNo.nameAsc)
        }
        getSearch(search)
    }

    const changeSortLastApprovalReceived = () => {
        if(sortLastApprovalReceived == filterListApproval.nameAsc) {
            setSortLastApprovalReceived(filterListApproval.nameDesc)
        } else {
            setSortLastApprovalReceived(filterListApproval.nameAsc)
        }
        getSearch(search)
    }

    const changeSortLastApprovalSent = () => {
        if(sortLastApprovalSent == filterListApproval.nameAsc) {
            setSortLastApprovalSent(filterListApproval.nameDesc)
        } else {
            setSortLastApprovalSent(filterListApproval.nameAsc)
        }
        getSearch(search)
    }

    const changeSortDateReceived = () => {
        if(sortDateReceived == filterListDate.nameAsc) {
            setSortDateReceived(filterListDate.nameDesc)
        } else {
            setSortDateReceived(filterListDate.nameAsc)
        }
        getSearch(search)
    }

    const changeSortDateSent = () => {
        if(sortDateSent == filterListDate.nameAsc) {
            setSortDateSent(filterListDate.nameDesc)
        } else {
            setSortDateSent(filterListDate.nameAsc)
        }
        getSearch(search)
    }
    const goToPage = (id) => {
        setSearch('')
        history.push(`/preview/only/pdf/${id}`)
    }


    const handleChangePageReceived = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageReceived(value);
    };

    const handleChangePageSent = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageSent(value);
    };

    const handleFilterSearch = (selected) => {
        switch (selected){
            case SELECTED_RECEIVED: {
                setDefault()
                setSelectReceived(true)
                setSelectSent(false)
                break
            }
            case SELECTED_SENT: {
                setDefault()
                setSelectReceived(false)
                setSelectSent(true)
                break
            }
        }
    }

    return (
        <div className="px-5 pt-4" style={{height: '100%'}}>

            <div className="row">
                <div className="col-12">
                    <h1 className="page-title-box d-flex align-items-center justify-content-between mb-0 font-size-28 font-family-spoqa">Search</h1>
                    {/*<div className="page-title-box d-flex align-items-center justify-content-between">
                         <h1 className="mb-0 font-size-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                    </div>*/}
                </div>
            </div>

            <div className="row">
                <div className="app-search" style={{padding:"16px"}}>

                    <div className="position-relative">
                        <input style={{backgroundColor: "#FFFFFF"}}
                               name={"searchBar"}
                               type="text"
                               className="form-control"
                               value={search}
                               placeholder="Search Document ..."
                               autoComplete="off"
                               onKeyUp={(e) => {
                                   if (e.key === 'Enter') {
                                       setPageSent(1)
                                       setPageReceived(1)
                                       getSearch(search)
                                   }
                               }}
                               onChange={(e) => setSearch(e.target.value)} />
                        <span className="bx bx-search-alt"></span>
                    </div>

                </div>
            </div>

            <div style={{width:"306px", marginLeft: "2px", marginBottom:"6px"}} className={"row d-flex d-inline"}>

                <div onClick={() => handleFilterSearch(SELECTED_RECEIVED)} style={

                    isSelectReceived? {cursor:"pointer", color: "#FFF", backgroundColor:"#F58220", width:"144px", maxWidth:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"} :
                        {cursor:"pointer", color: "#000", backgroundColor:"#FFF", border:"1px solid #F58220", width:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"}

                } className="col d-flex align-items-center justify-content-center mr-2"><SearchReceivedIcon/><div className={"ml-2"}>Received</div></div>

                <div onClick={() => handleFilterSearch(SELECTED_SENT)} style={

                    isSelectSent? {cursor:"pointer", color: "#FFF", backgroundColor:"#F58220", width:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"} :
                        {cursor:"pointer", color: "#000", backgroundColor:"#FFF", border:"1px solid #F58220", width:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"}

                } className="col d-flex align-items-center justify-content-center mr-2"><SearchSentIcon/><div className={"ml-2"}>Sent</div></div>

                {/*<div onClick={() => handleFilterSearch(SELECTED_ALL)} style={
            isSelectAll? {cursor:"pointer", color: "#FFF", backgroundColor:"#F58220", width:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"} :
                {cursor:"pointer", color: "#000", backgroundColor:"#FFF", border:"1px solid #F58220", width:"144px", borderRadius: "8px", paddingTop:"6px", paddingBottom:"6px"}
        }
             className="col d-flex align-items-center justify-content-center"><SearchAllIcon/><div className='ml-2'>See all</div>
        </div>*/}

            </div>

            {/*<div className="col-lg-6 mb-3 font-size-16 border-bottom" style={{ color: '#0A0A0A', padding: '5px', backgroundColor: '#EDEDED' }} onClick={() => setOpenReceived(!isOpenReceived)}>
        <div style={{ marginLeft: '8px' }}>
          Received
        </div>
      </div>
      <div className="col-lg-6 mb-3 border-bottom" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '5px', backgroundColor: '#EDEDED' }} onClick={() => setOpenReceived(!isOpenReceived)}>
        <div style={{ marginRight: '8px' }}>
          {isOpenReceived ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>*/}

            {/*SECTION RECEIVED START*/}
            {isSelectReceived &&
                <div>
                    <div className="row mt-4 mb-2">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            {/*<div className="row d-none">
                    <div className="col-xl-2 col-lg-4 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold cursor-pointer" role="button" onClick={() => changeSortNameReceived()}>
                          Type File <span style={sortNameReceived == 'typeName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                  </div>
                  <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortLastApprovalReceived()}>
                          Modified By <span style={sortLastApprovalReceived == 'lastApprovedByName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                      </div>
                  <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortDateReceived()}>
                          Date <span style={sortDateReceived == 'lastApprovedDate,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                  </div>
              </div>*/}
                        </div>
                    </div>

                    {!loadingListReceived ?
                        <div>
                            <table style={{width:"100%"}}>
                                <thead style={{backgroundColor: "#043B72"}}>
                                <tr style={{color: "#FFF"}}>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"ml-1 p-2"}>
                                                {filterListName.idleFilter == true && <ArrowFilterIdle style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  true,
                                                        downFilter : false,
                                                        idleFilter : false
                                                    })
                                                    if(isSelectReceived) {
                                                        setSortNameReceived(filterListName.nameAsc)
                                                    } else {
                                                        setSortNameSent(filterListName.nameAsc)
                                                    }
                                                    // getSearch(search)
                                                }
                                                }/>}
                                                {filterListName.topFilter == true && <ArrowFilterTop style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  false,
                                                        downFilter : true,
                                                        idleFilter : false
                                                    })

                                                    if(isSelectReceived) {
                                                        setSortNameReceived(filterListName.nameDesc)
                                                    } else {
                                                        setSortNameSent(filterListName.nameDesc)
                                                    }

                                                    // getSearch(search)
                                                }}/>}
                                                {filterListName.downFilter == true &&<ArrowFilterBottom style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  false,
                                                        downFilter : false,
                                                        idleFilter : true
                                                    })

                                                    if(isSelectReceived) {
                                                        setSortNameReceived("")
                                                    } else {
                                                        setSortNameSent("")
                                                    }

                                                    // getSearch(search)
                                                }}/>}
                                            </div>
                                            <div className={"p-2"}>Regarding</div>
                                        </div>
                                    </th>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"p-2"}>Document Number</div>
                                        </div>
                                    </th>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"p-2"}>Date</div>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody style={{background:"#FFF"}}>
                                {
                                    getSearchReceived.map((item, index) => {
                                        return (
                                            <tr onMouseOver={() => {
                                                setHoveredIndexReceived(index)
                                            }} onMouseLeave={() => {
                                                setHoveredIndexReceived(-1)
                                            }} key={index} style={
                                                (hoveredIndexReceived == index) ?
                                                    {backgroundColor:"#FFF4EB", borderBottom:"1px solid #E0E0E0", cursor:"pointer"} :
                                                    {backgroundColor:"#FFF", borderBottom:"1px solid #E0E0E0", cursor:"pointer"}
                                            }
                                                onClick={() => goToPage(item.id)}
                                            >
                                                <td>
                                                    <div style={{padding:0}} className={"row"}>
                                                        <div style={
                                                            (hoveredIndexReceived == index) ?
                                                                {padding:0, maxWidth:"5px", backgroundColor:"#F58220", marginLeft:"11px"} :
                                                                {padding:0, maxWidth:"5px", backgroundColor:"#FFF", marginLeft:"11px"}
                                                        }></div>
                                                        <div className={"col"} style={{padding:"16px"}}>
                                                            {item.regarding ? item.regarding : "-"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.fileNumber ? item.fileNumber : "-"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.dateFormat}
                                                    </div>
                                                </td>
                                            </tr>

                                        )
                                    })

                                }
                                </tbody>

                                { getSearchReceived.length == 0 && !loadingListReceived &&
                                    <td colSpan={"4"}>
                                        <div className="my-4" style={{height: '400px', width:"100%"}}>
                                            <div>
                                                <div style={{textAlign: 'center'}}>
                                                    <NoDataSearchReceived className='my-5'/>
                                                    <h3>We couldn't find any results for '{search}'</h3>
                                                    <p style={{color: '#9E9E9E'}}>Check for spelling or try searching for another term</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                }

                            </table>
                        </div> :
                        <div style={{height: '280px'}}>
                            <div className="shimmerBG mt-2"></div>
                        </div>
                    }

                    {!loadingListReceived ?
                        <div className="row">
                            {getSearchReceived.length > 0 ? <> <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                                <div style={{display: 'flex'}}>
                  <span className="mt-3 mx-2">
                    Show
                  </span>
                                    <span>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>

                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={sizeReceived}
                    onChange={(event) => {
                        setSizeReceived(event.target.value);
                    }}
                    label="Limit"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
                  </span>
                                    <span className="mt-3 mx-2">
                    Entries
                  </span>
                                </div>
                            </div>

                                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
                                    <Pagination count={totalPageReceived} color="primary" page={pageReceived} showFirstButton showLastButton onChange={handleChangePageReceived}/>
                                </div> </> : <> </>}
                        </div> :
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <div style={{height: '30px'}} className="my-3">
                                        <div className="shimmerBG"></div>
                                    </div>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <div style={{height: '30px'}} className="my-3">
                                        <div className="shimmerBG"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>}
            {/*SECTION RECEIVED END*/}


            {/*<div className="col-lg-6 mb-3 font-size-16 border-bottom" style={{ color: '#0A0A0A', padding: '5px', backgroundColor: '#EDEDED' }} onClick={() => setOpenSent(!isOpenSent)}>
        <div style={{ marginLeft: '8px' }}>
          Sent
        </div>
      </div>
      <div className="col-lg-6 mb-3 border-bottom" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '5px', backgroundColor: '#EDEDED' }} onClick={() => setOpenSent(!isOpenSent)}>
        <div style={{ marginRight: '8px' }}>
          {isOpenSent ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>*/}

            {/*SECTION SENT START*/}
            {isSelectSent &&
                <div>
                    <div className="row mt-4 mb-2">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            {/*<div className="row d-none">
                    <div className="col-xl-2 col-lg-4 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold cursor-pointer" role="button" onClick={() => changeSortNameSent()}>
                          Type File <span style={sortNameSent == 'typeName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                  </div>
                  <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortLastApprovalSent()}>
                          Modified By <span style={sortLastApprovalSent == 'lastApprovedByName.keyword,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                      </div>
                  <div className="col-lg-2 col-sm-12 col-xs-12 my-2">
                      <div className="bg-white py-3 px-3 rounded font-weight-bold" role="button" onClick={() => changeSortDateSent()}>
                          Date <span style={sortDateSent == 'date,asc' ? {float: 'right'} : {float: 'right', transform: 'rotate(180deg)'}}><BottomSort/></span>
                      </div>
                  </div>
              </div>*/}
                        </div>
                    </div>
                    {!loadingListSent ?
                        <div>
                            <table style={{width:"100%"}}>
                                <thead style={{backgroundColor: "#043B72"}}>
                                <tr style={{color: "#FFF"}}>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"ml-1 p-2"}>
                                                {filterListName.idleFilter == true && <ArrowFilterIdle style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  true,
                                                        downFilter : false,
                                                        idleFilter : false
                                                    })
                                                    if(isSelectReceived) {
                                                        setSortNameReceived(filterListName.nameAsc)
                                                    } else {
                                                        setSortNameSent(filterListName.nameAsc)
                                                    }

                                                    getSearch(search)
                                                }
                                                }/>}
                                                {filterListName.topFilter == true && <ArrowFilterTop style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  false,
                                                        downFilter : true,
                                                        idleFilter : false
                                                    })

                                                    if(isSelectReceived) {
                                                        setSortNameReceived(filterListName.nameDesc)
                                                    } else {
                                                        setSortNameSent(filterListName.nameDesc)
                                                    }

                                                    getSearch(search)
                                                }}/>}
                                                {filterListName.downFilter == true &&<ArrowFilterBottom style={{cursor: "pointer"}} onClick={()=> {
                                                    setDefault()

                                                    setFilterListName({
                                                        ...filterListName,
                                                        topFilter :  false,
                                                        downFilter : false,
                                                        idleFilter : true
                                                    })

                                                    if(isSelectReceived) {
                                                        setSortNameReceived("")
                                                    } else {
                                                        setSortNameSent("")
                                                    }

                                                    getSearch(search)
                                                }}/>}
                                            </div>
                                            <div className={"p-2"}>Regarding</div>
                                        </div>
                                    </th>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"p-2"}>Document Number</div>
                                        </div>
                                    </th>
                                    <th style={{padding:"8px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"p-2"}>Date</div>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody style={{background:"#FFF"}}>
                                {
                                    getSearchSent.map((item, index) => {
                                        return (
                                            <tr onMouseOver={() => {
                                                setHoveredIndexSent(index)
                                            }} onMouseLeave={() => {
                                                setHoveredIndexSent(-1)
                                            }} key={index} style={
                                                (hoveredIndexSent == index) ?
                                                    {backgroundColor:"#FFF4EB", borderBottom:"1px solid #E0E0E0", cursor:"pointer"} :
                                                    {backgroundColor:"#FFF", borderBottom:"1px solid #E0E0E0", cursor:"pointer"}
                                            }
                                                onClick={() => goToPage(item.id)}
                                            >
                                                <td>
                                                    <div style={{padding:0}} className={"row"}>
                                                        <div style={
                                                            (hoveredIndexSent == index) ?
                                                                {padding:0, maxWidth:"5px", backgroundColor:"#F58220", marginLeft:"11px"} :
                                                                {padding:0, maxWidth:"5px", backgroundColor:"#FFF", marginLeft:"11px"}
                                                        }></div>
                                                        <div className={"col"} style={{padding:"16px"}}>
                                                            {item.regarding ? item.regarding : "-"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.fileNumber ? item.fileNumber : "-"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.dateFormat}
                                                    </div>
                                                </td>
                                            </tr>

                                        )
                                    })

                                }
                                </tbody>

                                { getSearchSent.length == 0 && !loadingListSent &&
                                    <td colSpan={"4"}>
                                        <div className="my-4" style={{height: '400px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                            <div>
                                                <div style={{textAlign: 'center'}}>
                                                    <NoDataSearchSent className='my-5'/>
                                                    <h3>We couldn't find any results for '{search}'</h3>
                                                    <p style={{color: '#9E9E9E'}}>Check for spelling or try searching for another term</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                }

                            </table>
                        </div> :
                        <div style={{height: '280px'}}>
                            <div className="shimmerBG mt-2"></div>
                        </div>
                    }

                    {!loadingListSent ?
                        <div className="row">
                            {getSearchSent.length > 0 ? <> <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                                <div style={{display: 'flex'}}>
                  <span className="mt-3 mx-2">
                    Show
                  </span>
                                    <span>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 50}}>

                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={sizeSent}
                    onChange={(event) => {
                        setSizeSent(event.target.value);
                    }}
                    label="Limit"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
                  </span>
                                    <span className="mt-3 mx-2">
                    Entries
                  </span>
                                </div>
                            </div>

                                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{display: 'flex', justifyContent: 'end'}}>
                                    <Pagination count={totalPageSent} color="primary" page={pageSent} showFirstButton showLastButton onChange={handleChangePageSent}/>
                                </div> </> : <> </>}
                        </div> :
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <div style={{height: '30px'}} className="my-3">
                                        <div className="shimmerBG"></div>
                                    </div>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <div style={{height: '30px'}} className="my-3">
                                        <div className="shimmerBG"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>}
            {/*SECTION SENT END*/}

        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Search);
