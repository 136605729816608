import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { IRootState } from 'app/shared/reducers';
import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import axios from 'axios';
import NotFoundImage from 'app/component/notfound';
import { Storage } from 'react-jhipster';
import LinearProgress from '@mui/material/LinearProgress';

export const NotFound = (props) => {

  let history: any = useHistory();
  let path = window.location.pathname

  useEffect(() => {
    getSession()
  }, []);

  const getSession = () => {
    // if (path) {
      // history.push(path)
      // window.location.reload()
    // } else {
      axios.get(`/services/uaadocservice/api/account`)
        .then((res) => {
        })
        .catch((err) => {
          setTimeout(() => {
            Storage.session.remove(`termandconditions`);
            props.history.replace('/')
          }, 1000);
        })
    // }
  }

  const loadingView = () => {
    return (
      <div id="root">
        <div className="centerUp">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img src="./content/images/logoloading2.png" style={{ objectFit: "contain", width: '60%' }} />
            <div style={{ height: 100, width: "80%" }}>
              <div className="centerLoadingBar meter">
                <LinearProgress color="warning" />
              </div>
            </div>
          </div>
        </div>
        <img src="./content/images/supportby.png" className="centerDown" style={{ objectFit: "contain", width: '40%' }} />
      </div>
    )
  }

  if (path) {
    return (loadingView())
  } else {
    return (
      <div className="font-family-spoqa" style={{ height: '90vh', width: '100%', verticalAlign: 'middle', alignContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column' }}>
        <div className="px-5 pt-4 text-center" >
          <NotFoundImage />
          <br></br>
        </div >
        <div className='my-3 text-center'>
          <h2 className='text-center' style={{ color: '#404040' }}>Oops, your session has been expired please login again</h2>
        </div>
      </div>
    );
  }
};

const mapStateToProps = ({ document }: IRootState) => ({
});
const mapDispatchToProps = {
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
