import * as React from "react"
import { SVGProps } from "react"

const IconSaveAsTemplate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M6.76578 4.83441C6.45336 4.52199 5.94683 4.52199 5.63441 4.83441C5.32199 5.14683 5.32199 5.65336 5.63441 5.96578L8.03441 8.36578C8.34683 8.6782 8.85336 8.6782 9.16578 8.36578L11.5658 5.96578C11.8782 5.65336 11.8782 5.14683 11.5658 4.83441C11.2534 4.52199 10.7468 4.52199 10.4344 4.83441L9.4001 5.86873L9.4001 3.0001H11.8001C12.6838 3.0001 13.4001 3.71644 13.4001 4.6001V8.6001C13.4001 9.48375 12.6838 10.2001 11.8001 10.2001H5.4001C4.51644 10.2001 3.8001 9.48375 3.8001 8.6001V4.6001C3.8001 3.71644 4.51644 3.0001 5.4001 3.0001H7.8001L7.8001 5.86873L6.76578 4.83441Z" fill="white" />
        <path d="M7.8001 1.4001C7.8001 0.95827 8.15827 0.600098 8.6001 0.600098C9.04192 0.600098 9.4001 0.95827 9.4001 1.4001L9.4001 3.0001H7.8001L7.8001 1.4001Z" fill="white" />
        <path d="M2.2001 6.2001C1.31644 6.2001 0.600098 6.91644 0.600098 7.8001V11.8001C0.600098 12.6838 1.31644 13.4001 2.2001 13.4001H8.6001C9.48375 13.4001 10.2001 12.6838 10.2001 11.8001H2.2001V6.2001Z" fill="white" />
    </svg>
)

export default IconSaveAsTemplate
