export const GET_SUMMARY_TOTAL = `/services/erraservicename1/api/v1/dashboard/summarytotal`
export const GET_SECTOR_PIE_CHART = `/services/erraservicename1/api/v1/dashboard/sectorpiechart`
export const GET_COMPANY_SECTOR = `/services/erraservicename1/api/v1/dashboard/companysector`
export const POST_TABLE_ANALYST = `/services/erraservicename1/api/v1/dashboard/analystrecommendation`
export const GET_LIST_SECTOR = `/services/erraservicename1/api/v1/stock/sectors`
export const GET_LIST_STOCK_BY_SECTOR = `/services/erraservicename1/api/v1/stock/stocksbysector`
export const GET_LIST_RESEARCHER = `/services/erraservicename1/api/v1/usersuaa`
export const GET_ANALYST_LIST = `/services/erraservicename1/api/v1/users`
export const GET_USER_DETAIL = `/services/erraservicename1/api/v1/user/detail`
export const GET_PROGRESS_ANALYST = `/services/erraservicename1/api/v1/dashboard/progressanalyst`
export const GET_OVERALL_RECOMMENDATION = `/services/erraservicename1/api/v1/dashboard/recommendation`
export const GET_GRAPH = `/services/erraservicename1/api/v1/dashboard/performancegraph`
export const PUT_UPLOAD_DRAFT = `/services/documentservice/api/dms-erra/draft`
export const PUT_SEND_DRAFT = `/services/documentservice/api/dms-erra/send`
export const GET_DETAIL_DRAFT = `/services/documentservice/api/documents-erra`
export const GET_ATTACHMENT_DRAFT = `/services/documentservice/api/dms/get_attachment_from_gcp`
