import * as React from "react"

const ExcelFileIcon = (props) => (
  <svg
    width={49}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.871 23.025 13.268 20.1v21.613a1.788 1.788 0 0 0 1.79 1.787h28.65a1.788 1.788 0 0 0 1.792-1.787V33.75L29.87 23.025Z"
      fill="#185C37"
    />
    <path
      d="M29.871 4.5H15.057a1.788 1.788 0 0 0-1.79 1.787v7.963L29.872 24l8.792 2.925L45.5 24v-9.75L29.87 4.5Z"
      fill="#21A366"
    />
    <path d="M13.268 14.25H29.87V24H13.268v-9.75Z" fill="#107C41" />
    <path
      opacity={0.1}
      d="M25.15 12.3H13.269v24.376H25.15a1.8 1.8 0 0 0 1.79-1.787V14.087a1.8 1.8 0 0 0-1.79-1.786Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M24.174 13.275H13.268V37.65h10.906a1.8 1.8 0 0 0 1.791-1.786V15.062a1.8 1.8 0 0 0-1.79-1.787Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M24.174 13.275H13.268V35.7h10.906a1.8 1.8 0 0 0 1.791-1.786V15.062a1.8 1.8 0 0 0-1.79-1.787Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M23.198 13.275h-9.93V35.7h9.93a1.8 1.8 0 0 0 1.79-1.786V15.062a1.8 1.8 0 0 0-1.79-1.787Z"
      fill="#000"
    />
    <path
      d="M5.291 13.275h17.907a1.79 1.79 0 0 1 1.791 1.787v17.877a1.79 1.79 0 0 1-1.791 1.786H5.291A1.788 1.788 0 0 1 3.5 32.94V15.062a1.789 1.789 0 0 1 1.791-1.787Z"
      fill="url(#a)"
    />
    <path
      d="m9.05 29.81 3.766-5.826-3.45-5.793h2.771l1.883 3.71c.174.35.3.612.357.786h.025c.123-.282.254-.554.39-.82l2.013-3.67h2.55l-3.539 5.76 3.629 5.858h-2.713l-2.175-4.067a3.54 3.54 0 0 1-.257-.547h-.036a2.527 2.527 0 0 1-.252.526l-2.24 4.083H9.05Z"
      fill="#fff"
    />
    <path
      d="M43.709 4.5H29.871v9.75H45.5V6.287A1.787 1.787 0 0 0 43.709 4.5Z"
      fill="#33C481"
    />
    <path d="M29.872 24H45.5v9.75H29.872V24Z" fill="#107C41" />
    <defs>
      <linearGradient
        id="a"
        x1={7.241}
        y1={11.871}
        x2={21.248}
        y2={36.129}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset={0.5} stopColor="#117E43" />
        <stop offset={1} stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </svg>
)

export default ExcelFileIcon
