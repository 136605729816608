import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import Resizer from "react-image-file-resizer";
import 'react-image-crop/dist/ReactCrop.css'
import SignatureIconWhite from 'app/component/signature-icon-white'
import Axios from 'axios'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getSignature, saveSignature, deleteSignature } from './../../entities/signature/signature.reducer'
import { IRootState } from 'app/shared/reducers'
import { Spinner } from 'reactstrap'
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const SignaturePageUpload = (props: IDocumentProps) =>  {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)
  const [urlImage, setUrlImage] = useState("")
  const [progress, setProgress] = useState(false)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if(!validateSize(e.target.files[0])){
      toast.error('Ukuran file Tidak Boleh Lebih Dari 2MB', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        e.target.value = null;
    } else {
      if (e.target.files && e.target.files.length > 0) {
        setCrop(undefined) // Makes crop preview update between images.
        const reader = new FileReader()
        reader.addEventListener('load', () =>
          setImgSrc(reader.result.toString() || ''),
        )
        reader.readAsDataURL(e.target.files[0])
      }
    }
 
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      return false
    } else {
      return true
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        // const croppedImageUrl = await this.getCroppedImg(
        //   this.imageRef,
        //   completedCrop,
        //   'newFile.jpeg'
        // );

        // setUrlImage(croppedImageUrl)

        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  

  const saveSignature = async () => {
    setProgress(true)
 
    let basse64 = previewCanvasRef.current.toDataURL()
    const image = await resizeFile(dataURLtoBlob(basse64));

    const formData = new FormData();
    formData.append("signatureImage", dataURLtoBlob(basse64));
    Axios.put('/services/uaadocservice/api/personal-identity/save-signature', formData)
    .then(res => {
       toast.success('Success Save Signature', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
       setProgress(false)
        props.history.push('/')
    }).catch(err => {
      toast.error('Failed Save Signature', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
        setProgress(false)
    })
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      100,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  
  return (
    <div className="App container">
      <div className="Crop-Controls">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Signature Upload</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">View Your Signature </h1>
          </div>
        </div>
      </div>
        <div className='card bg-white px-3 py-3'>
        <input type="file" accept="image/*" onChange={onSelectFile} />
        </div>
      </div>
      <div className="row">
        <div className="col card bg-white mt-3 px-2 py-3 mx-2">
            <p>
              Before : 
            </p>
            {Boolean(imgSrc) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
          
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div className='col card bg-white mt-3 px-2 py-3 mx-2'>
            <p>
              After : 
            </p>
            <div>
            {Boolean(completedCrop) && (
              <canvas
                id="canvasimage"
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            )}
            
          </div>
        </div>
      </div>

      {Boolean(completedCrop) && ( <button disabled={progress} className='btn btn-primary px-3' onClick={() =>saveSignature()}>{ progress != true ? <SignatureIconWhite className='mr-1' /> : <Spinner color='#fff' size="sm" className='mr-1'/> }Save Signature</button> )}


      
    </div>
  )
}
const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  // getSignature,
  saveSignature, 
  deleteSignature
};

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(SignaturePageUpload);
