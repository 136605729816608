import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Spinner } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'
import './document-update.scss';
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDocumentDetail,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getDeptList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft
}
  from './document.reducer';
import { IDocument, IBranchCode, IApproval } from 'app/shared/model/document.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import documentPreview from '../preview/document-preview';
import { Editor } from '@tinymce/tinymce-react';
import { MultiSelect } from "react-multi-select-component"
import ArrowRightIcon from 'app/component/arrow-right-icon';
import ArrowRightIconWhite from 'app/component/arrow-right-icon-white';
import SignatureIcon from 'app/component/signature-icon';
import SignatureWhiteCreateFile from 'app/component/signature-white-create-file';
import SaveDraftIcon from 'app/component/save-draft-icon';
import { stringify } from 'querystring';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { toast } from 'react-toastify';
import InfoIcon from 'app/component/InfoIcon';
import './document-update.scss';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import UploadFileIcon from 'app/component/upload-icon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Item } from 'react-bootstrap/lib/Pagination';
import { type } from 'os';
import Axios from 'axios';
import { b64toBlob, bytesToSize, replaceP, validateSize, validateUrl } from './utils';
import { DocumentForm } from './releaseapproval-type';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import approval from '../approval/approval';
import {
  typeDocument,
  priorityDocument,
  typeApplication,
} from './releaseapproval-dataMock';

import {
  // apiGetDetailDocument,
  // apiGetListAttachement,
  // apiGetListGroupNo,
  // apiGetDepartmentList,
  // apiGenerateDocumentNumberByGroup,
  // apiDeleteFileUpload,
  // apiValidasiURL,
  // apiGetDataSendTo,
  apiGetAllLevelOrganization,
  apiGetGroup,
  apiGetApprovers,
  apiGetAllLevelOrganizationScreener,
  // apiGetOrganizationScreener,
  // apiGetApproversScreener,
  // apiSaveAsDraft,
  // apiNextStep,
  // apiGetApprovals,
  // apiGetAcknowledge,
} from 'app/config/api-collection';
import {UploadConstants} from "app/shared/util/upload.constants";

export interface Props {
  getDocumentDetail?: any,
  detaildocument?: any,
  location: {
    state?: {
      file: any,
      data: any,
      link: any
    }
  }
};

type typeProcat = {
  value: string;
  label: string;
};

type typeListApp = {
  value: string;
  label: string;
};

type releaseChecklist = {
  value: string;
  label: string;
};

type membersGroup = {
  value: string;
  label: string;
};

export interface IDocumentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const ReleaseApprovalCreate = (props: any, editMode = true) => {

  const tooltipMessage = {
    tooltipQa: "The person responsible for ensuring the application can work properly.",
    tooltipItSec: "The person responsible for ensuring data and application security.",
    tooltipUiUx: "Lorem Ipsum",
    tooltipProdOwn: "Lorem Ipsum",
    tooltipHoD: "Lorem Ipsum",
  }

  const initialFormData = {
    id: "0",
    type: "RELEASE_APPROVAL",
    priority: "",
    projectCategory: "",
    typeApplication: "",
    application: "",
    releaseChecklist: [],
    qualityAssurance: [],
    // uiUx: [],
    // itSecurity: [],
    // productOwner: [],
    // headOfDivision: [],
    date: new Date(),
    expDate: new Date(),
    regarding: "",
    notes: "",
    fileContent: "",
    groupId: "",
    documentNumber: "",
    documentCode: "",
    isMerge:"false"
  }

  const initialDocumentNo = {
    documentCode: null,
    documentNumber: null,
    groupId: null
  }

  const editorRef = useRef(null);
  const [dataUser, setDataUser] = useState<any>("");
  const [formData, setFormData] = useState(initialFormData)
  const [formDocumentNo, setFormDocumentNo] = useState<DocumentForm>(initialDocumentNo)
  const [projectCategory, setProjectCategory] = useState<typeProcat[]>([]);
  const [listApplication, setListApplication] = useState<typeListApp[]>([]);
  const [releaseChecklist, setReleaseChecklist] = useState<releaseChecklist[]>([]);
  const [groupListDocument, setGroupListDocument] = useState<any[]>([]);
  const [groupMembers, setGroupMembers] = useState<membersGroup[]>([]);
  const [checked, setChecked] = useState([]);
  const [initialValue, setInitialValue] = useState(undefined);
  const [fileStatus, setFileStatus] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [listFile, setListFile] = useState([])
  const [listAttachment, setListAttachments] = useState([])
  const [listProductOwner, setListProductOwner] = useState([])
  const [loadingSaveAsDraft, setLoadingSaveAsDraft] = useState(false)
  const [loadingNextStep, setLoadingNextStep] = useState(false)
  const [loadingLink, setLoadingLink] = useState(false)
  const [loadingDraft, setLoadingDraft] = useState(false)
  const [loadingDraftFile, setLoadingDraftFile] = useState(false)
  const [dataLevOrgByGroup, setDataLevOrgByGroup] = useState<any>([])
  const [QAField, setQAField] = useState([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupQA, setDataSelectGroupQA] = useState<any>([])
  const [dataApproversQA, setDataApproversQA] = useState<any>([])
  const [ItSecField, setItSecField] = useState<any>([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupItSec, setDataSelectGroupItSec] = useState<any>([])
  const [dataApproversItSec, setDataApproversItSec] = useState<any>([])
  const [UiUxField, setUiUxField] = useState<any>([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupUiUx, setDataSelectGroupUiUx] = useState<any>([])
  const [dataApproversUiUx, setDataApproversUiUx] = useState<any>([])
  const [HodField, setHodField] = useState<any>([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupHod, setDataSelectGroupHod] = useState<any>([])
  const [dataApproversHod, setDataApproversHod] = useState<any>([])

  const [dataLevOrgProdOwn, setDataLevOrgProdOwn] = useState<any>([])
  const [ProdOwnField, setProdOwnField] = useState<any>([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupProdOwn, setDataSelectGroupProdOwn] = useState<any>([])
  const [dataApproversProdOwn, setDataApproversProdOwn] = useState<any>([])

  const [dataLevOrgViewer, setDataLevOrgViewer] = useState<any>([])
  const [viewerField, setViewerField] = useState<any>([{ "levelOrganization": 0, "groupId": 0, "approvers": "" }])
  const [dataSelectGroupViewer, setDataSelectGroupViewer] = useState<any>([])
  const [dataApproversViewer, setDataApproversViewer] = useState<any>([])


  const apiSaveAsDraft = `/services/documentservice/api/dms/save_as_draft`;
  const apiNextStep = `/services/documentservice/api/documents/get-data-for-preview/new`;

  useEffect(() => {
    console.log("1 a")
    getDataUser()
    getProjectCategory()
    getListApplication()
    getReleaseChecklist()
    getDocumentGroup()
    getLevOrgProdOwn()
    getLevOrgViewer()
    if (props.match.params.id) {
      console.log("1 b", props)
      getListAttachment()
      getDetailDocument()
    } else if (props.location.state) {
      if (props.location.state.from == "home") {
        let currentTime = new Date().getTime();
        let updatedTIme = new Date(currentTime + 2 * 60 * 60 * 1000);
        setFormData({...formData, expDate: updatedTIme});
      } else {
        console.log("1 c")
        getLevOrgByGroup()
        setListFile(props.location.state.file)
        setListAttachments(props.location.state.link)
        let data = JSON.parse(props.location.state.data)
        let qaParse = JSON.parse(props.location.state.qaParse)
        setQAField(qaParse)
        qaParse.map((data, i) => {
          getOrganizationQA(data.levelOrganization)
          getApproversQA(data.groupId)
        })
        let itSecParse = JSON.parse(props.location.state.itSecParse)
        setItSecField(itSecParse)
        itSecParse.map((data, i) => {
          getOrganizationItSec(data.levelOrganization)
          getApproversItSec(data.groupId)
        })
        let uiUxParse = JSON.parse(props.location.state.uiUxParse)
        setUiUxField(uiUxParse)
        uiUxParse.map((data, i) => {
          getOrganizationUiUx(data.levelOrganization)
          getApproversUiUx(data.groupId)
        })
        let hodParse = JSON.parse(props.location.state.hodParse)
        setHodField(hodParse)
        hodParse.map((data, i) => {
          getOrganizationHod(data.levelOrganization)
          getApproversHod(data.groupId)
        })
        let prodOwnParse = JSON.parse(props.location.state.prodOwnParse)
        setProdOwnField(prodOwnParse)
        prodOwnParse.map((data, i) => {
          getOrganizationProdOwn(data.levelOrganization)
          getApproversProdOwn(data.groupId)
        })
        let viewerParse = JSON.parse(props.location.state.viewerParse)
        setViewerField(viewerParse)
        viewerParse.map((data, i) => {
          getOrganizationViewer(data.levelOrganization)
          getApproversViewer(data.groupId)
        })
        setTimeout(() => {
          setFormData({
            ...formData,
            type: data.type,
            priority: data.priority,
            projectCategory: data.projectCategory,
            typeApplication: data.typeApplication,
            application: data.application,
            regarding: data.regarding,
            notes: data.notes,
            fileContent: data.fileContent,
            date: new Date(),
            expDate: subtractHours(-7, new Date(data.expDate)),
            releaseChecklist: data.releaseChecklist,
            isMerge: "false"
          })
          setChecked(data.releaseChecklist)
          setInitialValue(data.fileContent)
          generateDocumentNumberByGroup(data.groupId)
          getMembersGroup(data.groupId)
        }, 1000)
      }
    }

    return () => {
      // second
    }
  }, [])

  const getDataUser = () => {
    axios.get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data.employeeNo)
      }).catch((err) => {
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  // function //
  const handleInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value.value
    })
  }

  const handleInputDocumentNo = (name, value: any) => {
    setFormDocumentNo({
      ...formDocumentNo,
      [name]: value
    })
  }

  const handleUpdate = (newValue, editor) => {
    setFormData({
      ...formData,
      fileContent: newValue
    })
  };

  var isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setFormData({
      ...formData,
      releaseChecklist: updatedList
    })
  };

  const getListApplication = () => {
    axios.get('services/documentservice/api/parameter/get-list-application').then(res => {
      if (res.data.length > 0) {
        res.data.map((data, i) => {
          if (data.isActive == true) {
            setListApplication(prevListApp => [...prevListApp, { value: data.parameterCode, label: data.parameterName }]);
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getDocumentGroup = () => {
    axios.get('/services/uaadocservice/api/group/get-list-document-code').then(res => {
      setGroupListDocument(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  const generateDocumentNumberByGroup = (id) => {
    axios.get(`/services/documentservice/api/dms/generateDocumentNumber/new?groupId=${id}&type=${formData.type}`).then(res => {
      setFormDocumentNo({
        groupId: id,
        documentNumber: res.data.data.documentNumber,
        documentCode: res.data.data.documentCode
      })
    }).catch(err => {
      console.log(err)
    })
  }

  const getMembersGroup = (id) => {
    axios.get(`/services/uaadocservice/api/users/get-members-of-group?groupId=${id}`).then(res => {
      if (res.data.length > 0) {
        res.data.map((data, i) => {
          setGroupMembers(prev => [...prev, { value: data.employee_no, label: data.full_name }]);
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getListAttachment = () => {
    setLoadingDraftFile(true)
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`)
      .then(result => {
        setLoadingDraftFile(false)
        setListFile(result.data?.data.content)
      }).then(err => {
        setLoadingDraftFile(false)
      })
  }

  const getDetailDocument = () => {
    setLoadingDraft(true)
    axios.get(`/services/documentservice/api/documents/${props.match.params.id}`)
      .then(result => {
        console.log("getDetailDocument", result)
        handleEditByStatus(result.data.data)
        setLoadingDraft(false)
      }).catch(err => {
        console.log("getDetailDocument")
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const getProjectCategory = () => {
    axios.get('services/documentservice/api/parameter/get-list-project-category').then(res => {
      if (res.data.length > 0) {
        res.data.map((data, i) => {
          if (data.isActive == true) {
            setProjectCategory(prevProcat => [...prevProcat, { value: data.parameterCode, label: data.parameterName }]);
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getReleaseChecklist = () => {
    axios.get('services/documentservice/api/parameter/get-list-release-checklist').then(res => {
      if (res.data.length > 0) {
        res.data.map((data, i) => {
          if (data.isActive == true) {
            setReleaseChecklist(prevListApp => [...prevListApp, { value: data.parameterCode, label: data.parameterName }]);
          }
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() + numOfHours);
    return date;
  }

  const handleEditByStatus = (data) => {
    setFileStatus(data.fileStatus)
    if (data.fileStatus == 'DRAFT' || data.fileStatus == 'RECALLED' || data.fileStatus == 'REJECTED' || data.fileStatus == 'REJECTED_BY_REJECTOR') {
      if (data.fileNumber) {
        getLevOrgByGroup()
      }
      let qaRespon = [];
      data.qualityAssurance.map((item) => {
        getOrganizationQA(item.levelOrganizationId)
        getApproversQA(item.branchCode)
        qaRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let itSecRespon = [];
      data.itSecurity.map((item) => {
        getOrganizationItSec(item.levelOrganizationId)
        getApproversItSec(item.branchCode)
        itSecRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let uiUxRespon = [];
      if (data.uiUx.length == 0) {
        uiUxRespon.push({ "levelOrganization": 0, "groupId": 0, "approvers": "" })
      } else {
        data.uiUx.map((item) => {
          getOrganizationUiUx(item.levelOrganizationId)
          getApproversUiUx(item.branchCode)
          uiUxRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
        })
      }
      let prodOwnRespon = [];
      data.productOwner.map((item) => {
        getOrganizationProdOwn(item.levelOrganizationId)
        getApproversProdOwn(item.branchCode)
        prodOwnRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let hodRespon = [];
      data.headOfDivision.map((item) => {
        getOrganizationHod(item.levelOrganizationId)
        getApproversHod(item.branchCode)
        hodRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let viewerRespon = [];
      if (data.viewer.length == 0) {
        uiUxRespon.push({ "levelOrganization": 0, "groupId": 0, "approvers": "" })
      } else {
        data.viewer.map((item) => {
          getOrganizationViewer(item.levelOrganizationId)
          getApproversViewer(item.branchCode)
          viewerRespon.push({levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo})
        })
      }
      let attachments = []
      data.fileAttachment.map((item) => {
        attachments.push({ id: item.attachmentId, value: item.attachmentName })
      })
      setFormData({
        ...formData,
        id: data.id,
        type: data.type,
        priority: data.priority,
        projectCategory: data.projectCategory,
        typeApplication: data.typeApplication,
        application: data.application,
        // qualityAssurance: data.qualityAssurance,
        // itSecurity: data.itSecurity,
        // uiUx: data.uiUx,
        // productOwner: data.productOwner,
        // headOfDivision: data.headOfDivision,
        regarding: data.regarding,
        notes: data.notes,
        fileContent: data.fileContent,
        date: (data.fileStatus == 'RECALLED' || data.fileStatus == 'REJECTED' || data.fileStatus == 'REJECTED_BY_REJECTOR') ? subtractHours(-7, new Date(data.date)) : new Date(),
        expDate: subtractHours(-7, new Date(data.expDate)),
        releaseChecklist: data.releaseChecklist,
        isMerge: "false"
      })
      setFormDocumentNo({
        documentNumber: data.documentNumber,
        documentCode: data.documentCode,
        groupId: data.groupId
      })
      setQAField(qaRespon)
      setItSecField(itSecRespon)
      setUiUxField(uiUxRespon)
      setProdOwnField(prodOwnRespon)
      setHodField(hodRespon)
      setViewerField(viewerRespon)
      setChecked(data.releaseChecklist);
      setInitialValue(data.fileContent);
      getMembersGroup(data.groupId);
    } else {
      if (data.fileNumber) {
        getLevOrgByGroup()
      }
      let qaRespon = [];
      data.qualityAssurance.map((item) => {
        getOrganizationQA(item.levelOrganizationId)
        getApproversQA(item.branchCode)
        qaRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let itSecRespon = [];
      data.itSecurity.map((item) => {
        getOrganizationItSec(item.levelOrganizationId)
        getApproversItSec(item.branchCode)
        itSecRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let uiUxRespon = [];
      if (data.uiUx.length == 0) {
        uiUxRespon.push({ "levelOrganization": 0, "groupId": 0, "approvers": "" })
      } else {
        data.uiUx.map((item) => {
          getOrganizationUiUx(item.levelOrganizationId)
          getApproversUiUx(item.branchCode)
          uiUxRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
        })
      }
      let prodOwnRespon = [];
      data.productOwner.map((item) => {
        getOrganizationProdOwn(item.levelOrganizationId)
        getApproversProdOwn(item.branchCode)
        prodOwnRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let hodRespon = [];
      data.headOfDivision.map((item) => {
        getOrganizationHod(item.levelOrganizationId)
        getApproversHod(item.branchCode)
        hodRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let viewerRespon = [];
      data.viewer.map((item) => {
        getOrganizationViewer(item.levelOrganizationId)
        getApproversViewer(item.branchCode)
        viewerRespon.push({ levelOrganization: item.levelOrganizationId, groupId: item.branchCode, approvers: item.employeeNo })
      })
      let attachments = [];
      data.fileAttachment.map((item) => {
        attachments.push({ id: item.attachmentId, value: item.attachmentName })
      })
      setFormData({
        ...formData,
        id: data.id,
        type: data.type,
        priority: data.priority,
        projectCategory: data.projectCategory,
        typeApplication: data.typeApplication,
        application: data.application,
        // qualityAssurance: data.qualityAssurance,
        // itSecurity: data.itSecurity,
        // uiUx: data.uiUx,
        // productOwner: data.productOwner,
        // headOfDivision: data.headOfDivision,
        regarding: data.regarding,
        notes: data.notes,
        fileContent: data.fileContent,
        date: new Date(),
        expDate: subtractHours(-7, new Date(data.expDate)),
        releaseChecklist: data.releaseChecklist,
        isMerge: "false"
      })
      setFormDocumentNo({
        documentNumber: data.documentNumber,
        documentCode: data.documentCode,
        groupId: data.groupId
      })
      setQAField(qaRespon)
      setItSecField(itSecRespon)
      setUiUxField(uiUxRespon)
      setProdOwnField(prodOwnRespon)
      setHodField(hodRespon)
      setViewerField(viewerRespon)
      setChecked(data.releaseChecklist);
      setInitialValue(data.fileContent);
      getMembersGroup(data.groupId);
    }
  }

  const validAttachement = () => {
    if (formData.releaseChecklist.length < 1) {
      toast.error(`Release Checklist cannot be empty.`);
    } else if (listFile.length < 1) {
      if (listAttachment.length < 1) {
        toast.error(`Attachement cannot be empty.`);
      }
      if (listAttachment.length > 0) {
        NextStep()
      }
    } else {
      NextStep()
    }
  }

  const modifyData = (array, branchName, approversName) => {
    let data = []
    array.map((item) => {
      data.push({ [branchName]: !item.groupId ? 0 : item.groupId, [approversName]: item.approvers })
    })
    return data
  }

  const lastObjectVal = (valObject) => {
    let dataVal = []
    valObject.map((item) => {
      if (item.levelOrganization != 0 && item.groupId != 0 && item.approvers != '') {
        dataVal.push(item)
      }
    })
    return dataVal
  }

  const saveAsDraft = () => {
    setLoadingSaveAsDraft(true)
    const dataFieldQa = lastObjectVal(QAField)
    const dataFieldItSec = lastObjectVal(ItSecField)
    const dataFieldUiUx = lastObjectVal(UiUxField)
    const dataFieldProdOwn = lastObjectVal(ProdOwnField)
    const dataFieldHod = lastObjectVal(HodField)
    const dataFieldViewer = lastObjectVal(viewerField)
    const formDataFinal = new FormData();
    let dataFormData: DocumentForm = formData
    dataFormData = {
      ...dataFormData,
      date: subtractHours(7, formData.date),
      expDate: subtractHours(7, formData.expDate),
      groupId: formDocumentNo.groupId,
      documentNumber: formDocumentNo.documentNumber,
      documentCode: formDocumentNo.documentCode,
      to: [{ "branchCode": formDocumentNo.groupId }],
      qualityAssurance: modifyData(dataFieldQa, 'branchCode', 'employeeNo'),
      itSecurity: modifyData(dataFieldItSec, 'branchCode', 'employeeNo'),
      uiUx: modifyData(dataFieldUiUx, 'branchCode', 'employeeNo'),
      productOwner: modifyData(dataFieldProdOwn, 'branchCode', 'employeeNo'),
      headOfDivision: modifyData(dataFieldHod, 'branchCode', 'employeeNo'),
      viewer: modifyData(dataFieldViewer, 'branchCode', 'employeeNo'),
      isMerge: "false"
    }
    formDataFinal.append("data", JSON.stringify(dataFormData));
    listFile.map((item, index) => {
      if (!item.fileAttachmentId) {
        formDataFinal.append('fileAttachment', dataURLtoFile(item.file, item.fileName))
      }
    })
    // link gdrive
    listAttachment.map((data, i) => {
      formDataFinal.append("fileAttachmentLinks", data.value);
    })
    axios({
      method: "put",
      url: `${apiSaveAsDraft}`,
      data: formDataFinal,
    })
      .then(function (response) {
        setLoadingSaveAsDraft(false)
        /*if(props.history?.location?.state?.from == "draft"){
          props.history.push("/draft")
        } else {
          props.history.push("/?tab=6")
        }*/
        if(props.history?.location?.state?.from == "home"){
          props.history.push("/draft")
        } else {
          props.history.goBack()
        }
      })
      .catch(function (err) {
        dataFormData = {
          ...dataFormData,
          date: subtractHours(-7, formData.date),
          expDate: subtractHours(-7, formData.expDate),
        }
        toast.error(translate(`${err.response.data.message}`));
        setLoadingSaveAsDraft(false)
      });
  }

  const NextStep = () => {
    setLoadingNextStep(true)
    const dataFieldQa = lastObjectVal(QAField)
    const dataFieldItSec = lastObjectVal(ItSecField)
    const dataFieldUiUx = lastObjectVal(UiUxField)
    const dataFieldProdOwn = lastObjectVal(ProdOwnField)
    const dataFieldHod = lastObjectVal(HodField)
    const dataFieldViewer = lastObjectVal(viewerField)

    let dataFormData: DocumentForm = formData
    dataFormData = {
      ...dataFormData,
      date: subtractHours(7, formData.date),
      expDate: subtractHours(7, formData.expDate),
      fileContent: replaceP(dataFormData.fileContent),
      groupId: formDocumentNo.groupId,
      documentNumber: formDocumentNo.documentNumber,
      documentCode: formDocumentNo.documentCode,
      to: [{ "branchCode": formDocumentNo.groupId }],
      qualityAssurance: modifyData(dataFieldQa, 'branchCode', 'employeeNo'),
      itSecurity: modifyData(dataFieldItSec, 'branchCode', 'employeeNo'),
      uiUx: modifyData(dataFieldUiUx, 'branchCode', 'employeeNo'),
      productOwner: modifyData(dataFieldProdOwn, 'branchCode', 'employeeNo'),
      headOfDivision: modifyData(dataFieldHod, 'branchCode', 'employeeNo'),
      viewer: modifyData(dataFieldViewer, 'branchCode', 'employeeNo'),
      isMerge: "false"
    }
    axios.post(`${apiNextStep}`, dataFormData)
      .then(res => {
        setLoadingNextStep(false)
        props.history.push('/preview/document/pdf', {
          data: JSON.stringify(dataFormData),
          file: JSON.stringify(listFile),
          link: JSON.stringify(listAttachment),
          qaParse: JSON.stringify(QAField),
          itSecParse: JSON.stringify(ItSecField),
          uiUxParse: JSON.stringify(UiUxField),
          hodParse: JSON.stringify(HodField),
          prodOwnParse: JSON.stringify(ProdOwnField),
          viewerParse: JSON.stringify(viewerField)
        })
      }).catch(err => {
        dataFormData = {
          ...dataFormData,
          date: subtractHours(-7, formData.date),
          expDate: subtractHours(-7, formData.expDate),
          fileContent: replaceP(dataFormData.fileContent),
          groupId: formDocumentNo.groupId,
          documentNumber: formDocumentNo.documentNumber,
          documentCode: formDocumentNo.documentCode
        }
        toast.error(translate(`${err.response.data.message}`));
        setLoadingNextStep(false)
      })
  }

  /* File attachement */
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl)
  }

  const deleteFile = (key) => {
    let arr = listFile
    var filteredArray = arr.filter(function (e, i) { return i !== key })
    setListFile(filteredArray)
  }

  const deleteFileUpload = (fileAttachmentId) => {
    axios.delete(`/services/documentservice/api/dms/delete-attachment?fileAttachmentId=${fileAttachmentId}&fileId=${props.match.params.id}`).then(result => {
      let arr = listFile
      var filteredArray = arr.filter(function (e, i) { return e.fileAttachmentId !== fileAttachmentId })
      setListFile(filteredArray)
      toast.success('Berhasil Menghapus File', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      toast.error('Gagal Menghapus File', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const isValidFileUploaded = (file) => {
    if (file.type == "") {
      return true
    } else {

      const validExtensions = ['png', 'jpg', 'jpeg', 'docx', 'xlsx', 'pdf', 'csv', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.openxmlformats-officedocument.wordprocessingml.document']
      // const validExtensions = ['pdf']
      const fileExtension = file.type.split('/')[1]
      return validExtensions.includes(fileExtension)
    }

  }

  const handleFileInput = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    let filesSelect = e.target.files
    Array.from(filesSelect).forEach(async (item: any) => {



      if (!isValidFileUploaded) {
        toast.error('Tipe file tidak diperbolehkan', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //file is valid
        if (!validateSize(item)) {
          toast.error('Ukuran file Tidak Boleh Lebih Dari 20MB', {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let data = {
            fileAttachmentId: null,
            fileName: item.name,
            file: await toBase64(item),
            fileContentType: item.type,
            fileSize: item.size
          }
          setListFile(oldArray => [...oldArray, data]);
        }
      }
    });
  }
  /* End File attachement */

  const deleteLink = (key) => {
    let arr = listAttachment
    var filteredArray = arr.filter(function (e, i) { return e.id !== key })
    setListAttachments(filteredArray)
  }

  const checkValidasiURL = (value) => {
    const formData = new FormData();
    formData.append("fileAttachmentLinks", value);
    axios.post('services/documentservice/api/dms/validasi_file_attachment', formData).then(res => {
      setListAttachments([...listAttachment, { id: uuidv4(), value: value }])
      setInputValue("")
      setLoadingLink(false)
    }).catch(err => {
      setLoadingLink(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const _handleKeyDown = (e) => {
    setLoadingLink(true)
    if (validateUrl(inputValue)) {
      checkValidasiURL(inputValue)
    } else {
      setLoadingLink(false)
      toast.error('Link yang dimasukkan kurang tepat', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /* approval */
  const getLevOrgByGroup = () => {
    axios.get(`${apiGetAllLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newLevel = response.map((obj, i) => ({ label: obj.levelOrganizationName, value: obj.id }));
        setDataLevOrgByGroup(newLevel)
      }).catch((err) => {
        console.log("getLevOrgByGroup")
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  /* qa */
  const getOrganizationQA = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
        const newArray = [...dataSelectGroupQA];
        newArray.splice(0, 1, newOrganization);
        setDataSelectGroupQA(newArray);
      }).catch((err) => {
        console.log("getOrganizationQA")
        toast.error(translate(`${err.response.data.message}`));
      })
  }

  const getApproversQA = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
      .then((res) => {
        const response = res.data
        const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
        const newArrayApprovers = [...dataApproversQA];
        newArrayApprovers.splice(0, 1, newApprovers);
        let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
          return obj.value !== dataUser;
        });
        setDataApproversQA([myArrayFirst]);
      }).catch((err) => {
        throw new Error('getApproversQA')
      })
  }

  const handleSelectQA = async (type, e) => {
    let data = [...QAField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationQA(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversQA(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setQAField(data)
  }

  /* it security */
  const getOrganizationItSec = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
        const newArray = [...dataSelectGroupItSec];
        newArray.splice(0, 1, newOrganization);
        setDataSelectGroupItSec(newArray);
      }).catch((err) => {
        throw new Error('getOrganizationItSec')
      })
  }

  const getApproversItSec = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
      .then((res) => {
        const response = res.data
        const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
        const newArrayApprovers = [...dataApproversItSec];
        newArrayApprovers.splice(0, 1, newApprovers);
        let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
          return obj.value !== dataUser;
        });
        setDataApproversItSec([myArrayFirst]);
      }).catch((err) => {
        throw new Error('getApproversItSec')
      })
  }

  const handleSelectItSec = async (type, e) => {
    let data = [...ItSecField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationItSec(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversItSec(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setItSecField(data)
  }

  /* UI/UX */
  const getOrganizationUiUx = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
        const newArray = [...dataSelectGroupUiUx];
        newArray.splice(0, 1, newOrganization);
        setDataSelectGroupUiUx(newArray);
      }).catch((err) => {
        throw new Error('getOrganizationUiUx')
      })
  }

  const getApproversUiUx = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
      .then((res) => {
        const response = res.data
        const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
        const newArrayApprovers = [...dataApproversUiUx];
        newArrayApprovers.splice(0, 1, newApprovers);
        let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
          return obj.value !== dataUser;
        });
        setDataApproversUiUx([myArrayFirst]);
      }).catch((err) => {
        throw new Error('getApproversUiUx')
      })
  }

  const handleSelectUiUx = async (type, e) => {
    let data = [...UiUxField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationUiUx(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversUiUx(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setUiUxField(data)
  }

  /* Head of Division */
  const getOrganizationHod = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
        const newArray = [...dataSelectGroupHod];
        newArray.splice(0, 1, newOrganization);
        setDataSelectGroupHod(newArray);
      }).catch((err) => {
        throw new Error('getOrganizationHod')
      })
  }

  const getApproversHod = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
      .then((res) => {
        const response = res.data
        const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
        const newArrayApprovers = [...dataApproversHod];
        newArrayApprovers.splice(0, 1, newApprovers);
        let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
          return obj.value !== dataUser;
        });
        setDataApproversHod([myArrayFirst]);
      }).catch((err) => {
        throw new Error('getApproversHod')
      })
  }

  const handleSelectHod = async (type, e) => {
    let data = [...HodField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationHod(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversHod(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setHodField(data)
  }

  /* Product Owner */
  const getLevOrgProdOwn = () => {
    axios.get(`${apiGetAllLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newLevel = response.map((obj, i) => ({ label: obj.levelOrganizationName, value: obj.id }));
        setDataLevOrgProdOwn(newLevel)
      }).catch((err) => {
        throw new Error('getLevOrgProdOwn')
      })
  }

  const getOrganizationProdOwn = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
      .then((res) => {
        const response = res.data
        const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
        const newArray = [...dataSelectGroupProdOwn];
        newArray.splice(0, 1, newOrganization);
        setDataSelectGroupProdOwn(newArray);
      }).catch((err) => {
        throw new Error('getOrganizationProdOwn')
      })
  }

  const getApproversProdOwn = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
      .then((res) => {
        const response = res.data
        const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
        const newArrayApprovers = [...dataApproversProdOwn];
        newArrayApprovers.splice(0, 1, newApprovers);
        let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
          return obj.value !== dataUser;
        });
        setDataApproversProdOwn([myArrayFirst]);
      }).catch((err) => {
        throw new Error('getApproversProdOwn')
      })
  }

  const handleSelectProdOwn = async (type, e) => {
    let data = [...ProdOwnField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationProdOwn(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversProdOwn(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setProdOwnField(data)
  }

  /* Viewer */
  const getLevOrgViewer = () => {
    axios.get(`${apiGetAllLevelOrganization}`)
        .then((res) => {
          const response = res.data
          const newLevel = response.map((obj, i) => ({ label: obj.levelOrganizationName, value: obj.id }));
          setDataLevOrgViewer(newLevel)
        }).catch((err) => {
      throw new Error('getLevOrgViewer')
    })
  }

  const getOrganizationViewer = (valLevelOrganization) => {
    axios.get(`${apiGetGroup}${valLevelOrganization}`)
        .then((res) => {
          const response = res.data
          const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
          const newArray = [...dataSelectGroupViewer];
          newArray.splice(0, 1, newOrganization);
          setDataSelectGroupViewer(newArray);
        }).catch((err) => {
      throw new Error('getOrganizationViewer')
    })
  }

  const getApproversViewer = (valGroup) => {
    axios.get(`/services/uaadocservice/api/group-authority/get-members-by-group-id?groupId=${valGroup}`)
        .then((res) => {
          const response = res.data
          const newApprovers = response.map((obj, index) => ({ label: obj.fullName, value: obj.employeeNo }));
          const newArrayApprovers = [...dataApproversViewer];
          newArrayApprovers.splice(0, 1, newApprovers);
          let myArrayFirst = newArrayApprovers[0].filter(function (obj) {
            return obj.value !== dataUser;
          });
          setDataApproversViewer([myArrayFirst]);
        }).catch((err) => {
      throw new Error('getApproversViewer')
    })
  }

  const handleSelectViewer = async (type, e) => {
    let data = [...viewerField];
    if (type == 'levelOrganization') {
      data[0].levelOrganization = e.value;
      data[0].groupId = 0;
      data[0].approvers = "";
      getOrganizationViewer(e.value)
    }
    if (type == 'groupId') {
      data[0].groupId = e.value;
      data[0].approvers = "";
      getApproversViewer(e.value)
    }
    if (type == 'approvers') {
      data[0].approvers = e.value;
    }
    setViewerField(data)
  }

  /* end approval */

  // end function

  return (
    <div className="container font-family-spoqa">
      <Row className="">
        <Col md="8">
          <h2 id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.label">Create or edit a Document</Translate>
          </h2>
          <p id="documenttestApp.document.home.createOrEditLabel">
            <Translate contentKey="create.description">Create or edit a Document</Translate>
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="row mt-3 mb-4">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Type
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <Select
                        isLoading={loadingDraft}
                        name="group"
                        value={typeDocument.filter((function (option: any) {
                          return option.value
                        }))}
                        isDisabled={true}
                        placeholder="Select Type Document"
                        options={typeDocument}
                        onChange={(e) => handleInput("type", e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Priority
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <Select
                        isLoading={loadingDraft}
                        name="group"
                        value={priorityDocument.filter((function (option: any) {
                          return option.value == formData.priority
                        }))}
                        isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                        placeholder="Select Priority Document"
                        options={priorityDocument}
                        onChange={(e) => handleInput("priority", e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        No File
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <Select
                        isLoading={loadingDraft}
                        name="group"
                        value={groupListDocument.filter((function (option: any) {
                          return option.id == formDocumentNo.groupId
                        }))}
                        placeholder="Select Document Group"
                        options={groupListDocument}
                        onChange={(e) => {
                          setGroupMembers([])
                          generateDocumentNumberByGroup(e.id)
                          getMembersGroup(e.id)
                          getLevOrgByGroup()
                        }}
                        isDisabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'}
                        getOptionLabel={(option: any) => option.groupName}
                        getOptionValue={(option: any) => option.id}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3">
                      <input className='form-control' disabled={fileStatus == 'APPROVED_BY_REJECTOR' || fileStatus == 'REJECTED'} type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputDocumentNo('documentNumber', parseInt(e.target.value) < 1 ? 1 : e.target.value)} value={formDocumentNo.documentNumber} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-7">
                      <input disabled={true} className='form-control' value={formDocumentNo.documentCode} />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Project Category
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <Select
                        isLoading={loadingDraft}
                        name="group"
                        value={projectCategory.filter((function (option: any) {
                          return option.value == formData.projectCategory
                        }))}
                        isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                        placeholder="Select Project Category"
                        options={projectCategory}
                        onChange={(e) => handleInput("projectCategory", e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                </div>
                <div className="col-5">
                  {/* <span style={{ fontSize: 10 }}>The document number will only be saved after you send your document</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="row mt-3 mb-4">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Date
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <DatePicker
                        value={formData.date}
                        disabled={true}
                        minDate={new Date()}
                        selected={formData.date}
                        dateFormat="d MMMM yyyy - h:mm aa"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                        Exp Date
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <DatePicker
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        // dateFormat="MMMM d, yyyy h:mm aa"
                        dateFormat="d MMMM yyyy - h:mm aa"
                        minDate={new Date()}
                        selected={formData.expDate}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            expDate: e
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Application Type
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      <Select
                        isLoading={loadingDraft}
                        name="group"
                        value={typeApplication.filter((function (option: any) {
                          return option.value == formData.typeApplication
                        }))}
                        isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                        placeholder="Select Type Application"
                        options={typeApplication}
                        onChange={(e) => handleInput("typeApplication", e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-2" style={{ alignSelf: 'center' }}>
                      <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                        Application Name
                        <span className="required" style={{ color: '#ff0000' }}>*</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10">
                      {
                        formData.typeApplication == ""
                          ?
                          <input className="form-control" disabled={true} />
                          :
                          (
                            formData.typeApplication == "1" ?
                              <input className="form-control" value={formData.application} onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  application: e.target.value
                                })}
                                disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                              />
                              :

                              <Select
                                isLoading={loadingDraft}
                                name="group"
                                value={listApplication.filter((function (option: any) {
                                  return option.value == formData.application
                                }))}
                                isDisabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                                placeholder="Select Name Application"
                                options={listApplication}
                                onChange={(e) => handleInput("application", e)}
                              />
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="mt-3 mb-4">
                <div className='font-weight-bold mb-3' style={{ display: 'flex' }}>
                  Project Description
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
                <textarea className='form-control' rows={2} style={{ resize: 'none' }} value={formData.regarding}
                  disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      setFormData({
                        ...formData,
                        regarding: e.target.value
                      })
                    } else {
                      setFormData({
                        ...formData,
                        regarding: e.target.value.slice(0, 250)
                      })
                    }
                  }} />
              </div>
            </div>
          </div>
          <div id="editorStyle" className="card mb-12">
            <div className="card-body">
              <div className="mt-3 mb-4">
                <div className='font-weight-bold' style={{ display: 'flex' }}>
                  Project Summary
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <Editor
                apiKey={process.env.REACT_APP_TYMCE_APIKEY}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={initialValue}
                value={formData.fileContent}
                onEditorChange={handleUpdate}
                // onSubmit={handleEditorSubmit}
                // onSubmit={saveEntity}
                // disabled={disabledDocument}
                disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                init={{
                  height: 800,
                  menubar: true,
                  paste_data_images: true,
                  // paste_block_drop: true,
                  forced_root_block_attrs: {
                    'style': 'font-size: 10pt;'
                  },
                  forced_root_block: 'div',
                  force_br_newlines: true,
                  force_p_newlines: false,
                  force_div_newlines: false,
                  table_row_advtab: true,
                  table_cell_advtab: true,
                  formats: {
                    bold: { inline: 'b' },
                    italic: { inline: 'i' },
                    underline: { inline: 'u' }
                  },
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor ',
                    'searchreplace visualblocks fullscreen ',
                    'insertdatetime media table paste code help wordcount hr ',
                    'anchor autolink charmap codesample ',
                    'emoticons image link lists media table visualblocks ',
                    'wordcount ',
                    'autosave tablesplitcells tablemergecells tablecellborderstyle ',
                    'tablecellprops tablerowprops'
                  ],
                  toolbar: 'undo redo | fontsizeselect |  formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      ' help hr' +
                      'blocks fontfamily fontsize | underline strikethrough | link image media table mergetags | ' +
                      'align lineheight | checklist numlist | ' +
                      'emoticons charmap | removeformat | tablesplitcells tablemergecells tablecellborderstyle tablecellprops tablerowprops',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }',
                  table_border_styles: [
                    {title: 'Solid', value: 'solid'},
                    {title: 'Dotted', value: 'dotted'},
                    {title: 'Dashed', value: 'dashed'},
                    {title: 'Double', value: 'double'},
                    {title: 'Groove', value: 'groove'},
                    {title: 'Ridge', value: 'ridge'},
                    {title: 'Inset', value: 'inset'},
                    {title: 'Outset', value: 'outset'},
                    {title: 'None', value: 'none'},
                    {title: 'Hidden', value: 'hidden'}
                  ]
                }}
              />
            </div>
          </div>
          <div className="card  mb-4">
            <div className="card-body">
              <div className="row mt-3 mb-4">
                <div className="mt-2 mb-4">
                  <div className='font-weight-bold' style={{ display: 'flex' }}>
                    Release Checklist
                    <span className="required" style={{ color: '#ff0000' }}>*</span>
                  </div>
                </div>
                {releaseChecklist.map((item, index) => {
                  return (
                    <div className="col-3" key={index}>
                      <div className="row mt-1 mb-3">
                        <div className="col-12 col-sm-12 col-md-1" style={{ display: "flex", alignSelf: "center" }}>
                          <input
                            type="checkbox"
                            value={item.value}
                            checked={checked.includes(item.value) == true ? true : false}
                            onChange={handleCheck}
                            disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-10" style={{ alignSelf: 'center' }}>
                          <div className='font-weight' style={{ display: 'flex', alignSelf: 'center' }}>
                            <span className={isChecked(item.value)}>{item.label}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="mt-3 mb-4">
                <div className='font-weight-bold mb-3' style={{ display: 'flex' }}>
                  Notes
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
                <textarea className='form-control' rows={2} style={{ resize: 'none' }} value={formData.notes}
                  disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                  onChange={(e) => {
                    if (e.target.value.length <= 250) {
                      setFormData({
                        ...formData,
                        notes: e.target.value
                      })
                    } else {
                      setFormData({
                        ...formData,
                        notes: e.target.value.slice(0, 250)
                      })
                    }
                  }} />
              </div>
            </div>
          </div>
          <div className="card  mb-4">
            <div className="card-body">
              <div className="row mt-3 mb-4">
                <div className="mt-2 mb-4">
                  <div className='font-weight-bold' style={{ display: 'flex' }}>
                    Approval
                  </div>
                </div>
                {QAField.map((QAElement, index) => {
                  return (
                    <div className='row mb-1' key={index}>
                      <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                        <div className='font-weight-bold'>
                          QA
                          <span className="required" style={{ color: '#ff0000' }}>*</span>
                          <Tooltip title={tooltipMessage.tooltipQa} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevOrgByGroup.filter((function (option: any) {
                            return option.value == QAElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevOrgByGroup}
                          onChange={(e) => handleSelectQA('levelOrganization', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataSelectGroupQA[0] == undefined ? null : dataSelectGroupQA[0]?.filter((function (option: any) {
                            return option.value == QAElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataSelectGroupQA[0]}
                          onChange={(e) => handleSelectQA('groupId', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversQA[0] == undefined ? null : dataApproversQA[0]?.filter((function (option: any) {
                            return option.value == QAElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversQA[0]}
                          onChange={(e) => handleSelectQA('approvers', e)}
                        />
                      </div>
                    </div>
                  )
                })}
                {ItSecField.map((ItSecElement, index) => {
                  return (
                    <div className='row mb-1' key={index}>
                      <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                        <div className='font-weight-bold'>
                          IT Security
                          <span className="required" style={{ color: '#ff0000' }}>*</span>
                          <Tooltip title={tooltipMessage.tooltipItSec} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevOrgByGroup.filter((function (option: any) {
                            return option.value == ItSecElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevOrgByGroup}
                          onChange={(e) => handleSelectItSec('levelOrganization', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataSelectGroupItSec[0] == undefined ? null : dataSelectGroupItSec[0]?.filter((function (option: any) {
                            return option.value == ItSecElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataSelectGroupItSec[0]}
                          onChange={(e) => handleSelectItSec('groupId', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversItSec[0] == undefined ? null : dataApproversItSec[0]?.filter((function (option: any) {
                            return option.value == ItSecElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversItSec[0]}
                          onChange={(e) => handleSelectItSec('approvers', e)}
                        />
                      </div>
                    </div>
                  )
                })}
                {UiUxField.map((UiUxElement, index) => {
                  return (
                    <div className='row mb-1' key={index}>
                      <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                        <div className='font-weight-bold'>
                          UI/UX
                          <Tooltip title={tooltipMessage.tooltipUiUx} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevOrgByGroup.filter((function (option: any) {
                            return option.value == UiUxElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevOrgByGroup}
                          onChange={(e) => handleSelectUiUx('levelOrganization', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataSelectGroupUiUx[0] == undefined ? null : dataSelectGroupUiUx[0]?.filter((function (option: any) {
                            return option.value == UiUxElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataSelectGroupUiUx[0]}
                          onChange={(e) => handleSelectUiUx('groupId', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversUiUx[0] == undefined ? null : dataApproversUiUx[0]?.filter((function (option: any) {
                            return option.value == UiUxElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversUiUx[0]}
                          onChange={(e) => handleSelectUiUx('approvers', e)}
                        />
                      </div>
                    </div>
                  )
                })}
                <div className='row mb-1'>
                  <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                    <div className='font-weight-bold'>
                      Product Owner
                      <span className="required" style={{ color: '#ff0000' }}>*</span>
                      <Tooltip title={"Lorem Ipsum Dolor Amet"} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                    </div>
                  </div>
                  <div className='col-3'>
                    <Select
                      name="group"
                      value={dataLevOrgProdOwn.filter((function (option: any) {
                        return option.value == ProdOwnField[0].levelOrganization
                      }))}
                      placeholder="Level Organization"
                      options={dataLevOrgProdOwn}
                      onChange={(e) => handleSelectProdOwn('levelOrganization', e)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      name="group"
                      value={dataSelectGroupProdOwn[0] == undefined ? null : dataSelectGroupProdOwn[0]?.filter((function (option: any) {
                        return option.value == ProdOwnField[0].groupId
                      }))}
                      placeholder="Select Group"
                      options={dataSelectGroupProdOwn[0]}
                      onChange={(e) => handleSelectProdOwn('groupId', e)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                      name="group"
                      value={dataApproversProdOwn[0] == undefined ? null : dataApproversProdOwn[0]?.filter((function (option: any) {
                        return option.value == ProdOwnField[0].approvers
                      }))}
                      placeholder="Select Approvers"
                      options={dataApproversProdOwn[0]}
                      onChange={(e) => handleSelectProdOwn('approvers', e)}
                    />
                  </div>
                </div>
                {HodField.map((HodElement, index) => {
                  return (
                    <div className='row mb-1' key={index}>
                      <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                        <div className='font-weight-bold'>
                          Head of Division
                          <span className="required" style={{ color: '#ff0000' }}>*</span>
                          <Tooltip title={tooltipMessage.tooltipHoD} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                        </div>
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataLevOrgByGroup.filter((function (option: any) {
                            return option.value == HodElement.levelOrganization
                          }))}
                          placeholder="Level Organization"
                          options={dataLevOrgByGroup}
                          onChange={(e) => handleSelectHod('levelOrganization', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataSelectGroupHod[0] == undefined ? null : dataSelectGroupHod[0]?.filter((function (option: any) {
                            return option.value == HodElement.groupId
                          }))}
                          placeholder="Select Group"
                          options={dataSelectGroupHod[0]}
                          onChange={(e) => handleSelectHod('groupId', e)}
                        />
                      </div>
                      <div className='col-3'>
                        <Select
                          name="group"
                          value={dataApproversHod[0] == undefined ? null : dataApproversHod[0]?.filter((function (option: any) {
                            return option.value == HodElement.approvers
                          }))}
                          placeholder="Select Approvers"
                          options={dataApproversHod[0]}
                          onChange={(e) => handleSelectHod('approvers', e)}
                        />
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>
          </div>

          <div className="card  mb-4">
            <div className="card-body">
              <div className="row mt-3 mb-4">
                <div className="mt-2 mb-4">
                  <div className='font-weight-bold' style={{ display: 'flex' }}>
                    Inform
                  </div>
                </div>
                <div className='row mb-1'>
                  <div className='col-2' style={{ display: "flex", alignItems: "center", maxWidth: "15%", marginRight: "8px" }}>
                    <div className='font-weight-bold'>
                      DevOps
                      <span className="required" style={{ color: '#ff0000' }}>*</span>
                      <Tooltip title={"Lorem Ipsum Dolor Amet"} placement="right"><span className='mx-1'><InfoIcon /></span></Tooltip>
                    </div>
                  </div>
                  <div className='col-3'>
                    <Select
                        name="group"
                        value={dataLevOrgViewer.filter((function (option: any) {
                          return option.value == viewerField[0].levelOrganization
                        }))}
                        placeholder="Level Organization"
                        options={dataLevOrgViewer}
                        onChange={(e) => handleSelectViewer('levelOrganization', e)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                        name="group"
                        value={dataSelectGroupViewer[0] == undefined ? null : dataSelectGroupViewer[0]?.filter((function (option: any) {
                          return option.value == viewerField[0].groupId
                        }))}
                        placeholder="Select Group"
                        options={dataSelectGroupViewer[0]}
                        onChange={(e) => handleSelectViewer('groupId', e)}
                    />
                  </div>
                  <div className='col-3'>
                    <Select
                        name="group"
                        value={dataApproversViewer[0] == undefined ? null : dataApproversViewer[0]?.filter((function (option: any) {
                          return option.value == viewerField[0].approvers
                        }))}
                        placeholder="Select Approvers"
                        options={dataApproversViewer[0]}
                        onChange={(e) => handleSelectViewer('approvers', e)}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Attachment
              {formData.releaseChecklist.length > 0 ? <span className="required" style={{ color: '#ff0000' }}>*</span> : ''}
            </h5>
            <br></br>
            <div>
              File
            </div>
            {listFile.length > 0 ?
              listFile.map((item, index) => {
                return (<>
                  <div key={`t-${index}`}>
                    <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                          {item.fileName} ( {bytesToSize(item.fileSize)})
                        </span>
                        {
                          item.fileAttachmentId ? <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteFileUpload(item.fileAttachmentId)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span> : <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteFile(index)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </>
                )
              })
              :
              (loadingDraftFile == true ?
                <div className="text-center my-4">
                  <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color='warning' />
                  </Box>
                </div>
                :
                <>
                  <div className="text-center my-4">
                    Anda Belum Memilih File
                  </div>
                </>
              )
            }
            {/* <div>
              Link
            </div>
            {
              listAttachment.length > 0 ? listAttachment.map((data, index) => {
                return (
                  <div key={`s-${index}`}>
                    <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {data.value}
                        </span>
                        {
                          data.id ? <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span> : <span className="px-2 py-2 mx-2" style={{ cursor: 'pointer' }} onClick={() => deleteLink(data.id)}>
                            <FontAwesomeIcon icon="trash" color="red" />
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                )
              }) :
                <div className="text-center my-4">
                  Anda Belum Memasukkan Link
                </div>
            } */}
          </div>
          <div className="bg-white rounded shadow my-4 px-4 py-4">
            <div className="row">
              <div className="col-12 col-lg-2 col-xl-2">
                <h5>
                  File
                </h5>
              </div>
              <div className="col-12 col-lg-10 col-xl-10">
                <span className="btn btn-primary btn-file">
                  <UploadFileIcon className="mx-1" /> Upload File
                  <input
                    type="file"
                    disabled={fileStatus == 'APPROVED_BY_REJECTOR'}
                    onChange={handleFileInput}
                    multiple
                  />
                </span>
                <div className="my-2">
                  <span style={{ color: '#757575' }}>Upload .png/.jpg/.jpeg/.docx/.xlsx/.pdf/ .csv file only, with max size 20MB.</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bg-white rounded shadow my-4 px-4 py-4">
            <h5>
              Link
            </h5>
            <div className="row mt-3">
              <div className="col-12 col-lg-2 my-2">
                Insert Google Drive Link
              </div>
              <div className="col-lg-6 col-12 col-sm-12 col-xs-12">
                <input className="form-control" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <div className="my-2">
                  <span style={{ color: '#757575' }}>{UploadConstants}</span>
                </div>
              </div>
              <div className="col-lg-4 col-12 col-sm-12 col-xs-12">
                <button className="btn btn-primary" onClick={(e) => _handleKeyDown(e)} disabled={loadingLink}>
                  {loadingLink ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}Add Link
                </button>
              </div>
            </div>
          </div> */}
          <div className="float-right">
            <Button onClick={() => saveAsDraft()} id="cancel-save2" type="submit" className='mr-2' disabled={loadingSaveAsDraft || loadingNextStep} color="primary">
              &nbsp;
              <span className="d-md-inline">
                {loadingSaveAsDraft ? <Spinner color='#fff' size="sm" className='mr-1' /> : <SaveDraftIcon className='mr-1' />}
                Save As Draft
              </span>
            </Button>
            &nbsp;
            <Button onClick={() => validAttachement()} name="next" color="primary" id="save-entity" type="submit" className='pb-2' disabled={loadingSaveAsDraft || loadingNextStep}>
              <span className='mr-1'>
                {loadingNextStep ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                Next</span>
              <ArrowRightIconWhite />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  documentEntity: storeState.document.entity,
  loading: storeState.document.loading,
  loadingDepartement: storeState.document.loadingdepartement,
  loadingDetail: storeState.document.loadingdetail,
  updating: storeState.document.updating,
  updateSuccess: storeState.document.updateSuccess,
  deptList: storeState.document.deptList,
  detaildocument: storeState.document.detaildocument,
  userList: storeState.document.userList,
  approvalList: storeState.document.approvalList,
  acknowledgeList: storeState.document.acknowledgeList,
  documentListCode: storeState.document.documentListCode,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getDeptList,
  getUserList,
  getApprovalList,
  getAcknowledgeList,
  getListDocumentCode,
  sendNewDocument,
  saveDocumentToDraft,
  documentPreview,
  getDocumentDetail
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseApprovalCreate);



