import * as React from "react"
import { SVGProps } from "react"

const ToRejectedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M13.0715 20.0003V10.5717C13.0715 10.1171 13.2521 9.68102 13.5736 9.35952C13.8951 9.03803 14.3312 8.85742 14.7858 8.85742H23.3572L31.9287 17.4289V29.4289C31.9287 29.8835 31.7481 30.3195 31.4266 30.641C31.1051 30.9625 30.669 31.1431 30.2144 31.1431H22.5001" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.3574 8.85742V17.4288H31.9288" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.9116 23.875L9.64307 31.1436" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.64307 23.875L16.9116 31.1436" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToRejectedIcon
