import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SignaturePage from './signature';
import SignaturePageAdd from './signatureadd';
import profile from './profile';
import { PinChange } from './pin';
import { PinSet } from './setPin';
import SignaturePageUpload from './signatureupload';
import { ResetPin } from './reset-pin';
import {ChangePassword} from "app/modules/changepassword/changepassword";
import {ChangePasswordProfile} from "app/modules/profile/change-password-profile";

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.url}/me`} component={profile} />
      <ErrorBoundaryRoute path={`${match.url}/signature/view`} component={SignaturePage} />
      <ErrorBoundaryRoute path={`${match.url}/signature/add`} component={SignaturePageAdd} />
      <ErrorBoundaryRoute path={`${match.url}/signature/upload`} component={SignaturePageUpload} />
      <ErrorBoundaryRoute path={`${match.url}/pin/change`} component={PinChange} />
      <ErrorBoundaryRoute path={`${match.url}/pin/set`} component={PinSet} />
      <ErrorBoundaryRoute path={`${match.url}/reset-pin`} component={ResetPin} />
      <ErrorBoundaryRoute path={`${match.url}/password/change`} component={ChangePasswordProfile} />
    </Switch>
    {/* <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DocumentDeleteDialog} /> */}
  </>
);

export default Routes;
