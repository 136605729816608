import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconTeams from "app/component/icon-teams";
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTeams } from '@fortawesome/free-solid-svg-icons';

import { faCopy } from '@fortawesome/free-solid-svg-icons';

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useParams, useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';
import { Modal, Button, Form, FormGroup, ModalBody, ModalFooter } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import CandidateList from './CandidateList';
import './TrackingProcess.scss';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import FormControl from '@mui/material/FormControl';
import { start } from 'repl';
import { baseUrlAPI } from './configCareer';
// @ts-ignore
import Accordion from 'react-bootstrap/Accordion'
import moment from 'moment';

import { ModalHeader } from "reactstrap";

// import { Email } from 'react-email';
// import id from 'date-fns/locale/id';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}
export interface IParams {
    key: any | null,
}

export const CandidateDetail = (props: IHeaderProps) => {
    const initialFormData = {
        listStatus: null,
        selectStatus: null,
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("full_name")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListOfJobs, setDataListOfJobs] = useState<any>([])
    const [dataDetailofJob, setDataDetailofJob] = useState<any>([])

    const [dataProfile, setDataProfile] = useState<any>([])
    const [dataJobCandidate, setDataJobCandidate] = useState<any>([])
    const [dataWorkExperience, setDataWorkExperience] = useState<any>([])
    const [dataEducation, setDataEducation] = useState<any>([])
    const [dataCapitalMarketLicense, setDataCapitalMarketLicense] = useState<any>([])
    const [dataTraining, setDataTraining] = useState<any>([])
    const [dataVerificationQuestion, setDataVerificationQuestion] = useState<any>([])
    
    const [dataApplicationCandidate, setDataApplicationCandidate] = useState<any>([])

    const [dataListofCandidates, setDataListofCandidates] = useState<any>([])
    const [dataListofStatus, setDataListofStatus] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
    const [dataInvestmentMagement, setDataInvestmentMagement] = useState<any>([])
    const [dataOrganization, setDataOrganization] = useState<any>([])
    const [selectedStartDate, setSelectedStartDate] = useState(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState(new Date())

    const [dataApplicant, setDataApplicant] = useState([]);
    const [flowProcess, setFlowProcess] = useState([]);
    // const currentRoute = window.location.pathname.split('/').pop();

    // const parameter = props.location.state.key as IParams
    const { id, id2 } = useParams();
    const confirmTnC = false;

    const token = localStorage.getItem('access_token');

    interface ConfirmTnc {
        confirm_tnc: boolean;
    }

    const [dataConfirmTnc, setDataConfirmTnc] = useState<ConfirmTnc | null>(null);

    useEffect(() => {
        getProfile()
        getCandidateDetail()
        getWorkExperience()
        getEducation()
        getCapitalMarketLicense()
        getTraining()
        getVerificationQuestion()
        getTrackingProcess()
        getStatus()
    }, [page, size, totalPage, keyword, formData, sort, ascending, formData.selectStatus]);

    const getInitials = (fullName) => {
        if (!fullName || typeof fullName !== 'string') {
            return ''; // Return an empty string or a default value if fullName is undefined or not a string
        }

        const nameParts = fullName.split(' '); // Split the name by spaces
        let initials = '';

        if (nameParts.length === 1) {
            // If there's only one name part, repeat the first letter
            initials = nameParts[0][0].toUpperCase() + nameParts[0][0].toUpperCase();
        } else {
            // Otherwise, take the first letter of the first and last parts
            initials = nameParts[0][0].toUpperCase() + nameParts[nameParts.length - 1][0].toUpperCase();
        }

        return initials;
    };

    const getProfile = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/candidate-profile/get-detail/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataProfile(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getCandidateDetail = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/job/get-candidates/get-detail/${id2}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataJobCandidate(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getWorkExperience = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/work-experience/get-list/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataWorkExperience(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getEducation = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/education/get-list/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataEducation(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getCapitalMarketLicense = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/capital-market-license/get-list/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataCapitalMarketLicense(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getTraining = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/training/get-list/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataTraining(res.data.data);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getVerificationQuestion = () => {
        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/admin/verification-question/get-list/${id}`, config)
            // axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?search=${keyword}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`, config )

            .then((res) => {
                setDataVerificationQuestion(res.data.data.verification_questions);
                setDataConfirmTnc({ confirm_tnc: res.data.data.confirm_tnc });
                
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const leftColumnQuestions = Array.isArray(dataVerificationQuestion) ? dataVerificationQuestion.slice(0, 6) : [];
    const rightColumnQuestions = Array.isArray(dataVerificationQuestion) ? dataVerificationQuestion.slice(6) : [];

    const getTrackingProcess = () => {

        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        };

        axios.get(`${baseUrlAPI()}/history/tracking-process/${id2}`, config)
            .then((res) => {
                if (res.data.code !== 200 || res.data.message !== "Success get list data histories") {
                    console.error('Failed to fetch tracking data');
                } else {
                    setDataApplicant([res.data.data.job_detail]);
                    setFlowProcess(res.data.data.flow_process);
                }
            })
            .catch((err) => {
                toast.error(`Error: ${err.response?.data?.message || 'Something went wrong'}`);
            });
    };

    const httpRequest = async (url, method, payload, headers) => {
        const config = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
            config.body = JSON.stringify(payload);
        }

        if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
            config.headers = headers;
        }

        try {
            const response = await fetch(url, config);
            if (response.ok) {
                let data = response;
                if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                    data = await response.json();
                }
                return data;
            }
            throw response;
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const getDownloadApplicantCandidate = async (id, applicantFileName, isPreview) => {
        try {
            const response = await httpRequest(`${baseUrlAPI()}/admin/candidate-profile/download-application/${id}`, 'get', null, null);
            const blobFile = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blobFile], { type: 'application/pdf' }));
            const linkUrl = document.createElement('a');
            linkUrl.href = url;
            linkUrl.setAttribute('download', applicantFileName + `.pdf`);

            if (isPreview) {
                window.open(url, '_blank');
            } else {
                document.body.appendChild(linkUrl);
                linkUrl.click();
                document.body.removeChild(linkUrl);
            }
        } catch (error) {
            const errCode = error?._data?.code;
            const errMsg = error?._data?.message;
            toast.error(`${errCode} (${errMsg})`);
        }
    }


    const getStatus = () => {
        axios.get(`${baseUrlAPI()}/process-status/get-list`).then((res) => {
            const response = res.data.data
            const newStatus = response.map((obj, i) => ({ label: obj.process_status_name, value: obj.process_status_code }));
            let addStatus = [{ label: "All", value: 0 }, ...newStatus];
            setDataListofStatus(addStatus)
        }).catch((err) => {
            throw new Error('getStatus')
        })
    }

    const downloadTranscript = async (id, transcriptFileName, isPreview) => {
        try {
            const response = await httpRequest(`${baseUrlAPI()}/admin/education/download/transcript/${id}`, 'get', null, null);
            const blobFile = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blobFile], { type: 'application/pdf' }));
            const linkUrl = document.createElement('a');
            linkUrl.href = url;
            linkUrl.setAttribute('download', transcriptFileName + `.pdf`);

            if (isPreview) {
                window.open(url, '_blank');
            } else {
                document.body.appendChild(linkUrl);
                linkUrl.click();
                document.body.removeChild(linkUrl);
            }
        } catch (error) {
            const errCode = error?._data?.code;
            const errMsg = error?._data?.message;
            toast.error(`${errCode} (${errMsg})`);
        }
    }

    const downloadCertificate = async (id, certificateFileName, isPreview) => {
        try {
            const response = await httpRequest(`${baseUrlAPI()}/admin/education/download/certificate/${id}`, 'get', null, null);
            const blobFile = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blobFile], { type: 'application/pdf' }));
            const linkUrl = document.createElement('a');
            linkUrl.href = url;
            linkUrl.setAttribute('download', certificateFileName + `.pdf`);

            if (isPreview) {
                window.open(url, '_blank');
            } else {
                document.body.appendChild(linkUrl);
                linkUrl.click();
                document.body.removeChild(linkUrl);
            }
        } catch (error) {
            const errCode = error?._data?.code;
            const errMsg = error?._data?.message;
            toast.error(`${errCode} (${errMsg})`);
        }
    }

    const downloadCapitalMarketLicense = async (id, capitalMarketLicenseFileName, isPreview) => {
        try {
            const response = await httpRequest(`${baseUrlAPI()}/admin/capital-market-license/download/${id}`, 'get', null, null);
            const blobFile = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blobFile], { type: 'application/pdf' }));
            const linkUrl = document.createElement('a');
            linkUrl.href = url;
            linkUrl.setAttribute('download', capitalMarketLicenseFileName + `.pdf`);

            if (isPreview) {
                window.open(url, '_blank');
            } else {
                document.body.appendChild(linkUrl);
                linkUrl.click();
                document.body.removeChild(linkUrl);
            }
        } catch (error) {
            const errCode = error?._data?.code;
            const errMsg = error?._data?.message;
            toast.error(`${errCode} (${errMsg})`);
        }
    }

    const downloadTraining = async (id, trainingFileName, isPreview) => {
        try {
            const response = await httpRequest(`${baseUrlAPI()}/admin/training/download/${id}`, 'get', null, null);
            const blobFile = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blobFile], { type: 'application/pdf' }));
            const linkUrl = document.createElement('a');
            linkUrl.href = url;
            linkUrl.setAttribute('download', trainingFileName + `.pdf`);

            if (isPreview) {
                window.open(url, '_blank');
            } else {
                document.body.appendChild(linkUrl);
                linkUrl.click();
                document.body.removeChild(linkUrl);
            }
        } catch (error) {
            const errCode = error?._data?.code;
            const errMsg = error?._data?.message;
            toast.error(`${errCode} (${errMsg})`);
        }
    }

    const fetchUpdatedCandidateData = async (id) => {
        try {
            const config = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                referrerPolicy: "strict-origin-when-cross-origin",
            };

            // Fetch the candidate detail
            const candidateResponse = await axios.get(`${baseUrlAPI()}/job/get-candidates/get-detail/${id}`, config);
            const dataJobCandidate = candidateResponse.data.data;

            // Fetch the tracking process
            const trackingResponse = await axios.get(`${baseUrlAPI()}/history/tracking-process/${id}`, config);
            const flowProcess = trackingResponse.data.data.flow_process;

            return { dataJobCandidate, flowProcess };
        } catch (error) {
            console.error('Error fetching updated candidate data:', error);
            throw error; // Re-throw the error to handle it in the calling function if needed
        }
    };

    const reloadCandidateData = () => {
        fetchUpdatedCandidateData(id2)
            .then((updatedData) => {
                setDataJobCandidate(updatedData.dataJobCandidate);
                setFlowProcess(updatedData.flowProcess);
            })
            .catch((error) => {
                toast.error('Failed to reload candidate data');
            });
    };

    const [rejectState, setRejectState] = useState({
        show: false,
        description: '',
        job_candidate_id: null,
    });

    const handleRejectClose = () => {
        setRejectState({
            show: false,
            description: rejectState.description,
            job_candidate_id: rejectState.job_candidate_id,
        });
    };

    const handleRejectShow = (id) => {
        setRejectState({
            show: true,
            description: rejectState.description,
            job_candidate_id: id,
        });
    };

    const handleRejectChange = (e) => {
        setRejectState({
            show: rejectState.show,
            description: e.target.value,
            job_candidate_id: rejectState.job_candidate_id,
        });
    };

    const rejectedCandidate = () => {
        const { job_candidate_id, description } = rejectState;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
            description: description,
        }

        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        };

        axios.post(`${baseUrlAPI()}/application-process/rejected`, formData, config)
            .then((res) => {
                toast.success('Candidate declined successfully');
                handleRejectClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`);
            });
    };

    const handleRejectSaveClick = () => {

        Swal.fire({
            title: 'Decline Confirmation',
            text: 'Are you sure want to declined this applicant?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#F37F26',
            confirmButtonText: 'Yes, decline it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            customClass: {
                confirmButton: 'swal2-confirm swal2-button--full-width',
                cancelButton: 'swal2-cancel swal2-button--full-width',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                rejectedCandidate();
            }
        });
    };

    const [scheduleState, setScheduleState] = useState({
        // show: false,
        date: '',
        startDate: '',
        endDate: '',
        location: '',
        upload: null,
        time: '',
        link: '',
        description: '',
        job_candidate_id: id2 ? id2 : null,
    });

    const [modalState, setModalState] = useState({
        showModalInReview: false,
        showInterview: false,
        showPsikotest: false,
        showMedicalCheckUp: false,
        showExtendOffer: false,
        showModalFinished: false,
    });

    const handleScheduleModalClose = () => {
        setModalState({
            showModalInReview: false,
            showInterview: false,
            showPsikotest: false,
            showMedicalCheckUp: false,
            showExtendOffer: false,
            showModalFinished: false,
        });
    };

    const handleScheduleModalShow = (modalType) => {
        setModalState({
            showModalInReview: modalType === 'In Review',
            showInterview: modalType === 'Schedule Interview',
            showPsikotest: modalType === 'Schedule Psikotest',
            showMedicalCheckUp: modalType === 'Schedule Medical Check Up',
            showExtendOffer: modalType === 'Extend Offer',
            showModalFinished: modalType === 'Mark as Finished',
        });
    };

    function formatTimeWithAmPm(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Handle midnight (0 hours)

        const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedTime;
    }

    // const handleScheduleDateChange = (e) => {
    //     setScheduleState({
    //         ...scheduleState,
    //         date: e.target.value,
    //         time: "",
    //     });

    //     console.log('date', e.target.value === new Date().toISOString().split('T')[0])
    //     console.log('time', new Date().toISOString().slice(11, 16))
    //     console.log('check', formatTimeWithAmPm(new Date()))
    // };

    const handleScheduleDateChange = (e) => {
        const time = "";
        setScheduleState({
            ...scheduleState,
            date: e.target.value,
            time: time,
        });
    };


    const handleScheduleStartDateChange = (e) => {
        setScheduleState({
            ...scheduleState,
            startDate: e.target.value,
        });
    };

    const handleScheduleEndDateChange = (e) => {
        setScheduleState({
            ...scheduleState,
            endDate: e.target.value,
        });
    };

    const handleScheduleTimeChange = (e) => {
        const today = new Date().toISOString().split('T')[0];
        const isToday = scheduleState.date === today;
        const isTimeValid = e.target.value >= formatTimeWithAmPm(new Date());
        if (isToday && isTimeValid || !isToday) {
            setScheduleState({
                ...scheduleState,
                time: e.target.value,
            });
        }
    };


    const handleScheduleLinkChange = (e) => {
        setScheduleState({
            ...scheduleState,
            link: e.target.value,
        });
    };

    const handleScheduleLocationChange = (e) => {
        setScheduleState({
            ...scheduleState,
            location: e.target.value,
        });
    };

    const handleScheduleUploadChange = (e) => {
        const file = e.target.files[0];
        setScheduleState({
            ...scheduleState,
            upload: file
        });
    };

    const handleScheduleDescChange = (e) => {
        setScheduleState({
            ...scheduleState,
            description: e.target.value,
        });
    };


    const inReviewCandidate = () => {
        const { job_candidate_id } = scheduleState;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
        }
        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }
        axios.post(`${baseUrlAPI()}/application-process/review-application`, formData, config)

            .then((res) => {
                toast.success(`Candidate review successfully`);
                handleScheduleModalClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const scheduleInterviewCandidate = () => {
        const { job_candidate_id, date, time, link, description } = scheduleState;
        const dateTime = `${date} ${time}`;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
            interview_date: dateTime,
            link: link,
            description: description,
        }
        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }
        axios.post(`${baseUrlAPI()}/application-process/scheduled-interview`, formData, config)

            .then((res) => {
                toast.success(`Candidate schedule interview successfully`);
                handleScheduleModalClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const schedulePsikotestCandidate = () => {
        const { job_candidate_id, date, time, link, description } = scheduleState;
        const dateTime = `${date} ${time}`;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
            psikotest_date: dateTime,
            link: link,
            description: description,
        }
        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }
        axios.post(`${baseUrlAPI()}/application-process/scheduled-psikotest`, formData, config)

            .then((res) => {
                toast.success(`Candidate schedule psikotest successfully`);
                handleScheduleModalClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const scheduleMedicalCheckUpCandidate = async () => {
        const { job_candidate_id, date, time, location, upload, link, description } = scheduleState;
        const dateTime = `${date} ${time}`;
        const formData = new FormData();
        formData.append('job_candidate_id', Number(job_candidate_id));
        formData.append('mcu_date', dateTime);
        if (upload) {
            formData.append('mcu_file', upload);
        }
        if (location) {
            formData.append('location', location);
        }
        if (link) {
            formData.append('link', link);
        }
        formData.append('description', description);
        try {
            const response = await axios.post(
                `${baseUrlAPI()}/application-process/scheduled-mcu`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                }
            );
            toast.success(`Candidate schedule medical check up successfully`);
            handleScheduleModalClose();
            reloadCandidateData();
        } catch (error) {
            toast.error(translate(`${error.response.data.message}`));
        }
    };


    const extendOfferCandidate = () => {
        const { job_candidate_id, date, time, link, description } = scheduleState;
        const dateTime = `${date} ${time}`;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
            offering_date: dateTime,
            description: description,
        }
        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }
        axios.post(`${baseUrlAPI()}/application-process/extend-offer`, formData, config)

            .then((res) => {
                toast.success(`Candidate extend offer successfully`);
                handleScheduleModalClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const markAsFinishedCandidate = () => {
        const { job_candidate_id } = scheduleState;
        const formData = {
            job_candidate_id: Number(job_candidate_id),
        }
        let config = {
            mode: 'no-cors',
            method: 'POST',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
        }
        axios.post(`${baseUrlAPI()}/application-process/mark-as-finished`, formData, config)

            .then((res) => {
                toast.success(`Candidate mark as finished successfully`);
                handleScheduleModalClose();
                reloadCandidateData();
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }


    const handleScheduleSave = (modalType) => {
        Swal.fire({
            title: `Schedule Confirmation`,
            text: `Are you sure you want to ${modalType}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F37F26',
            cancelButtonColor: '#043B72',
            confirmButtonText: 'Yes, schedule it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            customClass: {
                confirmButton: 'swal2-confirm swal2-button--full-width',
                cancelButton: 'swal2-cancel swal2-button--full-width',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (modalType === 'Schedule Interview') {
                    scheduleInterviewCandidate();
                } else if (modalType === 'Schedule Psikotest') {
                    schedulePsikotestCandidate();
                } else if (modalType === 'Schedule Medical Check Up') {
                    scheduleMedicalCheckUpCandidate();
                } else if (modalType === 'Extend Offer') {
                    extendOfferCandidate();
                }
            }
        });
    };

    const dateFormatDDMMYYYY = (date) => {
        return moment(date).format('DD MMM YYYY');
    }

    const handleEmailApllicantClick = () => {
        const subject = encodeURIComponent('Application Submission');
        const body = encodeURIComponent("Dear " + dataProfile.full_name);
        const email = dataProfile.email ? dataProfile.email : '';
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    }

    const handleEmailClick = (id, fileName) => {
        const subject = encodeURIComponent('Application Submission');
        const body = encodeURIComponent("" + dataProfile.full_name);
        const email = 'humancapital@miraeasset.co.id';
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    }

    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    const parameterBack = props.location.state?.key as IParams | null
    const handleClickBack = () => {
        history.push(parameterBack ? parameterBack : '/career/candidates');
    };

    const handleInput = (name, value) => {
        if (name == 'listStatus') {
            setFormData({ ...formData, selectStatus: null })
            setFormData({
                ...formData,
                [name]: value.value,
            })
            // getStatus()
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
        }
    }

    function formatDate(dateString) {
        return moment(dateString).format('DD/MM/YYYY');
    }

    const getNextProcessStatus = (currentStatus) => {
        if (currentStatus === 'Submitted') {
            return 'In Review';
        } else if (currentStatus === 'In Review') {
            return 'Schedule Interview';
        } else if (currentStatus === 'Scheduled for Interview') {
            return 'Schedule Psikotest';
        } else if (currentStatus === 'Scheduled for Psikotest') {
            return 'Extend Offer';
        } else if (currentStatus === 'Offer Extended') {
            return 'Schedule Medical Check Up';
        } else if (currentStatus === 'Medical Check Up') {
            return 'Mark as Finished';
        } else {
            return 'Unknown Status';  // Fallback in case the status is unrecognized
        }
    };

    return (
        <div className=" px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start my-2">
                        <button className="btn px-0" onClick={handleClickBack}>
                            <div className="font-family-spoqa mb-0 font-size-20" style={{ color: '#f58220' }}>
                                <FontAwesomeIcon icon="arrow-left" color="#f58220" /> Back
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {dataProfile && (
                <div>
                    <div className="row">

                        <div className="col-12 mx-2 ">
                            <div className="card font-family-spoqa">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="row w-100">
                                            <div className="col-6 d-flex align-items-center">
                                                <div
                                                    className="font-family-spoqa mb-0 font-size-20 py-3 px-3 rounded-circle me-2"
                                                    style={{
                                                        backgroundColor: '#F37F26',
                                                        color: '#ffff',
                                                    }}>
                                                    {getInitials(dataProfile.full_name)}
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="font-family-spoqa mb-0 font-size-20 fw-bold">
                                                            {dataProfile.full_name}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="font-family-spoqa mb-0 font-size-20">
                                                            {dataProfile.email}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 d-flex justify-content-end py-2">
                                                <button
                                                    className="btn btn-primary2 font-family-spoqa mb-0 py-1 px-4"
                                                    onClick={() => handleEmailApllicantClick()}
                                                    style={{
                                                        borderRadius: '10px'
                                                    }}
                                                >
                                                    Contact Applicant
                                                </button>

                                                {/* Conditional Rendering based on status */}
                                                {dataJobCandidate.process_status !== 'Position Filled' && dataJobCandidate.process_status !== 'Declined' && (
                                                    <>
                                                        <div className="d-none d-sm-flex col-auto text-center" style={{}}>
                                                            <div className="vr" style={{ width: 1, height: '100%' }}></div>
                                                        </div>
                                                        <button
                                                            className="btn btn-danger font-family-spoqa mb-0 py-1 px-4 ms-1"
                                                            onClick={() => handleRejectShow(id2)}
                                                            style={{
                                                                borderRadius: '10px',
                                                                backgroundColor: 'red',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            Reject
                                                        </button>

                                                        <Modal show={rejectState.show} onHide={handleRejectClose} centered>
                                                            <ModalHeader closeButton>
                                                                <h4 className="">Applicant Decline</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Form>
                                                                    <FormGroup controlId="formDescription">
                                                                        <div className="row px-3">
                                                                            <div className="col-12">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    value={rejectState.description}
                                                                                    onChange={handleRejectChange}
                                                                                    placeholder="Enter the reason for decline"
                                                                                    rows={4}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Form>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={handleRejectSaveClick}
                                                                    className="w-100 py-2"
                                                                    disabled={!rejectState.description.trim()}
                                                                    style={{
                                                                        backgroundColor: rejectState.description.trim() ? 'red' : 'grey',
                                                                        borderColor: rejectState.description.trim() ? 'red' : 'grey',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>

                                                        <button
                                                            className="btn btn-primary2 font-family-spoqa mb-0 py-1 px-4 ms-2"
                                                            onClick={() => handleScheduleModalShow(getNextProcessStatus(dataJobCandidate.process_status))}
                                                            style={{
                                                                borderRadius: '10px',
                                                                borderColor: '#f58220',
                                                                backgroundColor: '#f58220',
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            {getNextProcessStatus(dataJobCandidate.process_status)}
                                                        </button>

                                                        {/* Modal in review finish */}
                                                        <Modal
                                                            show={modalState.showModalInReview}
                                                            onHide={handleScheduleModalClose}
                                                            centered
                                                        >

                                                            <ModalHeader style={{ justifyContent: "center" }} closeButton>

                                                                <div className="d-flex flex-column align-items-center w-100">
                                                                    <FaCheckCircle style={{ color: 'green', fontSize: '3rem' }} />
                                                                    <h2 className="mb-0 mt-4">Review Complete</h2>
                                                                </div>
                                                            </ModalHeader>
                                                            <ModalBody className="text-center">
                                                                <div className="fs-5">
                                                                    is the application hasbeen reviewed? Continue to next step?
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <div className="row w-100">
                                                                    <div className="col-6">
                                                                        <Button
                                                                            className="w-100 py-2"
                                                                            variant="primary"
                                                                            onClick={handleScheduleModalClose}
                                                                            style={{
                                                                                backgroundColor: '#043B72',
                                                                                borderColor: '#043B72',
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Button
                                                                            className="w-100 py-2"
                                                                            variant="danger"
                                                                            onClick={inReviewCandidate}
                                                                            style={{
                                                                                backgroundColor: '#F37F26',
                                                                                borderColor: '#F37F26',
                                                                            }}
                                                                        >
                                                                            Yes, Continue
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </ModalFooter>
                                                        </Modal>

                                                        {/* Schedule Interview */}
                                                        <Modal show={modalState.showInterview} onHide={handleScheduleModalClose} centered>
                                                            <ModalHeader closeButton>
                                                                <h4>Schedule Interview</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Form>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.date}
                                                                                onChange={handleScheduleDateChange}
                                                                                className="form-control"
                                                                                min={new Date().toISOString().split('T')[0]}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Time</label>
                                                                            <input
                                                                                type="time"
                                                                                name="time"
                                                                                value={scheduleState.time}
                                                                                onChange={handleScheduleTimeChange}
                                                                                className="form-control"
                                                                                min={ }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Link</label>
                                                                            <input
                                                                                type="text"
                                                                                name="link"
                                                                                value={scheduleState.link}
                                                                                onChange={handleScheduleLinkChange}
                                                                                className="form-control"
                                                                                placeholder="Https://"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Description</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                name="description"
                                                                                value={scheduleState.description}
                                                                                onChange={handleScheduleDescChange}
                                                                                placeholder="Add descriptions here..."
                                                                                rows={4}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => handleScheduleSave(getNextProcessStatus(dataJobCandidate.process_status))}
                                                                    className="w-100 py-2"
                                                                    disabled={
                                                                        !scheduleState.date ||
                                                                        !scheduleState.time ||
                                                                        !scheduleState.link.trim() ||
                                                                        !scheduleState.description.trim()
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                        borderColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>

                                                        {/* Schedule Psikotest */}
                                                        <Modal show={modalState.showPsikotest} onHide={handleScheduleModalClose} centered>
                                                            <ModalHeader closeButton>
                                                                <h4>Schedule Psikotest</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Form>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.date}
                                                                                onChange={handleScheduleDateChange}
                                                                                className="form-control"
                                                                                min={new Date().toISOString().split('T')[0]}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Time</label>
                                                                            <input
                                                                                type="time"
                                                                                name="time"
                                                                                value={scheduleState.time}
                                                                                onChange={handleScheduleTimeChange}
                                                                                className="form-control"
                                                                                min={ }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Link</label>
                                                                            <input
                                                                                type="text"
                                                                                name="link"
                                                                                value={scheduleState.link}
                                                                                onChange={handleScheduleLinkChange}
                                                                                className="form-control"
                                                                                placeholder="Https://"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Description</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                name="description"
                                                                                value={scheduleState.description}
                                                                                onChange={handleScheduleDescChange}
                                                                                placeholder="Add descriptions here..."
                                                                                rows={4}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => handleScheduleSave(getNextProcessStatus(dataJobCandidate.process_status))}
                                                                    className="w-100 py-2"
                                                                    disabled={
                                                                        !scheduleState.date ||
                                                                        !scheduleState.time ||
                                                                        !scheduleState.link.trim() ||
                                                                        !scheduleState.description.trim()
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                        borderColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>

                                                        {/* Extend Offer */}
                                                        <Modal show={modalState.showExtendOffer} onHide={handleScheduleModalClose} centered>
                                                            <ModalHeader closeButton>
                                                                <h4>Extend Offer</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Form>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.date}
                                                                                onChange={handleScheduleDateChange}
                                                                                className="form-control"
                                                                                min={new Date().toISOString().split('T')[0]}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Time</label>
                                                                            <input
                                                                                type="time"
                                                                                name="time"
                                                                                value={scheduleState.time}
                                                                                onChange={handleScheduleTimeChange}
                                                                                className="form-control"
                                                                                min={ }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Start Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.startDate}
                                                                                onChange={handleScheduleStartDateChange}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">End Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.endDate}
                                                                                onChange={handleScheduleEndDateChange}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div> */}

                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Description</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                name="description"
                                                                                value={scheduleState.description}
                                                                                onChange={handleScheduleDescChange}
                                                                                placeholder="Add descriptions here..."
                                                                                rows={4}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => handleScheduleSave(getNextProcessStatus(dataJobCandidate.process_status))}
                                                                    className="w-100 py-2"
                                                                    disabled={
                                                                        !scheduleState.date ||
                                                                        !scheduleState.time ||
                                                                        !scheduleState.description.trim()
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                        borderColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>

                                                        {/* Schedule Medical Check Up */}
                                                        <Modal show={modalState.showMedicalCheckUp} onHide={handleScheduleModalClose} centered>
                                                            <ModalHeader closeButton>
                                                                <h4>Schedule Medical Check Up</h4>
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Form>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={scheduleState.date}
                                                                                onChange={handleScheduleDateChange}
                                                                                className="form-control"
                                                                                min={new Date().toISOString().split('T')[0]}
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Time</label>
                                                                            <input
                                                                                type="time"
                                                                                name="time"
                                                                                value={scheduleState.time}
                                                                                onChange={handleScheduleTimeChange}
                                                                                className="form-control"
                                                                                min={ }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Link</label>
                                                                            <input
                                                                                type="text"
                                                                                name="link"
                                                                                value={scheduleState.link}
                                                                                onChange={handleScheduleLinkChange}
                                                                                className="form-control"
                                                                                placeholder="Https://"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">MCU Location</label>
                                                                            <input
                                                                                type="text"
                                                                                name="location"
                                                                                value={scheduleState.location}
                                                                                onChange={handleScheduleLocationChange}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label className="text-start d-block">Upload MCU Cover Letter</label>
                                                                            <input
                                                                                type="file"
                                                                                name="upload"
                                                                                onChange={handleScheduleUploadChange}
                                                                                placeholder="Select File"
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-3 mt-3">
                                                                        <div className="col-12">
                                                                            <label className="text-start d-block">Description</label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                name="description"
                                                                                value={scheduleState.description}
                                                                                onChange={handleScheduleDescChange}
                                                                                placeholder="Add descriptions here..."
                                                                                rows={4}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => handleScheduleSave(getNextProcessStatus(dataJobCandidate.process_status))}
                                                                    className="w-100 py-2"
                                                                    disabled={
                                                                        !scheduleState.date ||
                                                                        !scheduleState.time ||
                                                                        !scheduleState.location ||
                                                                        !scheduleState.upload ||
                                                                        !scheduleState.link.trim() ||
                                                                        !scheduleState.description.trim()
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.location &&
                                                                                scheduleState.upload &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                        borderColor:
                                                                            scheduleState.date &&
                                                                                scheduleState.time &&
                                                                                scheduleState.location &&
                                                                                scheduleState.upload &&
                                                                                scheduleState.link.trim() &&
                                                                                scheduleState.description.trim() ? '#F37F26' : 'grey',
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>

                                                        {/* Modal confirm finish */}
                                                        <Modal show={modalState.showModalFinished} onHide={handleScheduleModalClose} centered>
                                                            <ModalHeader style={{ justifyContent: "center" }} closeButton>
                                                                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                                                                    <FaCheckCircle style={{ color: 'green', fontSize: '3rem' }} />
                                                                    <h2 className="mb-0 mt-4">Completed Apply</h2>
                                                                </div>
                                                            </ModalHeader>
                                                            <ModalBody className="text-center">
                                                                <div className="fs-5">
                                                                    Mark this applicant as Finished?
                                                                </div>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <div className="row w-100">
                                                                    <div className="col-6">
                                                                        <Button
                                                                            className="w-100 py-2"
                                                                            variant="primary"
                                                                            onClick={handleScheduleModalClose}
                                                                            style={{
                                                                                backgroundColor: '#043B72',
                                                                                borderColor: '#043B72',
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Button
                                                                            className="w-100 py-2"
                                                                            variant="danger"
                                                                            onClick={markAsFinishedCandidate}
                                                                            style={{
                                                                                backgroundColor: '#F37F26',
                                                                                borderColor: '#F37F26',
                                                                            }}
                                                                        >
                                                                            Yes, Finished
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </ModalFooter>
                                                        </Modal>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mx-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="row w-100">
                                    <div className="col-10 d-flex align-items-center">
                                        <div className="font-family-spoqa font-weight-bold mb-0 font-size-28 pe-2 ps-0">
                                            {dataJobCandidate.position} /
                                        </div>

                                        <button
                                            className="font-family-spoqa font-size-18 mb-0 py-1 px-4"
                                            disabled={true}
                                            style={{
                                                backgroundColor: dataJobCandidate.process_status === 'Declined' ? '#E52017' : '#F37F26',
                                                color: '#fff', // White text color for contrast
                                                border: 'none', // Remove default border
                                                padding: '8px 20px', // Padding for better button appearance
                                                borderRadius: '20px' // Slight rounding for aesthetics
                                            }}
                                        >
                                            {dataJobCandidate.process_status}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 mx-4">
                            <div className="page-title-box d-flex align-items-center justify-content-start">
                                <h5 className="font-family-spoqa font-color-light mb-0 font-size-24">{dataJobCandidate.location_name}</h5>
                            </div>
                        </div>


                        <Accordion defaultActiveKey={["0"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"0"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Profile"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>
                                    <div className="profile col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Profile</h2>
                                                    </div>
                                                </div> */}

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Full Name
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.full_name}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Date Of Birth
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {formatDate(dataProfile.birth_date)}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Mobile Number
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.dialing_code} {dataProfile.cellphone_number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Email
                                                            </div>

                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.email}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Gender
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.gender_name}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Nationalities
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.nationalities_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="bg-light mx-0 my-2" />

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Address
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.address}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                City
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.city_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Country
                                                            </div>

                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.country_name}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Post Code
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.post_code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="bg-light mx-0 my-2" />

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Current Address (Domicile)
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.current_address}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                City
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.current_city_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Country
                                                            </div>

                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.current_country_name}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                Post Code
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                {dataProfile.current_post_code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
                            } */}
                        </Accordion>


                        <Accordion defaultActiveKey={["1"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"1"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Experience"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>

                                    <div className="experience col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Experience</h2>
                                                    </div>
                                                </div> */}

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="col-md-12 col-sm-12">
                                                    {dataWorkExperience.map((experience, index) => (
                                                        <div className="row my-3" key={index}>
                                                            <div className="col-12 mb-3">
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    {experience.company_name}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 pb-3 pt-1">
                                                                    {experience.position_and_duration}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    Working Description
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">  
                                                                    {experience?.description ? experience.description.split("\n").map((item, key) => (
                                                                        <React.Fragment key={key}>
                                                                            {item}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )) : null}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    Quit Reason
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 pb-2 pt-1">
                                                                    {experience.quitting_reason}
                                                                </div>
                                                            </div>
                                                            <hr className="bg-light mx-0 my-2" />
                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
                            } */}
                        </Accordion>

                        <Accordion defaultActiveKey={["2"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"2"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Education"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>

                                    <div className="education col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Education</h2>
                                                    </div>
                                                </div> */}

                                        {dataEducation.map((education, index) => (
                                            <div key={index} className="d-flex align-items-center justify-content-between">
                                                <div className="row w-100">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="row my-3">
                                                            <div className="col-12 mb-3">
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    {education.university}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 py-2">
                                                                    {education.degree_name} - {education.major} | GPA {education.gpa}
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="row w-100">
                                                                        <div className="col-md-6 col-sm-12">
                                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                                Certificate
                                                                            </div>
                                                                            <div className="font-family-spoqa font-weight-normal px-2 py-2">
                                                                                <a
                                                                                    target={"#"}
                                                                                    href="#"
                                                                                    onClick={() => downloadCertificate(education.id, education.certificate_file_name, true)}
                                                                                    download={education.certificate_file_name}
                                                                                >
                                                                                    {education.certificate_file_name}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-12">
                                                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                                                Transcript
                                                                            </div>
                                                                            <div className="font-family-spoqa font-weight-normal px-2 py-2">
                                                                                <a
                                                                                    target={"#"}
                                                                                    href="#"
                                                                                    onClick={() => downloadTranscript(education.id, education.transcript_file_name, true)}
                                                                                    download={education.transcript_file_name}
                                                                                >
                                                                                    {education.transcript_file_name}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="bg-light mx-0 my-2" />
                                            </div>
                                        ))}

                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
} */}
                        </Accordion>

                        <Accordion defaultActiveKey={["3"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"3"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Capital Market License"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>

                                    <div className="capitalMarketLicense col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Capital Market License</h2>
                                                    </div>
                                                </div> */}

                                        {dataCapitalMarketLicense.map((license, index) => (
                                            <div key={index} className="d-flex align-items-center justify-content-between">
                                                <div className="row w-100">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="row my-3">
                                                            <div className="col-12 mb-3">
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    {license.license_status_name}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-2">
                                                                    {license.license_number} | {dateFormatDDMMYYYY(license.issued_date)} - {dateFormatDDMMYYYY(license.expired_date)}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-1">
                                                                    <a
                                                                        target={"#"}
                                                                        href="#"
                                                                        onClick={() => downloadCapitalMarketLicense(license.id, license.license_file_name, true)}
                                                                        download={license.license_file_name}
                                                                    >
                                                                        {license.license_file_name}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="bg-light mx-0 my-2" />
                                            </div>
                                        ))}

                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
} */}
                        </Accordion>


                        <Accordion defaultActiveKey={["4"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"4"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Training"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>
                                    <div className="training col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                                <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                        <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                            <h2 className="font-family-spoqa mb-n2 font-size-28">Training</h2>
                                                        </div>
                                                    </div> */}

                                        {dataTraining.map((training, index) => (
                                            <div key={index} className="d-flex align-items-center justify-content-between">
                                                <div className="row w-100">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="row my-3">

                                                            <div className="font-family-spoqa font-weight-bold px-2 mx-2">
                                                                {training.title} {/* Assuming there's a 'title' field */}
                                                            </div>
                                                            <div className="font-family-spoqa font-weight-normal px-2 mt-2 mx-2">
                                                                {training.institution} - {training.year}

                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-1 mx-n2">
                                                                    <a
                                                                        target={"#"}
                                                                        href="#"
                                                                        onClick={() => downloadTraining(training.id, training.training_file_name, true)}
                                                                        download={training.training_file_name}
                                                                    >
                                                                        {training.training_file_name}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="bg-light mx-0 my-2" />
                                            </div>
                                        ))}

                                        {/* </div>
                                            </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
                            } */}
                        </Accordion>


                        <Accordion defaultActiveKey={["5"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"5"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Filled Out Form Section"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>

                                    <div className="filledOutForm col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Filled Out Form Section</h2>
                                                    </div>
                                                </div> */}

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        {leftColumnQuestions.map((question, index) => (
                                                            <div key={index} className="col-12 mb-3">
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    {question.question_desc}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-2">
                                                                    Answer:
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-1">
                                                                    {question.option_and_essay_answer || 'No answer provided'}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="row my-3">
                                                        {rightColumnQuestions.map((question, index) => (
                                                            <div key={index} className="col-12 mb-3">
                                                                <div className="font-family-spoqa font-weight-bold px-2">
                                                                    {question.question_desc}
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-2">
                                                                    Answer:
                                                                </div>
                                                                <div className="font-family-spoqa font-weight-normal px-2 mt-1">
                                                                    {question.option_and_essay_answer || 'No answer provided'}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                {dataConfirmTnc?.confirm_tnc !== false && dataConfirmTnc?.confirm_tnc !== undefined ? (
                                                    <div className="col-12 mb-3">
                                                        <div className="font-family-spoqa font-weight-normal px-2 mt-n2">
                                                            <div className="form-check form-check-inline">
                                                                <input 
                                                                    className="form-check-input rounded-pill" 
                                                                    type="checkbox" 
                                                                    id="inlineCheckbox3" 
                                                                    value="optionChecked" 
                                                                    style={{ backgroundColor: '#F37F26', borderColor: '#F37F26' }} 
                                                                    checked={true}  
                                                                />
                                                                <label 
                                                                    className="form-check-label text-dark" 
                                                                    htmlFor="inlineCheckbox3" 
                                                                    style={{ color: 'black !important' }}
                                                                >
                                                                    I agree with The Terms & Conditions provided by Mirae Asset Sekuritas Indonesia.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null} 
                                            </div>
                                        </div>

                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
} */}
                        </Accordion>



                        <Accordion defaultActiveKey={["6"]} alwaysOpen className='ms-2'>

                            <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={"6"} >
                                <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                                    <div className='row' style={{ wordWrap: 'break-word' }} >
                                        <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                            <h3 className='font-family-spoqa font-weight-bold font-size-24' style={{ wordBreak: 'break-word' }}>{"Tracking Process"}</h3>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body style={{ backgroundColor: 'white' }}>

                                    <div className="trackingProcess col-12 mx-n3">
                                        {/* <div className="card font-family-spoqa">
                                            <div className="card-body"> */}

                                        {/* <div className="col-12">
                                                    <div className="page-title-box d-flex align-items-center justify-content-start mx-n2">
                                                        <h2 className="font-family-spoqa mb-n2 font-size-28">Tracking Process</h2>
                                                    </div>
                                                </div> */}

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-100">
                                                <div className="mt-2">
                                                    <div className="timeline">
                                                        {flowProcess.map((process, index) => (
                                                            <div key={index} className={`timeline-item ${index % 2 !== 0 ? 'odd' : 'even'}`}>
                                                                <div className={`timeline-line ${flowProcess[index + 1]?.has_done ? 'active' : ''}`} />
                                                                <div className={`timeline-dot ${process.has_done ? 'active' : ''}`} />
                                                                <div className="timeline-content">
                                                                    <p className={`font-family-spoqa font-weight-bold mb-0 title-font ${process.process_status_name === "Declined" ? 'declined-status' : !process.process_date ? 'inactive subtitleEmpty' : ''}`}>
                                                                        {process.process_status_name}
                                                                        {process.link && process.process_status_name !== "Medical Check Up" && (
                                                                            <a
                                                                                href={process.link}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="btn btn-teams btn-sm rounded-pill d-inline-flex align-items-center ml-2"
                                                                            >
                                                                                {process.process_status_name === "Interview" ? (
                                                                                    <span>

                                                                                        <i className="icon-teams me-1">
                                                                                            <IconTeams />
                                                                                        </i>
                                                                                        Join Meeting
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>
                                                                                        <i className='bx bx-globe'></i> Start Psikotest
                                                                                    </span>
                                                                                )}
                                                                            </a>
                                                                        )}
                                                                    </p>
                                                                    <span className="font-family-spoqa font-weight-normal mb-0 sub-title-font">
                                                                        {process.process_status_name === "Declined" ? "You're not matching with our requirement" : process.process_date !== null && process.process_date !== "" ? process.process_date + " " : process.process_date}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div>
                                        </div> */}
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* })
} */}
                        </Accordion>




                    </div>
                    <div className="float-right">
                        <Button onClick={() => getDownloadApplicantCandidate(id, dataProfile.full_name, true)} name="next" id="save-entity" color='primary' type="submit" className='btn btn-primary pb-2 ms-2' disabled={false}
                        >
                            <span className='mr-1'>
                                <i className="bx bxs-download mr-2" />
                                Download Application
                            </span>
                        </Button>
                    </div>
                    <div className="float-right">
                        <Button onClick={() => handleEmailClick(id, dataProfile.full_name)} name="next" id="save-entity" type="submit" className='btn btn-primary2 pb-2'>
                            <span className='mr-1'>
                                <i className="bx bx-file mr-2" />
                                Send Application Via Email
                            </span>
                        </Button>
                    </div>


                </div>
            )}

        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetail);