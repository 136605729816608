import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconCalendar from "app/component/icon-calendar";
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';
import Swal from 'sweetalert2';

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useParams, useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { baseUrlAPI } from './configWhistleblowing';
// import id from 'date-fns/locale/id';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    key?: any,
}

export const ReportDetail = (props: IHeaderProps) => {
    const [dataDetailofWhistleblow, setDataDetailofWhistleblow] = useState<any>([]);
    const [dataListofStatus, setDataListofStatus] = useState<any>([]);
    const [formData, setFormData] = useState<any>({});


    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        getDetailWhistleblowing();
        getStatus();
    }, []);

    const token = localStorage.getItem('access_token');
    const reviewerName = localStorage.getItem('fullName');

    const getDetailWhistleblowing = () => {
        const config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/whistleblow-reports-detail/${id}`, config)
            .then((res) => {
                setDataDetailofWhistleblow(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response ? err.response.data.message : err.message);
            });
    }

    const getStatus = () => {
        const response = [
            { process_status_name: "Open", process_status_code: "Open" },
            { process_status_name: "In Review", process_status_code: "In Review" },
            { process_status_name: "Completed", process_status_code: "Completed" },
        ];
        const newStatus = response.map((obj, i) => ({ label: obj.process_status_name, value: obj.process_status_code }));
        setDataListofStatus(newStatus);
    }

    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    const handleClickBack = () => {
        history.push(`/whistleblow/report`)
    };

    useEffect(() => {
        setFormData({
            report_id: id,
            status: dataDetailofWhistleblow ? dataDetailofWhistleblow.status : '',
            comment: dataDetailofWhistleblow ? dataDetailofWhistleblow.comment : '',
            reviewer: reviewerName ? reviewerName : '',
        });
    }, [dataDetailofWhistleblow]);

    const handleInput = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            report_id: id,
            [name]: value
        }));
    };

    const handleSaveDetailofWhistleblow = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    mode: 'no-cors',
                    method: 'PUT',
                    referrerPolicy: "strict-origin-when-cross-origin",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    data: JSON.stringify({
                        report_id: id,
                        status: formData.status,
                        comment: formData.comment,
                        reviewer: "admin", 
                    }),
                }
                axios.put(`${baseUrlAPI()}/whistleblow-report-update-status`, formData, config)
                    .then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Reply Submitted',
                            text: 'Your reply hasbeen sent!',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#3085d6',
                        })
                        history.push(`/whistleblow/report`)
                    })
                    .catch((err) => {
                        toast.error(err.response ? err.response.data.message : err.message);
                    });
            }
        })
    }

    return (
        <div className=" px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start my-2">
                        <button className="btn px-0" onClick={handleClickBack}>
                            <div className="font-family-spoqa mb-0 font-size-20" style={{ color: '#f58220' }}>
                                <FontAwesomeIcon icon="arrow-left" color="#f58220" /> Back to Whistleblow Report
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {dataDetailofWhistleblow && (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="row w-100 px-2">
                                    <div className="col-10 d-flex align-items-center">
                                        <div className="font-family-spoqa font-weight-bold mb-0 font-size-30 pe-2 ps-0">
                                        ID Report - {dataDetailofWhistleblow.report_id}
                                        </div>
                                        <button
                                            className="font-family-spoqa mb-0 py-1 px-4"
                                            disabled={true}
                                            style={{
                                                backgroundColor: dataDetailofWhistleblow?.status === 'Completed' ? '#54803F' : dataDetailofWhistleblow?.status === 'In Review' ? '#043B72' : '#4880EE',
                                                color: '#fff',
                                                border: 'none',
                                                padding: '8px 20px', 
                                                borderRadius: '20px'
                                            }}
                                        >
                                            {dataDetailofWhistleblow.status}
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-start ms-n2">
                                <div className="font-family-spoqa mb-n4 font-size-30 px-2">Disclosure</div>
                            </div>
                            <hr className="ms-2 mt-n2 " style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="row w-100 px-2">
                                <div className="col-md-6 col-sm-12">
                                    <div className="row my-3">
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Diclosure Type
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.disclosure ? dataDetailofWhistleblow.disclosure : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Full Name
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.reporter ? dataDetailofWhistleblow.reporter : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Contact Number
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.contact_number ? dataDetailofWhistleblow.contact_number : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Other Information
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.other ? dataDetailofWhistleblow.other : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row my-3">
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Location
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.location ? dataDetailofWhistleblow.location : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Email Address
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.email ? dataDetailofWhistleblow.email : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="font-family-spoqa font-weight-bold px-2">
                                                Connection
                                            </div>
                                            <textarea
                                                className="form-control font-family-spoqa"
                                                rows={1}
                                                style={{ resize: 'none', height: '38px' }}
                                                value={dataDetailofWhistleblow.connection ? dataDetailofWhistleblow.connection : '-'}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start ms-n2">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Incident Type</div>
                                </div>
                                <hr className="ms-2 mt-n2 " style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                        <div style={{ width: '100%', height: 'auto', border: 'none', overflow: 'visible' }}>
                                            {dataDetailofWhistleblow?.type && dataDetailofWhistleblow?.type?.map((incident, index) => (
                                                <a 
                                                    key={index} 
                                                    className="btn btn-primary me-2" 
                                                    download 
                                                    style={{
                                                        color: '#fff',
                                                        border: 'none',
                                                        padding: '8px 20px', 
                                                        borderRadius: '20px'
                                                     }}
                                                >
                                                    {incident}
                                                </a>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Answers</div>
                                </div>
                                <hr className="ms-2 mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    {dataDetailofWhistleblow?.answers?.length > 0 ? (
                                        dataDetailofWhistleblow?.answers?.map((answer, index) => (
                                            <div key={index} className="col-md-6 d-flex align-items-start justify-content-center">
                                                <div className="page-title-box d-flex align-items-start justify-content-start" style={{ width: '100%' }}>
                                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                                        <div style={{ width: '100%', height: 'auto', border: 'none', overflow: 'visible' }}>
                                                            <div className="font-family-spoqa mb-2" style={{ fontWeight: 'bold' }}>{answer.question}</div>
                                                            <div className="font-family-spoqa">
                                                            <textarea
                                                                className="form-control font-family-spoqa"
                                                                rows={1}
                                                                style={{ resize: 'none', height: '38px' }}
                                                                value={answer.answer ? answer.answer : '-'}
                                                                disabled={true}
                                                            />
                                                            </div>
                                                        </div>
                                                        <hr className="bg-light mx-0 my-2" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="page-title-box d-flex align-items-center justify-content-start">
                                            <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                                <div className="d-flex" style={{ width: '100%', height: 'auto', overflow: 'visible' }}>
                                                    <div className="text-center" style={{ width: '100%', height: '80px', border: '1px solid #E5E5EA', borderRadius: '5px', padding: '15px' }}>
                                                        <div className="px-2" style={{ fontSize: '14px', color: '#6c757d' }}>
                                                            No answers found.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Attachments</div>
                                </div>
                                <hr className="ms-2 mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                        <div className="d-flex" style={{ width: '100%', height: 'auto', overflow: 'visible' }}>
                                            {dataDetailofWhistleblow?.attachments && dataDetailofWhistleblow?.attachments.length > 0 ? (
                                                dataDetailofWhistleblow?.attachments.map((attachment, index) => (
                                                    <a 
                                                        key={attachment.ID} 
                                                        href={attachment.FilePath} 
                                                        className="btn btn-primary me-2 px-2 py-2" 
                                                        target="_blank" 
                                                        rel="noopener noreferrer"
                                                        download 
                                                        style={{ 
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#43936C',
                                                            borderColor: '#43936C',
                                                            border: '1px solid #43936C',
                                                        }}
                                                        onMouseOver={e => e.currentTarget.style.backgroundColor = '#F7F7F7'}
                                                        onMouseOut={e => e.currentTarget.style.backgroundColor = '#FFFFFF'}
                                                    >
                                                        <span style={{ fontSize: '14px', verticalAlign: 'middle' }}>{attachment.FileName}</span>
                                                        <i className='bx bx-download bx-sm ms-1' style={{ fontSize: '20px', verticalAlign: 'middle' }}></i>
                                                    </a>
                                                ))
                                            ) : (
                                                <div className="text-center" style={{ width: '100%', height: '80px', border: '1px solid #E5E5EA', borderRadius: '5px', padding: '15px' }}>
                                                    <div style={{ fontSize: '14px', color: '#6c757d' }}>No attachments found.</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Update Status</div>
                                </div>
                                <hr className="ms-2 mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-2">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 ps-2" style={{ width: '100%', overflow: 'visible' }}>
                                    <Select
                                        name="status"
                                        value={dataListofStatus.find(option => option.value === formData.status) || null}
                                        placeholder="Select Status"
                                        options={dataListofStatus}
                                        onChange={(e) => handleInput('status', e.value)}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                border: '1px solid #F37F26', 
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #F37F26',
                                                },
                                            }),
                                        }}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-n4 font-size-30 px-2">Add Comment</div>
                                </div>
                                <hr className="ms-2 mt-n2" style={{ height: '2px', width: '20px', backgroundColor: '#F37F26' }}/>
                            </div>
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <div className="font-family-spoqa mb-0 px-2" style={{ width: '100%', overflow: 'visible' }}>
                                        <div style={{ width: '100%', height: 'auto', border: 'none', overflow: 'visible' }}>
                                            <textarea className="form-control" required defaultValue={dataDetailofWhistleblow.comment} rows={5} style={{ resize: 'none', width: '100%', overflow: 'visible' }} onChange={(e) => handleInput('comment', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-start">
                                    <button className="btn btn-primary ms-2" type="submit" onClick={(e) => {
                                        e.preventDefault()
                                        if (!formData.comment) {
                                            toast.error('Please fill in the comment field');
                                            return;
                                        }
                                        handleSaveDetailofWhistleblow()
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
 
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);