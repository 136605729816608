export const priorityDocument = [
    {
        label: "High",
        value: "1"
    },
    {
        label: "Medium",
        value: "2"
    },
    {
        label: "Low",
        value: "3"
    },
]


export const  typeDocument = [{
    label: "Internal Memo",
    value: "IMO"
  }]
  
export const documentSecurity = [
      {
        label: "Restricted",
        value: "1"
      },
      {
        label: "Confidential",
        value: "2"
      },
      {
        label: "Internal",
        value: "3"
      },
      {
        label: "Public",
        value: "4"
      },
  ]