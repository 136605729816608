import * as React from "react"
import { SVGProps } from "react"

const HambIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.6 2A1.2 1.2 0 0 1 1.8.8h14.4a1.2 1.2 0 0 1 0 2.4H1.8A1.2 1.2 0 0 1 .6 2ZM.6 8a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 0 1 0 2.4H1.8A1.2 1.2 0 0 1 .6 8ZM.6 14a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H1.8A1.2 1.2 0 0 1 .6 14Z"
      fill="#0A0A0A"
    />
  </svg>
)

export default HambIcon
