import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';


import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSignature, saveSignature, deleteSignature } from './../../entities/signature/signature.reducer'
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import SignatureIcon from 'app/component/signature-icon';
import SignatureIconWhite from 'app/component/signature-icon-white';
import SignaturePad from 'react-signature-canvas'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import Resizer from "react-image-file-resizer";
import { width } from '@fortawesome/free-solid-svg-icons/faSort';
import { toast } from 'react-toastify';
import axios from 'axios'

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const SignaturePageAdd = (props: IDocumentProps) => {
  // const { account } = props;
  const signCanvas = useRef<any>();
  const [disable, setDisable] = useState<any>(true)
  const [progress, setProgress] = useState<any>(false)
  const clear = () => {
    signCanvas.current.clear()
    setDisable(true)
  };


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }


  const save = async () => {
    setProgress(true)
  await delayTime(2500)
 
  let basse64 = signCanvas.current.getCanvas().toDataURL("image/png")  
  
  const image = await resizeFile(dataURLtoBlob(basse64));
    // props.saveSignature(dataURLtoBlob(basse64))
    // toast.success('Sukses Menyimpan Tanda Tangan', {
    //   position: "top-left",
    //   autoClose: 8000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   });
    // props.history.push('/')
    // setProgress(false)
    const formData = new FormData();
    formData.append("signatureImage", dataURLtoBlob(basse64));
    axios.put('/services/uaadocservice/api/personal-identity/save-signature', formData)
    .then(res => {
       toast.success('Success Save Signature', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
       setProgress(false)
         props.history.push('/')
    }).catch(err => {
      toast.error('Failed Save Signature', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setProgress(false)
    })
  }






const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      150,
      100,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });


  
  useEffect(() => {
    // props.getSignature()
  }, []);
  

  

  

 
 



  const { documentList, loading } = props;
  return (


    <div className="padding-wrap-sign" style={{height: '80vh'}}>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Signature Add</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">View Your Signature </h1>
          </div>
        </div>
      </div>
    
      <div className="container-fluid">
      <div className='shadow-sm justify-content-center d-flex wrapSign'>
         <div style={{backgroundColor: '#fff', height: 200, width: 300}} >
          <SignaturePad
          onEnd={() => {if(signCanvas.current.isEmpty() === false) {setDisable(false)}}}
          minWidth={1}
          maxWidth={1}
          ref={signCanvas}
          canvasProps={{ className: "signature__canvas", height: 200, width: 300, backgroundColor: '#ffffff' }}
          />
        </div>
      </div>
    

      <div className='my-3'>
             <button className='btn btn-secondary mr-2 px-4' disabled={progress} onClick={() => props.history.goBack()}>Back</button>
            <button className='btn btn-secondary mr-2 px-4' disabled={progress} onClick={() => clear()}>Clear</button>
            {/* <button className='btn btn-danger mr-2 px-3'><XCircleIcon className='mr-1'/>Delete</button> */}
            <button className='btn btn-primary px-3' disabled={disable || progress } onClick={() => save()}>{ progress != true ? <SignatureIconWhite className='mr-1' /> : <Spinner color='#fff' size="sm" className='mr-1'/> }Save Signature</button>
      </div>
     

      </div>
     
    </div >
    
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  // getSignature,
  saveSignature, 
  deleteSignature
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(SignaturePageAdd);
