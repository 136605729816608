import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDocumentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DocumentDetail = (props: IDocumentDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { documentEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="documenttestApp.document.detail.title">Document</Translate> [<b>{documentEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="type">
              <Translate contentKey="documenttestApp.document.type">Type</Translate>
            </span>
          </dt>
          <dd>{documentEntity.type}</dd>
          <dt>
            <span id="fileNumber">
              <Translate contentKey="documenttestApp.document.fileNumber">File Number</Translate>
            </span>
          </dt>
          <dd>{documentEntity.fileNumber}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="documenttestApp.document.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{documentEntity.priority}</dd>
          <dt>
            <span id="documentSecurity">
              <Translate contentKey="documenttestApp.document.documentSecurity">Document Security</Translate>
            </span>
          </dt>
          <dd>{documentEntity.documentSecurity}</dd>
          <dt>
            <span id="date">
              <Translate contentKey="documenttestApp.document.date">Date</Translate>
            </span>
          </dt>
          <dd>{documentEntity.date ? <TextFormat value={documentEntity.date} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="expDate">
              <Translate contentKey="documenttestApp.document.expDate">Exp Date</Translate>
            </span>
          </dt>
          <dd>{documentEntity.expDate ? <TextFormat value={documentEntity.expDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="sender">
              <Translate contentKey="documenttestApp.document.sender">Sender</Translate>
            </span>
          </dt>
          <dd>{documentEntity.sender}</dd>
          <dt>
            <span id="senderPosition">
              <Translate contentKey="documenttestApp.document.senderPosition">Sender Position</Translate>
            </span>
          </dt>
          <dd>{documentEntity.senderPosition}</dd>
          <dt>
            <span id="senderLevel">
              <Translate contentKey="documenttestApp.document.senderLevel">Sender Level</Translate>
            </span>
          </dt>
          <dd>{documentEntity.senderLevel}</dd>
          <dt>
            <span id="receiver">
              <Translate contentKey="documenttestApp.document.receiver">Receiver</Translate>
            </span>
          </dt>
          <dd>{documentEntity.receiver}</dd>
          <dt>
            <span id="cc">
              <Translate contentKey="documenttestApp.document.cc">Cc</Translate>
            </span>
          </dt>
          <dd>{documentEntity.cc}</dd>
          <dt>
            <span id="regarding">
              <Translate contentKey="documenttestApp.document.regarding">Regarding</Translate>
            </span>
          </dt>
          <dd>{documentEntity.regarding}</dd>
          <dt>
            <span id="fileContent">
              <Translate contentKey="documenttestApp.document.fileContent">File Content</Translate>
            </span>
          </dt>
          <dd>{documentEntity.fileContent}</dd>
          <dt>
            <span id="fileStatus">
              <Translate contentKey="documenttestApp.document.fileStatus">File Status</Translate>
            </span>
          </dt>
          <dd>{documentEntity.fileStatus}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="documenttestApp.document.version">Version</Translate>
            </span>
          </dt>
          <dd>{documentEntity.version}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="documenttestApp.document.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{documentEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="documenttestApp.document.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {documentEntity.createdDate ? <TextFormat value={documentEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="lastModifiedBy">
              <Translate contentKey="documenttestApp.document.lastModifiedBy">Last Modified By</Translate>
            </span>
          </dt>
          <dd>{documentEntity.lastModifiedBy}</dd>
          <dt>
            <span id="lastModifiedDate">
              <Translate contentKey="documenttestApp.document.lastModifiedDate">Last Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {documentEntity.lastModifiedDate ? (
              <TextFormat value={documentEntity.lastModifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="description">
              <Translate contentKey="documenttestApp.document.description">Description</Translate>
            </span>
          </dt>
          <dd>{documentEntity.description}</dd>
          <dt>
            <span id="fileId">
              <Translate contentKey="documenttestApp.document.fileId">File Id</Translate>
            </span>
          </dt>
          <dd>{documentEntity.fileId}</dd>
          <dt>
            <span id="filePath">
              <Translate contentKey="documenttestApp.document.filePath">File Path</Translate>
            </span>
          </dt>
          <dd>{documentEntity.filePath}</dd>
        </dl>
        <Button tag={Link} to="/document" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/document/${documentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentEntity: document.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail);
