import * as React from "react"
import { SVGProps } from "react"

const SaveDraftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.2 2.2A1.6 1.6 0 0 1 1.8.6h3.669A1.6 1.6 0 0 1 6.6 1.07L9.331 3.8c.3.3.469.707.469 1.131v6.87a1.6 1.6 0 0 1-1.6 1.6H1.8a1.6 1.6 0 0 1-1.6-1.6V2.2ZM1.8 7a.8.8 0 0 1 .8-.8h4.8a.8.8 0 0 1 0 1.6H2.6a.8.8 0 0 1-.8-.8Zm.8 2.4a.8.8 0 1 0 0 1.6h4.8a.8.8 0 0 0 0-1.6H2.6Z"
      fill="#fff"
    />
  </svg>
)

export default SaveDraftIcon
