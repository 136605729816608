import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocument, defaultValue } from 'app/shared/model/document.model';

export const ACTION_TYPES = {
  SEARCH_DOCUMENTS: 'document/SEARCH_DOCUMENTS',
  FETCH_DOCUMENT_LIST: 'document/FETCH_DOCUMENT_LIST',
  FETCH_DOCUMENT_LIST_APPROVAL: 'document/FETCH_DOCUMENT_LIST_APPROVAL',
  FETCH_DOCUMENT_LIST_ACKNOWLEDGE: 'document/FETCH_DOCUMENT_LIST_ACKNOWLEDGE',
  FETCH_DOCUMENT_LIST_CONFIRMATION: 'document/FETCH_DOCUMENT_LIST_CONFIRMATION',
  FETCH_DOCUMENT_LIST_SURAT_MASUK: 'document/FETCH_DOCUMENT_LIST_SURAT_MASUK',
  FETCH_DOCUMENT_LIST_ACTIVITY: 'document/FETCH_DOCUMENT_LIST_ACTIVITY',
  FETCH_DOCUMENT_LIST_SURAT_KELUAR: 'document/FETCH_DOCUMENT_LIST_SURAT_KELUAR',
  FETCH_DOCUMENT_LIST_SURAT_REJECTED: 'document/FETCH_DOCUMENT_LIST_SURAT_REJECTED',
  FETCH_DOCUMENT_DETAIL: 'document/FETCH_DOCUMENT_DETAIL',
  FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  FETCH_DEPARTMENT: 'document/FETCH_DEPARTMENT',
  FETCH_USER_LIST: 'document/FETCH_USER_LIST',
  FETCH_APPROVAL_LIST: 'document/FETCH_APPROVAL_LIST',
  FETCH_ACKNOWLEDGE_LIST: 'document/FETCH_ACKNOWLEDGE_LIST',
  FETCH_CONFIRMATION_LIST: 'document/FETCH_CONFIRMATION_LIST',
  FETCH_DOCUMENT_CODE_LIST: 'document/FETCH_DOCUMENT_CODE_LIST',
  CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  RESET: 'document/RESET',
  OPENPREVIEW: 'document/previewsign',
  APPROVE_DOCUMENT: 'document/approve',
  REJECT_DOCUMENT: 'document/reject',
  VIEW_DOCUMENT_SIGN: 'document/viewsign',
  SEND_DOCUMENT: 'document/SEND_DOCUMENT',
  FETCH_MASTER_DOCUMENT_TYPE: 'document/FETCH_MASTER_DOCUMENT_TYPE', /* MASTER_DOCUMENT_TYPE */
  FETCH_MASTER_DOCUMENT_PRIORITY: 'document/FETCH_MASTER_DOCUMENT_PRIORITY', /* MASTER_DOCUMENT_PRIORITY */
  FETCH_MASTER_DOCUMENT_STATUS: 'document/FETCH_MASTER_DOCUMENT_STATUS', /* MASTER_DOCUMENT_STATUS */
  FETCH_MASTER_DOCUMENT_SENDER: 'document/FETCH_MASTER_DOCUMENT_SENDER', /* MASTER_DOCUMENT_SENDER */
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDocument>,
  documentDashboardList: [] as ReadonlyArray<IDocument>,
  documentListAcknowledge: [] as ReadonlyArray<IDocument>,
  documentListConfirmation: [] as ReadonlyArray<IDocument>,
  documentListSuratMasuk: [] as ReadonlyArray<IDocument>,
  documentListActivity: [] as ReadonlyArray<IDocument>,
  documentListSuratKeluar: [] as ReadonlyArray<IDocument>,
  documentListSuratRejected: [] as ReadonlyArray<IDocument>,
  deptList: [],
  userList: [],
  approvalList: [],
  acknowledgeList: [],
  documentListCode: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalApproval: 0,
  totalAcknowledge: 0,
  totalConfirmation: 0,
  totalSuratMasuk: 0,
  totalNotifSuratMasuk: 0,
  totalActivity: 0,
  totalSuratKeluar: 0,
  totalSuratRejected: 0,
  totalPageApproval: 0,
  totalPageAcknowledge: 0,
  totalPageConfirmation: 0,
  totalPageKotakMasuk: 0,
  totalPageActivity: 0,
  totalPageKotakKeluar: 0,
  totalPageKotakRejected: 0,
  totalNotifSuratRejected: 0,
  totalPage: 0,
  loadingdetail: false,
  loadingdepartement: false,
  detaildocument: [],
  loadingapprove: false,
  loadingreject: false,
  filePDFsign: null,
  acknowledgelist: [],
  confirmationlist: [],
  fileUrlBlob: null,
  fileBlobPreview: null,
  dataDocumentType: [], /* MASTER_DOCUMENT_TYPE */
  dataDocumentPriority: [], /* MASTER_DOCUMENT_PRIORITY */
  dataDocumentStatus: [], /* MASTER_DOCUMENT_STATUS */
  dataDocumentSender: [], /* MASTER_DOCUMENT_SENDER */
};

export type DocumentState = Readonly<typeof initialState>;

// Reducer
export default (state: DocumentState = initialState, action): DocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DOCUMENTS):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_CONFIRMATION):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACTIVITY):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED):
    case REQUEST(ACTION_TYPES.FETCH_DEPARTMENT):
    case REQUEST(ACTION_TYPES.FETCH_MASTER_DOCUMENT_TYPE): /* MASTER_DOCUMENT_TYPE */
    case REQUEST(ACTION_TYPES.FETCH_MASTER_DOCUMENT_PRIORITY): /* MASTER_DOCUMENT_PRIORITY */
    case REQUEST(ACTION_TYPES.FETCH_MASTER_DOCUMENT_STATUS): /* FETCH_MASTER_DOCUMENT_STATUS */
    case REQUEST(ACTION_TYPES.FETCH_MASTER_DOCUMENT_SENDER): /* FETCH_MASTER_DOCUMENT_SENDER */
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingdepartement: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_USER_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_APPROVAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_CONFIRMATION_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
    case REQUEST(ACTION_TYPES.OPENPREVIEW):
    case REQUEST(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
      const filepdf = new Blob([action.payload.data], { type: "application/pdf" });
      return {
        ...state,
        fileBlobPreview: filepdf,
      }
    case REQUEST(ACTION_TYPES.APPROVE_DOCUMENT):
      return {
        ...state,
        loadingapprove: true
      };
    case SUCCESS(ACTION_TYPES.APPROVE_DOCUMENT):
      return {
        ...state,
        loadingapprove: false
      }
    case FAILURE(ACTION_TYPES.APPROVE_DOCUMENT):
      return {
        ...state,
        loadingapprove: false
      }
    case REQUEST(ACTION_TYPES.REJECT_DOCUMENT):
      return {
        ...state,
        loadingreject: true
      }
    case SUCCESS(ACTION_TYPES.REJECT_DOCUMENT):
      return {
        ...state,
        loadingreject: false
      }
    case FAILURE(ACTION_TYPES.REJECT_DOCUMENT):
      return {
        ...state,
        loadingreject: false
      }
    case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
      return {
        ...state,
        loadingdetail: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.UPDATE_DOCUMENT):
    case REQUEST(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_DOCUMENTS):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
    case FAILURE(ACTION_TYPES.CREATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.UPDATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
      return {
        ...state,
        loadingdetail: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
      return {
        ...state,
        detaildocument: action.payload.data.data,
        acknowledgelist: action.payload.data.data.acknowledge,
        loadingdetail: false
      };
    case SUCCESS(ACTION_TYPES.OPENPREVIEW):
      const file = new Blob([action.payload.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
      return {
        ...state,
        filePDFsign: file
      }
    case SUCCESS(ACTION_TYPES.SEARCH_DOCUMENTS):
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
      return {
        ...state,
        loading: false,
        documentDashboardList: action.payload.data.data.content,
        totalApproval: action.payload.data.data.totalElements,
        totalPageApproval: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
      return {
        ...state,
        loading: false,
        documentListAcknowledge: action.payload.data.data.content,
        totalAcknowledge: action.payload.data.data.totalElements,
        totalPageAcknowledge: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_CONFIRMATION):
      return {
        ...state,
        loading: false,
        documentListConfirmation: action.payload.data.data.content,
        totalConfirmation: action.payload.data.data.totalElements,
        totalPageConfirmation: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
      return {
        ...state,
        loading: false,
        documentListSuratMasuk: action.payload.data.data.content,
        totalSuratMasuk: action.payload.data.data.totalElements,
        totalNotifSuratMasuk: action.payload.data.data.totalUnreadDocument,
        totalPageKotakMasuk: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACTIVITY):
      return {
        ...state,
        loading: false,
        documentListActivity: action.payload.data.data.content,
        totalActivity: action.payload.data.data.totalElements,
        totalPageActivity: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
      return {
        ...state,
        loading: false,
        documentListSuratKeluar: action.payload.data.data.content,
        totalSuratKeluar: action.payload.data.data.totalElements,
        totalPageKotakKeluar: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED):
      return {
        ...state,
        loading: false,
        documentListSuratRejected: action.payload.data.data.content,
        totalSuratRejected: action.payload.data.data.totalElements,
        totalNotifSuratRejected: action.payload.data.data.totalUnreadDocument,
        totalPageKotakRejected: action.payload.data.data.totalPages
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DEPARTMENT):
      return {
        ...state,
        loadingdepartement: false,
        deptList: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER_LIST):
      return {
        ...state,
        loading: false,
        userList: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPROVAL_LIST):
      return {
        ...state,
        loading: false,
        approvalList: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST):
      return {
        ...state,
        loading: false,
        acknowledgeList: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST):
      return {
        ...state,
        loading: false,
        documentListCode: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOCUMENT):
    case SUCCESS(ACTION_TYPES.UPDATE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOCUMENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_DOCUMENT_TYPE): /* MASTER_DOCUMENT_TYPE */
      return {
        ...state,
        loading: false,
        dataDocumentType: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_DOCUMENT_PRIORITY): /* MASTER_DOCUMENT_PRIORITY */
      return {
        ...state,
        loading: false,
        dataDocumentPriority: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_DOCUMENT_STATUS): /* MASTER_DOCUMENT_STATUS */
      return {
        ...state,
        loading: false,
        dataDocumentStatus: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_MASTER_DOCUMENT_SENDER): /* MASTER_DOCUMENT_SENDER */
      return {
        ...state,
        loading: false,
        dataDocumentSender: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'services/documentservice/api/documents';
const deptlisturl = 'services/uaadocservice/api/users/get-active-group';
const userlisturl = 'services/uaadocservice/api/users/get-approver';
const approvalListUrl = 'services/uaadocservice/api/users/get-approver';
const acknowledgeListUrl = 'services/uaadocservice/api/users/get-acknowledge';
const documentListCodeUrl = 'services/uaadocservice/api/group/get-list-document-code';
const apiSearchUrl = 'api/_search/documents';
const masterDocumentType = 'services/documentservice/api/documents/dropdown-document-type'; /* MASTER_DOCUMENT_TYPE */
const masterDocumentPriority = 'services/documentservice/api/documents/dropdown-document-priority'; /* MASTER_DOCUMENT_PRIORITY */
const masterDocumentStatus = 'services/documentservice/api/documents/dropdown-document-status'; /* MASTER_DOCUMENT_STATUS */
const masterDocumentSender = 'services/documentservice/api/documents/dropdown-document-sender'; /* MASTER_DOCUMENT_SENDER */

const streamToBase64 = (stream) => {
  const concat = require('concat-stream')
  const { Base64Encode } = require('base64-stream')
  return new Promise((resolve, reject) => {
    const base64 = new Base64Encode()
    const cbConcat = (base64) => {
      resolve(base64)
    }
    stream
      .pipe(base64)
      .pipe(concat(cbConcat))
      .on('error', (error) => {
        reject(error)
      })
  })
}

// Actions
export const getSearchEntities: ICrudSearchAction<IDocument> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DOCUMENTS,
  payload: axios.get<IDocument>(`${apiSearchUrl}?query=${query}`),
});

export const getEntities: ICrudGetAllAction<IDocument> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
  payload: axios.get<IDocument>(`${apiUrl}?cacheBuster=${new Date().getTime()}`),
});

export const getMasterDocumentType = () => ({
  type: ACTION_TYPES.FETCH_MASTER_DOCUMENT_TYPE,
  payload: axios.get<IDocument>(`${masterDocumentType}`),
}); /* MASTER_DOCUMENT_TYPE */

export const getMasterDocumentPriority = () => ({
  type: ACTION_TYPES.FETCH_MASTER_DOCUMENT_PRIORITY,
  payload: axios.get<IDocument>(`${masterDocumentPriority}`),
}); /* MASTER_DOCUMENT_PRIORITY */

export const getMasterDocumentStatus = () => ({
  type: ACTION_TYPES.FETCH_MASTER_DOCUMENT_STATUS,
  payload: axios.get<IDocument>(`${masterDocumentStatus}`),
}); /* MASTER_DOCUMENT_STATUS */

export const getMasterDocumentSender = () => ({
  type: ACTION_TYPES.FETCH_MASTER_DOCUMENT_SENDER,
  payload: axios.get<IDocument>(`${masterDocumentSender}`),
}); /* MASTER_DOCUMENT_STATUS */

/* Filter */
export const getDocumentApprovalFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_approval?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});

export const getDocumentAcknowledgeFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_acknowledge?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});

export const getDocumentConfirmationFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_CONFIRMATION,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_confirm?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});

export const getDocumentSuratMasukFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_masuk?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});


export const getDocumentActivityFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACTIVITY,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_activity?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});

export const getDocumentSuratKeluarFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_keluar?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});

export const getDocumentSuratRejectedFilter = (page, size, sort, searchDocType, searchPriorityFilter, searchDocStatus, searchSender, searchFromDateFilter, searchtoDateFilter) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_rejected?page=${page}&size=${size}${searchDocType}${searchPriorityFilter}${searchDocStatus}${searchSender}${searchFromDateFilter}${searchtoDateFilter}`),
});
/* End Filter */

export const getEntitiesByEmpId = (employeeId, page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST,
  payload: axios.get<IDocument>(`${apiUrl}/draft?employeeNo=${employeeId}`),
});

export const getDocumentApproval = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_approval?page=${page}&size=${size}`),
});

export const getDocumentAcknowledge = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_acknowledge?page=${page}&size=${size}`),
});

export const getDocumentConfirmation = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_CONFIRMATION,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_confirm?page=${page}&size=${size}`),
});

export const getDocumentSuratMasuk = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_masuk?page=${page}&size=${size}`),
});

export const getDocumentActivity = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_ACTIVITY,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_activity?page=${page}&size=${size}`),
});

export const getDocumentSuratKeluar = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_kotak_keluar?page=${page}&size=${size}`),
});

export const getDocumentSuratRejected = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_REJECTED,
  payload: axios.get<IDocument>(`${apiUrl}/dashboard_rejected?page=${page}&size=${size}`),
});

export const getDocumentDetail: ICrudGetAction<IDocument> = (id: string) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
  payload: axios.get<IDocument>(`/services/documentservice/api/documents/${id}`),
});

export const postDocument = (data, id) => {
  const formData = new FormData();
  formData.append("file", data);
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT_DETAIL,
    payload: axios.post<any>(`/services/documentservice/api/file-metadata/upload-to-gcp?fileId=${id}`, formData),
  }
}

export const viewPDF = (id) => {
  return {
    type: ACTION_TYPES.VIEW_DOCUMENT_SIGN,
    payload: axios.get<any>(`/services/documentservice/api/dms/get_document_from_gcp?fileId=${id}`, { responseType: 'arraybuffer' }),
  }
}

export const approveDocument = (id, notes, file) => {
  return {
    type: ACTION_TYPES.APPROVE_DOCUMENT,
    payload: axios.get<any>(`/services/documentservice/api/dms/confirm-approve?id=${id}&notes=${notes}`),
  }
}

export const rejectDocument = (id, notes, file) => {
  const formData = new FormData();
  formData.append("pdfFile", file);
  return {
    type: ACTION_TYPES.REJECT_DOCUMENT,
    payload: axios.put<any>(`/services/documentservice/api/dms/reject?id=${id}&notes=${notes}`, formData),
  }
}

export const signDocument = (data, sign, id) => {
  const formData = new FormData();
  formData.append("pdfFile", data);
  formData.append("signatureImage", sign);
  return {
    type: ACTION_TYPES.OPENPREVIEW,
    payload: axios.post<any>(`/services/documentservice/api/dms/sign-file`, formData, { responseType: 'blob' }),
  }
}

export const getDeptList = (sort) => ({
  type: ACTION_TYPES.FETCH_DEPARTMENT,
  payload: axios.get<any>(`${deptlisturl}`),
});

export const getUserList = (sort) => ({
  type: ACTION_TYPES.FETCH_USER_LIST,
  payload: axios.get<IDocument>(`${userlisturl}`),
});

export const getApprovalList = (sort) => ({
  type: ACTION_TYPES.FETCH_APPROVAL_LIST,
  payload: axios.get<IDocument>(`${approvalListUrl}`),
});

export const getAcknowledgeList = (sort) => ({
  type: ACTION_TYPES.FETCH_ACKNOWLEDGE_LIST,
  payload: axios.get<IDocument>(`${acknowledgeListUrl}`),
});

export const getListDocumentCode = (sort) => ({
  type: ACTION_TYPES.FETCH_DOCUMENT_CODE_LIST,
  payload: axios.get<IDocument>(`${documentListCodeUrl}`),
});

export const getEntity: ICrudGetAction<IDocument> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DOCUMENT,
    payload: axios.get<IDocument>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const sendNewDocument: ICrudPutAction<IDocument> = entity => async dispatch => {
  const requestUrl = `/services/documentservice/api/documents/send_document_new?id=`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const saveDocumentToDraft: ICrudPutAction<IDocument> = entity => async dispatch => {
  // const requestUrl = `/services/documentservice/api/documents/save_as_draft`;
  const requestUrl = `/services/documentservice/api/documents/save_as_draft?id=${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCUMENT,
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDocument> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOCUMENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});