import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { initial, size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

// update karyawan
export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

export const Karyawan = (props: IHeaderProps) => {

  const dataStatus = [
    {
      label: "All Status",
      value: "all"
    },
    {
      label: "Active",
      value: "true"
    },
    {
      label: "Inactive",
      value: "false"
    }
  ]

  let history = useHistory();
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [listNotification, setListNotification] = useState<any>([])
  const [listKaryawan, setListKaryawan] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [listDepartment, setListDepartment] = useState<any>([])
  const [modalEdit, setModalEdit] = useState<any>(false)
  const [initialValue, setInitialValue] = useState<any>(null)
  const [deptName, setDeptName] = useState<any>('')
  const [DOB, setDOB] = useState<any>('')
  // const [sort, setSort] = useState<any>("employeeNo")
  // const [ascending, setAscending] = useState<any>(true)
  const [editIdKaryawan, setEditIdKaryawan] = useState<any>("")
  const [editDataKaryawan, setEditDataKaryawan] = useState<any>({})
  const [editDataMember, setEditDataMember] = useState<any>([])
  const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
  const [dataOrganization, setDataOrganization] = useState<any>([])

  const [valActivated, setValActivated] = useState<any>("all");
  const [sort, setSort] = useState<any>("employeeNo")
  const [ascending, setAscending] = useState<any>(true)
  const [addStatus, setAddStatus] = useState<any>("")

  useEffect(() => {
    getListDataKaryawan()
    getLevelOrganization()
  }, [page, size, totalPage, keyword, dataOrganization, valActivated, sort, ascending]);

  const customStyles = {
    control: base => ({
      ...base,
      height: 54,
      minHeight: 54
    })
  };

  const KaryawanSchema = Yup.object().shape({
    nik: Yup.string()
      .required("NIK is required")
      .matches(/^\d+$/, 'The field should have number only'),
    fullname: Yup.string()
      .required("Fullname is required")
      .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
    email: Yup.string().email()
      .required("Email is required"),
    phonenumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d+$/, 'The field should have number only'),
    date: Yup.string()
      .required("Date is required"),
  });

  /* get data */
  const getListDataKaryawan = () => {
    // var valAsc = ascending ? 'asc' : 'desc';
    axios.get(`/services/uaadocservice/api/personal-identity/karyawan/list?keyword=${keyword}&page=${page}&limit=${size}&isAscending=${ascending}&sort=${sort}&statusFilter=${valActivated}`)
      .then((res) => {
        setListKaryawan(res.data.data.data)
        setTotalPage(res.data.data.totalPage)
      }).catch((err) => {
        throw new Error('getListDataKaryawan')
      })
  }

  const getDetailEmployee = async (id: any) => {
    await axios.get(`/services/uaadocservice/api/personal-identity/karyawan/get-detail/${id}`).then(res => {
      const responseDetail = res.data.data
      let payloadResponse = {
        id: responseDetail.id,
        employeeNo: id,
        email: responseDetail.email,
        fullName: responseDetail.fullName,
        joinDate: responseDetail.joinDate,
        phoneNumber: responseDetail.phoneNumber
      }
      const dataDetailMember = responseDetail.memberOfGroups.map(item => {
        return {
          levelOrganizationId: item.levelOrganizationId,
          groupId: item.groupId,
          position: item.position,
          jobLevel: item.levelJob
        };
      })
      setAddStatus(responseDetail.status == true ? "Active" : "Inactive")
      setEditIdKaryawan(responseDetail.id)
      setEditDataKaryawan(payloadResponse)
      getDataFirstOrganization(responseDetail.memberOfGroups)
      setEditDataMember(dataDetailMember.length == 0 ? [...editDataMember, { "levelOrganizationId": "", "groupId": "", "position": "", "jobLevel": "" }] : [...dataDetailMember, { "levelOrganizationId": "", "groupId": "", "position": "", "jobLevel": "" }])

      setModalEdit(true)
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`));
      setModalEdit(false)
    })
  }

  const onChangeStatus = (event) => {
    setAddStatus(event.target.value)
  }

  /* modal */
  const handleClose = () => {
    setShowModal(false)
  }

  const handleCloseEdit = () => {
    setModalEdit(false)
    setEditIdKaryawan("")
    setEditDataKaryawan({})
    setEditDataMember([])
    setDataLevelOrganization([])
    setDataOrganization([])
  }

  /* navigate */
  const goToPage = () => {
    setSearch('')
    history.push('/karyawan/upload')
  }

  const goToPageManual = () => {
    setSearch('')
    history.push('/karyawan/add')
  }

  /* pagination */
  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /* action */
  const updateKaryawan = (data) => {
    const last_element = editDataMember.findLast((item) => true);
    let lengthEditDataMember = editDataMember.length
    if (lengthEditDataMember == 1) {
      // check objectnya
      if (!last_element.levelOrganizationId && !last_element.groupId && !last_element.position && !last_element.jobLevel) {
        toast.error((`${'Member of Group cannot be empty'}`));
      } else {
        updateActionKaryawan(data, editDataMember)
      }
    } else {
      // validasi last object
      if (!last_element.levelOrganizationId && !last_element.groupId && !last_element.position && !last_element.jobLevel) {
        editDataMember.splice(-1)
        updateActionKaryawan(data, editDataMember)
      } else {
        updateActionKaryawan(data, editDataMember)
      }
    }
  }

  const updateActionKaryawan = (objData, arrData) => {
    var newArrayData = arrData.map(el => ({ "groupId": el.groupId, "levelOrganizationId": el.levelevelOrganizationId, "position": el.position, "jobLevel": el.jobLevel.toString()}))
    if (objData.phonenumber <= 0) {
      toast.warn('Fill in the data Phone number correctly');
    } else {
      setLoading(true)
      const formData = new FormData();
      let payload = {
        id: editIdKaryawan,
        employeeNo: objData.employeeNo,
        fullName: objData.fullname,
        email: objData.email,
        phoneNumber: objData.phonenumber.toString(),
        joinDate: moment(objData.date).format('DD/MM/YYYY'),
        flagActive: addStatus == "Active" ? true : false,
        groups: newArrayData
      }
      formData.append("data", JSON.stringify(payload))
      axios.put('/services/uaadocservice/api/personal-identity/karyawan/save', formData).then(res => {
        toast.success('Success Edit Employee');
        handleCloseEdit()
        setLoading(false)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
        handleCloseEdit()
        setLoading(false)
      })
    }
  }

  const deleteKaryawan = () => {
    setLoadingDelete(true)
    axios.delete(`services/uaadocservice/api/personal-identity/karyawan/${iddelete}/delete`).then(res => {
      setShowModal(false)
      setIdDelete(null)
      setLoadingDelete(false)
      getListDataKaryawan()
      toast.success('Success Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // setInitialValue(null)
      setModalEdit(false)
      getListDataKaryawan()
    }).catch(err => {
      setLoadingDelete(false)
      toast.error('Failed Delete Employee', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // setInitialValue(null)
      setModalEdit(false)
      getListDataKaryawan()
    })
  }

  // member of groups
  const getLevelOrganization = () => {
    axios.get(`/services/uaadocservice/api/level-organization/get-all-active?levelOrganizationId=0`).then((res) => {
      const response = res.data
      const newLevel = response.map((obj, i) => ({ label: obj.levelOrganizationName, value: obj.id }));
      setDataLevelOrganization(newLevel)
    }).catch((err) => {
      throw new Error('getLevelOrganization')
    })
  }

  const getDataFirstOrganization = (dataMemberGroups) => {
    try {
      const fetchCarouselPromises = dataMemberGroups.map(value =>
        axios.get(`/services/uaadocservice/api/group/get-by-level-organization?levelOrganizationId=${value.levelOrganizationId}`)
      )
      Promise.all(fetchCarouselPromises).then((values) => {
        const firstDataOrganization = values.map((object: any, index) => (
          object.data.map(item => {
            return {
              label: item.parentGroupName,
              value: item.parentGroupId
            };
          })
        ))
        setDataOrganization(firstDataOrganization)
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* get Organization first load */
  const getOrganization = async (type, i, valLevelOrganization) => {
    await axios.get(`/services/uaadocservice/api/group/get-by-level-organization?levelOrganizationId=${valLevelOrganization}`).then((res) => {
      const response = res.data
      const newOrganization = response.map((obj, index) => ({ label: obj.parentGroupName, value: obj.parentGroupId }));
      const newArray = [...dataOrganization];
      newArray.splice(i, 1, newOrganization);
      setDataOrganization(newArray);
    }).catch((err) => {
      throw new Error('getOrganization')
    })
  }

  const addFormFields = () => {
    setEditDataMember([...editDataMember, { "levelOrganizationId": "", "groupId": "", "position": "", "jobLevel": "" }])
  }

  const handleSelect = async (type, i, e) => {
    let data = [...editDataMember];
    if (type == 'levelOrganizationId') {
      data[i].levelOrganizationId = e.value;
      getOrganization('handleSelect', i, e.value)
    }
    if (type == 'groupId') {
      data[i].groupId = e.value;
    }
    if (type == 'position') {
      data[i].position = e;
    }
    if (type == 'jobLevel') {
      if (e == "0") {
        toast.warn('Level organization must more than 0', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        data[i].jobLevel = e.slice(0, 5);
      }
    }
    setEditDataMember(data)
  }

  const removeFormFields = (i) => {
    let newFormValues = [...editDataMember];
    newFormValues.splice(i, 1);
    setEditDataMember(newFormValues)
    removeOrganization(i)
  }

  const removeOrganization = (i) => {
    let newDataOrganization = [...dataOrganization];
    newDataOrganization.splice(i, 1);
    setDataOrganization(newDataOrganization)
  }

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Data Karyawan</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">Mirae Asset DMS - Data Karyawan</h1>
          </div>
        </div>
      </div>
      <div className='row my-3'>
        <div className="col-12 col-md-3 col-lg-3 px-2">
          <div className='btn btn-primary w-100 py-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={(data) => goToPageManual()}>
            <UserAddKaryawanIcon /> Tambah Data Karyawan
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-3 px-2">
          <div className='btn btn-primary w-100 py-2' onClick={(data) => goToPage()}>
            <UploadDocumentListIcon /> Upload Data Karyawan
          </div>
        </div>
      </div>
      <div className='row my-3'>
        <div className="col-12 col-md-9 col-lg-9 px-2">
          <div className="app-search w-100 py-0 bg-white">
            <div className="position-relative">
              <input type="text"
                className="form-control bg-white py-3"
                placeholder="Search data karyawan"
                onChange={(e) => {
                  setPage(1)
                  setKeyword(e.target.value)
                  getListDataKaryawan()
                }} onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    setPage(1)
                    getListDataKaryawan()
                  }
                }} />
              <span className="bx bx-search-alt my-0"></span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-3 px-2">
          <Select
            placeholder={"Select Status"}
            defaultValue={{ label: "All Status", value: "all" }}
            options={dataStatus}
            onChange={(e) => setValActivated(e.value)}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-centered  mb-0">
              <thead className="thead-light">
                <tr>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('employeeNo')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Employee Number</span>
                      <span className='mt-1'>
                        {(sort == "employeeNo" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "employeeNo" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "employeeNo") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('fullName')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Full Name</span>
                      <span className='mt-1'>
                        {(sort == "fullName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "fullName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "fullName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('phoneNumber')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Phone Number</span>
                      <span className='mt-1'>
                        {(sort == "phoneNumber" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "phoneNumber" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "phoneNumber") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('email')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Email</span>
                      <span className='mt-1'>
                        {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('join_date')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Join Date</span>
                      <span className='mt-1'>
                        {(sort == "join_date" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "join_date" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "join_date") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('flag_active')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Status</span>
                      <span className='mt-1'>
                        {(sort == "flag_active" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "flag_active" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "flag_active") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='fix'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  listKaryawan ? listKaryawan.map((item, index) => {
                    return (
                      <tr>
                        <td className='py-2' style={{ wordBreak: 'break-all', minWidth: '200px' }}>{item.employeeNo ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '300px' }}>{item.fullName ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px', maxWidth: '200px' }}>{item.phoneNumber ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '300px' }}>{item.email ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '200px' }}>{moment(item.joinDate).format("YYYY-MM-DD")}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>
                          {
                            item?.status == "Active" ?
                              <span style={{ fontSize: '12px', background: '#43936C', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                Active
                              </span>
                              :
                              <span style={{ fontSize: '12px', background: '#CB3A31', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                Inactive
                              </span>
                          }
                        </td>
                        <td style={{ wordBreak: 'break-all', minWidth: '120px' }} className='fix'>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <button
                              className='btn mr-2'
                              style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                              onClick={() => getDetailEmployee(item.employeeNo)}
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}Edit
                            </button>
                            {/* <div style={{ cursor: 'pointer' }} className="mx-2" onClick={() => getDetailEmployee(item.employeeNo)}>
                              <EditBlue />
                            </div> */}
                            {/* <div style={{ cursor: 'pointer' }} className="mx-2">
                              <DeleteRed onClick={() => {
                                setShowModal(true)
                                setFullName(item.fullName)
                                setIdDelete(item.employeeNo)
                              }} />
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    )
                  }) : <></>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
          <div style={{ display: 'flex' }}>
            <span className="mt-3 mx-2">
              Show
            </span>
            <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                <SelectMui
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={size}
                  onChange={handleChange}
                  label="Limit"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </SelectMui>
              </FormControl>
            </span>
            <span className="mt-3 mx-2">
              Entries
            </span>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
          <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
        </div>
      </div>
      <Modal className="font-family-spoqa" isOpen={modalEdit} toggle={handleClose} size="xl" centered={true} style={{}}>
        <div className="my-4">
          <div className="px-5 pt-3" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                  <h1 className="mb-0 font-size-24">Edit Data Karyawan</h1>
                </div>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                </div>
              </div>
            </div>
            <div className="row">
              <Formik
                initialValues={{ employeeNo: editDataKaryawan?.employeeNo, date: moment(editDataKaryawan?.joinDate).toDate(), phonenumber: editDataKaryawan?.phoneNumber, nik: editDataKaryawan?.employeeNo, fullname: editDataKaryawan?.fullName, email: editDataKaryawan?.email }}
                validationSchema={KaryawanSchema}
                onSubmit={(values) => {
                  updateKaryawan(values)
                }}>
                {({ touched, errors, isSubmitting, values, setFieldValue }) =>
                (
                  <div>
                    <Form>
                      <div className="">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Nomor Identitas Karyawan (NIK)
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <Field
                              type="text"
                              name="nik"
                              disabled={true}
                              placeholder=""
                              autocomplete="off"
                              className={`mt-2 form-control py-2 ${touched.nik && errors.nik ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              component="div"
                              name="nik"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Full Name
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <Field
                              type="text"
                              name="fullname"
                              placeholder=""
                              autocomplete="off"
                              className={`mt-2 form-control py-2 ${touched.fullname && errors.fullname ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              component="div"
                              name="fullname"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Email
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <Field
                              type="email"
                              name="email"
                              placeholder=""
                              autocomplete="off"
                              className={`mt-2 form-control py-2 ${touched.email && errors.email ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Phone Number
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <Field
                              type="number"
                              name="phonenumber"
                              placeholder="Masukkan Phone Number"
                              autocomplete="off"
                              className={`mt-2 form-control py-2 ${touched.phonenumber && errors.phonenumber ? "is-invalid" : ""}`}
                            />
                            <ErrorMessage
                              component="div"
                              name="phonenumber"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Join Date
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">
                            <DatePicker
                              name="date"
                              //  format="yyyy-MM-dd"
                              selected={values.date}
                              onChange={(date: Date) => setFieldValue('date', date)}
                              className={`mt-2 form-control py-2 px-0 mx-0 ${touched.date && errors.date ? "is-invalid" : ""}`}
                            />
                            <div className="text-danger mt-3" style={{ fontSize: '10px' }}>
                              {touched.date && errors.date ? errors.date : ""}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-3" style={{ alignSelf: 'center' }}>
                            <div className='font-weight-bold' style={{ display: 'flex', alignSelf: 'center' }}>
                              Status
                              <span className="required" style={{ color: '#ff0000' }}>*</span>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-9">

                            <div className="row">
                              <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                                <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <input type="radio"
                                    checked={addStatus === "Active"}
                                    value={"Active"}
                                  />
                                  <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                                    Active
                                  </span>
                                </label>
                              </div>
                              <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                                <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <input type="radio"
                                    checked={addStatus === "Inactive"}
                                    value={"Inactive"}
                                  />
                                  <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                                    Inactive
                                  </span>
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 mt-3">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                              <h1 className="mb-0 font-size-16">
                                Member of Groups
                                <span className="required" style={{ color: '#ff0000' }}>*</span>
                              </h1>
                            </div>
                          </div>
                        </div>
                        {editDataMember.map((element, index) => {
                          let valLength = editDataMember.length - 1;
                          return (
                            <div key={index} className="row mb-2">
                              <div className="col-12 col-sm-12 col-md-3">
                                <Select
                                  name="group"
                                  value={dataLevelOrganization.filter((function (option: any) {
                                    return option.value == element.levelOrganizationId
                                  }))}
                                  placeholder="Level Organization"
                                  options={dataLevelOrganization}
                                  onChange={(e) => handleSelect('levelOrganizationId', index, e)}
                                />
                              </div>
                              <div className="col-12 col-sm-12 col-md-3">
                                <Select
                                  name="group"
                                  value={dataOrganization[index]?.filter((function (option: any) {
                                    return option.value == element.groupId
                                  }))}
                                  placeholder="Organization"
                                  options={dataOrganization[index]}
                                  onChange={(e) => handleSelect('groupId', index, e)}
                                />
                              </div>
                              <div className="col-12 col-sm-12 col-md-3">
                                <input
                                  className="form-control"
                                  placeholder="Position"
                                  value={element.position}
                                  onChange={(e) =>
                                    handleSelect('position', index, e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-12 col-sm-12 col-md-2">
                                <input
                                  type='number'
                                  className="form-control"
                                  placeholder="Job Level"
                                  value={element.jobLevel}
                                  onChange={(e) =>
                                    handleSelect('jobLevel', index, e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-12 col-sm-12 col-md-1">
                                {
                                  valLength == index ?
                                    <Button style={{ display: 'flex', alignSelf: 'center', backgroundColor: '#F37F26', border: '1px solid #EEB4B0' }} onClick={() => addFormFields()} className="mr-2">
                                      <span style={{ color: '#F5F5F5' }}>+</span>
                                    </Button>
                                    :
                                    <Button color='#CB3A31' style={{ display: 'flex', alignSelf: 'center', backgroundColor: '#CB3A31', border: '1px solid #EEB4B0' }} onClick={() => removeFormFields(index)} className="mr-2">
                                      <span style={{ color: '#F5F5F5' }}>-</span>
                                    </Button>
                                }
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <Row className='mb-4 mt-5'>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                        </Col>
                        <Col className='text-right' xs="12" sm="12" md="6" lg="6">
                          <div className='btn btn-primary px-5 mr-2' style={{ background: '#002F48', borderColor: '#002F48' }} onClick={() => handleCloseEdit()}>
                            {/* {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} */}
                            Cancel </div>
                          <button className='btn btn-primary px-5' type="submit" disabled={loading}>
                            {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                            Update </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )
                }
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={showModal} toggle={handleClose} size="md" style={{ marginTop: '15%' }}>
        <ModalHeader toggle={handleClose}>
          <div className="w-100">
            <h2 className="">Delete Confirmation</h2>
            <h3 style={{ fontSize: '12px' }}>{`Apakah anda yakin menghapus user ${fullname}`}</h3>
          </div>
        </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
            <Col lg={12} className="text-right">
              <Button color="secondary" onClick={handleClose} className="mr-2">
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success px-4" onClick={() => deleteKaryawan()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Karyawan);