import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import './announcement.scss';
import AccountAnnounceIcon from 'app/component/announ-icon';
import { toast } from 'react-toastify';
import ThreeDots from 'app/component/three-dots';
import sanitizeHtml from 'sanitize-html';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}

export const Annoucement = (props: IHeaderProps) => {
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [dataUser, setDataUser] = useState<any>(null)
  const [actionDrop, setActionDrop] = useState([])
  const [totalElements, setTotalElements] = useState<any>(0)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [limit, setLimit] = useState<any>(10)
  const [loading, setLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [isAdmin, setIsAdmin] = useState<Boolean>(false)
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false)
  let history = useHistory();
  let profileDefault = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  let thumbnailDefault = "https://s3-alpha-sig.figma.com/img/c251/bc3c/9a6143e99fcac281fd64c356d117caa9?Expires=1672617600&Signature=PlbxEqK3lP12ZzpZGxUFnpCJ-gBR61cFpVx20nWABJ3s3AmdZlfby2r1lnHNLYUxuBIZVM~rAy-78C0AjBb2Pra1UNXTgnU40oP0snoUt4OW2BFNpPSK5bNgUCu9Egmzhf4a6Etq~sS~-yZDX4uXStUJ07BrjnHvqStHsGmjlV0zRTgLW8NV0tJfmBslz3tbSrXlnhafUc7i4NyRvob~76sBIj4dNRiuDqQ4YOltEWHSFR13VhOpxyW7iEx2AYZfX~Jft7ro5wBWDdzTB0dXhRuNKgaZxuCOSDT-Wgq0ob9eqjgCsdh7R0X8Ct0VY4AfRenorB-p0jr1i8WG~JfK8w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";

  useEffect(() => {
    getIsAdmin()
    getIsAnnounce()
    getListAnnouncement(page, limit)
    getDataUser()
  }, []);

  const getIsAdmin = () => {
    axios.get('/services/uaadocservice/api/is-admin-dms')
      .then(res => {
        setIsAdmin(res.data)
      }).catch(err => {
        setIsAdmin(false)
      })
  }

  const getIsAnnounce = () => {
    axios.get('/services/uaadocservice/api/is-user-may-announce')
      .then(res => {
        setIsAnnounce(res.data)
      }).catch(err => {
        setIsAnnounce(false)
      })
  }

  const getDataUser = () => {
    axios.get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        setDataUser(res.data)
      }).catch((err) => {
        setDataUser(null)
      })
  }

  const getListAnnouncement = (page, limit) => {
    axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`)
      .then(res => {
        res.data.data?.content.map((data, i) => {
          setActionDrop(oldArray => [...oldArray, false]);
        })
        setListAnnouncement(res.data.data?.content)
        setTotalPage(res.data.data?.totalPages)
        setTotalElements(res.data.data?.totalElements)
        setLoading(true)
      })
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = profileDefault;
    event.currentTarget.onerror = null;
  }

  const imageOnErrorHandlerThumbnail = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = thumbnailDefault;
    event.currentTarget.onerror = null;
  }

  const updateDropdown = (index) => {
    let items = [...actionDrop];
    items[index] = !items[index];
    setActionDrop(items);
  }

  const handleClose = () => {
    setShowModal(false)
  };

  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }

  const deleteFile = (id) => {
    setLoadingDelete(true)
    axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
      setLoadingDelete(false)
      toast.success('Success Delete Annoucement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowModal(false)
      setItemSelected(null)
      setPage(1)
      setLimit(5)
      getListAnnouncement(1, 5)
    }).catch(err => {
      setLoadingDelete(false)
      toast.error('Failed Delete Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowModal(false)
    })
  }

  const goToCreate = () => {
    history.push('/announcement/create')
  }

  const goToDraft = () => {
    history.push('/announcement/draft')
  }

  const goToDetail = (data) => {
    axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`)
      .then((res) => {
        history.push(`/announcement/view/${data.id}`)
      }).catch((err) => {
        history.push(`/announcement/view/${data.id}`)
      })
  }

  const goToView = (id) => {
    history.push(`/announcement/edit/${id}`)
  }

  const fetchMoreData = () => {
    console.log("fetchMoreData")
    if (limit < totalElements) {
      var limitNew = limit + 10
      axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limitNew}`)
        .then(res => {
          setListAnnouncement(res.data.data?.content)
          setLimit(limitNew)
          setLoading(true)
        })
    } else {
      setLoading(false)
      // hasMore false
    }
  }

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Announcement</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">Mirae Asset Sekuritas Announcement Management</h1>
          </div>
        </div>
      </div>
      {
        isAdmin || isAnnounce ? <div className="btn btn-primary rounded-pill" onClick={() => goToCreate()}>
          <AccountAnnounceIcon className="mr-1" /> Create Announcement
        </div> : <></>
      }
      <div className="btn btn-primary rounded-pill ml-2" onClick={() => goToDraft()}>
        Draft Announcement
      </div>
      <div className="scroll" id="scrollableDiv" style={{ height: "800px", width: "100%", overflow: "auto", overflowX: "hidden", display: "flex", flexDirection: "column" }}>
        <InfiniteScroll
          dataLength={10}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column', width: "100%", overflow: "hidden" }} //To put endMessage and loader to the top.
          hasMore={loading}
          scrollableTarget="scrollableDiv">
          {
            listAnnouncement.map((item, index) => (
              <div key={index} className='mt-4' style={{ background: item.isRead ? "#F5F5F5" : "#FFF4EB", padding: '10px', width: "100%", borderBottom: '1px solid #9E9E9E' }} >
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                      <img className="rounded-circle header-profile-user-announcement text-center" onError={(e) => imageOnErrorHandler(e)} src={dataUser?.firstName == item.senderEmployeeName ? `data:image/png;base64, ${dataUser?.profilePicture}` : profileDefault}
                        alt="Header Avatar" />
                      <div className='mx-2'>
                        <p className="text-center mb-0 font-size-14">
                          {item.senderEmployeeName}
                        </p>
                        <p className='mb-0 font-size-12'>
                          {item.postedDateFormat}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column right" style={{ display: 'flex', justifyContent: "end", alignItems: 'center' }}>
                    {item.canEdit || item.canDelete ?
                      <div className="dropdown d-inline-block"  >
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => updateDropdown(index)}>
                          <ThreeDots />
                        </button>
                        <div className={`dropdown-menu dropdown-menu-right text-wrap ${actionDrop[index] ? "d-block" : ""} `} onClick={() => updateDropdown(index)}>
                          {item.canEdit ?
                            <div className="dropdown-item my-1 py-2" style={{ cursor: 'pointer' }} onClick={() => goToView(item.id)}>
                              Edit
                            </div>
                            : <></>
                          }
                          {item.canDelete ?
                            <div className="dropdown-item my-1 py-2" style={{ cursor: 'pointer' }} onClick={() => openModalDelete(item)}>
                              Delete
                            </div>
                            : <></>
                          }
                        </div>
                      </div>
                      : <></>}
                  </div>
                </div>
                <div className="mt-3" style={{ cursor: 'pointer' }} onClick={() => goToDetail(item)}>
                  {item.image ?
                    <img
                      className="mx-auto d-block"
                      src={`data:image/png;base64, ${item.image}`}
                      style={{ objectFit: "contain", width: "50%", height: "300px", borderRadius: '10px' }}
                    />
                    : ""}
                </div>
                <div className="mt-3 ml-3 mr-3" style={{ cursor: 'pointer' }} onClick={() => goToDetail(item)}>
                  <div className="font-size-20" style={{ textAlign: 'justify', fontWeight: 500, }}>
                    {item.title}
                  </div>
                  <div className="mt-2 font-size-14" style={{ fontWeight: 500, color: "#616161" }}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          deentitize(
                            item.fileContent
                          ),
                        ),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))
          }
        </InfiniteScroll>
      </div>
    </div>
  )
};

const deentitize = function (input) {
  var ret = input
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/<br\s*[\/]?>/gi, '<br><br>')
  return ret
}

export default Annoucement;
