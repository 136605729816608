import * as React from "react"
import {SVGProps} from "react"

const InfoIconTop = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.45231 9.66667H6.78564V7H6.11898M6.78564 4.33333H6.79231M12.7856 7C12.7856 10.3137 10.0994 13 6.78564 13C3.47194 13 0.785645 10.3137 0.785645 7C0.785645 3.68629 3.47194 1 6.78564 1C10.0994 1 12.7856 3.68629 12.7856 7Z"
            stroke="#9E9E9E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export default InfoIconTop