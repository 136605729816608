import * as React from "react"
import { SVGProps } from "react"

const ToActivityIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="0.214355" width="40" height="40" rx="20" fill="#FFF4EB" />
        <path d="M25.2143 10.5713H27.7857C28.2404 10.5713 28.6764 10.7519 28.9979 11.0734C29.3194 11.3949 29.5 11.8309 29.5 12.2856V29.4284C29.5 29.8831 29.3194 30.3191 28.9979 30.6406C28.6764 30.9621 28.2404 31.1427 27.7857 31.1427H14.0715C13.6168 31.1427 13.1808 30.9621 12.8593 30.6406C12.5378 30.3191 12.3572 29.8831 12.3572 29.4284V12.2856C12.3572 11.8309 12.5378 11.3949 12.8593 11.0734C13.1808 10.7519 13.6168 10.5713 14.0715 10.5713H16.6429" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.5 8.85742H18.3571C17.4103 8.85742 16.6428 9.62493 16.6428 10.5717V11.4289C16.6428 12.3756 17.4103 13.1431 18.3571 13.1431H23.5C24.4467 13.1431 25.2143 12.3756 25.2143 11.4289V10.5717C25.2143 9.62493 24.4467 8.85742 23.5 8.85742Z" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.6428 17.4287H25.2143" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.6428 21.7148H25.2143" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.6428 26H25.2143" stroke="#F37F26" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ToActivityIcon
