import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
// @ts-ignore
import Accordion from 'react-bootstrap/Accordion'

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    subAccountNoList?: any,
    date?: any
    priceDigit?: any
}

export const CreateNewTCPreview = (props: IHeaderProps) => {
    let history = useHistory();
    const [dataListTransactionDetailsPreview, setDataListTransactionDetailsPreview] = useState<any>([])

    const parameterSubAcc = props.location.state.subAccountNoList as IParams
    const parameterDate = props.location.state.date as IParams
    const parameterPriceDigit = props.location.state.priceDigit as IParams

    useEffect(() => {
        getListTransactionDetails();
    }, []);

    // get data
    const getListTransactionDetails = () => {
        let listAccountNo = parameterSubAcc
        let date = parameterDate
        let priceDigit = parameterPriceDigit
        axios.get(`services/consolidatedportfolioservice/api/asset-management/${date}/average-price?subAccountNumbers=${listAccountNo}&decimal=${priceDigit}`).then((res) => {
            setDataListTransactionDetailsPreview(res.data.data.content)
        }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    const generateTC = () => {
        let subAccountNoList = parameterSubAcc
        let date = parameterDate

        let prcDigit = parameterPriceDigit
        let payload = {
            subAccountNoList: subAccountNoList,
            date: date,
            priceDigit: prcDigit
        }
        history.push(`/investmentmanager/createnewtc/result`, payload)
    }

    const generateCsv = (tableID, tableID2, filename = '') => {
        const table = document.getElementById(tableID);
        const table2 = document.getElementById(tableID2);
        if (!table) {
            console.error(`Table with ID ${tableID} not found.`);
            return;
        }
        if (!table2) {
            console.error(`Table with ID ${tableID2} not found.`);
            return;
        }

        const rows = table.querySelectorAll('tbody tr');
        const rows2 = table2.querySelectorAll('tbody tr');
        const title = table.querySelectorAll('thead tr');

        if (rows.length === 0) {
            console.error(`No data found in the table with ID ${tableID}.`);
            return;
        }
        if (rows2.length === 0) {
            console.error(`No data found in the table with ID ${tableID2}.`);
            return;
        }

        let csvContent = "data:text/csv;charset=utf-8,";
        let csvContent2 = "data:text/csv;charset=utf-8,";

        title.forEach(row => {
            const columns = row.querySelectorAll('th');
            const rowData = Array.from(columns).map(column => column.innerText.toString());
            csvContent += rowData.join(',') + '\n';
        });

        rows.forEach(row => {   
            const columns = row.querySelectorAll('td');
            const rowData = Array.from(columns).map(column => column.innerText.toString().replaceAll(",", ""));
            csvContent += rowData.join(',') + '\n';
        });

        title.forEach(row => {
            const columns = row.querySelectorAll('th');
            const rowData = Array.from(columns).map(column => column.innerText.toString());
            csvContent += rowData.join(',') + '\n';
        });
        rows2.forEach(row => {
            const columns = row.querySelectorAll('td');
            const rowData = Array.from(columns).map(column => column.innerText.toString().replaceAll(",", ""));
            csvContent += rowData.join(',') + '\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
    };



    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start">
                        <button className="btn px-0" onClick={() => history.goBack()}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
                        </button>
                        <h1 className="font-family-spoqa mb-0 font-size-28 px-2">Preview</h1>
                    </div>
                </div>
            </div>


            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {dataListTransactionDetailsPreview?.map((item, index) => {
                    return <Accordion.Item className="bg-white my-4" style={{ backgroundColor: '#ffffff !important' }} eventKey={index} >
                        <Accordion.Header className="bg-white" style={{ backgroundColor: '#ffffff !important' }} >
                            <div className='row' style={{ wordWrap: 'break-word' }} >
                                <div className="col-xs-12 col-sm-9 col-md-1 mx-auto text-center">
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-12 mt-1">
                                    <span className='font-weight-bold ml-1' style={{ wordBreak: 'break-word' }}>{item?.SUB_ACCOUNT}</span>
                                </div>
                            </div>
                            <button onClick={() => generateCsv(`table-id-1-${index}`, `table-id-2-${index}`, `${item?.SUB_ACCOUNT}`)}>Export Table Data To CSV</button>

                        </Accordion.Header>
                        <Accordion.Body style={{ backgroundColor: 'white' }}>
                            <div className="py-2">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h1 className="font-family-spoqa mb-0 font-size-14 px-2 fw-bold">BUY INVOICE</h1>
                                </div>
                                <div className="table-responsive">
                                    <table id={`table-id-1-${index}`} className="table table-striped table-centered mb-2">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Gross Amount</th>
                                                <th>Broker Fee</th>
                                                <th>Levy</th>
                                                <th>WHT</th>
                                                <th>VAT</th>
                                                <th>PPH23</th>
                                                <th style={{ textAlign: 'right' }}>Net Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item?.BUY ? item?.BUY.map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td> */}
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.ITEM_CD}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PRICE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.QUANTITY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.GROSS_AMOUNT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.BROKER_FEE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.LEVY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.WHT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.VAT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PPH23}</td>
                                                            <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{el?.NET_AMOUNT}</td>
                                                        </tr>
                                                    )
                                                }) : <></>
                                            }
                                            {
                                                item?.TOTAL_BUY ?
                                                    <tr>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY ? 'Total' : ''}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.GROSS_AMOUNT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.BROKER_FEE}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.LEVY}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.WHT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.VAT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_BUY.PPH23}</td>
                                                        <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{item?.TOTAL_BUY.NET_AMOUNT}</td>
                                                    </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="py-2">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h1 className="font-family-spoqa mb-0 font-size-14 px-2 fw-bold">SELL INVOICE</h1>
                                </div>
                                <div className="table-responsive">
                                    <table id={`table-id-2-${index}`} className="table table-striped table-centered mb-2">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Gross Amount</th>
                                                <th>Broker Fee</th>
                                                <th>Levy</th>
                                                <th>WHT</th>
                                                <th>VAT</th>
                                                <th>PPH23</th>
                                                <th style={{ textAlign: 'right' }}>Net Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                item?.SELL ? item?.SELL.map((el, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td> */}
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.ITEM_CD}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PRICE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.QUANTITY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.GROSS_AMOUNT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.BROKER_FEE}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.LEVY}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.WHT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.VAT}</td>
                                                            <td style={{ wordBreak: 'break-all' }}>{el?.PPH23}</td>
                                                            <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{el?.NET_AMOUNT}</td>
                                                        </tr>
                                                    )
                                                }) : <></>
                                            }
                                            {
                                                item?.TOTAL_SELL ?
                                                    <tr>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}></td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL ? 'Total' : ''}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.GROSS_AMOUNT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.BROKER_FEE}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.LEVY}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.WHT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.VAT}</td>
                                                        <td style={{ wordBreak: 'break-all' }}>{item?.TOTAL_SELL.PPH23}</td>
                                                        <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{item?.TOTAL_SELL.NET_AMOUNT}</td>
                                                    </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                })
                } : <></>
            </Accordion>
            <div className="row justify-content-end font-family-spoqa">
                <div className="col-12 col-md-3 col-lg-3">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <button className='btn btn-primary btn-lg' onClick={() => generateTC()}><span className="bx bx-check fa-lg my-0"></span>Confirm & Generate TC
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTCPreview);