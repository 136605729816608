export const typeDocument = [{
  label: "Release Approval",
  value: "RELEASE_APPROVAL"
}
]

export const priorityDocument = [
  {
      label: "High",
      value: "1"
  },
  {
      label: "Medium",
      value: "2"
  },
  {
      label: "Low",
      value: "3"
  },
]

export const typeApplication = [
  {
      label: "New",
      value: "1"
  },
  {
      label: "Existing",
      value: "2"
  }
]