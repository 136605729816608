import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconCalendar from "app/component/icon-calendar";
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export const Area = (props: IHeaderProps) => {
    const initialFormData = {
        listInvestManagement: null,
        selectInvestManagement: null,
        start_date: null,
        end_date: null,
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("im_name")
    // const [sort, setSort] = useState<any>("levelOrganizationName")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListOfGenerateTC, setDataListOfGenerateTC] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
    const [dataInvestmentMagement, setDataInvestmentMagement] = useState<any>([])
    const [dataOrganization, setDataOrganization] = useState<any>([])
    const [selectedStartDate, setSelectedStartDate] = useState(new Date())
    const [selectedEndDate, setSelectedEndDate] = useState(new Date())

    // const parameter = props.location.state.key as IParams

    useEffect(() => {
        getListAssetManagement()
        getListGenerateTC()
    }, [page, size, totalPage, keyword, formData, sort, ascending]);

    // get data
    const getListGenerateTC = () => {
        let code = !formData.listInvestManagement ? "" : formData.listInvestManagement
        let startDate = !formData.start_date ? "" : Number(moment(formData.start_date).format("YYYYMMDD"))
        let endDate = !formData.end_date ? "" : Number(moment(formData.end_date).format("YYYYMMDD"))
        axios.get(`services/consolidatedportfolioservice/api/asset-management/history/list?investmentManagerCode=${code}&startDate=${startDate}&endDate=${endDate}&search=${keyword}&page=${page}&size=${size}`).then((res) => {
            setDataListOfGenerateTC(res.data.data.content)
            setTotalPage(res.data.data.totalPages)
        }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    const getListAssetManagement = () => {
        axios.get(`services/consolidatedportfolioservice/api/asset-management/by-account-manager?page=0`).then((res) => {
            const response = res.data.data.content
            const newInvestManagement = response.map((obj, i) => ({ label: obj.INVESTMENT_MANAGER_NAME, value: obj.INVESTMENT_MANAGER_CODE }));
            let addInvestManagement = [{ label: "All", value: 0 }, ...newInvestManagement];
            setDataInvestmentMagement(addInvestManagement)
        }).catch((err) => {
            throw new Error('getInvestManagement')
        })
    }

    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    /* handle select */
    const handleInput = (name, value) => {
        if (name == 'listInvestManagement') {
            setFormData({ ...formData, selectInvestManagement: null })
            setFormData({
                ...formData,
                [name]: value.value,
            })
            // getListAssetManagement()
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
        }
    }

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-28">Area/Lokasi</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Your Dashboard to Employee Recruitment Excellence</h1>
                    </div>
                </div>
            </div>
            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="col-10 col-md-8 col-lg-10">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-white py-3" value={keyword} placeholder="Cari Account" onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        getListAssetManagement()
                                    } else {
                                        setKeyword(e.target.value)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getListAssetManagement()
                                    }
                                }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-md-4 col-lg-2">
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-12">
                            <Select
                                name="im"
                                defaultValue={{ label: "All", value: 0 }}
                                value={dataInvestmentMagement.filter((function (option: any) {
                                    return option.value == formData.listInvestManagement
                                }))}
                                placeholder="Select Status"
                                options={dataInvestmentMagement}
                                onChange={(e) => handleInput('listInvestManagement', e)}
                            />
                        </div>

                        {/* <div className="col-12 col-md-3 col-lg-6">
                            <div className="row">
                                <div className="col-12 col-md-3 col-lg-6">
                                    <div className="position-relative">
                                        <DatePicker
                                            id={"startDate"}
                                            placeholderText="Select Start Date"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={new Date()}
                                            selected={formData.start_date}
                                            className={"form-control"}
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            showYearDropdown
                                            onChange={(val) => {
                                                setFormData({
                                                    ...formData,
                                                    start_date: val
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-6">
                                    <div className="position-relative">
                                        <DatePicker
                                            id={"endDate"}
                                            placeholderText="Select End Date"
                                            dateFormat="yyyy-MM-dd"
                                            minDate={formData.start_date}
                                            maxDate={new Date()}
                                            selected={formData.end_date}
                                            className={"form-control"}
                                            scrollableYearDropdown
                                            showMonthDropdown
                                            showYearDropdown
                                            onChange={(val) => {
                                                setFormData({
                                                    ...formData,
                                                    end_date: val
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="card font-family-spoqa">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered mb-0">
                            <thead className="thead-light">
                                <tr>
                                    {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('levelOrganizationName')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Level Group</span>
                                            <span className='mt-1'>
                                                {(sort == "levelOrganizationName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "levelOrganizationName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "levelOrganizationName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th> */}
                                    <th>No</th>
                                    <th>Candidate Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Applied Positions</th>
                                    <th>Status</th>
                                    <th style={{ textAlign: 'end' }}>Action</th>
                                    {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('parentGroupName')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Parent Group</span>
                                            <span className='mt-1'>
                                                {(sort == "parentGroupName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "parentGroupName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "parentGroupName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th> */}
                                    {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('groupName')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Group</span>
                                            <span className='mt-1'>
                                                {(sort == "groupName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "groupName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "groupName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th> */}
                                    {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('fullName')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Approvers</span>
                                            <span className='mt-1'>
                                                {(sort == "fullName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "fullName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "fullName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th> */}
                                    {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('position')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Position</span>
                                            <span className='mt-1'>
                                                {(sort == "position" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "position" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "position") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataListOfGenerateTC ? dataListOfGenerateTC.map((item, index) => {
                                        // [
                                        //     { imCode: "111111", imName: "Syalendra Capital", subAccName: "Syalendra Capital", trxDate: "21 Agustus 2023" },
                                        //     { imCode: "111112", imName: "Bahana Sekuritas", subAccName: "Bahana Sekuritas", trxDate: "21 Agustus 2023" },
                                        //     { imCode: "111113", imName: "DBS", subAccName: "DBS", trxDate: "21 Agustus 2023" },
                                        // ].map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{'Nama'}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{'Name@mail.com'}</td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    +62 899 2510 912
                                                </td>
                                                <td style={{ wordBreak: 'break-all' }}>{'Position of Applied'}</td>
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    Status(New/Psikotest/Rejected)
                                                </td>
                                                <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>
                                                    {/* <button className='btn btn-primary my-n2' onClick={() => {
                                                        history.push(`/investmentmanager/historygeneratetc/result`, { key: item?.id })
                                                    }}> Download
                                                    </button> */}
                                                    Detail
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div><div className="row mt-4 font-family-spoqa">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">Show</span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Area);