import './header.scss';

import '../../../../../../../scss/app.min.scss'

// import avatar1 from '../../../../../../../images/users/avatar-5.jpg';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import {
  Navbar,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  Container,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, BrandIcon } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import SignatureIcon from 'app/component/signature-icon';
import {logout, logoutKong} from 'app/shared/reducers/authentication';
import LogoutIcon from 'app/component/logout-icon';
import { toast } from 'react-toastify';
import HambIcon from 'app/component/bottomnav/HambIcon';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import ClearSearch from 'app/component/clear-search';
import axios from 'axios'

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  logoutKong?: any,
  location?: Location,
  openMenu?: () => void,
  resetTime?: () => void,
  history?: any,
}

interface Location {
  state?: DataState
}

interface DataState {
  search?: any
}

const HeaderMobileNew = (props: IHeaderProps) => {
  let history: any = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [profiledrop, setProfileDrop] = useState(false);
  const [notifdrop, setNotifDrop] = useState(false);
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [showList, setShowList] = useState(false)



  const setDropdown = () => {
    setProfileDrop(!profiledrop)
  }

  const setDropdownNotif = () => {
    setNotifDrop(!notifdrop)
  }

  const handleLogout = async () => {
    Storage.local.set("isLoggedInDMS", "false")
      await props.logoutKong()

    await props.resetTime()
    await history.replace('/')
    await delayTime(2000)
    await toast.success('Berhasil Logout', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    Storage.session.remove(`termandconditions`);
  }


  const getSearch = async (e) => {
    if (e.length > 1) {
      setLoadingList(true)
      if (history.location.pathname != '/search') {
        setShowList(true)
      } else {
        setShowList(false)
      }
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=1&size=3`)
        .then(async (result) => {
          setListSearch(result.data.content)
          await delayTime(2000)
          setLoadingList(false)
        }).catch(err => {
          setListSearch([])
          setLoadingList(false)
        })
    } else if (e.length == 0) {

      if (history.location.pathname == '/search') {

        history.push('/search', { search: '' })
        setShowList(false)
        setSearch('')
      } else {
        setSearch('')
      }
    }
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };



  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      history.push('/search', { search: search })
      setShowList(false)
    }
  }

  const clearSearch = () => {
    if (history.location.pathname == '/search') {
      history.push('/search', { search: '' })
      setShowList(false)
      setSearch('')
    } else {
      setSearch('')
    }
  }

  useEffect(() => {
    if (history.location.state?.search) {
      setSearch(history.location.state?.search)
    }
  }, []);


  const goToPage = (id) => {
    setSearch('')
    history.replace(`/preview/only/pdf/${id}`)
  }


  const liveSearch = (e) => {
    console.log("searchLive", e, props.account.employeeNo)
    if (e != props.account.employeeNo) {
      setSearch(e)
      getSearch(e)
    }
  }

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header" className="pb-3" style={{ backgroundColor: '#F5F5F5 !important' }}>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      {/* <Navbar dark expand="sm" fixed="top" className="dms-navbar"> */}
      <header id="page-topbar">
        <div className="navbar-header px-2" style={{ backgroundColor: '#F5F5F5 !important' }}>
          <div className="d-flex" style={{ flexGrow: 1 }} onClick={() => props.openMenu()}>
            <HambIcon />
          </div>
          <div className="d-flex" style={{ flexGrow: 10 }}>
            <div className="app-search w-100">
              {/* <div className="position-relative">
                <input type="text" className="form-control" value={search} placeholder="Search Document ..." onChange={(e) => liveSearch(e.target.value)} onKeyDown={(e) => handleSearch(e)} />
                <span className="bx bx-search-alt"></span>
                {
                  search ? <div style={{ position: 'absolute', top: '20%', right: '0.5%' }} onClick={() => clearSearch()}>
                    <ClearSearch />
                  </div> : <></>
                }
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  logout, logoutKong
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobileNew)

