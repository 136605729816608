import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocument, defaultValue } from 'app/shared/model/document.model';

export const ACTION_TYPES = {
  //   SEARCH_DOCUMENTS: 'document/SEARCH_DOCUMENTS',
  //   FETCH_DOCUMENT_LIST: 'document/FETCH_DOCUMENT_LIST',
  //   FETCH_DOCUMENT_LIST_APPROVAL: 'document/FETCH_DOCUMENT_LIST_APPROVAL',
  //   FETCH_DOCUMENT_LIST_ACKNOWLEDGE: 'document/FETCH_DOCUMENT_LIST_ACKNOWLEDGE',
  //   FETCH_DOCUMENT_LIST_SURAT_MASUK: 'document/FETCH_DOCUMENT_LIST_SURAT_MASUK',
  //   FETCH_DOCUMENT_LIST_SURAT_KELUAR: 'document/FETCH_DOCUMENT_LIST_SURAT_KELUAR',
  //   FETCH_DOCUMENT_DETAIL: 'document/FETCH_DOCUMENT_DETAIL',
  //   FETCH_DOCUMENT: 'document/FETCH_DOCUMENT',
  FETCH_RESOURCE_OPTION: 'document/FETCH_RESOURCE_OPTION',
  FETCH_TYPE_WATCHLIST: 'document/FETCH_TYPE_WATCHLIST',
  FETCH_REPORT_INDICATOR: 'document/FETCH_REPORT_INDICATOR',
  //   CREATE_DOCUMENT: 'document/CREATE_DOCUMENT',
  //   UPDATE_DOCUMENT: 'document/UPDATE_DOCUMENT',
  //   DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
  //   RESET: 'document/RESET',
  //   OPENPREVIEW: 'document/previewsign',
  //   APPROVE_DOCUMENT: 'document/approve',
  //   REJECT_DOCUMENT: 'document/reject',
  //   VIEW_DOCUMENT_SIGN: 'document/viewsign',
  //   SEND_DOCUMENT: 'document/SEND_DOCUMENT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  dataOptionResource: {} as any,
  dataOptionsTypeWatchlist: {} as any,
  dataOptionsReportIndicator: {} as any,
  //   entities: [] as ReadonlyArray<IDocument>,
  //   documentDashboardList: [] as ReadonlyArray<IDocument>,
  //   documentListAcknowledge: [] as ReadonlyArray<IDocument>,
  //   documentListSuratMasuk: [] as ReadonlyArray<IDocument>,
  //   documentListSuratKeluar: [] as ReadonlyArray<IDocument>,
  //   entity: defaultValue,
  //   updating: false,
  //   updateSuccess: false,
  //   totalApproval: 0,
  //   totalAcknowledge: 0,
  //   totalSuratMasuk: 0,
  //   totalSuratKeluar: 0,
  //   totalPageApproval: 0,
  //   totalPageAcknowledge: 0,
  //   totalPageKotakMasuk: 0,
  //   totalPageKotakKeluar: 0,
  //   loadingdetail: false,
  //   detaildocument: [],
  //   loadingapprove: false,
  //   loadingreject: false,
  //   filePDFsign: null,
  //   acknowledgelist: [],
  //   fileUrlBlob: null,
  //   fileBlobPreview: null
};

export type AmlState = Readonly<typeof initialState>;

// // Reducer

export default (state: AmlState = initialState, action): AmlState => {
  switch (action.type) {
    //     case REQUEST(ACTION_TYPES.SEARCH_DOCUMENTS):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
    case REQUEST(ACTION_TYPES.FETCH_RESOURCE_OPTION):
    case REQUEST(ACTION_TYPES.FETCH_TYPE_WATCHLIST):
    case REQUEST(ACTION_TYPES.FETCH_REPORT_INDICATOR):
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT):
    //     case REQUEST(ACTION_TYPES.OPENPREVIEW):
    //     case REQUEST(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
    //       return {
    //         ...state,
    //         errorMessage: null,
    //         updateSuccess: false,
    //         loading: true,
    //       };
    //     case SUCCESS(ACTION_TYPES.VIEW_DOCUMENT_SIGN):
    //       const filepdf = new Blob([action.payload.data], { type: "application/pdf" });
    //       return {
    //         ...state,
    //         fileBlobPreview: filepdf,
    //       }
    //     case REQUEST(ACTION_TYPES.APPROVE_DOCUMENT):
    //       return {
    //         ...state,
    //         loadingapprove: true
    //     };
    //     case SUCCESS(ACTION_TYPES.APPROVE_DOCUMENT): 
    //       return {
    //           ...state,
    //           loadingapprove: false
    //       }
    //     case FAILURE(ACTION_TYPES.APPROVE_DOCUMENT):
    //       return {
    //         ...state,
    //         loadingapprove: false
    //       }
    //     case REQUEST(ACTION_TYPES.REJECT_DOCUMENT): 
    //       return {
    //         ...state,
    //         loadingreject: true
    //       }
    //     case SUCCESS(ACTION_TYPES.REJECT_DOCUMENT):
    //       return {
    //         ...state,
    //         loadingreject: false
    //       }
    //     case FAILURE(ACTION_TYPES.REJECT_DOCUMENT): 
    //       return {
    //         ...state,
    //         loadingreject: false
    //       }
    //     case REQUEST(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
    //       return {
    //           ...state,
    //           loadingdetail: true
    //     };
    //     case REQUEST(ACTION_TYPES.CREATE_DOCUMENT):
    //     case REQUEST(ACTION_TYPES.UPDATE_DOCUMENT):
    //     case REQUEST(ACTION_TYPES.DELETE_DOCUMENT):
    //       return {
    //         ...state,
    //         errorMessage: null,
    //         updateSuccess: false,
    //         updating: true,
    //       };
    //     case FAILURE(ACTION_TYPES.SEARCH_DOCUMENTS):
    //     case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    //     case FAILURE(ACTION_TYPES.FETCH_DOCUMENT):
    //     case FAILURE(ACTION_TYPES.CREATE_DOCUMENT):
    //     case FAILURE(ACTION_TYPES.UPDATE_DOCUMENT):
    case FAILURE(ACTION_TYPES.FETCH_RESOURCE_OPTION):
      // console.log("props FETCH_SEARCH_MANUAL Error", action)
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_TYPE_WATCHLIST):
      // console.log("props FETCH_TYPE_WATCHLIST Error", action)
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.FETCH_REPORT_INDICATOR):
      // console.log("props FETCH_TYPE_WATCHLIST Error", action)
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    //     case FAILURE(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
    //         return {
    //             ...state,
    //             loadingdetail: false
    //     };
    //     case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_DETAIL):
    //       return {
    //           ...state,
    //           detaildocument: action.payload.data.data,
    //           acknowledgelist: action.payload.data.data.acknowledge,
    //           loadingdetail: false
    //   };
    //   case SUCCESS(ACTION_TYPES.OPENPREVIEW):
    //     // window.open("data:application/pdf," + encodeURI(action.payload.data));
    //     const file = new Blob([action.payload.data], { type: "application/pdf" });
    //         //Build a URL from the file
    //         const fileURL = URL.createObjectURL(file);
    //         //Open the URL on new Window
    //          const pdfWindow = window.open();
    //          pdfWindow.location.href = fileURL;     
    //     return {
    //       ...state,
    //       filePDFsign: file
    //     }
    //     case SUCCESS(ACTION_TYPES.SEARCH_DOCUMENTS):
    //     case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST):
    //       return {
    //         ...state,
    //         loading: false,
    //         entities: action.payload.data,
    //       };
    //   case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_APPROVAL):
    //     return {
    //       ...state,
    //       loading: false,
    //       documentDashboardList: action.payload.data.data.content,
    //       totalApproval: action.payload.data.data.totalElements,
    //       totalPageApproval: action.payload.data.data.totalPages
    //     };
    //     case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_ACKNOWLEDGE):
    //       return {
    //         ...state,
    //         loading: false,
    //         documentListAcknowledge: action.payload.data.data.content,
    //         totalAcknowledge: action.payload.data.data.totalElements,
    //         totalPageAcknowledge: action.payload.data.data.totalPages
    //       };
    //       case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_MASUK):
    //         return {
    //           ...state,
    //           loading: false,
    //           documentListSuratMasuk: action.payload.data.data.content,
    //           totalSuratMasuk: action.payload.data.data.totalElements,
    //           totalPageKotakMasuk: action.payload.data.data.totalPages
    //       };
    //       case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT_LIST_SURAT_KELUAR):
    //         return {
    //           ...state,
    //           loading: false,
    //           documentListSuratKeluar: action.payload.data.data.content,
    //           totalSuratKeluar: action.payload.data.data.totalElements,
    //           totalPageKotakKeluar: action.payload.data.data.totalPages
    //       };
    //     case SUCCESS(ACTION_TYPES.FETCH_DOCUMENT):
    //       return {
    //         ...state,
    //         loading: false,
    //         entity: action.payload.data,
    //       };
    case SUCCESS(ACTION_TYPES.FETCH_RESOURCE_OPTION):
      // console.log("props asdasd", action.payload.data.data)
      var obj = action.payload.data.data
      const arrayResourceOptions = [];
      for (const key in obj) {
        arrayResourceOptions.push({ label: key, value: obj[key] })
      }
      return {
        ...state,
        loading: false,
        dataOptionResource: arrayResourceOptions
      };

    case SUCCESS(ACTION_TYPES.FETCH_TYPE_WATCHLIST):
      // console.log("props FETCH_TYPE_WATCHLIST", action.payload.data.data)
      var obj = action.payload.data.data
      const arrayTypeWatchlist = [];
      for (const key in obj) {
        arrayTypeWatchlist.push({ label: key, value: key })
      }
      return {
        ...state,
        loading: false,
        dataOptionsTypeWatchlist: arrayTypeWatchlist
      };
    case SUCCESS(ACTION_TYPES.FETCH_REPORT_INDICATOR):
      var obj = action.payload.data.data
      const arrayReportIndicator = [];
      for (const key in obj) {
        arrayReportIndicator.push({ kode_indicator: obj[key], action_select: false, indicator_laporan: key })
      }
      const arraySortReportIndicator = arrayReportIndicator.sort(function (a, b) {
        return a.kode_indicator.localeCompare(b.kode_indicator); //a.kode_indicator - b.kode_indicator;
      });
      return {
        ...state,
        loading: false,
        dataOptionsReportIndicator: arraySortReportIndicator
      };
    //     case SUCCESS(ACTION_TYPES.CREATE_DOCUMENT):
    //     case SUCCESS(ACTION_TYPES.UPDATE_DOCUMENT):
    //       return {
    //         ...state,
    //         updating: false,
    //         updateSuccess: true,
    //         entity: action.payload.data,
    //       };
    //     case SUCCESS(ACTION_TYPES.DELETE_DOCUMENT):
    //       return {
    //         ...state,
    //         updating: false,
    //         updateSuccess: true,
    //         entity: {},
    //       };
    //     case ACTION_TYPES.RESET:
    //       return {
    //         ...initialState,
    //       };
    default:
      return state;
  }
};

// export const getSearchManualAml = (data) => {
//   return {
//     type: ACTION_TYPES.FETCH_SEARCH_MANUAL,
//     payload: axios.post(searchManualUrl, data),
//   }
// }

export const optionResources = (data, id) => {
  const payloadData = {
    datas: {
      lookup: "sumber_informasi"
    }
  }
  return {
    type: ACTION_TYPES.FETCH_RESOURCE_OPTION,
    payload: axios.post<any>(`/services/amlservices/api/v1/lookup/get`, payloadData),
  }
}

export const watchlistType = (data, id) => {
  const payloadData = {
    datas: {
      lookup: "jenis_watchlist"
    }
  }
  return {
    type: ACTION_TYPES.FETCH_TYPE_WATCHLIST,
    payload: axios.post<any>(`/services/amlservices/api/v1/lookup/get`, payloadData),
  }
}
export const optionReportIndicator = (data, id) => {
  const payloadData = {
    datas: {
      lookup: "indicator_laporan"
    }
  }
  return {
    type: ACTION_TYPES.FETCH_REPORT_INDICATOR,
    payload: axios.post<any>(`/services/amlservices/api/v1/lookup/get`, payloadData),
  }
}

// export const getSearchManualAml: ICrudPutAction<IDocument> = entity => async dispatch => {
//     const result = await dispatch({
//       type: ACTION_TYPES.FETCH_SEARCH_MANUAL,
//       payload: axios.post(searchManualUrl, cleanEntity(entity)),
//     });
//     return result;
//   };

// export const getDeptList = (sort) => ({
//     type: ACTION_TYPES.FETCH_DEPARTMENT,
//     payload: axios.get<IDocument>(`${deptlisturl}`),
// });

// export const getEntity: ICrudGetAction<IDocument> = id => {
//   const requestUrl = `${apiUrl}/${id}`;
//   return {
//     type: ACTION_TYPES.FETCH_DOCUMENT,
//     payload: axios.get<IDocument>(requestUrl),
//   };
// };

// export const createEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
//   const result = await dispatch({
//     type: ACTION_TYPES.CREATE_DOCUMENT,
//     payload: axios.post(apiUrl, cleanEntity(entity)),
//   });
//   dispatch(getEntities());
//   return result;
// };

// export const sendNewDocument: ICrudPutAction<IDocument> = entity => async dispatch => {
//   const requestUrl = `/services/documentservice/api/documents/send_document_new?id=`;
//   const result = await dispatch({
//     type: ACTION_TYPES.CREATE_DOCUMENT,
//     payload: axios.put(requestUrl, cleanEntity(entity)),
//   });
//   dispatch(getEntities());
//   return result;
// };

// export const saveDocumentToDraft: ICrudPutAction<IDocument> = entity => async dispatch => {
//   const requestUrl = `/services/documentservice/api/documents/save_as_draft?id=`;
//   const result = await dispatch({
//     type: ACTION_TYPES.CREATE_DOCUMENT,
//     payload: axios.put(requestUrl, cleanEntity(entity)),
//   });
//   dispatch(getEntities());
//   return result;
// };


// export const updateEntity: ICrudPutAction<IDocument> = entity => async dispatch => {
//   const result = await dispatch({
//     type: ACTION_TYPES.UPDATE_DOCUMENT,
//     payload: axios.put(apiUrl, cleanEntity(entity)),
//   });
//   return result;
// };

// export const deleteEntity: ICrudDeleteAction<IDocument> = id => async dispatch => {
//   const requestUrl = `${apiUrl}/${id}`;
//   const result = await dispatch({
//     type: ACTION_TYPES.DELETE_DOCUMENT,
//     payload: axios.delete(requestUrl),
//   });
//   dispatch(getEntities());
//   return result;
// };

// export const reset = () => ({
//   type: ACTION_TYPES.RESET,
// });
