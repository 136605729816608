import * as React from "react"
import { SVGProps } from "react"
import { colors } from "react-select/dist/declarations/src/theme"

const HistoryNav = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.25 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2Z" fill={props.color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.25 3a2 2 0 0 1 2-2 3 3 0 0 0 3 3h2a3 3 0 0 0 3-3 2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2V3Zm3 4a1 1 0 0 0 0 2h.01a1 1 0 1 0 0-2h-.01Zm3 0a1 1 0 0 0 0 2h3a1 1 0 1 0 0-2h-3Zm-3 4a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Zm3 0a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z"
      fill={props.color}
    />
  </svg>
)

export default HistoryNav
