import * as React from "react"
import { SVGProps } from "react"

const NotifNav = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.75 0a6 6 0 0 0-6 6v3.586l-.707.707A1 1 0 0 0 1.75 12h12a1 1 0 0 0 .707-1.707l-.707-.707V6a6 6 0 0 0-6-6ZM7.75 16a3 3 0 0 1-3-3h6a3 3 0 0 1-3 3Z"
      fill={props.color}
    />
  </svg>
)

export default NotifNav
