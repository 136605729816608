import React, { useEffect, useState, ChangeEvent } from 'react';
import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import './styles.scss';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { translate } from 'react-jhipster';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import { Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment-timezone';
import { apiSearchUpload, apiSearchManual, apiExportDownload } from 'app/config/api-collection';
import { list, selectOption, filterOption } from "./masterdatacomponent";
import {
    optionResources,
    watchlistType,
    optionReportIndicator
} from './aml.reducer';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

const initialFormData = {
    selectFilter: 0,
    matchFilter: 0,
    namaAsli: "",
    noIndetitas: "",
    tempatLahir: "",
    tanggalLahir: null,
    selectMatch: 0,
    sminValue: 30,
    smaxValue: 100
}

export const AntiMoneyLaundry = (props) => {

    let history = useHistory();
    const [page, setPage] = useState(1)
    const [size, setSize] = useState<any>(5)
    const [totalPage, setTotalPage] = useState(1)
    const [selectedFile, setSelectedFile] = useState(null)
    const [formData, setFormData] = useState(initialFormData)
    const [dataTableAll, setDataTableAll] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [transactionStatus, setTransactionStatus] = useState<any>("Include")
    const [incidentStatus, setIncidentStatus] = useState<any>(false)
    const [disabledIncident, setDisabledIncident] = useState<any>(false)
    const [loading, setLoading] = useState<any>(false)
    const [modalIndicator, setModalIndicator] = useState(false)
    const [dataIndicatorReport, setDataIndicatorReport] = useState([])
    const [sumberId, setSumberId] = useState<any>("")


    useEffect(() => {
        props.optionResources()
        props.watchlistType()
        props.optionReportIndicator()
    }, [])

    const { dataOptionResource, dataOptionsTypeWatchlist } = props;

    const updateDataIndicatorReport = () => {
        if (dataIndicatorReport.length == 0) {
            if (props.dataOptionsReportIndicator.length) {
                setDataIndicatorReport(props.dataOptionsReportIndicator)
            }
        }
    }

    const clearTable = () => {
        setPage(1)
        setSize(5)
        setTotalPage(1)
        setDataTable([])
        setDataTableAll([])
    }

    const searchUploadOrManual = (e) => {
        handleInput('selectFilter', e)
        setIncidentStatus(false)
        setSumberId("")
    }

    /* checked data table */
    const selectAll = () => {
        const tempData = dataTableAll.map(el => {
            if (transactionStatus == "Include") {
                if (el.is_transacted == "1") {
                    return { ...el, checked: !el.checked };
                }
            } else {
                return { ...el, checked: !el.checked };
            }
            return el
        });
        const updateData = dataTable.map(el => {
            if (transactionStatus == "Include") {
                if (el.is_transacted == "1") {
                    return { ...el, checked: !el.checked };
                }
            } else {
                return { ...el, checked: !el.checked };
            }
            return el
        });
        setDataTable(updateData)
        setDataTableAll(tempData);
    }

    const checkOne = id => {
        if (incidentStatus == false) {
            const tempDataAll = dataTableAll.map(el => {
                if (el.account_no === id) {
                    return { ...el, checked: !el.checked };
                }
                return el;
            })
            const tempData = dataTable.map(el => {
                if (el.account_no === id) {
                    return { ...el, checked: !el.checked };
                }
                return el;
            })
            setDataTable(tempData)
            setDataTableAll(tempDataAll)
        } else {
            const tempDataAll = dataTableAll.map(el => {
                if (el.account_no === id) {
                    return { ...el, checked: !el.checked };
                }
                if (el.account_no != id) {
                    return { ...el, disabled: !el.disabled };
                }
                return el;
            })
            const tempData = dataTable.map(el => {
                if (el.account_no === id) {
                    return { ...el, checked: !el.checked };
                }
                if (el.account_no != id) {
                    return { ...el, disabled: !el.disabled };
                }
                return el;
            })
            setDataTable(tempData)
            setDataTableAll(tempDataAll)
        }
    }

    const countType = (val) => {
        const countTypes = dataTableAll.filter(el => el.checked === val);
        return countTypes.length;
    }

    /* select search */
    const handleInput = (name, value) => {
        if (value.label == "Upload") {
            setFormData({
                ...formData,
                [name]: value.value,
                matchFilter: 0,
                namaAsli: "",
                noIndetitas: "",
                tempatLahir: "",
                tanggalLahir: null,
                selectMatch: 0,
                sminValue: 30,
                smaxValue: 100
            })
            setTransactionStatus("Include")
            clearTable()
        } else {
            setFormData({
                ...formData,
                [name]: value.value,
                matchFilter: 0,
                selectMatch: 0,
                sminValue: 30,
                smaxValue: 100
            })
            setTransactionStatus("Include")
            clearTable()
        }
    }

    const handleMatch = (val) => {
        setFormData({ ...formData, selectMatch: val.value, sminValue: val.minVal, smaxValue: val.maxVal })
    }

    const onChangeTransaction = (event) => {
        var dataVal = event.target.value == "Include" ? false : true
        setTransactionStatus(event.target.value)
        setDataTable([])
        setDataTableAll([])
        setDisabledIncident(dataVal)
        setIncidentStatus(false)
        setSumberId("")
    }

    const onChangeIncident = () => {
        const valIncidentStatus = incidentStatus ? false : true;
        const tempDataAll = dataTableAll.map(el => {
            return { ...el, checked: false, disabled: false }
        })
        const tempData = dataTable.map(el => {
            return { ...el, checked: false, disabled: false }
        })
        setIncidentStatus(valIncidentStatus)
        setDataTable(tempData)
        setDataTableAll(tempDataAll)
    }

    /* search by upload */
    const handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    const handleFileChange = async (event) => {
        var filesSelect = event.target.files[0];
        var fileSize = filesSelect.size;
        var filename = filesSelect.name;
        var extension = filename.split('.').pop();
        var byte = 1000000; // 1 MB
        if (fileSize > byte) {
            const { target = {} } = event || {};
            target.value = "";
            toast.error(`File to large`);
            return;
        }
        if (extension != "xml") {
            const { target = {} } = event || {};
            target.value = "";
            toast.error(`Must use file type xml`);
            return;
        }
        const myNewFile = new File([filesSelect], filesSelect.name, { type: "application/xml" });
        setSelectedFile(myNewFile);
    }

    const pressSearchUpload = (e) => {
        e.preventDefault()
        if (!selectedFile) {
            toast.error(`No File Chosen`);
            return;
        }
        setLoading(true)
        const formDataAppend = new FormData();
        formDataAppend.append("file", selectedFile, selectedFile.name);
        formDataAppend.append("smin", formData.sminValue.toString());
        formDataAppend.append("smax", formData.smaxValue.toString());
        axios.post(apiSearchUpload, formDataAppend)
            .then(res => {
                if (!res.data.data) {
                    clearTable()
                } else {
                    var dataAll = res.data.data
                    var dataAllValidation = transactionStatus == "Include" ? dataAll.map(el => ({ ...el, checked: false, disabled: false })) : dataAll.map(el => ({ ...el, checked: false, disabled: false, resourcesInformation: "", typeWatchlist: "" }))
                    const indexOfLastPost = page * size;
                    const indexOfFirstPost = indexOfLastPost - size;
                    const countTotalPage = dataAllValidation.length == 0 ? 1 : (dataAllValidation.length / size);
                    const currentPosts = dataAllValidation.slice(indexOfFirstPost, indexOfLastPost);
                    setDataTableAll(dataAllValidation)
                    setDataTable(currentPosts)
                    setTotalPage(Math.ceil(countTotalPage))
                }
                setLoading(false)
            })
            .catch(err => {
                toast.error(translate(`${err.response.data.message}`));
                setLoading(false)
            });
    }

    /* search by manual */
    const pressSearchManual = () => {
        setLoading(true)
        const payloadData = {
            datas: {
                birth_date: formData.tanggalLahir == null ? formData.tanggalLahir : moment(formData.tanggalLahir).format('YYYY-MM-DD'),
                birth_place: formData.tempatLahir,
                cid_no: formData.noIndetitas,
                name: formData.namaAsli
            },
            filters: {
                smin: formData.sminValue,
                smax: formData.smaxValue
            }
        }
        axios.post(apiSearchManual, payloadData)
            .then(res => {
                var dataAll = res.data.data
                var dataAllValidation = transactionStatus == "Include" ? dataAll.map(el => ({ ...el, checked: false, disabled: false })) : dataAll.map(el => ({ ...el, checked: false, disabled: false, resourcesInformation: "1", typeWatchlist: "Internal Watchlist" }))
                const indexOfLastPost = page * size;
                const indexOfFirstPost = indexOfLastPost - size;
                const countTotalPage = dataAllValidation.length == 0 ? 1 : (dataAllValidation.length / size);
                const currentPosts = dataAllValidation.slice(indexOfFirstPost, indexOfLastPost);
                setDataTableAll(dataAllValidation)
                setDataTable(currentPosts)
                setTotalPage(Math.ceil(countTotalPage))
                setLoading(false)
            })
            .catch(err => {
                toast.error(translate(`${err.response.data.message}`));
                setLoading(false)
            })
    }

    /* table change pagination */
    const handleChange = (event) => {
        const indexOfLastPost = page * parseInt(event.target.value);
        const indexOfFirstPost = indexOfLastPost - parseInt(event.target.value);
        const nextPosts = dataTableAll.slice(indexOfFirstPost, indexOfLastPost);
        const countTotalPage = dataTableAll.length == 0 ? 1 : (dataTableAll.length / parseInt(event.target.value));
        setPage(1)
        setDataTable(nextPosts)
        setSize(parseInt(event.target.value))
        setTotalPage(Math.ceil(countTotalPage))
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        const indexOfLastPost = value * size;
        const indexOfFirstPost = indexOfLastPost - size;
        const nextPosts = dataTableAll.slice(indexOfFirstPost, indexOfLastPost);
        setPage(value);
        setDataTable(nextPosts)
    }

    //Usage example:
    const pressIndicatorReport = (index, kodeIndicator) => {
        const tempData = dataIndicatorReport.map(el => {
            if (el.kode_indicator === kodeIndicator) {
                return { ...el, action_select: !el.action_select };
            }
            return el;
        })
        setDataIndicatorReport(tempData)
    }

    const toggle = () => {
        if (formData.selectFilter == 1 && transactionStatus == "Include" && sumberId == "") {
            toast.error(`Please input sumber id`);
            return
        }


        setModalIndicator(!modalIndicator);
        const tempData = dataIndicatorReport.map(el => {
            if (el.action_select === true) {
                return { ...el, action_select: !el.action_select };
            }
            return el;
        })
        setDataIndicatorReport(tempData)
    }

    const pressExport = () => {


        var dataIndicatorResult = []
        dataIndicatorReport.map(el => {
            if (el.action_select === true) {
                dataIndicatorResult.push(el.kode_indicator)
            }
        })

        var dataAccountNoExport = []
        dataTableAll.map(el => {
            if (el.checked === true) {
                if (transactionStatus == "Include") {
                    console.log("dataObj 1", el.sumber_id)
                    const dataObj = {
                        account_no: el.account_no,
                        score: el.score,
                        incident: incidentStatus, //true or false
                        keterangan: el.keterangan,
                        sumber_id: el.sumber_id != undefined ? el.sumber_id : sumberId
                    };
                    dataAccountNoExport.push(dataObj)
                } else {
                    // console.log("dataObj 2", el.typeWatchlist)
                    // console.log("dataObj 3", el)
                    const dataObj = {
                        account_no: el.account_no,
                        score: el.score,
                        incident: incidentStatus, //true or false
                        keterangan: el.keterangan,
                        sumber_id: el.sumber_id, //true or false
                        watchlist_type: el?.typeWatchlist?.toUpperCase(), //"PROAKTIF WATCHLIST", // exclude
                        information_source: el.resourcesInformation //"1" // exclude
                    };
                    console.log("dataObj 3", dataObj)
                    dataAccountNoExport.push(dataObj)
                }
            }
        })

        const payloadData = {
            datas: {
                indent: true,
                direct_download: false,
                include_transactions: transactionStatus === "Include" ? true : false,
                indicators: dataIndicatorResult,
                customer: dataAccountNoExport
            }
        }

        console.log("payloadData", JSON.stringify(payloadData))
        payloadData.datas.direct_download == true ? exportDirectDownload(payloadData) : exportNoDirectDownload(payloadData)
    }

    const exportDirectDownload = (payloadData) => {
        axios.post(apiExportDownload, payloadData, { responseType: 'blob' })
            .then(res => {
                var timeStamp = moment(new Date().getTime()).format("DD-MM-YYYY hh:mm:ss");
                const url = new Blob([res.data], { type: 'application/zip' });
                const linkfile = document.createElement('a');
                linkfile.href = URL.createObjectURL(url);
                linkfile.setAttribute('download', `aml-export-${timeStamp}.zip`);
                document.body.appendChild(linkfile);
                linkfile.click();
                setTimeout(function () {
                    document.body.removeChild(linkfile);
                }, 100);
                setLoading(false)
                toggle()
            })
            .catch(err => {
                toast.error(translate(`${err.response.data.message}`));
                setLoading(false)
                toggle()
            })
    }

    const exportNoDirectDownload = (payloadData) => {
        setLoading(true)
        axios.post(apiExportDownload, payloadData)
            .then(res => {
                var timeStamp = moment(new Date().getTime()).format("DD-MM-YYYY hh:mm:ss");
                var binaryString = new Buffer(res.data.data, "base64");
                var blob = new Blob([binaryString], { type: 'application/zip' });
                const linkfile = document.createElement('a');
                linkfile.href = URL.createObjectURL(blob);
                linkfile.setAttribute('download', `aml-export-${timeStamp}.zip`);
                document.body.appendChild(linkfile);
                linkfile.click();
                setTimeout(function () {
                    document.body.removeChild(linkfile);
                }, 100);
                setLoading(false)
                toggle()
            })
            .catch(err => {
                toast.error(`${err.response.statusText}`); //err.response.data.message
                setLoading(false)
                toggle()
            })
    }

    const handleUpdateResourceInformation = (value, e) => {
        const tempData = dataTable.map(el => {
            if (el.account_no === value.account_no) {
                return { ...el, resourcesInformation: e.target.value };
            }
            return el;
        })
        const tempDataAll = dataTableAll.map(el => {
            if (el.account_no === value.account_no) {
                return { ...el, resourcesInformation: e.target.value };
            }
            return el;
        })
        setDataTable(tempData)
        setDataTableAll(tempDataAll)
    }

    const handleUpdateTypeWatchlist = (value, e) => {
        const tempData = dataTable.map(el => {
            if (el.account_no === value.account_no) {
                return { ...el, typeWatchlist: e.target.value };
            }
            return el;
        })
        const tempDataAll = dataTableAll.map(el => {
            if (el.account_no === value.account_no) {
                return { ...el, typeWatchlist: e.target.value };
            }
            return el;
        })
        setDataTable(tempData)
        setDataTableAll(tempDataAll)
    }

    return (
        <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-28">AML</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Mirae Asset Sekuritas AML</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-18">Search</h1>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="font-family-spoqa col-12 col-md-2 col-lg-2 px-2">
                    <Select
                        name="group "
                        defaultValue={{ label: "Upload", value: 0 }}
                        value={selectOption.filter((function (option: any) {
                            return option.value == formData.selectFilter
                        }))}
                        options={selectOption}
                        onChange={(e) => searchUploadOrManual(e)}
                    />
                </div>
                {
                    formData.selectFilter == 0 ?
                        <div className="font-family-spoqa col-12 col-md-3 col-lg-7 px-2">
                            <input className="form-control" type="file" accept=".xml" onChange={handleFileChange} onClick={handleClick} />
                            <span className="font-family-spoqa font-size-10">{`*) File must be type .xml and file size max 1 MB`}</span>
                        </div>
                        : <></>
                }
                {
                    formData.selectFilter == 0 ?
                        <div className="font-family-spoqa font-size-18 col-12 col-md-3 col-lg-3 px-2">
                            <button className="btn btn-primary w-100 py-2" style={{ border: "#000", backgroundColor: "#000" }} disabled={loading} onClick={(e) => pressSearchUpload(e)}>
                                {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : "Search"}
                            </button>
                        </div>
                        : <></>
                }
            </div>
            {
                formData.selectFilter == 1 ?
                    <div className="mb-2">
                        <div className="row">
                            <div className="font-family-spoqa mb-2 col-12 col-md-2 col-lg-5 px-2">
                                <div className="mb-2">
                                    Nama Asli
                                </div>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-control bg-white py-3"
                                        placeholder="Nama Asli"
                                        onChange={(e) => setFormData({ ...formData, namaAsli: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="font-family-spoqa mb-2 col-12 col-md-2 col-lg-5 px-2">
                                <div className="mb-2">
                                    No Identitas
                                </div>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-control bg-white py-3"
                                        placeholder="No Identitas"
                                        onChange={(e) => setFormData({ ...formData, noIndetitas: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="font-family-spoqa mb-2 col-12 col-md-2 col-lg-5 px-2">
                                <div className="mb-2">
                                    Tempat Lahir
                                </div>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-control bg-white py-3"
                                        placeholder="Tempat Lahir"
                                        onChange={(e) => setFormData({ ...formData, tempatLahir: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="font-family-spoqa mb-2 col-12 col-md-2 col-lg-5 px-2">
                                <div className="mb-2">
                                    Tanggal Lahir
                                </div>
                                <div className="position-relative">
                                    <DatePicker
                                        placeholderText="Date of Birth"
                                        maxDate={new Date()}
                                        selected={formData.tanggalLahir}
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                tanggalLahir: e
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="font-family-spoqa mb-2 mt-1 font-size-18 col-12 col-md-3 col-lg-2 px-2">
                                <div className="mb-4" />
                                <div className="btn btn-primary w-100 py-2" style={{ border: "#000", backgroundColor: "#000" }} onClick={() => pressSearchManual()}>
                                    {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : "Search"}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }

            <div className="row mt-4 mb-4">
                <div className="col-md-5">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-18">Filter</h1>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="mb-2" style={{ fontWeight: 600 }}>
                                Match (%)
                            </div>
                            <Select
                                name="group"
                                defaultValue={{ label: "0% - 100%", value: 0, minVal: 0, maxVal: 100 }}
                                value={filterOption.filter((function (option: any) {
                                    return option.value == formData.selectMatch
                                }))}
                                options={filterOption}
                                onChange={(e) => handleMatch(e)}
                            />

                        </div>
                        <div className="col-md-6">
                            <div className="mb-3" style={{ fontWeight: 600 }}>
                                Transaction
                            </div>
                            <div className="row">
                                <div className="col-sm-1" style={{ display: "flex", justifyContent: "start" }} onChange={event => onChangeTransaction(event)}>
                                    <input type="radio" checked={transactionStatus === "Include"} value={"Include"} />
                                </div>
                                <div className="col-sm-3">Include</div>
                                <div className="col-sm-1" style={{ display: "flex", justifyContent: "start" }} onChange={event => onChangeTransaction(event)}>
                                    <input type="radio" checked={transactionStatus === "Exclude"} value={"Exclude"} />
                                </div>
                                <div className="col-sm-3">Exclude</div>
                            </div>
                        </div>
                    </div>

                </div>
                {formData.selectFilter == 1 && transactionStatus == "Include" ?
                    <div className="col-md-5">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h1 className="font-family-spoqa mb-0 font-size-18">Sumber Data</h1>
                        </div>
                        <div className="mb-2" style={{ fontWeight: 600 }}>
                            Sumber ID
                        </div>
                        <input
                            className="form-control"
                            value={sumberId}
                            maxLength={16}
                            placeholder="Sumber ID"
                            onChange={(e) =>
                                setSumberId(e.target.value.replace(/[^0-9]+/g, ''))
                            }
                        />
                    </div>
                    : <></>
                }
            </div>
            <div className="row mt-2">
                <div className="" style={{ width: "2%" }}>
                    <input type="checkbox" name="Incident" disabled={disabledIncident} checked={incidentStatus} onChange={onChangeIncident} />
                </div>
                <div className="col-sm-3">Incident</div>
            </div>
            <div className="row mb-2 ml-1 mr-1">
                <div className="font-family-spoqa col-12 col-md-2 col-lg-8 px-2" />
                <div style={{ textAlign: "end" }} className="font-family-spoqa font-size-14 col-12 col-md-2 col-lg-4 px-2">
                    Selector {countType(true)} of {dataTableAll.length}
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered mb-0 font-family-spoqa">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ textAlign: 'center' }}><input type="checkbox" disabled={incidentStatus} checked={dataTableAll.length == 0 ? false : dataTableAll.every(el => el.checked)} onChange={selectAll} /></th>
                                    <th className="font-family-spoqa">Nama Asli</th>
                                    <th className="font-family-spoqa">No Identitas</th>
                                    <th className="font-family-spoqa">Tempat Lahir</th>
                                    <th className="font-family-spoqa">Tanggal Lahir</th>
                                    <th className="font-family-spoqa">Match (%)</th>
                                    <th className="font-family-spoqa">Transaksi</th>
                                    {transactionStatus == "Exclude" ? <th className="font-family-spoqa">Sumber Informasi</th> : <></>}
                                    {transactionStatus == "Exclude" ? <th className="font-family-spoqa">Jenis Watchlist</th> : <></>}
                                </tr>
                            </thead>
                            <tbody>
                                {dataTable ? dataTable.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={element.checked}
                                                    disabled={
                                                        incidentStatus == true
                                                            ?
                                                            (transactionStatus == "Include" ? (element.disabled == true ? true : false) : true)
                                                            :
                                                            element.is_transacted == 0 && transactionStatus == "Include" ? true : false
                                                        // element.is_transacted == 0 && transactionStatus == "Include"
                                                        //     ?
                                                        //     true
                                                        //     :
                                                        //     (incidentStatus == true ? (element.disabled == true ? true : false) : false)
                                                    }
                                                    onChange={(e) => checkOne(element.account_no)}
                                                />
                                            </td>
                                            <td className="font-family-spoqa">{element.account_name}</td>
                                            <td className="font-family-spoqa">{element.cid_no}</td>
                                            <td className="font-family-spoqa">{element.birth_place}</td>
                                            <td className="font-family-spoqa">{element.birth_date.slice(0, 10)}</td>
                                            <td className="font-family-spoqa">{parseInt(element.score)}</td>
                                            <td className="font-family-spoqa">
                                                <span
                                                    className={`${element.is_transacted == 0 ? "badge badge-soft-danger" : "badge badge-soft-primary"}`}
                                                    style={{ fontSize: "12px" }}>
                                                    {`${element.is_transacted == 0 ? "False" : "True"}`}
                                                </span>
                                            </td>
                                            {
                                                transactionStatus == "Exclude" ?
                                                    <td className="font-family-spoqa" style={{ minWidth: '100px' }}>
                                                        <select onChange={(e) => handleUpdateResourceInformation(element, e)}>
                                                            {dataOptionResource.map(({ value, label }, index) => <option value={value} selected={element.resourcesInformation == value} >{label}</option>)}
                                                        </select>
                                                    </td>
                                                    : <></>
                                            }
                                            {
                                                transactionStatus == "Exclude" ?
                                                    <td className="font-family-spoqa" style={{ minWidth: '100px' }}>
                                                        <select key={index} onChange={(e) => handleUpdateTypeWatchlist(element, e)}>
                                                            {dataOptionsTypeWatchlist.map(({ value, label }, index) => <option value={value} selected={element.typeWatchlist == value} >{label}</option>)}
                                                        </select>
                                                    </td>
                                                    : <></>
                                            }
                                        </tr>
                                    )
                                }) : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center mt-2">
                        {
                            loading
                                ?
                                <Spinner color='#fff' size="sm" className='mr-1' />
                                :
                                (dataTable.length == 0 ? <p style={{ color: "lightgray" }}>No data</p> : <></>)
                        }
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="font-family-spoqa col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">
                            Show
                        </span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="font-family-spoqa col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
            <div className="row mt-4">
                <div className="font-family-spoqa col-12 col-md-2 col-lg-10 px-2" />
                <div style={{ height: "50%", textAlign: "end" }} className="font-family-spoqa font-size-14 col-12 col-md-2 col-lg-2 px-2">
                    <button
                        className="btn btn-primary w-100 py-2"
                        style={{ border: "#000", backgroundColor: "#000" }}
                        disabled={countType(true) == 0 ? true : false}
                        onClick={toggle}>
                        Export
                    </button>
                </div>
            </div>
            <Modal size={'lg'} backdrop={'static'} centered={true} isOpen={modalIndicator} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <div className="font-size-18 font-weight-bold font-family-spoqa" style={{ textAlign: "center" }}>
                        Indikator Laporan
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="ml-3 mr-3 mt-3 mb-3 font-family-spoqa">
                        <table className="table table-striped table-centered mb-0 font-family-spoqa">
                            <thead className="thead-light">
                                <tr>
                                    <th>No</th>
                                    <th>Kode Indikator</th>
                                    <th>Indikator Laporan</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataIndicatorReport.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ wordBreak: 'break-all', minWidth: '50px' }}>{index + 1}</td>
                                            <td style={{ wordBreak: 'break-all', minWidth: '120px' }}>{value.kode_indicator}</td>
                                            <td style={{ wordBreak: 'break-all', minWidth: '250px', textAlign: 'justify' }}>{value.indicator_laporan}</td>
                                            <td style={{ wordBreak: 'break-all', minWidth: '120px' }}>
                                                <button
                                                    className='btn btn-primary'
                                                    type="submit"
                                                    onClick={() => pressIndicatorReport(index, value.kode_indicator)}
                                                    style={value.action_select ? { width: "80px", border: "1px solid #f37f26", backgroundColor: "#fff", padding: "10px" } : { width: "80px", border: "#f37f26", backgroundColor: "#f37f26", padding: "10px" }}>
                                                    <span style={value.action_select ? { color: '#f37f26' } : { color: '#fff' }}>{value.action_select ? "Unselect" : "Select"}</span>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary py-2"
                        style={{ border: "#000", backgroundColor: "#000", width: "20%" }}
                        disabled={loading}
                        onClick={pressExport}>
                        {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                        Export
                    </button>
                </ModalFooter>
            </Modal>
            {updateDataIndicatorReport()}
        </div>
    );
};

const mapStateToProps = ({ aml }: IRootState) => ({
    dataOptionResource: aml.dataOptionResource,
    dataOptionsTypeWatchlist: aml.dataOptionsTypeWatchlist,
    dataOptionsReportIndicator: aml.dataOptionsReportIndicator,
});

const mapDispatchToProps = {
    optionResources,
    watchlistType,
    optionReportIndicator
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(AntiMoneyLaundry);