import {IRootState} from "app/shared/reducers";
import {logout} from "app/shared/reducers/authentication";
import {connect} from "react-redux";
import {ProfilePage} from "app/modules/profile/profile";
import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, Spinner} from "reactstrap";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row, Col} from 'react-bootstrap';
import SuccessIllustrationHint from "app/component/success-illustration-hint";
import Axios from "axios";
import {toast} from "react-toastify";
import {translate} from "react-jhipster";

export const ChangePasswordProfile = () => {

    const history = useHistory()

    //Form Handle Start
    const icPassEye = "fa fa-fw fa-eye field-icon";
    const icSlashPassEye = "fa fa-eye fa-eye-slash";

    const [getIcTogglePassword, setIcTogglePassword] = useState(icSlashPassEye);
    const [getIcToggleNewPassword, setIcToggleNewPassword] = useState(icSlashPassEye);
    const [getIcToggleConfirmPassword, setIcToggleConfirmPassword] = useState(icSlashPassEye);

    const [getTypeTogglePassword, setTypeTogglePassword] = useState("password");
    const [getTypeToggleNewPassword, setTypeToggleNewPassword] = useState("password");
    const [getTypeToggleConfirmPassword, setTypeToggleConfirmPassword] = useState("password");

    const [etPassword, setPassword] = useState("")
    const [etNewPassword, setNewPassword] = useState("")
    const [etPasswordConf, setPasswordConf] = useState("")
    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    const togglePass = () => {
        if (getIcTogglePassword == icSlashPassEye) {
            setIcTogglePassword(icPassEye);
            setTypeTogglePassword("text");
        } else {
            setIcTogglePassword(icSlashPassEye);
            setTypeTogglePassword("password");
        }
    }
    const toggleNewPass = () => {
        if (getIcToggleNewPassword == icSlashPassEye) {
            setIcToggleNewPassword(icPassEye);
            setTypeToggleNewPassword("text");
        } else {
            setIcToggleNewPassword(icSlashPassEye);
            setTypeToggleNewPassword("password");
        }
    }
    const toggleConfPass = () => {
        if (getIcToggleConfirmPassword == icSlashPassEye) {
            setIcToggleConfirmPassword(icPassEye);
            setTypeToggleConfirmPassword("text");
        } else {
            setIcToggleConfirmPassword(icSlashPassEye);
            setTypeToggleConfirmPassword("password");
        }
    }
    const validation = (valueInput) => {
        var reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,12}$');
        return reg.test(valueInput)
    }

    const containsSpecialChars = (str) => {
        const specialChars =
            /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    const handleValidateChangePassword = () => {

        if (etPassword == "" || etNewPassword == "" || etPasswordConf == "") {
            toast.error(`Please check field doesn't empty`);
        } else if (!validation(etNewPassword) || !validation(etPasswordConf)) {
            toast.error(`Please check field and term and conditions`);
        } else if (etNewPassword != etPasswordConf) {
            toast.error(`Please check new password and confirm password`);
        } else if (containsSpecialChars(etNewPassword) || containsSpecialChars(etPasswordConf)) {
            toast.error(`Please check field and term and conditions`);
        } else {

            setLoadingButton(true)
            Axios.get('services/uaadocservice/api/account')
                .then(resAcc => {
                    Axios.post('/services/uaadocservice/api/employee/change-password',
                        {
                            "userName": resAcc.data.employeeNo,
                            "currentPassword": etPassword,
                            "newPassword": etNewPassword,
                            "confirmNewPassword": etPasswordConf
                        }).then(res => {
                        setLoadingButton(false)
                        if(res.data.code == 200) {
                            history.replace('/profile/me')
                            toast.success(`Change Password Successfull`, {
                                position: "top-left",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast.error(`${res.data.message}`, {
                                position: "top-left",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }).catch(err => {
                        setLoadingButton(false)
                        console.log("Response catch",err)
                        toast.error(translate(`${err.response.data.message}`), {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }).catch(err => {
                        setLoadingButton(false)
                        toast.error(translate(`${err.response.data.message}`), {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                })
        }
    }

    const [getModalTitleMessage, setModalTitleMessage] = useState("");
    const [getModalDescMessage, setModalDescMessage] = useState("");
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);

    const [isDisabledButton, setDisabledButton] = useState(false);

    //Form Handle End

    return <div className="container">
        <div className='container d-flex  h-100' style={{alignItems: 'center', justifyContent: 'center'}}>

            <div className="font-family-spoqa" style={{padding: '10px', width: '70%'}}>
                <div className='d-flex'>
                    <button className="btn px-0 mr-4" onClick={() => history.replace("/profile/me")}>
                        <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057"/>{' '}
                    </button>
                    <h2 className='mb-0'>
                        Change Password
                    </h2>
                </div>

                <div style={{padding: '10px', width: '70%'}}>

                    {/*Old Password Start*/}
                    <div className="row">
                        <div>
                            <h5 font-family-spoqa style={{marginTop: '50px'}}>
                                Old Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etPassword} name="etNewPass" minLength={8} maxLength={12} style={{width: "100%"}}
                                       type={getTypeTogglePassword} placeholder="Input Old Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           setPassword(e.target.value)
                                       }
                                       }/>
                            </Col>
                        </Row>
                        <Col onClick={togglePass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcTogglePassword}></span>
                        </Col>

                    </div>
                    {/*Old Password End*/}

                    {/*New Password Start*/}
                    <div className="row">
                        <div>
                            <h5 style={{marginTop: '24px'}}>
                                New Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etNewPassword} name="etNewPass" minLength={8} maxLength={12} style={{width: "100%"}}
                                       type={getTypeToggleNewPassword} placeholder="Input New Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           setNewPassword(e.target.value)
                                       }
                                       }/>
                            </Col>
                        </Row>
                        <Col onClick={toggleNewPass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcToggleNewPassword}></span>
                        </Col>
                    </div>
                    {/*New Password End*/}

                    {/*Confirmation Password Start*/}
                    <div className="row mb-4">
                        <div>
                            <h5 style={{marginTop: '24px'}}>
                                Confirm New Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etPasswordConf} name="etNewPass" minLength={8} maxLength={12} style={{width: "100%"}}
                                       type={getTypeToggleConfirmPassword} placeholder="Confirm New Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           setPasswordConf(e.target.value)
                                       }}/>
                            </Col>
                        </Row>
                        <Col onClick={toggleConfPass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcToggleConfirmPassword}></span>
                        </Col>
                    </div>
                    {/*Confirmation Password End*/}

                    <div style={{textAlign: "justify", lineHeight: "2.0"}}>
                        <ul style={{ marginTop: "-10px" }}>
                            <li>Password must be at least min 8 characters max 12 characters</li>
                            <li>Must include at least one upper case letter, one lower case letter, and one numeric digit</li>
                            <li>Password doesn't include special character</li>
                        </ul>
                    </div>

                    <Button disabled={loadingButton}
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                                handleValidateChangePassword()
                            }}
                            style={loadingButton ? {
                                backgroundColor: '#9f9f9f',
                                width: '100%',
                            } : {backgroundColor: '#F37F26', width: '100%'}}>
                        {(loadingButton) ? <Spinner color='#fff' size="sm" style={{marginRight: "8px"}}/> : <></>}
                        Save New Password
                    </Button>

                </div>

            </div>
        </div>
    </div>
}

export default ChangePasswordProfile;